import { WellHeader } from '_components';
import styles from './StaticPages.module.css';
import { Link } from 'react-router-dom';

export const ContactUs = () => {
  return (
    <div
      className={styles['outside']}
      style={{ background: 'rgba(248, 247, 243, 255)' }}
    >
      <WellHeader
        title={'Contact Us'}
        style={{ fill: 'rgba(248, 247, 243, 255)' }}
      />
      <div className={'container'}>
        <p>Have questions about ShareWell?</p>
        <p>
          Check out our{' '}
          <a href={'/https://sharewellnow.zendesk.com/hc/en-us'}>FAQ</a>. If
          you're a new member, we highly recommend attending a New Member
          session in the{' '}
          <Link
            to={
              '/well/welcome-well/' + process.env.REACT_APP_WELCOME_GROUP_UUID
            }
          >
            Welcome Well
          </Link>
          .
        </p>
        <p>
          If you still have questions, please email us{' '}
          <a href='mailto:support@sharewellnow.com'>support@sharewellnow.com</a>
          .
        </p>
      </div>
    </div>
  );
};
