import { makeStyles, createStyles } from '@material-ui/styles';


type Props = {
  cooldownRemaining?: number;
};


const useStyles = makeStyles(() =>
  createStyles({
    addAttachment: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(15, 20, 25, 0.1)',
      },
    }
  })
);




export const AddAttachment: React.FC<Props> = (props) => {

  const classes = useStyles();

  return (
    <svg className={classes.addAttachment} width='15' height='20' viewBox='0 0 15 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.48379 0.887599C9.55362 0.774638 10.6468 1.04951 11.4665 1.52278C12.2862 1.99604 13.0708 2.80529 13.5079 3.78827C13.9571 4.7985 14.0533 6.04234 13.3636 7.23688L8.86359 15.0311C8.61692 15.4584 8.13697 16.0101 7.43681 16.3254C6.67937 16.6666 5.74059 16.6921 4.76552 16.1292C3.79044 15.5662 3.3432 14.7404 3.25991 13.9139C3.18293 13.1499 3.42076 12.4584 3.66744 12.0311L7.66744 5.1029C7.94358 4.62461 8.55517 4.46073 9.03347 4.73688C9.51176 5.01302 9.67563 5.62461 9.39949 6.1029L5.39949 13.0311C5.31284 13.1812 5.22388 13.4557 5.24984 13.7134C5.2695 13.9085 5.35495 14.1601 5.76552 14.3971C6.17608 14.6342 6.43667 14.5824 6.6155 14.5018C6.85162 14.3955 7.04489 14.1812 7.13154 14.0311L11.6315 6.23688C11.9419 5.69936 11.922 5.14416 11.6804 4.60087C11.4267 4.03033 10.9458 3.53156 10.4665 3.25483C9.98716 2.97809 9.31475 2.81098 8.6938 2.87654C8.1025 2.93898 7.61175 3.19936 7.30141 3.73688L2.30142 12.3971C1.64709 13.5305 2.01568 15.6963 4.26552 16.9952C6.51535 18.2942 8.57529 17.5305 9.22962 16.3971L12.7296 10.335C13.0058 9.85666 13.6173 9.69279 14.0956 9.96893C14.5739 10.2451 14.7378 10.8567 14.4617 11.335L10.9617 17.3971C9.61599 19.7279 6.21183 20.4283 3.26552 18.7273C0.319203 17.0262 -0.776311 13.7279 0.569364 11.3971L5.56936 2.73688C6.25903 1.54234 7.3843 1.00369 8.48379 0.887599Z'
        fill={'rgb(29, 155, 240)'}
      />
    </svg>
  );
};
