import { roomConstants } from '_constants';
import { AppDispatch } from '_reducers';

export const roomMessagingActions = {
  setSendHostSignal,
  clearHostSignal,
};

function setSendHostSignal(sentHostSignal: any) {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: roomConstants.SET_SENT_HOST_SIGNAL,
      sentHostSignal: sentHostSignal,
    });
    return Promise.resolve(1);
  };
}


function clearHostSignal() {
  return (dispatch: AppDispatch) => {
    dispatch({ type: roomConstants.SET_RECEIVED_HOST_SIGNAL });
  };
}
