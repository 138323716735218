import { useState, useEffect, createContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions, groupUserActions } from '_actions';
import { GroupList } from '.';

export const GroupListContext = createContext(null);

export const GroupListProvider = () => {
  const dispatch = useDispatch();
  const { userStore } = useSelector((state) => state);

  const [groupList, setGroupList] = useState([]);
  const [myGroupList, setMyGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('popular');

  useEffect(() => {
    const start = async () => {
      if (userStore.loggedIn) {
        const myGroupsResponse = await dispatch(
          groupUserActions.getMyGroups(100, 0)
        );
        if (myGroupsResponse) setMyGroupList(myGroupsResponse);
      }

      setIsLoading(false);
    };

    start();
  }, []);

  useEffect(() => {
    const updateSearch = async () => {
      const groupResponse = await dispatch(
        groupActions.getGroupList(searchTerm, 100, 0, sortType)
      );

      if (groupResponse && groupResponse.length > 0)
        setGroupList(groupResponse);
    };

    updateSearch();
  }, [dispatch, searchTerm, sortType]);

  const updateSort = useCallback((newSort) => {
    setSearchTerm('');
    setSortType(newSort);
  }, []);

  return (
    <GroupListContext.Provider
      value={{
        //state
        groupList,
        myGroupList,
        searchTerm,
        isLoading,
        setSortType,

        //state functions
        setGroupList,
        setMyGroupList,
        setSearchTerm,
        setIsLoading,

        //functions
        updateSort,
      }}
    >
      <GroupList />
    </GroupListContext.Provider>
  );
};
