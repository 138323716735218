import styles from './FindingItems.module.css';

interface IFindingItem {
  isWhite: boolean;
}

export const FindingOne = ({ isWhite }: IFindingItem) => {

  return (

    <div className={styles['face-item'] + ' ' + styles['orange'] + ' ' + (isWhite ? styles['is-white'] : '')}>
      <img
        src={
          process.env.REACT_APP_ASSETS_URL +
          '/site/subscription/face-one.svg'
        }
        className={styles['face-image']}
        alt="Face One"
      />
      <div className={styles['face-percent']}>90%</div>
      <div className={styles['face-text']}>
        Report being happier after a session
      </div>
    </div>
  )


}



export const FindingTwo = ({ isWhite }: IFindingItem) => {

  return (
    <div className={styles['face-item'] + ' ' + styles['green'] + ' ' + (isWhite ? styles['is-white'] : '')}>
      <img
        src={
          process.env.REACT_APP_ASSETS_URL +
          '/site/subscription/face-two.svg'
        }
        className={styles['face-image']}
        alt="Face Two"
      />
      <div className={styles['face-percent']}>92%</div>
      <div className={styles['face-text']}>
        Prefer to attend 2 sessions per week
      </div>
    </div>

  )

}



export const FindingThree = ({ isWhite }: IFindingItem) => {

  return (
    <div className={styles['face-item'] + ' ' + styles['blue'] + ' ' + (isWhite ? styles['is-white'] : '')}>
      <img
        src={
          process.env.REACT_APP_ASSETS_URL +
          '/site/subscription/face-three.svg'
        }
        className={styles['face-image']}
        alt="Face Three"
      />
      <div className={styles['face-percent']}>96%</div>
      <div className={styles['face-text']}>
        Satisfied with peer support experience
      </div>
    </div >

  )

}

