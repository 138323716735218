import { useRef } from 'react';
import styles from './HowItWorks.module.css';
import { Link } from 'react-router-dom';
import {
  useGetImgixVersion,
  useScrollIntoView,
  useGetUsersInfo,
  usePageLoadScrollToAnchor,
} from '_hooks';
import {
  WellButtonLink,
  WellHeader,
  HomepageDivider,
  FeaturedGroups,
  UserItem,
  WellLoader,
  VideoPlayer,
} from '_components';

const hostListDev = [
  'JackNicholson',
  'DanielDayLewis',
  'burrito',
  'JulianneMoore',
];

const hostListLive = ['Mpumi', 'audaciousgrowth', 'Fausta', 'AprilMcBride'];

const hostList =
  process.env.REACT_APP_NODE_ENV === 'live' ? hostListLive : hostListDev;

export function HowItWorks() {
  // page could have an anchor
  usePageLoadScrollToAnchor();

  return (
    <div className={styles['how-it-works-container']}>
      <WellHeader
        title={'How ShareWell Works'}
        subtitle={
          'Your healing, your pace, your choice: learn more about how to make the most of the support ShareWell offers'
        }
        style={{ fill: 'rgba(248, 247, 243, 255)' }}
      />

      <FirstSection />

      <HomepageDivider />

      <SecondSection />

      <HomepageDivider />

      <ThirdSection />

      <HomepageDivider />

      <FourthSection />

      <HomepageDivider />

      <FifthSection />

      <HomepageDivider />

      <SixthSection />

      <HomepageDivider />

      <SeventhSection />

      <HomepageDivider />

      <EighthSection />
    </div>
  );
}

const FirstSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/people-looking.svg',
    {
      h: 300,
      w: 700,
    }
  );

  return (
    <section style={{ paddingTop: '16px' }}>
      <div className={styles['centered-content-container']}>
        <img
          className={styles['image-one']}
          src={imageOne}
          alt={'Animated people together'}
        />

        <h2 className={styles['section-title']}>
          Check out our Well Communities{' '}
        </h2>

        <p className={styles['section-text']}>
          ShareWell is organized into peer community groups called <b>Wells</b>.
          Anyone who has personal experience of the Well topic is welcome to
          join the community. You might find multiple Wells of interest to you,
          and you are more than welcome to join them all! New Wells are
          constantly being added as the community grows.
        </p>

        <FeaturedGroups />
      </div>
    </section>
  );
};

const SecondSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/sessions.png',
    {
      h: 300,
      w: 300,
    }
  );
  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/forum.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageThree = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/chat.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();
  const imageTwoRef = useRef<any>();
  const imageThreeRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(imageTwoRef, true, 0, () => {
    imageTwoRef?.current?.classList.add(styles['animate']);
  });
  useScrollIntoView(imageThreeRef, true, 0, () => {
    imageThreeRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section className={styles['white']}>
      <div className={styles['alternating-columns-row']}>
        <h2 className={styles['section-title']}>
          Within each Well, you'll find 3 ways to get peer support.
        </h2>

        <div
          className={styles['alternating-columns-item'] + ' ' + styles['limit']}
        >
          <img
            className={styles['image-two']}
            src={imageOne}
            alt={'A user on a ShareWell session'}
            ref={imageOneRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Sessions</b>: Sessions are small,
            virtual video meetings that range from 3-12 people. This is where
            you can connect live with real people for peer support. Sessions are
            hosted by our community hosts who share your life experience. You
            can register for sessions in advance to hold your spot and and when
            the time comes, you enter the session directly from from ShareWell,
            no downloads necessary!
          </p>
        </div>

        <div
          className={
            styles['alternating-columns-item'] +
            ' ' +
            styles['reverse'] +
            ' ' +
            styles['limit']
          }
        >
          <img
            className={styles['image-three']}
            src={imageTwo}
            alt={'ShareWell discussion board'}
            ref={imageTwoRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Discussion Board</b>: Each Well has
            its own all-member Discussion Board, open to everyone in the Well to
            see, post and comment. This is a place where support is sought and
            given, around the clock, outside of live video sessions. You can ask
            and answer questions, give support or share useful information.
          </p>
        </div>

        <div
          className={styles['alternating-columns-item'] + ' ' + styles['limit']}
        >
          <img
            className={styles['image-four']}
            src={imageThree}
            alt={'ShareWell chats'}
            ref={imageThreeRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Chats</b>: A chat is created for every
            session that includes everyone who registered for the session. Chats
            are temporary: they disappear three days after the session is over.
            Until then, you can use the chat to converse with your fellow
            session attendees, both during and after the session itself.
          </p>
        </div>
      </div>
    </section>
  );
};

const ThirdSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL +
      '/site/homepage/header-graphic-people.svg',
    {
      w: 600,
      h: 600,
    }
  );

  const imageOneRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section>
      <div className={styles['centered-content-container']}>
        <h2 className={styles['section-title']}>What are sessions like?</h2>

        <p className={styles['section-text']}>
          Peer support sessions are virtual video calls with a small group of
          3-12 people who meet to support each other in overcoming mental health
          issues or through big life transitions. It’s a perfect combination of
          healing and connection!
        </p>
        <div className={styles['what-sessions-like']}>
          <div className={styles['like-item']}>
            <div className={styles['yellow-dot']}></div>
            <span>Realize you are not alone</span>
          </div>
          <div className={styles['like-item']}>
            <div className={styles['yellow-dot']}></div>
            <span>Learn helpful information</span>
          </div>
          <div className={styles['like-item'] + ' ' + styles['slight-right']}>
            <div className={styles['yellow-dot']}></div>
            <span>Gain hope and help others</span>
          </div>
        </div>

        <div>
          <VideoPlayer isHomePage={false} />
        </div>

        <div className={styles['view-sessions-btn-container']}>
          <WellButtonLink
            to={'/sessions'}
            className={styles['view-sessions-btn']}
            btnText={'TRY A SESSION FOR FREE'}
            type={'well-btn-orange'}
          />
        </div>
      </div>
    </section>
  );
};

const FourthSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/laptop.png',
    {
      h: 300,
      w: 300,
    }
  );
  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL +
      '/site/how-it-works/computer-with-bio.png',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();
  const imageTwoRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(imageTwoRef, true, 0, () => {
    imageTwoRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section className={styles['gradient']}>
      <div className={styles['centered-content-container']}>
        <h2 className={styles['section-title']}>
          Who will I be speaking with?
        </h2>

        <p className={styles['section-text']}>
          Curious to know who's in the ShareWell community? Check out their bios
          (and write your own)! Click on any member's username to view their
          profile page. You can only see other members when you're part of the
          ShareWell community.
        </p>
        <div
          className={
            styles['alternating-columns-item'] +
            ' ' +
            styles['reverse'] +
            ' ' +
            styles['item-padding']
          }
        >
          <img
            src={imageOne}
            ref={imageOneRef}
            className={styles['image-six']}
            alt={'ShareWell for laptops'}
          />

          <div className={styles['content-container']}>
            <h3 className={styles['subsection-title']}>Community Members</h3>

            <p className={styles['section-text-connected']}>
              That’s you! (We hope.) ShareWell welcomes anyone and everyone who
              is seeking human connection, healing and growth. Whatever burdens
              you’re carrying, whatever you’ve been through thus far in life,
              ShareWell is open to you. All we ask is that you bring your full,
              authentic self: both when stepping forward to share your story and
              when stepping back to honor your own boundaries. We also ask that
              you adhere to our{' '}
              <Link className={styles['white-link']} to={'/safety-policy'}>
                Safety Policy
              </Link>{' '}
              and are generally respectful of other community members.
            </p>
          </div>
        </div>

        <div
          className={
            styles['alternating-columns-item'] + ' ' + styles['item-padding']
          }
        >
          <img
            className={styles['image-seven']}
            src={imageTwo}
            ref={imageTwoRef}
            alt={'ShareWell host bios'}
          />

          <div className={styles['content-container']}>
            <h3 className={styles['subsection-title']}>Community Hosts</h3>
            <p className={styles['section-text']}>
              This can be you too! Any community member can decide to become a
              Community Host. Hosts are a crucial component in ShareWell’s
              supportive community because they create safe spaces for everyone
              to discuss all forms of life experiences. They are key
              facilitators of ShareWell’s peer support sessions. That’s how the
              magic of peer support happens!
            </p>

            <p className={styles['section-text']}>
              Learn more about these heartfelt volunteers by clicking on their
              profiles to see their upcoming sessions before jumping into a
              session with them. Some may even have reviews from past sessions.
              Learn more about the basics of being a Community Host here.{' '}
              <a
                className={styles['white-link']}
                href={'https://blog.sharewellnow.com/become-a-sharewell-host/'}
                target={'_blank'}
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const FifthSection = () => {
  const { userList, userListLoading } = useGetUsersInfo(hostList, 20);

  return (
    <section>
      <div className={styles['centered-content-container-wide']}>
        <h2 className={styles['section-title']}>
          Who are the community hosts?
        </h2>

        <p className={styles['section-text']}>
          Meet some of the community members that host peer support sessions.
          When you're ready, you can host your own session too.
        </p>

        {userListLoading ? (
          <>
            <WellLoader heightWidth={100} />
          </>
        ) : (
          <>
            <div className={styles['users-row']}>
              {userList.map((user) => (
                <div className={styles['user-container']}>
                  <UserItem user={user} />
                </div>
              ))}
            </div>
            <p>
              Learn more about our{' '}
              <Link to={'/meet-hosts'}>host community</Link> and how you can
              join.
            </p>
          </>
        )}
      </div>
    </section>
  );
};

const SixthSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL +
      '/site/how-it-works/person-meditating.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section className={styles['white']}>
      <div className={styles['centered-content-container']}>
        <h2 className={styles['section-title']}>
          How is ShareWell different from other virtual support groups?
        </h2>

        <p className={styles['section-text']}>
          It's simple, we prioritize emotional safety. Opening yourself up to
          anyone can be a bit hard. Here are some of the ways ShareWell keeps
          you safe:
        </p>

        <div className={styles['safe-row']}>
          <div className={styles['left']}>
            <div className={styles['list-item']}>
              <h4>Three's a party, two's not allowed</h4>
              <p>
                At ShareWell, a session will only begin if there are three or
                more participants. Talking with a new person one-on-one is
                always awkward, and we've found that receiving support in a
                group setting with 3+ people is more comfortable. You will never
                be alone with another person and will always have witnesses to
                your conversations.
              </p>
            </div>

            <div className={styles['list-item']}>
              <h4>If you don't want to cross paths, you can take space</h4>
              <p>
                If you no longer wish to be in the same session as another
                person, maybe your boss or ex-partner joins ShareWell too, you
                can always restrict them. When you restrict a member, you are no
                longer able to attend sessions together. You can do this on
                users' profiles or directly in a session.
              </p>
            </div>

            <div className={styles['list-item']}>
              <h4>You can report disruptive members</h4>
              <p>
                If someone behaves inappropriately and violates ShareWell's{' '}
                <Link to={'/safety-policy'}>Safety Policy</Link>, report them so
                the ShareWell team can be aware. Any member who repeatedly
                violates the Code of Conduct or makes other members feel unsafe
                may be subject to account suspension or deactivation. Similarly,
                you can report sessions that are inappropriate for the ShareWell
                team's attention.
              </p>
            </div>
          </div>

          <img
            className={styles['image-nine']}
            src={imageOne}
            ref={imageOneRef}
            alt={'Peace of mind with ShareWell'}
          />
        </div>

        <p className={styles['section-text'] + ' ' + styles['bottom-text']}>
          Unsure if something is appropriate for ShareWell or not? You can also
          consult our <Link to={'/safety-policy'}>Safety Policy</Link> and{' '}
          <Link to={'/safety-policy'}>Safety Policy</Link> to clarify what is
          and isn't permitted.
        </p>
      </div>
    </section>
  );
};

const SeventhSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/basic.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/unlimited.svg',
    {
      h: 800,
      w: 300,
    }
  );

  const itemOneRef = useRef<any>();
  const itemTwoRef = useRef<any>();

  useScrollIntoView(itemOneRef, true, 0, () => {
    itemOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(itemTwoRef, true, 0, () => {
    itemTwoRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section id={'membership'}>
      <div className={styles['centered-content-container']}>
        <h2 className={styles['section-title']}>
          Ready to join? We have two membership options.
        </h2>

        <p className={styles['section-text']}>
          Our unlimited plan allows you to attend as many support sessions as
          you want each month. Find one support group you enjoy and attend it
          every week, or jump around and try multiple groups. We have many to
          choose from, including support for anxiety, depression, heartbreak,
          and much more.
        </p>

        <div className={styles['membership-row']}>
          <div className={styles['membership-item']} ref={itemOneRef}>
            <img
              className={styles['membership-image']}
              src={imageOne}
              alt={'ShareWell Basic Membership'}
            />

            <div className={styles['membership-title']}>Basic Membership</div>
            <div className={styles['membership-price']}>Free</div>
            <div className={styles['divider']}></div>
            <div>Attend 1 session per month</div>
            <div>Register for 1 session at a time</div>
            <div>Host multiple sessions</div>
          </div>

          <div className={styles['membership-item']} ref={itemTwoRef}>
            <img
              className={styles['membership-image']}
              src={imageTwo}
              alt={'ShareWell Unlimited Membership'}
            />
            <div className={styles['membership-title'] + ' ' + styles['blue']}>
              Unlimited Membership
            </div>
            <div className={styles['membership-price']}>$15/Month</div>
            <div className={styles['divider']}></div>
            <div>
              Attend <span className={styles['blue']}>unlimited</span> sessions
              each month
            </div>
            <div>
              Register for <span className={styles['blue']}>4</span> sessions at
              a time
            </div>
            <div>
              Host <span className={styles['blue']}>unlimited</span> sessions
            </div>
          </div>
        </div>

        <div className={styles['bottom-btn-container']}>
          <WellButtonLink
            to={'/register'}
            className={styles['membership-button']}
            type={'well-btn-orange'}
            btnText={'TRY UNLIMITED'}
          />
        </div>
      </div>
    </section>
  );
};

const EighthSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/member-2.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/host-2.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageThree = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/partner-2.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const itemOneRef = useRef<any>();
  const itemTwoRef = useRef<any>();
  const itemThreeRef = useRef<any>();

  useScrollIntoView(itemOneRef, true, 0, () => {
    itemOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(itemTwoRef, true, 0, () => {
    itemTwoRef?.current?.classList.add(styles['animate']);
  });
  useScrollIntoView(itemThreeRef, true, 0, () => {
    itemThreeRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section className={styles['white']}>
      <div className={styles['centered-content-container']}>
        <h2 className={styles['section-title']}>
          Ready to dive into ShareWell's peer support?
        </h2>

        <p className={styles['section-text'] + ' ' + styles['quote']}>
          “It's important that we share our experiences with other people. Your
          story will heal you and your story will heal somebody else.” <br></br>
          — Iyanla Vanzant
        </p>

        <div className={styles['ready-row']}>
          <div className={styles['ready-item']} ref={itemOneRef}>
            <img
              className={styles['ready-image']}
              src={imageOne}
              alt={'Become a member'}
            />
            <div className={styles['ready-title']}>Become a Member </div>
            <p className={styles['ready-text']}>
              Join ShareWell and experience the benefits of peer support
              firsthand.
            </p>
            <WellButtonLink
              to={'/register'}
              className={styles['ready-btn']}
              btnText={'LEARN MORE'}
              type={'well-btn-orange'}
            />
          </div>

          <div className={styles['ready-item']} ref={itemTwoRef}>
            <img
              className={styles['ready-image']}
              src={imageTwo}
              alt={'Become a host'}
            />
            <div className={styles['ready-title']}>Become A Host</div>
            <p className={styles['ready-text']}>
              Share your story, inspire others to do the same, and heal along
              the way.
            </p>
            <WellButtonLink
              to={'/host'}
              className={styles['ready-btn']}
              btnText={'LEARN MORE'}
              type={'well-btn-orange'}
            />
          </div>

          <div className={styles['ready-item']} ref={itemThreeRef}>
            <img
              className={styles['ready-image']}
              src={imageThree}
              alt={'Become a partner'}
            />
            <div className={styles['ready-title']}>Become A Partner</div>
            <p className={styles['ready-text']}>
              If you're a company or non-profit looking to provide mental health
              support, we can help!
            </p>
            <WellButtonLink
              to={'/partners'}
              className={styles['ready-btn']}
              btnText={'LEARN MORE'}
              type={'well-btn-orange'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
