import { adminConstants } from '_constants';
import { adminService } from '_services';
import { adminUserActions, adminSessionsActions } from '_actions';
import { AppDispatch } from '_reducers';

export const adminGeneralActions = {
  getAdminDashboard,
  getFlaggedData,
  updateCurrentView,
};

function getAdminDashboard(
  period: 'day' | 'month' | 'week' | 'year',
  limit: number,
  offset: number,
  isNew: boolean
) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await adminService.getAdminDashboard(
        period,
        limit,
        offset
      );
      if (response[period]) {
        let headers = response[period][0];
        let data = response[period];
        data.shift();

        if (isNew)
          dispatch({
            type: adminConstants.GET_DASHBOARD_DATA,
            headers: headers,
            data: data,
            period: period,
            isOver: data.length < limit ? true : false,
            offset: limit,
          });
        else {
          dispatch({
            type: adminConstants.ADD_TO_DASHBOARD_DATA,
            data: data,
            offset: limit,
            isOver: data.length < limit ? true : false,
          });
        }

        return Promise.resolve(1);
      }

      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function getFlaggedData(limit: number, offset: number) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await adminService.getFlaggedData(limit, offset);
      if (response.activity) {
        let data = response.activity;

        return Promise.resolve(data);
      }
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function updateCurrentView(newView: string) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: adminConstants.UPDATE_CURRENT_VIEW, newView: newView });
  };
}
