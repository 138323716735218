import { handleFetchReturn, failedToFetch } from '..';

export const userTestimonialService = {
  createTestimonial,
  deleteTestimonial,
  listTestimonials,
  makeTestimonialPublic,
};

//uuid: user uuid
//testimonial: testimonial text
export function createTestimonial(session, thumbs, postToForum, value) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      thumbs,
      post_to_forum: postToForum,
      value: JSON.stringify(value),
    }),
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/' +
      session +
      '/testimonial/create',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

//testimonial uuid
export function deleteTestimonial(uuid) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/testimonial/' + uuid + '/delete',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

/**
 * Lists the testimonials for a user
 * @param {boolean} isSelf - if looking at cur user PRIVATE testimonials are returned
 * @returns
 */
export function listTestimonials(uuid, limit, offset, isSelf) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  if (!isSelf)
    return fetch(
      process.env.REACT_APP_API_URL +
        '/user/' +
        uuid +
        '/testimonial/list?limit=' +
        limit +
        '&offset=' +
        offset,
      requestOptions
    )
      .then((response) => handleFetchReturn(response))
      .catch((error) => failedToFetch(error));

  return fetch(
    process.env.REACT_APP_API_URL +
      '/testimonial/list?limit=' +
      limit +
      '&offset=' +
      offset,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function makeTestimonialPublic(uuid) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/testimonial/' + uuid + '/makePublic',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
