import { Room } from 'twilio-video';
import { useEffect } from 'react';
import { useWellDispatch, useWellSelector } from '_hooks';
import { roomActions } from '_actions';
import { ESessionRoomStatus } from '_types';

export default function useHandleRoomChange(room: Room | null) {

  const { userStatus } = useWellSelector(state => state.roomStore);
  const dispatch = useWellDispatch();

  useEffect(() => {
    const disconnect = async () => {
      dispatch(roomActions.clearTokenInfo());
      room?.disconnect();
    };

    if (
      room &&
      room.state === 'connected' &&
      userStatus !== ESessionRoomStatus.IN_ROOM
    ) {
      disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus, dispatch]);
}
