import { useCallback, useState } from 'react';

import styles from './Footer.module.css';

import { Link } from 'react-router-dom';
import { useGetImgixVersion, useShowFooter } from '_hooks';
import Logo from '_images/logo-long-white.svg';
import { useDispatch } from 'react-redux';
import { generalActions } from '_actions';
import { WellInputWrapper } from '_components';
import { WellCheckbox } from '_components/WellItems';

export const Footer = () => {
  const shouldShow = useShowFooter();

  if (!shouldShow) return null;

  return (
    <footer className={styles['well-footer']}>
      <div className={styles['well-footer-container']}>
        <FooterTop />

        <div className={styles['white-line']}></div>

        <FooterBottom />
      </div>
    </footer>
  );
};

const FooterTop = () => {
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const becomeHost = useCallback(() => {
    if (submitted || !email) return;

    if (!isChecked) {
      setError('Please verify your age.');
      return;
    }
    if (email.toString().match(/^\S+@\S+\.\S+$/) === null) {
      setError('Invalid email.');
      return;
    }

    dispatch(
      generalActions.setOrAppendMarketingData(
        'append',
        'host_email_capture',
        JSON.stringify({ email }),
      ),
    );

    setEmail('');
    setSubmitted(true);
  }, [dispatch, email, isChecked, submitted]);

  return (
    <div className={styles['footer-top-row']}>
      <div className={'col-xl-3 col-lg-3 ' + styles['logo-col']}>
        <img
          src={Logo}
          className={styles['sharewell-logo']}
          alt={'ShareWell'}
        />
        <p className={styles['mission']}>
          Our vision is a world where everyone has access to the support they
          need when they need it through social wellness.
        </p>
      </div>

      <div className={'col-xl-2 col-lg-3 col-sm-12 ' + styles['footer-col']}>
        <FooterItem text={'Homepage'} link={'/homepage'} />
        <FooterItem text={'How It Works'} link={'/how-it-works'} />
        <FooterItem text={'About Us'} link={'/about-us'} />
        <FooterItem text={'Contact Us'} link={'/contact-us'} />
      </div>

      <div className={'col-xl-2 col-lg-3 col-12 ' + styles['footer-col']}>
        <FooterItem text={'Hosts'} link={'/meet-hosts'} />
        <FooterItem text={'Host Training'} link={'/training'} />
        <FooterItem text={'Safety Policy'} link={'/safety-policy'} />
        <FooterItem text={'Safety Resources'} link={'/safety-resources'} />
      </div>

      <div className={'col-xl-2 col-lg-3 col-12 ' + styles['footer-col']}>
        <FooterItem text={'Redeem Code'} link={'/redeem'} />
        {/*}<div className={styles['footer-item']}>
          <a
            href={'https://blog.sharewellnow.com/'}
            className={styles['footer-item-link']}
            target={'_blank'}
            rel="noreferrer"
          >
            Blog
          </a>
        </div>*/}
        <FooterItem text={'Partners'} link={'/partners'} />
        <FooterItem text={'Buy a Gift'} link={'/gift'} />

        <FooterItem text={'Glossary'} link={'/glossary'} />
      </div>

      <div className={'col-xl-3 col-lg-4 col-12 ' + styles['footer-col']}>
        {submitted ? (
          <div className={styles['input-container']}>
            Thank you! Your email has been received!
          </div>
        ) : (
          <div className={styles['input-container']}>
            <WellInputWrapper
              label={'Become a Host on ShareWell'}
              labelStyle={{ color: 'white' }}
            >
              <>
                <input
                  name={'email'}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Your email here'
                  aria-label='Email'
                  value={email}
                  maxLength={50}
                  autoFocus={false}
                  style={{
                    backgroundColor: 'rgb(112, 181, 244)',
                    border: '1px solid white',
                    color: 'white',
                  }}
                />
                <div className={styles['submit-button']} onClick={becomeHost}>
                  Submit
                </div>
              </>
            </WellInputWrapper>
            {error && <span className={styles['error']}>{error}</span>}

            <WellCheckbox
              checkItem={isChecked}
              handleChange={() => setIsChecked(!isChecked)}
              label={
                'By checking this box, you agree that you are at least 18 years of age.'
              }
              labelStyle={{
                color: 'white',
                fontSize: '12px',
                top: '-3px',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const FooterBottom = () => {
  return (
    <div className={styles['footer-item-bottom']}>
      <div className={styles['bottom-row']}>
        <Link to='/terms-and-conditions' className={styles['footer-item-link']}>
          Terms & Conditions
        </Link>

        <span className={styles['divider']}> | </span>

        <Link to='/privacy-policy' className={styles['footer-item-link']}>
          Privacy Policy
        </Link>
      </div>

      <div className={styles['bottom-row']}>
        <div className={styles['rights']}>
          @2023 ShareWell Labs Co. All Rights Reserved.
        </div>
        <div className={styles['social-row']}>
          <SocialIcon
            img={
              process.env.REACT_APP_ASSETS_URL +
              '/site/footer/social-icons/facebook.svg'
            }
            link={'https://www.facebook.com/sharewellpeersupport/'}
          />

          <SocialIcon
            img={
              process.env.REACT_APP_ASSETS_URL +
              '/site/footer/social-icons/twitter.svg'
            }
            link={'https://twitter.com/sharewellnow'}
          />

          <SocialIcon
            img={
              process.env.REACT_APP_ASSETS_URL +
              '/site/footer/social-icons/youtube.svg'
            }
            link={'https://www.youtube.com/channel/UCgHppNbD_2dmznWsjpO6DLQ'}
          />

          <SocialIcon
            img={
              process.env.REACT_APP_ASSETS_URL +
              '/site/footer/social-icons/linkedin.svg'
            }
            link={'https://www.linkedin.com/company/sharewellnow'}
          />

          <SocialIcon
            img={
              process.env.REACT_APP_ASSETS_URL +
              '/site/footer/social-icons/instagram.svg'
            }
            link={'https://www.instagram.com/sharewellnow/'}
          />

          <SocialIcon
            img={
              process.env.REACT_APP_ASSETS_URL +
              '/site/footer/social-icons/mail.png'
            }
            link={'mailto:support@sharewellnow.com'}
          />
        </div>
      </div>
    </div>
  );
};

const SocialIcon = ({ img, link }: { img: any; link: string }) => {
  const icon = useGetImgixVersion(img, { h: 60, w: 60 });

  return (
    <div className={styles['social-icon-container']}>
      <a href={link} target='_blank' rel='noreferrer'>
        <img
          src={icon}
          className={styles['social-icon']}
          alt={'Social Media Icon'}
        />
      </a>
    </div>
  );
};

const FooterItem = ({ text, link }: { text: string; link: string }) => {
  return (
    <div className={styles['footer-item']}>
      <Link to={link} className={styles['footer-item-link']}>
        {text}
      </Link>
    </div>
  );
};
