import { componentsConstants } from '_constants';
import { AnyAction } from 'redux';
import { EQuizAnswers, ISession } from '_types';

interface IComponentsStore {
  menuData: { isTransparent: boolean; isOnlyShowOnHover: boolean };
  modalData: { isOpen: boolean };
  newModal: {
    flags: number; // flags are used as watcher to invoke call backs, see useModalFlagWatcher
    isRegisterModalOpen: {
      isOpen: boolean;
      redirect: string;
    };
    isGroupJoinModalOpen: boolean | { group: string; session?: boolean };
    isBasicRegisterCtaModalOpen: false | string;
    isSessionJoinModalOpen: false | ISession; // this is either boolean or a session (need data)
    isHostTrainingModalOpen: boolean;
    isExternalSessionModalOpen: false | string; // if sting is not blank then it is iopen
    isSessionVideoNoticeModalOpen: {
      isOpen: boolean;
      src: string;
      btnText: string;
      btnHref: string;
    };
    isReceivedFreeMonthModalOpen: boolean;
  };
  homepageQuizSelection: EQuizAnswers;
}

let defaultState: IComponentsStore = {
  menuData: {
    isTransparent: false,
    isOnlyShowOnHover: false,
  },

  modalData: {
    isOpen: false,
  },
  // global modals
  newModal: {
    flags: 0, // start with no flags
    isRegisterModalOpen: {
      isOpen: false,
      redirect: '',
    },
    isGroupJoinModalOpen: false,
    isBasicRegisterCtaModalOpen: false,
    isSessionJoinModalOpen: false,
    isHostTrainingModalOpen: false,
    isSessionVideoNoticeModalOpen: {
      isOpen: false,
      src: '',
      btnText: '',
      btnHref: '',
    },
    isExternalSessionModalOpen: false,
    isReceivedFreeMonthModalOpen: false,
  },
  homepageQuizSelection: EQuizAnswers.general,
};

export function componentsStore(state = defaultState, action: AnyAction) {
  switch (action.type) {
    ///// Menu //////////////////////////////////////////////////////
    case componentsConstants.SET_MENU_IS_TRANSPARENT:
      return {
        ...state,
        menuData: {
          ...state.menuData,
          isTransparent: action.isTransparent,
        },
      };
    case componentsConstants.SET_MENU_ONLY_SHOW_ON_HOVER:
      return {
        ...state,
        menuData: {
          ...state.menuData,
          isOnlyShowOnHover: action.isOnlyShowOnHover,
        },
      };

    ///// MODAL //////////////////////////////////////////////////////
    case componentsConstants.OPEN_MODAL:
      return {
        ...state,
        modalData: {
          ...action.settings,
          isOpen: true,
        },
      };

    case componentsConstants.CLOSE_MODAL:
      return {
        ...state,
        modalData: {
          isOpen: false,
        },
      };

    case componentsConstants.TOGGLE_NEW_MODAL:
      return {
        ...state,
        newModal: {
          ...state.newModal,
          isWelcomeOpen: action?.isWelcomeOpen ? action.isWelcomeOpen : false,
          isRegisterModalOpen: action?.isRegisterModalOpen
            ? action.isRegisterModalOpen
            : false,
          isGroupJoinModalOpen: action?.isGroupJoinModalOpen
            ? action.isGroupJoinModalOpen
            : false,
          isBasicRegisterCtaModalOpen: action?.isBasicRegisterCtaModalOpen
            ? action.isBasicRegisterCtaModalOpen
            : false,
          isSessionJoinModalOpen: action?.isSessionJoinModalOpen
            ? action.isSessionJoinModalOpen
            : false,
          isHostTrainingModalOpen: action?.isHostTrainingModalOpen
            ? action.isHostTrainingModalOpen
            : false,
          isSessionVideoNoticeModalOpen: action?.isSessionVideoNoticeModalOpen
            ? action.isSessionVideoNoticeModalOpen
            : false,
          isExternalSessionModalOpen: action?.isExternalSessionModalOpen
            ? action.isExternalSessionModalOpen
            : false,
          isReceivedFreeMonthModalOpen: action?.isReceivedFreeMonthModalOpen
            ? action.isReceivedFreeMonthModalOpen
            : false,
        },
      };

    case componentsConstants.SET_UNSET_FLAG_NEW_MODAL:
      // this is because the bitwise operators change the number itself, and we do not want to alter state like that
      let curFlags = state.newModal.flags;
      return {
        ...state,
        newModal: {
          ...state.newModal,
          flags: action.set
            ? (curFlags |= action.flag)
            : (curFlags &= ~action.flag),
        },
      };

    ///// OTHER //////////////////////////////////////////////////////
    case componentsConstants.SET_HOMEPAGE_QUIZ_SELECTION:
      return {
        ...state,
        homepageQuizSelection: action.homepageQuizSelection,
      };

    default:
      return state;
  }
}
