import styles from './CustomChannel.module.css';
import { MessageList, MessageInput } from 'stream-chat-react';

import { ChannelHeader, CustomMessage, CustomChannelInput } from '_components';

import { useWellChat } from '_hooks';

export const CustomChannel = () => {
  const { overrideSubmitHandler } = useWellChat();

  return (
    <div className={styles['messages-list-container']}>
      <ChannelHeader />
      <MessageList Message={CustomMessage} />
      <MessageInput
        Input={CustomChannelInput}
        overrideSubmitHandler={overrideSubmitHandler}
      />
    </div>
  )

}
