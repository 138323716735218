import useStyles from './AlternatingColumnsStyles';
import { useTheme } from '@material-ui/core/styles';

import { SectionWrapper } from '../Wrappers/SectionWrapper';

export const AlternatingColumns = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <SectionWrapper>
      <div>
        <div className={classes.sectionOne}>
          <div className={classes.topSection}>
            <div className={classes.first}>
              <h3>What is Peer Support?</h3>
              <p className={classes.noMarginBottom}>
                Peer support is when people connect over shared challenges and
                use their experiences to help each other heal. You have likely
                heard of Alcoholics Anonymous, that's peer support. At
                ShareWell, our support covers a wide variety of mental health
                challenges.
              </p>
            </div>

            <div className={classes.imageContainerOne}>
              <img
                src={
                  'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/girl-at-computer.svg'
                }
                alt={'Girl with computer'}
                className={classes.image}
              />
            </div>
          </div>
          <p>
            Participating in peer support provides connection and support to
            help anyone on their emotional healing and growth journey. At
            ShareWell we strive to create a world where everyone has access to
            the support they need, when they need it!
          </p>
        </div>

        <div className={classes.sectionTwo}>
          <div className={classes.topSectionTwo}>
            <div className={classes.imageContainerTwo}>
              <img
                src={
                  'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/book.svg'
                }
                alt={'Make money with ShareWell'}
                className={classes.image}
              />
            </div>

            <div className={classes.second}>
              <h3>What is the Host Training?</h3>
              <p>
                ShareWell's Host Training equips people passionate about peer
                support with the knowledge and tools needed to run a support
                group on a topic of their choice. It's a chance to help others
                while healing yourself.
              </p>
              <p className={classes.noMarginBottom}>
                This host training program is designed for new and seasoned
                hosts alike– to sharpen their peer support skills and lean how
                to be more effective facilitators and conduits of healing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
