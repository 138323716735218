import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSessionsActions } from '_actions';
import {
  getNowStringFormat,
  getFutureStringFormat,
  getPastStringFormat,
} from '_helpers';
import { ISession } from '_types';

// paginated another user's sessions
export const useHostedSessionsPagination = (
  user: string,
  isPast: boolean,
  paginationLimit: number
) => {
  const dispatch = useDispatch();

  const [allSessions, setAllSessions] = useState<ISession[]>([]);
  const [sessionsPagination, setSessionsPagination] = useState({
    curOffset: 0,
    isDone: false,
  });
  const [isLoadingSessions, setIsLoadingSessions] = useState(true);
  const [isLoadingMoreSessions, setIsLoadingMoreSessions] = useState(false);

  /**
   * This handles the searching & initial load
   * On search phrase update sessions list
   */
  useEffect(() => {
    const search = async () => {
      if (!user) return;

      setIsLoadingSessions(true);

      let response: any;
      if (isPast) {
        response = await dispatch(
          userSessionsActions.getHostedSessions(
            user,
            paginationLimit,
            0,
            getPastStringFormat(),
            getNowStringFormat(),
            'DESC'
          )
        );
      } else {
        response = await dispatch(
          userSessionsActions.getHostedSessions(
            user,
            paginationLimit,
            0,
            getNowStringFormat(),
            getFutureStringFormat(),
            'ASC'
          )
        );
      }

      if (response) {
        setAllSessions(response);
        setSessionsPagination({
          curOffset: sessionsPagination.curOffset + paginationLimit,
          isDone: response.length === 0
        });
      }
      setIsLoadingSessions(false);
    };

    search();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPast, user]);

  // loads more sessions
  const loadMore = async () => {
    if (sessionsPagination.isDone || isLoadingMoreSessions) return false;
    setIsLoadingMoreSessions(true);

    let response: any;
    if (isPast) {
      response = await dispatch(
        userSessionsActions.getHostedSessions(
          user,
          paginationLimit,
          sessionsPagination.curOffset,
          getPastStringFormat(),
          getNowStringFormat(),
          'DESC'
        )
      );
    } else {
      response = await dispatch(
        userSessionsActions.getHostedSessions(
          user,
          paginationLimit,
          sessionsPagination.curOffset,
          getNowStringFormat(),
          getFutureStringFormat(),
          'ASC'
        )
      );
    }

    if (response) {
      setAllSessions(allSessions.concat(response));
      setSessionsPagination({
        curOffset: sessionsPagination.curOffset + paginationLimit,
        isDone: response.length === 0
      });
    }

    setIsLoadingMoreSessions(false);
  };


  return {
    //state
    allSessions,
    sessionsPagination,
    isLoadingSessions,
    isLoadingMoreSessions,

    //functions
    loadMore,
  };
};
