import { createContext, Dispatch, SetStateAction } from 'react';
import { useForumState } from '_hooks';

interface IForumContext {
  pinnedActivities: any;

  group: string;
  activities: any;
  isMore: boolean;
  setActivities: Dispatch<SetStateAction<any[]>>;
  isLoading: boolean;
  isOver: boolean;

  //functions
  reloadActivity: (uuid: string, isPinned: boolean | undefined) => void;
  removeActivity: (uuid: string) => void;
  getActivities: () => void;
  getNewActivities: () => void;
  tagUsers: (post: string, postContent: string, taggedUsers: any) => void;
  reportActivity: (uuid: string) => void;
  pinPost: (post: string) => void;
  resetPinPosts: (post: string) => void;

}

export const ForumContext = createContext({} as IForumContext);

interface IForumProvider {
  group: string,
  pinnedActivities?: any,
  children: JSX.Element;
}

export const ForumProvider = ({
  group,
  pinnedActivities,
  children
}: IForumProvider) => {

  const {
    activities,
    isLoading,
    isOver,
    isMore,

    setActivities,

    //functions
    reloadActivity,
    getActivities,
    getNewActivities,
    tagUsers,
    reportActivity,
    removeActivity,
    pinPost,
    resetPinPosts,
  } = useForumState(group, (pinnedActivities ? pinnedActivities : null));


  return (
    <ForumContext.Provider
      value={{
        //pinned activities
        pinnedActivities,

        group, //the uuid of the group
        //state
        activities,
        isMore,
        setActivities,
        isLoading,
        isOver,

        //functions
        reloadActivity,
        removeActivity,
        getActivities,
        getNewActivities,
        tagUsers,
        reportActivity,
        pinPost,
        resetPinPosts,
      }}
    >
      {children}
    </ForumContext.Provider>
  );
};
