import { useState, useEffect } from 'react';
import { sessionsActions } from '_actions';
import { isEmpty } from '_helpers';
import { IUser, ReturnNotice } from '_types';
import { useWellDispatch, useWellSelector } from '_hooks';

export const useSessionInfoLoad = (pathname: string, params: any) => {
  const dispatch = useWellDispatch();

  const { sessionsStore } = useWellSelector((state) => state);
  const { sessionInfo } = sessionsStore;

  const [isLoading, setIsLoading] = useState(true); // initial load
  const [disallowClick, setDisallowClick] = useState(false); // loading action
  const [show404, setShow404] = useState<0 | 1 | 2>(0); // show the 404
  const [showMetrics, setShowMetrics] = useState(false); // show metrics
  const [isBlocked, setIsBlocked] = useState(false); // did a user block me?

  useEffect(() => {
    const start = async () => {
      setShow404(0); // start by removing the 404 if was showing

      let { seriesUuid, sessionUuid } = params;

      try {
        let session;
        if (seriesUuid)
          session = await dispatch(sessionsActions.getSeriesInfo(seriesUuid));
        else
          session = await dispatch(sessionsActions.getSessionInfo(sessionUuid));

        if (session && session.uuid) setIsLoading(false);
        else if (
          session &&
          session.id &&
          session.id === ReturnNotice.SESSION_DELETED.getId()
        )
          setShow404(1);
        else setShow404(2);
      } catch (error) {
        // catch current errors
        setShow404(2);
      }
    };

    start();

    return () => {
      setIsLoading(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (isEmpty(sessionInfo)) return;
    console.log('sessionInfo:', sessionInfo);

    if (!sessionInfo.joined_users) setShowMetrics(false);
    else setShowMetrics(true);

    if (!sessionInfo.am_i_joined) {
      // check host
      if (sessionInfo.host_user.blocked) setIsBlocked(true);

      // checked if I am blocked by anyone
      if (sessionInfo.joined_users)
        sessionInfo.joined_users.map(function (user: IUser) {
          if (user.blocked) setIsBlocked(true);
          return false;
        });
    }
  }, [sessionInfo]);

  return {
    isLoading,
    setIsLoading,
    show404,
    setShow404,
    disallowClick,
    setDisallowClick,
    showMetrics,
    isBlocked,
  };
};
