import { useContext } from 'react';

import { SessionsListContext } from '../../Sessions';

export const useSessionsList = () => {
  const context = useContext(SessionsListContext);
  if (context === undefined) {
    throw new Error('useSessionsList must be used within a SessionsListContext');
  }
  return context;
};

