import { generalConstants } from '_constants';
import { generalService } from '_services';
import { AppDispatch } from '_reducers';

export const generalActions = {
  setServerConfig,
  updateServerTimeOffset,

  //Marketing data
  setOrAppendMarketingData,
  loadMarketingData,
};

function setServerConfig() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await generalService.setServerConfig();
      dispatch({ type: generalConstants.SET_SERVER_CONFIG, config: response });
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function updateServerTimeOffset(epoch: any) {
  if (!epoch) return;
  return (dispatch: AppDispatch) => {
    dispatch({ type: generalConstants.SET_SERVER_TIME_OFFSET, epoch: epoch });
  };
}

/**
 * Sets or appends a value to the marketing dump for the key
 * If set it overwrites the current data
 *
 * @param {string} type - set or append
 * @param {string} key  - key to use
 * @param {string} value - value
 * @returns
 */
function setOrAppendMarketingData(type: any, key: any, value: any) {
  return async () => {
    try {
      await generalService.setOrAppendMarketingData(type, key, value);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function loadMarketingData(key: any) {
  return async () => {
    try {
      const response = await generalService.loadMarketingData(key);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
