import * as React from 'react';
import wellLinkify from './WellLinkifyIt';

export const LinkifyRedux = ({ children }: { children: JSX.Element }) => {

  const isMatch = (text: string) => {
    return wellLinkify.match(text);
  };

  const parseString = (string: string) => {
    if (string === '') {
      return string;
    }

    const matches = isMatch(string);
    if (!matches) {
      return string;
    }

    const elements = [];
    let lastIndex = 0;
    matches.forEach((match, i) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }

      const decoratedHref = defaultHrefDecorator(match.url);
      const decoratedText = defaultTextDecorator(match.text);
      const decoratedComponent = defaultComponentDecorator(
        decoratedHref,
        decoratedText,
        i
      );
      elements.push(decoratedComponent);

      lastIndex = match.lastIndex;
    });

    // Push remaining text if there is any
    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  };

  const parse = (children: any, key: number = 0): any => {
    if (typeof children === 'string') {
      return parseString(children);
    } else if (
      React.isValidElement(children) &&
      children.type !== 'a' &&
      children.type !== 'button'
    ) {
      let hold: any = children
      return React.cloneElement(
        children,
        { key: key },
        parse(hold.props?.children)
      );
    } else if (Array.isArray(children)) {
      return children.map((child, i) => parse(child, i));
    }

    return children;
  };

  return <React.Fragment>{parse(children)}</React.Fragment>;
};

const defaultComponentDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
) => {
  return (
    <a href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
};

const defaultHrefDecorator = (href: string) => {
  return href;
};

const defaultTextDecorator = (text: string) => {
  return text;
};
