import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './PollItem.module.css';
import {
  Panel,
  PanelHeading,
  PanelContent,
  PanelFooter,
  Avatar,
  WellLoader,
} from '_components';
import { useForumPoll } from '_hooks';
import { useSelector } from 'react-redux';
import { RootState } from '_reducers';
import CheckMark from '_images/check-mark-black.svg';

export const PollItem = () => {
  // polling system that was set up
  const {
    pollText,
    pollAnswers,
    pollHasBeenAnswered,
    pollStatus,
    submitPollAnswer,
  } = useForumPoll();

  return (
    <Panel>
      <PanelHeading>
        <div className={styles['header-container']}>
          <Link to={'/profile/sharewell'} className={styles['user-link']}>
            <Avatar
              pic={process.env.REACT_APP_ASSETS_URL + '/site/common/logo.svg'}
              isSmall={false}
            />
          </Link>
          <div className={styles['header-text']}>
            <Link to={'/profile/sharewell'} className={styles['user-link']}>
              <h4 className={styles['post-username']}>{'ShareWell'}</h4>
            </Link>
          </div>
        </div>
      </PanelHeading>

      <div
        className={
          styles['item-container'] +
          ' ' +
          (pollStatus.isLoading && styles['is-loading'])
        }
      >
        <PanelContent>
          {pollStatus.isLoading ? (
            <WellLoader heightWidth={125} />
          ) : (
            <>
              <h3 className={styles['poll-question']}>
                <b>Poll</b>: {pollText.question}
              </h3>

              <div className={styles['poll-answers-container']}>
                {pollHasBeenAnswered !== false ? (
                  <>
                    {Object.entries(pollText).map(([key, value]) => {
                      if (key === 'question' || key === 'answerKey' || !value)
                        return (
                          <React.Fragment
                            key={'unused-' + key}
                          ></React.Fragment>
                        );

                      return (
                        <AnsweredItem
                          key={'poll-answer-' + key}
                          text={value as string}
                          answerKey={key as string}
                          isSubmittedAnswer={
                            pollHasBeenAnswered == key ? true : false
                          }
                          pollAnswers={pollAnswers}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    {Object.entries(pollText).map(([key, value]) => {
                      if (key === 'question' || key === 'answerKey' || !value)
                        return (
                          <React.Fragment
                            key={'unused-' + key}
                          ></React.Fragment>
                        );

                      return (
                        <PollAnswer
                          key={'poll-answer-' + key}
                          text={value as string}
                          answerKey={key}
                          submitPollAnswer={submitPollAnswer}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </>
          )}
        </PanelContent>
        <PanelFooter>
          {/* <div className={styles['num-responses']}>
            {totalPollAnswers} {totalPollAnswers === 1 ? <>Response</> : <>Responses</>}
          </div> */}
        </PanelFooter>
      </div>
    </Panel>
  );
};

const PollAnswer = ({
  text,
  answerKey,
  submitPollAnswer,
}: {
  text: string;
  answerKey: string;
  submitPollAnswer: (user: string, answer: string) => Promise<void>;
}) => {
  const { userStore } = useSelector((state: RootState) => state);
  const { userData } = userStore;

  const submitAnswer = useCallback(() => {
    submitPollAnswer(userData.uuid, answerKey);
  }, [answerKey, submitPollAnswer, userData.uuid]);

  return (
    <div className={styles['poll-answer']} onClick={submitAnswer}>
      <h4 className={styles['answer-text']}>{text}</h4>
    </div>
  );
};

interface IAnswer {
  text: string;
  answerKey: string;
  isSubmittedAnswer: boolean;
  pollAnswers: any;
}
const AnsweredItem = ({
  text,
  answerKey,
  isSubmittedAnswer,
  pollAnswers,
}: IAnswer) => {
  // typescript being poop
  let numAnswers = 0;
  if (answerKey === 'answerOne') numAnswers = pollAnswers['answerOne'];
  if (answerKey === 'answerTwo') numAnswers = pollAnswers['answerTwo'];
  if (answerKey === 'answerThree') numAnswers = pollAnswers['answerThree'];
  if (answerKey === 'answerFour') numAnswers = pollAnswers['answerFour'];
  if (answerKey === 'answerFive') numAnswers = pollAnswers['answerFive'];

  let totalPollAnswers =
    pollAnswers['answerOne'] +
    pollAnswers['answerTwo'] +
    pollAnswers['answerThree'] +
    pollAnswers['answerFour'] +
    pollAnswers['answerFive'];

  let fillIn = 100 - (numAnswers / totalPollAnswers) * 100;

  return (
    <div className={styles['answered']}>
      <div
        className={styles['bar']}
        style={{ width: `calc(100% - ${fillIn}%)` }}
      ></div>
      <h4 className={styles['answer-text']}>
        {text}
        {isSubmittedAnswer && (
          <span className={styles['check-container']}>
            <img
              src={CheckMark}
              className={styles['check-mark']}
              alt={'Check Mark'}
            />
          </span>
        )}
      </h4>
      <h4 className={styles['percent']}>
        {((numAnswers / totalPollAnswers) * 100).toFixed(0)}%
      </h4>
    </div>
  );
};
