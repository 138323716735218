import { handleFetchReturn, failedToFetch } from '.';
import { ISubscriptionUpdate, IPurchaseSubscriptionGift } from '_types';

export const stripeService = {
  getCards,
  sendCardInfo,
  updateSubscription,
  getSubscriptionActivity,
  claimDiscountCode,
  listSubscriptionTypes,
  purchaseSubscriptionGift,
};

// retrieve a user's stripe credit cards
export function getCards() {
  return fetch(process.env.REACT_APP_API_URL + '/payment/billing/list', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// send a token to the server to be added to the user's cards
export function sendCardInfo(token: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/payment/billing/update?stripe_token=' +
      token,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function updateSubscription(
  subscriptionUpdateRequest: ISubscriptionUpdate
) {
  return fetch(process.env.REACT_APP_API_URL + '/account/subscribe/update', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(subscriptionUpdateRequest),
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getSubscriptionActivity() {
  return fetch(
    process.env.REACT_APP_API_URL + '/account/subscription/activity',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function claimDiscountCode(code: string, claim: boolean) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/payment/claim_discount_code/' +
      code +
      '/' +
      claim,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function listSubscriptionTypes() {
  return fetch(
    process.env.REACT_APP_API_URL + '/payment/subscription_types/list',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function purchaseSubscriptionGift(
  subscriptionType: number,
  purchaseGiftRequest: IPurchaseSubscriptionGift
) {
  return fetch(process.env.REACT_APP_API_URL + '/payment/purchase_gift/' + subscriptionType, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(purchaseGiftRequest),
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
