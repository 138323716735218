import { useEffect, useState } from 'react';
import styles from './CustomListItem.module.css';
import { useGetImgixVersion, useWellChat } from '_hooks';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import { GroupBadge } from '_components';

export const CustomListItem: React.FC<ChannelPreviewUIComponentProps> = (props) => {
  const {
    channel,
  } = props;

  const { setCurChannel } = useWellChat();

  const [unreadCount, setUnreadCount] = useState<number>(channel.state.unreadCount);

  useEffect(() => {

    const listener = channel.on((event) => {
      if (event.type === 'message.new')
        setUnreadCount(channel.countUnread());
    });

    return () => {
      listener.unsubscribe();
    }

  }, [channel]);


  const sessionPic = useGetImgixVersion(channel?.data?.image, {
    h: 40,
    w: 40,
    fit: 'crop',
    crop: 'focalpoint',
  });


  const showUnreadCount = channel.state.unreadCount && channel.state.unreadCount > 0 ? true : false;
  const latestMessage = channel.state.messages &&
    channel?.state?.messages.length > 0 ? channel.state.messages[channel.state.messages.length - 1].text : 'Nothing yet...';

  return (
    <button
      className={styles['list-item']}
      onClick={() => {
        setCurChannel(channel)
      }}
    >
      <div className={styles['content-row']}>
        <div className={styles['chat-image-container']}>
          {!sessionPic ?
            <div className={styles['image-place-holder']}></div>
            :
            <img src={sessionPic} className={styles['chat-image']} alt={channel?.data?.name} />
          }
          {showUnreadCount &&
            <div className={styles['unread']}>
              <GroupBadge number={unreadCount} isLarge={false} />
            </div>
          }
        </div>
        <div className={styles['text-row']}>
          <div className={styles['title']}>{channel?.data?.name}</div>
          <div className={styles['last-message']}>{latestMessage}</div>
        </div>

      </div>
    </button>
  )

}
