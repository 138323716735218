import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';
import { useWellDispatch } from '_hooks';
import { Callback } from '../../../types';
import { roomConstants } from '_constants';
import { ESessionRoomStatus } from '_types';

export default function useHandleRoomDisconnection(
  room: Room | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  const dispatch = useWellDispatch();

  //needs better error handling here
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          dispatch({
            type: roomConstants.SET_LEAVE_STATE,
            redirectLocation: '/',
          });

          onError(error);
        }
        // removeLocalAudioTrack();
        // removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }

        dispatch({
          type: roomConstants.SET_USER_STATUS,
          userStatus: ESessionRoomStatus.NONE,
        });
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
    dispatch,
  ]);
}
