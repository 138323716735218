import { useGetImgixVersion } from '_hooks';
import { useWellChat, useWellSelector } from '_hooks';
import styles from './WaitingRoom.module.css';
import { useEffect } from 'react';
import { getServerTime, getSessionStartTime } from '_helpers';
import { WellLoader } from '_components';

export const WaitingRoom = () => {
  const { roomStore } = useWellSelector((state) => state);
  const { sessionInfo, hostIsActive, roomData } = roomStore;
  const { openToChannel } = useWellChat();

  // on opening of this page just send open the chat for the user
  useEffect(() => {
    openToChannel(sessionInfo.uuid);
  }, []);

  // image for the new watiing room
  let image = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/room/waiting-room.svg',
    {
      w: 320,
    }
  );

  // if session has started let load once to see what we show to the user
  if (
    roomData.length === 0 &&
    !sessionInfo.am_i_host &&
    getServerTime().isAfter(getSessionStartTime(sessionInfo))
  ) {
    return (
      <div>
        <WellLoader heightWidth={200} />
      </div>
    );
  }

  return (
    <>
      <div className={styles['container']}>
        {!sessionInfo.is_group_hosted ? (
          <>
            <h3>Just a moment, your session will begin once 3 peers join.</h3>

            <p>
              Waiting a few minutes? Check the Chat box <br></br>in the lower
              right corner to connect with the session host.
            </p>
          </>
        ) : (
          <>
            <h3>Your group-hosted session will begin once 3 peers join.</h3>

            <p>
              Waiting a few minutes? Check the Chat box <br></br>in the lower
              right corner to connect with your session's attendees.
            </p>
          </>
        )}

        <div className={styles['image-container']}>
          <img src={image} className={styles['image']} />
        </div>
      </div>
    </>
  );
};
