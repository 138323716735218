import { componentsConstants } from '_constants';
import { AppDispatch } from '_reducers';

export const componentActions = {
  openModal,
  closeModal,
  setIsTransparent,
  setOnlyShowOnHover,
};

function openModal(settings: any) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: componentsConstants.OPEN_MODAL, settings: settings });
  };
}

function closeModal() {
  return (dispatch: AppDispatch) => {
    dispatch({ type: componentsConstants.CLOSE_MODAL });
  };
}

function setIsTransparent(isTransparent: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: componentsConstants.SET_MENU_IS_TRANSPARENT,
      isTransparent: isTransparent,
    });
  };
}

function setOnlyShowOnHover(isOnlyShowOnHover: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: componentsConstants.SET_MENU_ONLY_SHOW_ON_HOVER,
      isOnlyShowOnHover: isOnlyShowOnHover,
    });
  };
}

