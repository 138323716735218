import { useSelector } from 'react-redux';
import { AnalyticsSendEvent } from '_helpers';

import styles from './SocialShare.module.css';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import CopyLink from '_images/copy-link-icon.png';
import EmailShare from '_images/email-share.png';

export const SocialShareSession = ({ closeModal }) => {
  const { sessionInfo } = useSelector((state) => state.sessionsStore);
  const { userData } = useSelector((state) => state.userStore);

  function sendEvent(type) {
    AnalyticsSendEvent('session_share_click', {
      session: sessionInfo.uuid,
      user: userData.uuid,
      type: type,
    });
    return Promise.resolve();
  }

  function handleCopy() {
    document.getElementById('link-text').classList.remove(styles['blink-me']);

    var copyText = document.getElementById('copy-text');

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    document.execCommand('copy');

    document.getElementById('link-text').classList.add(styles['blink-me']);
    document.getElementById('link-text').innerHTML = 'Link Copied';
  }

  return (
    <div className={styles['social-share-items'] + ' col-12'}>
      <div className={styles['social-share-row'] + ' row'}>
        <div
          className={styles['close-btn']}
          onClick={() => {
            document.getElementById('link-text').innerHTML = 'Copy Link';
            document
              .getElementById('link-text')
              .classList.remove(styles['blink-me']);
            closeModal();
          }}
        >
          &#10006;
        </div>

        <input
          type={'text'}
          className={styles['copy-text']}
          id={'copy-text'}
          value={window.location}
          onChange={() => {}}
        />

        <div className={styles['share-title'] + ' col-12'}>
          {' '}
          Share this session{' '}
        </div>

        <div className={styles['share-item'] + ' col-12'}>
          <a
            className={styles['email-link']}
            href={
              "mailto:?subject=You're invited to join a conversation on ShareWell!&body=Peers are gathering to talk about " +
              sessionInfo.title +
              ' and I thought you may want to check it out: ' +
              window.location
            }
            title="Share by Email"
            onClick={() => {
              sendEvent('email');
            }}
          >
            <img
              src={EmailShare}
              className={styles['mail-icon']}
              alt={'Mail Icon'}
            />
            <span className={styles['link-text']}>Email</span>
          </a>
        </div>

        <div className={styles['share-item'] + ' col-12'}>
          <FacebookShareButton
            className={styles['facebook-icon']}
            quote={
              'Have you heard? Peers are gathering to talk about ' +
              sessionInfo.title +
              ' @ShareWellNow Join them here: ' +
              window.location
            }
            hashtag={'#ShareWell'}
            url={window.location.href}
            beforeOnClick={() => {
              sendEvent('facebook');
            }}
          >
            <FacebookIcon size={35} round={true} />{' '}
            <span className={styles['link-text']}>Facebook</span>
          </FacebookShareButton>
        </div>

        <div className={styles['share-item'] + ' col-12'}>
          <TwitterShareButton
            className={
              styles['social-share-item'] + ' ' + styles['twitter-icon']
            }
            url={window.location.href}
            beforeOnClick={() => {
              sendEvent('twitter');
            }}
            title={
              'Have you heard? Peers are gathering to talk about ' +
              sessionInfo.title +
              ' @ShareWellNow Join them here: '
            }
            hashtags={['ShareWell', 'PeerSupport', 'ShareWellNow']}
            related={['ShareWellCo']}
          >
            <TwitterIcon size={35} round={true} />{' '}
            <span className={styles['link-text']}>Twitter</span>
          </TwitterShareButton>
        </div>

        <div className={styles['share-item'] + ' col-12'} onClick={handleCopy}>
          <img
            src={CopyLink}
            className={styles['copy-link-icon']}
            alt={'Copy Link'}
          />
          <span className={styles['link-text']} id={'link-text'}>
            Copy Link
          </span>
        </div>
      </div>
    </div>
  );
};
