
import { ActivityItem } from '_components';
import { IActivity } from '_types';


export const SinglePostForum = ({ singlePost, setShowingPost }: { singlePost: IActivity | boolean, setShowingPost: any }) => {

  if (singlePost === false || singlePost === true || !singlePost?.uuid) return null;

  return (
    <div>
      <ActivityItem
        activity={singlePost}
        key={'activity-' + singlePost?.uuid}
        isSinglePost={true}
      // outsideSetActivities={setShowingPost}
      />
    </div>
  )

}