import { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './SessionItem.module.css';
import {
  AnalyticsSendEvent,
  getSessionEndTime,
  getSessionStartTime,
} from '_helpers';
import { timeConstants, notificationConstants } from '_constants';
import moment from 'moment-timezone';
import { JoinedStatusNew } from '..';
import { useGetImgixVersion, useWellApp } from '_hooks';
import { ISession } from '_types';
import { WellTextHighlighter, WellLoader } from '_components';
import BonusBadge from '_images/bonus-badge.svg';

interface ISessionItem {
  session: ISession;
  isSquare: boolean;
  searchPhrase?: string;
  allowRegister: boolean; // this is function to reload a single session in the list
  reloadSession?: (...args: any[]) => void;
}

export const SessionItem = ({
  session,
  searchPhrase,
  isSquare,
  allowRegister,
  reloadSession,
}: ISessionItem) => {
  const sessionPic = useGetImgixVersion(session.pic, {
    h: 300,
    w: 730,
    fit: 'crop',
    crop: 'focalpoint',
  });

  // send event when the session is clicked
  const clickSession = useCallback(() => {
    AnalyticsSendEvent('session_click', { session: session.uuid });
  }, [session.uuid]);

  return (
    <div
      className={
        styles['session-item'] + ' ' + (isSquare ? styles['square'] : '')
      }
    >
      {allowRegister !== false && !session.am_i_joined && reloadSession && (
        <AllowRegister
          session={session}
          reloadSession={reloadSession}
          isSmall={false}
        />
      )}
      <Link
        to={'/' + session.group_slug + '/session/' + session.uuid}
        onClick={clickSession}
        target={process.env.REACT_APP_NODE_ENV !== 'local' ? '_blank' : ''}
        rel='noopener noreferrer'
      >
        {(session.is_group_hosted) && (
          <img
            src={BonusBadge}
            className={styles['bonus-badge']}
            alt={'Bonus check'}
          />
        )}

        <div className={styles['image-container']}>
          {!sessionPic ? (
            <div className={styles['image-placeholder']}></div>
          ) : (
            <img
              src={sessionPic}
              className={styles['image']}
              alt={session.title}
            />
          )}
        </div>

        <div className={styles['bottom']}>
          <JoinedStatusNew
            session={session}
            className={styles['status-container']}
          />

          <h3 className={styles['session-item-title']}>
            <WellTextHighlighter
              text={session.title}
              searchPhrase={searchPhrase ? searchPhrase : ''}
            />
          </h3>

          {isSquare && (
            <div className={styles['description']}>{session.description}</div>
          )}

          <SessionTime session={session} />

          <SessionHost session={session} />
        </div>
      </Link>
    </div>
  );
};

const SessionTime = ({ session }: { session: ISession }) => {
  const [sessionTime, setSessionTime] = useState(
    getSessionStartTime(session).format(timeConstants.timeFormat) +
      ' - ' +
      getSessionEndTime(session).format(timeConstants.timeFormat) +
      ' ' +
      getSessionStartTime(session).tz(moment.tz.guess()).format('z')
  );

  useEffect(() => {
    // check if session is currently in session
    if (session.in_session) {
      setSessionTime(notificationConstants.SESSION_LIVE);
    }
  }, [session.in_session]);

  const sessionStartTime = getSessionStartTime(session);
  return (
    <div
      className={
        styles['session-time-container'] +
        ' ' +
        (session.in_session ? styles['in-session'] : '')
      }
    >
      {sessionStartTime ? (
        <>
          <span className={styles['session-date']}>
            {sessionStartTime.format('ddd') +
              ', ' +
              sessionStartTime.format('MMM D')}
          </span>
          {session.status !== 'Canceled' ? (
            sessionTime === notificationConstants.SESSION_LIVE ? (
              <>
                <span className={styles['time-divider']}>|</span>
                <span className={'blink ' + styles['in-session']}>
                  {sessionTime}
                </span>
              </>
            ) : (
              <>
                <span className={styles['time-divider']}>|</span>
                {sessionTime}
              </>
            )
          ) : (
            ''
          )}
        </>
      ) : null}
    </div>
  );
};

const SessionHost = ({ session }: { session: ISession }) => {

  const { host_user } = session;

  const hostImage = useGetImgixVersion(host_user.pic, {
    h: 500,
    w: 500,
    fit: 'crop',
    crop: 'focalpoint',
  });


  // if no host then do no show creator
  if (session.is_group_hosted) return <></>;

  return (
    <div className={styles['host']}>
      <div className={styles['host-image-container']}>
        {!hostImage ? (
          <div className={styles['host-image-placeholder']}></div>
        ) : (
          <>
            <img
              src={hostImage}
              className={styles['host-image']}
              alt={session.host_user.username}
            />
          </>
        )}
      </div>
    </div>
  );
};

export const AllowRegister = ({
  session,
  reloadSession,
  isSmall,
}: {
  session: ISession;
  reloadSession: (...args: any[]) => void;
  isSmall: boolean;
}) => {
  const { registerForSession } = useWellApp();
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = async () => {
    await registerForSession(session, setIsLoading, () => {});
    reloadSession(session.uuid);
  };

  return (
    <div
      className={
        styles['register-button'] + ' ' + (isSmall ? styles['is-small'] : '')
      }
      onClick={handleRegister}
    >
      <>{isLoading ? <WellLoader /> : <>REGISTER</>}</>
    </div>
  );
};
