import { useCallback, useEffect, useState } from 'react';
import { RoomModal } from '_components';
import useVideoContext from 'SessionRoom/SessionRoomTwilio/hooks/useVideoContext';
import modalStyles from '_components/SessionRoom/RoomModal/RoomModal.module.css';
import { Button } from '@material-ui/core';
import { useWellDispatch, useWellSelector } from '_hooks';
import { history } from '_helpers';
import { ESessionRoomStatus } from '_types';
import { roomConstants } from '_constants';

export function RouterPrompt() {
  const dispatch = useWellDispatch();

  const { redirectLocation, userStatus } = useWellSelector(
    (state) => state.roomStore
  );

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const { room, removeLocalAudioTrack, removeLocalVideoTrack } =
    useVideoContext();

  useEffect(() => {
    if (
      userStatus === ESessionRoomStatus.NEEDS_TO_LEAVE &&
      redirectLocation !== ''
    ) {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
      // session survey needs the roomStore data but other then that remove it
      if (redirectLocation !== '/session-survey') {
        dispatch({ type: roomConstants.CLEAR_SESSION_ROOM_DATA });
      }
      history.push(redirectLocation);
      return;
    }

    history.block((prompt) => {
      setCurrentPath(prompt.pathname);
      setShowPrompt(true);
      return false;
    });

    return () => {
      history.block(() => {});
    };
  }, [
    redirectLocation,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    userStatus,
  ]);

  const handleOK = useCallback(async () => {
    if (room && room.state === 'connected') room.disconnect(); // this should be generic
    removeLocalAudioTrack(); // only when leaving the rooms page do we remove tracks
    removeLocalVideoTrack();
    history.block(() => {});
    history.push(currentPath);
  }, [currentPath, removeLocalAudioTrack, removeLocalVideoTrack, room]);

  const handleCancel = useCallback(async () => {
    setShowPrompt(false);
  }, []);

  return showPrompt ? (
    <RoomModal closeModal={setShowPrompt}>
      <div className={modalStyles['inner-container']}>
        <h3 className={modalStyles['inner-title']}>
          Are you sure you want to leave?
        </h3>

        <div className={modalStyles['inner-buttons-container']}>
          <Button
            variant='contained'
            className={modalStyles['inner-no-button']}
            onClick={handleCancel}
          >
            {'STAY'}
          </Button>

          <Button
            color='primary'
            variant='contained'
            className={modalStyles['inner-yes-button']}
            onClick={handleOK}
          >
            {'LEAVE'}
          </Button>
        </div>
      </div>
    </RoomModal>
  ) : null;
}
