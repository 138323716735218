import { WellButtonLink } from '_components';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    pageContainer: {
      position: 'relative',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'center',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    image: {
      maxWidth: '800px',
      margin: '0 auto',
      flexBasis: '100%',
      marginBottom: '20px',
    },
  })
);

export function Custom404({ image }: { image?: string }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageContainer + ' container'}>
        {image ? (
          <img
            src={
              process.env.REACT_APP_ASSETS_URL + '/site/static-pages/' + image
            }
            alt={'ShareWell 404'}
            className={classes.image}
          />
        ) : (
          <img
            src={
              process.env.REACT_APP_ASSETS_URL + '/site/static-pages/404.svg'
            }
            alt={'ShareWell 404'}
            className={classes.image}
          />
        )}

        <WellButtonLink
          type={'well-btn-primary'}
          to={'/'}
          btnText={'HOMEPAGE'}
        />
      </div>
    </>
  );
}
