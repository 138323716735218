export enum ESessionRoomStatus {
  NONE = 'NONE',
  IN_ROOM = 'IN_ROOM',
  IS_MOVING_ROOMS = 'IS_MOVING_ROOMS',
  NEEDS_TO_WAIT = 'NEEDS_TO_WAIT',
  NEEDS_TO_LEAVE = 'NEEDS_TO_LEAVE',
}

export enum ESessionRoomSafety {
  NONE = 'NONE',
  SAFE = 'SAFE',
  UNSAFE_HOST_ABSENT = 'UNSAFE_HOST_ABSENT', // DEPRECATED - no longer used
  UNSAFE_RULE_OF_3 = 'UNSAFE_RULE_OF_3',
}

export interface ISessionRoomData {
  session_rooms: ISessionRoom[];
  session_payload: string;
}

export interface ISessionRoom {
  room_assigned: number;
  safety: string; // this is really ERoomSafetyStatus
  users: ISessionRoomUser[];
}

export interface ISessionRoomUser {
  uuid: string;
  host: boolean;
  active: boolean;
}

export interface IPostSessionChecks {
  completed_mood_tracker: boolean;
  completed_session_or_host_review: boolean;
  show_group_hosted_survey: boolean;
  has_already_checked: boolean;
}

