import { Link } from 'react-router-dom';
import styles from './GroupItem.module.css';
import { useGetImgixVersion } from '_hooks';
import NewBadge from '_images/new-badge.svg';


export const GroupItem = ({ group, isHomepage, searchTerm }) => {
  const groupPic = useGetImgixVersion(group.pic, {
    w: 660,
    h: 400,
    fit: 'crop',
    crop: 'focalpoint',
  });

  const filterColorRender = (text) => {
    if (!searchTerm || !searchTerm.replace(/\s/g, '').length || !text)
      return text;

    const tokenPhrase = searchTerm.trim().replace(/\s\s+/g, ' '); //change all multiple spaces to single space and remove trailing spaces

    let strArr = text.split(new RegExp(`(${tokenPhrase})`, 'ig'));

    return strArr.map((ea, i) => {
      if (ea.toLowerCase() === tokenPhrase.toLowerCase()) {
        return (
          <span key={`match${i}`} className={styles['highlight-item']}>
            {ea}
          </span>
        );
      } else {
        return ea;
      }
    });
  };

  return (
    <Link
      to={
        '/well/' +
        group.title.toLowerCase().replaceAll(' ', '-') +
        '/' +
        group.uuid +
        '/sessions'
      }
      className={
        styles['group-item'] + ' ' + (isHomepage ? styles['is-homepage'] : '')
      }
    >
      <div className={styles['inside']}>
        <div className={styles['image-container']}>
          {group.pic ? (
            <img
              src={groupPic}
              className={styles['group-image']}
              alt={'Group Representation'}
            />
          ) : (
            <div className={styles['no-image']}></div>
          )}
          <svg
            className={styles['wave-svg']}
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
          >
            <path d="M-0.56,70.56 C250.00,-32.05 246.61,188.00 500.56,73.53 L500.00,150.00 L0.00,150.00 Z"></path>
          </svg>
        </div>
        <div className={styles['group-content']}>
          <h3 className={styles['title']}>{filterColorRender(group.title)}</h3>
        </div>
      </div>
    </Link>
  );
};
