import { useCallback, useEffect, useState } from 'react';
import { useWellDispatch } from '_hooks';
import { sessionsActions } from '_actions';
import { ISession } from '_types';


// if false = error, length = 0 loading
export const useGetSeriesInfoBatch = (series: string[]) => {
  const dispatch = useWellDispatch();

  const [sessions, setSessions] = useState<ISession[] | false>([]); // false if error

  const reload = useCallback(() => {
    dispatch(sessionsActions.getSeriesInfoBatch(series))
      .then((sessionsArray) => {
        if (sessionsArray.length > 0)
          return setSessions(
            sessionsArray.sort((a, b) => a.start_time > b.start_time ?  1 : -1) // order by time
          ); 
        else return Promise.reject();
      })
      .catch(() => {
        setSessions(false);
      });
  }, [dispatch, series]);

  // on creation call
  useEffect(() => {
    reload();
  }, [reload]);


  return {
    sessions,
    reload,
  }
};
