import { handleFetchReturn, failedToFetch } from '.';
import { generalConstants } from '_constants';
import { IGroupCreateRequest } from '_types';

export const groupService = {
  // general
  getGroupList,
  getGroupInfo,
  subscribeToGroup,
  reportGroup,

  // creator
  createGroup,
  updateGroup,
  updateGroupImage,
  deleteGroup,
  removeUser,

  // user
  getMyGroups,
  getUsersGroups,
  getGroupNotificationPreferences,

  // forum
  postGet,
  postList,
  postCreate,
  postEdit,
  postEditImage,
  postLike,
  postDelete,
  postReport,
  tagUser,

  // badges
  badgeReset,
};

export function getGroupList(
  search: string,
  limit: number,
  offset: number,
  sortType: string
) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/group/list/search?q=' +
      search +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&sort_type=' +
      sortType,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getGroupInfo(uuid: string) {
  return fetch(process.env.REACT_APP_API_URL + '/group/' + uuid + '/get', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function subscribeToGroup(uuid: string, action: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/group/' + uuid + '/join/' + action,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function reportGroup(uuid: string, note: string) {
  return fetch(process.env.REACT_APP_API_URL + '/group/' + uuid + '/flag', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ note }),
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function createGroup(formData: IGroupCreateRequest) {
  return fetch(process.env.REACT_APP_API_URL + '/group/create', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function updateGroup(uuid: string, formData: IGroupCreateRequest) {
  return fetch(process.env.REACT_APP_API_URL + '/group/' + uuid + '/update', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function updateGroupImage(uuid: string, picData: any) {
  let formData = new FormData();

  formData.append('filename', picData);

  return fetch(
    process.env.REACT_APP_API_URL + '/group/' + uuid + '/update/pic',
    {
      method: 'POST',
      headers: {},
      credentials: 'include',
      body: formData,
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function deleteGroup(uuid: string) {
  return fetch(process.env.REACT_APP_API_URL + '/group/' + uuid + '/delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function removeUser(group: string, user: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/group/' +
      group +
      '/user/' +
      user +
      '/kick',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getMyGroups(limit: number, offset: number) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/group/list/joined?limit=' +
      limit +
      '&offset=' +
      offset,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getUsersGroups(
  uuid: string,
  limit: number,
  offset: number,
  order: string
) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/group/list/user/' +
      uuid +
      '/created?limit=' +
      limit +
      '&offset=' +
      offset +
      '&order=' +
      order,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

interface _s {
  sessions?: boolean;
  forums?: boolean;
}
export function getGroupNotificationPreferences(
  uuid: string,
  sessions: null | boolean,
  forums: null | boolean
) {
  let sendData: _s = {};
  if (sessions !== null) sendData.sessions = sessions;
  if (forums !== null) sendData.forums = forums;

  return fetch(
    process.env.REACT_APP_API_URL + '/group/' + uuid + '/update/notification',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(sendData),
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function postGet(post: string) {
  return fetch(process.env.REACT_APP_API_URL + '/forum_post/' + post + '/get', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

/**
 */
export function postList(
  group: string,
  limit: number,
  type: string,
  marker: string,
  parent: string
) {
  let postUrl =
    process.env.REACT_APP_API_URL +
    '/forum_post/group/' +
    group +
    '/list?limit=' +
    limit;
  if (type !== 'none' && marker) postUrl += '&' + type + '=' + marker;
  if (parent) postUrl += '&parent_forum_post_uuid=' + parent;

  return fetch(postUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// creates a new post
export function postCreate(group: string, content: string, parent: string) {
  return fetch(process.env.REACT_APP_API_URL + '/forum_post/create', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      group_uuid: group,
      content: content,
      parent_uuid: parent,
    }),
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// edits a post
export function postEdit(uuid: string, content: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/forum_post/' + uuid + '/update',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        content,
        group_uuid: generalConstants.SPECIAL_UUID,
      }),
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// updates a posts image
export function postEditImage(uuid: string, picData: any) {
  let formData = new FormData();

  formData.append('filename', picData);

  return fetch(
    process.env.REACT_APP_API_URL + '/forum_post/' + uuid + '/update/pic',
    {
      method: 'POST',
      headers: {},
      credentials: 'include',
      body: formData,
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// like or unlike a post
export function postLike(post: string, emoji: string, action: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/forum_post/' +
      post +
      '/like/' +
      emoji +
      '/' +
      action,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// deletes a post
export function postDelete(uuid: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/forum_post/' + uuid + '/delete',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// reports a post
export function postReport(uuid: string, reason: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/forum_post/' + uuid + '/flag',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        note: reason,
      }),
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// tag a user
export function tagUser(post: string, user: string, action: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/forum_post/' +
      post +
      '/tag_user/' +
      user +
      '/' +
      'true',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// resets the badge for users
export function badgeReset(group: string, type: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/group/' + group + '/reset_badge/' + type,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
