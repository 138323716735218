import {
  createContext,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { sessionsActions } from '_actions';
import { SessionInfo } from '.';
import { RouteComponentProps } from 'react-router-dom';
import {
  useWellApp,
  useSessionInfoLoad,
  useSessionOptions,
  useSeriesOptions,
  useSessionPermissions,
  useSessionsInSeries,
  useWellDispatch,
  useWellSelector,
  useModalFlagWatcher,
} from '_hooks';
import { ISession, EModalFlags } from '_types';
import { isEmpty, history } from '_helpers';

interface ISessionInfoContext {
  // functions
  enterRoom: () => void;
  deleteSession: (callback: any) => Promise<void>;
  reportSession: (callback: any) => Promise<void>;
  deleteSeries: (callback: any) => void;
  updateSessionInfo: () => void;

  // state
  sessionInfo: ISession;
  sessionPermissions: any;
  isLoading: boolean;
  disallowClick: boolean;
  setDisallowClick: Dispatch<SetStateAction<boolean>>;
  show404: 0 | 1 | 2;
  showMetrics: boolean;
  sessionsInSeries: ISession[];
  isBlocked: boolean;

  // registering
  handleRegisterForSession: () => void;
  handleUnregisterForSession: () => void;
}

export const SessionInfoContext = createContext<ISessionInfoContext>(
  {} as ISessionInfoContext,
);

export const SessionInfoProvider = ({
  location,
  match,
}: RouteComponentProps<{ identifier: string }>) => {
  const dispatch = useWellDispatch();
  const { sessionsStore } = useWellSelector((state) => state);
  const { sessionInfo } = sessionsStore;

  // global functions
  const { enterSession, registerForSession, unregisterFromSession } =
    useWellApp();

  // load the current state of the session
  const {
    show404,
    isLoading,
    disallowClick,
    setDisallowClick,
    showMetrics,
    isBlocked,
  } = useSessionInfoLoad(location.pathname, match.params);

  // session & series options
  const { deleteSession, reportSession } = useSessionOptions(sessionInfo);
  const { deleteSeries } = useSeriesOptions(sessionInfo.series);

  // permissions associated with the session
  const sessionPermissions = useSessionPermissions(sessionInfo);

  // get other sessions in the series (if needed)
  const { sessionsInSeries } = useSessionsInSeries(
    sessionInfo?.series?.uuid ? sessionInfo.series.uuid : '',
    sessionInfo.uuid,
  );

  // function to update the session info after a change in state occurs
  const updateSessionInfo = useCallback(async () => {
    await dispatch(sessionsActions.getSessionInfo(sessionInfo.uuid));
  }, [dispatch, sessionInfo.uuid]);

  // when user clicks button, allow them to register for the session
  const handleRegisterForSession = useCallback(async () => {
    registerForSession(sessionInfo, setDisallowClick, updateSessionInfo);
  }, [sessionInfo, setDisallowClick, registerForSession, updateSessionInfo]);

  // when user clicks button unregister from the session
  const handleUnregisterForSession = useCallback(async () => {
    unregisterFromSession(sessionInfo, setDisallowClick, updateSessionInfo);
  }, [sessionInfo, setDisallowClick, unregisterFromSession, updateSessionInfo]);

  // enter a room
  const enterRoom = async () => {
    setDisallowClick(true);
    const response = await enterSession(sessionInfo.uuid);
    // if we fail update the session info on this page.
    if (!response) dispatch(sessionsActions.getSessionInfo(sessionInfo.uuid));
    setDisallowClick(false);
  };

  // after we join a group on this page, we always register for the session
  const groupJoinCallback = async () => {
    const newSessionInfo = await dispatch(
      sessionsActions.getSessionInfo(sessionInfo.uuid),
    );
    if (newSessionInfo?.uuid)
      // if this is a session
      registerForSession(newSessionInfo, setDisallowClick, updateSessionInfo);
  };

  // watch for modal changes in state, we are watching to register for the session
  useModalFlagWatcher(EModalFlags.HAS_UPDATED_SESSION, groupJoinCallback);

  // if user lands on this page with unregister=true then remove them from the session
  useEffect(() => {
    if (isEmpty(sessionInfo) || !location.search) return;
    if (location?.search.includes('unregister=true')) {
      if (sessionInfo.am_i_joined && !sessionInfo.am_i_host) {
        handleUnregisterForSession();
        history.replace({
          search: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo]);

  return (
    <SessionInfoContext.Provider
      value={{
        //functions
        enterRoom,
        deleteSession,
        reportSession,
        deleteSeries,
        updateSessionInfo,

        //state
        sessionInfo,
        sessionPermissions,
        isLoading,
        disallowClick,
        setDisallowClick,
        show404,
        showMetrics,
        sessionsInSeries,
        isBlocked,

        // registering
        handleUnregisterForSession,
        handleRegisterForSession,
      }}
    >
      <SessionInfo />
    </SessionInfoContext.Provider>
  );
};
