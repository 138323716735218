import styles from './SessionItemList.module.css';
import { useGetImgixVersion } from '_hooks';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { JoinedStatusNew, AllowRegister } from '..';
import { timeConstants } from '_constants';
import { ISession } from '_types';
import BonusBadge from '_images/bonus-badge.svg';

export const SessionItemList = ({
  session,
  allowRegister,
  reloadSession,
}: {
  session: ISession;
  allowRegister?: boolean;
  reloadSession?: (session: string) => void;
}) => {
  const image = useGetImgixVersion(session.pic, {
    h: 75,
    w: 290,
    fit: 'crop',
    crop: 'edge',
  });

  return (
    <div className={styles['session-item']}>
      {allowRegister !== false &&
        !session.am_i_joined &&
        reloadSession && (
          <AllowRegister
            session={session}
            reloadSession={reloadSession}
            isSmall={true}
          />
        )}

      <Link to={'/' + session.group_slug + '/session/' + session.uuid}>
        {(session.is_group_hosted) && (
            <img
              src={BonusBadge}
              className={styles['bonus-badge']}
              alt={'Bonus check'}
            />
          )}


        <div className={styles['image-container']}>
          {!image ? (
            <div className={styles['image-placeholder']}></div>
          ) : (
            <img src={image} className={styles['image']} alt={session.title} />
          )}
        </div>

        <div className={styles['bottom']}>
          <JoinedStatusNew
            session={session}
            className={styles['status-container']}
          />

          <h3 className={styles['session-item-title']}>{session.title}</h3>

          <div className={styles['session-time-container']}>
            {moment()
              .startOf('day')
              .isBefore(moment.tz(session.start_time, session.timezone)) &&
            moment()
              .endOf('day')
              .isAfter(moment.tz(session.start_time, session.timezone)) ? (
              <span className={styles['today']}>
                Today{' '}
                {moment
                  .tz(session.start_time, session.timezone)
                  .local()
                  .format(timeConstants.timeFormat)}
              </span>
            ) : (
              moment
                .tz(session.start_time, session.timezone)
                .local()
                .format('dddd, MMMM D')
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
