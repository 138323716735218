import { handleFetchReturn, failedToFetch } from '..';

export const userSessionsService = {
  getMySessions,
  getHostedSessions,
  getMyGroupsSessions,
};

/**
 * get the joined/ hosted sessions
 */
export function getMySessions(limit, offset, startTime, endTime, order) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/list/joined?limit=' +
      limit +
      '&offset=' +
      offset +
      '&start_time_from=' +
      startTime +
      '&start_time_to=' +
      endTime +
      '&order=' +
      order,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getHostedSessions(
  uuid,
  limit,
  offset,
  startDateTime,
  endDateTime,
  order
) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/list/user/' +
      uuid +
      '/hosted?limit=' +
      limit +
      '&offset=' +
      offset +
      '&start_time_from=' +
      startDateTime +
      '&start_time_to=' +
      endDateTime +
      '&order=' +
      order,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

/**
 * get the joined/ hosted sessions
 */
export function getMyGroupsSessions(limit, offset, excludeFullSessions) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/list/groups/joined?limit=' +
      limit +
      '&offset=' +
      offset +
      '&exclude_full_sessions=' +
      excludeFullSessions,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
