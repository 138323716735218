import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generateRandomId } from '_helpers';
import { groupForumActions } from '_actions';
import { resizeImage } from '_helpers';


/**
 * For File uploading
 */
export const useFileUpload = () => {

  const dispatch = useDispatch();

  const [images, setImages] = useState({ data: {}, order: [] });

  /**
   * Called by uploadNewFiles to set the correct data in the images state
   */
  const uploadNewImage = useCallback((file) => {
    const id = generateRandomId(); //set random ID for image
    setImages(({ order, data }) => {
      data[id] = { id, file, state: 'uploaded' }; //set its status to uploading
      return { data: { ...data }, order: [...order, id] };
    });

    if (FileReader) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const previewUri = event.target.result;
        if (!previewUri) return;
        setImages((prevState) => {
          if (!prevState.data[id]) return prevState;
          prevState.data[id].previewUri = previewUri;
          return { ...prevState, data: { ...prevState.data } };
        });
      };
      reader.readAsDataURL(file);
    }
  }, []);

  /**
   * Removes an image from the state
   */
  const removeImage = useCallback((id) => {
    setImages((prevState) => {
      prevState.order = prevState.order.filter((oid) => id !== oid);
      delete prevState.data[id];
      return { ...prevState };
    });
  }, []);

  /**
   * Handles files or image upload to the react component
   */
  const uploadNewFiles = useCallback(
    (files) => {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        if (file.type.startsWith('image/')) {
          uploadNewImage(file);
        }
      }
    },
    [uploadNewImage]
  );

  //upload an image to post
  const uploadImageToServer = useCallback(
    async (uuid, images) => {
      const key = images.order[0];

      return resizeImage({ file: images.data[key].file, maxSize: 6000 })
        .then(async (data) => {
          await dispatch(groupForumActions.postEditImage(uuid, data));
          return Promise.resolve(1);
        })
        .catch((err) => {
          return Promise.resolve(1);
        });
    },
    [dispatch]
  );

  return {
    //state
    images,

    //state functions
    setImages,

    //function
    uploadNewFiles,
    uploadNewImage,
    removeImage,

    //upload the image
    uploadImageToServer
  };
};
