import { stripeConstants } from '_constants';
import { AnyAction } from 'redux';
import { ISubscriptionType } from '_types';

interface IDefaultState {
  subscriptionTypes: ISubscriptionType[];
}

const defaultState: IDefaultState = {
  subscriptionTypes: [
    {} as ISubscriptionType,
    {} as ISubscriptionType,
    {} as ISubscriptionType,
  ],
};

export function stripeStore(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case stripeConstants.SET_SUBSCRIPTION_TYPES:
      return {
        ...state,
        subscriptionTypes: action.subscriptionTypes,
      };

    default:
      return state;
  }
}
