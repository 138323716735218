import { sessionsConstants } from '_constants';
import { AnyAction } from 'redux';
import { ISession } from '_types';

interface IDefaultState {
  sessionInfo: ISession | {};
  nextSession: ISession;
  showCongrats: boolean;
  sessionsCallbackList: string[];
}

let defaultState: IDefaultState = {
  sessionInfo: {}, // session info for one session
  nextSession: {} as ISession, // upcoming session for a user
  showCongrats: false, // for after session creation
  sessionsCallbackList: [],
};

export function sessionsStore(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case sessionsConstants.SET_MY_UPCOMING_SESSION:
      return {
        ...state,
        nextSession: action.nextSession,
      };

    case sessionsConstants.GET_SESSION_INFO_SUCCESS:
      return { ...state, sessionInfo: action.session };

    case sessionsConstants.CLEAR_SESSION_INFO:
      return { ...state, sessionInfo: {}, similarSessions: [] };

    case sessionsConstants.SET_SHOW_CONGRATS:
      return {
        ...state,
        showCongrats: action.showCongrats,
      };

    case sessionsConstants.SET_UNSET_SESSIONS_CALLBACK_LIST:
      let newList;
      if(action.set)
        newList = [...state.sessionsCallbackList, action.session]
      else
        newList = state.sessionsCallbackList.filter(item => item !== action.session);

      return {
        ...state,
        sessionsCallbackList: newList,
      };

    default:
      return state;
  }
}
