import styles from './LikeItem.module.css';



const emojiList = ['👍', '❤️', '😔'];


export const EmojisSelector = ({
  sendEmoji,
  unsendEmoji,
  likedItems,
  activity,
}) => {
  const handleClick = (isSent, emoji) => {
    if (!isSent) {
      sendEmoji(emoji);
    } else {
      unsendEmoji(emoji);
    }
  };

  return (
    <div className={styles['emojis-selector-container']}>
      {emojiList.map((emoji) => {
        const isSent = likedItems ? likedItems.includes(emoji) : false;
        return (
          <span
            className={
              styles['emoji-selector-item'] +
              ' ' +
              (isSent ? styles['active'] : '')
            }
            onClick={() => handleClick(isSent, emoji)}
            key={activity.uuid + '-selector-' + emoji}
          >
            {emoji}
          </span>
        );
      })}
    </div>
  );
};