import store from '_reducers';

export function AnalyticsIdentify(uuid, data) {
  if (typeof window.analytics !== 'undefined')
    window.analytics.identify(uuid, data);
}

// add data
export function AnalyticsSendEvent(eventName, data) {
  if (typeof window.analytics !== 'undefined') {
    let newData = { ...data };
    if (store) {
      const { userStore } = store.getState();

      if (userStore.loggedIn) {
        newData = {
          ...newData,
          // subscription
          is_unlimited_subscribed:
            userStore.userData.subscription_info.is_unlimited_subscribed,
          current_subscription_type:
            userStore.userData.subscription_info.current_subscription_type,
          next_subscription_type:
            userStore.userData.subscription_info.next_subscription_type,
          has_basic_session_remaining:
            userStore.userData.subscription_info.next_subscription_type,
          is_auto_renewed: userStore.userData.subscription_info.auto_renewal,

          // user data
          email: userStore.userData.email,
          username: userStore.userData.username,
          host_level: userStore.userData.host_level,

          // stats
          num_sessions_attended: userStore.userData.num_sessions_attended,
          num_sessions_hosted: userStore.userData.num_sessions_hosted,
          num_session_users_met: userStore.userData.num_session_users_met,

          is_admin: userStore.userData.is_admin,
        };
      }
    }

    window.analytics.track(eventName, newData);
  }
}

export function RedditSendEvent(eventName, data) {
  if (typeof window.rdt !== 'undefined') window.rdt('track', eventName, data);
}
