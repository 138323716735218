
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    highlight: {
      backgroundColor: 'rgba(255, 255, 0, 0.6)',
      color: 'white',
      borderRadius: '3px'
    },
  })
);


interface IWellTextHighlighter {
  searchPhrase: string;
  text: string;
}

export const WellTextHighlighter = ({ searchPhrase, text }: IWellTextHighlighter) => {

  const classes = useStyles();

  if (!searchPhrase || !searchPhrase.replace(/\s/g, '').length || !text)
    return (<>{text}</>);

  //change all multiple spaces to single space and remove trailing spaces
  const tokenPhrase = searchPhrase.trim().replace(/\s\s+/g, ' ');

  let strArr = text.split(new RegExp(`(${tokenPhrase})`, 'ig'));

  return <>{
    strArr.map((ea, i) => {
      if (ea.toLowerCase() === tokenPhrase.toLowerCase()) {
        return (
          <span key={`match${i}`} className={classes.highlight}>
            <>{ea}</>
          </span>
        );
      } else {
        return (<>{ea}</>);
      }
    })
  }</>

}
