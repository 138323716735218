import { useState, useEffect, useCallback } from 'react';
import { useWellDispatch } from '_hooks';
import { adminUserActions } from '_actions';

const PAGINATION_LIMIT = 40;

export const useUserActivity = (uuid: string) => {
  const dispatch = useWellDispatch();

  const [activity, setActivity] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activityPagination, setActivityPagination] = useState({
    curOffset: 0,
    isDone: false,
  });

  const getData = useCallback(async () => {
    if (activityPagination.isDone || isLoading) return;

    setIsLoading(true);
    dispatch(
      adminUserActions.getUserActivity(
        uuid,
        PAGINATION_LIMIT,
        activityPagination.curOffset
      )
    )
      .then((response) => {
        setActivity((prev: any) => {
          return prev.concat(response);
        });

        if (response.length < PAGINATION_LIMIT)
          setActivityPagination({
            curOffset: 0,
            isDone: true,
          });
        else 
          setActivityPagination({
            curOffset: activityPagination.curOffset + PAGINATION_LIMIT,
            isDone: false,
          });        

        setIsLoading(false);
      })
      .catch((error) => {
        setActivityPagination({
          curOffset: 0,
          isDone: true,
        });
        setIsLoading(false);
      });
  }, [activityPagination.curOffset, activityPagination.isDone, dispatch, isLoading, uuid]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activity,
    isLoading,
    getData,
  };
};
