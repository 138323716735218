import { userSessionsService } from '_services';
import { sessionsConstants } from '_constants';
import { getUTC, getFutureStringFormat } from '_helpers';
import { AppDispatch } from '_reducers';
import { ISession } from '_types';

export const userSessionsActions = {
  getMySessions, // gets sessions the user has joined
  getMyGroupsSessions, // gets ALL sessions of groups user is joined to
  getHostedSessions, // gets the hosted sessions of a user
  checkForUpcomingSession,
};

interface IGetMySessionsTimeRange {
  startTime: string;
  endTime: string;
  order: string;
}

// get all sessions user has joined
function getMySessions(
  limit: number,
  offset: number,
  timeRange: IGetMySessionsTimeRange
) {
  return async () => {
    try {
      const response = await userSessionsService.getMySessions(
        limit,
        offset,
        timeRange.startTime,
        timeRange.endTime,
        timeRange.order
      );

      if (response.sessions) {
        return Promise.resolve(response.sessions);
      }
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// get sessions of groups the user has joined
// does not require user joined the session itself
function getMyGroupsSessions(
  limit: number,
  offset: number,
  excludeFullSessions: boolean
) {
  return async () => {
    try {
      const response = await userSessionsService.getMyGroupsSessions(
        limit,
        offset,
        excludeFullSessions
      );
      if (response.sessions) {
        return Promise.resolve(response.sessions);
      }
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// might move this
function getHostedSessions(
  uuid: string,
  limit: number,
  offset: number,
  startDateTime: string,
  endDateTime: string,
  order: string
) {
  return async () => {
    try {
      const response = await userSessionsService.getHostedSessions(
        uuid,
        limit,
        offset,
        startDateTime,
        endDateTime,
        order
      );
      if (response.sessions) {
        return Promise.resolve(response.sessions);
      }
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// check for the user's next session
// the user's next session could be cancelled so need to grab a few
function checkForUpcomingSession() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await userSessionsService.getMySessions(
        5, // arbitrary number
        0,
        getUTC().toISOString(),
        getFutureStringFormat(),
        'ASC'
      );
      if (response.sessions && response.sessions.length > 0) {
        dispatch({
          type: sessionsConstants.SET_MY_UPCOMING_SESSION,
          nextSession: response.sessions.filter(
            (session: ISession) =>
              session.status !== 'Canceled' && session.status !== 'Over'
          )[0],
        });
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
}
