import CSS from 'csstype';
import styles from './WellInputWrapper.module.css';

interface IWellLabel {
  label: string;
  labelStyle?: CSS.Properties; // label style
}

// a label to be used above inputs
export const WellLabel = ({
  label,
  labelStyle,
}: IWellLabel) => {

  return (
    <label className={styles['label']} style={labelStyle}>{label}</label>
  )
}
