import styles from './NoticeModals.module.css';
import { ModalWrapper, WellButtonLink } from '_components';
import { useGetImgixVersion, useWellDispatch, useWellSelector } from '_hooks';

import { componentsConstants } from '_constants';
import { useCallback, useEffect } from 'react';
import { history } from '_helpers';

export const HostTrainingModal = () => {
  const dispatch = useWellDispatch();

  let image = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/modal/host-training-modal.svg',
    {
      w: 320,
    }
  );

  const closeModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isHostTrainingModalOpen: false,
    });
  }, [dispatch]);

  // if you change page close modal
  useEffect(() => {
    // monitor changes in history, to close modal
    const unlisten = history.listen(() => {
      closeModal();
    });
    return () => {
      unlisten();
    };
  }, [closeModal]);

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
      wrapperClassName={styles['wrapper']}
    >
      <>
        <div className={styles['main-text']}>
          <h3 className={styles['title']}>Join the ShareWell Host Training!</h3>
          <p className={styles['main-text']}>
            Did you know that ShareWell has an in-depth training program that
            you can participate in for free?
          </p>

          <div className={styles['bottom']}>
            <div className={styles['left-side']}>
              <h4>Sharpen your skills to become a better supporter</h4>
              <div className={styles['bullet-item']}>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/hosts/checkmark.svg'
                  }
                  className={styles['check']}
                  alt={'Check Mark'}
                />
                <span>Facilitation</span>
              </div>

              <div className={styles['bullet-item']}>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/hosts/checkmark.svg'
                  }
                  className={styles['check']}
                  alt={'Check Mark'}
                />
                <span>Emotional Safety</span>
              </div>

              <div className={styles['bullet-item']}>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/hosts/checkmark.svg'
                  }
                  className={styles['check']}
                  alt={'Check Mark'}
                />
                <span>Crisis Response</span>
              </div>

              <div className={styles['bullet-item']}>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/hosts/checkmark.svg'
                  }
                  className={styles['check']}
                  alt={'Check Mark'}
                />
                <span>Boundaries</span>
              </div>
            </div>

            <div className={styles['right-side']}>
              <img
                src={image}
                className={styles['heart-image']}
                alt={'Host Training'}
              />
            </div>
          </div>

          <div className={styles['btn-container']}>
            <WellButtonLink
              type={'well-btn-orange'}
              to={'/training'}
              btnText={'JOIN TRAINING!'}
              className={styles['btn']}
            />
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};

export const SessionVideoModal = () => {
  const dispatch = useWellDispatch();

  const { componentsStore } = useWellSelector((state) => state);
  const { newModal } = componentsStore;

  const closeModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isSessionVideoNoticeModalOpen: {
        isOpen: false,
        src: '',
        btnText: '',
        btnHref: '',
      },
    });
  }, [dispatch]);

  // if you change page close modal
  useEffect(() => {
    // monitor changes in history, to close modal
    const unlisten = history.listen(() => {
      closeModal();
    });
    return () => {
      unlisten();
    };
  }, [closeModal]);

  return (
    <ModalWrapper
      closeModal={closeModal}
      isLoading={false}
      wrapperClassName={styles['modal-wrapper-class']}
    >
      <>
        <iframe
          className={styles['iframe']}
          title={'ShareWell Video'}
          src={newModal.isSessionVideoNoticeModalOpen.src}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <div className={styles['btn-container']}>
          <WellButtonLink
            type={'well-btn-orange'}
            to={newModal.isSessionVideoNoticeModalOpen.btnHref}
            btnText={newModal.isSessionVideoNoticeModalOpen.btnText}
          />
        </div>
      </>
    </ModalWrapper>
  );
};
