import { useCallback, useEffect, useState } from 'react';
import styles from './UpdateBillingCadence.module.css';
import {
  WellSlideOut,
  SubscriptionItemSmall,
  WellButton,
  BillingNotice,
  BillingPriceFuture,
  WellLoader,
} from '_components';
import { useWellDispatch, useSubscriptionTypes } from '_hooks';
import { stripeActions } from '_actions';
import { ISubscriptionType, IUserSubscription } from '_types';

export const UpdateBillingCadence = ({
  cancel,
  userSubscription,
}: {
  cancel: () => void;
  userSubscription: IUserSubscription;
}) => {
  const dispatch = useWellDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const subscriptionTypes = useSubscriptionTypes();

  const [selected, setSelected] = useState<ISubscriptionType>(
    {} as ISubscriptionType
  );

  useEffect(() => {
    if (selected?.id) return;
    const item = subscriptionTypes.find(
      (s) => s.id === userSubscription.next_subscription_type
    );
    if (item !== undefined) setSelected(item);
    else setSelected(subscriptionTypes[0])
  }, [subscriptionTypes]);

  const updateSubscription = useCallback(async () => {
    setIsLoading(true);
    // else they have no grace period so subscribe them now
    dispatch(
      stripeActions.updateSubscription({
        subscription_type: selected.id,
        auto_subscribe: 'true',
      })
    )
      .then(async () => {
        setIsDone(true);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [dispatch, selected]);

  if (
    userSubscription.next_subscription_type === null ||
    selected?.id === undefined
  )
    return (
      <WellSlideOut
        isLoading={isLoading}
        closeSlide={cancel}
        disableOverlay={false}
        disallowClose={false}
      >
        <WellLoader />
      </WellSlideOut>
    );

  return (
    <WellSlideOut
      isLoading={isLoading}
      closeSlide={cancel}
      disableOverlay={false}
      disallowClose={false}
    >
      <>
        {!isDone ? (
          <>
            <h2 className={styles['title']}>Change Billing Plan</h2>

            <>
              <div className={styles['subscriptions-row']}>
                <SubscriptionItemSmall
                  subscriptionData={subscriptionTypes[0]}
                  isActive={selected === subscriptionTypes[0]}
                  onClick={() => setSelected(subscriptionTypes[0])}
                  color={'one'}
                />

                <SubscriptionItemSmall
                  subscriptionData={subscriptionTypes[1]}
                  isActive={selected === subscriptionTypes[1]}
                  onClick={() => setSelected(subscriptionTypes[1])}
                  color={'two'}
                />

                <SubscriptionItemSmall
                  subscriptionData={subscriptionTypes[2]}
                  isActive={selected === subscriptionTypes[2]}
                  onClick={() => setSelected(subscriptionTypes[2])}
                  color={'three'}
                />
              </div>

              <div className={styles['botton-content']}>

                <BillingPriceFuture
                  userSubscription={userSubscription}
                  subscriptionType={selected}
                />

                <BillingNotice
                  userSubscription={userSubscription}
                  subscriptionType={selected}
                />
              </div>

            </>

            <div className={styles['modal-btn-container']}>
              <WellButton
                btnOnClick={updateSubscription}
                className={styles['modal-btn']}
                btnText={'Update Membership Plan'}
                type={'well-btn-primary'}
                isLoading={isLoading}
              />
            </div>
          </>
        ) : (
          <SubscriptionHasBeenUpdated />
        )}
      </>
    </WellSlideOut>
  );
};

export const SubscriptionHasBeenUpdated = () => {
  return (
    <div className={styles['is-done-container']}>
      <div className={styles['is-done-image-container']}>
        <img
          className={styles['is-done-image']}
          src={
            process.env.REACT_APP_ASSETS_URL +
            '/site/subscription/success-2.svg'
          }
          alt={'ShareWell for desktop'}
        />
      </div>

      <div className={styles['is-done']}>
        Your ShareWell Unlimited Membership has&nbsp;been&nbsp;updated!
      </div>
    </div>
  );
};
