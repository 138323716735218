import { useState, useCallback, useEffect } from 'react';
import styles from './CreditCardUpdate.module.css';
import {
  WellSlideOut,
  StripeContainer,
  StripeNewCard,
  WellLoader,
  PrevCardItem,
} from '_components';
import { usePreviousCards } from '_hooks';

export const CreditCardUpdate = ({
  cancel,
  callback,
}: {
  cancel: () => void;
  callback: () => void;
}) => {
  const [delay, setDelay] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const { prevCards, getPreviousCardsData } = usePreviousCards();

  const closeModal = useCallback(() => {
    callback();
    setIsDone(true);
  }, [callback]);

  useEffect(() => {
    setTimeout(() => setDelay(true), 1000);
  }, []);

  return (
    <WellSlideOut
      isLoading={false}
      closeSlide={cancel}
      disableOverlay={false}
      disallowClose={false}
    >
      <>
        {isDone ? (
          <div className={styles['is-done-container']}>
            <div className={styles['is-done-image-container']}>
              <img
                className={styles['is-done-image']}
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  '/site/subscription/success-2.svg'
                }
                alt={'ShareWell for desktop'}
              />
            </div>

            <div className={styles['is-done']}>
              Thank you! Your card has been updated!
            </div>
          </div>
        ) : delay ? (
          <>
            <h2 className={styles['title']}>Update credit card</h2>

            <h3 className={styles['subtitle']}>
              Updated credit cards will take effect on the next billing date.
            </h3>

            <StripeContainer>
              <StripeNewCard callback={closeModal} buttonText={'UPDATE CARD'} />
            </StripeContainer>

            {prevCards && prevCards.length > 0 && (
              <>
                <div className={styles['divider']}></div>
                <div className={styles['current-card-container']}>
                  <h3>Current Card on ShareWell:</h3>
                  <PrevCardItem
                    last4={prevCards[0].last4}
                    expire={prevCards[0].expire}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <div className={styles['loader-container']}>
            <WellLoader heightWidth={100} />
          </div>
        )}
      </>
    </WellSlideOut>
  );
};
