import { useState, useEffect } from 'react';
import styles from './BreakoutRooms.module.css';
import { BreakoutRoomPerson } from './BreakoutRoomPerson';
import { notificationConstants } from '_constants';
import { ISessionRoom } from '_types';

interface IBreakoutRoomBox {
  roomNumber: number;
  breakoutRoomData: ISessionRoom;
  clickPersonToNewRoom: (user: string, currentRoom: number) => void;
}

export const BreakoutRoomBox = ({
  roomNumber,
  breakoutRoomData,
  clickPersonToNewRoom,
}: IBreakoutRoomBox) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // let size = getObjSize(breakoutRoomData);
    let size = breakoutRoomData.users.length;
    if (size < 3 && size !== 0 && roomNumber != 0) setIsValid(false);
    else setIsValid(true);
  }, [breakoutRoomData, roomNumber]);

  return (
    <div
      className={
        styles['breakout-room-box'] +
        ' col-12 ' +
        (!isValid ? styles['invalid'] : '')
      }
    >
      {roomNumber == 0 ? (
        <h4 className={styles['breakout-room-box-title']}>Main Room</h4>
      ) : (
        <h4 className={styles['breakout-room-box-title']}>
          Breakout Room {roomNumber}
        </h4>
      )}

      <div className={styles['breakout-room-box-row'] + ' row'}>
        {breakoutRoomData.users
          .filter((user) => user.active)
          .map((person) => {
            return (
              <BreakoutRoomPerson
                key={
                  'breakout-room-person-room-' + roomNumber + '-' + person.uuid
                }
                user={person.uuid}
                roomNumber={roomNumber}
                clickPersonToNewRoom={clickPersonToNewRoom}
              />
            );
          })}
      </div>

      {!isValid ? (
        <div className={styles['invalid-notice']}>
          {notificationConstants.BREAKOUT_ROOM_INVALID}
        </div>
      ) : null}
    </div>
  );
};
