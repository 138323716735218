// this is the user info interface which is returned from the server
export interface IUser {
  about_me: string;
  age: string;
  banner_pic: string;
  blocked: boolean;
  can_block: boolean;
  host_level: number;
  last_name: string | null;
  name: string;
  num_session_users_met: number;
  num_sessions_attended: number;
  num_sessions_hosted: number;
  pic: string | null;
  testimonials_pct_thumbs_up: number;
  user_marketing_json: any;
  username: string;
  uuid: string;
}

// the currently logged in person
export interface IAccount extends IUser {
  email: string;
  email_unconfirmed: string;
  has_password: boolean;
  is_admin: boolean;
  num_sessions_attended: number;
  getstreamio_user_token: string;
  receives_general_emails: boolean;
  receives_session_emails: boolean;
  receives_marketing_emails: boolean;
  public_private_profile?: boolean;
  special_notice: string;
  subscription_info: IUserSubscription;
}

export interface IUserSubscription {
  is_unlimited_subscribed: boolean;
  auto_renewal: boolean;
  current_period_end: string | null;
  current_period_start: string | null;
  grace_period_end: string | null;
  current_subscription_type: number | null;
  next_subscription_type: number | null;
  error_date: string | null;
  has_basic_session_remaining: boolean | null;
}


// the current valid host levels
export enum EHostLevel {
  HOST_LEVEL_0 = 0,
  HOST_LEVEL_1 = 1,
  HOST_LEVEL_2 = 2,
}

export interface IKnownUsers {
  [key: string]: IUser;
}
