import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './LoginForm.module.css';

import { history, AnalyticsSendEvent } from '_helpers';
import { useForm, useWellDispatch, useWellSelector } from '_hooks';
import {
  WellButton,
  WellInputWrapper,
  GoogleLoginProvider,
  FacebookLoginComponent,
} from '_components';
import { authActions } from '_actions';
import {
  errorMsgs,
  regexItems,
  authConstants,
  componentsConstants,
} from '_constants';
import { IFormItemString, KeyboardEvent } from '_types';

interface ILoginForm {}

export const LoginForm = ({}: ILoginForm) => {
  const dispatch = useWellDispatch();
  const { authStore } = useWellSelector((state) => state);

  const [data, setData] = useState({
    usernameOrEmail: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.MORE_THAN_3_CHARACTERS,
      errorMsg: errorMsgs.USERNAME_OR_EMAIL_INVALID,
    } as IFormItemString,
    password: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.MORE_THAN_3_CHARACTERS,
      errorMsg: errorMsgs.PASSWORD_INVALID,
    } as IFormItemString,
  });

  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { errors, validateAll, handleChange, inputRefs } = useForm(
    data,
    setData
  );

  // handle submit of the form
  const handleSubmit = () => {
    if (!validateAll()) return false;

    const { usernameOrEmail, password } = data;

    setIsLoading(true);

    dispatch({ type: authConstants.SET_IS_LOGGING_IN, isLoggingIn: true }); // set user is logging in

    // submit to server
    dispatch(
      authActions.loginWithPassword(usernameOrEmail.value, password.value)
    )
      .then(() => {
        AnalyticsSendEvent('auth_login_success', { type: 'pasword' });
        // only on the gift page do we not recirect
        if (
          history.location.pathname !== '/gift' &&
          history.location.pathname !== '/redeem'
        ) {
          if (authStore.loginRedirect && authStore.loginRedirect !== '/login')
            history.push(authStore.loginRedirect);
          else history.push('/');
        }
        dispatch({ type: authConstants.SET_IS_LOGGING_IN, isLoggingIn: false });
      })
      .catch((error: string) => setServerError(error))
      .finally(() => setIsLoading(false));
  };

  // allow submit with enter key
  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !isLoading) {
      handleSubmit();
    }
  };

  const openRegisterModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isRegisterModalOpen: {
        isOpen: true,
        redirect: '',
      },
    });
  }, [dispatch]);

  return (
    <>
      <div className={styles['form-container']} onKeyDown={onKeyDownHandler}>
        <WellInputWrapper
          error={errors.usernameOrEmail}
          inputContainerClassName={styles['input-container']}
          errorRef={inputRefs.usernameOrEmail}
        >
          <input
            value={data.usernameOrEmail.value}
            onChange={handleChange}
            name={'usernameOrEmail'}
            placeholder={'Email or Username'}
            autoFocus={true}
          />
        </WellInputWrapper>

        <WellInputWrapper
          error={errors.password}
          inputContainerClassName={styles['input-container']}
          errorRef={inputRefs.password}
        >
          <input
            value={data.password.value}
            onChange={handleChange}
            name={'password'}
            placeholder={'Password'}
            type={'password'}
          />
        </WellInputWrapper>

        {serverError && (
          <div className={styles['server-error']}>{serverError}</div>
        )}

        <WellButton
          className={styles['login-submit-btn']}
          btnOnClick={handleSubmit}
          btnText={'LOGIN'}
          isLoading={isLoading}
          type={'well-btn-orange'}
        />

        <div className={styles['other-sign-ins']}>
          <GoogleLoginProvider
            disabled={isLoading}
            setError={setServerError}
            setIsLoading={setIsLoading}
          />

          <FacebookLoginComponent
            disabled={isLoading}
            setError={setServerError}
            setIsLoading={setIsLoading}
          />
        </div>

        <div className={styles['link-container']}>
          <Link className={styles['link']} to={'/forgot-password'}>
            Forgot Password?
          </Link>
        </div>

        <div className={styles['divider']}></div>

        <div className={styles['link-container']}>
          <WellButton
            className={styles['create-account-btn']}
            btnOnClick={openRegisterModal}
            btnText={'CREATE ACCOUNT'}
            type={'well-btn-primary'}
          />
        </div>
      </div>
    </>
  );
};
