import { useState } from 'react';
import {
  Panel,
  PanelContent,
  PanelFooter,
  Avatar,
  ActivityFeedInput,
  WellButton,
  WellLoader,
} from '_components';
import { isEmpty } from '_helpers';

import { ImageUploadButton, ImagePreviewer } from 'react-file-utils';
import 'react-file-utils/dist/styles/ImageUploadButton.css';
import 'react-file-utils/dist/styles/ImagePreviewer.css';

import {
  useForum,
  useStatusUpdateForm,
  useFileUpload,
  useWellDispatch,
  useWellSelector,
} from '_hooks';
import styles from './StatusUpdateForm.module.css';
import { userActions } from '_actions';
import { EmojiPickerIcon } from '_components';

export const StatusUpdateForm = () => {
  const dispatch = useWellDispatch();
  const { userData } = useWellSelector((state) => state.userStore);

  const { group, getNewActivities, tagUsers } = useForum();
  const {
    postText,
    setPostText,
    taggedUsers,
    setTaggedUsers,
    isLoading,
    setIsLoading,
    submitActivityText,
  } = useStatusUpdateForm();

  const {
    images,
    uploadNewFiles,
    removeImage,
    setImages,
    uploadImageToServer,
  } = useFileUpload();

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);

  // submit the text, handle upload images, adds the activity
  const createActivity = async () => {
    if (!postText) return;
    setIsLoading(true);

    const response = await submitActivityText(postText); // post from custom hook

    if (response && response.uuid)
      await tagUsers(response, postText, taggedUsers);

    if (images.order.length > 0 && response && response.uuid)
      await uploadImageToServer(response.uuid, images);

    setPostText('');
    setImages({ data: {}, order: [] });
    setTaggedUsers({});
    await getNewActivities();
    setIsLoading(false);
  };

  return (
    <>
      <h3 className={styles['new-post']}>New Post</h3>
      <Panel>
        {isLoading && (
          <div className={styles['loading-container']}>
            <WellLoader heightWidth={100} />
          </div>
        )}
        <div
          className={
            styles['form-container'] + ' ' + (isLoading && styles['is-loading'])
          }
        >
          <PanelContent>
            <div className={styles['row-section']}>
              <Avatar pic={userData.pic} isSmall={false} />
              <ActivityFeedInput
                value={postText}
                setValue={setPostText}
                taggedUsers={taggedUsers}
                setTaggedUsers={setTaggedUsers}
                hasInlineOptions={''}
                groupToSearch={group}
                emojiSelectorOpen={emojiSelectorOpen}
                setEmojiSelectorOpen={setEmojiSelectorOpen}
              />
            </div>
            <div className={styles['row-section']}>
              {images.order.length > 0 && (
                <ImagePreviewer
                  imageUploads={images.order.map((id) => images.data[id])}
                  handleRemove={removeImage}
                  multiple={false}
                />
              )}
            </div>
          </PanelContent>

          <PanelFooter>
            <div className={styles['footer-container']}>
              <div className={styles['footer-btns']}>
                <div
                  className={
                    styles['upload-item-container'] +
                    ' ' +
                    (!isEmpty(images.data) && styles['disabled-image-input'])
                  }
                >
                  <ImageUploadButton
                    resetOnChange
                    handleFiles={uploadNewFiles}
                    multiple={false}
                  />
                </div>

                <div
                  className={styles['emoji-picker-icon-container']}
                  onClick={() => setEmojiSelectorOpen(!emojiSelectorOpen)}
                >
                  <EmojiPickerIcon color={'#a0b2b8'} heightWidth={'25'} />
                </div>
              </div>

              <WellButton
                className={
                  styles['submit-activity-btn'] +
                  ' ' +
                  (postText.replace(/\s/g, '').length === 0 &&
                    styles['disabled'])
                }
                btnOnClick={createActivity}
                type={'well-btn-orange'}
                btnText={'POST'}
                isLoading={false}
              />
            </div>
          </PanelFooter>
        </div>
      </Panel>
    </>
  );
};
