import { useState } from 'react';
import { useGetImgixVersion, useWellDispatch, useWellSelector } from '_hooks';
import {
  ModalWrapper,
  ModalBodyWrapper,
  ModalButtonWrapper,
  WellCheckbox,
  WellLoader,
  WellButton,
} from '_components';
import styles from './JoinModals.module.css';
import { groupActions } from '_actions';
import { AnalyticsSendEvent } from '_helpers';
import { componentsConstants } from '_constants';
import { EModalFlags } from '_types';

export const GroupJoinModal = () => {
  const dispatch = useWellDispatch();
  const { componentsStore, userStore } = useWellSelector((state) => state);
  const { newModal } = componentsStore;

  const [checkedOne, setCheckedOne] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let image = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/modal/register-hearts.png',
    {
      w: 225,
    }
  );

  const joinToWell = async () => {
    AnalyticsSendEvent('group_subscribe', {
      user: userStore.userData.uuid,
      group: newModal.isGroupJoinModalOpen,
    });

    setIsLoading(true);

    await dispatch(
      groupActions.subscribeToGroup(newModal.isGroupJoinModalOpen.group, true)
    );

    if (newModal.isGroupJoinModalOpen.session) {
      // update flag for watchers (currently only session item)
      dispatch({
        type: componentsConstants.SET_UNSET_FLAG_NEW_MODAL,
        set: true,
        flag: EModalFlags.HAS_UPDATED_SESSION,
      });
      closeModal();
    } else {
      // flip flag for group info page watcher
      dispatch({
        type: componentsConstants.SET_UNSET_FLAG_NEW_MODAL,
        set: true,
        flag: EModalFlags.HAS_UPDATED_GROUP,
      });
      setIsOver(true);
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isGroupJoinModalOpen: false,
    });
  };

  if (isLoading)
    return (
      <ModalWrapper
        isLoading={false}
        closeModal={closeModal}
        disallowClose={isLoading}
        wrapperClassName={styles['modal-wrapper']}
      >
        <WellLoader heightWidth={150} />
      </ModalWrapper>
    );

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
    >
      <>
        {isOver ? (
          <>
            <img
              src={image}
              className={styles['heart-image']}
              alt={'Giant Heart'}
            />
            <h4 className={styles['modal-title'] + ' ' + styles['congrats']}>
              CONGRATULATIONS
            </h4>
            <div className={styles['main-text']}>
              <p>
                Congrats, your peers are waiting! You are now welcome to join
                any peer support session in this well.
              </p>
              <p>
                New sessions are added by community members weekly. Don't see an
                upcoming session? Why not host your own. Anyone can be a peer
                supporter,{' '}
                <a
                  className={styles['learn-how']}
                  href={
                    'https://blog.sharewellnow.com/become-a-sharewell-host/'
                  }
                  target={'_blank'}
                  rel='noreferrer'
                >
                  learn how
                </a>
                !
              </p>
            </div>
          </>
        ) : (
          <>
            {!newModal.isGroupJoinModalOpen.session ? (
              <div className={styles['title']}>
                Before joining this community, the creator of this Well requires
                that you adhere ShareWell's{' '}
                <a
                  className={styles['code-of-conduct-link']}
                  href={'/safety-policy'}
                  target='_blank'
                  rel='noreferrer'
                >
                  Safety Policy
                </a>{' '}
                and that you have lived experience of the topic.
              </div>
            ) : (
              <div className={styles['title']}>
                You must be a member of this Well community to join&nbsp;this&nbsp;session.{' '}
              </div>
            )}

            <ModalBodyWrapper>
              <div className={styles['checkbox-row']}>
                <WellCheckbox
                  inputStyles={{height: '25px', width: '25px'}}
                  checkItem={checkedOne}
                  handleChange={() => setCheckedOne(!checkedOne)}
                  label={"I commit to ShareWell’s Code of Conduct and confirm I have lived experience of this topic"}
                />
              </div>
            </ModalBodyWrapper>

            <ModalButtonWrapper>
              <WellButton
                type={'well-btn-primary'}
                btnText={'JOIN WELL'}
                className={styles['join-button']}
                btnOnClick={joinToWell}
                disabled={!checkedOne}
              />
            </ModalButtonWrapper>
          </>
        )}
      </>
    </ModalWrapper>
  );
};
