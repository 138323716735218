import { useState, useEffect, useCallback } from 'react';
import { groupForumActions } from '_actions';
import { IActivity } from '_types';
import { useWellDispatch, useActivityPagination } from '_hooks';

export const useRepliesItems = (activity: IActivity) => {
  const dispatch = useWellDispatch();

  const [isLoadingReplies, setIsLoadingReplies] = useState(true);
  const [replyItems, setReplyItems] = useState<IActivity[]>([]); //a list of replies

  const { loadAheadOfMarker, loadBehindOfMarker, isMore, setIsMore } =
    useActivityPagination(activity.group_uuid);

  // load more replies to stack on top (older)
  const loadMoreReplies = useCallback(
    async (isFirstLoad = false) => {
      setIsLoadingReplies(true);
      const response: any = await loadBehindOfMarker(
        activity.uuid,
        isFirstLoad
      );
      //if we got reply
      if (response && response.length > 0) {
        response.reverse();
        setReplyItems((prevState: IActivity[]) => {
          let newState = [...response, ...prevState];
          if (newState.length < activity.num_replies) setIsMore(true);
          else setIsMore(false);
          return newState;
        });
      }

      setIsLoadingReplies(false);
    },
    [activity.num_replies, activity.uuid, loadBehindOfMarker, setIsMore]
  );

  // newer replies
  // these are replies at the bottom
  const loadNewerReplies = useCallback(async () => {
    const response: any = await loadAheadOfMarker(activity.uuid, false);
    //add the new reply & any other ones
    if (response && response.length > 0) {
      response.reverse();
      setReplyItems((prevState) => {
        return [...prevState, ...response];
      });
    }
  }, [activity.uuid, loadAheadOfMarker]);

  // delete a reply
  const deleteReply = useCallback(
    async (reply) => {
      if (!reply) return false;
      dispatch(groupForumActions.postDelete(reply));
      //remove the reaction from the current state
      setReplyItems((prevState) => {
        return prevState.filter((item) => item.uuid !== reply);
      });
      activity.num_replies--;
    },
    [activity.num_replies, dispatch]
  );

  // reload a reply
  const reloadReply = useCallback(
    async (reply) => {
      const response = await dispatch(groupForumActions.postGet(reply));

      if (!response || !response.uuid) return Promise.resolve();

      setReplyItems((prevState) => {
        const x = prevState.map((replyItem) => {
          if (replyItem.uuid === reply) {
            return response;
          }
          return replyItem;
        });

        return x;
      });

      return Promise.resolve();
    },
    [dispatch]
  );

  // on load get replies
  useEffect(() => {
    const start = async () => {
      await loadMoreReplies(true);
    };
    start();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    replyItems,
    isLoadingReplies,
    isMore,
    loadMoreReplies,
    loadNewerReplies,
    deleteReply,
    reloadReply,
  };
};
