import { useState, useEffect } from 'react';
import { sessionsActions } from '_actions';
import { ISession } from '_types';
import { useWellDispatch } from '_hooks';

/**
 * Get's sessions in a series
 * @param series - the uuid of the series we are looking at
 */
export const useSessionsInSeries = (series: string, sessionToRemove: string) => {
  const dispatch = useWellDispatch();

  const [sessionsInSeries, setSessionsInSeries] = useState<ISession[]>([]);
  const [isLoadingSessionsInSeries, setIsLoadingSessionsInSeries] =
    useState(true);

  useEffect(() => {
    const start = async () => {
      if (!series) {
        setSessionsInSeries([]);
        return;
      }

      const response = await dispatch(
        sessionsActions.getSessionsInSeries(series)
      );

      if (response) setSessionsInSeries(response.filter((item: ISession) => item.uuid !== sessionToRemove));

      setIsLoadingSessionsInSeries(false);
    };

    start();
  }, [dispatch, series, sessionToRemove]);

  return {
    sessionsInSeries,
    isLoadingSessionsInSeries,
  };
};
