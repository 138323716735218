import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      textAlign: 'center',
      paddingBottom: '32px',
    },
    hostsRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      textAlign: 'center',
      '& > div:nth-of-type(1)': {
        position: 'relative',
        left: '15px',
      },
      '& > div:nth-of-type(2)': {
        position: 'relative',
        left: '10px',
      },
      '& > div:nth-of-type(3)': {
        position: 'relative',
        left: '5px',
      },
      '& > div:nth-of-type(4)': {
        position: 'relative',
        left: '-5px',
      },
      '& > div:nth-of-type(5)': {
        position: 'relative',
        left: '-10px',
      },
      '& > div:nth-of-type(6)': {
        position: 'relative',
        left: '-15px',
      },
      [theme.breakpoints.up('md')]: {
        '& > div:nth-of-type(1)': {
          position: 'relative',
          left: '30px',
        },
        '& > div:nth-of-type(2)': {
          position: 'relative',
          left: '20px',
        },
        '& > div:nth-of-type(3)': {
          position: 'relative',
          left: '10px',
        },
        '& > div:nth-of-type(4)': {
          position: 'relative',
          left: '-10px',
        },
        '& > div:nth-of-type(5)': {
          position: 'relative',
          left: '-20px',
        },
        '& > div:nth-of-type(6)': {
          position: 'relative',
          left: '-30px',
        },
      },
    },

    hostContainer: {},
    hostImageContainer: {},
    hostImage: {
      border: '1px solid white',
      boxShadow: '0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%)',
      borderRadius: '100%',
    },
    name: {
      fontStyle: 'italic',
      fontWeight: 600,
      fontSize: '16px',
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
  })
);

export default useStyles;
