import React from 'react';
import styles from './GroupBadge.module.css';

export const GroupBadge = ({ number, isLarge }) => {


  return (
    <div
      className={
        styles['well-badge-container'] +
        ' ' +
        (number > 9 && styles['greater-container']) +
        ' ' +
        (isLarge && styles['is-large'])
      }
    >
      <div className={styles['number']}>
        {number > 9 ? (
          <div className={styles['greater']}>
            9<sup>+</sup>
          </div>
        ) : (
          number
        )}
      </div>
    </div>
  );
};
