import styles from './JoinModals.module.css';
import { ModalWrapper } from '_components';
import { useGetImgixVersion, useWellDispatch, useWellSelector } from '_hooks';

import { Link } from 'react-router-dom';
import { componentsConstants } from '_constants';
import { useEffect, useCallback, useState } from 'react';
import { history } from '_helpers';
import { AddToCalendarBtn } from 'Sessions';

export const SessionJoinModal = () => {
  const dispatch = useWellDispatch();
  const { componentsStore } = useWellSelector((state) => state);
  const { isSessionJoinModalOpen } = componentsStore.newModal;

  const [addToCalenderClicked, setAddToCalenderClicked] = useState(false);

  function handleAddToCalender() {
    setAddToCalenderClicked(!addToCalenderClicked);
  }

  // let image = useGetImgixVersion(
  //   // process.env.REACT_APP_ASSETS_URL + '/site/modal/register-hearts.png',
  //   process.env.REACT_APP_ASSETS_URL +
  //     '/site/homepage/homepage-quiz-header.svg',
  //   {
  //     w: 320,
  //   }
  // );

  let bottomImageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/session/session-join-1.svg',
    {
      w: 320,
    }
  );

  let bottomImageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/session/session-join-2.svg',
    {
      w: 320,
    }
  );

  let bottomImageThree = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/session/session-join-3.svg',
    {
      w: 320,
    }
  );

  const closeModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isSessionJoinModalOpen: false,
    });
  }, [dispatch]);

  useEffect(() => {
    // monitor changes in history, to close modal
    const unlisten = history.listen(() => {
      closeModal();
    });
    return () => {
      unlisten();
    };
  }, [closeModal]);

  let mainText = styles['main-text'];
  let expandModalOrNot = addToCalenderClicked
    ? styles['expand-register-modal']
    : '';
  let joinModal = mainText + ' ' + expandModalOrNot;

  //   <img
  //   src={image}
  //   className={styles['heart-image']}
  //   alt={'Giant Heart'}
  // />

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
      wrapperClassName={styles['wrapper']}
    >
      <>
        <div className={joinModal}>
          <p>
            You are confirmed! {isSessionJoinModalOpen.host_user.username} looks
            forward to seeing you. Please save this session to your calendar:
          </p>

          <AddToCalendarBtn
            session={isSessionJoinModalOpen}
            isOrange={true}
            onAddToCalendarClick={handleAddToCalender}
          />

          <h3 className={styles['bottom-title']}>
            ShareWell members report a boost in overall well-being.
          </h3>

          <div className={styles['bottom-row']}>
            <div className={styles['bottom-item']}>
              <img
                src={bottomImageOne}
                className={styles['bottom-image']}
                alt={''}
              />
              <div className={styles['bottom-text']}>
                Connect with people who get you!
              </div>
            </div>

            <div className={styles['bottom-item']}>
              <img
                src={bottomImageTwo}
                className={styles['bottom-image']}
                alt={''}
              />
              <div className={styles['bottom-text']}>
                Safely share (when you're ready)
              </div>
            </div>

            <div className={styles['bottom-item']}>
              <img
                src={bottomImageThree}
                className={styles['bottom-image']}
                alt={''}
              />
              <div className={styles['bottom-text']}>
                Learn helpful tips and resources
              </div>
            </div>
          </div>

          <Link to={'/sessions'} className={styles['sessions-link']}>
            Ready to explore more sessions?
          </Link>
        </div>
      </>
    </ModalWrapper>
  );
};
