import styles from './HomepageHeader.module.css';
import { WellButtonLink, WellButton } from '_components';
import { useGetImgixVersion, useWellSelector } from '_hooks';
import { useDispatch } from 'react-redux';
import { componentsConstants } from '_constants';
import CSS from 'csstype';

interface IHomepageHeaderNoVideo {
  title?: string;
  titleHalf?: string;
  titleSecondHalf?: string;
  subtitleHalf: string;
  subtitleSecondHalf: string;
  btnText: string;
  image: string;
  quote: string;
  quoteAuthor: string;
  quoteTextStyle: CSS.Properties;
}

export const HomepageHeaderNoVideo = ({
  title,
  titleHalf,
  titleSecondHalf,
  subtitleHalf,
  subtitleSecondHalf,
  btnText,
  image,
  quote,
  quoteAuthor,
  quoteTextStyle,
}: IHomepageHeaderNoVideo) => {
  const dispatch = useDispatch();

  const { loggedIn } = useWellSelector((state) => state.userStore);

  const headerGraphic = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + image,
    {
      w: 600,
      h: 300,
    }
  );

  const quotes = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/quote-yellow.svg',
    {
      h: 100,
      w: 100,
    }
  );


  const openRegisterModal = () => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isRegisterModalOpen: {
        isOpen: true,
        skipOnboarding: false,
        redirect: '',
      },
    });
  };

  return (
    <section className={styles['header']} style={{ maxHeight: '650px' }}>
      <div className={styles['header-container']}>
        <div className={styles['header-row'] + ' row'}>
          <div className={styles['left'] + ' col-12 col-lg-6 col-xl-7'}>
            <h1 className={styles['title']}>
              {title ? (
                <>{title}</>
              ) : (
                <>
                  {titleHalf} <br className={styles['special-break']}></br>
                  {titleSecondHalf}
                </>
              )}
            </h1>
            <h2 className={styles['subtitle']}>
              {subtitleHalf} <br className={styles['special-break']}></br>
              {subtitleSecondHalf}
            </h2>

            <div className={styles['join-now-btn-container']}>
              {loggedIn ? (
                <WellButtonLink
                  className={styles['logged-in']}
                  to={'/'}
                  btnText={btnText}
                  type={'well-btn-orange'}
                />
              ) : (
                <WellButton
                  className={styles['logged-in']}
                  btnOnClick={openRegisterModal}
                  btnText={btnText}
                  type={'well-btn-orange'}
                />
              )}
            </div>
          </div>

          <div className={styles['right'] + ' col-12 col-lg-6 col-xl-5'}>
            <div className={styles['quote-container']}>
              <img
                src={quotes}
                className={styles['quote-brackets']}
                alt={'Quotes'}
              />
              <p className={styles['quote']} style={quoteTextStyle}>
                &ldquo;{quote}&rdquo;
              </p>
              <p className={styles['quote-author']} style={quoteTextStyle}>
                - {quoteAuthor}
              </p>
              <img
                className={styles['quote-image']}
                src={headerGraphic}
                alt={'Header graphic'}
              />
            </div>
          </div>

          <svg className={styles['arrows']}>
            <path className={styles['a2']} d="M0 0 L80 36 L160 0"></path>
          </svg>
        </div>
      </div>

      <svg
        className={styles['wave-svg-desktop']}
        viewBox="0 0 500 150"
        width={'100%'}
        height={'100'}
        preserveAspectRatio="none"
      >
        <path d="M0.00,49.98 C232.22,-105.09 264.95,282.72 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"></path>
      </svg>
    </section>
  );
};
