import styles from './SessionInfoLeft.module.css';

import { SessionHost, SessionHostless, Testimonials } from '_components';
import { useSessionInfo } from '_hooks';
import { Link } from 'react-router-dom';

export const SessionInfoLeft = () => {
  const { sessionInfo } = useSessionInfo();

  return (
    <div className={styles['session-info-left']}>
      <AboutSession />
      {sessionInfo.is_group_hosted ? <SessionHostless /> : <SessionInfoHost />}
    </div>
  );
};

export const AboutSession = () => {
  const { sessionInfo } = useSessionInfo();

  return (
    <div className={styles['description-container']}>
      <h3 className={styles['title']}>About This Session</h3>

      <p className={styles['description']}>{sessionInfo.description}</p>

      {sessionInfo.am_i_joined && sessionInfo.status !== 'Over' && (
        <div className={styles['external-notice-row']}>
          <span>
            {sessionInfo.external_url ? (
              <>
                You are registered! You can enter the session from this screen.
                The session waiting room will open 5 minutes before the
                scheduled start time.
              </>
            ) : (
              <>
                You are registered! You can enter the session from this screen.
                The session waiting room will open 5 minutes before the
                scheduled start time. You will not be allowed to enter the
                session more than 5 minutes late, as latecomers disrupt the
                session conversation. Ensure your technology setup is ready to
                go by testing your video and audio{' '}
                <Link to={'/camera-test'}>here</Link>. Sessions are only held
                with 3 or more participants.
              </>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export const SessionInfoHost = () => {
  const { sessionInfo } = useSessionInfo();

  return (
    <div className={styles['host-container']}>
      <SessionHost
        host={sessionInfo.host_user}
        showDesc={true}
        showBadges={false}
      />
      <div className={styles['testimonials-container']}>
        <Testimonials
          uuid={sessionInfo.host_user.uuid}
          isSelf={false}
          isSessionInfo={true}
        />
      </div>
    </div>
  );
};
