import { useState } from 'react';
import { useCallback } from 'react';
import {
  useForum,
  useFileUpload,
  useWellDispatch,
  useRepliesItems,
  useWellSelector,
} from '_hooks';
import {
  ReplyItem,
  ActivityFeedInput,
  EmojiPickerIcon,
  WellButton
} from '_components';
import styles from './ActivityItem.module.css';
import { ImageUploadButton, ImagePreviewer } from 'react-file-utils';
import { TUserMapping, IActivity } from '_types';
import { groupForumActions, userActions } from '_actions';
import { AnalyticsSendEvent } from '_helpers';


interface IActivityItemReplies {
  activity: IActivity;
}

export const ActivityItemReplies = ({
  activity,
}: IActivityItemReplies) => {

  const dispatch = useWellDispatch();
  const { userData } = useWellSelector(state => state.userStore);

  const {
    replyItems,
    isLoadingReplies,
    isMore,
    loadNewerReplies,
    loadMoreReplies,
    deleteReply,
    reloadReply,
  } = useRepliesItems(activity);


  const { tagUsers } = useForum();

  // maybe move to custom hook?
  const [replyText, setReplyText] = useState('');
  const [taggedUsers, setTaggedUsers] = useState<TUserMapping>({});
  const [isLoadingAddingReply, setIsLoadingAddingReply] = useState(false);
  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);

  const {
    images,
    setImages,
    uploadNewFiles,
    removeImage,
    uploadImageToServer,
  } = useFileUpload();

  // post the reply to the activity
  const postReplyToActivity = useCallback(async () => {

    if (!replyText) return;

    setIsLoadingAddingReply(true);

    const response = await dispatch(
      groupForumActions.postCreate(
        activity.group_uuid,
        replyText,
        activity.uuid
      )
    );

    await tagUsers(response, replyText, taggedUsers);

    if (response && response.uuid) {

      // tag users if needed
      await tagUsers(response, replyText, taggedUsers);

      // add images if needed
      if (images.order.length > 0)
        await uploadImageToServer(response.uuid, images);

      AnalyticsSendEvent('group_forum_activity_reply', {
        activity: activity.uuid,
      });

      setReplyText('');
      setImages({ data: {}, order: [] });
      setTaggedUsers({});
      activity.num_replies++;
      loadNewerReplies();

    }

    setIsLoadingAddingReply(false);

    return;

  }, [activity.group_uuid, activity.num_replies, activity.uuid, dispatch, images, loadNewerReplies, replyText, setImages, tagUsers, taggedUsers, uploadImageToServer, userData.user_marketing_json]);


  return (

    <>
      <div className={styles['bottom-content']}>
        {isMore && !isLoadingReplies && (
          <h3 className={styles['load-more']} onClick={loadMoreReplies}>
            View All Comments
          </h3>
        )}

        <div className={styles['reply-container']}>
          {replyItems &&
            replyItems.length > 0 &&
            replyItems.map((reply) => {
              return (
                <ReplyItem
                  key={'reply-item-' + reply.uuid}
                  reply={reply}
                  deleteReply={deleteReply}
                  reloadReply={reloadReply}
                />
              );
            })}
        </div>

      </div>
      <span className={styles['submit-reply-container']}>
        <ActivityFeedInput
          value={replyText}
          setValue={setReplyText}
          taggedUsers={taggedUsers}
          setTaggedUsers={setTaggedUsers}
          hasInlineOptions={activity.uuid}
          groupToSearch={activity.group_uuid}
          emojiSelectorOpen={emojiSelectorOpen}
          setEmojiSelectorOpen={setEmojiSelectorOpen}
        />

        <div className={styles['inline-btns-container']}>
          <ImageUploadButton
            resetOnChange
            handleFiles={uploadNewFiles}
            multiple={false}
            disabled={images.order.length > 0}
          />

          <div
            className={styles['emoji-picker-icon-container']}
            onClick={() => setEmojiSelectorOpen(!emojiSelectorOpen)}
          >
            <EmojiPickerIcon color={'#a0b2b8'} heightWidth={'20'} />
          </div>

        </div>
      </span>

      <div className={
        styles['submit-reply-bottom'] +
        ' ' + (isLoadingAddingReply ? styles['section-loading'] : '')
      }>

        {images.order.length > 0 ? (
          <div>
            <ImagePreviewer
              imageUploads={images.order.map((id) => images.data[id])}
              handleRemove={removeImage}
              multiple={false}
            />
          </div>
        ) :
          <div></div>
        }


        {replyText.replace(/\s/g, '').length !== 0 &&
          <WellButton
            btnOnClick={postReplyToActivity}
            type={'well-btn-orange'}
            btnText={'REPLY'}
            className={styles['submit-reply-btn']}
            disabled={!replyText}
            isLoading={isLoadingAddingReply}
          />
        }

      </div>



    </>




  )

}
