import { useState, useCallback, useEffect } from 'react';
import styles from './BreakoutRooms.module.css';
import modalStyles from '_components/SessionRoom/RoomModal/RoomModal.module.css';

import { roomMessagingActions, roomActions } from '_actions';
import { getServerTime } from '_helpers';
import { WellLoader, WellButton } from '_components';

import { BreakoutRoomBox } from './BreakoutRoomBox';
import { useWellSelector, useWellDispatch } from '_hooks';
import cloneDeep from 'clone-deep';
import { RoomModal } from '..';

import { FormControl } from 'react-bootstrap';
import { DispatchBoolean, ISessionRoom } from '_types';
import { roomConstants } from '_constants';

export const BreakoutRooms = () => {
  const dispatch = useWellDispatch();

  const { roomStore } = useWellSelector((state) => state);
  const { sessionInfo, roomData, breakoutRoomModalIsOpen } = roomStore;

  const [breakoutRoomData, setBreakoutRoomData] = useState<ISessionRoom[]>(
    [] as ISessionRoom[],
  );

  const [timerModalOpen, setTimerModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBreakoutRoomValid, setIsBreakoutRoomValid] = useState(false);

  // when closing breakout rooms reset the data
  useEffect(() => {
    if (breakoutRoomModalIsOpen) return;
    setBreakoutRoomData(roomData); // reset the data
  }, [roomData, breakoutRoomData, breakoutRoomModalIsOpen]);

  useEffect(() => {
    if (
      breakoutRoomData.length > 0 &&
      breakoutRoomData[1]?.users.length > 0 &&
      breakoutRoomData[1]?.users.length < 3
    )
      setIsBreakoutRoomValid(false);
    else setIsBreakoutRoomValid(true);
  }, [breakoutRoomData, setIsBreakoutRoomValid]);

  // click a person into the other room
  const clickPersonToNewRoom = useCallback(
    (user: string, currentRoom: number) => {
      let oppositeRoom = currentRoom == 1 ? 0 : 1;

      let copy = cloneDeep(breakoutRoomData);

      const userInfo = copy[currentRoom].users.find(
        (item) => item.uuid === user,
      );

      const filtered = copy[currentRoom].users.filter(
        (item) => item.uuid !== user,
      );

      if (filtered) copy[currentRoom].users = filtered; // set the filtered
      if (userInfo) copy[oppositeRoom].users.push(userInfo); // set the new room

      setBreakoutRoomData(copy);
    },
    [breakoutRoomData, setBreakoutRoomData],
  );

  // send people to their correct room
  const sendPeopleToRoom = useCallback(() => {
    if (breakoutRoomData[1].users.length === 0 && sessionInfo.am_i_host)
      dispatch(
        roomMessagingActions.setSendHostSignal({
          SHAREWELL_EVENT_BREAKOUT_ROOM_TIMER_STOP: 1,
        }),
      );

    breakoutRoomData.map((room, roomNum) => {
      room.users.map((user) => {
        dispatch(
          roomActions.assignToRoom(sessionInfo.uuid, user.uuid, roomNum),
        );
      });
    });

    setTimerModalOpen(false);
  }, [breakoutRoomData, dispatch, sessionInfo.am_i_host, sessionInfo.uuid]);

  const openTimerModal = useCallback(() => {
    setTimerModalOpen(true);
  }, []);

  const setBreakoutRoomsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch({
        type: roomConstants.SET_BREAKOUT_ROOM_MODAL,
        breakoutRoomModalIsOpen: isOpen,
      });
    },
    [dispatch],
  );

  // it takes 1 cycle for the breakout rooms to be good
  if (!breakoutRoomModalIsOpen || breakoutRoomData.length === 0) return <></>;

  const allInRoomOne = breakoutRoomData[1].users.length === 0 ? true : false;

  return (
    <>
      {timerModalOpen && (
        <TimerModal
          closeModal={setTimerModalOpen}
          sendPeopleToRoom={sendPeopleToRoom}
          setBreakoutRoomsOpen={setBreakoutRoomsOpen}
          setIsLoading={setIsLoading}
        />
      )}

      <div
        className={
          styles['breakout-room-status-box'] +
          ' ' +
          (!isBreakoutRoomValid ? styles['invalid-arrangement'] : '')
        }
      >
        <span
          className={styles['close-breakout-room-status-box']}
          onClick={() => setBreakoutRoomsOpen(!breakoutRoomModalIsOpen)}
        >
          &#10006;
        </span>

        <div className={styles['component-title-container']}>
          <h3 className={styles['component-title']}> Breakout Rooms </h3>
        </div>

        {isLoading ? (
          <WellLoader heightWidth={150} />
        ) : (
          <div className={'row'}>
            {breakoutRoomData.map((room) => {
              return (
                <BreakoutRoomBox
                  key={'breakout-room-box-' + room.room_assigned}
                  roomNumber={room.room_assigned}
                  breakoutRoomData={room}
                  clickPersonToNewRoom={clickPersonToNewRoom}
                />
              );
            })}
            <div className={styles['move-people-btn-container']}>
              <WellButton
                className={
                  styles['move-people-btn'] +
                  ' ' +
                  (isBreakoutRoomValid ? styles['is-valid'] : '')
                }
                btnOnClick={allInRoomOne ? sendPeopleToRoom : openTimerModal}
                btnText={'Confirm Assignments'}
                type={'well-btn-primary'}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface ITimerModal {
  closeModal: any;
  sendPeopleToRoom: () => void;
  setBreakoutRoomsOpen: (isOpen: boolean) => void;
  setIsLoading: DispatchBoolean;
}

const TimerModal = ({
  closeModal,
  sendPeopleToRoom,
  setBreakoutRoomsOpen,
  setIsLoading,
}: ITimerModal) => {
  const dispatch = useWellDispatch();

  const [timerLength, setTimerLength] = useState(15);
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setTimerLength(value);
  };

  const setTimer = useCallback(async () => {
    if (timerLength < 15 || timerLength > 45) return;

    setIsLoading(true);

    dispatch(
      roomMessagingActions.setSendHostSignal({
        SHAREWELL_EVENT_BREAKOUT_ROOM_TIMER_END_TIME: getServerTime()
          .utc()
          .add(timerLength, 'minutes')
          .format(),
      }),
    );

    sendPeopleToRoom();
    closeModal(false);
    setBreakoutRoomsOpen(false);
    setIsLoading(false);
  }, [
    closeModal,
    dispatch,
    sendPeopleToRoom,
    setBreakoutRoomsOpen,
    setIsLoading,
    timerLength,
  ]);

  useEffect(() => {
    if (timerLength < 15 || timerLength > 45) setIsValid(false);
    else setIsValid(true);
  }, [timerLength]);

  return (
    <RoomModal closeModal={closeModal}>
      <h4 className={styles['modal-title']} style={{ marginBottom: 0 }}>
        How long would you like to open the room for?
      </h4>
      <h4 className={styles['modal-subtitle']}>(45 minutes max)</h4>
      <div className={modalStyles['form-container']}>
        <FormControl
          as={'input'}
          onChange={handleChange}
          className={
            'well-form-item ' +
            styles['modal-input'] +
            ' ' +
            (!isValid ? styles['invalid-timer-length'] : '')
          }
          value={timerLength}
          type={'number'}
        />
        <span className={modalStyles['minutes']}>Minutes</span>
      </div>

      <div className={modalStyles['inner-buttons-container']}>
        <WellButton
          type={'well-btn-primary'}
          btnText={'CANCEL'}
          className={modalStyles['inner-no-button']}
          btnOnClick={() => closeModal(false)}
        />

        <WellButton
          type={'well-btn-primary'}
          btnText={'SET TIMER'}
          disabled={!isValid}
          className={modalStyles['inner-yes-button']}
          btnOnClick={setTimer}
        />
      </div>
    </RoomModal>
  );
};
