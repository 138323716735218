import { getServerTime, getSessionStartTime } from '_helpers';
import { ISession, ISessionRoom, ISessionRoomData } from '_types';

export function checkSessionHasStarted(session: ISession, isAdmin: boolean) {
  const startTime = getSessionStartTime(session);
  if (
    (session.am_i_joined || isAdmin) &&
    session.status !== 'Over' &&
    getServerTime() > startTime.subtract(10, 'minutes') &&
    session.status !== 'Canceled'
  ) {
    return true;
  }

  return false;
}

export function parseRoomsData(roomData: ISessionRoom[], curUser: string) {
  let returnData = {
    correctRoom: -1, // correct room starts as unknown
    hostIsActive: false, // we do not know if the host is active yet
  };

  for (var i = 0; i < roomData.length; i++) {
    for (var x = 0; x < roomData[i].users.length; x++) {
      let user = roomData[i].users[x];
      // check if the current user should be in this room
      if (user.uuid === curUser) returnData.correctRoom = i;
      // check if the host is active
      if (user.host) returnData.hostIsActive = user.active;
    }
  }

  return returnData;
}

// export function setBreakoutRoomData(
//   roomData: ISessionRoom[],
//   sessionUserData,
//   hostUUID,
//   hostPic,
//   hostRoom
// ) {
//   let returnData = {};
//
//   if (!roomData) return returnData;
//
//   for (var i = 0; i < roomData.length; i++) {
//     returnData[i] = {};
//     for (var x = 0; x < roomData[i].users.length; x++) {
//       if (!roomData[i].users[x].active) continue;
//
//       let user = roomData[i].users[x];
//
//       //this can be a hash map lookup
//       for (var y = 0; y < sessionUserData.length; y++) {
//         if (user.uuid == sessionUserData[y].uuid) {
//           returnData[i][user.uuid] = {
//             firstName: sessionUserData[y].name,
//             username: sessionUserData[y].username,
//             pic: sessionUserData[y].pic,
//             isHost: false,
//             originalRoom: i,
//           };
//           break;
//         }
//       }
//     }
//   }
//
//   //add the host at the end
//   returnData[hostRoom][hostUUID] = {
//     firstName: '',
//     username: 'You',
//     pic: hostPic,
//     isHost: true,
//     originalRoom: hostRoom,
//   };
//
//   return returnData;
// }
//
