import styles from './WellCheckbox.module.css';
import CSS from 'csstype';

interface IWellCheckbox {
  checkItem: boolean;
  handleChange: () => void;
  label: string;
  labelStyle?: CSS.Properties;
  inputStyles?: CSS.Properties;
}

export const WellCheckbox = ({
  checkItem,
  handleChange,
  label,
  labelStyle,
  inputStyles,
}: IWellCheckbox) => {

  return (
    <div className={styles['checkbox-container']} onClick={handleChange}>
      <input
        type={'checkbox'}
        className={styles['checkbox']}
        checked={checkItem}
        onChange={() => {}}
        style={inputStyles ? inputStyles : { position: 'relative' }}
      />
      <label className={styles['checkbox-label']} style={labelStyle}>
        {label}
      </label>
    </div>
  );
};

export const WellCheckBoxWithChild = ({
  children,
  checkItem,
  handleChange,
  inputStyles,
}: any) => {
  return (
    <div className={styles['checkbox-container']} onClick={handleChange}>
      <input
        type={'checkbox'}
        className={styles['checkbox']}
        checked={checkItem}
        onChange={() => {}}
        style={inputStyles ? inputStyles : { position: 'relative' }}
      />
      {children}
    </div>
  );
};
