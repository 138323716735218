import { useState, useRef } from 'react';
import styles from './LikeItem.module.css';
import { ThumbsUpIcon, IconColors, EmojisSelector } from '_components';
import { useClickOutside, useLikeItem } from '_hooks';

export const LikeItemListCombo = ({ activity, handleReload }) => {
  const [openEmojis, setOpenEmojis] = useState(false);

  const { sendEmoji, unsendEmoji, likedItems, totalLikes } = useLikeItem(
    activity,
    setOpenEmojis,
    handleReload
  );

  let propertyValues = [];

  Object.values(activity.like_user_emojis).map((key) => {
    propertyValues = [...new Set(propertyValues.concat(key))].sort();
  });

  if (propertyValues.length === 3) {
    [propertyValues[0], propertyValues[1]] = [
      propertyValues[1],
      propertyValues[0],
    ];
  }

  //refs
  const emojiSelectorRef = useRef(null);
  useClickOutside(emojiSelectorRef, () => setOpenEmojis(false));

  return (
    <div
      className={styles['like-item'] + ' ' + styles['is-reaction']}
    >
      {openEmojis && (
        <div ref={emojiSelectorRef}>
          <EmojisSelector
            sendEmoji={sendEmoji}
            unsendEmoji={unsendEmoji}
            setOpenEmojis={setOpenEmojis}
            likedItems={likedItems}
            activity={activity}
          />
        </div>
      )}

      {totalLikes > 0 ? (
        <div
          className={styles['emoji-container']}
          onClick={() => setOpenEmojis(true)}
        >
          <div className={styles['emoji-container-inner']}>
            {propertyValues &&
              propertyValues.reverse().map((emoji) => (
                <span
                  className={styles['emoji']}
                  key={activity.uuid + '-reaction-' + emoji}
                >
                  {emoji}
                </span>
              ))}
          </div>
          <span className={styles['total-likes']}>{totalLikes}</span>
        </div>
      ) : (
        <div className={styles['no-likes']} onClick={() => setOpenEmojis(true)}>
          <ThumbsUpIcon style={{ color: IconColors.Inactive }} />
        </div>
      )}
    </div>
  );
};
