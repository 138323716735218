// check to make sure the user wants to leave the room
import { useEffect } from 'react';
import { roomActions } from '_actions';
import { ESessionRoomSafety, ESessionRoomStatus } from '_types';
import { useWellSelector, useWellDispatch } from '_hooks';
import { roomConstants } from '_constants';
import { getServerTime, getSessionStartTime } from '_helpers';

export const useSessionRoomManager = () => {
  const dispatch = useWellDispatch();
  const { roomStore } = useWellSelector((state) => state);

  const {
    correctRoomSafety,
    correctRoom,
    currentRoom,
    sessionInfo,
    hasCompletedVideoCheck,
    userStatus,
    postSessionChecks,
  } = roomStore;

  // check to see if we can stay in this room
  useEffect(() => {
    // does this user need to go back to waiting room?
    if (correctRoomSafety !== ESessionRoomSafety.SAFE) {
      dispatch({
        type: roomConstants.SET_USER_STATUS,
        userStatus: ESessionRoomStatus.NEEDS_TO_WAIT,
      });
    }
    // is this user moving rooms?
    else if (
      correctRoom !== -1 &&
      currentRoom !== -1 &&
      correctRoom !== currentRoom
    )
      dispatch({
        type: roomConstants.SET_USER_STATUS,
        userStatus: ESessionRoomStatus.IS_MOVING_ROOMS,
      });
    // worried this can be a race condition
    else if (
      correctRoomSafety === ESessionRoomSafety.SAFE &&
      userStatus === ESessionRoomStatus.NEEDS_TO_WAIT
    ) {
      dispatch({
        type: roomConstants.SET_USER_STATUS,
        userStatus: ESessionRoomStatus.NONE,
      });
    }
  }, [dispatch, correctRoomSafety, correctRoom, currentRoom, userStatus]);

  // if not in a room, has completed the video check & has polled once to get the correct room, we can enter
  useEffect(() => {
    if (
      hasCompletedVideoCheck &&
      correctRoom !== -1 &&
      userStatus !== ESessionRoomStatus.IN_ROOM &&
      userStatus !== ESessionRoomStatus.NEEDS_TO_WAIT &&
      correctRoomSafety === ESessionRoomSafety.SAFE
    ) {
      // this fails on kick sometimes
      dispatch(roomActions.enterRoom(sessionInfo.uuid, correctRoom));
    }
  }, [
    correctRoom,
    correctRoomSafety,
    dispatch,
    hasCompletedVideoCheck,
    sessionInfo.uuid,
    userStatus,
  ]);

  // the first time we are IN_ROOM check to see if post session items
  useEffect(() => {
    if (
      userStatus === ESessionRoomStatus.IN_ROOM &&
      !postSessionChecks.has_already_checked
    ) {
      // check if we are after or before the 5 minute mark
      const timeToFiveMinuteMark = getSessionStartTime(sessionInfo)
        .clone()
        .add(5.1, 'm')
        .diff(getServerTime());

      // if already passed the 5 minute mark just check now
      if (timeToFiveMinuteMark < 0) {
        dispatch(roomActions.checkPostSessionCompletions(sessionInfo.uuid));
        return;
      }

      // else we need to wait to check
      const timeout = setTimeout(() => {
        dispatch(roomActions.checkPostSessionCompletions(sessionInfo.uuid));
      }, timeToFiveMinuteMark);

      return () => clearTimeout(timeout);
    }
  }, [
    dispatch,
    postSessionChecks.has_already_checked,
    sessionInfo,
    sessionInfo.uuid,
    userStatus,
  ]);
};
