import { WellInputWrapper } from '_components/WellItems';
import Datetime from 'react-datetime';
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    cancelBtn: {
      position: 'absolute',
      right: '1px',
      top: '-1px',
      fontWeight: 600,
      fontSize: '14px',
      cursor: 'pointer',
    },
    container: {
      maxWidth: '215px',
    }

  })
);


export const SeriesEndTimeInput = ({
  data,
  nonValidationData,
  setNonValidationData
}: any) => {

  const classes = useStyles();

  const handleChange = (e: any) => {
    setNonValidationData({ ...nonValidationData, seriesEndTime: e });
  };

  const valid = (current: any) => {
    return current.isAfter(data.startTime.value);
  };

  const resetTime = () => {
    setNonValidationData({ ...nonValidationData, seriesEndTime: null });
  };

  return (

    <div>
      <WellInputWrapper
        containerClassName={classes.container}
      >
        <>
          {nonValidationData.seriesEndTime !== null &&
            <span onClick={resetTime} className={classes.cancelBtn}>X</span>
          }
          <Datetime
            isValidDate={valid}
            value={nonValidationData.seriesEndTime}
            onChange={handleChange}
            timeFormat={false}
            renderInput={(props: any) => {
              return (
                <input {...props} value={nonValidationData.seriesEndTime !== null ? props.value : ''} />
              );
            }}
          />
        </>
      </WellInputWrapper>

    </div>

  );
}
