import styles from './GroupInfoHeader.module.css';
import { useGetImgixVersion, useGroupInfo, useWellSelector } from '_hooks';
import { WellButton, WellMeatBalls } from '_components';
import ShareIcon from '_images/white-share-icon.png';

export const GroupInfoHeader = () => {
  const { userStore } = useWellSelector((state) => state);

  const {
    group,
    subscribeToGroup,
    unsubscribeToGroup,
    deleteGroup,
    editGroup,
    reportGroup,
    goToPreferences,
    openShareModal,
  } = useGroupInfo();

  const groupImage = useGetImgixVersion(group.pic, {
    w: window.innerWidth,
    h: 640,
    fit: 'crop',
    crop: 'entropy',
  });

  return (
    <div className={styles['parent']}>
      {group.pic ? (
        <div
          className={styles['group-info-parallax']}
          style={{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
              groupImage +
              ')',
          }}
        ></div>
      ) : (
        <div
          className={styles['group-info-parallax'] + ' ' + styles['no-image']}
        ></div>
      )}
      <div className={styles['parallax-content'] + ' container'}>
        <div className={styles['parallax-row']}>
          <h1 className={styles['group-title']}>{group.title}</h1>
          <p className={styles['group-desc']}>{group.description}</p>
          {group.visible && (
            <Members />
          )}
        </div>

        <div className={styles['bottom-row']}>
          <div className={styles['btn-row']}>
            {!group.am_i_creator &&
              userStore.loggedIn &&
              group.uuid !== process.env.REACT_APP_WELCOME_GROUP_UUID && (
                <WellButton
                  type={'well-btn-orange'}
                  className={styles['subscribe-btn']}
                  btnText={!group.am_i_joined ? 'JOIN WELL' : 'LEAVE WELL'}
                  btnOnClick={
                    !group.am_i_joined ? subscribeToGroup : unsubscribeToGroup
                  }
                />
              )}

            {userStore.loggedIn && (
              <div className={styles['options-btn']}>
                <WellMeatBalls
                  isLeft={true}
                  dropDownKey={'group-info-' + group.uuid}
                  dropDownItems={[
                    userStore.isAdmin
                      ? {
                          text: 'Edit Well',
                          onClick: editGroup,
                          disable: false,
                        }
                      : {},
                    userStore.isAdmin && !group.is_default_group
                      ? {
                          text: 'Delete Well',
                          onClick: deleteGroup,
                          disable: false,
                        }
                      : {},
                    {
                      text: 'Report Well',
                      onClick: reportGroup,
                      disable: false,
                    },
                    group.am_i_joined
                      ? {
                          text: 'Settings',
                          onClick: goToPreferences,
                          disable: false,
                        }
                      : {},
                  ]}
                  style={{
                    color: 'white',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                />
              </div>
            )}

            {group.am_i_joined && (
              <div className={styles['email-link']} onClick={openShareModal}>
                <img
                  src={ShareIcon}
                  className={styles['share-icon']}
                  alt={'ShareWell Share Icon'}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <svg
        className={styles['wave-svg-desktop']}
        viewBox="0 0 500 150"
        width={'100%'}
        height={'100'}
        preserveAspectRatio="none"
      >
        <path d="M0.00,49.98 C232.22,-105.09 264.95,282.72 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"></path>
      </svg>
    </div>
  );
};

const noShowNumber = [
  'b4d33080-f048-47c8-a3fe-f3803d3e8861',
  '07c020c7-338f-4d8d-ba15-06d29be6e537',
];

const Members = () => {
  const { group } = useGroupInfo();

  const uuid =
    process.env.REACT_APP_NODE_ENV === 'live'
      ? group.uuid
      : '3526331f-4f76-47d0-8046-cf40cff6cf33';

  return (
    <div className={styles['members-container']}>
      {!noShowNumber.includes(group.uuid) && (
        <div className={styles['member-number']}>
          {Math.ceil(group.num_users_joined / 100) * 100}+ <span>members</span>
        </div>
      )}
      <div className={styles['image-container']}>
        <img
          src={
            process.env.REACT_APP_ASSETS_URL + '/site/groups/' + uuid + '.svg'
          }
          alt={group.title + ' members'}
          className={styles['member-image']}
        />
      </div>
    </div>
  );
};

