import styles from './LegalDocuments.module.css';

import { WellHeader } from '_components';

export const SafetyResources = () => {
  return (
    <div className={styles['legal-document']}>
      <WellHeader title={'Additional Mental Health Support and Resources'} />

      <div className={styles['legal-document-container'] + ' container'}>
        <p>
          Sharewell understands that it’s not always comfortable or easy for
          someone to seek support. A common stigma and myth about mental health
          is that seeking help is a weakness, but here at Sharewell, we see it
          as strong people advocating for themselves. You’re looking out for
          yourself, and we love that for you. We believe everyone deserves to
          have their stories heard by someone who understands. Peer support is
          only one of the many resources you incorporate into your healing
          journey.
        </p>
        <p>
          This page is here for anyone seeking additional support. Finding
          resources can be a struggle if one doesn’t know what to look for, how
          to look for it, or if finding an affordable option is a barrier. Below
          you will find a brief description of different options that exist as
          well as direct resources. We hope this page helps you discover
          additional options and build a plan that is right for you.
        </p>
        <h3 className={styles['h3-resources']}>Crisis Hotlines / Immediate Support</h3>
        <p>
          Crisis hotlines are not reserved exclusively for life-threatening
          “911” situations. They also serve as immediate emotional support for
          non-life-threatening situations - Plus advocates on the hotlines are
          trained and qualified to help you find local resources in your
          community. For someone who is experiencing a significant impairment in
          functioning due to feeling overwhelmed and their usual coping skills
          are not working; Calling a hotline unique to their situation is a good
          choice. A few examples of non-life-threatening situations are panic
          attacks, receiving sudden bad news, or coping with emotional/verbal
          domestic abuse.
        </p>
        <p>
          Please see the list below for immediate resources. All are free,
          anonymous, and confidential. Please reach out if you are in need, you
          deserve support.
        </p>
        <h4>Suicidal Thoughts or Ideation</h4>
        <p>
          If you are struggling with suicidal thoughts or ideation, it's
          important to seek immediate help. You don't have to go through this
          alone. Here are some resources that can provide support:
        </p>
        <h4>National Suicide Prevention Lifeline: 1-800-273-TALK (8255) </h4>
        <ul>
          <li>
            Call to speak with a trained counselor. The hotline is available
            24/7 and is free and confidential.
          </li>
        </ul>
        <h4>Crisis Text Line: Text HOME to 741741</h4>
        <ul>
          <li>
            Connect with a trained crisis counselor via text message. The
            service is available 24/7 and is free.
          </li>
        </ul>
        <h4>Your healthcare provider:</h4>
        <ul>
          <li>
            Contact your healthcare provider or mental health professional for
            assistance. They can provide guidance on treatment options and
            referrals to resources in your area.
          </li>
        </ul>
        <h4>Emergency services: Call 911</h4>
        <ul>
          <li>
            If you are in immediate danger, call 911 or go to your nearest
            emergency room.
          </li>
        </ul>
        <p>
          Remember, seeking help is a sign of strength and can make a
          significant difference in your life. You don't have to struggle with
          suicidal thoughts on your own. There are people who care and are ready
          to help you.
        </p>
        <h4>Domestic Violence </h4>
        <p>
          If someone is experiencing domestic violence and needs help finding a
          local shelter, there are several resources available.
        </p>
        <h4>
          National Domestic Violence Hotline: 1-800-799-SAFE (7233) or
          1-800-787-3224 (TTY)
        </h4>
        <ul>
          <li>
            Call to connect with a trained advocate who can help you find a
            local shelter or support services.
          </li>
        </ul>
        <h4>
          State or Local Domestic Violence Hotline:{' '}
          <a target='_blank' href='https://www.thehotline.org/help/'>
            https://www.thehotline.org/help/
          </a>
        </h4>
        <ul>
          <li>
            Many states and local communities have their own domestic violence
            hotlines that can provide information about local resources,
            including shelters. You can find a list of state hotlines at the
            National Domestic Violence Hotline website.
          </li>
        </ul>
        <h4>Safe Horizon: 1-800-621-HOPE (4673)</h4>
        <ul>
          <li>
            Safe Horizon is a non-profit organization that provides assistance
            to victims of domestic violence, including help finding a local
            shelter. Call their hotline to connect with a trained advocate.
          </li>
        </ul>
        <h4>
          National Network to End Domestic Violence: 1-800-799-SAFE (7233)
        </h4>
        <ul>
          <li>
            The National Network to End Domestic Violence has a searchable
            database of domestic violence service providers across the United
            States. You can search for a local shelter or service provider on
            their{' '}
            <a href='https://nnedv.org/get-help/' target='_blank'>
              website
            </a>{' '}
            or call their hotline for assistance.
          </li>
        </ul>
        <p>
          It's important to remember that if you or someone you know is in
          immediate danger, call 911 or your local emergency services. Seeking
          help is a critical step in getting to a safe and healthy place.
        </p>
        <h3 className={styles['h3-resources']}>Other Helplines, Warm Lines and/or Text Lines</h3>
        <p>
          If you feel the resources above do not suit the situation you’re faced
          with, there are many other helplines, warm lines and text lines
          available to you. For the most part, these lines are staffed by Peer
          Support (individuals who have lived experience with mental health
          struggles or have utilized crisis support themselves in their
          recovery) and also Crisis Counselors. Each agency is different, but
          expect the advocate to designate at least 20 minutes to talking about
          whatever is on your mind (often longer in text form). This is a good
          option for all sorts of non-emergency mental health concerns.
        </p>
        <p>
          If you find yourself needing a lot of extra support during a messy
          chapter, don’t hesitate to reach out to different lines across the
          nation in order to interact many times in a day. Unique perspectives
          from each person you speak to may also benefit you in making progress
          and taking steps forward with what you’re going through.
        </p>
        <h4>Crisis Text Line: Text HOME to 741741</h4>
        <ul>
          <li>
            This free, 24/7 service offers support to people in crisis via text
            message. Text HOME to 741741 to connect with a trained crisis
            counselor.
          </li>
        </ul>
        <h4>The Warmline Directory: warmline.org</h4>
        <ul>
          <li>
            The Warmline Directory is a database of non-emergency helplines that
            provide peer support and resources for people with mental health
            concerns. Visit their website at warmline.org to find a helpline in
            your state.
          </li>
        </ul>
        <h4>National Suicide Prevention Lifeline: 1-800-273-TALK (8255)</h4>
        <ul>
          <li>
            This resource is not just for people in suicidal crisis but also for
            anyone in distress. It’s also a resource for loved ones to learn
            about prevention and best practices to help others contemplating
            suicide.
          </li>
        </ul>
        <h4>National Hopeline Network: 1-800-SUICIDE (1-800-784-2433)</h4>
        <ul>
          <li>
            Connect to a network of crisis centers that provide 24/7 phone-based
            support for people experiencing emotional distress or suicidal
            thoughts.
          </li>
        </ul>
        <h4>SAMHSA National Helpline: 1-800-662-HELP (1-800-662-4357)</h4>
        <ul>
          <li>
            The Helpline is a free, confidential information and referral
            service for people facing mental health and/or substance use
            disorders.
          </li>
        </ul>
        <h4>The Trevor Project: 1-866-488-7386</h4>
        <ul>
          <li>
            This organization provides crisis intervention and suicide
            prevention services to LGBTQ+ youth via phone, text, or chat. Call
            1-866-488-7386 or text START to 678678 to connect with a counselor.
          </li>
        </ul>
        <h4>
          The National Alliance on Mental Illness (NAMI) Helpline:
          1-800-950-NAMI (1-800-950-6264)
        </h4>
        <ul>
          <li>
            NAMI provides information, resources, and support for people living
            with mental health conditions and their families. Call to connect
            with a trained volunteer.
          </li>
        </ul>
        <h4>Veterans Crisis Line: 1-800-273-8255 and press 1</h4>
        <ul>
          <li>
            This service provides free, confidential support to veterans,
            active-duty service members, and their families who are in crisis.
            Call 1-800-273-8255 and press 1, text 838255, or chat online to
            connect with a trained crisis counselor.
          </li>
        </ul>
        <h4>
          Substance Abuse and Mental Health Services Administration (SAMHSA)
          Disaster Distress Helpline: 1-800-985-5990
        </h4>
        <ul>
          <li>
            This helpline provides 24/7 crisis counseling and support for people
            experiencing emotional distress related to natural or human-caused
            disasters. Call 1-800-985-5990 or text TalkWithUs to 66746 to
            connect with a trained crisis counselor.
          </li>
        </ul>
        <h4>Eating Disorder Hope Helpline: 1-888-985-7211</h4>
        <ul>
          <li>
            This helpline provides support and resources for people struggling
            with eating disorders and their families. Call to connect with a
            trained representative.
          </li>
        </ul>
        <h3 className={styles['h3-resources']}>
          In Person Peer Support / Living Room / Crisis Walk or Drop-in Services
        </h3>
        <p>
          If calling a hotline feels too disconnected or impersonal, then
          in-person options may work for you. Many agencies have a designated,
          safe, and protected Common area (often furnished much like a Living
          Room space) that anyone can simply walk in to talk to Certified Peer
          Support Specialists or Crisis Counselors who are on staff that day. A
          wonderful feature is that these visits are free, utilized as needed by
          you, require no scheduling, and you do not have to share any personal
          information such as your name or address. The goal of such spaces is
          Harm Reduction which means they aren’t looking to hospitalize people
          against their will. Advocates' primary goal is to help de-escalate the
          individual and help them return to a baseline in which they feel less
          overwhelmed and capable of functioning again. There are many ways to
          find such a place in your area:
        </p>
        <ul>
          <li>
            <b>Directly call the NAMI national hotline</b>, or use the search
            feature on the NAMI website (https://nami.org/findsupport) and call
            the location nearest to you. Along with potential walk-in living
            rooms, most NAMI locations also host peer support groups much like
            what is offered on Sharewell. It’s a good way to find time slots
            that fit your schedule, but also connect you with local resources
            and in person support.{' '}
          </li>

          <li>
            <b>
              Google search your locations and variations of “Walk-in peer
              support”
            </b>
            , “Drop-in mental health crisis community center”, “Mental health
            living room”, “In person crisis support”, or “Non-emergency in
            person crisis support.” Walk-in services go by many names and each
            agency may call it something different across the nation, hence
            searching variations since it may be different depending where you
            are. Bonus? You get to discover other resources in your area that
            may be useful to you.
          </li>

          <li>
            <b>Call your state or county’s health department.</b> You can
            discover this number by google searching something like “(insert
            your county) mental health department health.” The website to look
            for in the search results should end in “.gov” (ex:
            Sandiegocounty.gov)
          </li>
        </ul>
        <h4>
          If you are unable to find a helpline that meets your specific needs:
        </h4>
        <ul>
          <li>
            <b>Seek out local mental health resources</b>: Look for mental
            health clinics or counseling services in your area. You can use
            online directories or search engines to find options near you. If
            you have health insurance, check with your provider to see what
            mental health services are covered.
          </li>
          <li>
            <b>Talk to your healthcare provider</b>: Your primary care physician
            or mental health provider may be able to offer additional resources
            or referrals to specialists who can provide support.
          </li>
          <li>
            <b>Consider online therapy</b>: Many online therapy platforms offer
            a variety of mental health services, including individual counseling
            and support groups. Look for platforms that are accredited and
            staffed by licensed professionals.
          </li>
        </ul>
      </div>
    </div>
  );
};
