import { useState, useEffect, useCallback } from 'react';
import { useWellApp } from '_hooks';
import { IUser } from '_types';

export const useGetUsersInfo = (
  usersToView: string[],
  paginationLimit: number
) => {
  const [userList, setUserList] = useState<IUser[]>([]);

  const [userListPagination, setUserListPagination] = useState({
    curOffset: 0,
    isDone: false,
  });
  const [userListLoading, setUserListLoading] = useState(true);
  const [userListLoadingMore, setUserListLoadingMore] = useState(false);

  const { getUserInformation } = useWellApp();

  // get member info
  useEffect(() => {
    if (!usersToView) return;
    setUserListLoading(true);

    // use promise all here so it is concurrent
    Promise.all(
      usersToView
        .slice(0, paginationLimit)
        .map((user) => getUserInformation(user, true))
    )
      .then((response: IUser[]) => {
        handleSuccess(response, false);
        return Promise.resolve(true);
      })
      .catch((error) => {
        setUserListPagination((prev) => {
          return { ...prev, isDone: true };
        });
        return Promise.resolve(false);
      })
      .finally(() => setUserListLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccess = useCallback(
    (response: IUser[], isAddition: boolean) => {
      if (response.length > 0) {
        if (response.length === paginationLimit) {
          setUserListPagination((prev) => {
            return {
              ...prev,
              curOffset: prev.curOffset + paginationLimit,
              isDone: false,
            };
          });
        } else {
          setUserListPagination({
            curOffset: 0,
            isDone: true,
          });
        }

        if (!isAddition)
          setUserList(
            response.filter((user: IUser) => user !== undefined).reverse()
          );
        else
          setUserList((prev) => {
            return [
              ...prev,
              ...response.filter((user: IUser) => user !== undefined).reverse(),
            ];
          });
      } else {
        setUserListPagination({
          curOffset: 0,
          isDone: true,
        });
      }
    },
    [paginationLimit]
  );

  // load more of the users
  const loadMoreUsers = useCallback(() => {
    if (userListPagination.isDone || userListLoadingMore || userListLoading)
      return false;

    setUserListLoadingMore(true);

    Promise.all(
      usersToView
        .slice(
          userListPagination.curOffset,
          userListPagination.curOffset + paginationLimit
        )
        .map((user) => getUserInformation(user, true))
    )
      .then((response) => {
        handleSuccess(response, true);
        return Promise.resolve(true);
      })
      .catch((error) => {
        setUserListPagination((prev) => {
          return { ...prev, isDone: true };
        });
        return Promise.resolve(false);
      })
      .finally(() => {
        setUserListLoadingMore(false);
      });
  }, [
    getUserInformation,
    handleSuccess,
    paginationLimit,
    userListLoading,
    userListLoadingMore,
    userListPagination.curOffset,
    userListPagination.isDone,
    usersToView,
  ]);

  return {
    userList,
    userListPagination,
    loadMoreUsers,
    userListLoading,
    userListLoadingMore,
  };
};
