import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { componentActions, creatorActions } from '_actions';
import { notificationConstants } from '_constants';
import { ISeries } from '_types';

export const useSeriesOptions = (series: ISeries) => {
  const dispatch = useDispatch();

  // delete a series
  const deleteSeries = useCallback((callback) => {
    dispatch(
      componentActions.openModal({
        title: 'End this Series?',
        body: notificationConstants.SERIES_DELETE_NOTICE,
        modalBodyStyle: { textAlign: 'center' },
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        yesText: 'DELETE',
        noText: 'CANCEL',
        afterText: notificationConstants.SERIES_DELETE_NOTICE_AFTER,
        yesClick: async () => {

          const { uuid } = series;
          const response: any = await dispatch(
            creatorActions.deleteSeries(uuid)
          );

          if(callback) callback();

          return Promise.resolve(response);
        },
        noClick: () => {},
      })
    );
  }, [dispatch, series]);

  return {
    deleteSeries,
  };
};
