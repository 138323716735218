import styles from './BillingBoilerPlate.module.css';
import { IUserSubscription, ISubscriptionType, IDiscountCode } from '_types';
import moment from 'moment-timezone';

// if the user does not have a subscription
export const BillingPricePayNow = ({
  subscriptionType,
}: {
  subscriptionType: ISubscriptionType;
}) => {
  return (
    <>
      <div className={styles['billing-row'] + ' ' + styles['bold']}>
        <div className={styles['left']}>Billed Today</div>
        <div className={styles['right']}>
          {subscriptionType.slashed_price && (
            <span
              className={styles['strike'] + ' ' + styles['strike-left-price']}
            >
              {' '}
              ${parseInt(subscriptionType.slashed_price).toFixed(2)}
            </span>
          )}
          ${parseFloat(subscriptionType.price).toFixed(2)}
        </div>
      </div>
    </>
  );
};

// <div className={styles['billing-row']}>
//   <div className={styles['left']}>Cost per month</div>
//   <div className={styles['right']}>
//     {subscriptionType.slashed_price && (
//       <span
//         className={styles['strike'] + ' ' + styles['strike-left-price']}
//       >
//         $
//         {(
//           parseFloat(subscriptionType.slashed_price) /
//           subscriptionType.months_off
//         ).toFixed(2)}
//       </span>
//     )}
//     $
//     {(
//       parseFloat(subscriptionType.price) / subscriptionType.months_off
//     ).toFixed(2)}
//   </div>
// </div>

// if the user does not have a subscription
export const BillingPricePayMonth = ({
  subscriptionType,
}: {
  subscriptionType: ISubscriptionType;
}) => {
  return (
    <>
      <div className={styles['billing-row']}>
        <div className={styles['left']}>1 Month of Unlimited Peer Support</div>
        <div className={styles['right']}>Subscription</div>
      </div>

      <div className={styles['billing-row'] + ' ' + styles['bold']}>
        <div className={styles['left']}>Monthly Membership Due</div>
        <div className={styles['right']}>
          {subscriptionType.slashed_price && (
            <span
              className={styles['strike'] + ' ' + styles['strike-left-price']}
            >
              {' '}
              ${parseInt(subscriptionType.slashed_price).toFixed(2)}
            </span>
          )}
          ${parseFloat(subscriptionType.price).toFixed(2)}
        </div>
      </div>
    </>
  );
};

// if the user already has a subscription
export const BillingPriceFuture = ({
  userSubscription,
  subscriptionType,
}: {
  userSubscription: IUserSubscription;
  subscriptionType: ISubscriptionType;
}) => {
  const nextBillDate =
    userSubscription.current_period_end !== null
      ? moment(userSubscription.current_period_end)
      : userSubscription.grace_period_end !== null
      ? moment(userSubscription.grace_period_end)
      : moment();

  const price = parseFloat(subscriptionType.price).toFixed(2);

  return (
    <div className={styles['container']}>
      <div className={styles['billing-row'] + ' ' + styles['bold']}>
        <div className={styles['left']}>Billed Today:</div>
        <div className={styles['right']}>$0.00</div>
      </div>
      <div className={styles['billing-row']}>
        <div className={styles['left']}>
          Billed on {nextBillDate.format('MMM D, YYYY')}:
        </div>
        <div className={styles['right']}>${price}</div>
      </div>
    </div>
  );
};

// user has a discount only can be during onboarding
export const BillingPriceFreeMonths = ({
  subscriptionType,
  discountCode,
}: {
  subscriptionType: ISubscriptionType;
  discountCode: IDiscountCode;
}) => {
  const price = parseFloat(subscriptionType.price).toFixed(2);

  return (
    <div className={styles['container']}>
      <div className={styles['billing-row'] + ' ' + styles['bold']}>
        <div className={styles['left']}>Billed Today:</div>
        <div className={styles['right'] + ' ' + styles['strike']}>${price}</div>
      </div>

      <>
        <div className={styles['billing-row'] + ' ' + styles['discount']}>
          <div className={styles['left']}>Discount:</div>
          <div className={styles['right']}>-100%</div>
        </div>
        <div className={styles['line']}></div>
        <div className={styles['billing-row'] + ' ' + styles['bold']}>
          <div className={styles['left']}>
            First {discountCode.months_off}{' '}
            {discountCode.months_off === 1 ? 'Month' : 'Months'} Total:
          </div>
          <div className={styles['right']}>$0.00</div>
        </div>
      </>
    </div>
  );
};

//////////////////////////////////////////////////////////

interface IBillingNotice {
  subscriptionType: ISubscriptionType;
  userSubscription: IUserSubscription;
}

// pay now or in the future
export const BillingNotice = ({
  subscriptionType,
  userSubscription,
}: IBillingNotice) => {
  return (
    <div className={styles['billing-notice']}>
      {!userSubscription.is_unlimited_subscribed ? (
        <>
          All sales are charged in USD and all sales are final unless canceled
          by {moment().add('30', 'days').format('MM-DD-YYYY')}. You will be
          charged ${subscriptionType.price} USD every{' '}
          {subscriptionType.months_off}{' '}
          {subscriptionType.months_off === 1 ? <>month</> : <>months</>} while
          the membership is active. <sup>*</sup>Must submit refund email to
          support@sharewellnow.com after
          canceling&nbsp;within&nbsp;30&nbsp;days.
        </>
      ) : (
        <>
          All sales are charged in USD and all sales are final. You will be
          charged ${subscriptionType.price} USD every{' '}
          {subscriptionType.months_off}{' '}
          {subscriptionType.months_off === 1 ? <>month</> : <>months</>} while
          the membership is active.
        </>
      )}
    </div>
  );
};

// if discount is 100% off
export const BillingNoticeMonthsOff = ({
  subscriptionType,
  discountCode,
}: {
  subscriptionType: ISubscriptionType;
  discountCode: IDiscountCode;
}) => {
  const nextPayment = moment().add(discountCode.months_off, 'months');

  return (
    <div className={styles['billing-notice']}>
      All sales are charged in USD and all sales are final. You will be charged
      ${subscriptionType.price} USD in {discountCode.months_off}{' '}
      {discountCode.months_off === 1 ? 'Month' : 'Months'} on{' '}
      {nextPayment.format('MM-DD-YYYY')}. You will be charged $
      {subscriptionType.price} USD every {subscriptionType.months_off} months
      thereafter while the membership is active.
    </div>
  );
};
