import React, { useState, useEffect, useCallback } from 'react';

import styles from './WellToolTip.module.css';
import ToolTip from '_images/tool-tip.png';
import ColorToolTip from '_images/tool-tip-color.png';

export const WellToolTip = ({ text, isColor, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  const onMouseEnter = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onMouseLeave = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  if (!text) return <></>;

  return (
    <div className={styles['well-tool-tip'] + ' ' + (className)}>
      <img
        className={
          styles['well-tool-tip-icon'] +
          ' ' +
          (isColor ? styles['colored'] : '')
        }
        src={!isColor ? ToolTip : ColorToolTip}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        alt={'Question Mark'}
      />
      <div
        className={
          styles['well-tool-tip-item'] + ' ' + (isOpen ? styles['open'] : '')
        }
      >
        <h4 className={styles['well-tool-tip-text']}>{text}</h4>
      </div>
    </div>
  );
};
