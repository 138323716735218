import styles from './HostBadges.module.css';

import HostBadgeOne from '_images/badges/host_1.svg';
import HostBadgeTwo from '_images/badges/host_2.svg';
import HostBadgeThree from '_images/badges/host_3.svg';
import PeerBadgeOne from '_images/badges/peer_1.svg';
import PeerBadgeTwo from '_images/badges/peer_2.svg';
import PeerBadgeThree from '_images/badges/peer_3.svg';
// import ExpertBadgeOne from '_images/badges/expert_1.svg';
import ExpertCheck from '_images/expert-check.svg';
import { badgeConstants } from '_constants';
import { IUser } from '_types';

export const HostBadges = ({
  selectedUserData,
}: {
  selectedUserData: IUser;
}) => {
  const { num_session_users_met, num_sessions_hosted, host_level } =
    selectedUserData;

  return (
    <>
      {num_session_users_met >= badgeConstants.PEERS_SUPPORTED_ONE &&
      num_session_users_met < badgeConstants.PEERS_SUPPORTED_TWO ? (
        <div className={styles['badge-item']}>
          <img
            src={PeerBadgeOne}
            className={styles['badge']}
            alt={'Peer Badge One'}
          />
          <span>Peer</span>
        </div>
      ) : num_session_users_met >= badgeConstants.PEERS_SUPPORTED_TWO &&
        num_session_users_met < badgeConstants.PEERS_SUPPORTED_THREE ? (
        <div className={styles['badge-item']}>
          <img
            src={PeerBadgeTwo}
            className={styles['badge']}
            alt={'Peer Badge Two'}
          />
          <span>StarPeer</span>
        </div>
      ) : num_session_users_met >= badgeConstants.PEERS_SUPPORTED_THREE ? (
        <div className={styles['badge-item']}>
          <img
            src={PeerBadgeThree}
            className={styles['badge']}
            alt={'Peer Badge Three'}
          />
          <span>SuperPeer</span>
        </div>
      ) : (
        <></>
      )}

      {num_sessions_hosted >= badgeConstants.HOSTED_ONE &&
      num_sessions_hosted < badgeConstants.HOSTED_TWO ? (
        <div className={styles['badge-item']}>
          <img
            src={HostBadgeOne}
            className={styles['badge']}
            alt={'Host Badge One'}
          />
          <span>Host</span>
        </div>
      ) : num_sessions_hosted >= badgeConstants.HOSTED_TWO &&
        num_sessions_hosted < badgeConstants.HOSTED_THREE ? (
        <div className={styles['badge-item']}>
          <img
            src={HostBadgeTwo}
            className={styles['badge']}
            alt={'Host Badge Two'}
          />
          <span>StarHost</span>
        </div>
      ) : num_sessions_hosted >= badgeConstants.HOSTED_THREE ? (
        <div className={styles['badge-item']}>
          <img
            src={HostBadgeThree}
            className={styles['badge']}
            alt={'Host Badge Three'}
          />
          <span>SuperHost</span>
        </div>
      ) : (
        <></>
      )}

      {/* {host_level > 1 &&
        <div className={styles['badge-item']}>
          <img src={ExpertCheck} className={styles['badge']} alt={'Expert Badge'} />
          <span>ExpertHost</span>
        </div>
      } */}
    </>
  );
};
