import styles from './SurveyQuestion.module.css';

// to delete when switch back to old survey
export const NPSQuestion = ({
  setAnswer,
  selected,
}: {
  setAnswer: (value: number) => void;
  selected: number | null;
}) => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['question']} style={{ color: '#2b7bb9' }}>
        How likely are you to recommend ShareWell?
      </h3>

      <div className={styles['answers-row']}>
        <NPSQuestionItem
          number={1}
          onClick={() => setAnswer(1)}
          isSelected={selected === 1}
          text={'Not At All Likely'}
        />
        <NPSQuestionItem
          number={2}
          onClick={() => setAnswer(2)}
          isSelected={selected === 2}
          text={''}
        />
        <NPSQuestionItem
          number={3}
          onClick={() => setAnswer(3)}
          isSelected={selected === 3}
          text={''}
        />
        <NPSQuestionItem
          number={4}
          onClick={() => setAnswer(4)}
          isSelected={selected === 4}
          text={''}
        />
        <NPSQuestionItem
          number={5}
          onClick={() => setAnswer(5)}
          isSelected={selected === 5}
          text={''}
        />
        <NPSQuestionItem
          number={6}
          onClick={() => setAnswer(6)}
          isSelected={selected === 6}
          text={''}
        />
        <NPSQuestionItem
          number={7}
          onClick={() => setAnswer(7)}
          isSelected={selected === 7}
          text={''}
        />
        <NPSQuestionItem
          number={8}
          onClick={() => setAnswer(8)}
          isSelected={selected === 8}
          text={''}
        />
        <NPSQuestionItem
          number={9}
          onClick={() => setAnswer(9)}
          isSelected={selected === 9}
          text={''}
        />
        <NPSQuestionItem
          number={10}
          onClick={() => setAnswer(10)}
          isSelected={selected === 10}
          text={'Highly Likely'}
        />
      </div>
    </div>
  );
};

// to delete when switch back to old survey
export const NPSQuestionItem = ({
  number,
  onClick,
  isSelected,
  text,
}: {
  number: number;
  onClick: () => void;
  isSelected: boolean;
  text: string;
}) => {
  return (
    <div
      className={
        styles['answer-item'] +
        ' ' +
        styles['nps-answer-item'] +
        ' ' +
        (text ? styles['nps-lower'] : '')
      }
      onClick={onClick}
    >
      <span>{number}</span>
      <div className={styles['answer-number']}>
        {isSelected ? (
          <img
            src={process.env.REACT_APP_ASSETS_URL + '/site/hosts/checkmark.svg'}
            className={styles['check']}
            alt={'Check Mark'}
          />
        ) : (
          <div className={styles['empty']}>
            <div className={styles['empty-inside']}></div>
          </div>
        )}
      </div>
      <div className={styles['nps-answer-text']}>{text}</div>
    </div>
  );
};

//////////////////////////////////////////////

export const SurveyQuestionGeneric = ({
  question,
  setAnswer,
  selected,
  questionColor,
  isOnboarding,
}: {
  question: string;
  setAnswer: (value: number) => void;
  selected: number | null;
  questionColor: string;
  isOnboarding: boolean;
}) => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['question']} style={{ color: questionColor }}>
        {question}
      </h3>

      <div className={styles['answers-row']}>
        {isOnboarding ? (
          <>
            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(1)}
              isSelected={selected === 1}
              text={'Not at All'}
            />
            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(2)}
              isSelected={selected === 2}
              text={'Some of the Time'}
            />
            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(3)}
              isSelected={selected === 3}
              text={'Most of the Time'}
            />

            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(4)}
              isSelected={selected === 4}
              text={'All of the Time'}
            />
          </>
        ) : (
          <>
            <SurveyQuestionGenericItem
              onClick={() => setAnswer(1)}
              isSelected={selected === 1}
              text={'Not at All'}
            />
            <SurveyQuestionGenericItem
              onClick={() => setAnswer(2)}
              isSelected={selected === 2}
              text={'Some of the Time'}
            />
            <SurveyQuestionGenericItem
              onClick={() => setAnswer(3)}
              isSelected={selected === 3}
              text={'Most of the Time'}
            />
            <SurveyQuestionGenericItem
              onClick={() => setAnswer(4)}
              isSelected={selected === 4}
              text={'All of the Time'}
            />
          </>
        )}
      </div>
    </div>
  );
};

export const PostSessionQuestionGeneric = ({
  question,
  setAnswer,
  selected,
  questionColor,
}: {
  question: string;
  setAnswer: (value: number) => void;
  selected: number | null;
  questionColor: string;
}) => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['question']} style={{ color: questionColor }}>
        {question}
      </h3>

      <div className={styles['answers-row']}>
        <>
          <SurveyQuestionItemOnboarding
            onClick={() => setAnswer(1)}
            isSelected={selected === 1}
            text={'I feel much better'}
          />
          <SurveyQuestionItemOnboarding
            onClick={() => setAnswer(2)}
            isSelected={selected === 2}
            text={'I feel slightly better'}
          />
          <SurveyQuestionItemOnboarding
            onClick={() => setAnswer(3)}
            isSelected={selected === 3}
            text={'Neutral'}
          />

          <SurveyQuestionItemOnboarding
            onClick={() => setAnswer(4)}
            isSelected={selected === 4}
            text={'I feel slightly worse'}
          />

          <SurveyQuestionItemOnboarding
            onClick={() => setAnswer(5)}
            isSelected={selected === 5}
            text={'I feel much worse'}
          />
        </>
      </div>
    </div>
  );
};

export const SurveyQuestionGenericItem = ({
  onClick,
  isSelected,
  text,
}: {
  onClick: () => void;
  isSelected: boolean;
  text: string;
}) => {
  return (
    <div className={styles['answer-item']} onClick={onClick}>
      <div className={styles['answer-number']}>
        {isSelected ? (
          <img
            src={process.env.REACT_APP_ASSETS_URL + '/site/hosts/checkmark.svg'}
            className={styles['check']}
            alt={'Check Mark'}
          />
        ) : (
          <div className={styles['empty']}>
            <div className={styles['empty-inside']}></div>
          </div>
        )}
      </div>
      <div className={styles['answer-text']}>{text}</div>
    </div>
  );
};

const faceArrayOff = [
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-0-off.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-1-off.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-2-off.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-3-off.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-4-off.svg',
];

const faceArrayOn = [
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-0-on.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-1-on.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-2-on.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-3-on.svg',
  process.env.REACT_APP_ASSETS_URL + '/site/session/face-4-on.svg',
];

export const SurveyQuestionFeeling = ({
  setAnswer,
  selected,
  questionColor,
  isOnboarding,
}: {
  setAnswer: (value: number) => void;
  selected: number | null;
  questionColor: string;
  isOnboarding: boolean;
}) => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['question']} style={{ color: questionColor }}>
        How are you feeling today?
      </h3>

      <div className={styles['answers-row']}>
        {isOnboarding ? (
          <>
            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(1)}
              isSelected={selected === 1}
              text={'Awful'}
            />
            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(2)}
              isSelected={selected === 2}
              text={'Bad'}
            />
            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(3)}
              isSelected={selected === 3}
              text={'Meh'}
            />

            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(4)}
              isSelected={selected === 4}
              text={'Good'}
            />

            <SurveyQuestionItemOnboarding
              onClick={() => setAnswer(5)}
              isSelected={selected === 5}
              text={'Awesome'}
            />
          </>
        ) : (
          <>
            <SurveyQuestionFeelingItemPost
              onClick={() => setAnswer(1)}
              isSelected={selected === 1}
              faceItem={0}
              text={'Awful'}
            />
            <SurveyQuestionFeelingItemPost
              onClick={() => setAnswer(2)}
              isSelected={selected === 2}
              faceItem={1}
              text={'Bad'}
            />
            <SurveyQuestionFeelingItemPost
              onClick={() => setAnswer(3)}
              isSelected={selected === 3}
              faceItem={2}
              text={'Meh'}
            />

            <SurveyQuestionFeelingItemPost
              onClick={() => setAnswer(4)}
              isSelected={selected === 4}
              faceItem={3}
              text={'Good'}
            />
            <SurveyQuestionFeelingItemPost
              onClick={() => setAnswer(5)}
              isSelected={selected === 5}
              faceItem={4}
              text={'Awesome'}
            />
          </>
        )}
      </div>
    </div>
  );
};

const SurveyQuestionFeelingItemPost = ({
  onClick,
  isSelected,
  faceItem,
  text,
}: {
  onClick: any;
  isSelected: boolean;
  faceItem: number;
  text: string;
}) => {
  return (
    <div className={styles['answer-item']} onClick={onClick}>
      <div className={styles['answer-face']}>
        <img
          src={isSelected ? faceArrayOn[faceItem] : faceArrayOff[faceItem]}
          className={styles['face']}
          alt={'Face'}
        />
      </div>
      <div className={styles['answer-text']}>{text}</div>
    </div>
  );
};

const SurveyQuestionItemOnboarding = ({
  onClick,
  isSelected,
  text,
}: {
  onClick: any;
  isSelected: boolean;
  text: string;
}) => {
  return (
    <div
      className={
        styles['answer-item-onboarding'] +
        ' ' +
        (isSelected ? styles['selected'] : '')
      }
      onClick={onClick}
    >
      <div className={styles['answer-text-onboarding']}>{text}</div>
    </div>
  );
};
