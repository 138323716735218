import { useState, useEffect, useCallback } from 'react';
import { stripeActions } from '_actions';
import { useWellDispatch } from '_hooks';
import { ISubscriptionActivity } from '_types';

// gets the current subscription
export const useSubscriptionActivity = () => {
  const dispatch = useWellDispatch();

  // starts empty, needs to be called if needed
  const [subscriptionActivity, setSubscriptionActivity] = useState<
    ISubscriptionActivity[]
  >([]);

  const getData = useCallback(async () => {
    dispatch(stripeActions.getSubscriptionActivity()).then((res) => {
      setSubscriptionActivity(res);
    });
  }, [dispatch]);

  // always start by getting the data
  useEffect(() => {
    getData();
  }, [getData]);

  return {
    subscriptionActivity,
    getData, // use for getting data again if needed
  };
};
