import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

export const wellLinkify = new LinkifyIt();
wellLinkify.tlds(tlds);

wellLinkify.add('@', {
  validate: function (text: any, pos: any, self: any) {
    var tail = text.slice(pos);

    if (!self.re.well) {
      self.re.well =  new RegExp(
        '^([a-zA-Z0-9_]){1,15}(?!_)(?=$|' + self.re.src_ZPCc + ')'
      );
    }
    if (self.re.well.test(tail)) {
      // Linkifier allows punctuation chars before prefix,
      // but we additionally disable `@` ("@@mention" is invalid)
      if (pos >= 2 && tail[pos - 2] === '@') {
        return false;
      }
      return tail.match(self.re.well)[0].length;
    }
    return 0;
  },
  normalize: function (match) {
    match.url = process.env.REACT_APP_SITE_URL + '/profile/' + match.url.replace(/^@/, '');
  }
});


export default wellLinkify;
