import styles from './ExternalSessionModal.module.css';
import { ModalWrapper } from '_components';
import { useWellDispatch, useWellSelector } from '_hooks';

import { componentsConstants } from '_constants';
import { useCallback } from 'react';

export const ExternalSessionModal = () => {
  const dispatch = useWellDispatch();

  const { isExternalSessionModalOpen } = useWellSelector(
    (state) => state.componentsStore.newModal
  );


  const closeModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isExternalSessionModalOpen: false,
    });
  }, [dispatch]);

  if(isExternalSessionModalOpen === false)
    return <></>;

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
      wrapperClassName={styles['wrapper']}
    >
      <>
        <h4 className={styles['title']}>Enjoy Your Session!</h4>
          <div className={styles['modal-content']}>
            <div className={styles['modal-body-container']}>
              <p>
                A new tab should have opened linking to the external link. If
                not, please click{' '}
                <a
                  className={styles['external-session-link']}
                  href={isExternalSessionModalOpen}
                >
                  here
                </a>{' '}
                to directly go to the session.
              </p>
            </div>
          </div>


      </>
    </ModalWrapper>
  );
};
