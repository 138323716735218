import { useState, useEffect, useRef, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupCreatorActions } from '_actions';
import {
  history,
  ValidateBatch,
  resizeImage,
  AnalyticsSendEvent,
} from '_helpers';

import { GroupCreate } from '.';

export const GroupCreateContext = createContext(null);

export const GroupCreateProvider = () => {
  const dispatch = useDispatch();
  const { userStore } = useSelector((state) => state);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    groupImage: '',
    imageName: '',
  });

  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [groupNameTaken, setGroupNameTaken] = useState(false);

  //refs
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (step < 0) history.push('/wells');
  }, [step]);

  //////////////// FUNCTIONS ////////////////////////////////

  const imageSet = (event) => {
    const groupImage = event.target.files[0];

    resizeImage({ file: event.target.files[0], maxSize: 6000 })
      .then((data) => {
        setFormData({
          ...formData,
          groupImage: data,
          imageName: groupImage.name,
        });
      })
      .catch((err) => {});
  };

  const handleSubmit = async () => {
    const { title, description } = formData;

    let validateArray = [
      { value: title, type: 'groupTitle', ref: titleRef.current },
      {
        value: description,
        type: 'groupDescription',
        ref: descriptionRef.current,
      },
    ];

    if (!ValidateBatch(validateArray)) return;

    setBtnIsLoading(true);

    let descriptionNoNewLines = description.replace(/(\r\n|\n|\r)/gm, '');

    const response = await dispatch(
      groupCreatorActions.createGroup({
        title,
        description: descriptionNoNewLines,
      })
    );

    if (response && response.group) {
      if (formData.groupImage)
        await dispatch(
          groupCreatorActions.updateGroupImage(
            response.group.uuid,
            formData.groupImage
          )
        );

      //add the group create event
      AnalyticsSendEvent('group_create', {
        user: userStore.userData.uuid,
        group: response.group.uuid,
      });

      //need to also add the group sub event
      AnalyticsSendEvent('group_subscribe', {
        user: userStore.userData.uuid,
        group: response.group.uuid,
      });

      history.push(
        '/well/' +
          response.group.title.toLowerCase().replaceAll(' ', '-') +
          '/' +
          response.group.uuid +
          '/sessions'
      );
    } else if (
      response.notice &&
      response.notice.name === 'GROUP_TITLE_IS_TAKEN'
    ) {
      setGroupNameTaken(true);
      setBtnIsLoading(false);
    }
  };

  //////////////// ROUTING FUNCTIONS ////////////////////////////////

  const decrementStep = () => {
    setStep(step - 1);
  };
  const incrementStep = () => {
    setStep(step + 1);
  };

  return (
    <GroupCreateContext.Provider
      value={{
        //state
        formData,
        setFormData,
        step,
        btnIsLoading,
        setBtnIsLoading,
        groupNameTaken,
        setGroupNameTaken,

        //refs
        titleRef,
        descriptionRef,

        //functions
        imageSet,
        handleSubmit,

        //routing functions
        incrementStep,
        decrementStep,
      }}
    >
      <GroupCreate />
    </GroupCreateContext.Provider>
  );
};
