import { useRef } from 'react';
import { useGroupCreate } from '_hooks';
import styles from './GroupCreate.module.css';
import {
  WellInput,
  WellTextarea,
  WellButton,
  WellLoader,
  WellImageInput,
} from '_components';

export const GroupCreate = () => {
  const { step } = useGroupCreate();

  return (
    <div className={styles['create-group-page']}>
      <div className={'container'}>
        <div className={styles['create-group-form']}>
          {step === 0 && <Step1 />}

          {step === 1 && <Step2 />}

          {step === 2 && <Step3 />}

          {step === 3 && <Step4 />}

          {step === 4 && <Step5 />}

          {step === 5 && <Step6 />}
        </div>
      </div>
    </div>
  );
};

const Step1 = () => {
  const { incrementStep, decrementStep } = useGroupCreate();

  return (
    <>
      <h2 className={styles['create-group-header']}>
        Welcome to the Well Creation Page
      </h2>
      <p>
        Well Creators are ShareWell members who are actively building a
        peer-support community around a topic of their choice. Being a Well
        Creator looks a little different for everyone.
      </p>
      <p>
        By creating this community you acknowledge that you have lived
        experience with the topic.
      </p>
      <div className={styles['btn-row']}>
        <WellButton
          className={styles['back-btn']}
          btnOnClick={decrementStep}
          type={'well-btn-new-secondary'}
          btnText={'CANCEL'}
          isLoading={false}
        />

        <WellButton
          className={styles['forward-btn']}
          btnOnClick={incrementStep}
          type={'well-btn-new-primary'}
          btnText={'START'}
          isLoading={false}
        />
      </div>
    </>
  );
};

const Step2 = () => {
  const { formData, setFormData, titleRef, incrementStep, decrementStep } =
    useGroupCreate();

  const handleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const submitTitle = () => {
    if (formData.title.length < 2) {
      return false;
    }
    incrementStep();
  };

  return (
    <>
      <h2 className={styles['create-group-header']}>
        What will your Well community's name be?
      </h2>
      <p className={styles['section-text']}>
        Choose a name that will give people a clear idea of what the Well is
        about. Feel free to get creative!
      </p>

      <WellInput
        placeHolder={'Title'}
        onChange={handleChange}
        value={formData.title}
        autoFocus={true}
        maxLength={50}
        readOnly={false}
        name={'title'}
        ref={titleRef}
        className={styles['title-input']}
        showCharacterCount={true}
        showMaxReached={true}
      />

      <div className={styles['btn-row']}>
        <WellButton
          className={styles['back-btn']}
          btnOnClick={decrementStep}
          type={'well-btn-new-secondary'}
          btnText={'BACK'}
          isLoading={false}
        />
        <WellButton
          className={
            styles['forward-btn'] +
            ' ' +
            (formData.title.length < 3 && styles['disabled'])
          }
          btnOnClick={submitTitle}
          type={'well-btn-new-primary'}
          btnText={'NEXT'}
          isLoading={false}
        />
      </div>
    </>
  );
};

const Step3 = () => {
  const {
    formData,
    setFormData,
    descriptionRef,
    incrementStep,
    decrementStep,
  } = useGroupCreate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      description: e.target.value.replace(/^(\r\n)|(\n)/, ''),
    });
  };

  return (
    <>
      <h2 className={styles['create-group-header']}>
        How would you describe your Well?
      </h2>
      <p className={styles['section-text']}>
        A good description will help new ShareWell members find and relate to
        the community topic. Please describe your Well in no less than 100
        characters.
      </p>
      <WellTextarea
        ref={descriptionRef}
        autoFocus={false}
        value={formData.description}
        readOnly={false}
        onChange={handleChange}
        name={'description'}
        maxLength={5000}
        placeholder={'Description'}
        showCharacterCount={true}
        className={styles['description-input']}
        showMaxReached={true}
        disallowEnter={true}
      />
      <div className={styles['btn-row']}>
        <WellButton
          className={styles['back-btn']}
          btnOnClick={decrementStep}
          type={'well-btn-new-secondary'}
          btnText={'BACK'}
          isLoading={false}
        />
        <WellButton
          className={
            styles['forward-btn'] +
            ' ' +
            (formData.description.length < 100 && styles['disabled'])
          }
          btnOnClick={incrementStep}
          type={'well-btn-new-primary'}
          btnText={'NEXT'}
          isLoading={false}
        />
      </div>
    </>
  );
};

const Step4 = () => {
  const { formData, incrementStep, decrementStep, imageSet } =
    useGroupCreate();

  return (
    <>
      <h2 className={styles['create-group-header']}>
        Add a custom image for this Well?
      </h2>
      <p className={styles['section-text']}>
        Images help users quickly find your Well. If you do not select an image,
        a default image will be used.
      </p>

      <WellImageInput
        className={styles['image-input']}
        label={'Group Image'}
        imageName={formData.imageName}
        handleChange={imageSet}
      />

      <div className={styles['btn-row']}>
        <WellButton
          className={styles['back-btn']}
          btnOnClick={decrementStep}
          type={'well-btn-new-secondary'}
          btnText={'BACK'}
          isLoading={false}
        />
        <WellButton
          className={styles['forward-btn']}
          btnOnClick={incrementStep}
          type={'well-btn-new-primary'}
          btnText={'NEXT'}
          isLoading={false}
        />
      </div>
    </>
  );
};

const Step5 = () => {
  const { incrementStep, decrementStep } = useGroupCreate();

  return (
    <>
      <h2 className={styles['create-group-header']}>
        Review ShareWell Guidelines
      </h2>
      <p className={styles['section-text'] + ' ' + styles['review-paragraph']}>
        Almost done! Just take a moment to review our guidelines. ShareWell is a
        safe community for peer support, this means all Wells should:
      </p>
      <ul className={styles['section-list']}>
        <li>• Stay respectful and compassionate in all interactions</li>
        <li>
          • Share lived experiences and resources but refrain from giving advice
          or diagnosing
        </li>
        <li>• Maintain confidentiality of all shared information.</li>
      </ul>
      <p className={styles['section-text']}>
        For more information reference our{' '}
        <a href='/safety-policy' target='_blank'>
          Safety Policy
        </a>
        .
      </p>
      <div className={styles['btn-row']}>
        <WellButton
          className={styles['back-btn']}
          btnOnClick={decrementStep}
          type={'well-btn-new-secondary'}
          btnText={'BACK'}
          isLoading={false}
        />
        <WellButton
          className={styles['forward-btn']}
          btnOnClick={incrementStep}
          type={'well-btn-new-primary'}
          btnText={'ACCEPT'}
          isLoading={false}
        />
      </div>
    </>
  );
};

/**
 * @NOTES:
 *  - This step also handles if the name is already taken
 *  - in this case need to allow input for new name without
 *  - going back
 */
const Step6 = () => {
  const {
    decrementStep,
    formData,
    setFormData,

    handleSubmit,
    btnIsLoading,
    groupNameTaken,
    setGroupNameTaken,
  } = useGroupCreate();
  const titleRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const submitTitle = () => {
    if (formData.title.length < 2) {
      return false;
    }
    setGroupNameTaken(false);
  };

  return (
    <>
      {/* Need to allow group name edit here */}
      {!groupNameTaken ? (
        <>
          <h2
            className={
              styles['create-group-header'] +
              ' ' +
              styles['extra-padding-bottom']
            }
          >
            Almost done! Here is a preview of your Well.
          </h2>

          <div className={styles['review-item-title']}>
            <h3>{formData.title}</h3>
          </div>
          <div className={styles['review-item-desc']}>
            <h3>{formData.description}</h3>
          </div>

          <div className={styles['review-item-note']}>
            <h4>
              Want to make this a private Well? Private communities are most
              suitable for companies, organizations or members with an existing
              client or follower base. Email{' '}
              <a href='mailto:wells@sharewellnow.com'>wells@sharewellnow.com</a>{' '}
              for more information about creating a private Well.{' '}
            </h4>
          </div>

          <div className={styles['btn-row']}>
            {btnIsLoading ? (
              <WellLoader heightWidth={100} />
            ) : (
              <>
                <WellButton
                  className={styles['back-btn']}
                  btnOnClick={decrementStep}
                  type={'well-btn-new-secondary'}
                  btnText={'EDIT'}
                  isLoading={false}
                />
                <WellButton
                  className={styles['forward-btn']}
                  btnOnClick={handleSubmit}
                  type={'well-btn-new-primary'}
                  btnText={'CREATE'}
                  isLoading={false}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h2
            className={
              styles['create-group-header'] + ' ' + styles['name-taken-notice']
            }
          >
            This Well name has already been taken. Please select another.
          </h2>
          <WellInput
            placeHolder={'Title'}
            onChange={handleChange}
            value={formData.title}
            autoFocus={true}
            maxLength={50}
            readOnly={false}
            name={'title'}
            ref={titleRef}
            className={styles['title-input']}
            showCharacterCount={true}
            showMaxReached={true}
          />
          <div className={styles['btn-row']}>
            <WellButton
              className={
                styles['forward-btn'] +
                ' ' +
                (formData.title.length < 3 && styles['disabled'])
              }
              btnOnClick={submitTitle}
              type={'well-btn-new-primary'}
              btnText={'NEXT'}
              isLoading={false}
            />
          </div>
        </>
      )}
    </>
  );
};
