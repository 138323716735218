export const badgeConstants = {
  HOSTED_ONE: 1,
  HOSTED_TWO: 10,
  HOSTED_THREE: 50,

  PEERS_SUPPORTED_ONE: 10,
  PEERS_SUPPORTED_TWO: 100,
  PEERS_SUPPORTED_THREE: 500,

  EXPERT_ONE: null,
};
