import styles from './RoomTimer.module.css';
import Countdown, { zeroPad } from 'react-countdown';
import { getServerTime, getSessionEndTime } from '_helpers';
import { useWellSelector } from '_hooks';

export const RoomTimerRedux = () => {
  const { roomStore } = useWellSelector((state) => state);
  const { sessionInfo } = roomStore;

  let endTime = getSessionEndTime(sessionInfo);

  // TODO: update
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => (
    <span>
      {completed ? (
        <span className={styles['end-text']}>{'SESSION ENDING'}</span>
      ) : (
        <span>
          Session Ends In: {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      )}
    </span>
  );

  return (
    <>
      <div className={styles['session-timer-container']}>
        <Countdown
          date={Date.now() + endTime.diff(getServerTime())}
          renderer={renderer}
          zeroPadTime={2}
        />
      </div>
    </>
  );
};
