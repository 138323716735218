import React from 'react';
import CSS from 'csstype';
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionWrapper: {
      display: 'flex',
      padding: '32px 0',
      [theme.breakpoints.up('lg')]: {
        padding: '64px 0',
      },

      '& h1': {
        fontSize: '34px',
        fontWeight: 600,
        lineHeight: 1.1,
        [theme.breakpoints.up('md')]: {
          fontSize: '78px',
        },
      },
      '& h3': {
        fontSize: '22px',
        fontWeight: 'bold',
        [theme.breakpoints.up('lg')]: {
          fontSize: '30px',
        },
      },
      '& h4': {
        fontSize: '20px',
        fontWeight: 'bold',
        [theme.breakpoints.up('lg')]: {
          fontSize: '22px',
        },
      },

      '& p': {
        fontSize: '20px',
        [theme.breakpoints.up('lg')]: {
          fontSize: '22px',
        },
      },
    },
    container: {
      width: '100%',
      marginLeft: 'auto',
      boxSizing: 'border-box',
      marginRight: 'auto',
      display: 'block',
      paddingLeft: '16px',
      paddingRight: '16px',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1200px',
      },
    },
  })
);

interface ISectionWrapper {
  children: React.ReactNode;
  style?: CSS.Properties;
}

export const SectionWrapper = ({ children, style }: ISectionWrapper) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.sectionWrapper} style={style}>
      <div className={classes.container}>{children}</div>
    </div>
  );
};
