import { useCallback, useState } from 'react';
import { useForm, useWellDispatch, useWellSelector } from '_hooks';
import { IFormItemString, ICreateAccount, KeyboardEvent } from '_types';
import { errorMsgs, regexItems, authConstants } from '_constants';
import { authActions } from '_actions';
import { AnalyticsSendEvent, history } from '_helpers';

// custom hook as might want to allow register on multiple pages
export const useRegister = (callBack: () => void) => {
  const dispatch = useWellDispatch();
  const { componentsStore } = useWellSelector((state) => state); // should we skip onboarding?
  const { redirect } = componentsStore.newModal.isRegisterModalOpen;

  const [data, setData] = useState({
    email: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.EMAIL,
      errorMsg: errorMsgs.EMAIL_INVALID,
    } as IFormItemString,
    username: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.USERNAME,
      errorMsg: errorMsgs.USERNAME_INVALID,
    } as IFormItemString,
    password: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.PASSWORD,
      errorMsg: errorMsgs.PASSWORD_LENGTH_INVALID,
    } as IFormItemString,
  });

  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { errors, validateAll, handleChange, inputRefs } = useForm(
    data,
    setData,
  );

  // handle submit of the form
  const handleSubmit = useCallback(() => {
    if (!validateAll()) return false;

    setIsLoading(true);
    dispatch({ type: authConstants.SET_IS_LOGGING_IN, isLoggingIn: true }); //set user is logging in

    const submitData: ICreateAccount = {
      username: data.username.value,
      name: data.username.value, // on register set display name to be username
      email: data.email.value,
      password: data.password.value,
    };

    return dispatch(authActions.register(submitData))
      .then(async () => {
        // send event to mixpanel
        AnalyticsSendEvent('auth_register_success', {
          username: data.username,
          email: data.email,
        });

        if (callBack) callBack();

        // profile is loaded in dispatch function, only need to push on history stack
        // only on the gift page do we not recirect
        if (redirect !== '') window.open(redirect, '_blank');
        if (
          history.location.pathname !== '/gift' &&
          history.location.pathname !== '/redeem'
        )
          history.push('/onboarding');
        // open redirect in new tab
      })
      .catch((error) => {
        setServerError(error);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch({ type: authConstants.SET_IS_LOGGING_IN, isLoggingIn: false });
      });
  }, [
    callBack,
    data.email,
    data.password.value,
    data.username,
    dispatch,
    redirect,
    validateAll,
  ]);

  // allow submit with enter key
  const onKeyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !isLoading) {
        handleSubmit();
      }
    },
    [handleSubmit, isLoading],
  );

  return {
    data,
    serverError,
    setServerError,
    isLoading,
    setIsLoading,
    errors,
    validateAll,
    handleChange,
    inputRefs,
    handleSubmit,
    onKeyDownHandler,
  };
};
