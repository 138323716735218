import React, { useRef, useEffect, useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
  ReactEventHandler,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
} from 'stream-chat-react';

import { MessageIconTrashcan, MessageIconFlag } from '_components';
import { roomReportingActions, componentActions } from '_actions';

import { useClickOutside, useWellSelector} from '_hooks';
import type { UserResponse } from 'stream-chat';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    actionsContainer: {
      position: 'absolute',
      // left: '-29px',
      top: '-55%',
      transform: 'translateY(-50%)',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,1.00)',
      boxShadow:
        'rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px',
      borderRadius: '10px',
    },
    dropdownItem: {
      position: 'relative',
      padding: '5px',
      '&:hover': {
        backgroundColor: 'rgba(15, 20, 25, 0.1)',
      },
    },
    trashIcon: {
      width: '16px',
      height: '16px',
    },
    editIcon: {
      width: '16px',
      height: '16px',
    },
    flagIcon: {
      width: '16px',
      height: '16px',
    },
  })
);

type Props = {
  setActionsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openThread?: ReactEventHandler;
  // setMessageActionUser: React.Dispatch<React.SetStateAction<string | undefined>>;
  thread?: boolean;
  user?: UserResponse | null;
  setEdit: ReactEventHandler;
};

export const CustomMessageActions: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  let classes = useStyles();

  const { isAdmin } = useWellSelector(state=>state.userStore)

  const dropDownRef = useRef(null);
  useClickOutside(dropDownRef, () => setActionsDropdownOpen(false));

  const { setEdit, setActionsDropdownOpen, user } = props;

  const { client } = useChatContext();
  const { message } = useMessageContext();
  const { channel } = useChannelStateContext();

  const isOwnUser = client.userID === user?.id;

  const handleDelete = () => {
    client.deleteMessage(message.id);
    setActionsDropdownOpen(false);
  };

  const handleFlag = () => {
    if (channel) {
      dispatch(
        componentActions.openModal({
          title: 'Report this message?',
          body: 'Please enter a reason for reporting this message.',
          noButtonStyle: { background: '#999999' },
          yesButtonStyle: {
            background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
          },
          isInput: true,
          yesText: 'REPORT',
          noText: 'CANCEL',
          placeholder: 'Reason...',
          afterText: 'Message has been reported.',
          yesClick: (reason: string) => {
            dispatch(
              roomReportingActions.reportSession(
                channel?.data?.session as string,
                'Flagged Chat: ' + message.text + '\n\nReason: ' + reason
              )
            );
            return Promise.resolve(1);
          },
          noClick: () => {},
        })
      );

      setActionsDropdownOpen(false);
    }
  };

  const handleEdit = (e: MouseEvent) => {
    e.preventDefault();
    setEdit(e);
    setActionsDropdownOpen(false);
  };

  return (
    <div className={classes.actionsContainer} ref={dropDownRef}>
      <div className={classes.dropdownItem} onClick={handleFlag}>
        <MessageIconFlag className={classes.flagIcon} />
      </div>
      {(isAdmin || isOwnUser) && (
        <>
          {/* <div className={classes.dropdownItem} onClick={handleEdit}>
            <MessageIconEdit className={classes.editIcon} />
          </div> */}
          <div className={classes.dropdownItem} onClick={handleDelete}>
            <MessageIconTrashcan className={classes.trashIcon} />
          </div>
        </>
      )}
    </div>
  );
};
