export const generalConstants = {
  //actions
  SET_SERVER_CONFIG: 'GENERAL_SET_SERVER_CONFIG',

  //SET_SERVER_TIME_OFFSET
  SET_SERVER_TIME_OFFSET: 'GENERAL_SET_SERVER_TIME_OFFSET',

  //Assets
  STATIC_ASSETS:
    'https://sharewell-space-live.sfo3.digitaloceanspaces.com/site',

  SPECIAL_UUID: '00000000-0000-0000-0000-000000000000',
};
