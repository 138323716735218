import { userService, emailService } from '_services';
import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';

export const emailActions = {
  confirmEmail,
  unsubscribeEmail,
  resendEmailConfirmation,
};

function confirmEmail(
  uuid: string,
  email: string,
  password: string,
  callBack: any
) {
  return (dispatch: Dispatch) => {
    emailService.confirmEmail(uuid, email, password).then(
      (response) => {
        if (response.notice) callBack(0);
        else callBack(1);
      },
      (error) => {
        callBack(0);
      }
    );
  };
}

function unsubscribeEmail(email: string, secret: string, callBack: any) {
  return (dispatch: Dispatch) => {
    emailService.unsubscribeEmail(email, secret).then(
      (response) => {
        if (response.notice) callBack(0);
        else callBack(1);
      },
      (error) => {
        callBack(0);
      }
    );
  };
}

function resendEmailConfirmation(email: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await userService.updateProfile({ email: email });
      if (response.notice) {
        toastr.error(response.notice.msg, '');
        return Promise.resolve(0);
      } else {
        // toastr.success('Email confirmation has been resent');
        return Promise.resolve(1);
      }
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
