import { groupService, generalService } from '_services';
import { generalActions } from '_actions';

export const groupForumActions = {
  postGet,
  postList,
  postCreate,
  postEdit,
  postEditImage,
  postLike,
  postDelete,
  postReport,
  tagUser,
  getPinnedPosts,
};

/**
 * Gets a single post
 * @param  {string} group  - The group uuid I am looking at
 */
function postGet(post) {
  return async () => {
    try {
      const response = await groupService.postGet(post);
      if (response.forum_post) return Promise.resolve(response.forum_post);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// get a number of posts for a group
function postList(group, limit, type, marker, parent) {
  return async () => {
    try {
      const response = await groupService.postList(
        group,
        limit,
        type,
        marker,
        parent
      );
      if (response.forum_post) return Promise.resolve(response.forum_post);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// create a post on the discussion board for a group
function postCreate(group, content, parent) {
  return async () => {
    try {
      const response = await groupService.postCreate(group, content, parent);
      if (response.forum_post) return Promise.resolve(response.forum_post);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// edit a post
function postEdit(uuid, content) {
  return async () => {
    try {
      await groupService.postEdit(uuid, content);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

/**
 * Edit a post
 * @param  {[type]} uuid    - uuid of the post to edit
 * @param  {[type]} content - content to replace with
 */
function postEditImage(uuid, picData) {
  return async () => {
    try {
      await groupService.postEditImage(uuid, picData);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

/**
 * Likes a post
 * @param  {string} post - uuid of the post we are liking
 * @param  {string} action - like|unlike
 */
function postLike(post, emoji, action) {
  return async () => {
    try {
      await groupService.postLike(post, emoji, action);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

/**
 * Delete a post
 */
function postDelete(uuid) {
  return async () => {
    try {
      await groupService.postDelete(uuid);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// report a post
function postReport(uuid, reason) {
  return async () => {
    try {
      await groupService.postReport(uuid, reason);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

/**
 * Tag a user
 */
function tagUser(post, user, action) {
  return async () => {
    try {
      await groupService.tagUser(post, user, action);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// load pinned posts
function getPinnedPosts() {
  return async (dispatch) => {
    try {
      const pinnedIds = await generalService.loadMarketingData(
        'pinned_forum_post_uuid'
      );

      if (
        pinnedIds &&
        pinnedIds.pinned_forum_post_uuid &&
        pinnedIds.pinned_forum_post_uuid.length > 0
      ) {
        const promises = [];
        let itemIds = [];

        var len = pinnedIds.pinned_forum_post_uuid.length;
        while (len--) {
          let parsed = JSON.parse(pinnedIds.pinned_forum_post_uuid[len]);
          itemIds.push(parsed);
          promises.push(dispatch(postGet(parsed)));
        }
        const items = await Promise.all(promises); //dont need to wait for these
        items.map((item) => (item.is_pinned = true));

        return Promise.resolve({
          items,
          itemIds,
        });
      }
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}


