import React from 'react';
import { Link } from 'react-router-dom';
import { useGetImgixVersion } from '_hooks';
import styles from './Member.module.css';
import { WellMeatBalls } from '_components';
import { useGroupInfo } from '_hooks';

export const Member = ({ userData, myUUID}) => {
  const pic = useGetImgixVersion(userData.pic, { h: 140, w: 140, fit: 'crop' });
  const { group, removeUser } = useGroupInfo();

  return (
    <div className={styles['member-item']}>
      {/* {group.am_i_creator && userData.uuid !== myUUID &&
        <div className={styles['user-options-container']}>
          <WellMeatBalls
            dropDownKey={999}
            dropDownItems={[
              {
                text: 'Remove User',
                onClick: () => {
                  removeUser(userData.uuid)
                },
                disable: false,
              },
            ]}
            style={{ top: '16px', color: '#70B5F4' }}
            isLeft={true}
          />
        </div>
      } */}
      <Link to={'/profile/' + userData.username} className={styles['member-link']}>
        <div className={styles['member-image-container']}>
          <img src={pic} className={styles['member-image']} alt={'ShareWell Member'}/>
        </div>

        <div className={styles['right-container']}>
          <div className={styles['member-name']}>{userData.username}</div>
          <div className={styles['metric-item']}>
            <h4 className={styles['metric-joined']}>Joined {userData.age} ago</h4>
          </div>


          <div className={styles['metric-item']}>
            {userData.num_sessions_hosted !== null && userData.num_sessions_hosted > 0 ? (
                <h3 className={styles['metric']}>
                  {userData.num_sessions_hosted}{' '}
                  {userData.num_sessions_hosted === 1 ? 'session' : 'sessions'} hosted
                </h3>
            ) : null}
            {userData.num_sessions_hosted !== null && userData.num_sessions_hosted > 0 && 
              userData.num_session_users_met !== null && userData.num_session_users_met > 0 &&
              <span className={styles['divider']}>·</span>
            }
            {userData.num_session_users_met !== null && userData.num_session_users_met > 0 ? (
                <h3 className={styles['metric']}>
                  {userData.num_session_users_met}{' '}
                  {userData.num_session_users_met === 1 ? 'peer' : 'peers'} supported
                </h3>
            ) : null}
          </div>


        </div>

      </Link>

    </div>
  );
};
