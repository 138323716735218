import { useState, useEffect } from 'react';
import { groupActions } from '_actions';
import { useWellSelector, useWellDispatch } from '_hooks';
import { IGroup } from '_types';

export const useFeaturedGroups = () => {
  const dispatch = useWellDispatch();
  const { generalStore } = useWellSelector((state) => state);

  const { client_featured_group_uuids } = generalStore;

  const [groupsToShow, setGroupsToShow] = useState<IGroup[]>([]);
  const [isLoadingFeaturedGroups, setIsLoadingFeaturedGroups] = useState(true);

  useEffect(() => {
    Promise.all(
      client_featured_group_uuids.map((uuid) =>
        dispatch(groupActions.getGroupInfo(uuid))
      )
    )
      .then((response) => {
        if (response.length > 0) setGroupsToShow(response);
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject();
      })
      .finally(() => setIsLoadingFeaturedGroups(false));
  }, [client_featured_group_uuids, dispatch]);

  return {
    groupsToShow,
    isLoadingFeaturedGroups,
  };
};
