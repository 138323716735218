import { groupService } from '_services';
// import { groupsConstants } from '_constants';
import { userActions } from '_actions';

export const groupActions = {
  getGroupList, //get all groups
  getGroupInfo, //get one group's info

  getGroupMemberData, //get member data by uuid list

  subscribeToGroup, //subscribe or unsubscribe to a group

  reportGroup, //If a user does not like a group

  badgeReset, // resets the badges
};

function getGroupList(search, limit, offset, sortType) {
  return async () => {
    try {
      const response = await groupService.getGroupList(
        search,
        limit,
        offset,
        sortType,
      );
      if (response.groups) {
        return Promise.resolve(response.groups);
      }
      //TODO: error handling
      else {
        return Promise.resolve(0);
      }

      // return Promise.resolve(group);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function getGroupInfo(uuid) {
  return async () => {
    try {
      const response = await groupService.getGroupInfo(uuid);
      if (!response.group) {
        return Promise.reject();
      }
      let group = response.group;
      return Promise.resolve(group);
    } catch (error) {
      return Promise.resolve();
    }
  };
}

/**
 * Gets the list of members of a group
 * TODO: Make so uses the known users list.
 */
function getGroupMemberData(uuidList) {
  return async (dispatch) => {
    try {
      let promises = [];

      var len = uuidList.length;
      while (len--) {
        await promises.push(dispatch(userActions.getUserInfo(uuidList[len])));
      }

      return Promise.resolve(promises);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function subscribeToGroup(uuid, action) {
  return async (dispatch) => {
    try {
      const response = await groupService.subscribeToGroup(uuid, action);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(-1);
    }
  };
}

function reportGroup(uuid, reason) {
  return async (dispatch) => {
    try {
      await groupService.reportGroup(uuid, reason);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(-1);
    }
  };
}

function badgeReset(group, type) {
  return async (dispatch) => {
    try {
      await groupService.badgeReset(group, type);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(-1);
    }
  };
}
