import { toastr } from 'react-redux-toastr';
import { AppDispatch } from '_reducers';
import { adminService } from '_services';
import { IDiscountCodeAdmin } from '_types';

export const adminDiscountCodeActions = {
  listDiscountCodes,
  createDiscountCode,
  updateDiscountCode,
};

function listDiscountCodes() {
  return async () => {
    try {
      const response = await adminService.listDiscountCodes();
      if (response.discount_code) {
        return Promise.resolve(response.discount_code as IDiscountCodeAdmin[]);
      }

      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  };
}

function createDiscountCode(monthsOff: number, code: string, isGift: boolean) {
  return async () => {
    try {
      const response = await adminService.createDiscountCode(
        monthsOff,
        code,
        isGift
      );
      if (response?.notice) toastr.error(response.notice.msg, '');

      return Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function updateDiscountCode(discountCode: number, isActive: boolean) {
  return async () => {
    try {
      await adminService.updateDiscountCode(discountCode, isActive);

      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  };
}
