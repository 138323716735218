import { getServerTime } from '_helpers';

const errorMsgs = {
  username: 'Invalid username',
  password: 'Passwords must be at least 6 characters long',
  firstName: 'Invalid name',
  lastName: 'Invalid last name',
  email: 'Invalid email',
  additionalInfo: 'Invalid user information',

  groupTitle: 'Group titles should be between 3 and 50 characters',
  groupDescription:
    'Group descriptions should be between 100 and 5000 characters',

  //sessions
  sessionTitle: 'Session titles should be between 3 and 50 characters',
  sessionDescription:
    'Session descriptions should be between 100 and 5000 characters',
  sessionDuration:
    'Invalid Duration. Sessions should be between 30 and 180 minutes.',
  numAttendees: 'Invalid Number of Attendees',
  sessionStartTime: 'Session start times can not start in the past or within the next 24 hours.',
  externalUrl: 'Invalid external URL',

  //user
  testimonial: 'Recommendation should be between 1 and 200 characters',
};

const regexItems = {
  phone: /^\+(?=.{7,15}$)([1-9][0-9]*)$/,
  password: /(.){6,32}$/,
  username: /^(?!_)[a-zA-Z0-9_]{1,29}[a-zA-Z0-9]$/,
  email: /^\S+@\S+\.\S+$/,
  firstName: /^.{1,}$/,
  lastName: /^.{0,}$/,
  smsCode: /^\d{4}$/,
  additionalInfo: /([.]){0,5000}$/,

  groupTitle: /(.){3,50}$/,
  groupDescription: /(.|\n){100,5000}$/,

  //sessions
  sessionTitle: /(.){3,50}$/,
  sessionDescription: /(.|\n){100,5000}$/,
  sessionStartTime: '',
  sessionDuration: [30, 180],
  numAttendees: /^[2-9]|1[0-2]$/,
  tag: /^[^,\s]{3,128}$/,
  // externalUrl: /https:\/\/.*zoom\.us\/.+?\b/,
  EXTERNAL_URL: /(https:\/\/meet\.google\.com\/.+?\b)|(https:\/\/.*zoom\.us\/.+?\b)/,

  //user
  testimonial: /([.]){0,200}$/,
};

export function ValidateBatch(validateArray) {
  let allGood = true;

  //go through array and validate
  for (var i = validateArray.length - 1; i >= 0; i--) {
    //remove error first
    if (validateArray[i].ref) {
      validateArray[i].ref.classList.remove('form-input-error');
      if (validateArray[i].ref.nextSibling.classList.contains('form-error-msg'))
        validateArray[i].ref.nextSibling.innerText = '';
      if (
        validateArray[i].ref.nextSibling.nextSibling &&
        validateArray[i].ref.nextSibling.nextSibling.classList.contains(
          'form-initial-msg'
        )
      )
        validateArray[i].ref.nextSibling.nextSibling.classList.remove(
          'form-initial-msg-hidden'
        );
    }

    if (validateArray[i].isSkip) allGood &= true;
    else if (validateArray[i].inNumberRange)
      allGood &= ValidateNumberRange(validateArray[i]);
    else allGood &= Validate(validateArray[i]);
  }

  return allGood;
}

export function Validate(itemObject) {
  let value = itemObject.value;
  if (value == null) value = '';

  const regex = regexItems[itemObject.type];
  if (value.toString().match(regex) != null) {
    return true;
  }

  handleErrors(itemObject);
  return false;
}

export function ValidateNumberRange(itemObject) {
  let value = parseInt(itemObject.value);
  if (value == null || isNaN(value)) value = 0;

  if (
    regexItems[itemObject.type][0] <= value &&
    value <= regexItems[itemObject.type][1]
  )
    return true;

  handleErrors(itemObject);
  return false;
}

function handleErrors(errorObj) {
  //add error class
  errorObj.ref.classList.add('form-input-error');
  let errorSpan = errorObj.ref.nextSibling;

  //if next sibling is the error place holder add the error
  if (errorSpan && errorSpan.classList.contains('form-error-msg'))
    errorSpan.innerText = errorMsgs[errorObj.type];

  if (
    errorObj.ref.nextSibling.nextSibling &&
    errorObj.ref.nextSibling.nextSibling.classList.contains('form-initial-msg')
  )
    errorObj.ref.nextSibling.nextSibling.classList.add(
      'form-initial-msg-hidden'
    );

  return false;
}

export function ValidateSessionStartTime(startTime, ref, isAdmin) {
  let errorSpan = ref.nextSibling;
  errorSpan.innerText = '';
  if (isAdmin) {
    if (startTime < getServerTime()) {
      ref.classList.add('form-input-error');
      errorSpan.innerText = errorMsgs['sessionStartTime'];
      return false;
    }
  } else if (startTime < getServerTime().add(1, 'd')) {
    ref.classList.add('form-input-error');
    errorSpan.innerText = errorMsgs['sessionStartTime'];
    return false;
  }
  ref.classList.remove('form-input-error');
  return true;
}





export const stringHasCharacters = (str) => {
  return /[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]/.test(str);
}
