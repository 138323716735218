import styles from './UserRight.module.css';
import { BadgeItemHosted, BadgeItemSupported } from '_components';
import { useProfile } from '_hooks';

export const UserRight = () => {
  const { selectedUserData } = useProfile();

  return (
    <div className={styles['container']}>
      <div className={styles['top']}>
        <h4 className={styles['section-title']}>Activity & Achievement</h4>
        <p>Earn badges for being an active peer or host!</p>
      </div>

      <div className={styles['divider']}></div>

      <div className={styles['bottom']}>
        <BadgeItemSupported
          curNumber={selectedUserData.num_session_users_met}
        />
        <BadgeItemHosted curNumber={selectedUserData.num_sessions_hosted} />
      </div>
    </div>
  );
};
