import React from 'react';
import styles from './SessionsDates.module.css';
import { SessionItem, SessionItemEmpty } from '_components';
import { useWellSelector } from '_hooks';

export const SessionsDates = ({
  sessionsToShow,
  searchPhrase,
  showDates,
  showEmpty,
  isGroupPage,
}) => {
  const { userData } = useWellSelector((state) => state.userStore);

  let hasSession = false;

  let showDate = false;
  let dayText = '';
  let todayDone = false;
  let tomorrowDone = false;
  let thisWeekDone = false;
  let thisMonthDone = false;
  let hasShownEmpty = false;

  return (
    <div className={styles['session-list-row']}>
      {sessionsToShow.map((session, index) => {
        //if over do not show on session list
        if (!session.start_time || !session.end_time) return <></>;

        hasSession = true;

        if (session.date_filters.today && !todayDone) {
          dayText = 'TODAY';
          showDate = true;
          todayDone = true;
        } else if (session.date_filters.tomorrow && !tomorrowDone) {
          dayText = 'TOMORROW';
          showDate = true;
          tomorrowDone = true;
        } else if (
          session.date_filters.thisWeek &&
          !session.date_filters.today &&
          !session.date_filters.tomorrow &&
          !thisWeekDone
        ) {
          dayText = 'THIS WEEK';
          showDate = true;
          thisWeekDone = true;
        } else if (
          session.date_filters.thisMonth &&
          !session.date_filters.thisWeek &&
          !thisMonthDone
        ) {
          dayText = 'THIS MONTH';
          showDate = true;
          thisMonthDone = true;
        } else {
          showDate = false;
        }

        return (
          <React.Fragment key={session.uuid}>
            {showDates && showDate && (
              <>
                <div className={styles['sessions-date-container']}>
                  <h2 className={styles['sessions-date']}>{dayText}</h2>
                </div>
              </>
            )}

            <SessionItem session={session} searchPhrase={searchPhrase} allowRegister={false}/>

            {userData.host_level > 0 &&
              showEmpty &&
              !hasShownEmpty &&
              (!sessionsToShow[index + 1] ||
                (session.date_filters.today &&
                  !sessionsToShow[index + 1].date_filters.today) ||
                (session.date_filters.tomorrow &&
                  !sessionsToShow[index + 1].date_filters.tomorrow) ||
                (session.date_filters.thisWeek &&
                  !sessionsToShow[index + 1].date_filters.thisWeek) ||
                (session.date_filters.thisMonth &&
                  !sessionsToShow[index + 1].date_filters.thisMonth)) && (
                <>
                  {(hasShownEmpty = true)}
                  <SessionItemEmpty
                    isSquare={false}
                    allowCreate={true}
                    selectedGroup={{
                      uuid: isGroupPage ? session.group_uuid : '',
                      name: '',
                    }}
                  />
                </>
              )}
          </React.Fragment>
        );
      })}

      {!hasSession && (
        <div className={styles['no-sessions-notice']}>
          We're sorry. No upcoming sessions match these filters.
        </div>
      )}
    </div>
  );
};
