import { makeStyles, createStyles } from "@material-ui/styles";


export const useStyles = makeStyles(() =>
  createStyles({
    blurred: {
      filter: 'blur(4px)',
      pointerEvents: 'none',
    },
  })
);


export const useModalStyles = makeStyles(() =>
  createStyles({
    content: {
      position: 'relative',
    },
    mainP: {
      position: 'relative',
      color: 'black',
    },
    imageContainer: {
      textAlign: 'center',
      marginBottom: '16px',
    },
    image: {
      maxWidth: '400px',
      margin: '0 auto',
    },
    cta: {
      position: 'relative',
    },
    bold: {
      fontWeight: 500,
    }
  })
);