// flags for if the modal changes something

export enum EModalFlags {
  HAS_UPDATED_GROUP = 1,
  HAS_UPDATED_SESSION = 2,
  // 8
  // 16
  // 32
}

export let MODAL_FLAGS = {
  HAS_UPDATED_GROUP: EModalFlags.HAS_UPDATED_GROUP,
  HAS_UPDATED_SESSION: EModalFlags.HAS_UPDATED_SESSION,
};
