import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './ReplyItem.module.css';
import {
  Avatar,
  DynamicFeedTime,
  LikeItemListCombo,
  WellMeatBalls,
  WellButton,
  RenderForumPost,
  WellLoader,
  ActivityFeedInput,
} from '_components';
import {
  useForum,
  useGetImgixVersion,
  useWellSelector,
  useWellDispatch
} from '_hooks';
import { userListToUuidUsernameMapping } from '_helpers';
import { IActivity, TUserMapping } from '_types';
import { groupForumActions } from '_actions'


interface IReplyItem {
  reply: IActivity;
  deleteReply: (uuid: string) => void;
  reloadReply: (uuid: string) => void;
}

export const ReplyItem = ({ reply, deleteReply, reloadReply }: IReplyItem) => {
  const { reportActivity } = useForum();
  const { userStore } = useWellSelector((state) => state);

  const dispatch = useWellDispatch();

  const { tagUsers } = useForum();
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(reply.content);
  const [isLoading, setIsLoading] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState<TUserMapping>(userListToUuidUsernameMapping(reply.tagged_users));

  // edit the reply, tag users, then reload
  const submitEdit = async () => {
    setIsLoading(true);
    await dispatch(groupForumActions.postEdit(reply.uuid, editContent));
    await tagUsers(reply as any, editContent, taggedUsers);
    await handleReload();
    setIsLoading(false);
    setIsEditing(false);
  };

  // reload needs an id, 
  const handleReload = () => {
    reloadReply(reply.uuid);
  }

  const replyImage = useGetImgixVersion(reply.pic, {
    h: 500,
    w: 500,
  });

  return (
    <div className={styles['reply-item'] + ' reply-item'}>
      <Avatar pic={reply.user.pic} isSmall={true} />
      <div className={styles['reply-item-content']}>
        <div className={styles['options-container']}>
          <WellMeatBalls
            dropDownKey={'reply-meatballs-' + reply.uuid}
            style={{
              color: '#000000',
              fontSize: '14px',
              top: '-2px',
              left: '5px',
            }}
            dropDownItems={[
              userStore.userData.uuid === reply.user.uuid
                ? {
                  text: 'Edit Reply',
                  onClick: () => {
                    setIsEditing(true);
                  },
                }
                : {},
              userStore.userData.uuid === reply.user.uuid || userStore.isAdmin
                ? {
                  text: 'Delete Reply',
                  onClick: () => {
                    deleteReply(reply.uuid);
                  },
                }
                : {},
              {
                text: 'Report Post',
                onClick: () => {
                  reportActivity(reply.uuid);
                },
              },
            ]}
          />
        </div>

        <Link
          to={'/profile/' + reply.user.username}
          className={styles['reply-item-user']}
        >
          {reply.user.username}
        </Link>
        {isLoading ?
          <div className={styles['loader-container']}>
            <WellLoader heightWidth={40} />
          </div>
          : !isEditing ? (
            <div className={styles['reply-item-text']}>
              <>
                <RenderForumPost
                  text={reply.content}
                  taggedUsers={reply.tagged_users}
                  isReply={true}
                />

                {reply && reply.pic && replyImage && (
                  <div className={styles['image-container']}>
                    <img
                      src={replyImage}
                      className={styles['activity-image']}
                      alt={'Reply'}
                    />
                  </div>
                )}
              </>
            </div>
          ) : (
            <div className={styles['reply-item-input']}>
              <ActivityFeedInput
                value={editContent}
                setValue={setEditContent}
                taggedUsers={taggedUsers}
                setTaggedUsers={setTaggedUsers}
                hasInlineOptions={''}
                groupToSearch={reply.group_uuid}
              />
              <WellButton
                className={styles['cancel-btn']}
                btnOnClick={() => {
                  setIsEditing(false);
                  setEditContent(reply.content);
                }}
                btnText={'CANCEL'}
                isLoading={isLoading}
                type={'well-btn-new-secondary'}
              />
              <WellButton
                className={
                  styles['save-btn'] +
                  ' ' +
                  (editContent.replace(/\s/g, '').length < 1 &&
                    styles['disabled'])
                }
                btnOnClick={submitEdit}
                btnText={'SAVE'}
                isLoading={isLoading}
                type={'well-btn-orange'}
              />
            </div>
          )}

        <div className={styles['time-container']}>
          <DynamicFeedTime time={reply.time_created} />
        </div>
        <LikeItemListCombo
          key={'reply-like-' + reply.uuid}
          activity={reply}
          handleReload={handleReload}
        />
      </div>
    </div>
  );
};
