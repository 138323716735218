import useStyles from './MastheadStyles';
import { useTheme } from '@material-ui/core/styles';
import { WellButton } from '_components';
import { SectionWrapper } from '../Wrappers/SectionWrapper';
import { useWellDispatch, useWellSelector } from '_hooks';
import { useCallback } from 'react';
import { history } from '_helpers';
import { componentsConstants } from '_constants';
import { useCookies } from 'react-cookie';

export const Masthead = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <div
        className={classes.background}
        style={{
          backgroundImage:
            'url(https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/swoop.svg)',
        }}
      >
        <SectionWrapper
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div className={classes.flexContainer}>
            <div className={classes.titleContainer}>
              <h1 className={classes.title}>
                ShareWell<br className={classes.specialBreak}></br> Host
                Training
              </h1>
              <h2 className={classes.subtitle}>
                Learn how to become an effective Peer Support Host and
                facilitator.
              </h2>
              <div className={classes.buttonContainer}>
                <TrainingBtn text={'JOIN NOW!'} className={classes.btn} />
              </div>
            </div>

            <div className={classes.imageContainer}>
              <img
                src={
                  'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/people-and-computer.svg'
                }
                alt={'Happy people with computer'}
                className={classes.mastheadImage}
              />
            </div>
          </div>
        </SectionWrapper>
      </div>
    </div>
  );
};

export const TrainingBtn = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => {
  const dispatch = useWellDispatch();

  const [cookie, setCookie] = useCookies();

  const { loggedIn } = useWellSelector((state) => state.userStore);

  const handleClick = useCallback(() => {
    setCookie('WELL_INTERESTED_IN_TRAINING', 1, {
      path: '/',
      domain: '.sharewellnow.com',
      expires: new Date(Date.now() + 1296000),
    });

    if (loggedIn) {
      history.push('/thinkific');
      return;
    }

    // user is not logged in
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isRegisterModalOpen: {
        isOpen: true,
        skipOnboarding: true,
        redirect: '/thinkific',
      },
    });
  }, [dispatch, loggedIn]);

  return (
    <WellButton
      btnText={text}
      btnOnClick={handleClick}
      type={'well-btn-orange'}
      className={className}
    />
  );
};
