export const componentsConstants = {
  //MODAL
  OPEN_MODAL: 'COMPONENTS_OPEN_MODAL',
  CLOSE_MODAL: 'COMPONENTS_CLOSE_MODAL',
  TOGGLE_NEW_MODAL: 'COMPONENTS_TOGGLE_NEW_MODAL',
  SET_UNSET_FLAG_NEW_MODAL: 'COMPONENTS_SET_UNSET_FLAG_NEW_MODAL',

  // MENU
  SET_MENU_IS_TRANSPARENT: 'COMPONENTS_SET_MENU_IS_TRANSPARENT',
  SET_MENU_ONLY_SHOW_ON_HOVER: 'COMPONENTS_SET_MENU_ONLY_SHOW_ON_HOVER',

  // OTHER
  SET_HOMEPAGE_QUIZ_SELECTION: 'COMPONENTS_SET_HOMEPAGE_QUIZ_SELECTION',
};
