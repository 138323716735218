import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { groupActions, groupUserActions } from '_actions';
import styles from './GroupPreferences.module.css';
import { useParams } from 'react-router-dom';
import { WellPanel, WellLoader, WellButton, WellCheckbox } from '_components';
import { history } from '_helpers';

export const GroupPreferences = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [group, setGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [btnIsEnd, setBtnIsEnd] = useState(false);
  const [preferences, setPreferences] = useState({
    sessions: null,
    forums: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getGroupData = async () => {
      const groupResponse = await dispatch(groupActions.getGroupInfo(uuid));

      if (!groupResponse || !groupResponse.am_i_joined) {
        history.push(
          '/well/' +
            group.title.toLowerCase().replaceAll(' ', '-') +
            '/' +
            uuid +
            '/sessions'
        );
        return false;
      }

      const preferencesResponse = await dispatch(
        groupUserActions.getGroupNotificationPreferences(uuid)
      );

      setGroup(groupResponse); //set the group data
      setPreferences({
        sessions: preferencesResponse.sessions,
        forums: preferencesResponse.forums,
      });
      setIsLoading(false);
    };
    getGroupData();
  }, [uuid, dispatch, group]);

  const handleSave = async () => {
    await dispatch(
      groupUserActions.getGroupNotificationPreferences(
        uuid,
        preferences.sessions,
        preferences.forums
      )
    );
    setBtnIsEnd(true);
  };

  return (
    <WellPanel>
      {isLoading ? (
        <WellLoader heightWidth={200} />
      ) : (
        <div className={styles['preferences-container']}>
          <h1 className={styles['title']}>{group.title}</h1>

          <div className={styles['input-container']}>
            <WellCheckbox
              checkItem={preferences.sessions}
              handleChange={() => {
                setBtnIsEnd(false);
                setPreferences((prevState) => {
                  return {
                    ...prevState,
                    sessions: !prevState.sessions,
                  };
                });
              }}
              label={'I want to be notified about new sessions'}
            />
            <div></div>
            <WellCheckbox
              checkItem={preferences.forums}
              handleChange={() => {
                setBtnIsEnd(false);
                setPreferences((prevState) => {
                  return {
                    ...prevState,
                    forums: !prevState.forums,
                  };
                });
              }}
              label={'I want to be notified about new forum posts'}
            />
          </div>

          <WellButton
            className={styles['submit-btn']}
            btnOnClick={handleSave}
            type={'well-btn-orange'}
            btnText={'SAVE'}
            isLoading={false}
            endingText={'SAVED!'}
            isEnd={btnIsEnd}
          />
        </div>
      )}
    </WellPanel>
  );
};
