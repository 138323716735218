import { useState, Dispatch, SetStateAction } from 'react';
import styles from './SessionInfoRight.module.css';
import { getServerTime, history, getSessionStartTime } from '_helpers';
import { notificationConstants } from '_constants';
import {
  WellButton,
  SessionAttendee,
  SessionAttendeeEmpty,
  WellCountdown,
  ModalWrapper,
  ModalBodyWrapper,
} from '_components';
import { IUser } from '_types';
import { authActions } from '_actions';
import { useSessionInfo, useWellSelector, useWellDispatch } from '_hooks';
import { SessionInfoOptions } from '..';

export const SessionInfoRight = () => {
  return (
    <>
      <div className={styles['session-info-right']}>
        <SessionTimer />

        <SessionButtons />

        <SessionInfoOptions />

        <Availability />

        <SessionInfoAttendees />
      </div>
    </>
  );
};

export const SessionTimer = () => {
  const { sessionInfo, updateSessionInfo } = useSessionInfo();

  return (
    <>
      {!sessionInfo.in_session &&
      sessionInfo.status !== 'Canceled' &&
      sessionInfo.status !== 'Over' ? (
        <div className={styles['time-row']}>
          <WellCountdown
            startTime={getSessionStartTime(sessionInfo)}
            timeToEnd={getSessionStartTime(sessionInfo).diff(getServerTime())}
            endNotice={notificationConstants.SESSION_LIVE}
            endNoticeClass={'blink'}
            onComplete={updateSessionInfo}
          />
        </div>
      ) : sessionInfo.in_session ? (
        <div className={styles['time-row']}>
          <div className={styles['in-session-notice'] + ' blink'}>
            {notificationConstants.SESSION_LIVE}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const SessionButtons = () => {
  const { sessionInfo } = useSessionInfo();
  const dispatch = useWellDispatch();

  const [timerModalIsOpen, setTimerModalIsOpen] = useState(false);

  const {
    sessionPermissions,
    handleRegisterForSession,
    handleUnregisterForSession,
    enterRoom,
    disallowClick,
    isBlocked,
  } = useSessionInfo();

  const handleLoginClick = () => {
    dispatch(authActions.setOnLoginRedirect(history.location.pathname));
    history.push('/login');
  };

  const showEnterNotice = () => {
    setTimerModalIsOpen(true);
  };

  return (
    <>
      <div className={styles['buttons-row-first']}>
        {(sessionPermissions.canEnter || sessionPermissions.canSeeEnterBtn) && (
          <WellButton
            className={
              styles['register-btn'] +
              ' ' +
              (!sessionPermissions.canEnter ? styles['can-not-enter'] : '') +
              ' ' +
              (sessionPermissions.canSeeRegisterBtn
                ? styles['two-buttons']
                : '')
            }
            btnOnClick={
              sessionPermissions.canEnter ? enterRoom : showEnterNotice
            }
            btnText={sessionInfo.am_i_joined ? 'ENTER' : 'ADMIN ENTER'}
            isLoading={disallowClick}
            type={'well-btn-orange'}
          />
        )}

        {sessionPermissions.canSeeRegisterBtn && !isBlocked ? (
          <WellButton
            className={styles['register-btn']}
            btnOnClick={handleRegisterForSession}
            btnText={'REGISTER'}
            isLoading={disallowClick}
            type={'well-btn-orange'}
          />
        ) : sessionPermissions.canSeeLoginBtn ? (
          <WellButton
            className={styles['register-btn']}
            btnOnClick={handleLoginClick}
            btnText={'LOGIN'}
            isLoading={disallowClick}
            type={'well-btn-orange'}
          />
        ) : null}
      </div>

      {sessionPermissions.canSeeUnregisterBtn && (
        <div
          className={
            styles['buttons-row-second'] + ' ' + styles['no-margin-bottom']
          }
        >
          <WellButton
            className={styles['unregister-btn']}
            btnOnClick={handleUnregisterForSession}
            btnText={'UNREGISTER'}
            isLoading={disallowClick}
            type={'well-btn-new-primary'}
          />
        </div>
      )}

      {timerModalIsOpen && <CountdownModal closeModal={setTimerModalIsOpen} />}
    </>
  );
};

// what is the status of the session
export const Availability = () => {
  const { sessionInfo, isBlocked } = useSessionInfo();

  return (
    <div className={styles['availability-container']}>
      <h3 className={styles['title']}>Session Availability</h3>
      <div className={styles['availability-row']}>
        <div className={styles['availability-item']}>
          <span className={styles['availability-number']}>
            {sessionInfo.status === 'Over' ? (
              <>OVER</>
            ) : sessionInfo.status === 'Going' ? (
              <>GOING</>
            ) : (!sessionInfo.external_url &&
                sessionInfo.num_spots_left === 0) ||
              isBlocked ? (
              <>FULL</>
            ) : sessionInfo.in_session && !sessionInfo.am_i_joined ? (
              <>CLOSED</>
            ) : (
              <>OPEN</>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

// who is going to the session
export const SessionInfoAttendees = () => {
  const { loggedIn } = useWellSelector((state) => state.userStore);

  const {
    sessionInfo,
    handleRegisterForSession,
    disallowClick,
    sessionPermissions,
    isBlocked,
  } = useSessionInfo();

  if (
    (!loggedIn && sessionInfo.num_users_joined === 1) ||
    sessionInfo.status === 'Over' ||
    isBlocked
  )
    return <></>;

  return (
    <div className={styles['participants-container']}>
      <h3 className={styles['title']}>
        Attendees&nbsp;
        {sessionInfo.type === 'INTERNAL' &&
          sessionInfo.status !== 'Over' &&
          !sessionInfo.in_session && (
            <span className={styles['joined-status']}>
              (<b>{sessionInfo.num_spots_left}</b>&nbsp;
              {sessionInfo.num_spots_left === 1 ? 'spot' : 'spots'} left)
            </span>
          )}
      </h3>

      <div className={styles['participants-row']}>
        {sessionPermissions.canSeeRegisterBtn && (
          <SessionAttendeeEmpty
            onClick={handleRegisterForSession}
            isLoading={disallowClick}
          />
        )}

        {sessionInfo.joined_users &&
          sessionInfo.joined_users.map((user: IUser) => {
            return (
              <SessionAttendee user={user} key={user.uuid + '-attendee'} />
            );
          })}
      </div>
    </div>
  );
};

// only needed here
const CountdownModal = ({
  closeModal,
}: {
  closeModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { sessionInfo } = useSessionInfo();

  return (
    <ModalWrapper closeModal={() => closeModal(false)} isLoading={false}>
      <>
        <ModalBodyWrapper>
          <>
            <WellCountdown
              startTime={getSessionStartTime(sessionInfo)}
              timeToEnd={getSessionStartTime(sessionInfo).diff(getServerTime())}
              endNotice={'Session is Open'}
              endNoticeClass={''}
              onComplete={() => {}}
            />
            <p className={styles['modal-p']}>
              You will be able to enter the waiting room 5 minutes before the
              session&nbsp;start&nbsp;time.
            </p>
          </>
        </ModalBodyWrapper>
      </>
    </ModalWrapper>
  );
};
