import { adminConstants } from '_constants';
import { AnyAction } from 'redux';

const defaultState = {
  currentView: 'home',
  menuIsShowing: true,
  isLoading: false,

  isShowingAllUsers: true,

  isAllSessionsShowing: 1,
  sessionData: {
    data: [],
    title: '',
  },
};

export interface initStateTypes {}

export function adminStore(state: any = defaultState, action: AnyAction) {
  switch (action.type) {
    case adminConstants.UPDATE_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.newView,
      };

    case adminConstants.UPDATE_USERS_VIEW:
      return {
        ...state,
        isShowingAllUsers: action.isShowing,
      };

    case adminConstants.GET_DASHBOARD_DATA:
      return {
        ...state,
        timeRangeData: {
          headers: action.headers,
          data: action.data,
          period: action.period,
          isOver: action.isOver,
          offset: action.offset,
        },
      };

    case adminConstants.ADD_TO_DASHBOARD_DATA:
      let newData = state.timeRangeData.data.concat(action.data); //merge two arrays together
      let newOffset = state.timeRangeData.offset + action.offset;
      return {
        ...state,
        timeRangeData: {
          ...state.timeRangeData,
          data: newData,
          offset: newOffset,
          isOver: action.isOver,
        },
      };

    case adminConstants.UPDATE_SESSIONS_VIEW:

      return {
        ...state,
        isAllSessionsShowing: action.isShowing,
      };

    case adminConstants.GET_SINGLE_SESSION_DATA:
      return {
        ...state,
        isAllSessionsShowing: 0,
        sessionData: {
          ...state.sessionData,
          data: action.data,
          title: action.title,
        },
      };

    default:
      return state;
  }
}
