import styles from './SessionInfo.module.css';
import { WellLoader, SessionItemList } from '_components';
import {
  SessionInfoHeader,
  SessionInfoLeft,
  SessionInfoRight,
  AboutSession,
  SessionInfoHost,
  SessionButtons,
  SessionInfoOptions,
  Availability,
  SessionInfoAttendees,
} from '.';

import { Custom404 } from 'StaticPages';
import { Helmet } from 'react-helmet';
import { useSessionInfo, useGetImgixVersion } from '_hooks';
import { ISession } from '_types';

export const SessionInfo = () => {
  const { sessionInfo, sessionsInSeries, show404, isLoading, showMetrics } =
    useSessionInfo();

  // always same so loads quickly from cache
  const blurryImage = useGetImgixVersion(sessionInfo.pic, {
    w: 1000,
    h: 640,
    fit: 'crop',
    crop: 'entropy',
  });

  if (show404 === 1) return <Custom404 image={'session-deleted.svg'} />;
  else if (show404 === 2) return <Custom404 />;
  else if (isLoading) return <WellLoader heightWidth={150} />;

  return (
    <>
      <div className={styles['session-info']}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{sessionInfo.title}</title>
          <meta name="description" content={sessionInfo.description} />
        </Helmet>

        <div
          className={styles['session-info-parallax']}
          style={{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(' +
              blurryImage +
              ')',
          }}
        ></div>

        <div className={'container'}>
          <SessionInfoHeader />

          <div className={styles['options-row']}></div>

          <div className={styles['session-info-body']}>
            <div className={styles['desktop']}>
              <div className={showMetrics ? styles['left-container'] : ''}>
                <SessionInfoLeft />
              </div>

              {showMetrics && (
                <div className={styles['right-container']}>
                  <SessionInfoRight />
                </div>
              )}
            </div>

            <div className={styles['mobile']}>
              <AboutSession />
              <SessionButtons />
              <SessionInfoOptions />
              <SessionInfoHost />
              <Availability />
              <SessionInfoAttendees />
            </div>
          </div>

          {sessionsInSeries && sessionsInSeries.length > 0 ? (
            <div className={styles['related-sessions']}>
              <h4 className={styles['related-sessions-title']}>
                {sessionInfo.series ? (
                  <>Other Sessions in This Series</>
                ) : (
                  <>Related Sessions</>
                )}
              </h4>
              <div className={styles['related-sessions-row']}>
                {sessionsInSeries.slice(0, 3).map((session: ISession) => {
                  return (
                    <SessionItemList
                      session={session}
                      key={'related-session-' + session.uuid}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
