import { useContext } from 'react';
import { SessionInfoContext } from 'Sessions/SessionInfo';

export const useSessionInfo = () => {
  const context = useContext(SessionInfoContext);
  if (context === undefined) {
    throw new Error('useSessionInfo must be used within a SessionInfoContext');
  }
  return context;
};
