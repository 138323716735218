import { useCallback } from 'react';
import styles from './SessionItem.module.css';
import { history } from '_helpers';

interface ISessionItemEmpty {
  isSquare: boolean;
  selectedGroup: {
    uuid: string;
    name: string;
  }
}

export const SessionItemEmpty = ({ isSquare, selectedGroup }: ISessionItemEmpty) => {

  const handleOnClick = useCallback(() => {
    if (selectedGroup.uuid !== '')
      history.push('/session-create/' + selectedGroup.uuid)
    else
      history.push('/session-create')
  }, [selectedGroup.uuid]);


  return (
    <div
      className={
        styles['session-item'] +
        ' ' +
        styles['session-item-empty'] +
        ' ' +
        (isSquare
          ? styles['square']
          : '')
      }
      onClick={handleOnClick}
    >
      <div>
        <div className={styles['plus-sign']}>+</div>
        <div className={styles['empty-cta']}>CREATE SESSION</div>
      </div>

    </div>
  );
};
