import { useContext} from 'react';
import { ForumContext } from '_components';

//CONTEXT
export const useForum = () => {
  const context = useContext(ForumContext);
  if (context === undefined) {
    throw new Error('useForum must be used within a forumContext');
  }
  return context;
};


