import { roomConstants } from '_constants';
import { AnyAction } from 'redux';
import {
  ESessionRoomSafety,
  ESessionRoomStatus,
  IPostSessionChecks,
  ISession,
  ISessionRoom,
} from '_types';

interface IDefaultState {
  // providers
  provider: string;
  providerApiKey: string;
  providerIdentifier: string;
  providerToken: string;
  // data
  userStatus: ESessionRoomStatus;
  hasCompletedVideoCheck: boolean;
  redirectLocation: string;
  hostIsActive: boolean; // needs to be added to new repo

  // breakout rooms
  breakoutRoomTimer: string | boolean;
  breakoutRoomModalIsOpen: boolean;

  correctRoomSafety: ESessionRoomSafety;
  roomData: ISessionRoom[];
  correctRoom: number;
  currentRoom: number;
  sessionInfo: ISession;
  sentHostSignal: {};
  previousHostSignal: {};
  receivedHostSignal: {};
  postSessionChecks: IPostSessionChecks;
}

let defaultState = {
  // providers
  provider: '',
  providerApiKey: '',
  providerIdentifier: '',
  providerToken: '',
  // data
  userStatus: ESessionRoomStatus.NONE,
  hasCompletedVideoCheck: false,
  redirectLocation: '',
  hostIsActive: false,

  // breakout rooms
  breakoutRoomTimer: false,
  breakoutRoomModalIsOpen: false,

  correctRoomSafety: ESessionRoomSafety.NONE, // starts with no stauts
  roomData: [] as ISessionRoom[],
  correctRoom: -1, // -1 means no room is correct
  currentRoom: -1, // -1 means no room is correct
  sessionInfo: {} as ISession,
  sentHostSignal: {},
  previousHostSignal: {},
  receivedHostSignal: {},
  postSessionChecks: {
    // unless otherwise proven, user has completed these items
    completed_mood_tracker: true,
    completed_session_or_host_review: true,
    show_group_hosted_survey: true,
    has_already_checked: false, // we should only check when we enter (any) a room
  },
};

export function roomStore(
  state: IDefaultState = defaultState,
  action: AnyAction
) {
  switch (action.type) {
    // when their is a success to enter the room
    case roomConstants.ENTER_SESSION_ROOM_SUCCESS:
      return {
        ...state,
        currentRoom: action.currentRoom,
        //provider
        provider: action.roomInfo.session_video.provider,
        //tokens
        providerApiKey: action.roomInfo.session_video.provider_api_key,
        providerIdentifier: action.roomInfo.session_video.provider_identifier,
        providerToken: action.roomInfo.session_video.provider_token,
      };

    case roomConstants.SET_SESSION_INFO:
      return {
        ...state,
        sessionInfo: action.session,
      };

    case roomConstants.SET_HAS_COMPLETED_VIDEO_TEST:
      return {
        ...state,
        hasCompletedVideoCheck: action.hasCompletedVideoCheck,
      };

    case roomConstants.SET_USER_STATUS:
      return {
        ...state,
        userStatus: action.userStatus,
      };

    case roomConstants.SET_LEAVE_STATE:
      return {
        ...state,
        userStatus: ESessionRoomStatus.NEEDS_TO_LEAVE,
        redirectLocation: action.redirectLocation,
      };

    // when the status to be able to enter a room is updated
    case roomConstants.UPDATE_ROOM_DATA:
      return {
        ...state,
        roomData: action.roomData,
        correctRoomSafety: action.correctRoomSafety,
        correctRoom: action.correctRoom,
        hostIsActive: action.hostIsActive,
      };

    //////////////////////////////////////////////////

    // messaging
    case roomConstants.SET_SENT_HOST_SIGNAL:
      return {
        ...state,
        sentHostSignal: action.sentHostSignal,
        previousHostSignal: state.sentHostSignal,
      };

    case roomConstants.SET_RECEIVED_HOST_SIGNAL:
      return {
        ...state,
        receivedHostSignal: action.receivedHostSignal,
        previousHostSignal: state.sentHostSignal,
      };

    case roomConstants.SET_BREAKOUT_ROOM_TIMER:
      return {
        ...state,
        breakoutRoomTimer: action.breakoutRoomTimer,
      };

    case roomConstants.SET_BREAKOUT_ROOM_MODAL:
      return {
        ...state,
        breakoutRoomModalIsOpen: action.breakoutRoomModalIsOpen,
      };

    //////////////////////////////////////////////////
    case roomConstants.CLEAR_TOKEN_INFO:
      return {
        ...state,
        providerApiKey: null,
        providerIdentifier: null,
        providerToken: null,
      };
    case roomConstants.CLEAR_SESSION_ROOM_DATA:
      return defaultState;

    //////////////////////////////////////////////////

    case roomConstants.SET_POST_SESSION_CHECKS:
      return {
        ...state,
        postSessionChecks: {
          ...action.postSessionChecks,
          has_already_checked: true,
        },
      };

    default:
      return state;
  }
}
