import { useRef } from 'react';
import {
  useGroupInfo,
  useGetUsersInfo,
  useWellSelector,
  useListAtBottomWithRef,
} from '_hooks';
import styles from './GroupInfoMembers.module.css';
import { Member } from './Member/Member';
import { WellLoader } from '_components';

export const GroupInfoMembers = () => {
  const { userData } = useWellSelector((state) => state.userStore);
  const { group } = useGroupInfo();

  const {
    userList,
    loadMoreUsers,
    userListLoading,
    userListPagination,
    userListLoadingMore,
  } = useGetUsersInfo(group.joined_users, 25);

  const usersRef = useRef(null);

  useListAtBottomWithRef(
    usersRef,
    loadMoreUsers,
    1000,
    userListPagination.isDone
  );

  return (
    <div className={styles['members-container'] + ' container'}>
      {userListLoading ? (
        <div>
          <WellLoader heightWidth={200} />
        </div>
      ) : (
        <>
          <div className={styles['members-row']} ref={usersRef}>
            {userList &&
              userList.map((member) => {
                return (
                  <Member
                    userData={member}
                    key={member.uuid}
                    myUUID={userData.uuid}
                  />
                );
              })}
          </div>
          {userListLoadingMore && (
            <div className={styles['loader']}>
              <WellLoader heightWidth={125} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
