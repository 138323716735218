export const adminConstants = {
  //GENERAL
  PAGINATION_LIMIT: 30,
  PAGINATION_LIMIT_SHORT: 11,

  UPDATE_CURRENT_VIEW: 'ADMIN_UPDATE_CURRENT_VIEW',

  //DASHBOARD
  GET_DASHBOARD_DATA: 'ADMIN_GET_DASHBOARD_DATA',
  ADD_TO_DASHBOARD_DATA: 'ADMIN_ADD_TO_DASHBOARD_DATA',

  //USER
  UPDATE_USERS_VIEW: 'ADMIN_UPDATE_USERS_VIEW',

  //SESSIONS
  UPDATE_SESSIONS_VIEW: 'ADMIN_UPDATE_SESSIONS_VIEW',
  GET_SINGLE_SESSION_DATA: 'ADMIN_GET_SINGLE_SESSION_DATA',
};
