import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { AlternatingColumns } from './AlternatingColumns/AlternatingColumns';
import { HostQualifications } from './HostQualifications/HostQualifications';
import { Introduction } from './Introduction/Introduction';
import { Masthead } from './Masthead/Masthead';
import { MeetOtherHosts } from './MeetOtherHosts/MeetOtherHosts';
import { WhatPeersAreSaying } from './WhatPeersAreSaying/WhatPeersAreSaying';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: 'linear-gradient(to bottom, #0998c4, #0a7fdc)',
      // background: 'linear-gradient(#0998c4, #0a7fdc)',
      color: 'white',
    },

    hasLargeSwipe: {
      position: 'relative',
      backgroundSize: 'cover',
      // backgroundPosition: '0 -1240px',
    },
    largeSwipe: {
      width: '100vw',
      height: '100%',
    },
  })
);

export const Training = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <Masthead />

      <Introduction />

      <div
        className={classes.hasLargeSwipe}
        style={{
          backgroundImage:
            'url(https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/swipe-large.svg)',
        }}
      >
        <AlternatingColumns />

        <HostQualifications />

        <MeetOtherHosts />

        <WhatPeersAreSaying />
      </div>
    </div>
  );
};
