import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'; //load bootstrap first

import { Provider } from 'react-redux';
import store from '_reducers';
import { WellAppProvider } from 'WellAppProvider';
import { WellChatProvider } from 'WellChatProvider';
import { WellRoutesContainer } from '_routing';
import { ErrorBoundary } from '_utils';
import { CookiesProvider } from 'react-cookie';

// css
import './common.module.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-file-utils/dist/styles/ImageUploadButton.css';
import 'react-file-utils/dist/styles/ImagePreviewer.css';

ReactDOM.hydrate(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <ErrorBoundary>
          <WellAppProvider>
            <WellRoutesContainer />
          </WellAppProvider>
        </ErrorBoundary>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
