import { useActivityItem, useWellSelector, useForum } from '_hooks';
import { Link } from 'react-router-dom';
import { IActivity } from '_types';
import {
  Panel,
  PanelHeading,
  PanelContent,
  Avatar,
  AvatarPlaceholder,
  WellMeatBalls,
  CommentIcon,
  ActivityItemContent,
  ActivityItemReplies,
  LikeItemButton,
  PinnedNotice,
} from '_components';
import { generalConstants } from '_constants';
import styles from './ActivityItem.module.css';
import moment from 'moment-timezone';

interface IActivityItem {
  activity: IActivity;
  isSinglePost: boolean;
}

export const ActivityItem = ({ activity, isSinglePost }: IActivityItem) => {
  const { userStore } = useWellSelector((state) => state);
  const { userData, isAdmin } = userStore;

  // group can be the special uuid
  const { group, reloadActivity, reportActivity, pinPost, resetPinPosts } =
    useForum();

  const { isEditing, setIsEditing, deleteActivity } = useActivityItem(activity);

  const { user } = activity;

  const handleReload = () => {
    reloadActivity(activity.uuid, activity.is_pinned);
  };

  // copy the url of the post to view as a single
  const copyUrl = () => {
    var copyText: any = document.getElementById('copy-text-' + activity.uuid);

    try {
      copyText.select();
      copyText.setSelectionRange(0, 99999);

      document.execCommand('copy');
    } catch (error) {}
  };

  // passing the ref all the way seems like too much
  const focusOnReplyTextarea = () => {
    document.getElementById('reply-input-' + activity.uuid)?.focus();
  };

  return (
    <Panel>
      <div className={styles['form-container']}>
        <PanelHeading>
          <div className={styles['header-container']}>
            <Link
              to={'/profile/' + user.username}
              className={styles['user-link']}
            >
              {user && user.pic ? (
                <Avatar pic={user.pic} isSmall={false} />
              ) : (
                <AvatarPlaceholder />
              )}
            </Link>
            <div className={styles['header-text']}>
              <Link
                to={'/profile/' + user.username}
                className={styles['user-link']}
              >
                <h4 className={styles['post-username']}>{user.username}</h4>
              </Link>

              {!activity.is_pinned && (
                <>
                  <div className={styles['circle-divider']}>&bull;</div>
                  <DynamicFeedTime time={activity.time_created} />
                </>
              )}

              {group === generalConstants.SPECIAL_UUID && (
                <Link
                  to={
                    '/well/' +
                    activity.group_title.toLowerCase().replaceAll(' ', '-') +
                    '/' +
                    activity.group_uuid
                  }
                  className={styles['group-title']}
                >
                  {activity.group_title}
                </Link>
              )}
            </div>
          </div>

          {activity.is_pinned && <PinnedNotice />}
          {!isSinglePost && (
            <div className={styles['options-container']}>
              <WellMeatBalls
                dropDownKey={'activity-meatballs-' + activity.uuid}
                style={{ color: '#000000' }}
                dropDownItems={[
                  !isEditing &&
                  (userStore.userData.uuid === activity.user.uuid ||
                    userStore.isAdmin)
                    ? {
                        text: 'Edit Post',
                        onClick: () => {
                          setIsEditing(true);
                        },
                        disable: false,
                      }
                    : {},
                  (userData.uuid === activity.user.uuid || isAdmin) &&
                  !activity.is_pinned //&&
                    ? // !outsideSetActivities
                      {
                        text: 'Delete Post',
                        onClick: deleteActivity,
                        disable: false,
                      }
                    : {},
                  userStore.isAdmin &&
                  !activity.is_pinned &&
                  activity.group_uuid ===
                    process.env.REACT_APP_WELCOME_GROUP_UUID
                    ? {
                        text: 'Pin Post',
                        onClick: () => {
                          pinPost(activity.uuid);
                        },
                        disable: false,
                      }
                    : {},
                  userStore.isAdmin &&
                  activity.group_uuid ===
                    process.env.REACT_APP_WELCOME_GROUP_UUID
                    ? {
                        text: 'Reset Pin With',
                        onClick: () => {
                          resetPinPosts(activity.uuid);
                        },
                        disable: false,
                      }
                    : {},
                  {
                    text: 'Report Post',
                    onClick: () => {
                      reportActivity(activity.uuid);
                    },
                    disable: false,
                  },
                  {
                    text: 'Copy Post URL',
                    onClick: copyUrl,
                    disable: false,
                  },
                ]}
              />
            </div>
          )}
        </PanelHeading>

        <PanelContent>
          <div className={styles['top-content']}>
            <ActivityItemContent
              activity={activity}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />

            <div className={styles['top-content-second-divider']}>
              {!isSinglePost ? (
                <LikeItemButton
                  activity={activity}
                  handleReload={handleReload}
                />
              ) : (
                <div></div>
              )}

              <div
                className={styles['comment-button']}
                onClick={focusOnReplyTextarea}
              >
                <div className={styles['like-button']}>
                  <CommentIcon style={{ color: '#7a8287' }} />
                  <span className={styles['comment-text']}>Comment</span>
                </div>
              </div>
            </div>
          </div>

          <ActivityItemReplies activity={activity} />
        </PanelContent>
      </div>

      <input
        type={'text'}
        className={styles['copy-text']}
        id={'copy-text-' + activity.uuid}
        value={
          process.env.REACT_APP_WELL_URL +
          '/well/' +
          activity.group_title.toLowerCase().replaceAll(' ', '-') +
          '/' +
          activity.group_uuid +
          '/discussion/' +
          activity.uuid
        }
        onChange={() => {}}
      />
    </Panel>
  );
};

export const DynamicFeedTime = ({ time }: { time: string }) => {
  return (
    <div className={styles['from-now']}>
      {moment
        .tz(time, 'UTC')
        .fromNow()
        .replace(' minutes', 'm')
        .replace(' hours', 'h')
        .replace(' days', 'd')}
    </div>
  );
};
