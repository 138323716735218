import { handleFetchReturn, failedToFetch } from '..';
import { IAccountUpdate } from '_types';

export const userService = {
  getProfileInfo,
  updateProfile,
  uploadProfilePic,
  getSubscription,
  getUserInfo,
  searchForUser,
};

// sends request to server to get the information of the currently logged in person
export function getProfileInfo(isLoginTest = false) {
  return fetch(process.env.REACT_APP_API_URL + '/account/profile', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response, isLoginTest))
    .catch((error) => failedToFetch(error, isLoginTest));
}

// sends a POST request to server to update the user's profile
export function updateProfile(formData: IAccountUpdate) {
  return fetch(process.env.REACT_APP_API_URL + '/account/profile/update', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// get information about the user's subscription
export function getSubscription() {
  return fetch(
    process.env.REACT_APP_API_URL + '/account/subscription/current',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// sends a POST request to server to update the user's profile Picture
export function uploadProfilePic(picData: any) {
  let formData = new FormData();

  formData.append('filename', picData);

  return fetch(process.env.REACT_APP_API_URL + '/account/profile/update/pic', {
    method: 'POST',
    headers: {},
    credentials: 'include',
    body: formData,
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getUserInfo(uuid: string) {
  return fetch(process.env.REACT_APP_API_URL + '/user/' + uuid + '/profile', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function searchForUser(group: string, username: string) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/user/search/group/' +
      group +
      '/prefix?q=' +
      username,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
