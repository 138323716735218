import React, { useCallback, useRef, Dispatch, SetStateAction } from 'react';
import styles from './ActivityFeedInput.module.css';
import { useClickOutside, useWellSelector, useUserSearch, useForum } from '_hooks';
import { Picker } from 'emoji-mart';
import { KeyboardEvent, TUserMapping } from '_types';


interface IActivityFeedInput {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  taggedUsers: TUserMapping;
  setTaggedUsers: Dispatch<SetStateAction<TUserMapping>>;
  hasInlineOptions: string; // now used as unique id for input
  groupToSearch: string;
  emojiSelectorOpen?: boolean;
  setEmojiSelectorOpen?: Dispatch<SetStateAction<boolean>>;
}

export const ActivityFeedInput = ({
  value,
  setValue,
  taggedUsers,
  setTaggedUsers,
  hasInlineOptions,
  groupToSearch,
  emojiSelectorOpen,
  setEmojiSelectorOpen,
}: IActivityFeedInput) => {

  const { userData } = useWellSelector(state => state.userStore);

  const inputRef = useRef<any>();

  const {
    results,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    addUserToTaggedMapping,
  } = useUserSearch(value, setValue, setTaggedUsers, groupToSearch, inputRef);

  // handle the change of the value
  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    let { value } = e.currentTarget;
    setValue(value);
  }



  // render the text, 
  // this searches for people tagged & makes them blue
  const renderText = useCallback(() => {
    return value
      .replace(/[\n\r]/, ' \n') // replace \r with \n for ease
      .split(' ') // split on space
      .map((part: string, textKey: number) => {
        let found = false;
        Object.keys(taggedUsers).map((key) => {
          if (part.includes('@' + taggedUsers[key])) {
            found = true;
            return null;
          }
        });

        if (found) {
          return (
            <span key={part + '-' + textKey} className={styles['user-link']}>
              {part}{' '}
            </span>
          );
        }

        return part + ' ';
      });
  }, [taggedUsers, value]);

  // handle key down events
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowUp' && isOpen && selected > 0) {
      e.preventDefault();
      setSelected((prev) => prev - 1);
    } else if (e.key === 'ArrowUp' && isOpen)
      e.preventDefault(); // don't allow reshuffling of the input cursor
    else if (e.key === 'ArrowDown' && isOpen && Object.keys(results).length > selected + 1) {
      e.preventDefault();
      setSelected((prev) => prev + 1);
    } else if (e.key === 'ArrowDown' && isOpen) // don't allow reshuffling of the input cursor
      e.preventDefault();
    else if (e.key === 'Enter' && isOpen) {
      e.preventDefault();
      if (Object.keys(results).length === 0) return;
      // messy way to extract the pair I need
      if (!results[Object.keys(results)[selected]]) return;
      const item = {
        [Object.keys(results)[selected]]: results[Object.keys(results)[selected]],
      }
      addUserToTaggedMapping(item);
    } else if (e.key === 'ArrowRight' && isOpen) { // if right arrow do nothing
    } else if ((e.key === 'ArrowLeft' || e.key === 'Space') && isOpen) { // if left arrow need to close
      setIsOpen(false);
    }
  }

  const emojiPicker = useRef<any>();
  useClickOutside(emojiPicker, () => setEmojiSelectorOpen && setEmojiSelectorOpen(false)); // when click outside close modal
  // https://stackoverflow.com/questions/51818776/how-to-make-input-field-or-textarea-with-emoji-picker-in-angular/65404150#65404150
  const addEmoji = (emojiObject: any) => {

    inputRef.current.focus(); //focus on the input
    const [start, end] = [
      inputRef.current.selectionStart,
      inputRef.current.selectionEnd,
    ]; //get position to add

    inputRef.current.setRangeText(emojiObject.native, start, end, 'end'); //add the emoji
    var event = new Event('input', { bubbles: true }); //create a new event to trigger on change
    inputRef.current.dispatchEvent(event); //trigger event
  };

  const placeHolder = (!hasInlineOptions ? 'What\'s on your mind, ' + userData.username + '?' : 'Reply....')

  return (
    <div className={
      styles['forum-input-container'] +
      ' ' +
      (hasInlineOptions ? styles['has-inline-options'] : '')
    }>
      <>
        <textarea
          className={styles['hidden-input']}
          value={value}
          maxLength={5000}
          autoFocus={false}
          readOnly={false}
          onChange={handleInput}
          placeholder={placeHolder}
          onKeyDown={(e) => { handleKeyDown(e); }}
          ref={inputRef}
          id={(hasInlineOptions ? 'reply-input-' + hasInlineOptions : '')}
        />

        <div
          className={styles['activity-feed-div']}
        >
          {value ? renderText() : <>{placeHolder}</>}
        </div>
      </>

      {isOpen && (
        <UserSearch
          results={results}
          addUserToTaggedMapping={addUserToTaggedMapping}
          selected={selected}
        />
      )}

      {emojiSelectorOpen && (
        <div className={styles['emoji-picker-container']} ref={emojiPicker}>
          <Picker set='apple' onSelect={addEmoji} native={true} />
        </div>
      )}

    </div>
  )

}


interface IUserSearch {
  results: TUserMapping;
  addUserToTaggedMapping: (userMapping: TUserMapping) => void;
  selected: any;
}


const UserSearch = ({ results, addUserToTaggedMapping, selected }: IUserSearch) => {
  if (Object.keys(results).length < 1) return <></>;

  return (
    <div className={styles['user-search-container']}>
      {Object.keys(results).map((key, count) => {
        return (
          <div
            key={'user-search-' + key}
            className={
              styles['user-item'] +
              ' ' +
              (count === selected && styles['active'])
            }
            onClick={() => {
              addUserToTaggedMapping({ [key]: results[key] })
            }}
          >
            {results[key]}
          </div>
        );
      })}
    </div>
  );
};
