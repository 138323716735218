import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import styles from './StripeCardsWithPrevious.module.css';
import {
  iframeStylesStripeCards,
  cardElementOpts,
  WellButton,
} from '_components';
import { useStripeCards } from '_hooks';
import { KeyboardEvent } from '_types';

export const StripeNewCard = ({
  callback,
  buttonText,
}: {
  callback: () => void;
  buttonText: string;
}) => {
  const {
    isLoadingStripeCards,
    errors,
    handleAddCard,
    handleCardNumberChange,
  } = useStripeCards(false);

  const handleSubmit = async () => {
    handleAddCard().then(() => callback());
  };

  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !isLoadingStripeCards) {
      handleSubmit();
    }
  };

  return (
    <div onKeyDown={onKeyDownHandler}>
      <div className={styles['card-input-container']}>
        <div className={styles['container']}>
          {errors.general && (
            <div className={styles['error-container']}>{errors.general}</div>
          )}
          <div className={styles['input-item']}>
            <CardNumberElement
              options={{ style: iframeStylesStripeCards }}
              onChange={handleCardNumberChange}
            />
          </div>
          <div className={styles['input-item']}>
            <CardExpiryElement options={cardElementOpts} />
            <CardCvcElement options={cardElementOpts} />
          </div>
        </div>
      </div>

      <div className={styles['buttons-container']}>
        <WellButton
          isLoading={isLoadingStripeCards}
          btnOnClick={handleSubmit}
          className={styles['modal-btn-back']}
          btnText={buttonText}
          type={'well-btn-primary'}
        />
      </div>
    </div>
  );
};
