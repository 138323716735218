import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { userTestimonialActions, componentActions } from '_actions';
import {
  WellButton,
  WellHeader,
  WellInputWrapper,
  SessionItem,
  WellLoader,
  TempPostSessionSurvey,
} from '_components';
import {
  useWellDispatch,
  useWellSelector,
  useSessionsPagination,
} from '_hooks';
import { history, getNowStringFormat, getFutureStringFormat } from '_helpers';
import styles from './SessionSurveyUser.module.css';
import { EHostLevel, ESortType, ISession } from '_types';
import { SessionSurveyGroupHosted } from 'SessionRoom';

enum EScreen {
  STEP_SURVEY = 0,
  STEP_REVIEW = 1,
  STEP_SESSION = 2,
}

export const SessionSurveyUser = () => {
  const { roomStore } = useWellSelector((state) => state);
  const { postSessionChecks } = roomStore;

  // depending on what the user has completed, show the correct screen
  const [screen, setScreen] = useState(
    postSessionChecks.completed_mood_tracker &&
      postSessionChecks.completed_session_or_host_review
      ? EScreen.STEP_SESSION
      : postSessionChecks.completed_mood_tracker &&
        !postSessionChecks.completed_session_or_host_review
      ? EScreen.STEP_REVIEW
      : EScreen.STEP_SURVEY
  );

  if (screen === EScreen.STEP_SURVEY)
    return <ScreenSurvey setScreen={setScreen} />;
  else if (screen === EScreen.STEP_REVIEW)
    return !postSessionChecks.show_group_hosted_survey ? (
      <ScreenReview setScreen={setScreen} />
    ) : (
      <SessionSurveyGroupHosted />
    );
  return <ScreenSessionRecommendations />;
};

interface IScreen {
  setScreen: Dispatch<SetStateAction<EScreen>>;
}

const ScreenSurvey = ({ setScreen }: IScreen) => {
  const { roomStore } = useWellSelector((state) => state);
  const { postSessionChecks } = roomStore;

  const handleNextScreen = useCallback(() => {
    if (postSessionChecks.completed_session_or_host_review)
      setScreen(EScreen.STEP_SESSION);
    else setScreen(EScreen.STEP_REVIEW);
  }, [postSessionChecks]);

  return (
    <div className={styles['outside']}>
      <WellHeader
        title={"Thanks for attending. Let's do a little check-in."}
        subtitle={'Let us know how you are feeling!'}
        headerClass={styles['header-class']}
      />
      <div className={styles['container']}>
        <TempPostSessionSurvey
          session={roomStore.sessionInfo.uuid}
          callback={handleNextScreen}
          btnClass={styles['submit-btn']}
        />
      </div>
    </div>
  );
};

const ScreenReview = ({ setScreen }: IScreen) => {
  const dispatch = useWellDispatch();

  const { userStore, roomStore } = useWellSelector((state) => state);
  const { sessionInfo } = roomStore;
  const { userData } = userStore;

  const [thumbs, setThumbs] = useState<null | boolean>(null);
  const [openQuestion, setOpenQuestion] = useState('');

  // submit the review
  const submitReview = () => {
    if (thumbs === null) return;

    dispatch(
      userTestimonialActions.createTestimonial(
        sessionInfo.uuid,
        thumbs, // regular thumbs
        thumbs, // is private
        {
          review: openQuestion,
          session_title: sessionInfo.title,
          session_start_time: sessionInfo.start_time,
        }
      )
    );

    if (
      userData.host_level === EHostLevel.HOST_LEVEL_0 &&
      userData.num_sessions_attended >= 2
    )
      dispatch(
        componentActions.openModal({
          createSessionCtaModal: true,
        })
      );

    setScreen(EScreen.STEP_SESSION);
  };

  return (
    <div className={styles['outside']}>
      <WellHeader title={'Your feedback is important!'} />
      <div className={styles['main-container'] + ' container'}>
        <h3 className={styles['section-title']}>
          Would you recommend this session?
        </h3>
        <div className={styles['thumbs-container']}>
          <img
            src={
              process.env.REACT_APP_ASSETS_URL + '/site/session/thumbs-up.svg'
            }
            className={
              styles['thumbs-up'] +
              ' ' +
              (thumbs === true ? styles['active'] : '')
            }
            alt={'Thumbs up'}
            onClick={() => {
              setThumbs(true);
            }}
          />
          <img
            src={
              process.env.REACT_APP_ASSETS_URL + '/site/session/thumbs-down.svg'
            }
            className={
              styles['thumbs-down'] +
              ' ' +
              (thumbs === false ? styles['active'] : '')
            }
            alt={'Thumbs down'}
            onClick={() => {
              setThumbs(false);
            }}
          />
        </div>

        <div
          className={
            styles['open-question-container'] +
            ' ' +
            (thumbs === null ? styles['off'] : '')
          }
        >
          <h2 className={styles['textarea-title']}>
            How was your session experience?
          </h2>
          <h4 className={styles['ten-minimum']}>3 Character Minimum</h4>

          <WellInputWrapper>
            <textarea
              className={styles['input-textarea']}
              onChange={(e) => {
                setOpenQuestion(e.target.value);
              }}
              value={openQuestion}
            />
          </WellInputWrapper>
        </div>

        <div
          className={
            styles['submit-buttons-container'] +
            ' ' +
            (thumbs === null ? styles['off'] : '') +
            ' ' +
            (openQuestion.length < 3 ? styles['off'] : '')
          }
        >
          <WellButton
            className={styles['submit-private']}
            btnOnClick={submitReview}
            btnText={'SUBMIT'}
            isLoading={false}
            type={'well-btn-primary'}
          />
        </div>
      </div>
    </div>
  );
};

export const ScreenSessionRecommendations = () => {
  const { roomStore } = useWellSelector((state) => state);
  const { sessionInfo } = roomStore;

  const { allSessions, isLoadingInitial, reloadSingleSession } =
    useSessionsPagination(
      sessionInfo.group_uuid,
      getNowStringFormat(),
      getFutureStringFormat(),
      ESortType.SORT_TYPE_SOON,
      false,
      6, // grab 6 but we only need 3
      false //exlude full sessions
    );

  // if there are no other sessions in this group, skip
  useEffect(() => {
    if (isLoadingInitial) return;
    // if no sessions then just go to home
    if (allSessions.filter((session) => !session.in_session).length === 0)
      history.push('/');
  }, [allSessions, isLoadingInitial]);

  if (isLoadingInitial)
    return (
      <div>
        <WellLoader heightWidth={200} />
      </div>
    );

  return (
    <div className={styles['outside']}>
      <WellHeader
        title={'Save your spot in the next session!'}
        subtitle={
          'Members who attend 1 peer support session per week report the biggest shifts in happiness, outlook, and overall well-being!'
        }
        headerClass={styles['header-class']}
      />

      <div className={styles['container']}>
        <div className={styles['sessions-row']}>
          {isLoadingInitial ? (
            <WellLoader />
          ) : (
            <>
              {allSessions
                .filter((session) => !session.in_session)
                .slice(0, 3)
                .map((session: ISession) => (
                  <SessionItem
                    session={session}
                    searchPhrase={''}
                    allowRegister={true}
                    reloadSession={reloadSingleSession}
                    isSquare={true}
                    key={'next-session' + session.uuid}
                  />
                ))}
            </>
          )}
        </div>
      </div>

      <div className={styles['buttons-row']}>
        <WellButton
          className={styles['submit-private']}
          btnOnClick={() => history.push('/')}
          btnText={'CONTINUE'}
          isLoading={false}
          type={'well-btn-primary'}
        />
      </div>
    </div>
  );
};
