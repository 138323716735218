import styles from './GroupInfoForum.module.css';
import { ForumProvider, Forum, SinglePostForum } from '_components';
import { useGroupInfo } from '_hooks';
import { useSelector } from 'react-redux';
import { RootState } from '_reducers';
import { EHostLevel } from '_types';

export const GroupInfoForum = () => {
  const { group, showingPost, setShowingPost } = useGroupInfo();

  const { userStore } = useSelector((state: RootState) => state);

  return (
    <div className={styles['forum']}>
      <div className={styles['forum-container'] + ' container'}>
        <ForumProvider group={group.uuid}>
          {showingPost ? (
            <SinglePostForum
              singlePost={showingPost}
              setShowingPost={setShowingPost}
            />
          ) : (
            <Forum
              showStatusUpdate={
                group.uuid === process.env.REACT_APP_WELCOME_GROUP_UUID &&
                userStore.userData.host_level !== EHostLevel.HOST_LEVEL_2
                  ? false
                  : true
              }
            />
          )}
        </ForumProvider>
      </div>
    </div>
  );
};
