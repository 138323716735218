import { useRef, useCallback, useEffect } from 'react';

import { createPortal } from 'react-dom';
import { useClickOutside } from '_hooks';
import styles from './ModalWrapper.module.css';
import { XIcon } from '_components';

interface IModalWrapper {
  children: JSX.Element;
  closeModal: () => void;
  isLoading: boolean;
  disallowClose?: boolean;
  disableOverlay?: boolean;
  wrapperClassName?: string;
}

export const ModalWrapper = ({
  children,
  closeModal,
  isLoading,
  disallowClose,
  disableOverlay,
  wrapperClassName,
}: IModalWrapper) => {
  const modalRef = useRef(null); // modal's ref

  // can the modal be closed?
  const checkIfShouldClose = useCallback(() => {
    if (isLoading || disallowClose) return false;

    closeModal();
  }, [disallowClose, isLoading, closeModal]);

  // check for click outside
  useClickOutside(modalRef, checkIfShouldClose);

  // disallow scroll until closed
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return createPortal(
    <>
      {!disableOverlay && <div className={styles['modal-overlay']} />}
      <div
        className={
          styles['modal-container'] +
          ' ' +
          (wrapperClassName ? wrapperClassName : '')
        }
        ref={modalRef}
      >
        {!disallowClose && (
          <div className={styles['close-modal']}>
            <XIcon onClick={checkIfShouldClose} />
          </div>
        )}
        {children}
      </div>
    </>,
    document.getElementById('portal')!
  );
};

export const ModalBodyWrapper = ({ children }: { children: JSX.Element }) => {
  return <div className={styles['body-wrapper']}>{children}</div>;
};

export const ModalButtonWrapper = ({ children }: { children: JSX.Element }) => {
  return <div className={styles['buttons-wrapper']}>{children}</div>;
};
