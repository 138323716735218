export const userConstants = {
    PAST_SESSIONS_PAGINATION_LIMIT: 10,


    SELF_PROFILE_SUCCESS: "USERS_SELF_PROFILE_SUCCESS",
    SELF_PROFILE_FAILURE: "USERS_SELF_PROFILE_FAILURE",

    // SUBSCRIPTION
    GET_USER_SUBSCRIPTION: "USER_GET_USER_SUBSCRIPTION",


    //Logout
    HANDLE_LOGOUT: "USERS_HANDLE_LOGOUT",


};