import { useState, useEffect } from 'react';
import { generalActions, userHomeActions } from '_actions';
import { AnalyticsSendEvent } from '_helpers';
import { useWellDispatch, useWellSelector } from '_hooks';

const POLL_KEY = 'poll_redux_';

export const useForumPoll = () => {
  const dispatch = useWellDispatch();

  const { userStore } = useWellSelector((state) => state);

  const [pollStatus, setPollStatus] = useState({
    isLoading: true,
    hasError: false,
  });

  // has the poll already been answer by the user & if so which one
  const [pollHasBeenAnswered, setPollHasBeenAnswered] = useState<
    boolean | string
  >(false);

  // answers have how many selections?
  const [pollAnswers, setPollAnswers] = useState({
    answerOne: 0,
    answerTwo: 0,
    answerThree: 0,
    answerFour: 0,
    answerFive: 0,
  });

  const [pollText, setPollText] = useState({
    question: '',
    answerOne: '',
    answerTwo: '',
    answerThree: '',
    answerFour: '',
    answerFive: '',
    answerKey: -1,
  });

  useEffect(() => {
    const start = async () => {
      const handleError = () => {
        setPollStatus({
          isLoading: false,
          hasError: true,
        });
      };

      // first try to get the questions & answer
      try {
        const pollTextRes = await dispatch(userHomeActions.getForumPoll(false));

        // now get the answers people have already submitted
        const data = await dispatch(
          generalActions.loadMarketingData(POLL_KEY + pollTextRes.answerKey)
        );

        // make sure that the call did not fail
        if (!data[POLL_KEY + pollTextRes.answerKey]) return handleError();

        // save the question and answers
        setPollText(pollTextRes);

        // check if user has answered
        data[POLL_KEY + pollTextRes.answerKey].map((item: any) => {

          const itemObj = JSON.parse(item);
          const user = Object.keys(itemObj)[0];
          const answerKey: any = itemObj[user];

          if (user === userStore.userData.uuid)
            setPollHasBeenAnswered(answerKey);

          //set the answers
          setPollAnswers((prev: any) => {
            const num = prev[answerKey] + 1;
            return {
              ...prev,
              [answerKey]: num,
            };
          });
        });

        // all good
        setPollStatus({
          isLoading: false,
          hasError: false,
        });
      } catch (error) {
        return handleError();
      }
    };

    start();
  }, []);

  // submit the poll answer to the server
  const submitPollAnswer = async (user: string, answerKey: string) => {

    if (pollText.answerKey === undefined || pollText.answerKey === -1) return;

    await dispatch(
      generalActions.setOrAppendMarketingData(
        'append',
        POLL_KEY + pollText.answerKey,
        JSON.stringify({ [user]: answerKey })
      )
    );

    setPollHasBeenAnswered(answerKey);

    setPollAnswers((prev) => {
      let numAnswers = 0;
      if (answerKey === 'answerOne') numAnswers = pollAnswers['answerOne'];
      if (answerKey === 'answerTwo') numAnswers = pollAnswers['answerTwo'];
      if (answerKey === 'answerThree') numAnswers = pollAnswers['answerThree'];
      if (answerKey === 'answerFour') numAnswers = pollAnswers['answerFour'];
      if (answerKey === 'answerFive') numAnswers = pollAnswers['answerFive'];
      let newNum = numAnswers + 1;
      return {
        ...prev,
        [answerKey]: newNum,
      };
    });

    AnalyticsSendEvent('group_forum_poll_submit', {});
  };

  return {
    //functions
    submitPollAnswer,

    //State
    pollText,
    pollAnswers,
    pollHasBeenAnswered,
    pollStatus,
  };
};
