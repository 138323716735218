import React from 'react';
import useStyles from './MeetOtherHostsStyles';
import { useTheme } from '@material-ui/core/styles';
import { SectionWrapper } from '../Wrappers/SectionWrapper';

export const MeetOtherHosts = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <SectionWrapper>
      <div>
        <h3 className={classes.sectionTitle}>Meet Other Peer Support Hosts</h3>
        <div className={classes.hostsRow}>
          <Host
            image={
              'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/faces/1.jpg'
            }
            name={'Ellen'}
          />
          <Host
            image={
              'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/faces/2.jpg'
            }
            name={'Lara'}
          />
          <Host
            image={
              'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/faces/3.jpg'
            }
            name={'Kim'}
          />
          <Host
            image={
              'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/faces/4.jpg'
            }
            name={'Jack'}
          />
          <Host
            image={
              'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/faces/5.jpg'
            }
            name={'Tammy'}
          />
          <Host
            image={
              'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/faces/6.jpg'
            }
            name={'Sarah'}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

interface IHost {
  image: string;
  name: string;
}

const Host: React.FC<IHost> = ({ image, name }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.hostContainer}>
      <div className={classes.hostImageContainer}>
        <img src={image} alt={name} className={classes.hostImage} />
      </div>
      <div className={classes.name}>{name}</div>
    </div>
  );
};
