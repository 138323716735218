import { adminConstants } from '_constants';
import { adminService } from '_services';
import { sessionsActions, groupActions } from '_actions';
import { AppDispatch } from '_reducers';
import { EHostLevel } from '_types';

export const adminUserActions = {
  adminUserSearch,
  getUserData,
  getUserActivity,
  updateUserAccount,
  updateUsersView,
  loginAsAnotherUser,
};

function adminUserSearch(hostLevel: EHostLevel | null, search: string) {
  return async () => {
    try {
      const res = await adminService.adminUserSearch(100, 0, hostLevel, search);

      if (res?.users) return Promise.resolve(res?.users);
      return Promise.resolve([]);
    } catch (error) {
      return Promise.reject(false);
    }
  };
}

function getUserData(uuid: string) {
  return async () => {
    try {
      const response = await adminService.getUserData(uuid);
      if (response.user) {
        return Promise.resolve(response.user);
      }
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function getUserActivity(uuid: string, limit: number, offset: number) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await adminService.getUserActivity(uuid, limit, offset);
      if (response.activity) {
        let data = response.activity;
        for (let i = 0; i < data.length; i++) {
          //if session get info
          if (data[i].session_uuid) {
            data[i].details = await dispatch(
              sessionsActions.getSessionInfo(data[i].session_uuid),
            );
          }
          //if group join get the info
          else if (data[i].type === 'GROUP_JOIN' && data[i].target_uuid1) {
            data[i].details = await dispatch(
              groupActions.getGroupInfo(data[i].target_uuid1),
            );
          } else data[i].details = {};
        }

        return Promise.resolve(data);
      }

      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function updateUserAccount(
  uuid: string,
  hostLevel: EHostLevel,
  isActive: string,
) {
  return async () => {
    try {
      await adminService.updateUserAccount(uuid, hostLevel, isActive);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function loginAsAnotherUser(uuid: string) {
  return async () => {
    try {
      await adminService.loginAsAnotherUser(uuid);
      window.location.reload();
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function updateUsersView(isShowing: boolean | string) {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: adminConstants.UPDATE_USERS_VIEW, isShowing: isShowing });
  };
}
