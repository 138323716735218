import { useState, useEffect, useCallback } from 'react';
import { useWellApp, useWellDispatch } from '_hooks';
import {
  adminGeneralActions,
  adminSessionsActions,
  sessionsActions,
} from '_actions';

//TODO: Add pagination
export const useFlagsList = () => {
  const { getUserInformation } = useWellApp();

  const dispatch = useWellDispatch();

  const [flagData, setFlagData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(async () => {
    setIsLoading(true);

    const data = await dispatch(adminGeneralActions.getFlaggedData(1000, 0));

    for (let i = 0; i < data.length; i++) {
      if (data[i].type === 'USER_FLAG_SESSION')
        data[i].session_target = await dispatch(
          sessionsActions.getSession(data[i].target_uuid1)
        );
    }

    if (data) setFlagData(data);
    setIsLoading(false);
  }, [dispatch, getUserInformation]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    flagData,
    isLoading,
    getData,
  };
};
