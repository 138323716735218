import { lazy } from 'react';

export const AdminContainer = lazy(() =>
  import('Admin').then((module) => ({ default: module.AdminContainer }))
);
export const TwilioVideoApp = lazy(() =>
  import('SessionRoom/SessionRoomTwilio/TwilioVideoApp').then((module) => ({
    default: module.TwilioVideoApp,
  }))
);

export const Hosts = lazy(() =>
  import('Hosts').then((module) => ({ default: module.Hosts }))
);

export const MeetHosts = lazy(() =>
  import('Hosts').then((module) => ({ default: module.MeetHosts }))
);

export const Gift = lazy(() =>
  import('Subscription').then((module) => ({ default: module.Gift }))
);

export const Blogs = lazy(() =>
  import('Blog').then((module) => ({ default: module.Blogs }))
);

/////////////////
/// landing pages
////////////////

export const Adhd = lazy(() =>
  import('Homepage').then((module) => ({ default: module.Adhd }))
);

export const Anxiety = lazy(() =>
  import('Homepage').then((module) => ({ default: module.Anxiety }))
);

export const Depression = lazy(() =>
  import('Homepage').then((module) => ({ default: module.Depression }))
);

export const EatingDisorder = lazy(() =>
  import('Homepage').then((module) => ({ default: module.EatingDisorder }))
);

export const EmotionalAbuse = lazy(() =>
  import('Homepage').then((module) => ({ default: module.EmotionalAbuse }))
);

export const GeneralMentalHealth = lazy(() =>
  import('Homepage').then((module) => ({ default: module.GeneralMentalHealth }))
);

export const Grief = lazy(() =>
  import('Homepage').then((module) => ({ default: module.Grief }))
);

export const RelationshipStruggles = lazy(() =>
  import('Homepage').then((module) => ({
    default: module.RelationshipStruggles,
  }))
);

export const SelfCare = lazy(() =>
  import('Homepage').then((module) => ({ default: module.SelfCare }))
);

export const Trauma = lazy(() =>
  import('Homepage').then((module) => ({ default: module.Trauma }))
);

/////////////////
/// static pages
////////////////

export const AboutUs = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.AboutUs }))
);
export const ContactUs = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.ContactUs }))
);
export const ContentPolicy = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.ContentPolicy }))
);
export const HowItWorks = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.HowItWorks }))
);
export const Partners = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.Partners }))
);
export const PrivacyPolicy = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.PrivacyPolicy }))
);
export const SafetyResources = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.SafetyResources }))
);
export const Training = lazy(() =>
  import('StaticPages').then((module) => ({ default: module.Training }))
);
export const TermsAndConditions = lazy(() =>
  import('StaticPages').then((module) => ({
    default: module.TermsAndConditions,
  }))
);
