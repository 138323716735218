import { useRef } from 'react';
import styles from './HowItWorks.module.css';
import { useGetImgixVersion, useScrollIntoView } from '_hooks';
import { WellHeader, HomepageDivider, RecommendationsPartners, WellButton } from '_components';

export function Partners() {
  return (
    <div className={styles['how-it-works-container']}>

      <WellHeader
        title={'Partner with ShareWell'}
        subtitle={'Provide best-in-class mental health support to your company or organization by letting us do the heavy lifting!'}
        style={{ fill: 'rgba(248, 247, 243, 255)' }}
      />

      <SecondSection />

      <HomepageDivider />

      <ThirdSection />

      <HomepageDivider />

      <FourthSection />

      <HomepageDivider />

      <RecommendationsPartners />

      <HomepageDivider />

      <FifthSection />

    </div>
  );
}



const SecondSection = () => {

  const handshake = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/handshake-2.svg',
    {
      h: 300,
      w: 300,
    }
  )


  function jump(h: string) {
    var top = document.getElementById(h)?.offsetTop; //Getting Y of target element
    window.scrollTo(0, top ? top - 25 : -1000);
  }


  return (
    <section style={{ paddingTop: '16px' }}>
      <div className={styles['centered-content-container']}>

        <p className={styles['section-text-large']}>
          ShareWell works with partners of all kinds to provide quality peer support to enhance human connection and foster continuous growth and healing. We are passionate about peer support because we believe that no one is, or should be, alone in overcoming life's challenges.
        </p>


        <img
          className={styles['handshake']}
          src={handshake}
          alt={'ShareWell host bios'}
        />


        <h2 className={styles['section-title'] + ' ' + styles['blue']}>Which describes you best?</h2>
        <div className={styles['selection-row']}>


          <div className={styles['selection']}>

            <h4 className={styles['selection-top']}>A corporation</h4>
            <p className={styles['selection-text']}>
              looking to enhance and strengthen the mental health of its employees and stakeholders.
            </p>

            <WellButton
              btnOnClick={() => jump('corporation')}
              className={styles['thats-me-btn']}
              type={'well-btn-orange'}
              btnText={'THAT\'S ME'}
            />
          </div>


          <div className={styles['selection']}>

            <h4 className={styles['selection-top']}>A non-profit</h4>
            <p className={styles['selection-text']}>
              and/or community-driven organization that provides mental health support and services.
            </p>

            <WellButton
              btnOnClick={() => jump('nonprofit')}
              className={styles['thats-me-btn']}
              type={'well-btn-orange'}
              btnText={'THAT\'S ME'}
            />
          </div>




        </div>

      </div>
    </section>
  )

}



const ThirdSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/alt-1.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/alt-2.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageThree = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/alt-3.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();
  const imageTwoRef = useRef<any>();
  const imageThreeRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(imageTwoRef, true, 0, () => {
    imageTwoRef?.current?.classList.add(styles['animate']);
  });
  useScrollIntoView(imageThreeRef, true, 0, () => {
    imageThreeRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section className={styles['white']} id={'corporation'}>
      <div className={styles['alternating-columns-row']}>

        <h2 className={styles['section-title'] + ' ' + styles['blue']}>Corporations big and small</h2>
        <p className={styles['section-text-large']}>
          We provide teams with community-driven mental health support that encourages connection and therapeutic conversations. Curious how to leverage the power of peer support for your company?
        </p>

        <div className={styles['alternating-columns-item'] + ' ' + styles['limit']}>
          <img
            className={styles['image-two']}
            src={imageOne}
            alt={'A user on a ShareWell session'}
            ref={imageOneRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>ShareWell can be a company benefit</b> that helps you and your team prioritize mental health and wellness in the workplace. Peer support is proven to boost well-being, decrease loneliness, and promote growth and recovery for a variety of life's challenges. It is also cost-effective and accessible. Write to us at <a href='mailto:partnerships@sharewellnow.com'>partnerships@sharewellnow.com</a> if you're looking for a customized partnership arrangement.
          </p>
        </div>

        <div className={styles['alternating-columns-item'] + ' ' + styles['reverse'] + ' ' + styles['limit']}>
          <img
            className={styles['image-three']}
            src={imageTwo}
            alt={'ShareWell discussion board'}
            ref={imageTwoRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Host peer support sessions for your company</b> on ShareWell. In addition to topics such as anxiety and burnout. ShareWell is a safe space to have bold conversations on diversity, equity and inclusion. We welcome both employee resource groups and company leadership to host peer-to-peer conversations on these important topics that relate to everyone's life experiences.
          </p>
        </div>


        <div className={styles['alternating-columns-item'] + ' ' + styles['limit']}>
          <img
            className={styles['image-four']}
            src={imageThree}
            alt={'ShareWell chats'}
            ref={imageThreeRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Be inspired by ShareWell's founder.</b> Looking for a speaker for your next event? Our founder, CeCe Cheng, has developed a model to scale peer support globally and can inspire and educate your community on how a peer model is the solution to the mental health crisis. Give your team a new perspective on eliminating stigma against mental health challenges and prioritizing their own wellness.
          </p>
        </div>


      </div>
    </section>
  )

}



const FourthSection = () => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/alt-4.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/alt-5.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageThree = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partners/alt-6.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();
  const imageTwoRef = useRef<any>();
  const imageThreeRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(imageTwoRef, true, 0, () => {
    imageTwoRef?.current?.classList.add(styles['animate']);
  });
  useScrollIntoView(imageThreeRef, true, 0, () => {
    imageThreeRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section className={styles['white']} id={'nonprofit'}>
      <div className={styles['alternating-columns-row']}>

        <h2 className={styles['section-title'] + ' ' + styles['blue']}>Non-profits and other mental health organizations</h2>
        <p className={styles['section-text-large']}>
          We work with partners like you across the globe to provide an abundance of quality peer support options.
          Ready to join us? Here are some things you can do!
        </p>


        <div className={styles['alternating-columns-item'] + ' ' + styles['limit']}>
          <img
            className={styles['image-two']}
            src={imageOne}
            alt={'A user on a ShareWell session'}
            ref={imageOneRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Share us as a resource</b> so your community can access free peer support on a variety of topics touching thousands of lives on ShareWell. Download our Partnership Promotion Kit for different ways you can get the word out. Whether you'd like to print our flyer for waiting rooms or post on social media, we've got some suggestions to make it easier!
          </p>
        </div>

        <div className={styles['alternating-columns-item'] + ' ' + styles['reverse'] + ' ' + styles['limit']}>
          <img
            className={styles['image-three']}
            src={imageTwo}
            alt={'ShareWell discussion board'}
            ref={imageTwoRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Host your own peer support sessions</b> using our platform. Maybe you already host peer support sessions virtually. Did you know ShareWell has unique safety features designed to minimize disruptive experiences? Both organizations and individuals can use ShareWell's video platform to host secure and intimate peer support sessions
          </p>
        </div>


        <div className={styles['alternating-columns-item'] + ' ' + styles['limit']}>
          <img
            className={styles['image-four']}
            src={imageThree}
            alt={'ShareWell chats'}
            ref={imageThreeRef}
          />

          <p className={styles['section-text']}>
            <b className={styles['blue']}>Join ShareWell's facilitator training program</b> and become a mental health supporter and champion! If you're looking to upgrade your skills in facilitating mental health conversations, <a href={'https://forms.gle/VWFj4YsVaMAoVJNMA'} className={styles['link']} target={'_blank'} rel='noreferrer'>sign up for the waitlist</a> and be one of the first to become certified by ShareWell as a mental health supporter.
          </p>
        </div>


      </div>
    </section>
  )

}






const FifthSection = () => {

  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/people-arms.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();


  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  return (
    <section>
      <div className={styles['centered-content-container']}>

        <h2 className={styles['section-title']}>Ready to champion ShareWell's peer support?</h2>

        <p className={styles['section-text'] + ' ' + styles['quote']}>
          We have prepared a Partnership Promotion Kit to kickstart our journey towards greater wellness in the world together. Got questions? Email us at <a href='mailto:partnerships@sharewellnow.com'>partnerships@sharewellnow.com</a>!
        </p>

        <div className={styles['bottom-btn-container']}>
          <a
            href={'https://drive.google.com/drive/folders/11YmOLGUn-GcF1SSvhhDaReN5e1M5ntIi'}
            className={styles['download-btn']}
            target={'_blank'} rel='noreferrer'
          >
            DOWNLOAD NOW
          </a>
        </div>

        <img
          className={styles['image-ten']}
          src={imageOne}
          ref={imageOneRef}
          alt={'ShareWell host bios'}
        />

      </div>
    </section>
  )

}