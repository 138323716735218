import CSS from 'csstype';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      '&:hover': {
        // boxShadow: '0.1875rem 0.1875rem 0.625rem black',
        transform: 'translate(2px, -2px)',
      },
    },
  })
);

interface IXIcon {
  onClick: () => void;
  styles?: CSS.Properties;
}

export const XIcon: React.FC<IXIcon> = ({ onClick, styles }) => {
  const classes = useStyles();

  return (
    <span onClick={onClick} className={classes.item} style={{ ...styles }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
      >
        <g opacity='0.6'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3.93451 4.10089C4.24693 3.78847 4.75346 3.78847 5.06588 4.10089L8.5002 7.53521L11.9345 4.10089C12.2469 3.78847 12.7535 3.78847 13.0659 4.10089C13.3783 4.41331 13.3783 4.91984 13.0659 5.23226L9.63157 8.66658L13.0659 12.1009C13.3783 12.4133 13.3783 12.9198 13.0659 13.2323C12.7535 13.5447 12.2469 13.5447 11.9345 13.2323L8.5002 9.79795L5.06588 13.2323C4.75346 13.5447 4.24693 13.5447 3.93451 13.2323C3.62209 12.9198 3.62209 12.4133 3.93451 12.1009L7.36882 8.66658L3.93451 5.23226C3.62209 4.91984 3.62209 4.41331 3.93451 4.10089Z'
            fill='white'
          />
        </g>
      </svg>{' '}
    </span>
  );
};
