// Requests & forms
import { Moment } from 'moment';

export interface IFormItems {
  [key: string]: TFormItem;
}

export type TFormItem = IFormItemString | IFormItemTime;

export interface IFormItemString {
  __typename: 'IFormItemString';
  value: string;
  validation: RegExp;
  errorMsg: string;
  flag?: boolean;
}

export interface IFormItemTime {
  __typename: 'IFormItemTime';
  value: Moment; // time to use
  validationBefore: Moment; // has to be before this time
  validationAfter: Moment; // has to be after this time
  errorMsg: string;
  flag?: boolean;
}

export function isFormItemString(item: TFormItem): item is IFormItemString {
  return item.__typename === 'IFormItemString';
}

export function isFormItemTime(item: TFormItem): item is IFormItemTime {
  return item.__typename === 'IFormItemTime';
}

/////////////////////////////
/////////// USER ////////////
export interface ICreateAccount {
  username: string;
  email: string;
  name: string;
  password: string;
}

export interface IAccountUpdate {
  username?: string;
  name?: string;
  email?: string;
  receives_general_emails?: boolean;
  receives_session_emails?: boolean;
  receives_marketing_emails?: boolean;
  public_private_profile?: boolean;
  password?: string;
  about_me?: string;
  url?: string;
  user_marketing?: string;
  first_name?: string;
  last_name?: string;
  gender?: string;
  how_find?: string;
  age_range?: string;
  interested_topics?: string;
}

export interface ISubscriptionUpdate {
  auto_subscribe: string;
  subscription_type: number | null;
}

export interface IPurchaseSubscriptionGift {
  sender_email: string;
  sender_name: string;
  recipient_email: string;
  recipient_name: string;
  message: string;
}

export interface IFacebookLogin{
  userId: string;
  name: string
  email: string;
}

/////////////////////////////
/////// SESSIONS ////////////

export interface ISessionCreateRequest {
  title: string;
  description: string;
  start_time: string;
  duration_minutes: number;
  max_attendees: number;
  timezone: string;
  group_uuid: string;
  series_repeat?: string;
  series_end_datetime?: string;
  group_hosted_flag?: boolean;
  parent_session_uuid?: string;
}

export interface ISessionEditRequest {
  title: string;
  description: string;
  start_time: string;
  duration_minutes: number;
  max_attendees: number;
  timezone: string;
  group_uuid: string;
  external_url?: string;
}

export interface ISeriesEditRequest {
  title: string;
  description: string;
  start_time: string;
  duration_minutes: number;
  timezone: string;
  series_repeat: string;
  series_end_datetime?: string;
  max_attendees?: number;
}

/////////////////////////////
////////// GROUPS ///////////

export interface IGroupCreateRequest {
  title: string;
  description: string;
}

/////////////////////////////
////////// SERVER ///////////
export interface IServerEmptyResponse {
  server_time: number;
}


export interface IReturnNotice {
  id: number;
  msg: string;
  name: string;
}

export const SERVER_ERROR = {
  id: 0,
  msg: 'An error has occurred. Please try again.',
  name: 'SERVER_ERROR',
};
