import { handleFetchReturn, failedToFetch } from '..';

export const roomReportingService = {
  reportSession,
  kickUser,
  reportUser,
  blockUser,
};

export function reportSession(sessionUUID, reason) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ note: reason }),
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + sessionUUID + '/flag',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function kickUser(sessionUUID, userUUID, reason) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ note: reason }),
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/' +
      sessionUUID +
      '/user/' +
      userUUID +
      '/kick',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function reportUser(userUUID, reason, sessionUUID) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ note: reason, sessionUUID: sessionUUID }),
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/user/' + userUUID + '/flag',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function blockUser(userUUID, isBlock, reason) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ note: reason }),
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/user/' + userUUID + '/block/' + isBlock,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
