///////////////////////////////////////////////////////////////
// Routes and route logic
///////////////////////////////////////////////////////////////
import { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useShowHeader, useWellApp, useWellSelector } from '_hooks';
import { history } from '_helpers';
import ReduxToastr from 'react-redux-toastr';

import {
  CanCreateSession,
  CanEnterRoom,
  IsAdmin,
  LoggedinRoute,
  LoggedOutRoute,
  OnboardingRoute,
} from '_routing';

//START COMPONENTS
import {
  CookieAcceptBanner,
  Footer,
  GlobalModals,
  WellBanner,
  WellLoader,
  WellMenu,
  WellModal,
} from '_components';

import { HomepageQuiz, HomepageParent, AdLandingPage } from 'Homepage';

import { UserHomeProvider } from 'UserHome';

import {
  GroupCreateProvider,
  GroupEdit,
  GroupInfoProvider,
  GroupListProvider,
  GroupPreferences,
} from 'Groups';

import {
  SeriesEdit,
  SessionCreateOrEditProvider, // handles editing & creating
  SessionInfoProvider,
  SessionsListParent,
} from 'Sessions';

import {
  Login,
  ForgotPassword,
  PasswordReset,
  Logout,
  ThinkificLogin,
  Register,
} from 'Auth';

import {
  CameraTestParent,
  SessionCanceled,
  SessionSurveyParent,
  WaitingRoom,
} from 'SessionRoom';

import { Account, Billing, ProfileProvider, PastSessions } from 'User';

import { PersistentChat } from 'Chat';

import {
  ConfirmEmailSuccess,
  Custom404,
  EmailUnsubscribe,
  ServerDown,
  WellWelcome,
} from 'StaticPages';

import { Onboarding, SubscriptionDetails, PricingDetails } from 'Onboarding';
import { SubscriptionCheckout, RedeemCode } from 'Subscription';

import {
  // pages
  AdminContainer,
  TwilioVideoApp,
  Hosts,
  MeetHosts,
  Gift,
  Blogs,
  // landing pages
  Adhd,
  Anxiety,
  Depression,
  EatingDisorder,
  EmotionalAbuse,
  GeneralMentalHealth,
  Grief,
  RelationshipStruggles,
  SelfCare,
  Trauma,
  // static pages
  AboutUs,
  ContactUs,
  ContentPolicy,
  HowItWorks,
  Partners,
  PrivacyPolicy,
  SafetyResources,
  Training,
  TermsAndConditions,
} from './LazyLoadedRoutes';

import { landingPageData } from 'Homepage';
// bad
import { glossaryTermPagesData } from 'Glossary/GlossaryTermPages/GlossaryTermPagesData';
import { GlossaryTermPage, Glossary } from 'Glossary';

//END COMPONENTS

export const WellRoutesContainer = () => {
  return (
    <Router history={history}>
      <WellRoutes />
    </Router>
  );
};

const WellRoutes = () => {
  const { componentsStore, userStore } = useWellSelector((state) => state);

  const { initialLoad } = useWellApp();

  const shouldShow = useShowHeader();

  // don't let anything happen until the getProfileInfo Returns
  if (initialLoad) return <WellLoader heightWidth={200} position={'inline'} />;

  return (
    <>
      {componentsStore.modalData.isOpen && <WellModal />}
      <CookieAcceptBanner />

      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        preventDuplicates={true}
        position='top-center'
        transitionIn='fadeIn'
        closeOnToastrClick
      />

      <div
        className={
          'router-container ' +
          (componentsStore.modalData.isOpen ? 'darkened' : '')
        }
      >
        <div
          className={'page-content ' + (!shouldShow ? 'no-header-menu' : '')}
        >
          <Suspense fallback={<WellLoader heightWidth={200} />}>
            <Switch>
              {/* GROUP CREATION */}
              <IsAdmin
                path='/well/create'
                exact={true}
                component={GroupCreateProvider}
              />
              <IsAdmin path='/well/:title/:uuid/edit' component={GroupEdit} />

              {/* GROUPS */}
              <LoggedinRoute
                path='/well/:title/:uuid/preferences'
                component={GroupPreferences}
              />

              <LoggedOutRoute
                path={'/login'}
                exact={true}
                render={(props: any) => {
                  return <Login autoLoadModal={false} {...props} />;
                }}
              />

              <Route path='/logout' exact={true} component={Logout} />

              {/*ADMIN*/}
              <IsAdmin path='/admin' exact={true} component={AdminContainer} />
              <IsAdmin
                path={'/waiting-room-test'}
                exact={true}
                component={WaitingRoom}
              />

              <Route path='*' component={Custom404} />
            </Switch>
          </Suspense>
        </div>

        <Footer />
      </div>
    </>
  );
};
