import { useState, useEffect } from 'react';
import { componentActions } from '_actions';
import { useLocation } from 'react-router-dom';
import { useWellDispatch, useWellSelector } from '_hooks';
import { EModalFlags } from '_types';
import { componentsConstants } from '_constants';

// I know what you are thinking.... why not just use css to change the color when user scrolls a
// little off the top of the dom. This way is cooler and works with the Logo well. Do not judge me.
export function useTransparentMenuAtTop() {
  const dispatch = useWellDispatch();

  useEffect(() => {
    document.addEventListener('scroll', scrollWatch);
    dispatch(componentActions.setIsTransparent(true));

    return () => {
      document.removeEventListener('scroll', scrollWatch);
      dispatch(componentActions.setIsTransparent(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollWatch = () => {
    if (document.documentElement.scrollTop < 90) {
      dispatch(componentActions.setIsTransparent(true));
    } else if (document.documentElement.scrollTop > 90) {
      dispatch(componentActions.setIsTransparent(false));
    }
  };
}

export function useHoverOverMenu() {
  const dispatch = useWellDispatch();

  useEffect(() => {
    dispatch(componentActions.setOnlyShowOnHover(true));

    return () => {
      dispatch(componentActions.setOnlyShowOnHover(false));
    };
  }, [dispatch]);
}

// hook to check if I should show the header
const noShowListHeader = [
  '/server-down',
  '/admin',
  '/onboarding',
  '/welcome',
  '/onboarding/membership/details',
  '/onboarding/membership',
  '/register',
  '/login',
  '/redeem',
  '/gift',
  '/forgot-password',
  '/logout',
  '/room',
];

export function useShowHeader() {
  const location = useLocation();

  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    for (let i = 0; i < noShowListHeader.length; i++) {
      if (location.pathname === noShowListHeader[i]) {
        setShouldShow(false);
        return;
      }
    }

    setShouldShow(true);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return shouldShow;
}

// hook to check if I should show the footer
const noShowListFooter = [
  '/server-down',
  '/admin',
  '/room',
  '/well/',
  '/onboarding',
  '/onboarding/membership/details',
  '/onboarding/membership',
  '/sessions',
  '/past-sessions',
  '/anxiety',
  '/depression',
  '/emotional-abuse',
  '/grief',
  '/relationship-struggles',
  '/trauma',
  '/welcome',
  '/register',
];
export function useShowFooter() {
  const location = useLocation();

  const { userStore, authStore } = useWellSelector((state) => state);

  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    // don't show on user home
    if (
      (userStore.loggedIn && location.pathname === '/') ||
      location.pathname === '/home'
    ) {
      setShouldShow(false);
      return;
    }

    for (let i = 0; i < noShowListFooter.length; i++) {
      if (location.pathname.includes(noShowListFooter[i])) {
        setShouldShow(false);
        return;
      }
    }
    setShouldShow(true);

    return;
  }, [authStore.canGoToOnboarding, location, userStore.loggedIn]);

  return shouldShow;
}

// check if I should show banner
const noShowBanner = [
  '/confirmemail',
  '/server-down',
  '/unsubscribe',
  '/admin',
  '/room',
  '/room/wait',
  '/room/preview',
  '/onboarding',
  '/onboarding/membership',
  '/login',
  '/logout',
  '/gift',
  '/welcome',
  '/thinkific',
  '/camera-test',
  '/register',
  '/redeem',
  '/membership/checkout',
  '/session-survey',
];
export function useShowBanner() {
  const location = useLocation();

  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    for (let i = 0; i < noShowBanner.length; i++) {
      if (location.pathname.includes(noShowBanner[i])) {
        setShouldShow(false);
        return;
      }
    }

    setShouldShow(true);
    return;
  }, [location]);

  return shouldShow;
}

// watched for flag changes to call a call back after a modal changes state on server but not on front end
export const useModalFlagWatcher = (
  flag: EModalFlags,
  callback: () => void
) => {
  const dispatch = useWellDispatch();
  const { componentsStore } = useWellSelector((state) => state);
  const { flags } = componentsStore.newModal;

  // watch for when flag changes then flip flag and use callback
  useEffect(() => {
    if ((flags & flag) === flag) {
      callback();
      dispatch({
        type: componentsConstants.SET_UNSET_FLAG_NEW_MODAL,
        set: false,
        flag: flag,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags]);
};
