import CSS from 'csstype';

interface IGreenCheck {
  height: string;
  width: string;
  style: CSS.Properties;
  className?: string;
}

export const GreenCheck: React.FC<IGreenCheck> = ({ height, width, style, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      zoomAndPan="magnify"
      viewBox="0 0 72 72"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      height={height}
      width={width}
      style={style}
      className={className ? className : ''}
    >
      <defs>
        <clipPath id="id1-green">
          <path
            d="M 1.394531 0 L 70.394531 0 L 70.394531 72 L 1.394531 72 Z M 1.394531 0 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#id1-green)">
        <path
          fill="rgb(2.749634%, 76.858521%, 59.21936%)"
          d="M 67.753906 0.226562 L 64.65625 2.351562 C 48.449219 13.464844 31.859375 36.46875 23.578125 55.171875 C 22.300781 53.378906 21.914062 52.78125 20.675781 50.789062 C 15.613281 43.933594 9.199219 38.054688 6.152344 35.882812 L 1.394531 37.875 L 1.546875 38.15625 C 4.832031 41.761719 10.59375 49.761719 19.28125 67.386719 C 19.8125 68.148438 20.382812 68.894531 21.027344 69.59375 C 22.730469 71.417969 24.472656 71.800781 25.636719 71.800781 C 28.953125 71.800781 30.910156 69.175781 32.253906 66.105469 C 40.957031 33.730469 61.515625 11.445312 70.40625 3.8125 Z M 67.753906 0.226562 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export const NoCheck = ({ className }: { className: string }) => {
  return (
    <svg className={className} width="96" height="96" viewBox="0 0 96 96">
      <path
        d="M48,87.5 C26.1847524,87.5 8.5,69.8152476 8.5,48 C8.5,26.1847524 26.1847524,8.5 48,8.5 C69.8152476,8.5 87.5,26.1847524 87.5,48 C87.5,69.8152476 69.8152476,87.5 48,87.5 Z M37.6934003,60.6199738 L26.8170892,49.7436626 L23.3125,53.3690997 L37.6934003,67.75 L68.6304633,36.8129371 L65.0050262,33.1875 L37.6934003,60.6199738 Z"
        fill="#000000"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export const YesCheck = ({ className }: { className: string }) => {
  return (
    <svg className={className} width="96" height="96" viewBox="0 0 96 96">
      <path
        d="M48,87.5 C26.1847524,87.5 8.5,69.8152476 8.5,48 C8.5,26.1847524 26.1847524,8.5 48,8.5 C69.8152476,8.5 87.5,26.1847524 87.5,48 C87.5,69.8152476 69.8152476,87.5 48,87.5 Z M37.6934003,60.6199738 L26.8170892,49.7436626 L23.3125,53.3690997 L37.6934003,67.75 L68.6304633,36.8129371 L65.0050262,33.1875 L37.6934003,60.6199738 Z"
        fill="#000000"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};
