import { useCallback } from 'react';
import { sessionsActions, componentActions, userActions } from '_actions';
import { useWellChat, useWellDispatch, useWellSelector } from '_hooks';
import { componentsConstants, notificationConstants } from '_constants';
import {
  AnalyticsSendEvent,
  getServerTime,
  getSessionStartTime,
} from '_helpers';
import { ISession, ReturnNotice } from '_types';
import { toastr } from 'react-redux-toastr';

// logic for joining or unjoining a session
export const useSessionRegister = () => {
  const dispatch = useWellDispatch();
  const { reloadChannels } = useWellChat();

  const { userStore } = useWellSelector((state) => state);
  const { userData } = userStore;

  // checks if the session has started
  const checkIfSessionStarted = useCallback(
    (session: ISession, updateCallback: () => void) => {
      const startTime = getSessionStartTime(session);

      if (startTime.isBefore(getServerTime())) {
        updateCallback();
        toastr.error('Session has already started', '');
        return true;
      }
      return false;
    },
    []
  );

  // finishes the registration process, after the checks are completed
  const registerForSession = useCallback(
    (
      session: ISession,
      setIsLoading: (loading: boolean) => void,
      updateCallback: () => void
    ) => {
      if (checkIfSessionStarted(session, updateCallback)) return;

      ///////////////////// All checks completed

      setIsLoading(true);

      return dispatch(sessionsActions.joinOrLeaveSession(session.uuid, true))
        .then(() => {
          // send to mix panel user had joined
          AnalyticsSendEvent('session_join', { session: session.uuid });
          // get again the session info
          updateCallback();
          // show congrats modal
          dispatch({
            type: componentsConstants.TOGGLE_NEW_MODAL,
            isSessionJoinModalOpen: session,
          });

          // update profile incase basic member
          dispatch(userActions.getProfileInfo(false));

          // reload the chat channels as user can not use the chat room
          reloadChannels();
        })
        .catch((notice) => {
          // check if the notice is about being on basic plan, we can show a modal here
          if (
            notice.name ===
              ReturnNotice.SESSION_JOINED_TOO_MANY_BASIC.getName() ||
            notice.name ===
              ReturnNotice.SESSION_BASIC_PLAN_MAX_REACHED.getName()
          ) {
            dispatch({
              type: componentsConstants.TOGGLE_NEW_MODAL,
              isBasicRegisterCtaModalOpen: true,
            });
          } else {
            // otherwise just give server error
            toastr.error(notice.msg, '');
          }
          // callback to so component knows what happened
          updateCallback();
        })
        .finally(() => setIsLoading(false));
    },
    [checkIfSessionStarted, dispatch, reloadChannels]
  );

  // unregister a user from the session
  const unregisterFromSession = useCallback(
    async (
      session: ISession,
      setIsLoading: (loading: boolean) => void,
      updateCallback: () => void
    ) => {
      if (checkIfSessionStarted(session, updateCallback)) return;

      const finishUnregister = () => {
        AnalyticsSendEvent('session_unjoin', {
          session: session.uuid,
          user: userData.uuid,
        });
        updateCallback();
        setIsLoading(false);
        dispatch(userActions.getProfileInfo(false)); // incase basic member
        reloadChannels(); //reload the chat channels
      };

      dispatch(
        componentActions.openModal({
          title: 'Unregister from this session?',
          body: notificationConstants.SESSION_UNREGISTER_NOTICE,
          modalStyle: { textAlign: 'center' },
          noButtonStyle: { background: '#999999' },
          yesButtonStyle: {
            background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
          },
          yesText: 'UNREGISTER',
          noText: 'CANCEL',
          afterText: 'You have successfully unregistered.',
          yesClick: async () => {
            setIsLoading(true);
            await dispatch(
              sessionsActions.joinOrLeaveSession(session.uuid, false)
            );
            finishUnregister();
          },
          noClick: () => {},
        })
      );
    },
    [checkIfSessionStarted, dispatch, reloadChannels, userData.uuid]
  );

  return {
    registerForSession,
    unregisterFromSession,
  };
};
