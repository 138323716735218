import styles from './UserHome.module.css';
import { Link } from 'react-router-dom';
import {
  Forum,
  WellLoader,
  SessionItemList,
  WellButtonLink,
  ForumProvider,
  SessionItemListEmpty,
  UHLeftMenu,
  UserHomeTop,
} from '_components';
import { generalConstants, sessionsConstants } from '_constants';
import { useSessionsPagination, useUserHome, useWellSelector } from '_hooks';
import { getNowStringFormat, getFutureStringFormat } from '_helpers';
import { EHostLevel, ESortType } from '_types';
import { PollItem } from '_components/Forum/PollItem/PollItem';

export const UserHome = () => {
  const { isLoadingMyGroups } = useUserHome();

  if (isLoadingMyGroups) {
    return (
      <div className={styles['user-homepage']}>
        <WellLoader heightWidth={150} />
      </div>
    );
  }

  return (
    <>
      <div className={styles['user-homepage']}>
        <div className={styles['homepage-row']}>
          <div className={styles['left']}>
            <UHLeftMenu />
          </div>

          <RightSide />
        </div>
      </div>
    </>
  );
};

const RightSide = () => {
  const { userStore } = useWellSelector((state) => state);

  const { selectedGroup, pinnedActivities, isLoadingPinnedActivities } =
    useUserHome();

  const { allSessions, isLoadingSessions, reloadSingleSession } =
    useSessionsPagination(
      selectedGroup.uuid === ''
        ? generalConstants.SPECIAL_UUID
        : selectedGroup.uuid,
      getNowStringFormat(),
      getFutureStringFormat(),
      ESortType.SORT_TYPE_SOON,
      false,
      sessionsConstants.LIST_PAGINATION_LIMIT,
      true,
    );

  if (isLoadingSessions || isLoadingPinnedActivities)
    return (
      <div className={styles['center-container']}>
        <div className={styles['loading-container']}>
          <WellLoader heightWidth={200} />
        </div>
      </div>
    );

  return (
    <>
      <div className={styles['center-container']}>
        <UserHomeTop
          allSessions={allSessions}
          reloadSingleSession={reloadSingleSession}
        />

        {selectedGroup.uuid === '' && <PollItem />}
        <ForumProvider
          group={
            selectedGroup.uuid === ''
              ? generalConstants.SPECIAL_UUID
              : selectedGroup.uuid
          }
          pinnedActivities={selectedGroup.uuid === '' ? pinnedActivities : null}
        >
          <Forum
            showStatusUpdate={
              (selectedGroup.uuid ===
                process.env.REACT_APP_WELCOME_GROUP_UUID &&
                userStore.userData.host_level !== EHostLevel.HOST_LEVEL_2) ||
              selectedGroup.uuid === ''
                ? false
                : true
            }
          />
        </ForumProvider>
      </div>

      <div className={styles['right']}>
        <div className={styles['items-container']}>
          <div className={styles['header-container']}>
            <h2 className={styles['title']}>Upcoming Sessions</h2>
          </div>

          {allSessions.length === 0 || allSessions.length === 1 ? (
            <div>
              <p className={styles['join-session-text']}>
                {allSessions.length === 0 ? (
                  <>
                    It looks like this well does not have any sessions. Be the
                    first to create one and bring support to those who need it!
                  </>
                ) : (
                  <>
                    It looks like this Well only has one session. Create another
                    one and bring support to those who need it!
                  </>
                )}
              </p>
              <WellButtonLink
                to={'/session-create'}
                className={styles['create-session-btn']}
                btnText={'CREATE A SESSION'}
                type={'well-btn-orange'}
              />
            </div>
          ) : (
            <>
              {allSessions && allSessions[1] && (
                <SessionItemList
                  session={allSessions[1]}
                  key={'user-homepage-session-featured-' + allSessions[1].uuid}
                  allowRegister={true}
                  reloadSession={reloadSingleSession}
                />
              )}
              {allSessions && allSessions[2] && (
                <SessionItemList
                  session={allSessions[2]}
                  key={'user-homepage-session-featured-' + allSessions[2].uuid}
                  allowRegister={true}
                  reloadSession={reloadSingleSession}
                />
              )}
              {allSessions && allSessions[3] && (
                <SessionItemList
                  session={allSessions[3]}
                  key={'user-homepage-session-featured-' + allSessions[3].uuid}
                  allowRegister={true}
                  reloadSession={reloadSingleSession}
                />
              )}
              {allSessions &&
                allSessions[1] &&
                userStore.userData.host_level > EHostLevel.HOST_LEVEL_0 && (
                  <SessionItemListEmpty
                    allowCreate={true}
                    selectedGroup={selectedGroup}
                  />
                )}
              {allSessions && allSessions[1] && (
                <Link
                  to={
                    selectedGroup.uuid !== ''
                      ? '/well/' +
                        selectedGroup.name.toLowerCase().replace(' ', '-') +
                        '/' +
                        selectedGroup.uuid +
                        '/sessions'
                      : '/sessions'
                  }
                  className={styles['view-all-session']}
                >
                  View All
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
