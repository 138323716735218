import { useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './RoomModal.module.css';
import { useClickOutside } from '_hooks';
import { XIcon } from '_components';

export const RoomModal = ({ closeModal, children }: any) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, () => {
    if (closeModal) closeModal(false);
  });

  return createPortal(
    <>
      <div className={styles['room-modal-overlay']} />
      <div className={styles['room-modal']} ref={modalRef}>
        <div className={styles['close-modal']}>
          <XIcon onClick={() => closeModal && closeModal(false)} />
        </div>
        {children}
      </div>
    </>,
    document.getElementById('portal')!
  );
};
