import styles from './SubscriptionItem.module.css';
import { ISubscriptionType } from '_types';
import { toFixed } from '_helpers';

interface ISubscriptionItemSmall {
  subscriptionData: ISubscriptionType;
  isActive: boolean;
  onClick: () => void;
  color: 'one' | 'two' | 'three';
}

export const SubscriptionItemSmall = ({
  subscriptionData,
  isActive,
  onClick,
  color,
}: ISubscriptionItemSmall) => {
  return (
    <div
      className={
        styles['subscription'] +
        ' ' +
        (isActive ? styles['active'] : '') +
        ' ' +
        styles[color]
      }
      onClick={onClick}
    >
      <span className={styles['free-trial']}>{subscriptionData.name}</span>

      <div className={styles['bottom']}>
        {subscriptionData.months_off !== 1 &&
          subscriptionData.slashed_price && (
            <span className={styles['slashed-price']}>
              ${subscriptionData.slashed_price}
            </span>
          )}
        <span>
          $
          {parseFloat(subscriptionData.price) % 1 === 0
            ? parseInt(subscriptionData.price).toFixed(0)
            : parseFloat(subscriptionData.price).toFixed(2)}
        </span>
      </div>

      <div className={styles['cadence']}>{subscriptionData.billed_cadence}</div>
      {subscriptionData.months_off !== 1 && (
        <div className={styles['price-per-month']}>
          $
          {toFixed(
            parseFloat(
              parseFloat(subscriptionData.price) / subscriptionData.months_off +
                ''
            ),
            2
          )}{' '}
          Per Month
        </div>
      )}
    </div>
  );
};

// just hard coded data
export const SubscriptionItemDiscount = () => {
  return (
    <div
      className={
        styles['subscription'] + ' ' + styles['active'] + ' ' + styles['two']
      }
    >
      <span className={styles['free-trial']}>1 Month Special</span>

      <div className={styles['bottom']}>
        <span className={styles['slashed-price']}>15</span>
        <span>$3</span>
      </div>

      <div className={styles['cadence']}>Then billed monthly at</div>
      <div className={styles['price-per-month']}>$15 Per Month</div>
    </div>
  );
};
