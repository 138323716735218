import { useState } from 'react';
import { generalActions } from '_actions';

import {
  PostSessionQuestionGeneric,
  WellButton,
  WellHeader,
  WellInputWrapper,
} from '_components';
import styles from './SessionSurveyHost.module.css';
import { useWellDispatch, useWellSelector } from '_hooks';
import { ScreenSessionRecommendations } from 'SessionRoom';

export const SessionSurveyGroupHosted = () => {
  const dispatch = useWellDispatch();
  const { userStore, roomStore } = useWellSelector((state) => state);
  const { sessionInfo, postSessionChecks } = roomStore;
  const { userData } = userStore;

  const [screen, setScreen] = useState(
    postSessionChecks.completed_mood_tracker ? 1 : 0
  );

  const [answers, setAnswers] = useState<[number, number, string]>([
    -1,
    -1,
    '',
  ]);

  const setAnswer = (key: number, value: number | string) => {
    setAnswers((prev) => {
      let newList: [number, number, string] = [...prev];
      newList[key] = value;
      return newList;
    });
  };

  function handleSubmit() {
    const answerObj = {
      session: sessionInfo.uuid,
      user: userData.uuid,
      how_session_impact_mood: answers[0],
      how_satisfied_with_support: answers[1],
      how_could_make_better: '',
    };
    dispatch(
      generalActions.setOrAppendMarketingData(
        'append',
        'grouphosted_survey',
        JSON.stringify(answerObj)
      )
    );
    setScreen(1);
  }

  if (screen === 1) return <ScreenSessionRecommendations />;

  return (
    <div className={styles['outside']}>
      <WellHeader
        title={
          "You've completed a Group-Hosted session! This is a new offering, and we'd really appreciate feedback on your experience and what we can improve."
        }
      />

      <div className={styles['hostless-session'] + ' container'}>
        <>
          <PostSessionQuestionGeneric
            question={'How did this session impact your mood?'}
            setAnswer={(value: number) => setAnswer(0, value)}
            selected={answers[0]}
            questionColor={'#2b7bb9'}
          />

          <PostSessionQuestionGeneric
            question={
              'How satisfied are you with the support you received in this session?'
            }
            setAnswer={(value: number) => setAnswer(1, value)}
            selected={answers[1]}
            questionColor={'#2b7bb9'}
          />

          <WellInputWrapper
            containerClassName={styles['input-container']}
            label={
              'What could have made your group-hosted session better? We take your feedback seriously'
            }
          >
            <>
              <textarea
                value={answers[2]}
                onChange={(e) => setAnswer(2, e.target.value)}
                placeholder={
                  'What are one to two things that would have improved the session quality?'
                }
                className={styles['textarea']}
              />
            </>
          </WellInputWrapper>

          <div className={styles['btn-container']}>
            <WellButton
              className={styles['submit-survey']}
              btnOnClick={handleSubmit}
              btnText='SUBMIT'
              isLoading={false}
              type={'well-btn-orange'}
            />
          </div>
        </>
      </div>
    </div>
  );
};
