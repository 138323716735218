import { useState, useRef } from 'react';
import styles from './StaticPages.module.css';
import WellLogo from '_images/WellLogo.png';
import { history, AnalyticsSendEvent } from '_helpers';
import { ValidateBatch } from '_helpers';
import { Form, FormControl } from 'react-bootstrap';
import { WellButton } from '_components';
import { KeyboardEvent } from '_types';

export function ComingSoon() {
  const [email, updateEmail] = useState('');
  const [emailSubmitted, updateEmailSubmitted] = useState(false);
  const emailRef = useRef(null);

  function sendEmail() {
    let validateArray = [
      { value: email, type: 'email', ref: emailRef.current },
    ];

    if (!ValidateBatch(validateArray)) return;

    AnalyticsSendEvent('marketing_email_capture', { email: email });
    updateEmailSubmitted(true);
  }

  function onKeyDownHandler(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      sendEmail();
    }
  }

  return (
    <>
      <div
        className={styles['coming-soon-container'] + ' container'}
        onKeyDown={(e) => {
          onKeyDownHandler(e);
        }}
      >
        <img
          src={WellLogo}
          className={styles['well-logo']}
          alt={'ShareWell logo'}
        />
        <p className={styles['coming-soon-text']}>
          ShareWell is a peer support platform making wellness accessible to
          all. We offer live small group sessions created by peer supporters who
          share your experience. We're currently only offering sessions to a
          small group of early community members.
        </p>

        {!history.location.pathname.includes('/confirmemail') &&
        !history.location.pathname.includes('/unsubscribe') ? (
          <div className={styles['email-capture']}>
            {!emailSubmitted ? (
              <>
                <p>
                  Interested in peer support? Sign up here for early access!
                </p>

                <Form.Group
                  className={
                    styles['email-capture-container'] +
                    ' well-form-group col-10 col-md-6 col-lg-4'
                  }
                >
                  <Form.Label className={'well-form-label'}>Email</Form.Label>
                  <div className={'well-form-item-container'} ref={emailRef}>
                    <FormControl
                      className={'well-form-item'}
                      autoFocus
                      onChange={(e) => updateEmail(e.target.value)}
                    />
                  </div>
                  <div className={'form-error-msg'}></div>
                </Form.Group>

                <WellButton
                  className={styles['email-submit-btn']}
                  btnOnClick={sendEmail}
                  btnText="SUBMIT"
                  type={'well-btn-primary'}
                />
              </>
            ) : (
              <p>Thank you! Your email has been submitted!</p>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}
