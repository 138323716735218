import { useContext } from 'react';
import { GroupInfoContext } from 'Groups';

export const useGroupInfo = () => {
  const context = useContext(GroupInfoContext);
  if (context === undefined) {
    throw new Error('useGroupInfo must be used within a GroupInfoContext');
  }
  return context;
};
