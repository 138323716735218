import styles from './BadgeItem.module.css';

import { badgeConstants } from '_constants'

import HostBadgeOne from '_images/badges/host_1.svg';
import HostBadgeTwo from '_images/badges/host_2.svg';
import HostBadgeThree from '_images/badges/host_3.svg';

import PeerBadgeOne from '_images/badges/peer_1.svg';
import PeerBadgeTwo from '_images/badges/peer_2.svg';
import PeerBadgeThree from '_images/badges/peer_3.svg';
import { useEffect, useState } from 'react';


interface IBadgeItem {
  curNumber: number;
}

export const BadgeItemHosted = ({ curNumber }: IBadgeItem) => {

  const [numToUse, setNumToUse] = useState('0');

  useEffect(() => {
    if (curNumber < badgeConstants.HOSTED_ONE)
      setNumToUse(((curNumber / badgeConstants.HOSTED_ONE) * 100).toFixed(2))
    else if (curNumber < badgeConstants.HOSTED_TWO)
      setNumToUse(((curNumber / badgeConstants.HOSTED_TWO) * 100).toFixed(2))
    else
      setNumToUse(((curNumber / badgeConstants.HOSTED_THREE) * 100).toFixed(2))
  }, [curNumber])


  return (
    <div className={styles['container'] + ' ' + styles['sessions']}>
      <div className={styles['top']}>
        <h4 className={styles['title']}>Sessions Hosted</h4>
        <div className={styles['number']}>{curNumber}</div>
      </div>


      <div className={styles['divider']}></div>

      <div className={styles['middle']}>
        <div className={styles['cur-badge-container']}>
          <svg viewBox="0 0 36 36" className={styles['circular-chart']}>
            <path className={styles['circle-bg']}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className={styles['circle']}
              strokeDasharray={(numToUse !== '-Infinity' ? numToUse : '0') + ", 100"}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            {curNumber < badgeConstants.HOSTED_ONE ?
              <image href={HostBadgeOne} className={styles['badge-middle']} x='+3.3px' y={'+3.1px'} />
              : curNumber < badgeConstants.HOSTED_TWO ?
                <image href={HostBadgeTwo} className={styles['badge-middle']} x='+3.3px' y={'+3.1px'} />
                :
                <image href={HostBadgeThree} className={styles['badge-middle']} x='+3.3px' y={'+3.1px'} />
            }
          </svg>
        </div>

        <div className={styles['middle-text']}>
          <h4>
            {curNumber < badgeConstants.HOSTED_ONE ?
              <>Host</>
              : curNumber < badgeConstants.HOSTED_TWO ?
                <>StarHost</>
                :
                <>SuperHost</>
            }
          </h4>
          <h5>
            {curNumber < badgeConstants.HOSTED_ONE ?
              <>{badgeConstants.HOSTED_ONE - curNumber} more {(badgeConstants.HOSTED_ONE - curNumber === 1 ? <>Session</> : <>Sessions</>)} to host</>
              : curNumber < badgeConstants.HOSTED_TWO ?
                <>{badgeConstants.HOSTED_TWO - curNumber} more {(badgeConstants.HOSTED_TWO - curNumber === 1 ? <>Session</> : <>Sessions</>)} to host</>
                : curNumber < badgeConstants.HOSTED_THREE ?
                  <>{badgeConstants.HOSTED_THREE - curNumber} more {(badgeConstants.HOSTED_THREE - curNumber === 1 ? <>Session</> : <>Sessions</>)} to host</>
                  :
                  <>Congrats you have completed this badge!</>
            }
          </h5>
        </div>

      </div>

      <div className={styles['bottom']}>
        <div
          className={styles['badge-item'] +
            ' ' +
            (curNumber >= badgeConstants.HOSTED_ONE ? styles['active'] : '')
          }>
          <img src={HostBadgeOne} className={styles['badge-bottom']} alt={'Host Badge One'} />
          <h4>Host</h4>
          <h5>Host 1 <br></br>Session</h5>
        </div>
        <div
          className={styles['badge-item'] +
            ' ' +
            (curNumber >= badgeConstants.HOSTED_TWO ? styles['active'] : '')
          }>
          <img src={HostBadgeTwo} className={styles['badge-bottom']} alt={'Host Badge Two'} />
          <h4>StarHost</h4>
          <h5>Host 10 <br></br>Sessions</h5>
        </div>
        <div
          className={styles['badge-item'] +
            ' ' +
            (curNumber >= badgeConstants.HOSTED_THREE ? styles['active'] : '')

          }>
          <img src={HostBadgeThree} className={styles['badge-bottom']} alt={'Host Badge Three'} />
          <h4>SuperHost</h4>
          <h5>Host 50<br></br> Sessions</h5>
        </div>

      </div>

    </div >
  )


}




export const BadgeItemSupported = ({ curNumber }: IBadgeItem) => {

  const [numToUse, setNumToUse] = useState('0');


  useEffect(() => {
    if (curNumber < badgeConstants.PEERS_SUPPORTED_ONE)
      setNumToUse(((curNumber / badgeConstants.PEERS_SUPPORTED_ONE) * 100).toFixed(2))
    else if (curNumber < badgeConstants.PEERS_SUPPORTED_TWO)
      setNumToUse(((curNumber / badgeConstants.PEERS_SUPPORTED_TWO) * 100).toFixed(2))
    else
      setNumToUse(((curNumber / badgeConstants.PEERS_SUPPORTED_THREE) * 100).toFixed(2))
  }, [curNumber])


  return (
    <div className={styles['container'] + ' ' + styles['peers']}>
      <div className={styles['top']}>
        <h4 className={styles['title']}>Peers Supported</h4>
        <div className={styles['number']}>{curNumber}</div>
      </div>


      <div className={styles['divider']}></div>

      <div className={styles['middle']}>
        <div className={styles['cur-badge-container']}>
          <svg viewBox="0 0 36 36" className={styles['circular-chart']}>
            <path className={styles['circle-bg']}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className={styles['circle']}
              strokeDasharray={(numToUse !== '-Infinity' ? numToUse : '0') + ", 100"}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            {curNumber < badgeConstants.PEERS_SUPPORTED_ONE ?
              <image href={PeerBadgeOne} className={styles['badge-middle']} x='+3.3px' y={'+3.5px'} />
              : curNumber < badgeConstants.PEERS_SUPPORTED_TWO ?
                <image href={PeerBadgeTwo} className={styles['badge-middle']} x='+3.3px' y={'+3.5px'} />
                :
                <image href={PeerBadgeThree} className={styles['badge-middle']} x='+3.3px' y={'+3.5px'} />
            }
          </svg>


        </div>

        <div className={styles['middle-text']}>
          <h4>
            {curNumber < badgeConstants.PEERS_SUPPORTED_ONE ?
              <>Peer</>
              : curNumber < badgeConstants.PEERS_SUPPORTED_TWO ?
                <>StarPeer</>
                :
                <>SuperPeer</>
            }
          </h4>
          <h5>
            {curNumber < badgeConstants.PEERS_SUPPORTED_ONE ?
              <>{badgeConstants.PEERS_SUPPORTED_ONE - curNumber} more {(badgeConstants.PEERS_SUPPORTED_ONE - curNumber === 1 ? <>peer</> : <>peers</>)} to be supported</>
              : curNumber < badgeConstants.PEERS_SUPPORTED_TWO ?
                <>{badgeConstants.PEERS_SUPPORTED_TWO - curNumber} more {(badgeConstants.PEERS_SUPPORTED_TWO - curNumber === 1 ? <>peer</> : <>peers</>)} to be supported</>
                : curNumber < badgeConstants.PEERS_SUPPORTED_THREE ?
                  <>{badgeConstants.PEERS_SUPPORTED_THREE - curNumber} more {(badgeConstants.PEERS_SUPPORTED_THREE - curNumber === 1 ? <>peer</> : <>peers</>)} to be supported</>
                  :
                  <>Congrats you have completed this badge!</>
            }
          </h5>
        </div>




      </div>

      <div className={styles['bottom']}>
        <div
          className={styles['badge-item'] +
            ' ' +
            (curNumber >= badgeConstants.PEERS_SUPPORTED_ONE ? styles['active'] : '')
          }>
          <img src={PeerBadgeOne} className={styles['badge-bottom']} alt={'Peer Badge One'} />
          <h4>Peer</h4>
          <h5>Support 10 <br></br>Peers</h5>
        </div>
        <div
          className={styles['badge-item'] +
            ' ' +
            (curNumber >= badgeConstants.PEERS_SUPPORTED_TWO ? styles['active'] : '')
          }>
          <img src={PeerBadgeTwo} className={styles['badge-bottom']} alt={'Peer Badge Two'} />
          <h4>StarPeer</h4>
          <h5>Support 100 <br></br>Peers</h5>
        </div>
        <div
          className={styles['badge-item'] +
            ' ' +
            (curNumber >= badgeConstants.PEERS_SUPPORTED_THREE ? styles['active'] : '')
          }>
          <img src={PeerBadgeThree} className={styles['badge-bottom']} alt={'Peer Badge Three'} />
          <h4>SuperPeer</h4>
          <h5>Support 500 <br></br>Peers</h5>
        </div>

      </div>

    </div >
  )


}


