import { useState, useRef } from 'react';
import styles from './LikeItem.module.css';
import { ThumbsUpIcon, IconColors, EmojisSelector } from '_components';
import { useClickOutside, useLikeItem } from '_hooks';

export const LikeItemButton = ({ activity, handleReload }) => {
  const [openEmojis, setOpenEmojis] = useState(false);
  const { sendEmoji, unsendEmoji, likedItems } = useLikeItem(
    activity,
    setOpenEmojis,
    handleReload
  );

  //refs
  const emojiSelectorRef = useRef(null);
  useClickOutside(emojiSelectorRef, () => setOpenEmojis(false));

  return (
    <div
      className={styles['like-item-button'] + ' ' + styles['not-reaction']}
      onClick={() => setOpenEmojis(true)}
    >
      {openEmojis && (
        <div ref={emojiSelectorRef}>
          <EmojisSelector
            sendEmoji={sendEmoji}
            unsendEmoji={unsendEmoji}
            setOpenEmojis={setOpenEmojis}
            likedItems={likedItems}
            activity={activity}
          />
        </div>
      )}

      {!likedItems ? (
        <div className={styles['like-button']}>
          <ThumbsUpIcon style={{ color: IconColors.Inactive }} />
          <span className={styles['unliked-text']}>Like</span>
        </div>
      ) : (
        <div
          className={styles['like-button']}
          onClick={() => setOpenEmojis(true)}
        >
          <ThumbsUpIcon style={{ color: IconColors.Active }} />
          <span className={styles['liked-text']}>Like</span>
        </div>
      )}
    </div>
  );
};
