import { useCallback, useEffect } from 'react';
import styles from './FreeMonthReceivedModal.module.css';
import { useWellDispatch } from '_hooks';
import {
  ModalWrapper,
  ModalBodyWrapper,
  WellButtonLink,
  ModalComponentFreeMonth,
} from '_components';
import { componentsConstants } from '_constants';
import { history } from '_helpers';

export const FreeMonthReceivedModal = () => {
  const dispatch = useWellDispatch();

  const closeModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isWelcomeOpen: false,
    });
  }, [dispatch]);

  // if you change page close modal
  useEffect(() => {
    // monitor changes in history, to close modal
    const unlisten = history.listen(() => {
      closeModal();
    });
    return () => {
      unlisten();
    };
  }, [closeModal]);

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
    >
      <>
        <ModalBodyWrapper>
          <div className={styles['item-row']}>
            <ModalComponentFreeMonth />
            <div className={styles['text']}>
              <p>
                Congrats! For hosting three sessions this month, you've been
                awarded a free month of ShareWell Unlimited.
              </p>
            </div>

            <div className={styles['buttons-container']}>
              <WellButtonLink
                type={'well-btn-new-primary'}
                btnText={'GO TO MEMBERSHIP'}
                className={styles['next-button']}
                to={'/membership'}
              />
            </div>
          </div>
        </ModalBodyWrapper>
      </>
    </ModalWrapper>
  );
};
