export const FacebookIcon = () => {
  return (
    <svg
      className=''
      width='25'
      height='25'
      viewBox='0 0 96 96'
    >
      <path
        d='M87.5,16.9642857 L87.5,79.0357143 C87.5,83.7087054 83.7087054,87.5 79.0357143,87.5 L63.9939732,87.5 L63.9939732,56.2526786 L74.6801339,56.2526786 L76.2142857,44.3321429 L63.9939732,44.3321429 L63.9939732,36.7142857 C63.9939732,33.2580357 64.9462054,30.9127232 69.9013393,30.9127232 L76.2142857,30.9127232 L76.2142857,20.2618304 C75.1209821,20.1207589 71.3825893,19.7857143 67.009375,19.7857143 C57.9102679,19.7857143 51.6678571,25.3404018 51.6678571,35.5504464 L51.6678571,44.3497768 L40.9464286,44.3497768 L40.9464286,56.2703125 L51.6854911,56.2703125 L51.6854911,87.5 L16.9642857,87.5 C12.2912946,87.5 8.5,83.7087054 8.5,79.0357143 L8.5,16.9642857 C8.5,12.2912946 12.2912946,8.5 16.9642857,8.5 L79.0357143,8.5 C83.7087054,8.5 87.5,12.2912946 87.5,16.9642857 Z'
        fill='#FFFFFF'
        fillRule='evenodd'
      ></path>
    </svg>
  );
};
