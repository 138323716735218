import { useState, Dispatch, SetStateAction } from 'react';
import { useGetImgixVersion } from '_hooks';
import { AnalyticsSendEvent } from '_helpers';

import styles from './VideoPlayer.module.css';
import { ModalWrapper } from '_components/Modals';

export const VideoPlayer = ({ isHomePage }: { isHomePage: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);

  const videoImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL +
      '/site/homepage/header-placeholder-2.png',
    {
      w: 600,
      h: 300,
    }
  );

  const playVideo = () => {
    AnalyticsSendEvent('marketing_video_play', {});
    setIsOpen(true);
  };

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['video-container']} onClick={playVideo}>
          {!isOpen && (
            <div>
              <img
                className={styles['graphic']}
                src={videoImage}
                alt={'ShareWell Video Preview'}
              />
              <div className={styles['play-icon-container-inpage']}>
                <div className={styles['play-icon']}></div>
              </div>
              <div className={styles['learn-more']}>
                See how peer support is transforming lives!
              </div>
            </div>
          )}
          {isOpen && (
            <CustomVideoPlayer
              src={'https://www.youtube.com/embed/gtnKY4vpk8U?autoplay=1'}
              isHomePage={isHomePage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const CustomVideoPlayer = ({
  src,
  isHomePage,
}: {
  src: string;
  isHomePage: boolean;
}) => {
  return (
    <iframe
      className={
        isHomePage ? styles['iframe-inpage-home'] : styles['iframe-inpage']
      }
      title={'ShareWell Video'}
      src={src}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

export const VideoModal = ({
  closeModal,
  src,
}: {
  closeModal: Dispatch<SetStateAction<boolean>>;
  src: string;
}) => {
  return (
    <ModalWrapper
      closeModal={() => closeModal(false)}
      isLoading={false}
      wrapperClassName={styles['modal-wrapper-class']}
    >
      <iframe
        className={styles['iframe']}
        title={'ShareWell Video'}
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </ModalWrapper>
  );
};
