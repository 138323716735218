import { WellInputWrapper } from '_components';
import { IGroup } from '_types';
import Select from 'react-select';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    // width: '100%',
    width: '300px',
  }),
};

export const GroupSelectInput = ({
  data,
  setValueChange,
  groups,
  error,
  errorRef,
}: any) => {
  let options = [];
  for (let x = 0; x < groups.length; x++) {
    // do not allow session creation in welcome well
    if (groups[x].uuid === process.env.REACT_APP_WELCOME_GROUP_UUID) continue;
    options.push({
      value: groups[x].uuid,
      label: groups[x].title,
    });
  }

  // is there a selected value?
  const selected = groups.filter(
    (group: IGroup) => group.uuid === data.group.value
  );

  // create the selected value
  let value: any = {};
  if (selected.length > 0)
    value = {
      label: selected[0].title,
      value: selected[0].uuid,
    };
  else
    value = {
      label: 'Select Well',
      value: null,
    };

  const handleSelectChange = (e: any) => {
    setValueChange(e.value, 'group');

    if (data.description.value === '')
      setValueChange(
        'This is a peer support session for ' +
          e.label +
          '. We will share our experience of ' +
          e.label +
          ' for mutual support and healing. All are welcome who have experience of this topic.',
        'description'
      );
  };

  return (
    <WellInputWrapper label={'Well'} error={error} errorRef={errorRef}>
      <Select
        options={options}
        onChange={handleSelectChange}
        value={value}
        defaultValue={() =>
          groups.filter((group: IGroup) => group.uuid === data.group.value)
        }
        menuPosition={'absolute'}
        menuPlacement={'auto'}
        styles={customStyles}
        menuPortalTarget={document.getElementById('root')}
        inputId={'series-repeat-input'}
        isSearchable={false}
        // menuIsOpen={true}
      />
    </WellInputWrapper>
  );
};
