import styles from './WellHeader.module.css';
import CSS from 'csstype';

interface IWellHeader {
  title?: string;
  subtitle?: string;
  subtitleTwo?: string;
  style?: CSS.Properties;
  children?: JSX.Element;
  headerClass?: string;
}

export const WellHeader = ({
  title,
  subtitle,
  subtitleTwo,
  style,
  children,
  headerClass,
}: IWellHeader) => {
  return (
    <section
      className={styles['header'] + ' ' + (headerClass ? headerClass : '')}
    >
      {title && (
        <div className={styles['header-row']}>
          <h1
            className={
              styles['title'] +
              ' ' +
              (!(subtitle || subtitleTwo) ? styles['center-title'] : '')
            }
          >
            {title}
          </h1>
          {subtitle && subtitleTwo ? (
            <h2 className={styles['subtitle']}>
              {subtitle}
              <br></br>
              {subtitleTwo}
            </h2>
          ) : subtitle ? (
            subtitle && <h2 className={styles['subtitle']}>{subtitle}</h2>
          ) : (
            <></>
          )}
          {children && children}
        </div>
      )}

      <svg
        className={styles['wave-svg-desktop']}
        viewBox="0 0 500 150"
        width={'100%'}
        height={'100'}
        preserveAspectRatio="none"
        style={style ? style : { position: 'absolute' }}
      >
        <path d="M0.00,49.98 C250.00,133.70 240.97,14.30 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"></path>
      </svg>
    </section>
  );
};
