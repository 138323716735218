import { useRef } from 'react';
import styles from './FAQ.module.css';
import { history } from '_helpers';
import { Link } from 'react-router-dom';

import { WellHeader } from '_components';

export const FAQ = () => {
  const dropdownOne = useRef(null);
  const dropdownTwo = useRef(null);
  const dropdownThree = useRef(null);
  const dropdownFour = useRef(null);
  const dropdownFive = useRef(null);
  const dropdownSix = useRef(null);
  const dropdownSeven = useRef(null);
  const dropdownEight = useRef(null);
  const dropdownTen = useRef(null);

  const openDropDown = (ref) => {
    if (ref.current.classList.contains(styles['open']))
      ref.current.classList.remove(styles['open']);
    else ref.current.classList.add(styles['open']);
  };

  const clickCodeOfConduct = () => {
    history.push('/safety-policy');
  };

  return (
    <div className={styles['faq']}>
      <WellHeader title={'FAQ'} />

      <div className={styles['faq-container'] + ' container'}>
        {/*What is Peer Support?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownOne)}
        >
          <h3 className={styles['dropdown-title']}>What is Peer Support?</h3>

          <div className={styles['dropdown']} ref={dropdownOne}>
            <div className={styles['faq-item']}>
              <h4>What is Peer Support?</h4>
              <p>
                Peer support is the process of giving and receiving
                non-clinical, mutual assistance to achieve long-term recovery.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>Is Peer Support the same as Group Therapy?</h4>
              <p>
                No, there are no mental health professionals leading a peer
                support group. Our peer support sessions are led by qualified
                peer support hosts or veteran ShareWell members.
              </p>
            </div>
          </div>
        </div>

        {/*What is Peer Support?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownEight)}
        >
          <h3 className={styles['dropdown-title']}>
            What is a Well community on ShareWell?
          </h3>

          <div className={styles['dropdown']} ref={dropdownEight}>
            <div className={styles['faq-item']}>
              <h4>What is a Well?</h4>
              <p>
                A Well is a community of peers on ShareWell. Wells are the
                heartbeat of ShareWell where people who share a common
                experience can convene to give or receive peer support.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>How do I join a Well?</h4>
              <p>
                You can join any Well by clicking into the Well and selecting
                the Join button. Wells are free to join but we require all Well
                members to have lived experience of the topic.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>What can I expect after I join a Well?</h4>
              <p>
                After you join a Well, you will have full access to the peer
                support resources within that Well. This includes the video
                support sessions as well as the Discussion board where you can
                easily post or answer questions or comments.
              </p>
            </div>
          </div>
        </div>

        {/*What can I expect in a Peer Support Session on ShareWell?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownTwo)}
        >
          <h3 className={styles['dropdown-title']}>
            What can I expect in a Peer Support Session on ShareWell?
          </h3>
          <div className={styles['dropdown']} ref={dropdownTwo}>
            <div className={styles['faq-item']}>
              <h4>How does it work?</h4>
              <p>
                All Peer Support Sessions on ShareWell are online and
                video-based. All participants in the meetings including the
                facilitator have shared lived experience of the session topic
              </p>
            </div>
            <div className={styles['faq-item']}>
              <h4>What is the format?</h4>
              <p>
                All peer support sessions on ShareWell are group online video
                meetings.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>Is video mandatory?</h4>
              <p>
                Video is mandatory for all ShareWell sessions for safety reasons
                and to further connection in the peer support group.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>Who are the hosts in a ShareWell session?</h4>
              <p>
                Hosts are community members who have decided to use their
                personal experience to support others. Many of our hosts come
                from mental health backgrounds but previous facilitating
                experience is not required. All of our hosts share the
                experience of the session topic and are your peers. Becoming a
                host on ShareWell is simple, learn more{' '}
                <Link to={'/host'}>here</Link>.
              </p>
            </div>
          </div>
        </div>

        {/*How can I host a peer support session on ShareWell?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownThree)}
        >
          <h3 className={styles['dropdown-title']}>
            How can I host a peer support session on ShareWell?
          </h3>
          <div className={styles['dropdown']} ref={dropdownThree}>
            <div className={styles['faq-item']}>
              <p>
                At ShareWell, we have a wide array of Peer Support Hosts from
                diverse backgrounds and experiences.
              </p>

              <h4>What is a Peer Support Host?</h4>
              <p>
                A Peer Support Host is a ShareWell community member who
                facilitates peer support sessions around a topic they have lived
                experience with. Hosts share their stories while actively
                encouraging and holding space for their peers to share. You do
                not have to be an expert or have previous facilitation
                experience to be a peer support host.
              </p>
            </div>
            <div className={styles['faq-item']}>
              <h4>How do I become a Host?</h4>
              <p>
                Anyone can become a host on ShareWell. The first step is to
                attend a session. To learn more about hosting, visit our{' '}
                <Link to={'/host'}>Host page</Link> or download the{' '}
                <a
                  href={
                    'https://blog.sharewellnow.com/how-to-become-a-peer-support-host-sharewells-hosting-handbook/'
                  }
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Community Host Handbook
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        {/*Is ShareWell free?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownFour)}
        >
          <h3 className={styles['dropdown-title']}>Is ShareWell free?</h3>
          <div className={styles['dropdown']} ref={dropdownFour}>
            <div className={styles['faq-item']}>
              <h4>Can I use ShareWell for free?</h4>
              <p>
                Yes, you can use ShareWell for free as a Basic Member. Basic
                Members can attend 1 peer support session per month and access
                our community forum and chat groups for free. You can also
                become a host and facilitate as many peer support sessions as
                you like.
              </p>
            </div>
            <div className={styles['faq-item']}>
              <h4>
                How do I attend more than 1 peer support session each month?
              </h4>
              <p>
                To attend unlimited peer support sessions each month, you can
                upgrade to our Unlimited Membership. Get more information about
                our Unlimited Membership{' '}
                <Link to={'/how-it-works#membership'}>here</Link>.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>How much does ShareWell cost?</h4>
              <p>
                ShareWell offers daily peer support sessions that are free of
                charge.
              </p>
            </div>
            <div className={styles['faq-item']}>
              <h4>How many sessions can I sign up for?</h4>
              <p>
                You're allowed to be registered for a maximum of 4 sessions at a
                time.
              </p>
            </div>
          </div>
        </div>

        {/*How does a ShareWell Membership work?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownTen)}
        >
          <h3 className={styles['dropdown-title']}>
            How does a ShareWell Membership work?
          </h3>
          <div className={styles['dropdown']} ref={dropdownTen}>
            <div className={styles['faq-item']}>
              <h4>
                What is the difference between a Basic and Unlimited membership?
              </h4>
              <p>
                A Basic Membership gives you access to 1 free peer support
                session each month, while an Unlimited Membership allows you to
                attend as many peer support sessions each month as you like.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>What is included in the ShareWell Basic Membership?</h4>
              <p>
                A Basic Membership allows you to attend 1 peer support session
                for free each month and host as many sessions as you like. You
                will also have full access to all of our community features such
                as the discussion board and chat rooms.
              </p>
              <p>
                A Basic Membership is a great option to use ShareWell for free
                if 1 session a month is all the support you need.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>What is included in the ShareWell Unlimited Membership?</h4>
              <p>
                An Unlimited Membership gives you full access to all of our
                sessions! As an Unlimited Member, you will be able to attend and
                host as many peer support sessions as you like. You will also
                have full access to all of our community features such as the
                discussion board and chat rooms.{' '}
              </p>
              <p>
                An Unlimited Membership is the best option to use ShareWell if
                you need more than 1 peer support session each month. We highly
                recommend our members unlock Unlimited access to get the most
                out of peer support.{' '}
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>What is your refund policy?</h4>
              <p>
                Unlimited Members on an Annual Plan may cancel their membership
                and request a full refund within the first 60 days by emailing{' '}
                <a href="mailto:support@sharewellnow.com">
                  support@sharewellnow.com
                </a>
                . Monthly and 3-Month memberships are non-refundable. If you
                encounter any issues or questions, email{' '}
                <a href="mailto:support@sharewellnow.com">
                  support@sharewellnow.com{' '}
                </a>
                for a fast resolution.
              </p>
            </div>
          </div>
        </div>

        {/*What about safety?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownFive)}
        >
          <h3 className={styles['dropdown-title']}>What about safety?</h3>
          <div className={styles['dropdown']} ref={dropdownFive}>
            <div className={styles['faq-item']}>
              <h4>How are the sessions kept safe?</h4>
              <p>
                Abuse of any kind is not tolerated in ShareWell sessions. In a
                regular session, a host can remove a participant from a session
                for violating the code of conduct. In addition, any member can
                report or restrict another member. Restricting a member prevents
                that person from being able to join future sessions with you. If
                a member repeatedly violates the Code of Conduct or threatens,
                abuses or makes any other member feel unsafe account
                de-activation or suspension may be required.
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>Who is allowed in a ShareWell session?</h4>
              <p>
                Anyone with lived experience of the session topic who abides by
                our{' '}
                <span className={styles['link']} onClick={clickCodeOfConduct}>
                  Safety Policy
                </span>
                . Only people with personal experience of the topic are allowed,
                no observers or allies.{' '}
              </p>
            </div>

            <div className={styles['faq-item']}>
              <h4>I need help beyond peer support, where can I go?</h4>
              <ul>
                <li>Emergency: 911</li>
                <li>National Domestic Violence Hotline: 1- 800-799-7233</li>
                <li>
                  National Suicide Prevention Lifeline: 1-800-273-TALK (8255)
                </li>
                <li>National Hopeline Network: 1-800-SUICIDE (800-784-2433)</li>
                <li>
                  Lifeline Crisis Chat (Online live messaging):
                  http://www.crisischat.org/
                </li>
                <li>Crisis Text Line: Text "START" TO 741-741</li>
                <li>Self-Harm Hotline: 1-800-DONT CUT (1-800-366-8288)</li>
                <li>Family Violence Helpline: 1-800-996-6228</li>
                <li>Planned Parenthood Hotline: 1-800-230-PLAN (7526) </li>
                <li>
                  American Association of Poison Control Centers: 1-800-222-1222
                </li>
                <li>
                  National Council on Alcoholism & Drug Dependency Hope Line:
                  1-800-622-2255
                </li>
                <li>
                  National Crisis Line - Anorexia and Bulimia: 1-800-233-4357
                </li>
                <li>GLBT Hotline: 1-888-843-4564</li>
                <li>TREVOR Crisis Hotline: 1-866-488-7386</li>
                <li>
                  TransLifeline: https://translifeline.org/, (877) 565-8860
                </li>
                <li>AIDS Crisis Line: 1-800-221-7044</li>
                <li>
                  Friendship Line for the Elderly: 1-800-971-0016, Text
                  Telephone 1-800-799-4889
                </li>
                <li>
                  Military Veterans Suicide Hotline: 1-800-273-8255 (press 1),
                  https://suicidepreventionlifeline.org
                </li>
                <li>
                  Veterans Crisis Line: https://www.veteranscrisisline.net
                </li>
                <li>
                  Suicide Prevention Wiki: http://suicideprevention.wikia.com
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*How do I allow ShareWell access to my camera for the session?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownSix)}
        >
          <h3 className={styles['dropdown-title']}>
            How do I allow ShareWell access to my camera for the session?
          </h3>
          <div className={styles['dropdown']} ref={dropdownSix}>
            <div className={styles['faq-item']}>
              <p>
                Depending on your browser, there are different steps to allowing
                camera access. Prior to following these directions, please make
                sure no other program is currently using your camera. Video
                software such as zoom can prevent ShareWell from gaining camera
                access.
              </p>
              <ul>
                <li>
                  <b>Chrome</b>
                  <ul>
                    <li>At the top right, click More {'>'} Settings.</li>
                    <li>Under "Privacy and security," click Site settings.</li>
                    <li>Click Camera or Microphone.</li>
                    <li>
                      Under "Restricted," select https://sharewell.com and
                      change the camera or microphone permission to "Allow."
                    </li>
                  </ul>
                </li>
                <li>
                  <b>FireFox</b>:
                  <ul>
                    <li>
                      In the Menu bar at the top of the screen, select Firefox
                      then Preferences.
                    </li>
                    <li>Click Privacy & Security from the left menu.</li>
                    <li>Scroll down to the Permissions section.</li>
                    <li>
                      Click the Settings… button for the Camera option. Firefox
                      displays the websites with saved Allow or Restrict
                      permission.
                    </li>
                    <li>Set status 'allow' for https://sharewell.com.</li>
                  </ul>
                </li>
                <li>
                  <b>Safari</b>:
                  <ul>
                    <li>
                      In the Menu bar at the top of the screen, select Safari
                      then Preferences.
                    </li>
                    <li>In the menu interface, select websites.</li>
                    <li>Scroll down to Camera permission</li>
                    <li>Set https://sharewell.com to allow</li>
                  </ul>
                </li>
                <li>
                  <b>Edge</b>:
                  <ul>
                    <li>
                      Select the lock icon located near the right side of your
                      search bar.
                    </li>
                    <li>
                      Select the drop-down menu next to camera and microphone.
                    </li>
                    <li>Change the camera and microphone access to Allow. </li>
                    <li>Refresh the browser tab (CTRL + R).</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*I have a different question, who can I contact?*/}
        <div
          className={styles['dropdown-container']}
          onClick={() => openDropDown(dropdownSeven)}
        >
          <h3 className={styles['dropdown-title']}>
            I have a different question, who can I contact?
          </h3>
          <div className={styles['dropdown']} ref={dropdownSeven}>
            <div className={styles['faq-item']}>
              <p>
                We'd love to hear from you, please email us at{' '}
                <a href="mailto:support@sharewellnow.com">
                  support@sharewellnow.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
