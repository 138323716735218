import { useCallback, useEffect, useState } from 'react';
import { userTestimonialActions } from '_actions';
import { componentActions } from '_actions';
import { ITestimonial } from '_types';
import { useWellDispatch } from '_hooks/useWellApp';

const PAGINATION = 10;

export const useTestimonials = (user: string, isSelf: boolean) => {
  const dispatch = useWellDispatch();

  const [isLoadingTestimonials, setIsLoadingTestimonials] = useState(true);
  const [testimonialsList, setTestimonialsList] = useState<ITestimonial[]>([]);
  const [testimonialsPagination, setTestimonialsPagination] = useState({
    curOffset: 0,
    isDone: false,
  });
  const [isShowingAll, setIsShowingAll] = useState(false);

  // starts to load the first set of testimonials
  useEffect(() => {
    const grabData = async () => {
      let response = await dispatch(
        userTestimonialActions.listTestimonials(
          user,
          PAGINATION,
          testimonialsPagination.curOffset,
          isSelf
        )
      );
      if (response && isSelf) setTestimonialsList(response); //should be ok
      else if (response && !isSelf) {
        //remove private items (possibly be every item)
        setTestimonialsList((prev: ITestimonial[]) => prev.concat(response));
      }
      setTestimonialsPagination({
        curOffset: testimonialsPagination.curOffset + PAGINATION,
        isDone: response.length < PAGINATION ? true : false,
      });

      if (response.length <= 3) setIsShowingAll(true);
    };

    grabData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // view the rest of the testimonials
  const viewAll = useCallback(async () => {
    if (!testimonialsPagination.isDone) {
      let response: any = await dispatch(
        userTestimonialActions.listTestimonials(
          user,
          999,
          testimonialsPagination.curOffset,
          isSelf
        )
      );
      if (response) {
        setTestimonialsList(testimonialsList.concat(response));
        setTestimonialsPagination({
          curOffset: 999,
          isDone: true,
        });
      }
    }

    setIsShowingAll(true); //no matter what show all
  }, [
    dispatch,
    isSelf,
    testimonialsList,
    testimonialsPagination.curOffset,
    testimonialsPagination.isDone,
    user,
  ]);

  // make the testimonial public for others to see
  const makePublic = useCallback(
    (uuid: string) => {
      return new Promise((resolve, reject) => {
        dispatch(
          componentActions.openModal({
            title: 'Make this review public?',
            body: 'This will allow other members to view this review on your profile.',
            modalBodyStyle: { textAlign: 'center' },
            noButtonStyle: { background: '#999999' },
            yesButtonStyle: {
              background:
                'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
            },
            isInput: false,
            yesText: 'PUBLISH',
            noText: 'CANCEL',
            afterText: 'This recommendation has been public!',
            yesClick: () => {
              dispatch(userTestimonialActions.makeTestimonialPublic(uuid));
              setTestimonialsList((prev: any) =>
                prev.map((item: any) => {
                  if (item.uuid === uuid) item.status = 'PUBLIC';
                  return item;
                })
              );
              return resolve(true);
            },
            noClick: () => {
              return resolve(false);
            },
          })
        );
      });
    },
    [dispatch]
  );

  /**
   * delete the testimonial
   * NOT USED
   */
  const deleteTestimonial = useCallback(
    (uuid: string) => {
      dispatch(
        componentActions.openModal({
          title: 'Delete this recommendation?',
          body: 'Are you sure you want to permanently delete this recommendation?',
          noButtonStyle: { background: '#999999' },
          yesButtonStyle: {
            background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
          },
          isInput: false,
          yesText: 'DELETE',
          noText: 'CANCEL',
          afterText: 'This recommendation has been deleted!',
          yesClick: async () => {
            const response = await dispatch(
              userTestimonialActions.deleteTestimonial(uuid)
            );
            setTestimonialsList((prev) =>
              prev.filter((item: ITestimonial) => item?.uuid !== uuid)
            );
          },
          noClick: () => {},
        })
      );
    },
    [dispatch]
  );

  return {
    testimonialsList,
    isLoadingTestimonials,
    testimonialsPagination,

    //functions
    makePublic,
    deleteTestimonial,
    viewAll,
    isShowingAll,
  };
};
