import styles from './GroupItemList.module.css';
import { useGetImgixVersion } from '_hooks';
import { Link } from 'react-router-dom';
import { IGroup } from '_types';

interface IGroupList {
  group: IGroup;
  onClick?: () => void;
}

export const GroupItemList = ({ group, onClick }: IGroupList) => {
  const image = useGetImgixVersion(group.pic, {
    h: 100,
    w: 100,
  });

  return (
    <Link
      to={
        '/well/' +
        group.title.toLowerCase().replaceAll(' ', '-') +
        '/' +
        group.uuid
      }
      className={styles['group-item-list']}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick();
            }
          : () => {}
      }
    >
      {group.pic ? (
        <img className={styles['image']} src={image} alt={group.title} />
      ) : (
        <div className={styles['place-holder'] + ' ' + styles['image']}></div>
      )}
      <div className={styles['content']}>
        <h4 className={styles['title']}>{group.title}</h4>
      </div>
    </Link>
  );
};
