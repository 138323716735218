import { useState, useEffect, useCallback } from 'react';
import { stripeActions } from '_actions';
import { ICardType } from '_types';
import { useWellDispatch } from '_hooks';

export const usePreviousCards = () => {
  const dispatch = useWellDispatch();
  const [prevCards, setPrevCards] = useState<ICardType[]>([]);
  const [isLoadingPreviousCards, setIsLoadingPreviousCards] = useState(true);

  const getPreviousCardsData = useCallback(async () => {
    setIsLoadingPreviousCards(true);
    const prevCards = await dispatch(stripeActions.getCards());
    if (prevCards !== false && prevCards.length > 0) setPrevCards(prevCards);
    setIsLoadingPreviousCards(false);
  }, [dispatch, setIsLoadingPreviousCards]);

  useEffect(() => {
    getPreviousCardsData();
  }, [getPreviousCardsData]);

  return {
    prevCards,
    getPreviousCardsData,
    isLoadingPreviousCards,
  };
};
