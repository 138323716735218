import { useEffect } from 'react';
import { stripeActions } from '_actions';
import { useWellDispatch, useWellSelector } from '_hooks';
import { ISubscriptionType } from '_types';

// gets the current subscription
export const useSubscriptionTypes = (): ISubscriptionType[] => {
  const { stripeStore } = useWellSelector((state) => state);
  const { subscriptionTypes } = stripeStore;
  const dispatch = useWellDispatch();

  // if we have not got the data, collect it
  useEffect(() => {
    if (!subscriptionTypes[0]?.id) {
      dispatch(stripeActions.listSubscriptionTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return subscriptionTypes;
};
