import { useDispatch } from 'react-redux';
import { useGroupInfo } from '_hooks';
import { componentActions } from '_actions';
import styles from './GroupInfoMenu.module.css';
import { useLocation } from 'react-router-dom';
import { GroupBadge } from '_components';

/**
 * Sets the correct tab for the group info page
 * TODO: find a better way to handle the url change
 * window.history.replaceState -> used to replace url w/o changing route
 */
export const GroupInfoMenu = () => {
  const dispatch = useDispatch();
  const { group, curTab, setCurTab, setShowingPost } = useGroupInfo();
  const location = useLocation();

  /**
   * Handles clearing the number when you leave the tab
   */
  const handleTabChange = () => {
    setShowingPost(false); //always no longer showing an individual post
    if (curTab === 0) group.badge_session = 0;
    else if (curTab === 1) group.badge_forum_post = 0;
    else if (curTab === 2) group.badge_user_join = 0;
    return Promise.resolve(1);
  };

  const showJoinNotice = (tab) => {
    dispatch(
      componentActions.openModal({
        title: 'Join Well to ' + tab,
      })
    );
  };

  const goToSessions = async () => {
    window.history.replaceState(
      null,
      '',
      location.pathname
        .replace('discussion', 'sessions')
        .replace('members', 'sessions')
        .replace('resources', 'sessions')
    );
    await handleTabChange();
    setCurTab(0);
  };

  const goToForum = async () => {
    window.history.replaceState(
      null,
      '',
      location.pathname
        .replace('sessions', 'discussion')
        .replace('members', 'discussion')
        .replace('resources', 'discussion')
    );
    await handleTabChange();
    setCurTab(1);
  };

  const goToMembers = async () => {
    window.history.replaceState(
      null,
      '',
      location.pathname
        .replace('sessions', 'members')
        .replace('discussion', 'members')
        .replace('resources', 'members')
    );
    await handleTabChange();
    setCurTab(2);
  };

  return (
    <div className={styles['menu'] + ' container'}>
      <div className={styles['menu-item']} onClick={goToSessions}>
        <h4
          className={
            styles['menu-item-text'] +
            ' ' +
            (curTab === 0 ? styles['active'] : '')
          }
        >
          Sessions
        </h4>
      </div>

      <div
        className={
          styles['menu-item'] +
          ' ' +
          (!group.am_i_joined ? styles['inactive'] : '')
        }
        onClick={
          !group.am_i_joined
            ? () => showJoinNotice('unlock discussion.')
            : goToForum
        }
      >
        <h4
          className={
            styles['menu-item-text'] +
            ' ' +
            (curTab === 1 ? styles['active'] : '')
          }
        >
          Discussion
        </h4>
      </div>

      {!group.is_default_group && (
        <div
          className={
            styles['menu-item'] +
            ' ' +
            styles['menu-item'] +
            ' ' +
            (!group.am_i_joined ? styles['inactive'] : '')
          }
          onClick={
            !group.am_i_joined
              ? () => showJoinNotice('view members.')
              : goToMembers
          }
        >
          <h4
            className={
              styles['menu-item-text'] +
              ' ' +
              (curTab === 2 ? styles['active'] : '')
            }
          >
            Members
          </h4>
        </div>
      )}
    </div>
  );
};
