import { useCallback } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import styles from './StripeCardsWithPrevious.module.css';
import { PrevCardItem, WellButtonRedux } from '_components';
import { useStripeCards } from '_hooks';

// this is styles for the stripe code
export const iframeStylesStripeCards = {
  base: {
    display: 'flex',
    maxWidth: '35rem',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '1.5rem 0.75rem',
    flexWrap: 'wrap',

    fontSize: '1rem',
    fontWeight: '400',
    color: '#4A4A52',
    backgroundColor: '#FFF',
    borderRadius: '0.75rem',
    border: '1px solid #F5F5F7',
  },
  invalid: {
    color: '#dc3545',
  },
  complete: {
    color: 'rgb(112, 181, 244)',
  },
};

// options for element
export const cardElementOpts = {
  iconStyle: 'solid',
  style: iframeStylesStripeCards,
  hidePostalCode: true,
};

// handles adding a credit card to a user
export const StripeCardsWithPrevious = ({
  callback,
  isLoadingCallback,
  children,
  btnText,
}: {
  callback: () => void;
  isLoadingCallback: boolean;
  children: JSX.Element;
  btnText: string;
}) => {
  const {
    isLoadingStripeCards,
    errors,
    handleAddCard,
    usingPrevCard,
    setUsingPrevCard,
    prevCards,
    handleCardNumberChange,
  } = useStripeCards(true);

  const handleSubmit = useCallback(async () => {
    if (!usingPrevCard) handleAddCard().then(() => callback());
    else callback();
  }, [callback, handleAddCard, usingPrevCard]);

  return (
    <>
      <div className={styles['card-info-container']}>
        {prevCards === false ? (
          <>
            <div className={styles['card-input-container']}>
              <div className={styles['container']}>
                <div className={styles['input-item']}>
                  <div
                    className={
                      styles['input-item-inside'] +
                      ' shimmer ' +
                      styles['loading']
                    }
                  ></div>
                </div>

                <div className={styles['input-item']}>
                  <div
                    className={
                      styles['input-item-inside'] +
                      ' shimmer ' +
                      styles['loading']
                    }
                  ></div>
                  <div
                    className={
                      styles['input-item-inside'] +
                      ' shimmer ' +
                      styles['loading']
                    }
                  ></div>
                </div>
              </div>
            </div>
          </>
        ) : usingPrevCard && prevCards && prevCards.length > 0 ? (
          <>
            <div className={styles['use-card']}>Use Card:</div>
            <PrevCardItem
              last4={prevCards[0].last4}
              expire={prevCards[0].expire}
            />

            <div
              className={styles['new-card']}
              onClick={() => setUsingPrevCard(false)}
            >
              <input
                type='radio'
                checked={false}
                className={styles['new-card-check']}
                readOnly={true}
              />
              <span className={styles['use-different-card']}>
                Use a different card
              </span>
            </div>
          </>
        ) : (
          <div className={styles['card-input-container']}>
            <div className={styles['container']}>
              {errors.general && (
                <div className={styles['error-container']}>
                  {errors.general}
                </div>
              )}

              <div className={styles['input-item']}>
                <div className={styles['input-item-inside']}>
                  <h5 className={styles['input-label']}>Card Number</h5>
                  <CardNumberElement
                    options={{ style: iframeStylesStripeCards }}
                    onChange={handleCardNumberChange}
                  />
                </div>
              </div>

              <div className={styles['input-item']}>
                <div className={styles['input-item-inside']}>
                  <h5 className={styles['input-label']}>Expire Date</h5>

                  <CardExpiryElement options={cardElementOpts} />
                </div>
                <div className={styles['input-item-inside']}>
                  <h5 className={styles['input-label']}>CVV</h5>

                  <CardCvcElement options={cardElementOpts} />
                </div>
              </div>
            </div>
            {prevCards.length > 0 && (
              <span
                onClick={() => setUsingPrevCard(true)}
                className={styles['use-prev-card']}
              >
                Use saved card
              </span>
            )}
          </div>
        )}
      </div>

      {children}

      <div className={styles['button-container']}>
        <WellButtonRedux
          type={'green'}
          onClick={handleSubmit}
          btnText={btnText}
          isLoading={isLoadingStripeCards || isLoadingCallback}
          className={styles['submit-button']}
          disabled={false}
        />
      </div>
    </>
  );
};
