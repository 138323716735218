import { useCallback, Dispatch, SetStateAction, useState } from 'react';
import styles from './ActivityItem.module.css';
import { ActivityFeedInput, RenderForumPost } from '_components';
import { groupForumActions } from '_actions';
import { WellButton, LikeList } from '_components';
import { useGetImgixVersion, useWellDispatch, useForum } from '_hooks';
import { IActivity, TUserMapping } from '_types';
import { userListToUuidUsernameMapping } from '_helpers';

interface IActivityItemContent {
  activity: IActivity;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

export const ActivityItemContent = ({
  activity,
  isEditing,
  setIsEditing,
}: IActivityItemContent) => {

  const { tagUsers, reloadActivity } = useForum();

  const dispatch = useWellDispatch();

  const [editContent, setEditContent] = useState(activity.content); // editing content
  const [isLoading, setIsLoading] = useState(false); // load on save
  const [taggedUsers, setTaggedUsers] = useState<TUserMapping>(userListToUuidUsernameMapping(activity.tagged_users));

  const activityImage = useGetImgixVersion(activity.pic, {
    h: 2000,
    w: 2000,
  });

  // edit the activity, tag users, and reload
  const editActivity = useCallback(
    async () => {
      if (isLoading) return;
      setIsLoading(true);

      await dispatch(groupForumActions.postEdit(activity.uuid, editContent));

      await tagUsers(activity as any, editContent, taggedUsers);
      await reloadActivity(activity.uuid, activity.is_pinned);
      setIsEditing(false);
      setIsLoading(false);
      return Promise.resolve(1);
    },
    [
      isLoading,
      dispatch,
      activity,
      editContent,
      tagUsers,
      taggedUsers,
      reloadActivity,
      setIsEditing
    ]
  );


  return (
    <div className={styles['post-content']}>
      {!isEditing ? (
        <>
          {/* TODO: Fix this so it gets the tagged users from state. */}
          <RenderForumPost
            text={activity.content}
            taggedUsers={activity.tagged_users}
            isReply={false}
          />
          {activity && activity.pic && (
            <div className={styles['image-container']}>
              <img
                src={activityImage}
                className={styles['activity-image']}
                alt={'Activity Post'}
              />
            </div>
          )}

          <div className={styles['likes-and-comments']}>
            <LikeList activity={activity} isReaction={false} />

            <h4 className={styles['number-comments']}>
              {activity.num_replies ? activity.num_replies : <>0</>}{' '}
              {activity.num_replies === 1 ? <>Comment</> : <>Comments</>}
            </h4>
          </div>
        </>
      ) : (
        <>
          <ActivityFeedInput
            value={editContent}
            setValue={setEditContent}
            taggedUsers={taggedUsers}
            setTaggedUsers={setTaggedUsers}
            hasInlineOptions={''}
            groupToSearch={activity.group_uuid}
          />

          <WellButton
            className={styles['cancel-btn']}
            btnOnClick={() => {
              setEditContent(activity.content);
              setIsEditing(false);
            }}
            btnText={'CANCEL'}
            isLoading={isLoading}
            type={'well-btn-new-secondary'}
          />
          <WellButton
            className={
              styles['save-btn'] +
              ' ' +
              (editContent.replace(/\s/g, '').length === 0 &&
                styles['disabled'])
            }
            btnOnClick={editActivity}
            btnText={'SAVE'}
            isLoading={isLoading}
            type={'well-btn-orange'}
          />
        </>
      )}
    </div>
  );
};
