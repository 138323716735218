import { useState, useCallback } from 'react';
import { IActivity } from '_types';
import { AnalyticsSendEvent } from '_helpers';
import { useForum, useWellDispatch } from '_hooks';
import { groupForumActions, componentActions } from '_actions';

export const useActivityItem = (activity: IActivity) => {
  const dispatch = useWellDispatch();

  const { removeActivity } = useForum();

  // is loading something?
  const [isLoading, setIsLoading] = useState(true);
  // allow editing of content
  const [isEditing, setIsEditing] = useState(false);

  // deletes the activity
  const deleteActivity = useCallback(async () => {
    dispatch(
      componentActions.openModal({
        title: 'Are you sure you want to delete this post?',
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        yesText: 'DELETE',
        noText: 'CANCEL',
        yesClick: async () => {
          await dispatch(groupForumActions.postDelete(activity.uuid));
          AnalyticsSendEvent('group_forum_activity_deleted', {
            activity: activity,
          });
          // remove the activity from the forum parent
          removeActivity(activity.uuid);
        },
        noClick: () => {},
      })
    );
  }, [activity, dispatch, removeActivity]);

  return {
    isLoading,
    setIsLoading,

    isEditing,
    setIsEditing,

    deleteActivity,
  };
};
