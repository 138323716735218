import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roomMessagingActions } from '_actions';
import { roomConstants } from '_constants';
import { RootState } from '_reducers';

export const useHostMessages = () => {
  const dispatch = useDispatch();

  const { roomStore } = useSelector((state: RootState) => state);
  // if I send same message twice then clear
  useEffect(() => {
    if (roomStore.sentHostSignal === roomStore.previousHostSignal)
      dispatch(roomMessagingActions.setSendHostSignal({}));
  }, [
    roomStore.sentHostSignal,
    roomStore.previousHostSignal,
    roomStore.receivedHostSignal,
    dispatch,
  ]);

  // when I get a message handle
  useEffect(() => {
    const signal = roomStore.receivedHostSignal;

    if (signal && signal.SHAREWELL_EVENT_BREAKOUT_ROOM_TIMER_END_TIME) {
      dispatch({
        type: roomConstants.SET_BREAKOUT_ROOM_TIMER,
        breakoutRoomTimer: signal.SHAREWELL_EVENT_BREAKOUT_ROOM_TIMER_END_TIME,
      });
    } else if (signal && signal.SHAREWELL_EVENT_BREAKOUT_ROOM_TIMER_STOP) {
      dispatch({
        type: roomConstants.SET_BREAKOUT_ROOM_TIMER,
        breakoutRoomTimer: false,
      });
    }
  }, [roomStore.receivedHostSignal, dispatch]);

  return {};
};
