import {
  useEffect,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  useSessionCreateOrEditData,
  useForm,
  useWellDispatch,
  useMyGroups,
  useWellSelector,
} from '_hooks';
import { useParams } from 'react-router-dom';
import {
  IGroup,
  ISession,
  ISessionCreateNoValidation,
  IFormItemTime,
  IFormItemString,
  EHostLevel,
  DispatchBoolean,
} from '_types';
import { sessionsActions } from '_actions';
import { SessionCreate, SessionEdit } from '.';
import { history } from '_helpers';
import { Moment } from 'moment';

interface ISessionCreateOrEditContext {
  myGroupList: IGroup[];
  isLoadingParent: boolean;
  data: {
    title: IFormItemString;
    description: IFormItemString;
    externalLink: IFormItemString;
    group: IFormItemString;
    startTime: IFormItemTime;
  };
  setData: Dispatch<
    SetStateAction<{
      title: IFormItemString;
      description: IFormItemString;
      externalLink: IFormItemString;
      group: IFormItemString;
      startTime: IFormItemTime;
    }>
  >;
  nonValidationData: ISessionCreateNoValidation;
  setNonValidationData: Dispatch<SetStateAction<ISessionCreateNoValidation>>;
  errors: any;
  validateAll: (skips?: String[]) => boolean;
  handleChange: (e: any) => void;
  inputRefs: any;
  setValueChange: (value: string | Moment, name: string) => void;
  editSession: ISession | undefined;
  copy: string | undefined;
  isExternal: boolean;
  setIsExternal: DispatchBoolean;
  originalStartTime: Moment | null;
}

export const SessionCreateOrEditContext =
  createContext<ISessionCreateOrEditContext>({} as ISessionCreateOrEditContext);
// export const SessionCreateOrEditContext = createContext<any>({});

export const SessionCreateOrEditProvider = () => {
  const [isLoadingParent, setIsLoadingParent] = useState(true);
  const { uuid, group, copy } = useParams<{
    group?: string;
    uuid?: string;
    copy?: string;
  }>();

  const dispatch = useWellDispatch();
  const { userStore } = useWellSelector((state) => state);

  const [editSession, setEditSession] = useState<ISession>(); // session for an edit

  // check if well exists & user can create in well
  const { myGroupList } = useMyGroups();

  // check if I need to grab a session for an edit
  useEffect(() => {
    const start = async () => {
      if (!uuid && !copy) {
        setIsLoadingParent(false);
        return;
      }

      let res;
      if (copy) res = await dispatch(sessionsActions.getSessionInfo(copy));
      else if (uuid) res = await dispatch(sessionsActions.getSessionInfo(uuid));

      // has to be a session, user is host or user is admin
      // can't be series
      if (res && res.uuid && (res.am_i_host || userStore.isAdmin)) {
        setEditSession(res);
        setIsLoadingParent(false);
      } else history.push('/');
    };
    start();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, uuid, copy]);

  // user can create session in well?
  useEffect(() => {
    if (!group || myGroupList.length === 0) return;

    let hasFound = false;
    myGroupList.map((groupItem: IGroup) => {
      if (groupItem.uuid === group) {
        hasFound = true;
        return false;
      }
    });

    // if the group is not found OR user is not admin trying Welcome Well
    if (
      !hasFound ||
      (group === process.env.REACT_APP_WELCOME_GROUP_UUID &&
        userStore.userData.host_level !== EHostLevel.HOST_LEVEL_2)
    )
      history.push('/');
  }, [group, myGroupList, userStore.isAdmin]);

  // if there is an edit session, we pass
  // else pass group if there was a group provided,
  // else pass undefined, user will need to choose the group
  const {
    data,
    setData,
    nonValidationData,
    setNonValidationData,
    originalStartTime,
    isExternal,
    setIsExternal,
  } = useSessionCreateOrEditData(
    editSession ? editSession : undefined,
    editSession ? editSession?.group_uuid : group ? group : undefined
  );

  // inputs w/ validation
  const { errors, validateAll, handleChange, inputRefs, setValueChange } =
    useForm(data, setData);

  return (
    <SessionCreateOrEditContext.Provider
      value={{
        myGroupList,
        isLoadingParent,
        data,
        setData,
        nonValidationData,
        setNonValidationData,
        errors,
        validateAll,
        handleChange,
        inputRefs,
        setValueChange,
        editSession,
        copy,
        originalStartTime,
        isExternal,
        setIsExternal,
      }}
    >
      {uuid ? <SessionEdit /> : <SessionCreate />}
    </SessionCreateOrEditContext.Provider>
  );
};
