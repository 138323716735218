import styles from './Testimonials.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTestimonials, useGetImgixVersion } from '_hooks';
import { ITestimonial } from '_types';
import { useCallback } from 'react';
import moment from 'moment';
import { RootState } from '_reducers';

export const Testimonials = ({
  uuid,
  isSelf,
  isSessionInfo,
}: {
  uuid: string;
  isSelf: boolean;
  isSessionInfo: boolean;
}) => {
  const { loggedIn } = useSelector((state: RootState) => state.userStore);

  const { testimonialsList, makePublic, viewAll, isShowingAll } =
    useTestimonials(uuid, isSelf);

  if (testimonialsList.length === 0) return <></>;

  // if not showing all only show first 3 of what we have
  return (
    <>
      {!isSessionInfo && (
        <div className={styles['top-container']}>
          <h4 className={styles['section-title']}>Reviews</h4>
        </div>
      )}
      <div className={styles['container']}>
        {!isShowingAll ? (
          <>
            {testimonialsList.slice(0, 3).map((testimonial: ITestimonial) => (
              <TestimonialItem
                testimonial={testimonial}
                isSelf={isSelf}
                makePublic={makePublic}
                key={'profile-testimonial-' + testimonial.uuid}
                loggedIn={loggedIn}
              />
            ))}
          </>
        ) : (
          <>
            {testimonialsList.map((testimonial: ITestimonial) => (
              <TestimonialItem
                testimonial={testimonial}
                isSelf={isSelf}
                makePublic={makePublic}
                key={'profile-testimonial-' + testimonial.uuid}
                loggedIn={loggedIn}
              />
            ))}
          </>
        )}

        {!isShowingAll && !isSessionInfo && (
          <span className={styles['view-all']} onClick={viewAll}>
            VIEW ALL
          </span>
        )}
      </div>
    </>
  );
};

interface ITestimonialItem {
  testimonial: ITestimonial;
  isSelf: boolean;
  makePublic: (uuid: string) => Promise<unknown>;
  loggedIn: boolean;
}

const TestimonialItem = ({
  testimonial,
  isSelf,
  makePublic,
  loggedIn,
}: ITestimonialItem) => {
  const { from_user, value, status, uuid } = testimonial;

  const userImage = useGetImgixVersion(
    from_user.pic_url ? from_user.pic_url : '',
    { h: 200, w: 200, fit: 'crop' }
  );

  const handleMakePublicClick = useCallback(() => {
    makePublic(uuid);
  }, [makePublic, uuid]);

  return (
    <div className={styles['item']}>
      <div className={styles['item-inner']}>
        <div className={styles['left']}>
          <Link
            to={'/profile/' + from_user.username}
            className={
              styles['pic-container'] +
              ' ' +
              (!loggedIn ? styles['disabled'] : '')
            }
          >
            <img
              src={userImage}
              className={styles['pic']}
              alt={from_user.username}
            />
          </Link>
          <div className={styles['left-info']}>
            <Link
              to={'/profile/' + from_user.username}
              className={
                styles['user'] + ' ' + (!loggedIn ? styles['disabled'] : '')
              }
            >
              {from_user.username}
            </Link>
            <h4 className={styles['session']}>{value?.session_title}</h4>
            <div className={styles['date']}>
              {moment(value.session_start_time).format('MMM D, YYYY')}
            </div>

            {isSelf && status === 'PRIVATE' && (
              <div className={styles['make-public-container']}>
                <div
                  className={styles['make-public']}
                  onClick={handleMakePublicClick}
                >
                  <span className={styles['make-public-text']}>
                    Make Public
                  </span>
                </div>
              </div>
            )}

            <div className={styles['thumb-container']}>
              {testimonial.thumbs ? (
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/session/thumbs-up.svg'
                  }
                  className={styles['thumbs-up']}
                  alt={'Thumbs up'}
                />
              ) : (
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/session/thumbs-down.svg'
                  }
                  className={styles['thumbs-down']}
                  alt={'Thumbs down'}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles['right']}>{value.review}</div>
      </div>
    </div>
  );
};
