import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Attachment,
  Avatar,
  ReactionSelector,
  MessageText,
  MessageTimestamp,
  MessageUIComponentProps,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
  useEditHandler,
  ReactEventHandler,
  ReactionsList,
} from 'stream-chat-react';

import {
  ActionsEllipse,
  CustomMessageActions,
  ReactionSmiley,
  CustomGallery,
} from '_components';
import { useClickOutside, useGetImgixVersion } from '_hooks';
import { StreamChatGenerics } from '_types';

import styles from './CustomMessage.module.css';

export const CustomMessage: React.FC<
  MessageUIComponentProps<StreamChatGenerics>
> = () => {
  //Contexts
  const { isMyMessage, message } = useMessageContext();
  const { editing, setEdit } = useEditHandler();

  //State
  const [showOptions, setShowOptions] = useState<boolean>(false); //on hover show ellipse * emojis
  const [actionsDropdownOpen, setActionsDropdownOpen] =
    useState<boolean>(false); //actions dropdown
  const [showReactionSelector, setShowReactionSelector] =
    useState<boolean>(false);

  const myMessage = isMyMessage(); //if my message

  const pic = useGetImgixVersion(message.user?.image, {
    h: 200,
    w: 200,
  });

  if (message.deleted_at || message.type === 'deleted') {
    return (
      <div
        className={
          styles['outside-container'] +
          ' ' +
          (myMessage ? styles['my-message'] : '')
        }
      >
        <div className={styles['message-row']}>
          <div className={styles['message'] + ' ' + styles['deleted-message']}>
            <div
              className={
                styles['message-text-outer'] + ' ' + styles['deleted-outer']
              }
            >
              <div
                className={
                  styles['message-text-inner'] + ' ' + styles['deleted-inner']
                }
              >
                This message was deleted.
              </div>
            </div>
          </div>
        </div>
        <MessageTimestamp customClass={styles['message-timestamp']} />
      </div>
    );
  }

  return (
    <>
      {editing && <></>}

      <div
        className={
          styles['outside-container'] +
          ' ' +
          (myMessage ? styles['my-message'] : '') +
          ' ' +
          (!message.text || message.text.length < 10
            ? styles['short-text']
            : '')
        }
        onMouseEnter={() => setShowOptions(true)}
        onMouseLeave={() => {
          //close everything
          setActionsDropdownOpen(false);
          setShowOptions(false);
          setShowReactionSelector(false);
        }}
      >
        <div className={styles['message-row']}>
          {message.attachments?.length ? (
            <div className={styles['attachment-container']}>
              <Attachment
                attachments={message.attachments}
                Gallery={CustomGallery}
              />
            </div>
          ) : null}

          <div className={styles['message-content']}>
            {!myMessage && (
              <>
                <Avatar
                  size={36}
                  image={pic}
                  name={message.user?.name || message.user?.id}
                  shape={'circle'}
                />
              </>
            )}
            <div className={styles['message']}>
              {!myMessage && (
                <Link
                  to={'/profile/' + message.user?.name}
                  className={styles['message-username']}
                >
                  {message.user?.name}
                </Link>
              )}

              <MessageText
                customWrapperClass={styles['message-text-outer']}
                customInnerClass={styles['message-text-inner']}
              />
            </div>

            {showOptions && (
              <MessageOptions
                actionsDropdownOpen={actionsDropdownOpen}
                setActionsDropdownOpen={setActionsDropdownOpen}
                // setMessageActionUser={setMessageActionUser}
                setShowReactionSelector={setShowReactionSelector}
                setEdit={setEdit}
                showReactionSelector={showReactionSelector}
              />
            )}
          </div>
        </div>

        <div className={styles['bottom-items']}>
          <div className={styles['submitted-reactions']}>
            <ReactionsList />
          </div>

          <MessageTimestamp customClass={styles['message-timestamp']} />
        </div>
      </div>
    </>
  );
};

type OptionsProps = {
  actionsDropdownOpen: boolean;
  setActionsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // setMessageActionUser: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowReactionSelector: React.Dispatch<React.SetStateAction<boolean>>;
  showReactionSelector: boolean;
  setEdit: ReactEventHandler;
};

const MessageOptions: React.FC<OptionsProps> = (props) => {
  const {
    actionsDropdownOpen,
    setActionsDropdownOpen,
    setEdit,
    // setMessageActionUser,
    setShowReactionSelector,
    showReactionSelector,
  } = props;

  const { thread } = useChannelStateContext();
  const { handleOpenThread, isMyMessage, message, reactionSelectorRef } =
    useMessageContext();

  //Refs
  const reactionsSelectorRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(reactionsSelectorRef, () => setShowReactionSelector(false)); //if outside close

  return (
    <div className={styles['message-options-container']}>
      <span
        className={styles['open-reaction-btn']}
        onClick={() => setShowReactionSelector((prev) => !prev)}
      >
        {showReactionSelector && (
          <span
            className={styles['reaction-container']}
            ref={reactionsSelectorRef}
          >
            <ReactionSelector ref={reactionSelectorRef} />
          </span>
        )}

        <ReactionSmiley />
      </span>
      <span
        className={styles['inside-ellipse']}
        onClick={() => setActionsDropdownOpen(!actionsDropdownOpen)}
      >
        <ActionsEllipse />
      </span>
      {actionsDropdownOpen && (
        <CustomMessageActions
          openThread={handleOpenThread}
          setActionsDropdownOpen={setActionsDropdownOpen}
          // setMessageActionUser={setMessageActionUser}
          thread={!thread}
          user={message.user}
          setEdit={setEdit}
        />
      )}
    </div>
  );
};
