import { roomReportingService } from '_services';

export const roomReportingActions = {
  reportSession,
  kickUser,
  reportUser,
  blockUser,
};

function reportSession(session: string, reason: string) {
  return async () => {
    try {
      await roomReportingService.reportSession(session, reason);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function kickUser(session: string, user: string, reason: String) {
  return async () => {
    try {
      await roomReportingService.kickUser(session, user, reason);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function reportUser(user: string, reason: string, session: string) {
  return async () => {
    try {
      await roomReportingService.reportUser(user, reason, session);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function blockUser(user: string, isBlock = 'true', note: string) {
  return async () => {
    try {
      await roomReportingService.blockUser(user, isBlock, note);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
