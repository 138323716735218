import { useEffect } from 'react';
import styles from './Login.module.css';
import WellLogo from '_images/nav-logo.svg';
import { useWellDispatch } from '_hooks';
import { CookiesEnabledModal, LoginForm } from '_components';
import { componentsConstants } from '_constants';

export const Login = ({ autoLoadModal }: { autoLoadModal: boolean }) => {
  const dispatch = useWellDispatch();

  // if we auto open the modal, then show it
  useEffect(() => {
    if (autoLoadModal)
      dispatch({
        type: componentsConstants.TOGGLE_NEW_MODAL,
        isRegisterModalOpen: {
          isOpen: true,
          redirect: '',
        },
      });
  }, [autoLoadModal, dispatch]);

  return (
    <>
      <CookiesEnabledModal />
      <div className={styles['page'] + ' ' + styles['is-login']}>
        <div className={styles['container']}>
          <div className={styles['page-row']}>
            <div className={styles['logo-container']}>
              <img
                src={WellLogo}
                className={styles['page-logo']}
                alt={'ShareWell Logo'}
              />
              <p>Find support, healing and connection through peer support</p>
            </div>

            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};
