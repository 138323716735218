import { VideoPlayer, GreenCheck } from '_components';
import styles from './AlternatingColumns.module.css';

export const AlternatingColumnsNew = ({ title }: { title: string }) => {
  return (
    <section className={styles['alternating-columns-new']}>
      <div className={'container'}>
        <h3 className={styles['section-title']}>{title}</h3>
        <p className={styles['section-text']}>
          Peer support is for anyone seeking validation, understanding, or
          simply looking for a safe group of people to share their story. It’s
          an opportunity to talk about your experiences, share skills and coping
          methods, express emotions and build relationships with people who
          understand you. Our members say their support groups feel like
          an&nbsp;understanding&nbsp;family.
        </p>
        <div
          className={
            styles['alternating-columns'] +
            ' ' +
            styles['alternating-columns-one-one']
          }
        >
          <div className={'col-lg-6'}>
            <p className={styles['alternating-columns-text']}>
              <GreenCheck
                height={'50px'}
                width={'50px'}
                style={{}}
                className={styles['green-check']}
              />
              Everyone shares the same lived experience in the group, including
              the facilitator.
            </p>
            <p className={styles['alternating-columns-text']}>
              <GreenCheck
                height={'50px'}
                width={'50px'}
                style={{}}
                className={styles['green-check']}
              />
              The goal is an emotional connection in support of healing and
              growth.
            </p>
            <p className={styles['alternating-columns-text']}>
              <GreenCheck
                height={'50px'}
                width={'50px'}
                style={{}}
                className={styles['green-check']}
              />
              A place to share, listen and support. We offer a wide variety of
              topics and a safe space for connection.
            </p>
          </div>

          <div className={'col-lg-6 ' + styles['right-img']}>
            <VideoPlayer isHomePage={false} />
          </div>
        </div>
      </div>
    </section>
  );
};
