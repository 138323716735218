import { adminUserActions } from '_actions';
import { adminConstants } from '_constants';
import { adminService } from '_services';

export const adminSessionsActions = {
  getSessionDataAdmin,
  updateSessionsView,
};

// named different so doesn't show up in searches
function getSessionDataAdmin(uuid, title, forView = true) {
  return async (dispatch) => {
    try {
      const response = await adminService.getSessionDataAdmin(uuid);

      let data = response.session.users;

      if (forView) {
        for (let i = 0; i < data.length; i++) {
          data[i].userData = await dispatch(
            adminUserActions.getUserData(data[i].user_uuid)
          );
        }
        dispatch({
          type: adminConstants.GET_SINGLE_SESSION_DATA,
          data: data,
          title: title,
        });
      }

      return Promise.resolve(data);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function updateSessionsView(isShowing) {
  return async (dispatch) => {
    dispatch({
      type: adminConstants.UPDATE_SESSIONS_VIEW,
      isShowing: isShowing,
    });
  };
}
