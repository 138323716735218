import { WellLoader } from '_components';
import styles from './WellButtonRedux.module.css';

interface IWellButton {
  isLoading: boolean;
  className?: string;
  onClick: () => void;
  btnText: string;
  disabled: boolean;
  type: 'white' | 'green';
}

export const WellButtonRedux = ({
  isLoading,
  className,
  onClick,
  type,
  btnText,
  disabled,
}: IWellButton) => {
  if (isLoading)
    return (
      <div
        className={styles['button'] + ' ' + styles[type] + ' ' + className}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <span className={styles['inner-btn'] + ' ' + styles['hidden']}></span>
        <WellLoader />
      </div>
    );

  return (
    <div
      className={
        styles['button'] +
        ' ' +
        className +
        ' ' +
        (disabled ? styles['disabled'] : '') +
        ' ' +
        styles[type]
      }
      onClick={onClick}
    >
      <span className={styles['inner-btn']}>{btnText}</span>
    </div>
  );
};
