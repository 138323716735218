
export const GoogleIcon = () => {
  return (
    <svg
      className=''
      width='25'
      height='25'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.6401 16.2993C28.6401 15.3656 28.5563 14.4679 28.4007 13.6061H16.0001V18.6992H23.0861C22.7809 20.345 21.8532 21.7395 20.4588 22.6731V25.9768H24.714C27.2037 23.6846 28.6401 20.3091 28.6401 16.2993Z'
        fill='#4285F4'
      ></path>
      <path
        d='M16.0001 29.1667C19.5551 29.1667 22.5356 27.9877 24.714 25.9768L20.4588 22.6731C19.2798 23.4631 17.7716 23.9299 16.0001 23.9299C12.5708 23.9299 9.66813 21.6138 8.63275 18.5017H4.23389V21.913C6.4004 26.2161 10.8531 29.1667 16.0001 29.1667Z'
        fill='#34A853'
      ></path>
      <path
        d='M8.63269 18.5017C8.36936 17.7117 8.21974 16.8678 8.21974 16C8.21974 15.1322 8.36936 14.2883 8.63269 13.4983V10.087H4.23383C3.34209 11.8645 2.83337 13.8754 2.83337 16C2.83337 18.1246 3.34209 20.1355 4.23383 21.913L8.63269 18.5017Z'
        fill='#FBBC05'
      ></path>
      <path
        d='M16.0001 8.07009C17.9332 8.07009 19.6688 8.7344 21.0334 10.0391L24.8098 6.26266C22.5296 4.13804 19.5491 2.83334 16.0001 2.83334C10.8531 2.83334 6.4004 5.78387 4.23389 10.087L8.63275 13.4983C9.66813 10.3862 12.5708 8.07009 16.0001 8.07009Z'
        fill='#EA4335'
      ></path>
    </svg>
  );
};
