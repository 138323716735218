import { ReactNode, createContext, useEffect } from 'react';
import {
  useHostMessages,
  useSessionRoomPoll,
  useSessionRoomManager,
} from '_hooks';

export interface ISessionRoomContext {}

export const SessionRoomContext = createContext<ISessionRoomContext>(
  {} as ISessionRoomContext
);

interface IRoomParent {
  children: ReactNode;
}

// this used to be the state for the room, have since moved to only reducer
export function SessionRoomProvider({ children }: IRoomParent) {

  useSessionRoomManager(); // room state
  useSessionRoomPoll(); // polling
  useHostMessages(); // host messages
  // breakout rooms is now handled in the component & by reducer

  return (
    <SessionRoomContext.Provider value={{}}>
      {children}
    </SessionRoomContext.Provider>
  );
}
