import { useState, useRef, useEffect } from 'react';
import { generateRandomId } from '_helpers';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ReactTinyLink } from 'react-tiny-link';
import { LinkifyRedux } from './LinkifyRedux';
import wellLinkify from './WellLinkifyIt';

export const RenderForumPost = ({ text, taggedUsers, isReply }) => {
  const heightToUse = isReply ? 46 : 100;
  const useStyles = makeStyles(() =>
    createStyles({
      outside: {
        maxHeight: 'auto',
      },
      hidden: {
        maxHeight: heightToUse + 'px',
        overflow: 'hidden',
        whiteSpace: 'break-spaces',
        // whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      inside: {
        fontSize: '15px',
      },
      showMoreBtn: {
        position: 'relative',
        paddingTop: '10px',
        fontSize: '12px',
        color: '#58A3E7',
        fontWeight: '500',
        cursor: 'pointer',
        display: 'inline-block',
      },
      dots: {
        position: 'relative',
      },
      linkPreview: {
        position: 'relative',
        marginTop: '16px',
        '& a': {
          width: '100%',
          maxWidth: 'none',
        },
        '& div:first-child': {
          backgroundSize: 'contain',
        },
        '& .react_tinylink_card_content_header p': {
          fontSize: '16px',
          color: '#151519 !important',
          fontWeight: '500',
        },
        '& .react_tinylink_card_content p': {
          fontSize: '16px',
          color: '#4a4a53 !important',
        },
        '& .react_tinylink_footer p': {
          fontSize: '14px',
          color: '#58A3E7 !important',
        },
      },
    })
  );

  const [isShowingMore, setIsShowingMore] = useState(false);
  const [height, setHeight] = useState(0);

  const contentRef = useRef();
  const classes = useStyles();

  const [firstPreview, setFirstPreview] = useState('');

  useEffect(() => {
    setHeight(contentRef.current.offsetHeight);
  }, [contentRef]);

  useEffect(() => {
    const matches = wellLinkify.match(text);
    if (!matches) {
      setFirstPreview('');
      return;
    }

    matches.some(function (match) {
      if (match.schema === 'https:') setFirstPreview(match.url);
      return match.schema === 'https:';
    });

  }, [text]);

  return (
    <>
      <div
        className={
          classes.outside + ' ' + (!isShowingMore ? classes.hidden : '')
        }
        key={'render-forum-post-container-' + generateRandomId()}
      >
        <div className={classes.inside} ref={contentRef}>
          <LinkifyRedux>{text}</LinkifyRedux>
        </div>
      </div>
      {!isShowingMore && contentRef.current && height > heightToUse && (
        <div
          className={classes.showMoreBtn}
          onClick={() => setIsShowingMore(true)}
        >
          SHOW MORE
        </div>
      )}

      {firstPreview && (
        <div className={classes.linkPreview}>
          <ReactTinyLink
            showGraphic={true}
            maxLine={2}
            minLine={1}
            url={firstPreview}
            proxyUrl={'https://well-proxy.herokuapp.com'}
            cardSize="large"
          />
        </div>
      )}
    </>
  );
};
