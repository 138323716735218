import { useEffect, useState } from 'react';
import {
  useGetSeriesInfoBatch,
  useWellDispatch,
  useGetImgixVersion,
  useWellChat,
  useWellSelector,
} from '_hooks';
import styles from './WellWelcome.module.css';
import { authConstants } from '_constants';
import {
  WellButtonLink,
  WellLoader,
  VideoModal,
  SessionItem,
} from '_components';

const devSeries = [
  'cdaca0a1-e3ed-4459-baf2-7a11d49d3e92', // cdaca0a1-e3ed-4459-baf2-7a11d49d3e92
  '5ede551a-ceb7-492a-9485-a4f3f0afc157', // 5ede551a-ceb7-492a-9485-a4f3f0afc157
  '8dd3ac40-d111-4270-9df0-827bb3704b22', // 8dd3ac40-d111-4270-9df0-827bb3704b22hj
  '8dd3ac40-d111-4270-9df0-827bb3704b22', // 8dd3ac40-d111-4270-9df0-827bb3704b22hj
  '8dd3ac40-d111-4270-9df0-827bb3704b22', // 8dd3ac40-d111-4270-9df0-827bb3704b22hj
];

const liveSeries = [
  'a68ae61a-38e1-48ca-ba0b-218138c8fa3c',
  '06e331ca-b896-4d92-88d1-914671ba2b2b',
  '9932addb-32bc-46b8-8c4d-717d0933a4fd',
  'ef07a423-2e05-4ab8-8b30-045d241e57a5',
  'ae70b708-2473-4a6c-b9c3-aaaffb37f743',
];

export const WellWelcome = () => {
  const dispatch = useWellDispatch();

  const { setIsOpen } = useWellChat();

  const { userStore } = useWellSelector((state) => state);

  const [isOpenModal, setIsOpenModal] = useState(true);

  const { sessions, reload } = useGetSeriesInfoBatch(
    process.env.REACT_APP_NODE_ENV === 'live' ? liveSeries : devSeries,
  );

  let imageWelcome = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/modal/welcome-1.svg',
    {
      h: 225,
      w: 825,
    },
  );

  // when leaving set so user can not go to onboarding
  useEffect(() => {
    return () => {
      dispatch({
        type: authConstants.SET_CAN_GO_TO_ONBOARDING,
        canGoToOnboarding: false,
      });
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles['main']}>
        <div className={styles['wave-image-container']}>
          <svg
            className={styles['wave-svg']}
            viewBox='0 0 500 150'
            width={'100%'}
            height={'100'}
            preserveAspectRatio='none'
          >
            <path d='M0.00,49.98 C250.00,133.70 240.97,14.30 500.00,49.98 L500.00,150.00 L0.00,150.00 Z'></path>
          </svg>
        </div>
        <div className={styles['container']}>
          <div className={styles['top-image-container']}>
            <img
              src={imageWelcome}
              alt={'Welcome'}
              className={styles['welcome-image']}
            />
          </div>

          <div className={styles['middle-text']}>
            <h1>Start with a New Members&nbsp;Session</h1>
            <p>
              Your peer support community is waiting for you. We recommend
              starting with a welcome session.
            </p>

            {userStore.userData.subscription_info.is_unlimited_subscribed && (
              <p>
                Have a question? Message us directly in the Welcome chat for the
                next day.
              </p>
            )}
          </div>

          <div className={styles['sessions']}>
            {sessions === false ? (
              <div className={styles['error']}>
                We're sorry these sessions are not available right&nbsp;now.{' '}
              </div>
            ) : sessions.length === 0 ? (
              <div className={styles['loader-container']}>
                <WellLoader heightWidth={100} />
              </div>
            ) : (
              <div className={styles['sessions-row']}>
                {sessions.map((session) => (
                  <SessionItem
                    session={session}
                    allowRegister={true}
                    reloadSession={reload}
                    key={'session-' + session.uuid}
                    isSquare={false}
                  />
                ))}
              </div>
            )}
          </div>

          <div className={styles['stat']}>
            <div className={styles['blue-stat']}>
              90% of our members feel happier immediately after a ShareWell peer
              support&nbsp;session.
            </div>
            <div>Your happiness boost is just one click&nbsp;away!</div>
            <div className={styles['bottom']}>
              Eager to explore the site? Head on over to see all of our
              offerings&nbsp;now.{' '}
            </div>
          </div>

          <div className={styles['btn-container']}>
            <WellButtonLink
              type={'well-btn-orange'}
              to={'/sessions'}
              btnText={'VIEW ALL SESSIONS'}
            />
          </div>
        </div>
      </div>

      {isOpenModal && (
        <VideoModal
          closeModal={(_) => setIsOpenModal(false)}
          src={'https://www.youtube.com/embed/y5NVVEjIAFw?autoplay=1&mute=1'}
        />
      )}
    </>
  );
};
