import { useSessionInfo } from '_hooks'; // can decouple in future
import { WellMeatBallsRedux } from '_components';
import { getServerTime, history, getSessionStartTime } from '_helpers';
import styles from './SessionOptionsDropdown.module.css';

interface ISessionOptions {
  className: string;
}

export const SessionOptionsDropdown = ({ className }: ISessionOptions) => {

  const {
    sessionInfo,
    deleteSession,
    reportSession,
    deleteSeries,
    sessionPermissions,
    handleUnregisterForSession,
    updateSessionInfo,
  } = useSessionInfo();

  const handleEdit = () => {
    history.push('/session/' + sessionInfo.uuid + '/edit');
  };

  const handleEditSeries = () => {
    history.push('/series/' + sessionInfo.series.uuid + '/edit');
  };

  const handleCopy = async () => {
    history.push('/session-create/copy/' + sessionInfo.uuid);
  };

  const viewSeriesPage = () => {
    history.push('/series/' + sessionInfo.series.uuid);
  };

  const handleDeleteSeries = () => {
    deleteSeries(updateSessionInfo); // need to reload after deleting series
  }

  return (

    <div className={className}>
      <WellMeatBallsRedux
        className={styles['options-container']}
        text={sessionInfo.am_i_host ? 'Edit' : 'Options'}
        dropDownKey={sessionInfo.uuid + '-options-dropdown'}
        dropDownItems={[
          sessionPermissions.canSeeUnregisterBtn
            ? {
              text: 'Unregister',
              onClick: handleUnregisterForSession,
              disable: false,
            }
            : {},
          sessionPermissions.canUseHostOptions &&
            getSessionStartTime(sessionInfo) > getServerTime() &&
            sessionInfo.status !== 'Over' &&
            (!sessionInfo.series || sessionInfo.series.status !== 'ACTIVE')
            ? {
              text: 'Edit Session',
              onClick: handleEdit,
              disable: false,
            }
            : {},
          // only allow series edit if host
          sessionPermissions.canUseHostOptions &&
            sessionInfo.series &&
            sessionInfo.series.status !== 'DELETED' &&
            getSessionStartTime(sessionInfo) > getServerTime()
            ? {
              text: 'Edit Series',
              onClick: handleEditSeries,
              disable: false,
            }
            : {},
          sessionPermissions.canUseHostOptions
            ? {
              text: 'Copy Session',
              onClick: handleCopy,
              disable: false,
            }
            : {},
          sessionPermissions.canUseHostOptions &&
            getSessionStartTime(sessionInfo) > getServerTime() &&
            sessionInfo.status !== 'Over'
            ? {
              text: 'Delete Session',
              onClick: deleteSession,
              disable: false,
            }
            : {},
          sessionPermissions.canUseHostOptions &&
            sessionInfo.series &&
            sessionInfo.series.status !== 'DELETED'
            ? {
              text: 'End Series',
              onClick: handleDeleteSeries,
              disable: false,
            }
            : {},
          sessionPermissions.canUseHostOptions &&
            sessionInfo.series &&
            sessionInfo.series.status !== 'DELETED'
            ? {
              text: 'View Series Page',
              onClick: viewSeriesPage,
              disable: false,
            }
            : {},
          {
            text: 'Report Session',
            onClick: reportSession,
            disable: false,
          },
        ]}
      />
    </div>
  )

}
