import { useCallback } from 'react';
import { FileUploadButton } from 'react-file-utils';

import {
  ChatAutoComplete,
  EmojiPicker,
  UploadsPreview,
  useMessageInputContext,
} from 'stream-chat-react';

import { EmojiPickerIcon, AddAttachment } from '_components';
import styles from './CustomChannelInput.module.css';

export const CustomChannelInput = () => {
  const { emojiPickerRef, handleChange, handleSubmit, openEmojiPicker, text } =
    useMessageInputContext();

  const submitMessageToChannel = useCallback(
    (e?) => {
      handleSubmit(e);
    },
    [handleSubmit],
  );

  return (
    <div className={styles['main-container']}>
      <UploadsPreview />

      <div className={styles['bottom']}>
        <div className={styles['file-upload-container']}>
          <FileUploadButton
            handleFiles={useMessageInputContext().uploadNewFiles} // eslint-disable-line
            multiple={false}
          >
            <AddAttachment />
          </FileUploadButton>
        </div>

        <div className={styles['input-container']}>
          <ChatAutoComplete onChange={handleChange} value={text} rows={1} />
          <EmojiPicker />
          <div
            className='input-ui-input-emoji-picker'
            ref={emojiPickerRef}
            onClick={openEmojiPicker}
          >
            <EmojiPickerIcon />
          </div>
        </div>

        <div
          onClick={submitMessageToChannel}
          className={styles['submit-svg-container']}
        >
          <svg
            viewBox='0 0 24 24'
            aria-hidden='true'
            className={styles['submit-svg']}
          >
            <g>
              <path
                d='M21.13 11.358L3.614 2.108c-.29-.152-.64-.102-.873.126-.23.226-.293.577-.15.868l4.362 8.92-4.362 8.92c-.143.292-.08.643.15.868.145.14.333.212.523.212.12 0 .24-.028.35-.087l17.517-9.25c.245-.13.4-.386.4-.664s-.155-.532-.4-.662zM4.948 4.51l12.804 6.762H8.255l-3.307-6.76zm3.307 8.26h9.498L4.948 19.535l3.307-6.763z'
                fill={'rgb(29, 155, 240)'}
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
