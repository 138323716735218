import { useEffect, useState } from 'react';
import { getServerTime, isEmpty, getSessionStartTime } from '_helpers';
import { ISession } from '_types';
import { useWellSelector } from '_hooks';

// check if user is allowed to perform certain actions
export const useSessionPermissions = (session: ISession) => {
  const { userStore } = useWellSelector((state) => state);
  const { isAdmin } = userStore;

  const [sessionPermissions, setSessionPermissions] = useState({
    canEnter: false,
    canSeeEnterBtn: false,
    canSeeRegisterBtn: false,
    canSeeUnregisterBtn: false,
    canSeeLoginBtn: false,
    canUseHostOptions: false,
    canSeeCalenderBtn: false,
  });

  useEffect(() => {
    if (isEmpty(session)) return;

    // everything false to start
    const newCheck = {
      canEnter: false,
      canSeeEnterBtn: false,
      canSeeRegisterBtn: false,
      canSeeUnregisterBtn: false,
      canSeeLoginBtn: false,
      canUseHostOptions: false,
      canSeeCalenderBtn: false,
    };

    // canEnter
    if (
      userStore.loggedIn &&
      (session.am_i_joined || isAdmin) &&
      session.status !== 'Over' &&
      getServerTime() > getSessionStartTime(session).subtract(5, 'minutes') &&
      session.status !== 'Canceled'
    ) {
      newCheck.canEnter = true;
    }

    // canSeeEnterBtn
    if (
      userStore.loggedIn &&
      (session.am_i_joined || isAdmin) &&
      session.status !== 'Over' &&
      session.status !== 'Canceled'
    )
      newCheck.canSeeEnterBtn = true;

    // canSeeRegisterBtn
    if (
      userStore.loggedIn &&
      !session.am_i_joined &&
      !session.am_i_host &&
      getSessionStartTime(session).isAfter(getServerTime()) &&
      (session.num_users_joined < session.max_attendees || session.external_url)
    )
      newCheck.canSeeRegisterBtn = true;

    // canSeeUnregisterBtn 
    if (
      userStore.loggedIn &&
      session.am_i_joined &&
      !session.am_i_host &&
      getSessionStartTime(session)
        .clone()
        .subtract(5, 'm')
        .isAfter(getServerTime())
    )
      newCheck.canSeeUnregisterBtn = true;

    // canSeeLoginBtn
    if (!userStore.loggedIn) newCheck.canSeeLoginBtn = true;

    // canUseHostOptions
    if (session.am_i_host || isAdmin)
      newCheck.canUseHostOptions = true;

    // canSeeCalenderBtn
    if (getServerTime() < getSessionStartTime(session).subtract(5, 'minutes'))
      newCheck.canSeeCalenderBtn = true;

    setSessionPermissions(newCheck);
  }, [session, userStore.isAdmin, userStore.loggedIn]);

  return sessionPermissions;
};
