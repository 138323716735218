import { useState, useRef } from 'react';
import { RoomReportModal } from '_components';
import styles from './RoomMeatBalls.module.css';
import { useClickOutside } from '_hooks';

export const PublisherMeatBalls = ({ setDropDownOpen }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const dropDownRef = useRef(null);

  useClickOutside(dropDownRef, () => setDropDownOpen(false), modalIsOpen);

  return (
    <div ref={dropDownRef}>
      {modalIsOpen && (
        <RoomReportModal closeModal={setModalIsOpen} isUser={false} />
      )}

      <div className={styles['dropdown']}>
        <div
          className={styles['dropdown-item']}
          onClick={() => setModalIsOpen(true)}
        >
          <h4 className={styles['dropdown-text']}>Report Session</h4>
        </div>
      </div>
    </div>
  );
};
