export const roomConstants = {
  // entering session room
  ENTER_SESSION_ROOM_SUCCESS: 'ROOM_ENTER_SESSION_ROOM_SUCCESS',

  // setting the sessioninfo
  SET_SESSION_INFO: 'ROOM_SET_SESSION_INFO',

  // clear session room data
  CLEAR_SESSION_ROOM_DATA: 'ROOM_CLEAR_SESSION_ROOM_DATA',

  // waiting & polling
  UPDATE_ROOM_DATA: 'ROOM_UPDATE_ROOM_DATA',

  // new messaging
  SET_SENT_HOST_SIGNAL: 'ROOM_SET_SENT_HOST_SIGNAL',
  SET_RECEIVED_HOST_SIGNAL: 'ROOM_SET_RECEIVED_HOST_SIGNAL',

  // room timer
  SHOW_ROOM_TIMER: 'ROOM_SHOW_ROOM_TIMER',
  HIDE_ROOM_TIMER: 'ROOM_HIDE_ROOM_TIMER',

  CLEAR_TOKEN_INFO: 'ROOM_CLEAR_TOKEN_INFO',

  SET_POST_SESSION_CHECKS: 'ROOM_SET_POST_SESSION_CHECKS',

  SET_HAS_COMPLETED_VIDEO_TEST: 'ROOM_SET_HAS_COMPLETED_VIDEO_TEST',
  SET_USER_STATUS: 'ROOM_SET_USER_STATUS',
  SET_LEAVE_STATE: 'ROOM_SET_LEAVE_STATE',
  SET_BREAKOUT_ROOM_TIMER: 'ROOM_SET_BREAKOUT_ROOM_TIMER',
  SET_BREAKOUT_ROOM_MODAL: 'ROOM_SET_BREAKOUT_ROOM_MODAL',
};
