import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import styles from './OpenChatButton.module.css';
import { GroupBadge } from '_components';
import { useWellChat } from '_hooks';
import { history } from '_helpers';

const noShowChat = [
  '/room',
  '/onboarding',
  '/onboarding/membership',
  '/membership/checkout',

  '/room/',
  '/onboarding/',
  '/onboarding/membership/details',
  '/onboarding/membership/',
  '/membership/checkout/',
];

interface OpenChatButtonProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const OpenChatButton: React.FC<OpenChatButtonProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { chatClient, initUnreadCount, channelList } = useWellChat();
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    setUnreadCount(initUnreadCount);
  }, [initUnreadCount]);

  //watch for unread increases
  useEffect(() => {
    let listener: any;

    if (chatClient) {
      listener = chatClient.on((event: any) => {
        if (event.total_unread_count !== undefined) {
          setUnreadCount(event.total_unread_count);
        }
      });
    }

    return () => {
      if (chatClient) listener.unsubscribe();
    };
  }, [chatClient]);

  return (
    <div
      className={
        styles['open-chat-btn'] +
        ' ' +
        (isOpen ? styles['display-none'] : '') +
        ' ' +
        (noShowChat.includes(history.location.pathname)
          ? styles['in-room']
          : '') +
        ' ' +
        (channelList.channels.length === 0 ? styles['no-channels'] : '')
      }
      onClick={() => setIsOpen(true)}
    >
      {unreadCount > 0 && (
        <div className={styles['unread-count']}>
          <GroupBadge number={unreadCount} isLarge={false} />
        </div>
      )}
      <h4 className={styles['messages-note']}>Chats</h4>
      <svg viewBox='0 0 24 24' className={styles['svg']} aria-hidden='true'>
        <g>
          <path d='M12 19.344l-8.72-8.72c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l9.25 9.25c.292.294.767.294 1.06 0l9.25-9.25c.146-.145.22-.337.22-.53s-.073-.383-.22-.53c-.293-.292-.768-.292-1.06 0L12 19.344z'></path>
          <path d='M12 11.535l-8.72-8.72c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l9.25 9.25c.292.294.767.294 1.06 0l9.25-9.25c.146-.145.22-.337.22-.53s-.073-.383-.22-.53c-.293-.292-.768-.292-1.06 0L12 11.535z'></path>
        </g>
      </svg>
    </div>
  );
};
