import { authConstants } from '_constants';
import { AnyAction } from 'redux';

const defaultState = {
  canGoToOnboarding: false,
  autoJoinGroups: {},
  isLoggingIn: false,
  loginRedirect: '',
};

export function authStore(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case authConstants.SET_ON_LOGIN_REDIRECT:
      return {
        ...state,
        loginRedirect: action.loginRedirect,
      };

    case authConstants.SET_CAN_GO_TO_ONBOARDING:
      return {
        ...state,
        canGoToOnboarding: action.canGoToOnboarding,
      };

    case authConstants.SET_IS_LOGGING_IN:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
      };

    default:
      return state;
  }
}
