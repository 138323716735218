import moment from 'moment-timezone';
import store from '_reducers';
import { ISession } from '_types';

export function getUTC() {
  return getServerTime().utc();
}

export function getNowStringFormat() {
  const now = getServerTime().utc();
  const string =
    now.format('yyyy-MM-DD') +
    'T' +
    now.format('HH') +
    '%3A' +
    now.format('mm') +
    '%3A' +
    now.format('ss') +
    '-00';
  return string;
}

// this should take a moment and make it into the string;
export function getFutureStringFormat() {
  return '2099-06-06T15%3A04%3A17-07';
}

export function getPastStringFormat() {
  return '2019-06-06T15%3A04%3A17-07';
}

export function getServerTime() {
  if(!store)
    return moment();

  const { generalStore } = store.getState();
  return moment().subtract(generalStore.server_time_offset, 'milliseconds');
}


export function getSessionStartTime(session: ISession) {
  return moment.tz(session.start_time, session.timezone).local();
}

export function getSessionEndTime(session: ISession) {
  return moment.tz(session.end_time, session.timezone).local();
}

// gets a local time from a time & timezone
export function getLocalTime(time: string, timezone: string) {
  return moment.tz(time, timezone).local();
}

export function getLocalTimeEstimateTimezone(time: string) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.tz(time, timezone).local();
}

export function estimateTimeZone(){
  return Intl.DateTimeFormat().resolvedOptions().timeZone
  ? Intl.DateTimeFormat().resolvedOptions().timeZone
  : null;
}
