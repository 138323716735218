import styles from './CookieAcceptBanner.module.css';
import { useCookies } from 'react-cookie';
import { WellButton } from '_components';

export const CookieAcceptBanner = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const okClick = () => {
    setCookie('WELL_COOKIE_ACCEPT', 'TRUE', {
      path: '/',
      maxAge: new Date().setFullYear(new Date().getFullYear() + 1),
    });
  };

  const noClick = () => {
    setCookie('WELL_COOKIE_DECLINE', 'TRUE', {
      path: '/',
      maxAge: new Date().setFullYear(new Date().getFullYear() + 1),
    });
  };

  if (cookies.WELL_COOKIE_ACCEPT || cookies.WELL_COOKIE_DECLINE) return <></>;

  return (
    <div className={styles['container']}>
      <div className={styles['text']}>
        We use cookies to give you the best experience on our website. If you
        continue to use the site, we’ll assume that’s okay.
      </div>
      <div className={styles['buttons-container']}>
        <WellButton
          btnOnClick={okClick}
          type={'well-btn-orange'}
          btnText={'Ok'}
          className={styles['btn']}
          disabled={false}
          isLoading={false}
        />
        <WellButton
          btnOnClick={noClick}
          type={'well-btn-orange'}
          btnText={'No'}
          className={styles['btn']}
          disabled={false}
          isLoading={false}
        />
      </div>
    </div>
  );
};
