import { useEffect, useState, useContext } from 'react';
import { ISession } from '_types';
import { regexItems, errorMsgs } from '_constants';
import moment, { Moment } from 'moment-timezone';
import {
  ISessionCreateNoValidation,
  IFormItemString,
  IFormItemTime,
} from '_types';
import { getServerTime, getSessionStartTime } from '_helpers';
import { SessionCreateOrEditContext } from 'Sessions';
import { useWellSelector } from '_hooks/useWellApp';

export const useSessionCreateOrEdit = () => {
  const context = useContext(SessionCreateOrEditContext);
  if (context === undefined) {
    throw new Error(
      'useSessionCreateOrEdit must be used within a SessionCreateOrEditContext'
    );
  }
  return context;
};

// set up the data for creation or edit
export const useSessionCreateOrEditData = (
  session: ISession | undefined,
  group: string | undefined
) => {
  const { isAdmin } = useWellSelector((state) => state.userStore);

  const [originalStartTime, setOriginalStartTime] = useState<null | Moment>(
    null
  );

const [isExternal, setIsExternal] = useState(false);

  // requires validation
  const [data, setData] = useState({
    // part 1
    title: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.SESSION_TITLE,
      errorMsg: errorMsgs.SESSION_TITLE_INVALID,
    } as IFormItemString,
    description: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.SESSION_DESCRIPTION,
      errorMsg: errorMsgs.SESSION_DESCRIPTION_INVALID,
    } as IFormItemString,
    externalLink: {
      __typename: 'IFormItemString',
      value: '',
      validation: regexItems.EXTERNAL_URL,
      errorMsg: errorMsgs.INVALID_EXTERNAL_URL,
    } as IFormItemString,
    group: {
      __typename: 'IFormItemString',
      value: group ? group : '',
      validation: regexItems.MORE_THAN_1_CHARACTERS,
      errorMsg: errorMsgs.WELL_IS_REQUIRED,
      flag: group ? true : false,
    } as IFormItemString,
    startTime: {
      __typename: 'IFormItemTime',
      value: moment().add(25, 'hour').startOf('hour'),
      validationAfter: getServerTime().add(-1, 'day'),
      validationBefore: getServerTime().add('61', 'days').startOf('day'),
      errorMsg: errorMsgs.INVALID_START_TIME,
    } as IFormItemTime,
  });

  // validates through limiting input
  const [nonValidationData, setNonValidationData] =
    useState<ISessionCreateNoValidation>({
      duration: 60,
      maxAttendees: 16,
      repeat: 'SINGLE',
      image: null,
      originalImage: null,
      seriesEndTime: null,
    });

  useEffect(() => {
    // if no session then we do nothing
    if (!session) return;

    // need to set the data for edit or copy
    setData({
      title: {
        __typename: 'IFormItemString',
        value: session.title,
        validation: regexItems.SESSION_TITLE,
        errorMsg: errorMsgs.SESSION_TITLE_INVALID,
      } as IFormItemString,
      description: {
        __typename: 'IFormItemString',
        value: session.description,
        validation: regexItems.SESSION_DESCRIPTION,
        errorMsg: errorMsgs.SESSION_DESCRIPTION_INVALID,
      } as IFormItemString,
      group: {
        __typename: 'IFormItemString',
        value: session.group_uuid,
        validation: regexItems.MORE_THAN_1_CHARACTERS,
        errorMsg: errorMsgs.WELL_IS_REQUIRED,
        flag: true,
      } as IFormItemString,
      externalLink: {
        __typename: 'IFormItemString',
        value: session.external_url,
      validation: regexItems.EXTERNAL_URL,
      errorMsg: errorMsgs.INVALID_EXTERNAL_URL,
      } as IFormItemString,
      startTime: {
        __typename: 'IFormItemTime',
        value: getSessionStartTime(session).isBefore(
          getServerTime().add('61', 'days').startOf('day')
        )
          ? getSessionStartTime(session)
          : getServerTime().add(1, 'hour').startOf('hour'),

        validationAfter: getServerTime().add(-1, 'day'),
        validationBefore: getServerTime().add('61', 'days').startOf('day'),
        validation: regexItems.MORE_THAN_1_CHARACTERS,
        errorMsg: errorMsgs.INVALID_START_TIME,
      } as IFormItemTime,
    });

    // non-validation items
    setNonValidationData({
      duration: session.duration_minutes,
      maxAttendees: session.max_attendees,
      repeat: session?.series?.repeat ? session.series.repeat : 'SINGLE',
      image: null,
      originalImage: session.pic,
      seriesEndTime: session?.series?.end_datetime
        ? session?.series?.end_datetime
        : null,
    });

    setOriginalStartTime(getSessionStartTime(session));

    // if already an external link set it
    if(session.external_url)
      setIsExternal(true);
      
  }, [isAdmin, session]);

  return {
    data,
    setData,
    nonValidationData,
    setNonValidationData,
    originalStartTime,
    isExternal,
    setIsExternal,
  };
};
