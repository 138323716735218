import { FortuneLogo, MSNBCLogo, WallStreetJournalLogo } from '_components';
import styles from './AsSeenIn.module.css';

export const AsSeenIn = () => {
  return (
    <>
      <div className={styles['container']}>
        <h1 className={styles['heading']}>As Seen In </h1>

        <div className={styles['items-container']}>
          <div className={styles['item'] + ' ' + styles['fortune']}>
            <a
              target='_blank'
              href='https://fortune.com/2021/10/21/sharewell-cece-cheng-peer-support-groups/'
            >
              <FortuneLogo />
            </a>
          </div>
          <div className={styles['item'] + ' ' + styles['wsj']}>
            <a
              target='_blank'
              href='https://www.wsj.com/articles/with-therapists-in-short-supply-college-students-counsel-each-other-b9cb5eb2'
            >
              <WallStreetJournalLogo />
            </a>
          </div>

          <div className={styles['item'] + ' ' + styles['msnbc']}>
            <a
              target='_blank'
              href='https://www.msnbc.com/msnbc/amp/ncna1305204'
            >
              <MSNBCLogo />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
