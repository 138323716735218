import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { groupForumActions } from '_actions';

import { IActivity } from '_types';

export const usePinnedActivities = () => {
  const dispatch = useDispatch();

  const [isLoadingPinnedActivities, setIsLoadingPinnedActivities] =
    useState<boolean>(true);
  const [pinnedActivities, setPinnedActivities] = useState<IActivity[]>([]);

  useEffect(() => {
    const start = async () => {
      const pinnedActivityResponse: any = await dispatch(
        groupForumActions.getPinnedPosts()
      );

      if (pinnedActivityResponse) setPinnedActivities(pinnedActivityResponse);
      setIsLoadingPinnedActivities(false);
    };

    start();
  }, [dispatch]);

  return {
    isLoadingPinnedActivities,
    pinnedActivities,
  };
};
