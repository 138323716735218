import { useEffect } from 'react';
import { useDispatch,  } from 'react-redux';
import { authActions } from '_actions';
import { history } from '_helpers';

export const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const logout = async () => {
      await dispatch(authActions.handleLogout());
      history.push('/login');
    };
    logout();
  }, [dispatch]);

  return null;
};
