import { useCallback, useEffect } from 'react';
import {
  ModalBodyWrapper,
  ModalWrapper,
  ModalButtonWrapper,
  WellButtonLink,
} from '_components';
import styles from './BasicRegisterCtaModal.module.css';
import { useWellDispatch, useWellSelector } from '_hooks';
import { ReturnNotice } from '_types';
import { history } from '_helpers';
import { componentsConstants } from '_constants';

export const BasicRegisterCtaModal = () => {
  const dispatch = useWellDispatch();
  const { isBasicRegisterCtaModalOpen } = useWellSelector(
    (state) => state.componentsStore.newModal
  );

  const closeModal = useCallback(() => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isBasicRegisterCtaModalOpen: false,
    });
  }, [dispatch]);

  useEffect(() => {
    // monitor changes in history, to close modal
    const unlisten = history.listen(() => {
      closeModal();
    });
    return () => {
      unlisten();
    };
  }, [closeModal]);

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
    >
      <>
        <ModalBodyWrapper>
          <>
            <div className={styles['image-container']}>
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  '/site/modal/standard-error.svg'
                }
                className={styles['image']}
                alt={'ShareWell error'}
              />
            </div>

            <h2 className={styles['title']}>
              {isBasicRegisterCtaModalOpen ===
              ReturnNotice.SESSION_BASIC_PLAN_MAX_REACHED ? (
                <>
                  ShareWell Basic members can only register for 1 session at a
                  time. Upgrade to Unlimited Membership to register for another
                  session!
                </>
              ) : (
                <>
                  ShareWell Basic members can only attend 1 session a
                  month. Upgrade to Unlimited Membership to register for another
                  session!
                </>
              )}
            </h2>
          </>
        </ModalBodyWrapper>
        <ModalButtonWrapper>
          <WellButtonLink
            type={'well-btn-primary'}
            btnText={'UPGRADE TO UNLIMITED'}
            className={styles['subscribe-button']}
            to={'/membership/checkout'}
          />
        </ModalButtonWrapper>
      </>
    </ModalWrapper>
  );
};
