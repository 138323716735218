interface IDoubleCheck {
  fill: string;
}

export const DoubleCheck = ({ fill }: IDoubleCheck) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='31'
      viewBox='0 0 32 31'
      fill='none'
    >
      <path
        d='M15.6093 17.236L17.0025 18.6292L25.3558 10.2759L26.751 11.6711L17.0025 21.4196L10.7232 15.1403L12.1184 13.7451L14.2151 15.8418L15.6093 17.235V17.236ZM15.6113 14.4457L20.4974 9.55859L21.8886 10.9498L17.0025 15.8369L15.6113 14.4457ZM12.8219 20.0254L11.4277 21.4196L5.14844 15.1403L6.54362 13.7451L7.93781 15.1393L7.93682 15.1403L12.8219 20.0254Z'
        fill={fill}
      />
    </svg>
  );
};
