// HTTP return codes:
// 400: submitted request is not valid JSON or unexpected form parse error
// 401: user is not logged in
// 500: server or client logic error
// 200: OK, can additionally return a "notice" of the following format:
////////// /////////////////// ////////////
// 501 : server is coompletly down.

import { history, sendSentryError } from '_helpers';
import { toastr } from 'react-redux-toastr';
import { notificationConstants } from '_constants';

//A helper function for when a server returns
export const handleFetchReturn = async (response, isLoginTest = false) => {
  if (response.ok) {
    let formattedData = await response.json();
    if (formattedData.notice)
      if (
        formattedData.notice.name &&
        formattedData.notice.name === 'SITE_IS_DOWN_FOR_MAINTENANCE'
      ) {
        history.push('/server-down');
        return Promise.reject();
      }
    return Promise.resolve(formattedData);
  }

  return Promise.reject(response, isLoginTest);
};


//A helper function for when a server returns
export async function failedToFetch(response, isLoginTest) {
  let status = response.status;

  if (status !== 401) sendSentryError(status);

  //first check for 401
  if (status === 401 && !isLoginTest) {
    window.location.reload(true);
  } else if (response === 'TypeError: Failed to fetch' && !isLoginTest) {
    toastr.error(notificationConstants.CONNECTION_ERROR);
    return Promise.reject();
  }
  //then check for everything else
  else if (!isLoginTest) {
    // toastr.error(notificationConstants.SERVER_ERROR);
    return Promise.reject();
  }
}
