import { useState, useEffect, useCallback } from 'react';

import { useWellDispatch, useWellChat, useWellSelector } from '_hooks';
import { getServerTime, getSessionStartTime, history } from '_helpers';
import styles from './RoomFooter.module.css';
import { RoomModal, GroupBadge } from '_components';
import DeviceSelectionDialog from 'SessionRoom/SessionRoomTwilio/components/DeviceSelectionDialog';

import moment from 'moment-timezone';

import AgendaIcon from '_images/agenda-icon.svg';
import AudioOffIcon from '_images/audio-off-icon.svg';
import AudioOnIcon from '_images/audio-on-icon.svg';
import RulesIcon from '_images/rules-icon.svg';
import BreakoutRoomIcon from '_images/rooms-icon.svg';
import ChatIcon from '_images/chat-icon.svg';
import DeviceChangeIcon from '_images/options-icon.svg';
import ExitIcon from '_images/exit-icon.svg';
import { roomConstants, timeConstants } from '_constants';

const enum EModalTypes {
  RULES,
  AGENDA,
}

interface IRoomFooter {
  publisherAudio: boolean;
  setPublisherAudio: () => void;
}

export const RoomFooter = ({
  publisherAudio,
  setPublisherAudio,
}: IRoomFooter) => {
  const dispatch = useWellDispatch();

  const { userStore, roomStore } = useWellSelector((state) => state);
  const { sessionInfo, hostIsActive } = roomStore;
  const { userData } = userStore;

  const [audioIcon, setAudioIcon] = useState(AudioOnIcon);
  const [modalIsOpen, setModalIsOpen] = useState<EModalTypes | false>(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  const { chatClient, openToChannel, isOpen } = useWellChat();
  const [unreadCount, setUnreadCount] = useState(0);

  // if we after the 5 minute mark dont worry
  const [hasReshownAgenda, setHasReshownAgenda] = useState(
    getServerTime().isBefore(
      getSessionStartTime(sessionInfo).clone().add(5, 'm'),
    )
      ? false
      : true,
  );

  const isHost = sessionInfo.am_i_host || userStore.isAdmin;

  // we want to check if host is here, on first render of footer
  useEffect(() => {
    if (
      (!sessionInfo.am_i_host && !hostIsActive) ||
      sessionInfo.is_group_hosted
    )
      openAgenda();
  }, []);

  // if after 5 minutes the host is still not here, then reshow the agenda with the new text
  useEffect(() => {
    // if we have already shown the model then don't worry
    if (hasReshownAgenda) return;

    // set time out till time to 5 minute marks
    const timeToFiveMinuteMark = getSessionStartTime(sessionInfo)
      .clone()
      .add(5, 'm')
      .diff(getServerTime());

    // if already passed the 5 minute mark then we good
    if (timeToFiveMinuteMark < 0) return;

    // else if the host is still not here at the 5 minute mark reshow the agenda
    const timeout = setTimeout(() => {
      if (!hostIsActive) {
        setHasReshownAgenda(true);
        openAgenda();
      }
    }, timeToFiveMinuteMark);

    return () => clearTimeout(timeout);
  }, [hostIsActive, sessionInfo, hasReshownAgenda]);

  // handle increase, decrease of chat icons
  useEffect(() => {
    let channel: any;
    let listenerOne: any;
    let listenerTwo: any;
    const watchChat = async () => {
      try {
        channel = await chatClient.channel(
          process.env.REACT_APP_GET_STREAM_CHANNEL,
          sessionInfo.uuid,
          {},
        );
        await channel.watch();

        setUnreadCount(channel.state.unreadCount);

        listenerOne = channel.on('message.new', (event: any) => {
          // handle watch started event
          setUnreadCount(event.unread_count);
        });

        listenerTwo = channel.on('message.read', (event: any) => {
          if (event.user.id === userData.uuid) setUnreadCount(0);
        });
      } catch (error) {}
    };

    watchChat();

    return () => {
      if (listenerOne) listenerOne.unsubscribe();
      if (listenerTwo) listenerTwo.unsubscribe();
    };
  }, [chatClient, sessionInfo.uuid, userData.uuid]);

  useEffect(() => {
    if (publisherAudio) setAudioIcon(AudioOnIcon);
    else setAudioIcon(AudioOffIcon);
  }, [publisherAudio]);

  const leaveRoom = () => {
    history.push('/session-survey');
  };

  const openRules = () => {
    setModalIsOpen(EModalTypes.RULES);
  };

  const openAgenda = () => {
    setModalIsOpen(EModalTypes.AGENDA);
  };

  const openBreakoutRooms = useCallback(() => {
    dispatch({
      type: roomConstants.SET_BREAKOUT_ROOM_MODAL,
      breakoutRoomModalIsOpen: true,
    });
  }, [dispatch]);

  // handle opening to a session
  const handleChatClick = useCallback(() => {
    openToChannel(sessionInfo.uuid);
  }, [openToChannel, sessionInfo.uuid]);

  return (
    <>
      {modalIsOpen !== false && (
        <RoomModal closeModal={setModalIsOpen}>
          {modalIsOpen === EModalTypes.RULES ? <RulesBody /> : <AgendaBody />}
        </RoomModal>
      )}

      <div className={styles['session-room-footer']}>
        <div className={styles['container'] + ' container'}>
          <div className={styles['footer-row']}>
            <div className={styles['left-side']}>
              <div
                className={styles['footer-icon-container']}
                onClick={() => {
                  setPublisherAudio();
                }}
              >
                <img
                  className={styles['footer-icon'] + ' ' + styles['audio-icon']}
                  src={audioIcon}
                  alt={'Mute icon'}
                />
                <p className={styles['footer-icon-text']}>Audio</p>
              </div>

              <div
                className={
                  styles['footer-icon-container'] +
                  ' ' +
                  (isHost ? styles['host-hide'] : '')
                }
                onClick={openRules}
              >
                <img
                  className={styles['footer-icon'] + ' ' + styles['rules-icon']}
                  src={RulesIcon}
                  alt={'rules icon'}
                />
                <p className={styles['footer-icon-text']}>Rules</p>
              </div>

              <div
                className={styles['footer-icon-container']}
                onClick={openAgenda}
              >
                <img
                  className={
                    styles['footer-icon'] + ' ' + styles['agenda-icon']
                  }
                  src={AgendaIcon}
                  alt={'agenda icon'}
                />
                <p className={styles['footer-icon-text']}>Agenda</p>
              </div>
            </div>

            <div className={styles['center']}>
              <div
                className={
                  styles['footer-icon-container'] +
                  ' ' +
                  styles['chat-container']
                }
                onClick={handleChatClick}
              >
                {unreadCount > 0 && !isOpen && (
                  <div className={styles['unread-count']}>
                    <GroupBadge number={unreadCount} isLarge={false} />
                  </div>
                )}

                <img
                  className={styles['footer-icon'] + ' ' + styles['chat-icon']}
                  src={ChatIcon}
                  alt={'Chat icon'}
                />
                <p
                  className={
                    styles['footer-icon-text'] + ' ' + styles['chat-text']
                  }
                >
                  Chat
                </p>
              </div>

              {isHost && (
                <div
                  className={styles['footer-icon-container']}
                  onClick={openBreakoutRooms}
                >
                  <img
                    className={
                      styles['footer-icon'] + ' ' + styles['rooms-icon']
                    }
                    src={BreakoutRoomIcon}
                    alt={'Rooms icon'}
                  />
                  <p
                    className={
                      styles['footer-icon-text'] + ' ' + styles['rooms-text']
                    }
                  >
                    Rooms
                  </p>
                </div>
              )}

              <div
                className={styles['footer-icon-container']}
                onClick={() => setDeviceSettingsOpen(!deviceSettingsOpen)}
              >
                <img
                  className={
                    styles['footer-icon'] + ' ' + styles['device-change-icon']
                  }
                  src={DeviceChangeIcon}
                  alt={'Device icon'}
                />
                <p
                  className={
                    styles['footer-icon-text'] + ' ' + styles['options-text']
                  }
                >
                  Settings
                </p>
              </div>

              <div
                className={styles['footer-icon-container']}
                onClick={leaveRoom}
              >
                <img
                  className={styles['footer-icon'] + ' ' + styles['leave-icon']}
                  src={ExitIcon}
                  alt={'Leave'}
                />
                <p
                  className={
                    styles['footer-icon-text'] + ' ' + styles['leave-text']
                  }
                >
                  Leave
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
    </>
  );
};

const RulesBody = () => {
  return (
    <>
      <h4 className={styles['rules-title']}>ShareWell Session Ground Rules:</h4>
      <p className={styles['rules-p']}>
        All members must follow these session rules. Violation of any rule may
        result in immediate removal from the session.
      </p>

      <ol className={styles['rules-list']}>
        <li>
          <b>Lived experience of the topic.</b> Everyone in the session must
          have personal experience of the session topic.{' '}
        </li>
        <li>
          <b>Video camera on and stay within camera view.</b>. We ask that you
          stay within camera view the entire session.{' '}
        </li>
        <li>
          <b>Share your experience, not your advice.</b> We do not allow members
          to give direct advice as this is a session amongst peers with no one
          acting in a professional mental health capacity.{' '}
        </li>
      </ol>
      <p className={styles['rules-p']}>
        See our{' '}
        <a href='/safety-policy' target='_blank'>
          Safety Policy
        </a>{' '}
        for more information and emergency resources.
      </p>
    </>
  );
};

const AgendaBody = () => {
  const { roomStore } = useWellSelector((state) => state);
  const { sessionInfo, hostIsActive } = roomStore;

  const isBefore = getServerTime().isBefore(
    getSessionStartTime(sessionInfo).clone().add(5, 'm'),
  );

  const GeneralList = () => {
    return (
      <ol className={styles['rules-list']}>
        <li>
          <b>Review the Ground Rules</b> and commit to following them.
        </li>
        <li>
          <b>Introductions</b>: What is your name and what brought you to the
          session? (5 min)
        </li>
        <li>
          <b>Sharing and support</b>: Below are some sample questions.
          <ul className={styles['inner-list']}>
            <li>What is your experience with the session topic?</li>
            <li>
              How can the group support you today? (After a share, chime in with
              reflections & support)
            </li>
            <li>
              What coping mechanisms have provided relief? What's working?
            </li>
            <li>
              Are there any insights or realizations you would like to share?
            </li>
            <li>
              What’s one action you can take this week to cultivate more peace?
            </li>
          </ul>
        </li>

        <li>
          <b>Closing circle</b>: What is a positive takeaway you gained from
          this session? (5 min)
        </li>
      </ol>
    );
  };

  const AnyOneCanHost = () => {
    return (
      <p className={styles['rules-p']}>
        Notes: If the group agrees, anyone can volunteer to step-up and
        substitute host this session.
      </p>
    );
  };

  const Concerns = () => {
    return (
      <p className={styles['rules-p']}>
        Have feedback? Please email{' '}
        <a href='mailto:support@sharewellnow.com'>support@sharewellnow.com</a>.
      </p>
    );
  };

  return (
    <>
      <h4 className={styles['rules-title']}>Session Guide & Agenda:</h4>

      {sessionInfo.is_group_hosted ? (
        <>
          <p className={styles['rules-p']}>
            We’re trying something new! This session will be group-hosted. Let’s
            get started! You can follow the session agenda below.{' '}
            <b>Please share in alphabetical order to start</b>.
          </p>

          <GeneralList />

          <AnyOneCanHost />

          <Concerns />
        </>
      ) : hostIsActive ? (
        <>
          <GeneralList />
          <Concerns />
        </>
      ) : isBefore ? (
        <>
          <p className={styles['rules-p']}>
            Your host has not yet arrived. Feel free to chat with your fellow
            attendees while we wait.{' '}
            <b>
              The session will start at{' '}
              {getSessionStartTime(sessionInfo)
                .clone()
                .add(5, 'm')
                .format(timeConstants.timeFormat) +
                ' ' +
                getSessionStartTime(sessionInfo)
                  .tz(moment.tz.guess())
                  .format('z')}{' '}
              regardless
            </b>
            .
          </p>
          <p>
            <b>Icebreaker Question</b>: How long have you been a ShareWell
            member?
          </p>
        </>
      ) : (
        <p className={styles['rules-p']}>
          <p className={styles['rules-p']}>
            Looks like your host has not arrived, so we’re going to get started!
            Please follow the session agenda below as a group.{' '}
            <b>Please share in alphabetical order to start</b>.
          </p>

          <GeneralList />

          <AnyOneCanHost />

          <Concerns />
        </p>
      )}
    </>
  );
};
