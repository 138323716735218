import { useState, useCallback } from 'react';
import { WellInputWrapper } from '_components';
import styles from './DiscountCodeInput.module.css';
import { stripeActions } from '_actions';
import { useWellDispatch } from '_hooks';
import { IDiscountCode } from '_types';

export const DiscountCodeInput = ({
  callback,
}: {
  callback: (discountCode: IDiscountCode) => void;
}) => {
  const dispatch = useWellDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const submitDiscount = useCallback(
    (_) => {
      dispatch(stripeActions.claimDiscountCode(inputValue, false))
        .then((res) => {
          callback({
            code: res.code,
            months_off: res.months_off,
            is_gift: res.is_gift,
          });
        })
        .catch(() => {
          setHasError(true);
          setInputValue('');
          setTimeout(() => {
            setHasError(false);
          }, 500);
        });
    },
    [callback, dispatch, inputValue]
  );

  return (
    <div>
      {!isOpen ? (
        <div className={styles['open-input']} onClick={() => setIsOpen(true)}>
          Enter Code
        </div>
      ) : (
        <WellInputWrapper
          error={''}
          inputContainerClassName={
            styles['input-container'] +
            ' ' +
            (hasError ? styles['has-error'] : '')
          }
          label={'Gift Code'}
        >
          <>
            <input
              value={inputValue}
              onChange={handleChange}
              name={'code'}
              placeholder={'Code...'}
            />
            <div className={styles['submit-button']} onClick={submitDiscount}>
              SUBMIT
            </div>
          </>
        </WellInputWrapper>
      )}
    </div>
  );
};
