import React, { useState, useEffect } from 'react';
import styles from './WellMeatBalls.module.css';

export const WellMeatBalls = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //anytime closeAll changes close all meatballs
  useEffect(() => {
    manualClose();
  }, [props.closeAll]);

  function setDropdownOpen() {
    if (isOpen) return;
    setIsOpen(true);
    document.addEventListener('mousedown', handleClickOutside);
  }

  function handleClickOutside(e) {
    if (
      !e.target.classList.contains(styles['well-meatballs-option']) &&
      !e.target.classList.contains(styles['well-meatballs-container'])
    ) {
      manualClose();
    }
  }

  function manualClose() {
    setIsOpen(false);
    document.removeEventListener('mousedown', handleClickOutside);
  }

  return (
    <div
      className={styles['well-meatballs-container']}
      onClick={() => {
        setDropdownOpen(true);
      }}
      style={props.style}
      data-key={props.dropDownKey}
    >
      <div
        className={
          styles['well-meatballs-dropdown'] +
          ' ' +
          (isOpen ? styles['open'] : '') +
          ' ' +
          (props.isLeft ? styles['is-left'] : '')
        }
      >
        {props.dropDownItems.map((item, index) => {
          if (!item || !item.text)
            return (
              <React.Fragment
                key={props.dropDownKey + '-' + index}
              ></React.Fragment>
            );
          return (
            <div
              key={props.dropDownKey + '-' + index}
              className={
                styles['well-meatballs-option'] +
                ' ' +
                (item.disable ? styles['disabled-option'] : '')
              }
              onClick={() => {
                item.onClick();
                manualClose();
              }}
            >
              {item.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};
