import { IGroup, IUser } from '.';

export enum ESeriesStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface ISeries {
  end_datetime: string | null;
  repeat: string;
  status: ESeriesStatus;
  uuid: string;
}

//This is the card info interface which is returned from the server
export interface ISession {
  am_i_host: boolean;
  am_i_joined: boolean;
  am_i_joined_to_group: boolean;
  description: string;
  duration_minutes: number;
  end_time: string;
  external_url: string | null;
  group_uuid: string;
  group_slug: string;
  host_user: IUser;
  in_session: false;
  joined_users: any;
  max_attendees: number;
  num_spots_left: number;
  num_users_joined: number;
  pic: string;
  price: string | null; // not used on front-end any more
  series: ISeries;
  start_time: string;
  status: string | null;
  timezone: string;
  title: string;
  type: string;
  uuid: string;
  video_provider: string | null;
  was_seen: boolean | null;
  is_group_hosted: boolean;
  group_info?: IGroup; // added on session info, not always available
}

export enum ESortType {
  SORT_TYPE_SOON = 'soon',
  SORT_TYPE_NEW = 'new',
}

// SESSION CREATE
export interface ISessionCreateNoValidation {
  duration: number;
  maxAttendees: number;
  repeat: string; // 'WEEKS:1' | 'WEEKS:2' | 'WEEKS:4',
  image: any; // image
  originalImage: string | null;
  seriesEndTime: any; // moment
}
