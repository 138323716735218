import {
  GoogleOAuthProvider,
  useGoogleLogin,
  CodeResponse,
} from '@react-oauth/google';
import { GoogleIcon } from '_components';
import { useOauthLogin } from '_hooks';
import { authService } from '_services';
import { DispatchBoolean, DispatchString } from '_types';
import styles from './OauthLogin.module.css';

export const GoogleLoginProvider = ({
  disabled,
  setError,
  setIsLoading,
}: {
  disabled: boolean;
  setError: DispatchString;
  setIsLoading: DispatchBoolean;
}) => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_APP_ID as string}
    >
      <GoogleLoginComponent
        setError={setError}
        setIsLoading={setIsLoading}
        disabled={disabled}
      />
    </GoogleOAuthProvider>
  );
};

export const GoogleLoginComponent = ({
  disabled,
  setError,
  setIsLoading,
}: {
  disabled: boolean;
  setError: DispatchString;
  setIsLoading: DispatchBoolean;
}) => {
  const { loginCallback } = useOauthLogin(setError, setIsLoading);

  const handleSuccess = async (
    tokenResponse: Omit<
      CodeResponse,
      'error' | 'error_description' | 'error_uri'
    >
  ) => {
    if (tokenResponse.code) {
      loginCallback(() => authService.loginGoogle(tokenResponse.code), 'Google');
    }
  };

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: handleSuccess,
  });

  return (
    <div
      className={styles['container'] + ' ' + styles['google']}
      onClick={login}
      style={disabled ? { pointerEvents: 'none' } : { position: 'relative' }}
    >
      <GoogleIcon />
      <span className={styles['text']}>Continue with Google</span>
    </div>
  );
};
