
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SessionAttendee.module.css';
import { Link } from 'react-router-dom';
import { useGetImgixVersion } from '_hooks';
import { IUser } from '_types';
import { RootState } from '_reducers';
import PlusSign from '_images/plus-sign.png';
import { WellLoader } from '_components';


export const SessionAttendee = ({ user }: { user: IUser }) => {
  const { loggedIn } = useSelector((state: RootState) => state.userStore);

  const { username, pic } = user;

  const croppedPic = useGetImgixVersion(pic, { h: 140, w: 140, fit: 'crop' });

  return (
    <Link
      to={'/profile/' + username}
      className={
        styles['attendee-item'] + ' ' + (loggedIn ? styles['can-click'] : '')
      }
    >
      <div className={styles['user-image-container']}>
        <img src={croppedPic} className={styles['user-image']} alt={username} />
      </div>
      {loggedIn && <div className={styles['attendee-name']}>{username}</div>}
    </Link>
  );
};




export const SessionAttendeeEmpty = ({ onClick, isLoading }: 
  { onClick: any; isLoading: boolean }) => {

  const [showingPlusSign, setShowingPlusSign] = useState(true);
  const { userData } = useSelector((state: RootState) => state.userStore);

  const userImage = useGetImgixVersion(userData.pic, {
    h: 140,
    w: 140,
    fit: 'crop',
  });

  const onMouseEnter = () => {
    setShowingPlusSign(false);
  };

  const onMouseLeave = () => {
    setShowingPlusSign(true);
  };


  return (
    <>
      <div
        className={styles['attendee-item'] + ' ' + styles['center']}
        onClick={!isLoading ? onClick : () => { }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles['plus-sign-container']}>
          {isLoading ? (
            <WellLoader heightWidth={75} />
          ) : showingPlusSign ? (
            <img
              src={PlusSign}
              className={styles['plus-sign']}
              alt={'Plus Sign'}
            />
          ) : (
            <>
              <img
                src={userImage}
                className={styles['user-image'] + ' ' + styles['blur']}
                alt={'User'}
              />
              <span className={styles['register-text']}>REGISTER</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};