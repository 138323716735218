import { useState, useRef } from 'react';
import { WellInputWrapper } from '_components';
import { resizeImage } from '_helpers';
import styles from './SessionImageInput.module.css';
import { useGetImgixVersion } from '_hooks';

// would like to show image preview here
export const SessionImageInput = ({
  nonValidationData,
  setNonValidationData,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const previewRef = useRef<any>();

  const handleImageChange = (event: any) => {
    setIsLoading(true);

    resizeImage({ file: event.target.files[0], maxSize: 6000 })
      .then((data) => {
        setNonValidationData({
          ...nonValidationData,
          image: data,
        });
        setShowImage(true);

        if (previewRef.current)
          previewRef.current.src = URL.createObjectURL(data);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  let image = useGetImgixVersion(nonValidationData.originalImage, {
    w: 740,
    h: 360,
    fit: 'crop',
    crop: 'entropy',
  });

  return (
    <>
      <WellInputWrapper label={'Session Image'}>
        <input
          type={'file'}
          onChange={handleImageChange}
          name={'image'}
          placeholder={'Image'}
          accept='image/jpg'
          title=''
          className={!showImage ? styles['no-image'] : ''}
        />
      </WellInputWrapper>

      {(image || showImage) && (
        <div className={styles['image-preview-container']}>
          <h3>Current Image:</h3>
          <img
            src={image}
            className={styles['image-preview']}
            alt={'Session'}
            ref={previewRef}
          />
        </div>
      )}
    </>
  );
};
