import { IAboutUsTeamMember } from './AboutUs';

const aboutUsTeamData: IAboutUsTeamMember[] = [
  {
    name: 'Alex Darling',
    photo: '/site/user-images/alex.jpeg',
    title: 'Growth',
    username: 'alex',
  },
  {
    name: 'Amy Vaninetti',
    photo: '/site/user-images/amy.jpeg',
    title: 'Community',
    username: 'amyvaninetti',
  },

  {
    name: 'Billy Herr',
    photo: '/site/user-images/billy.jpeg',
    title: 'Engineering',
    username: 'billy',
  },
  {
    name: 'Brett Memsic',
    photo: '/site/user-images/brett.jpeg',
    title: 'Product',
    username: 'brett',
  },
  {
    name: 'CeCe Cheng',
    photo: '/site/user-images/cece.jpg',
    title: 'Founder & CEO',
    username: 'cececheng',
  },
  {
    name: 'Mana Setayesh',
    photo: '/site/user-images/mana.jpeg',
    title: 'Marketing',
    username: 'mana',
  },
  {
    name: 'Mike Stratta',
    photo: '/site/user-images/mike.jpeg',
    title: 'Operations',
    username: 'mike',
  },
  {
    name: 'Nauseen Saiyed',
    photo: '/site/user-images/nauseen.jpeg',
    title: 'Support',
    username: 'nauseen',
  },
  {
    name: 'Riley Schumer',
    photo: '/site/user-images/riley.jpeg',
    title: 'Support',
    username: 'Riley',
  },
];

export default aboutUsTeamData;
