import styles from './UserHomeTop.module.css';
import { SessionItem, SessionItemEmpty } from '_components';
import { Link } from 'react-router-dom';
import { useUserHome, useWellDispatch } from '_hooks';
import { ISession } from '_types';
import { useEffect, useState } from 'react';
import { userHomeActions } from '_actions';

export const UserHomeTop = ({
  allSessions,
  reloadSingleSession,
}: {
  allSessions: ISession[];
  reloadSingleSession: (session: string) => void;
}) => {
  const dispatch = useWellDispatch();

  const { selectedGroup } = useUserHome();

  const [announcementLink, setAnnouncementLink] = useState<string | false>(false);
  useEffect(() => {
    dispatch(userHomeActions.getAnnouncementLink())
      .then((res) => {
        setAnnouncementLink(res);
      })
      .catch((_) => {});
  }, []);

  return (
    <>
      <div className={styles['upcoming-sessions-text']}>
        <h3>Next Session</h3>
      </div>

      <div className={styles['top-items-container']}>
        <div className={styles['left-session']}>
          {allSessions && allSessions[0] ? (
            <SessionItem
              key={'home-session-' + allSessions[0].uuid}
              session={allSessions[0]}
              isSquare={true}
              allowRegister={true}
              reloadSession={reloadSingleSession}
            />
          ) : (
            <SessionItemEmpty isSquare={true} selectedGroup={selectedGroup} />
          )}
        </div>

        <div className={styles['notice-container']}>
          {announcementLink !== false &&
            <Link to={'/profile/' + announcementLink} className={styles['notices']}>
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  '/site/announcements/announcement.svg?t=' + Math.floor(new Date().getTime() / 1000)
                }
                className={styles['announcement']}
                alt={'ShareWell Announcement'}
              />
            </Link>
          }
        </div>
      </div>
    </>
  );
};
