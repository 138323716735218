import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import styles from './CancelSubscription.module.css';
import { useWellDispatch, useWellSelector } from '_hooks';
import {
  WellSlideOut,
  ModalBodyWrapper,
  ModalButtonWrapper,
  WellButton,
  WellLoader,
  WellButtonLink,
} from '_components';
import { stripeActions, generalActions, userActions } from '_actions';
import { toastr } from 'react-redux-toastr';
import { AnalyticsSendEvent } from '_helpers';

interface ICancelSubscription {
  cancelSlide: () => void;
}

enum ECancelSubscriptionScreens {
  SCREEN_ONE = 1, // are you sure
  SCREEN_TWO = 2, // why are you canceling?
  SCREEN_THREE = 3, // go to sessions
}

export const CancelSubscription = ({ cancelSlide }: ICancelSubscription) => {
  const { userStore } = useWellSelector((state) => state);
  const { userData } = userStore;

  const dispatch = useWellDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState<ECancelSubscriptionScreens>(
    ECancelSubscriptionScreens.SCREEN_ONE
  );

  // all canceling is, is just stop auto paying.
  const cancelSubscription = useCallback(async () => {

    setIsLoading(true);

    dispatch(
      stripeActions.updateSubscription({
        subscription_type: null,
        auto_subscribe: 'false',
      })
    )
      .then(() => {
        AnalyticsSendEvent('subscription_unsubscribed', {});
        setIsLoading(false);
        setScreen(ECancelSubscriptionScreens.SCREEN_THREE);
      })
      .catch(() => {
        toastr.error('Something went wrong please try again.', '');
      });
  }, [dispatch]);

  // switch the step to the survey
  const goToSurvey = useCallback(() => {
    setScreen(ECancelSubscriptionScreens.SCREEN_TWO);
  }, []);

  return (
    <WellSlideOut
      closeSlide={cancelSlide}
      isLoading={isLoading}
      disableOverlay={false}
      disallowClose={false}
    >
      <>
        {screen === ECancelSubscriptionScreens.SCREEN_ONE ? (
          <>
            <h2 className={styles['modal-title']}>
              We hate to see you go, {userStore.userData.name}!
            </h2>
            <h3 className={styles['modal-subtitle']}>
              Are you sure you want to cancel your unlimited access
              to&nbsp;ShareWell&nbsp;peer&nbsp;support?
            </h3>

            <ModalBodyWrapper>
              <div className={styles['first-section']}>
                <iframe
                  className={styles['iframe']}
                  title={'ShareWell Video'}
                  src={'https://www.youtube.com/embed/AV_cUeilyvw?autoplay=1'}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </ModalBodyWrapper>

            {!isLoading ? (
              <div className={styles['buttons-container']}>
                <WellButton
                  btnOnClick={cancelSlide}
                  className={styles['modal-btn']}
                  btnText={'NO, GO BACK'}
                  type={'well-btn-new-primary'}
                />
                <WellButton
                  btnOnClick={goToSurvey}
                  className={styles['modal-btn']}
                  btnText={'YES, CONTINUE'}
                  type={'well-btn-red'}
                />
              </div>
            ) : (
              <div className={styles['loading-container']}>
                <WellLoader heightWidth={150} />
              </div>
            )}
          </>
        ) : screen === ECancelSubscriptionScreens.SCREEN_TWO ? (
          <CancelSubscriptionSurvey
            isLoading={isLoading}
            cancelSubscription={cancelSubscription}
            setScreen={setScreen}
          />
        ) : screen === ECancelSubscriptionScreens.SCREEN_THREE ? (
          <ModalBodyWrapper>
            <>
              <div className={styles['is-done-image-container']}>
                <img
                  className={styles['is-done-image']}
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    '/site/subscription/cancel.svg'
                  }
                  alt={'Two people hugging'}
                />
              </div>

              <h2 className={styles['modal-title']}>
                <b>We're sorry to see you downgrade, {userData.name}!</b>
              </h2>

              <h3 className={styles['modal-subtitle']}>
                <b>But you can still receive support at ShareWell.</b>
              </h3>
              <p className={styles['text']}>
                You will continue to have a free basic membership, so you can be
                part of ShareWell and access the community through our forum and
                by attending <b>1&nbsp;free&nbsp;session&nbsp;a&nbsp;month</b>.
              </p>
              <p className={styles['text']}>
                We hope you will continue to prioritize your mental health by
                attending an upcoming session. If you need more support, you can
                come back anytime and upgrade to an Unlimited Membership.
              </p>

              <p className={styles['text']}>
                If you have any followup questions or requests, please email{' '}
                <a href='mailto:support@sharewellnow.com'>
                  support@sharewellnow.com
                </a>{' '}
                for&nbsp;further&nbsp;assistance.
              </p>

              <div className={styles['btns-row']}>
                <WellButtonLink
                  type={'well-btn-primary'}
                  to={'/sessions'}
                  btnText={'VIEW SESSIONS'}
                  className={styles['view-sessions']}
                />
              </div>
            </>
          </ModalBodyWrapper>
        ) : (
          <></>
        )}
      </>
    </WellSlideOut>
  );
};

/////////////////////////////////////////////////////////////

const questions: IQuestionWithType[] = [
  {
    question: 'Not enough sessions that interest me',
    type: 'text',
    placeholder: 'what are you looking for?',
  },
  {
    question: 'I never used ShareWell',
    type: 'text',
    placeholder: 'why not?',
  },
  {
    question: 'I feel better and no longer need support',
    type: 'checkbox',
    placeholder: '',
  },
  {
    question: 'I found an alternative solution',
    type: 'text',
    placeholder: 'Please share',
  },
  { question: 'It was too expensive', type: 'checkbox', placeholder: '' },
  {
    question: 'It did not meet my expectations',
    type: 'text',
    placeholder: 'Why not?',
  },
  {
    question: 'I thought the service was different',
    type: 'text',
    placeholder: 'Please share',
  },
];

interface ICancelSubscriptionSurvey {
  isLoading: boolean;
  cancelSubscription: () => void;
  setScreen: Dispatch<SetStateAction<ECancelSubscriptionScreens>>;
}

export interface IQuestionWithType {
  question: string;
  type: string;
  placeholder: string;
}

const CancelSubscriptionSurvey = ({
  isLoading,
  cancelSubscription,
  setScreen,
}: ICancelSubscriptionSurvey) => {
  const dispatch = useWellDispatch();
  const { userData } = useWellSelector((state) => state.userStore);
  const [checkOne, setCheckOne] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [runTimeQuestions, setRunTimeQuestions] = useState([...questions]);
  const [feedback, setFeedback] = useState('');

  const submitSurvey = useCallback(() => {
    let question = runTimeQuestions[0].question;
    let feedback1 = feedback == '' ? '' : ' ? ' + feedback;
    let completeFeedback = question + feedback1;
    dispatch(
      generalActions.setOrAppendMarketingData(
        'append',
        'unsubscribe_survey',
        JSON.stringify({
          reason: completeFeedback,
          user: userData.username,
        })
      )
    );
    cancelSubscription();
    setScreen(ECancelSubscriptionScreens.SCREEN_THREE);
  }, [
    dispatch,
    feedback,
    runTimeQuestions,
    userData.username,
    cancelSubscription,
    setScreen,
  ]);

  return (
    <div className={`${styles['nopadding']} container`}>
      <div className={`${styles['nopadding']} row `}>
        <div className={` col-12 text-center`}>
          <h2 className={styles['modal-title']}>
            Can you help us understand why <br /> peer support is no longer for
            you?
          </h2>
          <div className={`${styles['nopadding']} col-12 text-center`}>
            <div className={styles['poll-row']}>
              <div className={styles['poll-answers']}>
                {runTimeQuestions.map((que, index) => {
                  return (
                    <div className={styles['poll-answer']}>
                      <input
                        className={styles['checkbox']}
                        name={index + ':' + checkOne[index]}
                        type='checkbox'
                        checked={checkOne[index]}
                        onChange={(event) => {
                          let checkAr = [
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                          ];
                          if (!event.target.checked) {
                            setRunTimeQuestions(questions);
                            setCheckOne([...checkAr]);
                          } else {
                            setRunTimeQuestions([questions[index]]);
                            checkAr[0] = event.target.checked;
                            setCheckOne([...checkAr]);
                          }
                          setFeedback('');
                        }}
                      />
                      <div className={styles['poll-answers-label']}>
                        {que.question}
                      </div>

                      {checkOne && que.type === 'text' && checkOne[index] ? (
                        <div className={styles['input-container']}>
                          <input
                            className={styles['answer-input']}
                            type='text'
                            value={feedback}
                            placeholder={que.placeholder}
                            onChange={(event) => {
                              setFeedback(event.target.value);
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <ModalButtonWrapper>
              {!checkOne.includes(true) ? (
                <></>
              ) : (
                <>
                  <WellButton
                    btnOnClick={submitSurvey}
                    btnText={'CANCEL MEMBERSHIP'}
                    type={'well-btn-new-primary'}
                    isLoading={isLoading}
                  />
                </>
              )}
            </ModalButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
