import { useContext } from 'react';

import { GroupListContext } from '../../Groups';

export const useGroupList = () => {
  const context = useContext(GroupListContext);
  if (context === undefined) {
    throw new Error('useGroupList must be used within a GroupListContext');
  }
  return context;
};

