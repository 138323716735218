import {
  useState,
  createContext,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useWellDispatch, useWellSelector } from '_hooks';
import { StreamChat } from 'stream-chat';
import { AnalyticsSendEvent, history } from '_helpers';
import { chatActions } from '_actions/chat.actions';
import { StreamChatGenerics, LocalUserType, LocalChannelType } from '_types';
import moment from 'moment-timezone';

// add zendesk to window object
interface Window {
  zE?: any;
}

interface ListChannelsObject {
  channels: LocalChannelType[];
  isDone: boolean;
  limit: number;
  loadingMore: boolean;
}

interface IWellChatContext {
  chatClient: any;
  overrideSubmitHandler: (message: any, channelCid: string) => Promise<void>;
  onMentionsClick: any;
  initUnreadCount: number;

  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  curChannel: any | LocalChannelType;
  setCurChannel: Dispatch<any>;

  getChannels: () => Promise<number | undefined>;
  reloadChannels: () => Promise<void>;
  channelList: ListChannelsObject;
  openToChannel: (uuid: string) => Promise<void>;
}

export const WellChatContext = createContext<IWellChatContext>(
  {} as IWellChatContext,
);
export const WellChatProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const dispatch = useWellDispatch();

  const { userStore } = useWellSelector((state) => state);
  const { userData, loggedIn } = userStore;

  const [chatClient, setChatClient] = useState<any>(null);
  const [channelList, setChannelList] = useState<ListChannelsObject>({
    channels: [],
    isDone: false,
    limit: 0,
    loadingMore: false,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false); // isChat open
  const [curChannel, setCurChannel] = useState<any | undefined>(undefined); //cur channel
  const [initUnreadCount, setInitUnreadCount] = useState<number>(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters: any = {
    type: process.env.REACT_APP_GET_STREAM_CHANNEL, //dev or live
    // if admin wants to see everything
    // ...(!userData.is_admin && {members: { $in: [userData.uuid] }}),
    members: { $in: [userData.uuid] },
    start_date: { $lt: moment().format() }, // start time is set to 48 before the sessions start
    close_date: { $gt: moment().format() }, // close date is set to 72 hours after session ends
  };

  // gets the channels for a user
  const getChannels = useCallback(async () => {
    if (!chatClient || channelList.isDone) return;

    setChannelList({
      ...channelList,
      isDone: true,
    });

    const channels = await chatClient.queryChannels(
      filters,
      [{ last_message_at: -1 }],
      {
        watch: true,
        state: true,
        limit: 20,
        offset: channelList.limit,
      },
    );

    if (channels)
      setChannelList({
        ...channelList,
        limit: channelList.limit + 20,
        channels: channelList.channels.concat(channels),
        isDone: channels.length < 20 ? true : false,
      });

    return Promise.resolve(1);
  }, [channelList, chatClient, filters]);

  // reloads the channels if they happen to change (session reg)
  const reloadChannels = useCallback(async () => {
    if (!chatClient) return;

    const channels = await chatClient.queryChannels(
      filters,
      [{ last_message_at: -1 }],
      {
        watch: true,
        state: true,
        limit: channelList.limit + 1,
        offset: 0,
      },
    );

    if (channels)
      setChannelList({
        ...channelList,
        channels: channels,
        limit: channelList.limit + 1,
      });
  }, [channelList, chatClient, filters]);

  // custom message callback to record on metrics
  const overrideSubmitHandler = useCallback(
    async (message: any, channelCid: string) => {
      const channelDetails = channelCid.split(':'); //split the type & channel
      //connect to the channel so I can post
      const channel = chatClient.channel(channelDetails[0], channelDetails[1]);

      message.mentioned_users = message.mentioned_users.map((item: any) => {
        dispatch(chatActions.tagInChat(channelDetails[1], item.id)); //tag the user
        return item.id;
      });
      await channel.sendMessage(message); //send message
      AnalyticsSendEvent('chat_message_submit', {
        user: userStore.userData.uuid,
        session: channel.data.session,
        group: channel.data.group,
        name: channel?.data?.name,
      });
    },
    [chatClient, dispatch, userStore.userData.uuid],
  );

  // custom message click for sever to send email
  const onMentionsClick = useCallback(
    (event: React.BaseSyntheticEvent, user: LocalUserType) => {
      history.push('/profile/' + user?.name);
    },
    [],
  );

  // open chat to a specific channel
  const openToChannel = useCallback(
    async (uuid: string) => {
      if (!userData.uuid || !chatClient) return;

      const filter: any = {
        type: process.env.REACT_APP_GET_STREAM_CHANNEL, //dev or live
        id: { $in: [uuid] },
        // limit: 1,
      };

      if (!userData.is_admin) {
        filter.members = { $in: [userData.uuid] }; // user is a member of the channel
      }

      try {
        const channels = await chatClient.queryChannels(
          filter,
          [{ last_message_at: -1 }],
          {
            // watch: true,
            state: true,
          },
        );

        if (channels && channels.length >= 1) {
          if (!isOpen) setIsOpen(true);
          const channelToUse = channels[0];
          setCurChannel(channelToUse);
        }
      } catch (error) {
        // unhandled error
      }
    },
    [chatClient, isOpen, userData.uuid, userData.is_admin],
  );

  // initialize chat
  useEffect(() => {
    // if (!loggedIn || chatClient || process.env.REACT_APP_NODE_ENV === 'local') return;
    if (!loggedIn || chatClient) return;

    const initChat = async () => {
      const chatClient = StreamChat.getInstance<StreamChatGenerics>(
        process.env.REACT_APP_GET_STREAM_KEY || '',
      );
      try {
        const res = await chatClient.connectUser(
          {
            id: userData.uuid,
            name: userData.username,
            image: userData.pic,
          },
          userData.getstreamio_user_token,
        );

        if (res) {
          setChatClient(chatClient);
          if (res?.me) setInitUnreadCount(res.me.total_unread_count);

          // try to mute old channels
          try {
            const muteFilter = {
              type: process.env.REACT_APP_GET_STREAM_CHANNEL, // dev or live
              members: { $in: [userData.uuid] }, // user is a member of the channel
              close_date: { $lt: new Date().toISOString() }, // close date is set to 72 hours after session ends
            };

            const sort: any = [{ last_message_at: -1 }];

            const channels = await chatClient.queryChannels(muteFilter, sort, {
              watch: false, // this is the default
              state: true,
              limit: 30,
            });

            if (channels.length > 0) {
              channels.map(async (channel: any) => {
                channel.markRead();
              });
            }
            return Promise.resolve(1);
          } catch (error) {
            return Promise.resolve(0);
          }
        }
      } catch {}
    };
    initChat();
  }, [chatClient, userData, loggedIn]);

  // on log out destroy chat
  useEffect(() => {
    if (loggedIn || !chatClient) return;

    chatClient.disconnectUser();
    setIsOpen(false); // close the chat
    setChannelList({
      channels: [],
      isDone: false,
      limit: 0,
      loadingMore: false,
    });
    setChatClient(null);
  }, [chatClient, loggedIn]);

  return (
    <WellChatContext.Provider
      value={{
        chatClient, // chat client to use api
        overrideSubmitHandler, // what happens on submit of the message
        onMentionsClick, // when you click a mentions
        initUnreadCount, // starting count of unreads

        isOpen, // is chat open
        setIsOpen, // set if the chat is open

        curChannel, // what channel is currently open
        setCurChannel, // set the cur channel that is open

        getChannels, // load channels
        reloadChannels, // reloads the current channels
        channelList, // list of channels
        openToChannel, // open the chat to a specific channel,
      }}
    >
      {children}
    </WellChatContext.Provider>
  );
};
