import { handleFetchReturn, failedToFetch } from '..';

export const emailService = {
  confirmEmail,
  unsubscribeEmail,
};

export function confirmEmail(uuid, email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/account/' +
      uuid +
      '/' +
      email +
      '/confirm/' +
      password,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function unsubscribeEmail(email, secret) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/account/' +
      email +
      '/unsubscribe/' +
      secret,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
