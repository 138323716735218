import {
  ModalBodyWrapper,
  ModalButtonWrapper,
  ModalWrapper,
  WellButtonLink,
} from '_components';
import { useModalStyles } from './Modals.styles';

export const LoginRequiredModal = () => {
  const classes = useModalStyles();

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={() => {}}
      disableOverlay={true}
      disallowClose={true}
    >
      <>
        <div className={classes.imageContainer}>
          <img
            src={
              'https://sharewell-space-dev.sfo3.digitaloceanspaces.com/site/modal/register-modal-1.svg'
            }
            className={classes.image}
            alt={'People in ShareWell video session'}
          />
        </div>
        <ModalBodyWrapper>
          <>
            <p className={classes.mainP}>
              Create an account to access thousands of free peer support
              sessions, 24/7 forum and chat. Join us today and talk to people
              who understand what you're going through.
            </p>

            <p className={classes.mainP + ' ' + classes.bold}>
              Ready to boost your happiness?<br></br> Create a free account in
              less than minute.
            </p>
          </>
        </ModalBodyWrapper>

        <ModalButtonWrapper>
          <WellButtonLink
            type={'well-btn-primary'}
            to={'/register'}
            btnText={'CREATE ACCOUNT'}
            className={classes.cta}
          />
        </ModalButtonWrapper>
      </>
    </ModalWrapper>
  );
};
