import {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FacebookIcon } from '_components';
import { useOauthLogin } from '_hooks';
import { authService } from '_services';
import { DispatchBoolean, DispatchString } from '_types';
import styles from './OauthLogin.module.css';

export const FacebookLoginComponent = ({
  disabled,
  setError,
  setIsLoading,
}: {
  disabled: boolean;
  setError: DispatchString;
  setIsLoading: DispatchBoolean;
}) => {
  const { loginCallback } = useOauthLogin(setError, setIsLoading);

  const responseFacebook = (
    userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => {
    // waht??
    const typeCheck: any = userInfo;

    if (typeCheck && typeCheck?.userID && typeCheck?.email && typeCheck?.name) {
      loginCallback(
        () =>
          authService.loginFacebook({
            userId: typeCheck.userID,
            email: typeCheck.email,
            name: typeCheck.name,
          }),
        'Facebook'
      );
    }
  };

  return (
    <>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
        autoLoad={false}
        fields={'name,email,picture'}
        scope={'public_profile,email'}
        callback={responseFacebook}
        render={(renderProps: any) => (
          <div
            className={styles['container'] + ' ' + styles['facebook']}
            onClick={renderProps.onClick}
            style={
              disabled ? { pointerEvents: 'none' } : { position: 'relative' }
            }
          >
            <FacebookIcon />
            <span className={styles['text']}>Continue with Facebook</span>
          </div>
        )}
      />
    </>
  );
};
