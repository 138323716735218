// nothing is protectings this right now for admin context, we check so the buttons don't appear

import { generalActions } from '_actions';
import { AppDispatch } from '_reducers';

export const adminDumpActions = {
  // pinning
  pinPost,
  resetPinPosts,
  // announcement
  setUserAsAnnouncementLink,
  // poll
  setForumPoll
};



// pin a new post
function pinPost(post: string) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        generalActions.setOrAppendMarketingData(
          'append',
          'pinned_forum_post_uuid',
          JSON.stringify(post)
        )
      );
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// reset pinned posts with only this one
function resetPinPosts(post: string) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        generalActions.setOrAppendMarketingData(
          'set',
          'pinned_forum_post_uuid',
          JSON.stringify(post)
        )
      );
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}


function setUserAsAnnouncementLink(user: string) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        generalActions.setOrAppendMarketingData(
          'set',
          'announcement_username',
          JSON.stringify(user)
        )
      );
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}


function setForumPoll(pollInfo: {
  question: string;
  answerOne: string
  answerTwo: string;
  answerThree: string;
  answerFour: string;
  answerFive: string;
}){
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        generalActions.setOrAppendMarketingData(
          'set',
          'forum_poll',
          JSON.stringify(pollInfo)
        )
      );
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}