import { Theme, useTheme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useCongratsStyles = makeStyles((theme: Theme) => {
  return createStyles({
    style: {
      color: '#fc9e43',
      fontWeight: 600,
      letterSpacing: '1px',
      lineHeight: '40px',
      textAlign: 'center',
      marginBottom: '10px',
      paddingBottom: 0,
      position: 'relative',
      fontSize: '32px',
      // [theme?.breakpoints?.up('md')]: {
      // },
      '&:after': {
        content: "'CONGRATULATIONS'",
        // fontSize: '30px',
        position: 'absolute',
        left: '50%',
        top: '18px',
        transform: 'translate(-50%, -50%)',
        opacity: '0.15',
        color: '#fc9e43',
        fontWeight: 900,
        letterSpacing: 0,
        textAlign: 'center',
        width: '100%',
        fontSize: '50px',
        // [theme?.breakpoints?.up('md')]: {
        // },
      },
    },

    freeMonth: {
      '&:after': {
        content: "'FREE MONTH EARNED'",
        fontSize: '45px',
      },
    },

    congrats: {
      '&:after': {
        content: "'CONGRATULATIONS'",
      },
    },

  });
});

export const ModalComponentCongrats = () => {
  const theme = useTheme();
  const classes = useCongratsStyles(theme);

  return (
    <h4 className={classes.style + ' ' + classes.congrats}>CONGRATULATIONS</h4>
  );
};

export const ModalComponentFreeMonth = () => {
  const theme = useTheme();
  const classes = useCongratsStyles(theme);

  return (
    <h4 className={classes.style + ' ' + classes.freeMonth}>
      FREE MONTH EARNED
    </h4>
  );
};
