import {
  SessionStartTimeInput,
  SessionMaxAttendeesInput,
  SessionDurationRadio,
} from '_components';
import styles from './InputGrouping.module.css';

export const InputGroupingTime = ({
  data,
  setValueChange,
  error,
  inputRef,
  nonValidationData,
  setNonValidationData,
  isExternal,
}: any) => {
  return (
    <section className={styles['input-grouping']}>
      <h2>Date & Attendees</h2>
      <p>What time your session starts and the maximum number of attendees.</p>

      <div className={styles['form-container']}>
        <div className={styles['double-inputs']}>
          <SessionStartTimeInput
            data={data}
            setValueChange={setValueChange}
            error={error}
            inputRef={inputRef}
          />

          {!isExternal && (
            <SessionMaxAttendeesInput
              nonValidationData={nonValidationData}
              setNonValidationData={setNonValidationData}
            />
          )}
        </div>
      </div>

      <SessionDurationRadio
        data={nonValidationData}
        setData={setNonValidationData}
      />
    </section>
  );
};
