import { useRef } from 'react';
import styles from './HomepageDivider.module.css';
import { useScrollIntoView } from '_hooks'

export const HomepageDivider = () => {

  const dividerRef = useRef<any>();
  useScrollIntoView(dividerRef, false, 250, () => {
    dividerRef.current.classList.add(styles['animate']);
  });


  return (
    <div className={styles['divider-container']}>
      <div className={styles['divider']} ref={dividerRef}></div>
    </div>
  )

}
