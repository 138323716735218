import { useEffect, useState } from 'react';
import { RegisterModal } from '_components';
import { useParams } from 'react-router-dom';
import { useWellDispatch, useWellSelector } from '_hooks';
import { useCookies } from 'react-cookie';

// registers the user on the partnership page
export const PartnershipModal = ({
  partneredGroups,
}: {
  partneredGroups: { [key: string]: string; };
}) => {
  const dispatch = useWellDispatch();
  const [showPartnershipModal, setPartnershipShowModal] = useState(false);
  const { uuid } = useParams<{ uuid: string }>();
  const { userStore } = useWellSelector((state) => state);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (!userStore.loggedIn && (uuid in partneredGroups))
      setPartnershipShowModal(true);
    else setPartnershipShowModal(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showPartnershipModal){
      setCookie('WELL_AUTO_ADD_' + partneredGroups[uuid].toUpperCase(), 1, {
        path: '/',
        expires: new Date(Date.now() + 1296000),
      });
    }
  }, [dispatch, setCookie, showPartnershipModal]);

  if (!showPartnershipModal) return <></>;

  return (
    <RegisterModal
      closeModal={() => setPartnershipShowModal(false)}
      isBranded={partneredGroups[uuid]}
    />
  );
};
