import { useWellSelector } from '_hooks';
import { getServerTime } from "_helpers";
import Datetime from 'react-datetime';
import { WellInputWrapper } from '_components/WellItems';
import { Moment } from 'moment';

// self validating
export const SessionStartTimeInput = ({
  data,
  setValueChange,
  error,
  inputRef,
  additionalTimeConstraint,
  isSeriesEdit,
}: any) => {

  const { userStore } = useWellSelector((state) => state);
  const { isAdmin } = userStore;

  const handleInputChange = (value: any) => {
    setValueChange(value, 'startTime')
  };

  const valid = (current: Moment) => {

    return (
      current.isAfter(getServerTime().add(-1, 'day')) &&
      current.isBefore(getServerTime().add('61', 'days').startOf('day')) &&
      (additionalTimeConstraint ? current < additionalTimeConstraint : true)
    );
  };

  return (

    <WellInputWrapper
      label={isSeriesEdit ? 'Next Session In Series' : 'Session Start Time'}
      error={error}
      errorRef={inputRef}
    >
      <Datetime
        isValidDate={valid}
        value={data.startTime.value}
        onChange={handleInputChange}
        timeConstraints={isAdmin ? {} : { minutes: { min: 0, max: 59, step: 15 } }}
      />
    </WellInputWrapper>

  );
}
