import React, { useState, useEffect, useCallback } from 'react';
import { ChannelListMessengerProps } from 'stream-chat-react';
import styles from './ChatList.module.css';
import { MinimizeIcon, WellLoader } from '_components';
import { useWellChat, useWellSelector } from '_hooks';
import { CustomListItem } from '..';

export const ChatList: React.FC<ChannelListMessengerProps> = () => {
  const { getChannels, channelList } = useWellChat();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  //start the page loading
  useEffect(() => {
    const start = async () => {
      await getChannels();
      setIsLoading(false);
    };
    start();
  }, []);

  //handles the scroll event to paginate the channels
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (channelList.isDone) return;

      const target = e.target as HTMLTextAreaElement;

      const bottom =
        target?.scrollHeight - target?.scrollTop === target?.clientHeight;
      if (bottom) getChannels();
    },
    [channelList.isDone, getChannels]
  );

  return (
    <div className={styles['list-container']}>
      {isLoading ? (
        <WellLoader heightWidth={100} />
      ) : channelList.channels.length === 0 ? (
        <div className={styles['custom-empty-container']}>
          <div className={styles['header']}>
            <h3 className={styles['header-title']}>Chats</h3>
            <div className={styles['close-icon']}>
              <MinimizeIcon />
            </div>
          </div>
          <span className={styles['no-messages-notice']}>
            Join a Session! <br></br>You’ll be added to a Chat Group will your
            host and fellow attendees.
          </span>
        </div>
      ) : (
        <>
          <div className={styles['header']}>
            <h3 className={styles['header-title']}>Chats</h3>
            <div className={styles['close-icon']}>
              <MinimizeIcon />
            </div>
          </div>
          <div className={styles['inner-row']}>
            <div className={styles['children']} onScroll={handleScroll}>
              {channelList.channels.map((channel: any) => {
                return (
                  <CustomListItem
                    channel={channel}
                    key={'channel-' + channel.id}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
