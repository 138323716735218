export enum EQuizAnswers {
  unanswered = -1,
  anxiety = 0,
  emotionalAbuse = 1,
  depression = 2,
  trauma = 3,
  relationship = 4,
  grief = 5,
  adhd = 6,
  general = 7,
  self = 8,
}

// https://stackoverflow.com/questions/52700659/how-to-use-enum-as-index-key-type-in-typescript
export const QUIZ_ANSWERS_TO_DATA: {
  [key in EQuizAnswers]: { name: string; url: string };
} = {
  [EQuizAnswers.unanswered]: { name: '', url: '' },
  [EQuizAnswers.anxiety]: { name: 'Anxiety', url: '/anxiety' },
  [EQuizAnswers.emotionalAbuse]: {
    name: 'NPD & Emotional Abuse',
    url: '/emotional-abuse',
  },
  [EQuizAnswers.depression]: { name: 'Depression', url: '/depression' },
  [EQuizAnswers.trauma]: { name: 'Trauma & PTSD', url: '/trauma' },
  [EQuizAnswers.relationship]: {
    name: 'Relationship Struggles',
    url: '/relationship-struggles',
  },
  [EQuizAnswers.grief]: { name: 'Grief & Loss', url: '/grief' },
  [EQuizAnswers.adhd]: { name: 'ADHD', url: '/adhd' },
  [EQuizAnswers.general]: {
    name: 'General Mental Health',
    url: 'general-support',
  },
  [EQuizAnswers.self]: { name: 'Self Care & Healing', url: '/self-care' },
};

export interface ISessionExperienceData {
  title: string;
  description: string;
  group: string;
  host: string;
  backgroundImage: string;
}

export const QuizAnswersToSessionData: {
  [key in EQuizAnswers]: ISessionExperienceData;
} = {
  [EQuizAnswers.unanswered]: {
    title: '',
    description: '',
    group: '',
    host: '',
    backgroundImage: '',
  },
  [EQuizAnswers.anxiety]: {
    title: 'Anxiety Peer Support',
    description:
      'One-hour peer support session around Anxiety. Whether it is a mild fluttery feeling or a full-blown panic attack, this is a safe space to share your experience, concerns and coping mechanisms around Anxiety.',
    group: 'Anxiety',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'JackB',
    backgroundImage: '/site/landing-pages/session-background-1.svg',
  },
  [EQuizAnswers.emotionalAbuse]: {
    title: 'Healing from Emotional Abuse',
    description:
      "This session welcomes all who are on the healing path from an emotionally abusive relationship. Perhaps the terms gaslighting or NPD sound familiar or sometimes you struggle to describe what happened to even close friends and family. Join a group who understands because we've been through it. We'll be checking in on how we're doing today and support each other in looking forward.",
    group: 'Narcissistic & Emotional Abuse',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'cececheng',
    backgroundImage: '/site/landing-pages/session-background-2.svg',
  },
  [EQuizAnswers.depression]: {
    title: 'Escaping the Cycle of Depression',
    description:
      "In this meeting we will explore depression. We will reflect on the paths that lead us to depression, where we find ourselves stuck, and how we break out. What have you learned about yourself through the challenges you’ve suffered? What are you currently struggling with? All are encouraged to share but no one will be forced to. We're here to listen and learn from each other and, hopefully, find comfort in the fact that we are not alone in our challenges.",
    group: 'Depression',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'Mike',
    backgroundImage: '/site/landing-pages/session-background-3.svg',
  },
  [EQuizAnswers.trauma]: {
    title: 'Navigating Life Beyond Trauma',
    description:
      'Have you experienced a traumatic event? Perhaps you have experienced chronic or complex trauma that you have endured over a period of time in your life, if so...this session is for you. We will discuss navigating our lives after we have begun to process the trauma we have endured in our lives. This is a space where as a complex trauma coach, I will not provide you with answers, but rather as a group, we will discuss ways we each are navigating our lives beyond our traumas.',
    group: 'Trauma',
    host:
      process.env.REACT_APP_NODE_ENV !== 'live'
        ? 'Billy'
        : 'ComplexTraumaCoach',
    backgroundImage: '/site/landing-pages/session-background-3.svg',
  },
  [EQuizAnswers.relationship]: {
    title: 'Freedom to Love Again: After Betrayal',
    description:
      'The act of infidelity creates a heavy weight for the offender and the offended. The offended is left with hurt, confusion, and distrust. The offender carries the guilt and shame. After infidelity, we question if we could bounce back and find true, authentic love again. If we are able and willing to open our hearts again to someone else or if restoration is an option. Join in on this peer support discussion as we discover ways to heal and restore!',
    group: 'Relationship Struggles',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'AprilMcBride',
    backgroundImage: '/site/landing-pages/session-background-2.svg',
  },
  [EQuizAnswers.grief]: {
    title: 'Grief and Grievers',
    description:
      'For anyone grieving over a loss of a friend, loved one, or just a lost relationship. Sometimes we just need someone to talk and know that we are not alone in this.',
    group: 'Grief & Loss',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'Susan',
    backgroundImage: '/site/landing-pages/session-background-2.svg',
  },
  [EQuizAnswers.adhd]: {
    title: 'ADHD Support Group For Adults',
    description:
      'This group is for general support around dealing with ADHD in a world that seems determined to overstimulate us. We can compare ADHD management strategies, coping skills, and general support for when things seem like they are spinning out of control. I was diagnosed with ADHD at age 34, and almost 20 years later, I am still learning new and useful ways to manage it. I would love to hear what works for the rest of you! Please join us!',
    group: 'General Mental Health',
    host:
      process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'audaciousgrowth',
    backgroundImage: '/site/landing-pages/session-background-1.svg',
  },
  [EQuizAnswers.general]: {
    title: 'Changing Unwelcome Thoughts into Positive Practice',
    description:
      "In this session we will identify internal thoughts as behavior and discuss techniques on how to change these thoughts into a positive practice. I often find myself ruminating on an upcoming task, a past experience I wish had gone differently, or a persistent worry. These internal thoughts are private behavior that may leave me with negative emotions or feelings. Let's practice tracking private behavior and increasing positive practices to instead contact reinforcement in those instances.",
    group: 'General Mental Health',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'jordan_blake',
    backgroundImage: '/site/landing-pages/session-background-4.svg',
  },
  [EQuizAnswers.self]: {
    title: 'Buiding & maintaining a healthy self-image',
    description:
      'Whenever the word "self-image" is mentioned, there are people who limit it only to looks, however, it is quite a broad concept. Granted, there will always be things we don\'t particularly like about ourselves but we still need to value and accept ourselves for who we are, what we have and what we can do.  This session is an eye-opener for those who could do with a bit more self-love.',
    group: 'Self-Care & Healing',
    host: process.env.REACT_APP_NODE_ENV !== 'live' ? 'Billy' : 'Mpumi',
    backgroundImage: '/site/landing-pages/session-background-4.svg',
  },
};
