import { useState } from 'react';
import { generalActions } from '_actions';
import { useWellDispatch, useWellSelector } from '_hooks';
import styles from './SurveyContainer.module.css';
import {
  NPSQuestion,
  SurveyQuestionFeeling,
  SurveyQuestionGeneric,
  WellButton,
} from '_components';
import moment from 'moment-timezone';

type TAnswers = (number | null)[];

// DEPRECATED
export const SurveyContainer = ({
  callback,
  btnClass,
  session,
  isOnboarding,
}: {
  callback: () => void;
  btnClass: string;
  session?: string;
  isOnboarding: boolean;
}) => {
  const dispatch = useWellDispatch();
  const { userStore } = useWellSelector((state) => state);

  const [answers, setAnswers] = useState<TAnswers>([null, null, null]);

  const submit = () => {
    dispatch(
      generalActions.setOrAppendMarketingData(
        'append',
        session ? 'mood_tracker_session' : 'mood_tracker_onboarding',
        JSON.stringify({
          user: userStore.userData.uuid,
          ...(session && { session: session }), // conditional session
          question_1: answers[0],
          question_2: answers[1],
          question_3: answers[2],
          time: moment().format(),
        })
      )
    );

    callback();
  };

  const setAnswer = (key: number, value: number) => {
    setAnswers((prev) => {
      let newList = [...prev];
      newList[key] = value;
      return newList;
    });
  };

  return (
    <>
      <div className={styles['questions-row']}>
        <SurveyQuestionFeeling
          setAnswer={(value: number) => setAnswer(0, value)}
          selected={answers[0]}
          questionColor={'#2b7bb9'}
          isOnboarding={isOnboarding}
        />

        <SurveyQuestionGeneric
          question={'How supported are you in your daily life?'}
          setAnswer={(value: number) => setAnswer(1, value)}
          selected={answers[1]}
          questionColor={isOnboarding ? '#2b7bb9' : '#0aacaf'}
          isOnboarding={isOnboarding}
        />

        <SurveyQuestionGeneric
          question={'How hopeful are you for the future? '}
          setAnswer={(value: number) => setAnswer(2, value)}
          selected={answers[2]}
          questionColor={'#2b7bb9'}
          isOnboarding={isOnboarding}
        />
      </div>

      <WellButton
        className={btnClass}
        btnOnClick={submit}
        btnText={'NEXT'}
        isLoading={false}
        type={'well-btn-primary'}
      />
    </>
  );
};

// to delete when switch back to old survey
export const TempPostSessionSurvey = ({
  callback,
  btnClass,
  session,
}: {
  callback: () => void;
  btnClass: string;
  session: string;
}) => {
  const dispatch = useWellDispatch();
  const { userStore } = useWellSelector((state) => state);

  const [answer, setAnswer] = useState<null | number>(null);

  const submit = () => {
    dispatch(
      generalActions.setOrAppendMarketingData(
        'append',
        'nps_survey',
        JSON.stringify({
          user: userStore.userData.uuid,
          session: session,
          question: answer,
          time: moment().format(),
        })
      )
    );

    callback();
  };

  return (
    <>
      <div className={styles['questions-row']}>
        <NPSQuestion
          setAnswer={(value: number) => setAnswer(value)}
          selected={answer}
        />
      </div>

      <WellButton
        className={btnClass}
        btnOnClick={submit}
        btnText={'NEXT'}
        isLoading={false}
        type={'well-btn-primary'}
      />
    </>
  );
};
