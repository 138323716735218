import {
  ModalBodyWrapper,
  ModalButtonWrapper,
  ModalWrapper,
  WellButton,
  WellInputWrapper,
  GoogleLoginProvider,
  FacebookLoginComponent,
} from '_components';
import {
  useGetImgixVersion,
  useRegister,
  useWellSelector,
} from '_hooks';
import styles from './RegisterModal.module.css';
import { Link } from 'react-router-dom';
import { EQuizAnswers, QUIZ_ANSWERS_TO_DATA } from '_types';
import { history } from '_helpers';

interface IRegisterModal {
  closeModal: () => void;
  isBranded: string;
}

export const RegisterModal = ({ closeModal, isBranded }: IRegisterModal) => {
  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disableOverlay={false}
      disallowClose={isBranded ? true : false}
      wrapperClassName={isBranded ? styles['branded-modal-wrapper'] : ''}
    >
      <>
        <div className={styles['inside-wrapper']}>
          {isBranded && isBranded === 'adaa' ? (
            <AdaaBrandedContent />
          ) : isBranded &&
            (isBranded === 'psi' ||
              isBranded === 'psi_2' ||
              isBranded === 'psi_3') ? (
            <PSIBrandedContent />
          ) : (
            <></>
          )}

          <RegisterForm callback={closeModal} />
        </div>
      </>
    </ModalWrapper>
  );
};

const PSIBrandedContent = () => {
  let logo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partnerships/psi-logo.png',
    {
      h: 350,
      w: 420,
    },
  );

  return (
    <div className={styles['left-wrapper']}>
      <div className={styles['logos']}>
        <div className={styles['']}>
          <img src={logo} className={styles['psi-logo']} alt={'Branded Logo'} />
        </div>
      </div>
      <h2 className={styles['branded-header']}>PSI Online Support Meetings </h2>
      <p className={styles['branded-text']}>
        Our groups are conducted using a peer-to-peer support model to promote
        awareness, prevention and treatment of perinatal mental health.
      </p>
      <p className={styles['branded-text']}>
        Create an account to access PSI's online support groups in partnership
        with ShareWell.
      </p>
      <p className={styles['branded-bottom-notice']}>
        By using ShareWell you agree to receive periodic communication from
        Postpartum Support International about updates and
        new&nbsp;service&nbsp;offerings.
      </p>
    </div>
  );
};

const AdaaBrandedContent = () => {
  let adaaLogo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/partnerships/adaa-logo.jpeg',
    {
      h: 350,
      w: 420,
    },
  );

  let sharewellLogo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/common/logo.svg',
    {
      h: 350,
      w: 420,
    },
  );

  return (
    <div className={styles['left-wrapper']}>
      <div className={styles['logos']}>
        <div className={styles['logo-container']}>
          <img src={adaaLogo} className={styles['logo']} alt={'Branded Logo'} />
        </div>
        <div className={styles['logo-container']}>
          <img
            src={sharewellLogo}
            className={styles['logo']}
            alt={'ShareWell Logo'}
          />
        </div>
      </div>
      <h2 className={styles['branded-header']}>
        Anxiety and Depression <br></br>Peer Support on ShareWell
      </h2>
      <p className={styles['branded-text']}>
        Join dozens of peer support groups and a 24/7 community forum for
        support when you need it.
      </p>
      <div className={styles['quote']}>
        My therapist recommended I seek peer support to help with my depression.
        Being with people who understand the challenges I am experiencing, is a
        huge relief. The conversations empower and encourage me."
      </div>
      <div className={styles['author']}>- Tracy Clark: Peer Testimonial</div>
    </div>
  );
};

export const RegisterForm = ({ callback }: { callback: () => void }) => {
  const {
    data,
    serverError,
    setServerError,
    isLoading,
    setIsLoading,
    errors,
    handleChange,
    inputRefs,
    handleSubmit,
    onKeyDownHandler,
  } = useRegister(callback);

  const { homepageQuizSelection } = useWellSelector(
    (state) => state.componentsStore,
  );

  // additional isLoading is needed here.
  const topicChosen =
    QUIZ_ANSWERS_TO_DATA[homepageQuizSelection as EQuizAnswers].name;

  return (
    <div className={styles['right-wrapper']}>
      <div className={styles['modal-top']}>
        <h2 className={styles['title']}>
          <>Peer support is&nbsp;waiting.</>
        </h2>
        <h3 className={styles['subtitle']}>Create an account to get started</h3>
        <div className={styles['divider']}></div>
      </div>

      <ModalBodyWrapper>
        <div onKeyDown={onKeyDownHandler}>
          {serverError && (
            <div className={styles['server-errors']}>{serverError}</div>
          )}

          <WellInputWrapper
            error={errors.email}
            inputContainerClassName={styles['input-container']}
            errorRef={inputRefs.email}
          >
            <input
              value={data.email.value}
              onChange={handleChange}
              name={'email'}
              placeholder={'Email'}
            />
          </WellInputWrapper>

          <WellInputWrapper
            error={errors.username}
            inputContainerClassName={styles['input-container']}
            errorRef={inputRefs.username}
          >
            <input
              value={data.username.value}
              onChange={handleChange}
              name={'username'}
              placeholder={'Username'}
              maxLength={30}
            />
          </WellInputWrapper>

          <WellInputWrapper
            error={errors.password}
            inputContainerClassName={styles['input-container']}
            errorRef={inputRefs.password}
          >
            <input
              value={data.password.value}
              onChange={handleChange}
              name={'password'}
              placeholder={'Password'}
              type={'password'}
              maxLength={32}
            />
          </WellInputWrapper>

          <div className={styles['oath-login']}>
            <GoogleLoginProvider
              disabled={isLoading}
              setError={setServerError}
              setIsLoading={setIsLoading}
            />
            <FacebookLoginComponent
              disabled={isLoading}
              setError={setServerError}
              setIsLoading={setIsLoading}
            />
          </div>

          <div className={styles['checkbox-container']}>
            By clicking Continue, you confirm that you are over 18 years of age,{' '}
            agree to our{' '}
            <a href={'/terms-and-conditions'} target='_blank' rel='noreferrer'>
              Terms and Conditions
            </a>
            , and acknowledge that you have read our{' '}
            <a href={'/privacy-policy'} target='_blank' rel='noreferrer'>
              Privacy&nbsp;Policy
            </a>
            .
          </div>
        </div>
      </ModalBodyWrapper>

      <ModalButtonWrapper>
        <WellButton
          type={'well-btn-primary'}
          btnOnClick={handleSubmit}
          btnText={'CONTINUE'}
          isLoading={isLoading}
        />
      </ModalButtonWrapper>

      {history.location.pathname !== '/login' && (
        <div className={styles['already-member']}>
          Already have an account?{' '}
          <Link className={styles['login-link']} to={'/login'}>
            Sign in
          </Link>
        </div>
      )}
    </div>
  );
};
