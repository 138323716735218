import { useGetImgixVersion } from '_hooks';
import styles from './Avatar.module.css';


export const Avatar = ({ pic, isSmall }) => {

  const imgixPic = useGetImgixVersion(pic, { h: 100, w: 100, fit: 'crop' });

  return (
    <div
      className={
        styles['avatar-container'] + ' ' + (isSmall && styles['is-small'])
      }
    >
      <img
        src={imgixPic}
        className={styles['avatar'] + ' ' + (isSmall && styles['is-small'])}
        alt={'User Avatar'}
      />
    </div>
  );
};

export const AvatarPlaceholder = () => {
  return (
    <div className={styles['avatar-container']}>
      <div className={styles['avatar'] + ' ' + styles['placeholder']}></div>
    </div>
  );
};
