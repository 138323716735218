import { useState } from 'react';
import styles from './UpcomingSessionBanner.module.css';
import { userSessionsActions } from '_actions';
import Arrow from '_images/arrow.svg';
import { useWellApp, useWellSelector, useWellDispatch } from '_hooks';


export const UpcomingSessionBanner = () => {
  const dispatch = useWellDispatch();

  const { nextSession } = useWellSelector((state) => state.sessionsStore);

  const [isLoading, setIsLoading] = useState(false);

  const { enterSession } = useWellApp();

  const enterRoom = async () => {
    setIsLoading(true);
    const response = await enterSession(nextSession.uuid);
    if (!response) dispatch(userSessionsActions.checkForUpcomingSession());
    setIsLoading(false);
  };

  // session is starting soon
  return (
    <div className={styles['upcoming-session-banner']}>
      <div
        className={styles['upcoming-session-banner-container']}
        onClick={enterRoom}
      >
        <div className={styles['text-container']}>
          <h4 className={styles['starting-notice']}>
            {nextSession.in_session ? (
              <>
                <b>{nextSession.title}</b> has started!
              </>
            ) : (
              <>
                Your Session <b>{nextSession.title}</b> is starting soon
              </>
            )}
          </h4>

          {!isLoading ? (
            <div className={styles['arrow-container']}>
              <img src={Arrow} className={styles['arrow']} alt={'arrow icon'} />
            </div>
          ) : (
            <div className={styles['loading']}>
              Entering<span className={styles['one']}>.</span>
              <span className={styles['two']}>.</span>
              <span className={styles['three']}>.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );


};

