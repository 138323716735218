export const errorMsgs = {
  ANYTHING: '',
  USERNAME_OR_EMAIL_INVALID: 'Invalid username or email',
  EMAIL_INVALID: 'Invalid email',
  USERNAME_INVALID: 'Invalid username',
  PASSWORD_INVALID: 'Passwords must be at least 6 characters long',
  PASSWORD_LENGTH_INVALID: 'Invalid password',
  DISPLAY_NAME_INVALID: 'Invalid display name',
  // sessions
  SESSION_TITLE_INVALID: 'Titles must be at least 3 characters',
  SESSION_DESCRIPTION_INVALID: 'Descriptions must be at least 100 characters',
  WELL_IS_REQUIRED: 'Please pick a well to continue',
  MAX_ATTENDEES_INVALID: '3-16',
  INVALID_START_TIME: 'Start time must be in future',

  MORE_THAN_1_CHARACTERS: 'Names must be at least 1 character',
  INVALID_PHONE: 'Please enter a valid phone number',
  INVALID_EXTERNAL_URL: 'Please enter a valid external link',
  INVALID_CODE: 'Invalid code',
};

export const regexItems = {
  ANYTHING: /.*/,
  MORE_THAN_3_CHARACTERS: /^.{3,}$/,
  MORE_THAN_1_CHARACTERS: /^.{1,}$/,
  EMAIL: /^\S+@\S+\.\S+$/,
  USERNAME: /^(?!_)[a-zA-Z0-9_]{1,29}[a-zA-Z0-9]$/,
  PASSWORD: /^(.){6,32}$/,
  // sessions
  SESSION_TITLE: /^(.){3,50}$/,
  SESSION_DESCRIPTION: /^(.|\n){100,5000}$/,
  MAX_ATTENDEES: /^[2-9]|1[0-6]$/,
  PHONE:
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  EXTERNAL_URL: /(https:\/\/meet\.google\.com\/.+?\b)|(https:\/\/.*zoom\.us\/.+?\b)/,
};
