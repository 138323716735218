import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { emailActions } from '_actions';
import styles from './StaticPages.module.css';
import { history } from '_helpers';
import { Custom404 } from '.';
import { useGetImgixVersion } from '_hooks';
import { WellLoader } from '_components';
import { useCookies } from 'react-cookie';

export const ConfirmEmailSuccess = () => {
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies();

  const wellLogo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/common/well-logo.png',
    {
      w: 200,
      h: 200,
    },
  );

  useEffect(() => {
    let uri = decodeURI(history.location.pathname).split('/');
    let userUUID = uri[2];
    let email = uri[3];
    let password = uri[4];
    if (!userUUID || !password || !email) {
      setIsFailed(true);
      return false;
    }

    dispatch(
      emailActions.confirmEmail(userUUID, email, password, (success) => {
        if (success) {
          setTimeout(() => {
            if (cookies.WELL_INTERESTED_IN_TRAINING)
              window.location = process.env.REACT_APP_WELL_URL + '/thinkific';
            else window.location = process.env.REACT_APP_WELL_URL + '/wells';
          }, 5000);
        } else {
          setIsFailed(true);
        }

        setIsLoading(false);
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && !isFailed) return <WellLoader heightWidth={200} />;

  if (isFailed) return <Custom404 />;

  return (
    <div className={styles['email-page']}>
      <div className={styles['email-container']}>
        <div className={styles['email-logo-container']}>
          <img
            src={wellLogo}
            className={styles['email-logo']}
            alt={'ShareWell Logo'}
          />
        </div>
        <p className={styles['email-text']}>
          Thank you! Your email is confirmed. You'll be redirected to ShareWell
          momentarily.
        </p>
      </div>
    </div>
  );
};

export const EmailUnsubscribe = () => {
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const wellLogo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/common/well-logo.png',
    {
      w: 200,
      h: 200,
    },
  );

  useEffect(() => {
    let uri = decodeURI(history.location.pathname).split('/');
    let email = uri[2];
    let secret = uri[3];
    if (!secret || !email) {
      setIsFailed(true);
      return false;
    }
    dispatch(
      emailActions.unsubscribeEmail(email, secret, (success) => {
        if (success) {
          setTimeout(() => {
            history.push('/wells');
          }, 5000);
        } else setIsFailed(true);

        setIsLoading(false);
      }),
    );
  }, []);

  if (isLoading && !isFailed) return <WellLoader heightWidth={200} />;

  if (isFailed) return <Custom404 />;

  return (
    <div className={styles['email-page']}>
      <div className={styles['email-container']}>
        <div className={styles['email-logo-container']}>
          <img
            src={wellLogo}
            className={styles['email-logo']}
            alt={'ShareWell logo'}
          />
        </div>
        <p className={styles['email-text']}>
          Your email has been unsubscribed. You'll be redirected to ShareWell
          momentarily.
        </p>
      </div>
    </div>
  );
};
