import { handleFetchReturn, failedToFetch } from '..';

export const creatorService = {
  createSession,

  updateSession,
  deleteSession,

  updateSeries,
  updateSeriesEndTime,
  deleteSeries,
  uploadSeriesImage,

  uploadSessionImage,
};

/**
 *    createSession
 *
 * - Sends the data to create a session on the server
 *
 */
export function createSession(formData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/session/create',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function updateSession(sessionUUID, formData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + sessionUUID + '/update',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function updateSeries(series, formData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/series/' + series + '/update',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}


export function updateSeriesEndTime(series, timeJson) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(timeJson),
    credentials: 'include',
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/series/' + series + '/update/end_time',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}



export function deleteSession(sessionUUID) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + sessionUUID + '/delete',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function deleteSeries(seriesUUID) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };
  return fetch(
    process.env.REACT_APP_API_URL + '/series/' + seriesUUID + '/delete',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function uploadSessionImage(sessionUUID, picData) {
  let formData = new FormData();

  formData.append('filename', picData);

  const requestOptions = {
    method: 'POST',
    headers: {},
    credentials: 'include',
    body: formData,
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + sessionUUID + '/update/pic',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function uploadSeriesImage(uuid, picData) {
  let formData = new FormData();

  formData.append('filename', picData);

  const requestOptions = {
    method: 'POST',
    headers: {},
    credentials: 'include',
    body: formData,
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/series/' + uuid + '/update/pic',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
