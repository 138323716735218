import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import modalStyles from './RoomModal.module.css';
import { roomReportingActions } from '_actions';
import { FormControl } from 'react-bootstrap';
import { RoomModal } from '.';
import { Button } from '@material-ui/core';

export const RoomReportModal = ({ closeModal, isUser }) => {
  const dispatch = useDispatch();

  const { roomStore } = useSelector((state) => state);
  const [reason, setReason] = useState('');

  const handleInput = (e) => {
    const newValue = e.target.value;
    setReason(newValue);
  };

  const reportSession = useCallback(() => {
    if (reason === '') return;

    const { uuid } = roomStore.sessionInfo;
    dispatch(roomReportingActions.reportSession(uuid, reason));
    closeModal(false);
  }, [closeModal, dispatch, reason, roomStore.sessionInfo]);

  const reportUser = useCallback(() => {
    if (reason === '') return;

    dispatch(
      roomReportingActions.reportUser(
        isUser,
        reason,
        roomStore.sessionInfo.uuid
      )
    );
    closeModal(false);
  }, [closeModal, dispatch, reason, isUser, roomStore.sessionInfo.uuid]);

  const handleSubmit = useCallback(() => {
    if (isUser) reportUser();
    else reportSession();
  }, [isUser, reportSession, reportUser]);

  return (
    <RoomModal closeModal={closeModal}>
      <div className={modalStyles['inner-modal-container']}>
        <h3 className={modalStyles['inner-modal-title']}>
          {isUser ? (
            <>
              Would you like to report this member, this session, or give
              feedback to the ShareWell team? This will only be shared with
              ShareWell staff.
            </>
          ) : (
            <>Are you sure you want to to report this session?</>
          )}
        </h3>

        <div className={modalStyles['inner-modal-input-container']}>
          <FormControl
            as={'textarea'}
            className={modalStyles['modal-input']}
            onChange={handleInput}
            placeholder={'Reason'}
            autoFocus={true}
            value={reason}
            maxLength={1000}
          />
        </div>

        <div className={modalStyles['inner-buttons-container']}>
          <Button
            color="danger"
            variant="contained"
            className={modalStyles['inner-no-button']}
            onClick={() => closeModal(false)}
          >
            CANCEL
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={
              modalStyles['inner-yes-button'] +
              ' ' +
              (reason === '' ? modalStyles['disabled'] : '')
            }
            onClick={handleSubmit}
          >
            SEND
          </Button>
        </div>
      </div>
    </RoomModal>
  );
};
