import styles from './Panel.module.css';

export const Panel = ({children}) => {

  return (
    <div className={styles['panel-container']}>
      {children}
    </div>
  )
}







export const PanelHeading = ({children}) => {


  return (
    <div className={styles['panel-heading']}>
      {children}
    </div>
  )

}




export const PanelContent = ({children}) => {

  return (
    <div className={styles['panel-content']}>
      {children}
    </div>
  )

}







export const PanelFooter = ({children}) => {

  return (
    <div className={styles['panel-footer']}>
      {children}
    </div>
  )

}