import styles from './SomethingWentWrong.module.css';

export const SomethingWentWrong = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['inside']}>
        <img
          src={
            process.env.REACT_APP_ASSETS_URL + '/site/modal/standard-error.svg'
          }
          className={styles['image']}
          alt={'ShareWell error'}
        />
        <h2>
          Sorry, we are having some technical issues (as you can see). Please
          try to refresh the page, if you are still getting the issue you can
          reach out to{' '}
          <a href="mailto:support@sharewellnow.com">support@sharewellnow.com</a>
          .
        </h2>
      </div>
    </div>
  );
};
