import styles from './ActivityAndAchievements.module.css';

import { BadgeItemHosted, BadgeItemSupported } from '_components'
import { useProfile } from '_hooks';

export const ActivityAndAchievements = () => {

  const { selectedUserData } = useProfile();

  return (
    <div className={styles['container']}>
      <h4 className={styles['section-title']}>Activity & Achievements</h4>
      <div className={styles['badge-container']}>
        <BadgeItemSupported curNumber={selectedUserData.num_session_users_met} />
        <BadgeItemHosted curNumber={selectedUserData.num_sessions_hosted} />
      </div>
    </div>
  )

}

