import { Dispatch } from 'redux';
import { groupService } from '_services';
import { IGroup } from '_types';

export const groupUserActions = {
  getMyGroups,
  getUsersGroups,
  getGroupNotificationPreferences,
};

// gets the logged in user's groups
function getMyGroups(limit: number, offset: number) {
  return async (dispatch: Dispatch<any>): Promise<Array<IGroup> | boolean> => {
    try {
      const response = await groupService.getMyGroups(limit, offset);
      if (response.groups) {
        return Promise.resolve(response.groups);
      }

      return Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(false);
    }
  };
}

// gets the groups that a user is subscribed to
function getUsersGroups(
  uuid: string,
  limit: number,
  offset: number,
  order: string
) {
  return async () => {
    try {
      const response = await groupService.getUsersGroups(
        uuid,
        limit,
        offset,
        order
      );
      if (response.groups) {
        return Promise.resolve(response.groups);
      }

      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

/**
 * Gets the preferences of a group for the signed in user
 * @param  {[string]} uuid - uuid of the group
 */
function getGroupNotificationPreferences(
  uuid: string,
  sessions: null | boolean = null,
  forums: null | boolean = null
) {
  return async () => {
    try {
      const response = await groupService.getGroupNotificationPreferences(
        uuid,
        sessions,
        forums
      );

      if (response.group_notification)
        return Promise.resolve(response.group_notification);

      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
