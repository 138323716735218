import { useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import LocalVideoPreview from '../LocalVideoPreview';
import DeviceSelectionDialog from '../DeviceSelectionDialog';
import useVideoContext from '../../hooks/useVideoContext';
import { RootState } from '_reducers';
import { useWellSelector, useWellDispatch } from '_hooks';
import styles from './PreJoinScreens.module.css';
import logo from '_images/logo-no-text.svg';
import { roomConstants } from '_constants';

interface IPreJoinScreens {
  isForSession: boolean;
}

export default function PreJoinScreens({ isForSession }: IPreJoinScreens) {
  const dispatch = useWellDispatch();

  const { userStore, roomStore } = useWellSelector((state: RootState) => state);

  const { getAudioAndVideoTracks, isAcquiringLocalTracks } = useVideoContext();

  const name = userStore.userData.username;

  const [mediaError, setMediaError] = useState<Error | null>(null);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  const disableButtons = isAcquiringLocalTracks || mediaError;

  useEffect(() => {
    if (mediaError) return;

    getAudioAndVideoTracks()
      .then((res: any) => {})
      .catch((error: any) => {
        setMediaError(error);
      });
  }, [getAudioAndVideoTracks, mediaError]);

  const handleJoin = useCallback(() => {
    dispatch({
      type: roomConstants.SET_HAS_COMPLETED_VIDEO_TEST,
      hasCompletedVideoCheck: true,
    });
  }, [dispatch]);

  return (
    <>
      <div className={styles['pre-join-screen']}>
        <div className={styles['left-side']}>
          <img src={logo} className={styles['logo']} alt={'ShareWell'} />
          <h3 className={styles['left-side-text']} style={{ marginBottom: 0 }}>
            ShareWell
          </h3>
          <h3 className={styles['left-side-text']}>Video Preview</h3>
        </div>
        <div className={styles['right-side']}>
          <div className={styles['errors-container']}>
            {mediaError && mediaError.message ? (
              <h3 className={styles['error-text']}>{mediaError.message}</h3>
            ) : mediaError && !mediaError.message ? (
              <h3 className={styles['error-text']}>
                ShareWell is unable to detect your video settings. Please visit
                our FAQ or contact our support to get video working.
              </h3>
            ) : (
              <></>
            )}
          </div>
          <div className={styles['title-container']}>
            <h3 className={styles['session-title']}>
              {isForSession ? roomStore.sessionInfo.title : <>Video Test</>}
            </h3>
            {isForSession && (
              <p>
                You’re looking great today, make sure your face is in view at
                all times during your session.
              </p>
            )}
          </div>
          <div className={styles['video-container']}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={styles['options-container']}>
            <div className={styles['buttons-container']}>
              <Button
                className={styles['device-settings']}
                variant='contained'
                data-cy-join-now
                onClick={() => setDeviceSettingsOpen(true)}
              >
                Device Settings
              </Button>

              {isForSession && (
                <Button
                  className={
                    styles['join-room'] +
                    ' ' +
                    (disableButtons ? styles['disabled'] : '')
                  }
                  variant='contained'
                  data-cy-join-now
                  onClick={handleJoin}
                  disabled={disableButtons}
                >
                  JOIN ROOM
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
    </>
  );
}
