import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { generalActions } from '_actions';
import { useSessionsList } from '_hooks';

export function useSearchLogging() {
  const dispatch = useDispatch();

  const { searchPhrase } = useSessionsList();
  const { loggedIn, userData } = useSelector((state) => state.userStore);

  const [scrollCheck, setScrollCheck] = useState(false);
  const [timeoutNum, setTimeoutNum] = useState(0);
  const [lastLogged, setLastLogged] = useState('');

  useEffect(() => {
    return () => {
      setScrollCheck(0);
      clearTimeout(timeoutNum);
    };
  }, []);

  useEffect(() => {
    if (searchPhrase === null) return;

    const trimmed = searchPhrase.trim();

    clearTimeout(timeoutNum);

    if (trimmed.length >= 2) {
      setScrollCheck(1);
      setTimeoutNum(setTimeout(searchCapture, 1250));
    } else {
      setScrollCheck(0);
      clearTimeout(timeoutNum);
    }
  }, [searchPhrase]);

  const searchScroll = () => {
    if (!scrollCheck || searchPhrase == lastLogged) return;
    searchCapture();
  };

  const searchCapture = () => {
    if (searchPhrase === lastLogged) return;
    setLastLogged(searchPhrase);
    setScrollCheck(0);
    clearTimeout(timeoutNum);

    let data = { searchPhrase };
    if (loggedIn) {
      dispatch(
        generalActions.setOrAppendMarketingData(
          'append',
          'sessions_search',
          JSON.stringify(data)
        )
      );
      data.user = userData.uuid;
    }
  };

  return searchScroll;
}



