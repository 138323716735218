import React from 'react';
import styles            from './WellPanel.module.css'


export const WellPanel = ({children}) => {
  return (
    <div className={styles['well-panel']}>
      <div className={styles['well-panel-inner']}>
        {children}
      </div>
    </div>
  )

}