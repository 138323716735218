import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './GroupEdit.module.css';
import { groupActions, groupCreatorActions } from '_actions';
import {
  WellLoader,
  WellInput,
  WellTextarea,
  WellButton,
  WellImageInput,
} from '_components';
import { ValidateBatch, history, resizeImage } from '_helpers';
import { toastr } from 'react-redux-toastr';

export const GroupEdit = ({ match }) => {
  const dispatch = useDispatch();
  const { userStore } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [groupData, setGroupData] = useState({});
  const [imageData, setImageData] = useState({
    groupImage: '',
    imageName: '',
  });

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!userStore.loggedIn || !userStore.userData) {
      history.push('/wells');
      return false;
    }

    const { uuid } = match.params;

    //if we are logged in then send the uuid
    const groupResponse = await dispatch(groupActions.getGroupInfo(uuid));

    if (!groupResponse.am_i_creator && !userStore.isAdmin) {
      history.push('/wells');
      return false;
    }

    setFormData({
      title: groupResponse.title,
      description: groupResponse.description,
    });

    if (groupResponse.pic) {
      setImageData({
        groupImage: '',
        imageName: 'current_image.jpg',
      });
    }

    setGroupData(groupResponse);

    setIsLoading(false);
  }, []);

  const onKeyDownHandler = (e) => {
    if (e.key === 'Enter' && !btnIsLoading) {
      e.preventDefault();
      handleSave();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    const { title, description } = formData;

    let validateArray = [
      { value: title, type: 'groupTitle', ref: titleRef.current },
      {
        value: description,
        type: 'groupDescription',
        ref: descriptionRef.current,
      },
    ];

    if (!ValidateBatch(validateArray)) return;

    setBtnIsLoading(true);

    let descriptionNoNewLines = description.replace(/(\r\n|\n|\r)/gm, '');

    const response = await dispatch(
      groupCreatorActions.updateGroup(groupData.uuid, {
        title,
        description: descriptionNoNewLines,
      })
    );

    if (!response.group) {
      setBtnIsLoading(false);
      if (response.notice) toastr.error(response.notice.msg);
    } else {
      if (imageData.groupImage)
        await dispatch(
          groupCreatorActions.updateGroupImage(
            groupData.uuid,
            imageData.groupImage
          )
        );

      history.push(
        '/well/' +
          response.group.title.toLowerCase().replaceAll(' ', '-') +
          '/' +
          response.group.uuid +
          '/sessions'
      );
    }
  };

  const handleImageChange = (event) => {
    setBtnIsLoading(true);

    const groupImageEvent = event.target.files[0];

    resizeImage({ file: event.target.files[0], maxSize: 6000 })
      .then((data) => {
        setImageData({
          groupImage: data,
          imageName: groupImageEvent.name,
        });
        setBtnIsLoading(false);
      })
      .catch((err) => {
        setBtnIsLoading(false);
      });
  };

  if (isLoading) return <WellLoader heightWidth={200} />;

  return (
    <div className={styles['group-edit-page']} onKeyDown={onKeyDownHandler}>
      <div className={styles['form-container']}>
        <h1 className={styles['title']}>Edit Your Well</h1>

        <WellInput
          label={'Title'}
          placeHolder={'Title'}
          onChange={handleChange}
          value={formData.title}
          autoFocus={true}
          maxLength={50}
          readOnly={false}
          name={'title'}
          ref={titleRef}
          className={styles['title-input']}
          showCharacterCount={true}
          showMaxReached={true}
        />

        <WellTextarea
          ref={descriptionRef}
          autoFocus={false}
          value={formData.description}
          readOnly={false}
          onChange={handleChange}
          name={'description'}
          maxLength={5000}
          placeholder={'Description'}
          showCharacterCount={true}
          className={styles['description-input']}
          showMaxReached={true}
          disallowEnter={true}
          label={'Description'}
        />

        <WellImageInput
          className={'s'}
          label={'Group Image'}
          imageName={imageData.imageName}
          handleChange={handleImageChange}
        />

        <WellButton
          className={styles['save-btn']}
          btnOnClick={handleSave}
          type={'well-btn-orange'}
          btnText={'SAVE'}
          isLoading={btnIsLoading}
        />
      </div>
    </div>
  );
};
