import { useState } from 'react';
import modalStyles from './RoomModal.module.css';
import { RoomModal } from '.';
import { Button } from '@material-ui/core';

export const RoomSimpleModal = ({
  closeModal,
  title,
  kickUser,
  yesText,
  noText,
}) => {
  const [reason, setReason] = useState('');
  const [showError, setShowError] = useState(false);
  return (
    <RoomModal closeModal={closeModal}>
      <div className={modalStyles['inner-modal-container']}>
        <h1 className={modalStyles['inner-modal-title']}>Remove Member</h1>
        <h2 className={modalStyles['inner-modal-subtitle']}>
          Are you sure you want to remove this member? This action cannot be
          undone.
          <br />
          Please share a reason for a removal.
        </h2>
        {/* <p className={modalStyles['inner-modal-paragragh']}>{title}</p> */}
        {showError && (
          <p className={modalStyles['error']}>provide reason for removal</p>
        )}

        <textarea
          value={reason}
          onChange={(event) => setReason(event.target.value)}
          className={modalStyles['room-textarea']}
          placeholder='Reason for removing of user'
        />

        <div className={modalStyles['inner-buttons-container']}>
          <Button
            color='danger'
            variant='contained'
            className={modalStyles['inner-no-button']}
            onClick={() => closeModal(false)}
          >
            {noText}
          </Button>

          <Button
            color='primary'
            variant='contained'
            className={modalStyles['inner-yes-button']}
            onClick={() => {
              if (reason == '') {
                setShowError(true);
                return;
              }
              kickUser(reason);
            }}
          >
            {yesText}
          </Button>
        </div>
      </div>
    </RoomModal>
  );
};
