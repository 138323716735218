import { useCallback } from 'react';
import styles from './RoomTimer.module.css';
import { roomMessagingActions, roomActions } from '_actions';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment-timezone';
import { getServerTime } from '_helpers';
import { useWellSelector, useWellDispatch } from '_hooks';
import { roomConstants } from '_constants';
import { ISessionRoomUser } from '_types';

export const BreakoutRoomTimer = () => {
  const dispatch = useWellDispatch();

  const { roomStore, userStore } = useWellSelector((state) => state);
  const { isAdmin } = userStore;
  const { roomData, sessionInfo, breakoutRoomTimer } = roomStore;

  let endTime = moment.tz(breakoutRoomTimer, sessionInfo.timezone).local();

  const closeTimer = () => {
    dispatch({
      type: roomConstants.SET_BREAKOUT_ROOM_TIMER,
      breakoutRoomTimer: false,
    });
  };

  const cancelTimer = () => {
    // cancel the timer
    dispatch(
      roomMessagingActions.setSendHostSignal({
        SHAREWELL_EVENT_BREAKOUT_ROOM_TIMER_STOP: 1,
      })
    );
    // then close the timer
    closeTimer();
  };

  const onComplete = useCallback(() => {
    setTimeout(() => {
      closeTimer();
      if (!sessionInfo.am_i_host) return;
      // everyone gets sent to room 1
      roomData[1].users.map((user: ISessionRoomUser) => {
        dispatch(roomActions.assignToRoom(sessionInfo.uuid, user.uuid, 0));
      });
    }, 2000);
  }, [closeTimer, dispatch, roomData, sessionInfo.am_i_host, sessionInfo.uuid]);

  const renderer = ({ minutes, seconds, completed }: any) => (
    <span>
      {completed ? (
        <span className={styles['end-text']}>{'ROOMS CLOSING'}</span>
      ) : (
        <span>
          Room closes in: {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      )}
    </span>
  );

  return (
    <>
      <div className={styles['session-timer-container']}>
        {sessionInfo.am_i_host || isAdmin ? (
          <div className={styles['close-session-timer']} onClick={cancelTimer}>
            &#10006;
          </div>
        ) : null}
        <Countdown
          date={Date.now() + endTime.diff(getServerTime())}
          renderer={renderer}
          zeroPadTime={2}
          onComplete={onComplete}
        />
      </div>
    </>
  );
};
