import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { WellLoader } from '..';
import styles from './WellButton.module.css';
import CSS from 'csstype';


interface IWellButton {
  isLoading?: boolean;
  type: 'well-btn-primary' |
  'well-btn-secondary' |
  'well-btn-less' |
  'well-btn-enter' |
  'well-btn-new-primary' |
  'well-btn-orange' |
  'well-btn-new-secondary' |
  'well-btn-purchase' |
  'well-btn-red';
  className?: string;
  endingText?: string;
  isEnd?: boolean;
  btnOnClick?: any
  btnText: string;
  icon?: any;
  style?: CSS.Properties;
  disabled?: boolean;
}


interface IWellButtonLink {
  type: 'well-btn-primary' |
  'well-btn-secondary' |
  'well-btn-less' |
  'well-btn-enter' |
  'well-btn-new-primary' |
  'well-btn-orange' |
  'well-btn-new-secondary' |
  'well-btn-purchase' |
  'well-btn-red';
  className?: string;
  to: string;
  btnText: string;
}

export const WellButton = ({
  isLoading,
  type,
  className,
  endingText,
  isEnd,
  btnOnClick,
  btnText,
  icon,
  style,
  disabled
}: IWellButton) => {
  const btnRef = useRef(null);

  if (isLoading)
    return (
      <div
        className={
          styles['well-btn'] +
          ' ' +
          (type ? styles[type] : styles['well-btn-primary']) +
          ' ' +
          className +
          ' ' +
          (disabled ? styles['disabled'] : '')
        }
        onClick={(e) => {
          e.preventDefault();
        }}
        ref={btnRef}
        style={style}
      >
        <span className={styles['inner-btn'] + ' ' + styles['hidden']}>
          {btnText}
        </span>
        <WellLoader />
      </div>
    );

  if (isEnd && endingText)
    return (
      <div
        className={
          styles['well-btn'] +
          ' ' +
          (type ? styles[type] : styles['well-btn-primary']) +
          ' ' +
          className +
          ' ' +
          styles['btn-end']
        }
        style={style}
        onClick={(e) => {
          e.preventDefault();
        }}
        ref={btnRef}
      >
        {endingText}
      </div>
    );

  return (
    <div
      className={
        styles['well-btn'] +
        ' ' +
        (type ? styles[type] : styles['well-btn-primary']) +
        ' ' +
        className +
        ' ' +
        (disabled ? styles['disabled'] : '')
      }
      style={style}
      onClick={btnOnClick}
      ref={btnRef}
    >
      <span className={styles['inner-btn']}>
        {icon ? <img className={styles['icon']} src={icon} alt={'Icon'} /> : null}
        {btnText}
      </span>
    </div>
  );
};

export const WellButtonLink = ({ type, className, to, btnText }: IWellButtonLink) => {
  return (
    <Link
      to={to}
      className={
        styles['well-btn'] +
        ' ' +
        (type ? styles[type] : styles['well-btn-primary']) +
        ' ' +
        className
      }
    >
      <span className={styles['inner-btn']}>{btnText}</span>
    </Link>
  );
};
