import { useState, useCallback, createRef } from 'react';
import { IFormItems, isFormItemString, isFormItemTime } from '_types';
import { Moment } from 'moment';
import { getServerTime } from '_helpers';
import moment from 'moment-timezone';
import { regexItems } from '_constants';

// form validation & change handling
export const useForm = (
  data: IFormItems,
  // setData: Dispatch<SetStateAction<IFormItems>>
  setData: any
) => {
  // dynamic number of refs
  // const inputRefs = useRef(Object.keys(data).map(() => createRef()));
  const inputRefs: any = Object.keys(data).reduce(
    (o, key) => ({ ...o, [key]: createRef() }),
    {}
  );

  // creates new obj with data keys => empty string
  const [errors, setErrors] = useState<any>(
    Object.keys(data).reduce((o, key) => ({ ...o, [key]: '' }), {})
  );

  // remove a single error
  const removeError = useCallback(
    (key) => {
      if (inputRefs[key]?.current)
        inputRefs[key].current.classList.remove('form-input-error');

      setErrors((prev: any) => ({
        ...prev,
        [key]: '',
      }));
    },
    [inputRefs]
  );

  // remove all the errors
  const removeErrors = useCallback(() => {
    Object.keys(data).map((key) => removeError(key));
  }, [data, removeError]);

  // validate a single item
  const validateString = useCallback(
    (key: string) => {
      const { value, validation, errorMsg } = data[key] as any;

      // check if value passes regex
      if (
        validation === regexItems.ANYTHING ||
        (value && value.toString().match(validation) != null)
      )
        return true;

      // set error ref
      if (inputRefs[key]?.current)
        inputRefs[key]?.current.classList.add('form-input-error');

      // set error text
      setErrors((prev: any) => ({
        ...prev,
        [key]: errorMsg,
      }));

      return false;
    },

    [data, inputRefs]
  );

  const validateTime = useCallback(
    (key: string) => {
      const { value, validationBefore, validationAfter, errorMsg } = data[
        key
      ] as any;

      if (
        value &&
        moment.isMoment(value) &&
        value?.isValid() &&
        value?.isAfter(getServerTime()) &&
        value?.isBefore(validationBefore)
      )
        return true;

      // set error ref
      if (inputRefs[key]?.current)
        inputRefs[key]?.current.classList.add('form-input-error');

      // set error text
      setErrors((prev: any) => ({
        ...prev,
        [key]: errorMsg,
      }));
      return false;
    },

    [data, inputRefs]
  );

  // validate every item in the form
  const validateAll = useCallback(
    (skips?: String[]) => {
      removeErrors();

      let toValidateKeys = Object.keys(data);
      if (skips)
        toValidateKeys = toValidateKeys.filter((key) => !skips.includes(key));

      const validationChecks = toValidateKeys.map((key) => {
        if (isFormItemString(data[key])) return validateString(key);
        if (isFormItemTime(data[key])) return validateTime(key);
        return false;
      });

      // if array has false then everything is false
      return !validationChecks.includes(false);
    },
    [data, removeErrors, validateString, validateTime]
  );

  // handle input change here
  const handleChange = useCallback(
    (e: any) => {
      const { value, name } = e.target;

      // on start typing remove error
      removeError(name);

      // set the data
      setData((prev: any) => {
        return {
          ...prev,
          [name]: {
            ...data[name],
            value,
          },
        };
      });
    },
    [data, removeError, setData]
  );

  // manually update the value if this is not a standard input
  const setValueChange = useCallback(
    (value: string | Moment, name: string) => {
      // on start typing remove error
      removeError(name);

      // set the data
      setData((prev: any) => {
        return {
          ...prev,
          [name]: {
            ...data[name],
            value,
          },
        };
      });
    },
    [data, removeError, setData]
  );

  return {
    inputRefs,
    errors,
    validateAll,
    validateString,
    removeErrors,
    removeError,
    handleChange,
    setValueChange,
  };
};
