import styles from './SessionInfoHeader.module.css';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { timeConstants } from '_constants';
import { useGetImgixVersion, useSessionInfo } from '_hooks';
import { getSessionEndTime, getSessionStartTime } from '_helpers';

export const SessionInfoHeader = () => {
  const { sessionInfo } = useSessionInfo();
  const { username } = sessionInfo?.host_user;
  const sessionImage = useGetImgixVersion(sessionInfo.pic, {
    w: 740,
    h: 360,
    fit: 'crop',
    crop: 'entropy',
  });

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['parallax-content-row'] + ' container'}>
          <div className={styles['session-image-container']}>
            <img
              src={sessionImage}
              className={styles['session-image']}
              alt={sessionInfo.title}
            />
          </div>

          <div className={styles['content-container']}>
            {sessionInfo?.group_info && (
              <Link
                to={
                  '/well/' +
                  sessionInfo.group_slug +
                  '/' +
                  sessionInfo.group_uuid +
                  '/sessions'
                }
                className={styles['group-name']}
              >
                {sessionInfo?.group_info.title}
              </Link>
            )}
            <h2 className={styles['title']}>{sessionInfo.title}</h2>
            {!sessionInfo.is_group_hosted && (
              <div className={styles['host']}>
                Hosted By:
                <Link
                  to={'/profile/' + username}
                  className={styles['host-name']}
                >
                  {username}
                </Link>
              </div>
            )}
            <div className={styles['time-container']}>
              {getSessionStartTime(sessionInfo).format(
                timeConstants.dateFormat
              )}
              <br></br>
              <span className={styles['time-time']}>
                {getSessionStartTime(sessionInfo).format(
                  timeConstants.timeFormat
                )}{' '}
                -{' '}
                {getSessionEndTime(sessionInfo).format(
                  timeConstants.timeFormat
                )}
                <span>&nbsp;</span>
                {getSessionStartTime(sessionInfo)
                  .tz(moment.tz.guess())
                  .format('z')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
