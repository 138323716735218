
import { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import styles from './ProfileInputItem.module.css';

import { useProfile } from '_hooks';
import { userActions } from '_actions';

import PencilIcon from '_images/pencil.png';
import SaveIcon from '_images/save-icon.png';
import CancelIcon from '_images/cancel-icon.png';
import { WellInputWrapper } from '_components';


const MAX_CHAR_LENGTH = 1000;
const MAX_CHAR_LENGTH_WEBSITE = 100;


interface IProfileItem {
  title?: string;
  value: string;
  isLink?: boolean;

}

interface IProfileInputItemSelf {
  title?: string;
  startValue: string;
  keyName: string;
  error?: string;
  placeholder: string;
}


// for viewing other people's
export const ProfileItem = ({ title, value, isLink }: IProfileItem) => {

  return (
    <div className={styles['about']}>
      <div className={styles['about-row']}>
        {title && (
          <h3 className={styles['about-title']}>{title}</h3>
        )}

        <AboutText>
          {isLink ?
            <a href={value} className={styles['about-link']}
              target={'_blank'} rel='noreferrer'>
              {value}
            </a>
            :
            <p className={styles['about-text']}>{value}</p>
          }
        </AboutText>
      </div>
    </div>
  );
};


// this for looking at your own profile - allows for editing
export const ProfileInputItemSelf = ({ title, startValue, keyName, error, placeholder }: IProfileInputItemSelf) => {

  const dispatch = useDispatch();

  const { selectedUserData, setSelectedUserData } = useProfile();

  const [value, setValue] = useState(startValue);
  const [isEditing, setIsEditing] = useState(false);
  const [inputTemp, setInputTemp] = useState(value);
  const [hasError, setHasError] = useState(false);


  const MAX_LENGTH = (keyName === 'website' ? MAX_CHAR_LENGTH_WEBSITE : MAX_CHAR_LENGTH);

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (keyName === 'website' && value.includes(' ')) return;
    setInputTemp(value);
  }

  const switchEdit = () => setIsEditing(!isEditing);

  // save the input
  // checks to see if about_me & saves in both about_me & profile_info
  const handleSave = async () => {

    // check website length
    if (inputTemp.length > MAX_LENGTH) return;

    // check that the website is a valid url
    if (keyName === 'website' && inputTemp !== '' && !isValidHttpUrl(inputTemp)) {
      setHasError(true);
      return;
    }

    // update the react state
    setSelectedUserData((prev: any) => {
      return {
        ...selectedUserData,
        user_marketing_json: {
          ...selectedUserData.user_marketing_json,
          profile_info: {
            ...selectedUserData.user_marketing_json.profile_info,
            [keyName]: inputTemp,
          }
        }
      }
    });

    // update the user marketing dump
    await dispatch(userActions.updateProfile({
      ...(keyName === 'about_me' && { about_me: inputTemp }), // conditionally add about_me
      user_marketing: JSON.stringify({
        ...selectedUserData.user_marketing_json,
        profile_info: {
          ...selectedUserData.user_marketing_json.profile_info,
          [keyName]: inputTemp,
        }
      })
    }));

    setValue(inputTemp);
    setIsEditing(false);
    setHasError(false);

  }

  // cancels the input
  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setInputTemp(value);
    setHasError(false);
  }, [value]);


  // check if the url is valid
  const isValidHttpUrl = (string: string): boolean => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }


  return (
    <div className={styles['about']}>
      {title && (
        <h3 className={styles['about-title']}>{title}</h3>
      )}
      <div className={styles['about-row']}>

        <div className={styles['options-container']}>
          {!isEditing && value !== '' ? (
            <img
              src={PencilIcon}
              className={styles['pencil-icon']}
              onClick={switchEdit}
              alt={'Pencil'}
            />
          ) : (
            <>
              <img
                src={CancelIcon}
                className={styles['cancel-icon']}
                onClick={handleCancel}
                alt={'Cancel'}
              />
              <img
                src={SaveIcon}
                className={
                  styles['save-icon'] +
                  ' ' +
                  (inputTemp.length > MAX_LENGTH ? styles['no-save'] : '')
                }
                onClick={handleSave}
                alt={'Save'}
              />
            </>
          )}

        </div>


        {!isEditing && value !== '' ? (
          <AboutText>
            {keyName === 'website' ?
              <a href={value} className={styles['about-link']}
                target={'_blank'} rel='noreferrer'>
                {value}
              </a>
              :
              <p className={styles['about-text']}>{inputTemp}</p>
            }
          </AboutText>
        ) : (
          <>
            <WellInputWrapper>
              <>
                <textarea
                  className={
                    styles['input-textarea'] +
                    ' ' +
                    (keyName === 'website' ? styles['small-textarea'] : '')
                  }
                  onChange={handleChange}
                  aria-label={title ? title : ''}
                  value={inputTemp}
                  maxLength={MAX_LENGTH}
                  placeholder={placeholder}
                />
                {(inputTemp.length > MAX_LENGTH) ?
                  <span className={styles['error']}>Max {MAX_LENGTH} characters</span>
                  : hasError ?
                    <span className={styles['error']}>{error}</span>
                    :
                    <></>
                }
              </>
            </WellInputWrapper>
          </>
        )}
      </div>
    </div>
  );
};


// looking at another user who has nothing yet
export const ProfileInputItemHasNone = ({ title }: { title?: string }) => {

  const { selectedUserData, } = useProfile();

  return (
    <div className={styles['about']}>
      <div className={styles['about-row']}>
        {title && (
          <h3 className={styles['about-title']}>{title}</h3>
        )}
        <p className={styles['about-text'] + ' ' + styles['has-none']}>
          {selectedUserData.username} is a ShareWell member who joined{' '}
          {selectedUserData.age} ago!
        </p>
      </div>
    </div>
  );


};


// allows for Read More
const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    overflow: "hidden",
    WebkitBoxOrient: "vertical"
  }
}));

const AboutText = ({ children }: { children: JSX.Element }) => {

  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  const [needsMore, setNeedsMore] = useState(false);

  const childRef = useRef(null);

  useEffect(() => {
    if (!childRef) return;

    const isTextClamped = (elm: any) => {
      if (elm.scrollHeight !== elm.clientHeight)
        setNeedsMore(true);
    }

    isTextClamped(childRef.current);

  }, [childRef]);


  return (

    <>
      <div
        className={isHidden ? classes.hidden : ''}
        ref={childRef}
      >
        {children}
      </div>
      {needsMore && isHidden &&
        <div
          className={styles['read-more']}
          onClick={() => setIsHidden(!isHidden)}>
          Read More
        </div>
      }

    </>

  )

}
