import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& h3': {
        textAlign: 'center',
        marginBottom: '16px',
      },
      '& p': {
        textAlign: 'center',
        marginBottom: '40px',
        // textAlign: 'center',
      },
    },
    listContainer: {},
    listItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '800px',
      margin: '20px auto',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap',
      },
      '& h4, & p': {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          textAlign: 'left',
        },
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    imageContainer: {
      flexBasis: '100%',
      [theme.breakpoints.up('md')]: {
        marginRight: '40px',
        flexBasis: 'auto',
        maxWidth: 'none',
      },
    },
    imageOne: {
      maxWidth: '100px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
        width: '300px',
      },
    },
    imageTwo: {
      maxWidth: '150px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
        width: '300px',
      },
    },
    imageThree: {
      maxWidth: '90px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
        width: '240px',
        marginRight: '55px',
      },
    },
    image: {},
    buttonContainer: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        marginTop: '32px',
      },
    },
    btn: {
      padding: '10px 20px',
      fontSize: '20px',
    },
  })
);

export default useStyles;
