import { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoomSimpleModal, RoomReportModal } from '_components';
import { roomReportingActions } from '_actions';
import { useClickOutside } from '_hooks';
import styles from './RoomMeatBalls.module.css';

export const SubscriberMeatBalls = ({
  sendSignal,
  uuid,
  isMuted,
  isHost,
  setDropDownOpen,
}) => {
  const { roomStore } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [kickModal, setKickModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const dropDownRef = useRef(null);
  useClickOutside(
    dropDownRef,
    () => setDropDownOpen(false),
    kickModal || reportModal
  );

  const kickUser = useCallback(
    (reason) => {
      dispatch(
        roomReportingActions.kickUser(roomStore.sessionInfo.uuid, uuid, reason)
      );
      setKickModal(false);
    },
    [dispatch, uuid, roomStore.sessionInfo.uuid]
  );

  const muteUser = () => {
    sendSignal('SHAREWELL_EVENT_MUTE_USER', uuid);
    setDropDownOpen(false);
  };

  return (
    <div ref={dropDownRef}>
      {kickModal && (
        <RoomSimpleModal
          closeModal={setKickModal}
          title={
            'Are you sure you want to remove this user? They will not be able to re-enter the session.'
          }
          kickUser={kickUser}
          yesText={'REMOVE'}
          noText={'CANCEL'}
        />
      )}

      {reportModal && (
        <RoomReportModal closeModal={setReportModal} isUser={uuid} />
      )}

      <div className={styles['dropdown']}>
        {isHost && (
          <div className={styles['dropdown-item']} onClick={muteUser}>
            <h4 className={styles['dropdown-text']}>
              {isMuted ? 'Unmute' : 'Mute'}
            </h4>
          </div>
        )}

        {isHost && (
          <div
            className={styles['dropdown-item']}
            onClick={() => setKickModal(true)}
          >
            <h4 className={styles['dropdown-text']}>Remove Member</h4>
          </div>
        )}
        <div
          className={styles['dropdown-item']}
          onClick={() => setReportModal(true)}
        >
          <h4 className={styles['dropdown-text']}>Report / Feedback</h4>
        </div>
      </div>
    </div>
  );
};
