import { useGetImgixVersion, useWellSelector } from '_hooks';
import { WellHeader } from '_components';
import aboutUsTeamData from './AboutUsTeamData';
import styles from './AboutUs.module.css';
import { Link } from 'react-router-dom';

export const AboutUs = () => {
  const { userStore } = useWellSelector((state) => state);
  const { loggedIn } = userStore;

  return (
    <div className={styles['main']}>
      <WellHeader
        title={'Meet the Team'}
        subtitle={'We are a small team passionate about bringing safe'}
        subtitleTwo={'and reliable peer support to the world.'}
        style={{ fill: 'rgba(248, 247, 243, 255)' }}
        headerClass={styles['header-class']}
      />

      <div className={styles['container']}>
        <div
          className={
            styles['team-row'] + ' ' + (!loggedIn ? styles['no-link'] : '')
          }
        >
          {aboutUsTeamData.map((person: IAboutUsTeamMember) => (
            <AboutUsTeamMember
              name={person.name}
              photo={person.photo}
              title={person.title}
              username={person.username}
            />
          ))}
        </div>
      </div>

      <Letter />
    </div>
  );
};

export interface IAboutUsTeamMember {
  name: string;
  photo: string;
  title: string;
  username: string;
}

export const AboutUsTeamMember = ({
  name,
  photo,
  title,
  username,
}: IAboutUsTeamMember) => {
  let photoCropped = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + photo,
    {
      h: 235,
      w: 235,
      fit: 'crop',
      crop: 'faces',
    }
  );

  return (
    <Link className={styles['team-member']} to={'/profile/' + username}>
      <img
        src={photoCropped}
        alt={username}
        className={styles['member-image']}
      />
      <div className={styles['bottom-text']}>
        <h2 className={styles['member-name']}>{name}</h2>
        <h3 className={styles['member-title']}>{title}</h3>
      </div>
    </Link>
  );
};

export function Letter() {
  const ceceImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/static-pages/_cece.jpg',
    {
      w: 2000,
      h: 2000,
      fit: 'faces',
    }
  );

  const bottomRight = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/static-pages/bottom-right.png',
    {
      w: 130,
      h: 130,
      fit: 'faces',
    }
  );

  const topLeft = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/static-pages/top-left.png',
    {
      w: 130,
      h: 130,
      fit: 'faces',
    }
  );

  return (
    <div className={styles['letter-ceo-container']}>
      <div className={styles['letter-ceo']}>
        <img src={topLeft} className={styles['top-left']} alt={'top left'} />

        <div className={styles['section-title']}>Our Story</div>

        <p>Dear ShareWell Members,</p>

        <p>Welcome, we’re so glad you're here.</p>

        <p>
          <b>ShareWell was founded from a deeply personal place.</b> From fall
          of 2019 through October 2020,{' '}
          <b>I was in a severely emotionally abusive relationship</b>. There was
          daily gaslighting, pathological lying, severe manipulation and a final
          discard that left me stranded in a foreign country during a pandemic.{' '}
        </p>

        <p>
          I am forever grateful to my friends, family, therapist and doctor for
          being there for me through that period, and feel extremely fortunate
          to have had such a strong support network. And yet, it was not until I
          spoke with{' '}
          <b>four other women who had survived similar abuse from my ex</b> that
          I was able to share my story vulnerably, fully recognize the pattern
          of abuse, and start working through my shame of having been in the
          relationship.
        </p>

        <p>
          Later, I learned this type of support had a name: <b>peer support</b>.
        </p>

        <p>
          The internet seems to offer many options for connecting you with
          others -- but when I went to look for support online,{' '}
          <b>
            I was shocked to find how difficult it is to find a reliable, high
            quality support group
          </b>
          . I found that available resources are fragmented across different
          non-profits and across discussions on Facebook groups and Reddit
          forums. Both of these formats make it challenging to have a genuine
          exchange of empathy and to build a sense of connection.
        </p>

        <p>
          <b>ShareWell was born to become your home for peer support</b>. We
          believe that everyone in the world should have the option to talk to
          someone who truly understands what they are going through. Bear with
          us as we are just getting started, but rest assured that the team
          behind ShareWell is passionate about making peer support truly
          accessible for anyone in need.{' '}
        </p>

        <div className={styles['signature-container']}>
          <div>
            <img
              src={ceceImage}
              className={styles['community-leader-image']}
              alt={'Community leader'}
            />
          </div>

          <div>
            <img
              src={
                process.env.REACT_APP_ASSETS_URL +
                '/site/static-pages/founder-signature.png'
              }
              className={styles['community-leader-signature']}
              alt={'Cece signature'}
            />
            <div className={styles['cece-name']}>
              <span className={styles['name']}>ShareWell CEO & Founder</span>
            </div>
          </div>
        </div>

        <img
          src={bottomRight}
          className={styles['bottom-right']}
          alt={'Bottom Right Design'}
        />
      </div>
    </div>
  );
}
