import { userTestimonialService } from '_services';
import { Dispatch } from 'redux';

export const userTestimonialActions = {
  createTestimonial,
  deleteTestimonial,
  listTestimonials,
  makeTestimonialPublic,
};

function createTestimonial(
  session: string,
  thumbs: boolean,
  postToForum: boolean,
  value: any
) {
  return async () => {
    try {
      await userTestimonialService.createTestimonial(
        session,
        thumbs,
        postToForum,
        value
      );
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function deleteTestimonial(uuid: string) {
  return async () => {
    try {
      await userTestimonialService.deleteTestimonial(uuid);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function listTestimonials(uuid: string, limit: number, offset: number, isSelf: boolean) {
  return async () => {
    try {
      const response = await userTestimonialService.listTestimonials(uuid, limit, offset, isSelf);
      if (response.testimonials) {
        //parse the value, which is a JSON string
        for (let x = 0; x < response.testimonials.length; x++) {
          response.testimonials[x].value = JSON.parse(
            response.testimonials[x].value
          );
        }
        return Promise.resolve(response.testimonials);
      }
      return Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function makeTestimonialPublic(uuid: string) {
  return async () => {
    try {
      const response = await userTestimonialService.makeTestimonialPublic(uuid);
      return Promise.resolve(response.testimonials);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
