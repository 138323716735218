import {
  WellInputWrapper,
  SessionImageInput,
  GroupSelectInput,
} from '_components';
import { IGroup } from '_types';
import styles from './InputGrouping.module.css';
import { useSessionCreateOrEdit } from '_hooks';
import { useEffect } from 'react';

export const InputGroupingBasic = () => {
  const {
    data,
    nonValidationData,
    setNonValidationData,
    errors,
    handleChange,
    inputRefs,
    setValueChange,
    copy,
    myGroupList,
    editSession,
  } = useSessionCreateOrEdit();

  // if the flag is set then we can auto populate with group title
  useEffect(() => {
    if (myGroupList?.length === 0 || !data.group.flag || copy !== undefined || editSession?.uuid)
      return;

    const selected = myGroupList?.filter(
      (group: IGroup) => group.uuid === data.group.value
    );
    if (!selected || selected.length === 0) return;
    setValueChange(
      'This is a peer support session for ' +
        selected[0].title +
        '. We will share our experience of ' +
        selected[0].title +
        ' for mutual support and healing. All are welcome who have experience of this topic.',
      'description'
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroupList]);

  return (
    <section className={styles['input-grouping']}>
      <h2>Session Details</h2>
      <p>
        Name your session and briefly describe what the session is about. Add
        details that highlight what makes it unique.
      </p>

      <div className={styles['form-container']}>
        <div className={styles['double-inputs']}>
          {!data.group.flag ? (
            <GroupSelectInput
              data={data}
              setValueChange={setValueChange}
              groups={myGroupList}
              error={errors.group}
              errorRef={inputRefs.group}
            />
          ) : (
            <></>
          )}
        </div>

        <WellInputWrapper
          error={errors.title}
          inputContainerClassName={styles['input-container']}
          errorRef={inputRefs.title}
          label={'Title'}
          charCount={data.title.value.length}
        >
          <input
            value={data.title.value}
            onChange={handleChange}
            name={'title'}
            placeholder={'Session Title'}
            maxLength={50}
          />
        </WellInputWrapper>

        <WellInputWrapper
          error={errors.description}
          containerClassName={styles['input-container-textarea']}
          errorRef={inputRefs.description}
          label={'Description'}
          charCount={data.description.value.length}
        >
          <>
            <span className={styles['additional']}>Edit template or create your own.</span>
            <textarea
              className={styles['textarea']}
              value={data.description.value}
              onChange={handleChange}
              name={'description'}
              placeholder={'Description'}
              maxLength={5000}
            />
          </>
        </WellInputWrapper>

        <SessionImageInput
          nonValidationData={nonValidationData}
          setNonValidationData={setNonValidationData}
        />
      </div>
    </section>
  );
};
