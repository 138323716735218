import { useCallback, useState } from 'react';
import styles from './UHLeftMenu.module.css';
import { useUserHome, useMySessionsPagination, useWellSelector } from '_hooks';
import {
  GroupItemList,
  SessionItemListSmall,
  WellButtonLink,
  CollapseIcon,
  OpenIcon
} from '_components';
import { EHostLevel, IGroup, ISession } from '_types';
import { WellLoader } from '_components/WellItems';

export const UHLeftMenu = () => {

  const { userData } = useWellSelector(state => state.userStore);

  let [isOpen, setIsOpen] = useState(false);

  const { myGroupList, selectedGroup, setSelectedGroup } = useUserHome();

  const {
    mySessions,
    isLoadingMySessions
  } = useMySessionsPagination(false);

  const updateSelectedGroup = (uuid: string, name: string) => {
    window.scrollTo(0, 0); //whenever change route scroll to top
    setIsOpen(false);
    setSelectedGroup({
      uuid,
      name
    })
  }

  const handleHamburgerClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen]);

  return (
    <>
      <div className={styles['open-icon']} onClick={handleHamburgerClick}>
        <OpenIcon size={'30px'} fill={'black'} />
      </div>
      <div
        className={
          styles['left'] +
          ' ' +
          (isOpen ? styles['open'] : '')
        }>
        <div className={styles['items-container']}>

          <div className={styles['close-icon']} onClick={handleHamburgerClick}>
            <CollapseIcon size={'30px'} fill={'black'} />
          </div>

          <div className={styles['group-item-container']}>
            <div
              className={
                styles['item-container'] +
                ' ' +
                (selectedGroup.uuid === '' ? styles['selected'] : '')
              }
              onClick={() => updateSelectedGroup('', '')}>
              <span className={styles['list-item-text']}>Home</span>
            </div>


            <div className={styles['divider']}></div>

            <div className={styles['header-container']}>
              <h2 className={styles['title']}>Your Sessions</h2>
            </div>
            <div className={styles['my-sessions-container']}>
              {isLoadingMySessions ?
                <WellLoader heightWidth={25} />
                : mySessions && mySessions.length > 0 ?
                  <>
                    {mySessions.map((session: ISession) => {
                      return (
                        <div
                          className={styles['item-container']}
                          key={'user-homepage-session-' + session.uuid}
                        >
                          <SessionItemListSmall
                            isSeries={false}
                            session={session}
                            key={'user-homepage-session-soon-' + session.uuid}
                          />
                        </div>
                      )
                    })}

                    {userData.host_level > EHostLevel.HOST_LEVEL_0 &&
                      <>
                        <div className={styles['small-divider']}></div>

                        <WellButtonLink
                          to={'/session-create'}
                          className={styles['create-session-btn']}
                          btnText={'CREATE A SESSION'}
                          type={'well-btn-orange'}
                        />
                      </>
                    }

                    {/* <Link className={styles['past-sessions-link']} to={'/past-sessions'}>
                      Past Sessions
                    </Link> */}
                  </>

                  :
                  <>
                    <p className={styles['join-session-text']}>
                      9 out of 10 people feel happier immediately after a peer support session. Join one today!
                    </p>
                    <WellButtonLink
                      to={'/sessions'}
                      className={styles['join-session-btn']}
                      btnText={'JOIN A SESSION'}
                      type={'well-btn-orange'}
                    />
                  </>
              }
            </div>

            <div className={styles['divider']}></div>

            <div className={styles['header-container']}>
              <h2 className={styles['title']}>Your Wells</h2>
            </div>
            {myGroupList.map((group: IGroup) => {
              return (
                <div
                  className={
                    styles['item-container'] +
                    ' ' +
                    (selectedGroup.uuid === group.uuid ? styles['selected'] : '')
                  }
                  onClick={() => updateSelectedGroup(group.uuid, group.title)}
                  key={'user-homepage-group-' + group.uuid}
                >
                  <GroupItemList
                    group={group}
                    onClick={() => updateSelectedGroup(group.uuid, group.title)}
                  />
                </div>
              )
            })}


          </div>

        </div>


      </div>
    </>
  )

}