import styles from './UserItem.module.css';
import { IUser } from '_types';
import { useGetImgixVersion } from '_hooks';
import { Link } from 'react-router-dom';
import { useWellSelector } from '_hooks';

export const UserItem = ({ user }: { user: IUser }) => {

  const { loggedIn } = useWellSelector((state) => state.userStore);
  const { username, pic, about_me, user_marketing_json } = user;

  const userImage = useGetImgixVersion(
    pic ? pic : '',
    { h: 200, w: 200, fit: 'crop' }
  );

  return (

    <Link
      to={'/profile/' + username}
      className={styles['container']}
      style={!loggedIn ? { pointerEvents: 'none' } : undefined}
    >

      <div className={styles['top-container']}>
        <div className={styles['image-container']}>
          <img
            src={userImage}
            className={styles['user-image']}
            alt={username}
          />
        </div>

        <div className={styles['right-container']}>

          <div className={styles['username-container']}>
            <h3 className={styles['username']}>{username}</h3>
          </div>



          {user_marketing_json?.profile_info?.credentials && user_marketing_json?.profile_info?.credentials.length > 0 &&
            <div className={styles['credentials']}>
              {user_marketing_json?.profile_info?.credentials.slice(0, 1).map((text: string) =>
                <div className={styles['credential']}>
                  {text}
                </div>
              )}
            </div>
          }


          <p
            className={styles['about-user'] + ' ' +
              (user_marketing_json?.profile_info?.credentials &&
                user_marketing_json?.profile_info?.credentials.length > 0 ?
                styles['no-margin'] : ''
              )}>
            {about_me}
          </p>

        </div>

      </div>


    </Link>
  )

}



export const UserItemEmpty = () => {


  return (
    <div className={styles['container']}>

      <div className={styles['top-container']}>
        <div className={styles['image-container']}>
          <div className={styles['plus-sign-container']}>
            <div className={styles['plus-sign']}>+</div>
          </div>
        </div>

        <div className={styles['right-container']}>

          <div className={styles['username-container']}>
            <h3 className={styles['username']}>Your Face Here</h3>
          </div>

          <div className={styles['credentials']}>
            <div className={styles['credential']}>
              Community Host
            </div>
          </div>

          <p className={styles['about-user'] + ' ' + styles['no-margin']}>
            Some of your story could be here and inspire others...
          </p>

        </div>

      </div>


    </div>
  )

}
