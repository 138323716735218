
import { useCallback } from 'react';
import Select from 'react-select';
import styles from './SessionTypeSelect.module.css';
import { ESortType } from '_types';



export const SessionTypeSelect = ({ handleChange, sortType }: { handleChange: (newSortType: ESortType) => void, sortType: ESortType }) => {


  const options = [
    {
      label: 'Starting Soon',
      value: ESortType.SORT_TYPE_SOON,
    },
    {
      label: 'Newest',
      value: ESortType.SORT_TYPE_NEW,
    },
  ];

  const handleSelect = useCallback((e: any) => {
    handleChange(e.value);
  }, [handleChange]);


  return (
    <div className={styles['select-container']}>
      <Select
        options={options}
        onChange={handleSelect}
        value={
          sortType === ESortType.SORT_TYPE_SOON
            ? {
              label: 'Starting Soon',
              value: ESortType.SORT_TYPE_SOON,
            }
            : {
              label: 'Newest',
              value: ESortType.SORT_TYPE_NEW,
            }
        }
        // defaultValue={{ label: 'Starting Soon', value: 'soon' }}
        menuPosition={'absolute'}
        menuPlacement={'auto'}
        menuPortalTarget={document.getElementById('root')}
        inputId={'series-repeat-input'}
        isSearchable={false}
      />
    </div>

  )


}