import React from 'react';
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import { WellButtonLink } from '_components';
import { SectionWrapper } from '../Wrappers/SectionWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      textAlign: 'center',
      '& h3': {
        paddingBottom: '32px',
      },
      '& p': {
        paddingBottom: '32px',
        maxWidth: '800px',
        margin: '32px auto 0',
      },
    },
    image: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0 10px',
    },
    content: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    buttonContainer: {
      marginTop: '32px',
    },
    btn: {
      padding: '7px 16px',
      fontSize: '18px',
    },
  })
);

export const WhatPeersAreSaying = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <SectionWrapper>
      <div className={classes.container}>
        <h3>Hear What Peers Are Saying</h3>

        <img
          src={
            'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/what-peers-are-saying.svg'
          }
          className={classes.image}
          alt={'What Peers Are Saying'}
        />

        <p>
          Not ready to join as a Host just yet? Join as a ShareWell community
          member and see why we are the fastest-growing peer support community.
        </p>

        <div className={classes.buttonContainer}>
          <WellButtonLink
            btnText={'LEARN MORE ABOUT SHAREWELL'}
            to={'/how-it-works'}
            type={'well-btn-orange'}
            className={classes.btn}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};
