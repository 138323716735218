import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SectionWrapper } from '../Wrappers/SectionWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    introductionContainer: {
      position: 'relative',
      '& > p': {
        textAlign: 'center',
      },
    },
    noMarginBottom: {
      marginBottom: 0,
    },
  })
);

export const Introduction = () => {
  const classes = useStyles();

  return (
    <SectionWrapper>
      <div className={classes.introductionContainer}>
        <p>
          ShareWell is providing an affordable and accessible mental health
          solution to combat the global mental health crisis and create a
          happier, healthier world. We do this through peer support!
        </p>
        <p className={classes.noMarginBottom}>
          We're redefining mental health support through our vibrant community
          of peer support Hosts. Hosts do not have to be mental health experts
          or have previous facilitation experience; they are only required to
          have similar lived experience with others in their support group.
        </p>
      </div>
    </SectionWrapper>
  );
};
