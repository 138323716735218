import styles from './BlogItem.module.css';
import { useGetImgixVersion } from '_hooks';

export const BlogItem = ({blog}) => {

  let image = useGetImgixVersion(process.env.REACT_APP_ASSETS_URL + blog.image, {
    h: 310,
    w: 310,
    fit:'crop', 
    crop: 'edge',
  });

  return(
      <a href={blog.link} className={styles['blog-item']} target={'_blank'} rel='noreferrer'>
        <div className={styles['blog-item-image-container']}>
          <img src={image} className={styles['blog-item-image']} alt={''}/>
        </div>
        <div className={styles['blog-item-content']}>
          <h4 className={styles['blog-item-category']}>{blog.category}</h4>
          {/*<h4 className={styles['blog-item-date']}>{blog.publishedDate}</h4>*/}
          <h3 className={styles['blog-item-title']}>{blog.title}</h3>
          <h4 className={styles['blog-item-subtitle']}>{blog.subtitle}</h4>
          <h4 className={styles['blog-item-length']}>{blog.publishedDate} · {blog.length}</h4>
        </div>
      </a>
  )
}
