import { useCallback, useEffect, useState } from 'react';
import { ISession } from '_types';
import { userSessionsActions } from '_actions';
import {
  getNowStringFormat,
  getFutureStringFormat,
  getPastStringFormat,
} from '_helpers';
import { useWellDispatch } from '_hooks';

const PAGINATION_LIMIT = 25;

const PAST_ARGS = {
  startTime: getPastStringFormat(),
  endTime: getNowStringFormat(),
  order: 'DESC',
};

const FUTURE_ARGS = {
  startTime: getNowStringFormat(),
  endTime: getFutureStringFormat(),
  order: 'ASC',
};

// gets the sessions that the user is joined to
export const useMySessionsPagination = (isPast: boolean) => {
  const dispatch = useWellDispatch();

  const [mySessions, setMySessions] = useState<ISession[]>([]);
  const [mySessionsPagination, setMySessionsPagination] = useState({
    curOffset: 0,
    isDone: false,
  });
  const [isLoadingMySessions, setIsLoadingMySessions] = useState(true);
  const [isLoadingMoreMySessions, setIsLoadingMoreMySessions] = useState(false);

  useEffect(() => {
    const start = async () => {
      let mySessionsResponse: any = await dispatch(
        userSessionsActions.getMySessions(
          PAGINATION_LIMIT,
          0,
          isPast ? PAST_ARGS : FUTURE_ARGS,
        ),
      );

      if (mySessionsResponse && mySessionsResponse.length > 0) {
        setMySessions(mySessionsResponse);
        setMySessionsPagination({
          curOffset: mySessionsPagination.curOffset + PAGINATION_LIMIT,
          isDone: mySessionsResponse.length === 0,
        });
      }

      setIsLoadingMySessions(false);
    };

    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPast]);

  /**
   * Loads more sessions when the user scrolls to the bottom of the page
   */
  const loadMoreSessions = useCallback(async () => {
    if (mySessionsPagination.isDone || isLoadingMoreMySessions) return false;
    setIsLoadingMoreMySessions(true);

    let response: any = await dispatch(
      userSessionsActions.getMySessions(
        PAGINATION_LIMIT,
        mySessionsPagination.curOffset,
        isPast ? PAST_ARGS : FUTURE_ARGS,
      ),
    );

    if (response) {
      setMySessions(mySessions.concat(response));
      setMySessionsPagination({
        curOffset: mySessionsPagination.curOffset + PAGINATION_LIMIT,
        isDone: response.length === 0,
      });
    }

    setIsLoadingMoreMySessions(false);
  }, [
    dispatch,
    isLoadingMoreMySessions,
    isPast,
    mySessions,
    mySessionsPagination.curOffset,
    mySessionsPagination.isDone,
  ]);

  return {
    mySessions,
    mySessionsPagination,
    // loading
    isLoadingMySessions,
    isLoadingMoreMySessions,
    // fun
    loadMoreSessions,
  };
};
