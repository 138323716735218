import styles from './WellSwitch.module.css';

// TODO: FIX ANY
export const WellSwitch = ({
  isOn,
  handleToggle,
  label,
}: {
  isOn: boolean | undefined;
  handleToggle: () => void;
  label: string;
}) => {
  return (
    <div>
      <span className={styles['label']}>{label}</span>

      <label
        style={{ background: isOn ? '#35C212' : '' }}
        className={styles['react-switch']}
      >
        <input
          checked={isOn}
          onChange={handleToggle}
          className={styles['react-switch-checkbox']}
          type="checkbox"
        />
        <div className={styles['react-switch-button']} />
        <div className={styles['react-switch-labels']}>
          <span style={{ display: !isOn ? 'none' : '' }}>ON</span>
          <span style={{ display: isOn ? 'none' : '' }}>OFF</span>
        </div>
      </label>
    </div>
  );
};

