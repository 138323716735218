import { useContext } from 'react';
import { UserHomeContext } from 'UserHome';

export const useUserHome = () => {
  const context = useContext(UserHomeContext);
  if (context === undefined) {
    throw new Error('useUserHome must be used within a UserHomeContext');
  }
  return context;
};
