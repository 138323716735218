import { handleFetchReturn, failedToFetch } from '..';

export const sessionsService = {
  getSessionInfo,
  getSessionsList,
  joinOrLeaveSession,
  getSessionsInSeries,
};

// asks the server for the information for a room in a session
export function pollSessionWaiting(session) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + session + '/room/wait',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

/**
 *
 * - sends request to server to get a list of sessions from the server
 * - excludes sessions that the user has already joined
 *
 */
export function getSessionsList(
  searchQuery,
  limit,
  offset,
  startDateTime,
  endDateTime,
  group = '',
  sortType,
  paidOnly,
  excludeFullSessions
) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/list/search?q=' +
      encodeURIComponent(searchQuery) +
      '&limit=' +
      limit +
      '&offset=' +
      offset +
      '&start_time_from=' +
      startDateTime +
      '&start_time_to=' +
      endDateTime +
      '&group_uuid=' +
      group +
      '&sort_type=' +
      sortType +
      '&paid_only=' +
      paidOnly +
      '&exclude_full_sessions=' +
      excludeFullSessions,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function joinOrLeaveSession(session, isJoin) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  let url =
    process.env.REACT_APP_API_URL + '/session/' + session + '/join/' + isJoin;

  return fetch(url, requestOptions)
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getSessionInfo(uuid) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + uuid + '/get',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function getSessionsInSeries(uuid, limit, offset, order) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/list/series/' +
      uuid +
      '?limit=' +
      limit +
      '&offset=' +
      offset +
      '&order=' +
      order,
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
