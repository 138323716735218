import { useState, useCallback, useEffect } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useWellDispatch } from '_hooks';
import { IPaymentErrors, ICardType } from '_types';
import { stripeActions } from '_actions';

export const useStripeCards = (usePrevCards: boolean) => {
  const dispatch = useWellDispatch();

  const [isLoadingStripeCards, setIsLoadingStripeCards] = useState(false);
  const [errors, setErrors] = useState<IPaymentErrors>({
    general: '',
    number: '',
    expiryDate: '',
    cvc: '',
  });

  const [prevCards, setPrevCards] = useState<ICardType[] | false>(false); // false will mean it is loading
  const [usingPrevCard, setUsingPrevCard] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const handleAddCard = useCallback(() => {
    if (!elements || !stripe) return Promise.reject();

    const cardNumber = elements.getElement('cardNumber');
    const cardExpiry = elements.getElement('cardExpiry');
    const cardCvc = elements.getElement('cardCvc');

    if (!cardNumber || !cardExpiry || !cardCvc) {
      setErrors({
        ...errors,
        general: 'Make sure all card fields are filled.',
      });
      return Promise.reject();
    }
    setIsLoadingStripeCards(true);

    return stripe
      .createToken(cardNumber)
      .then(async (result) => {
        // handle errors
        if (result.error) {
          setErrors({ ...errors, general: result.error.message });
          return Promise.reject();
        }

        if (!result || !result.token) return Promise.reject();

        const cardInfo = await dispatch(
          stripeActions.sendCardInfo(result?.token?.id)
        );

        // error
        if (cardInfo?.msg) {
          setErrors({ ...errors, general: cardInfo.msg });
          return Promise.reject();
        }

        return Promise.resolve();
      })
      .catch((err) => {
        setErrors({
          ...errors,
          general: 'There was an error processing your card.',
        });

        return Promise.reject();
      })
      .finally(() => {
        setIsLoadingStripeCards(false);
      });
  }, [dispatch, elements, errors, stripe]);

  // check for errors when the card number is changed
  const handleCardNumberChange = (ev: any) => {
    ev.error
      ? setErrors({ ...errors, number: ev.error.message })
      : setErrors({ ...errors, number: '' });
  };

  // always get the previous cards
  useEffect(() => {
    const start = async () => {
      const prevCards = await dispatch(stripeActions.getCards());
      setPrevCards(prevCards); // always set, could be empty list
      if (prevCards && prevCards.length > 1) setUsingPrevCard(true);
    };

    if (usePrevCards) start();
  }, [dispatch, usePrevCards]);

  return {
    isLoadingStripeCards,
    errors,
    setErrors,
    handleAddCard,
    usingPrevCard,
    setUsingPrevCard,
    prevCards,
    handleCardNumberChange,
  };
};
