
import { useContext } from 'react';
import { ProfileContext } from 'User';

// CONTEXT
export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileContext');
  }
  return context;
};





