import { makeStyles, createStyles } from '@material-ui/styles';

type Props = {
  width: string;
  height: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    addAttachment: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(15, 20, 25, 0.1)',
      },
    }
  })
);



export const ArrowIcon: React.FC<Props> = ({width, height}) => {

  const classes = useStyles();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} zoomAndPan="magnify" viewBox="0 0 375 374.999991" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="id1"><path d="M 3 19 L 367.5 19 L 367.5 356.25 L 3 356.25 Z M 3 19 " clipRule="nonzero" /></clipPath></defs><g clipPath="url(#id1)"><path fill="#ffffff" d="M 11.550781 356.273438 C 11.25 356.273438 10.949219 356.25 10.644531 356.203125 C 8.296875 355.820312 6.445312 354.007812 6.011719 351.667969 C 5.390625 348.339844 0.128906 318.027344 5.5625 279.25 C 8.65625 257.191406 14.621094 236.308594 23.289062 217.183594 C 34.023438 193.496094 48.972656 172.394531 67.726562 154.472656 C 109.414062 114.632812 157.898438 101.3125 192.910156 97.371094 L 192.910156 24.835938 C 192.910156 22.585938 194.25 20.550781 196.316406 19.660156 C 198.378906 18.769531 200.777344 19.195312 202.414062 20.738281 L 365.582031 174.757812 C 366.710938 175.824219 367.347656 177.304688 367.347656 178.855469 C 367.347656 180.40625 366.710938 181.890625 365.582031 182.953125 L 202.414062 336.984375 C 200.777344 338.527344 198.378906 338.953125 196.316406 338.058594 C 194.25 337.167969 192.910156 335.136719 192.910156 332.886719 L 192.910156 246.84375 C 161.066406 247.484375 130.832031 255.042969 102.953125 269.34375 C 54.414062 294.242188 27.9375 332.675781 16.476562 353.371094 C 15.472656 355.179688 13.574219 356.273438 11.550781 356.273438 Z M 11.550781 356.273438 " fillOpacity="1" fillRule="nonzero" /></g></svg>
  );
};
