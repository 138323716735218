import { useCallback, useState } from 'react';
import styles from './BlockUserModal.module.css';
import { useWellDispatch } from '_hooks';
import {
  ModalWrapper,
  ModalBodyWrapper,
  WellButton,
  WellInputWrapper,
} from '_components';
import { roomReportingActions } from '_actions';
import { IUser } from '_types';
import { AnalyticsSendEvent } from '_helpers';

export const BlockUserModal = ({
  blockiee,
  callback,
  closeModal,
}: {
  blockiee: IUser;
  callback?: () => void;
  closeModal: () => void;
}) => {
  const dispatch = useWellDispatch();

  const [phase, setPhase] = useState(0);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const blockUser = useCallback(async () => {
    setIsLoading(true);

    await dispatch(
      roomReportingActions.blockUser(blockiee.uuid, 'true', reason)
    );
    AnalyticsSendEvent('users_user_block', {
      blocked: blockiee.uuid,
    });

    if (callback) callback();
    closeModal();
  }, [blockiee.uuid, callback, closeModal, dispatch, reason]);

  return (
    <ModalWrapper
      isLoading={isLoading}
      closeModal={closeModal}
      disallowClose={isLoading}
    >
      <>
        <ModalBodyWrapper>
          <div className={styles['main']}>
            <h3>Restrict {blockiee.username}</h3>
            {phase === 0 ? (
              <div>
                <p>
                  Restricting means neither of you will be able to join a
                  session with the other, and neither of you will see sessions
                  the other person is registered for. We will not notify this
                  person that they were restricted; you can unrestrict anytime
                  by returning their profile or contacting the ShareWell team.
                </p>
                <div className={styles['btn-container']}>
                  <WellButton
                    type={'well-btn-primary'}
                    btnText={'CANCEL'}
                    btnOnClick={closeModal}
                  />
                  <WellButton
                    type={'well-btn-red'}
                    btnText={'CONTINUE'}
                    btnOnClick={() => setPhase(1)}
                  />
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Are you sure you want to restrict? Restricting can be used for
                  a number of reasons. In case of a person using harassing,
                  hateful, sexually explicit, or vulgar language, we ask you to
                  please elaborate so the ShareWell team can ensure the safety
                  of other members.
                </p>
                <WellInputWrapper
                  error={''}
                  inputContainerClassName={styles['input-container']}
                >
                  <textarea
                    className={styles['textarea']}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    name={'reaon'}
                    placeholder={'Reason...'}
                  />
                </WellInputWrapper>

                <div className={styles['btn-container']}>
                  <WellButton
                    type={'well-btn-primary'}
                    btnText={'CANCEL'}
                    btnOnClick={closeModal}
                  />
                  <WellButton
                    type={'well-btn-red'}
                    btnText={'RESTRICT'}
                    btnOnClick={blockUser}
                  />
                </div>
              </div>
            )}
          </div>
        </ModalBodyWrapper>
      </>
    </ModalWrapper>
  );
};
