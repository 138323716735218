// might want to move this out of HOC eventually
import { useEffect, useState } from 'react';
import { ModalBodyWrapper, ModalWrapper } from '_components';
import { checkCookie } from '_helpers';
import { useModalStyles } from './Modals.styles';

export const CookiesEnabledModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!checkCookie()) setShowModal(true);
  }, []);

  const classes = useModalStyles();

  if (!showModal) return <></>;

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={() => {}}
      disableOverlay={false}
      disallowClose={true}
    >
      <>
        <div className={classes.imageContainer}>
          <img
            src={
              'https://sharewell-space-dev.sfo3.digitaloceanspaces.com/site/modal/standard-error.svg'
            }
            className={classes.image}
            alt={'ShareWell error'}
          />
        </div>
        <ModalBodyWrapper>
          <p className={classes.mainP}>
            ShareWell requires cookies to be enabled in order to be used. Please
            enable cookies then reload this page.
          </p>
        </ModalBodyWrapper>
      </>
    </ModalWrapper>
  );
};
