import { useState, useEffect, useCallback } from 'react';
import styles from './BreakoutRooms.module.css';
import { useGetImgixVersion, useWellApp } from '_hooks';
import { IUser } from '_types';

interface IBreakoutRoomPerson {
  user: string;
  roomNumber: number;
  clickPersonToNewRoom: (user: string, currentRoom: number) => void;
}

export const BreakoutRoomPerson = ({
  user,
  roomNumber,
  clickPersonToNewRoom,
}: IBreakoutRoomPerson) => {
  const { getUserInformation } = useWellApp();
  const [userData, setUserData] = useState({} as IUser);

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserInformation(user, false);
      if (response) setUserData(response);
    };

    getUser();
  }, []);

  const userImage = useGetImgixVersion(userData.pic, {
    w: 45,
    h: 45,
    fit: 'crop',
    crop: 'focalpoint',
  });

  const handleClick = useCallback(() => {
    clickPersonToNewRoom(user, roomNumber);
  }, [clickPersonToNewRoom, roomNumber, user]);

  return (
    <div className={'col-6 col-sm-3 col-md-4'}>
      <div className={styles['breakout-room-person']} onClick={handleClick}>
        <img className={styles['person-pic']} src={userImage} alt={'User'} />
        <div className={styles['person-name-container']}>
          <h4 className={styles['person-username']}>{userData.name}</h4>
        </div>
      </div>
    </div>
  );
};
