import { WellInputWrapper } from '_components';

export const SessionMaxAttendeesInput = ({
  nonValidationData,
  setNonValidationData,
}: any) => {
  // do not allow 'e'
  const handleKeyPress = (e: any) => {
    if ((e.which !== 8 && e.which !== 0 && e.which < 48) || e.which > 57)
      e.preventDefault();
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (value > 16) return;
    setNonValidationData({ ...nonValidationData, maxAttendees: value });
  };

  return (
    <WellInputWrapper label={'Max Attendees'}>
      <input
        type={'number'}
        value={nonValidationData.maxAttendees}
        onChange={handleChange}
        name={'maxAttendees'}
        placeholder={'Max Attendees'}
        min={3}
        max={16}
        onKeyPress={handleKeyPress}
      />
    </WellInputWrapper>
  );
};
