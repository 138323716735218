import { stripeService } from '_services';
import { sendSentryError } from '_helpers';
import {
  ISubscriptionUpdate,
  ISubscriptionType,
  IPurchaseSubscriptionGift,
  IDiscountCode,
} from '_types';
import { userActions } from '_actions';
import { AppDispatch } from '_reducers';
import { stripeConstants } from '_constants';

export const stripeActions = {
  // credit cards
  getCards,
  sendCardInfo,
  // subscription
  updateSubscription,
  getSubscriptionActivity,
  // discount codes
  claimDiscountCode,
  // billing options
  listSubscriptionTypes,
  // gift
  purchaseSubscriptionGift,
};

function getCards() {
  return async () => {
    try {
      const response = await stripeService.getCards();
      if (response?.cards) return Promise.resolve(response?.cards);
      return Promise.resolve([]);
    } catch (error) {
      sendSentryError(error);
      return Promise.resolve([]);
    }
  };
}

function sendCardInfo(token: string) {
  return async () => {
    try {
      const response = await stripeService.sendCardInfo(token);
      if (response?.notice) return Promise.resolve(response.notice);

      return Promise.resolve(true);
    } catch (error) {
      sendSentryError(error);
      return Promise.resolve(false);
    }
  };
}

function updateSubscription(subscriptionUpdateRequest: ISubscriptionUpdate) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await stripeService.updateSubscription(
        subscriptionUpdateRequest
      );
      await dispatch(userActions.getProfileInfo(false));

      return Promise.resolve(response.subscription);
    } catch (error) {
      return Promise.reject();
    }
  };
}

function getSubscriptionActivity() {
  return async () => {
    try {
      const response = await stripeService.getSubscriptionActivity();
      if (response.activity) return Promise.resolve(response.activity);
      return Promise.reject();
    } catch (error) {
      sendSentryError(error);
      return Promise.reject();
    }
  };
}

function claimDiscountCode(code: string, claim: boolean) {
  return async () => {
    try {
      const response = await stripeService.claimDiscountCode(code, claim);
      if (response.notice) return Promise.reject(response.notice.msg);

      if (response.discount_code)
        return Promise.resolve(response.discount_code as IDiscountCode);

      return Promise.reject();
    } catch (error) {
      sendSentryError(error);
      return Promise.reject();
    }
  };
}

function listSubscriptionTypes() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await stripeService.listSubscriptionTypes();
      if (response.notice) return Promise.reject(response.notice.msg);

      if (response.subscription_types) {
        dispatch({
          type: stripeConstants.SET_SUBSCRIPTION_TYPES,
          subscriptionTypes: response.subscription_types,
        });
        return Promise.resolve(
          response.subscription_types as ISubscriptionType[]
        );
      }

      return Promise.reject('Something went wrong.');
    } catch (error) {
      sendSentryError(error);
      return Promise.reject();
    }
  };
}

function purchaseSubscriptionGift(
  subscriptionType: number,
  purchaseGiftRequest: IPurchaseSubscriptionGift
) {
  return async () => {
    try {
      const response = await stripeService.purchaseSubscriptionGift(
        subscriptionType,
        purchaseGiftRequest
      );

      return Promise.resolve();

      return Promise.reject('Something went wrong.');
    } catch (error) {
      sendSentryError(error);
      return Promise.reject();
    }
  };
}
