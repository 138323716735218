import { useState, useEffect } from 'react';
import styles from './JoinedStatus.module.css';
import { ISession } from '_types';
import NewBanner from '_images/new-badge.svg';

export const JoinedStatus = ({
  session,
  className,
  isSmall,
}: {
  session: ISession;
  className: string;
  isSmall: boolean;
}) => {
  const [color, setColor] = useState('');

  const { status, was_seen } = session;

  useEffect(() => {
    if (status === 'Canceled' || status === 'Over' || status === 'Full')
      setColor(styles['joined-status-red-background']);
    else if (status === 'Going')
      setColor(styles['joined-status-green-background']);
    else if (status === 'Hosting')
      setColor(styles['joined-status-orange-background']);
  }, [status, was_seen]);

  if (status === null && was_seen === false)
    return (
      <div className={styles['new-banner-container']}>
        <img
          src={NewBanner}
          className={styles['new-banner']}
          alt={'New banner'}
        />
      </div>
    );
  else if (!status || (isSmall && (status === 'Canceled' || status === 'Over')))
    return <></>;

  return (
    <div className={className + ' ' + color + ' ' + styles['joined']}>
      <span className={styles['status']}>{status}</span>
    </div>
  );
};

export const JoinedStatusNew = ({
  session,
  className,
}: {
  session: ISession;
  className: string;
}) => {
  const [color, setColor] = useState('');
  const [text, setText] = useState('');

  const { status, was_seen } = session;

  useEffect(() => {
    if (status === 'Canceled' || status === 'Over' || status === 'Full')
      setColor(styles['joined-status-red-background']);
    else if (status === 'Going')
      setColor(styles['joined-status-green-background']);
    else if (status === 'Hosting')
      setColor(styles['joined-status-orange-background']);
    else if (session.external_url) {
      setText('Open');
      setColor(styles['joined-status-grey-background']);
    } else if (session.num_spots_left === 1) {
      setText(session.num_spots_left + ' spot left');
      setColor(styles['joined-status-grey-background']);
    } else if (session.num_spots_left === 0) {
      setText('FULL');
      setColor(styles['joined-status-grey-background']);
    } else {
      setText(session.num_spots_left + ' spots left');
      setColor(styles['joined-status-grey-background']);
    }
  }, [session.num_spots_left, status, was_seen]);

  if ((!status && !text) || status === 'Canceled' || status === 'Over')
    return <></>;

  return (
    <div className={className + ' ' + color + ' ' + styles['joined']}>
      <span className={styles['status']}>{text ? text : status}</span>
    </div>
  );
};
