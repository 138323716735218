import { handleFetchReturn, failedToFetch } from '.';
import { EHostLevel } from '_types';

export const adminService = {
  getAdminDashboard,
  getFlaggedData,
  listDiscountCodes,
  createDiscountCode,
  updateDiscountCode,
  getSessionDataAdmin,
  adminUserSearch,
  getUserData,
  getUserActivity,
  updateUserAccount,
  loginAsAnotherUser,
  adminSetSubscriptionInactive,
};

function getAdminDashboard(period: string, limit: number, offset: number) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/admin/dashboard/' +
      period +
      '?limit=' +
      limit +
      '&offset=' +
      offset,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  ).then((response) => handleFetchReturn(response));
  // .catch(error => failedToFetch(error));
}

function getFlaggedData(limit: number, offset: number) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/admin/activity/types/USER_FLAG_SESSION,USER_FLAG_USER,USER_BLOCK_USER?limit=' +
      limit +
      '&offset=' +
      offset,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  ).then((response) => handleFetchReturn(response));
  // .catch(error => failedToFetch(error));
}

function listDiscountCodes() {
  return fetch(process.env.REACT_APP_API_URL + '/admin/discount_code/list', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  }).then((response) => handleFetchReturn(response));
  // .catch(error => failedToFetch(error));
}

function createDiscountCode(
  monthsOff: number,
  code: string,
  isGift: boolean,
) {
  let payload: any = {
    months_off: monthsOff,
    is_gift: isGift,
  };

  if (code && code.length > 5) payload.code = code;

  return fetch(process.env.REACT_APP_API_URL + '/admin/discount_code/create', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(payload),
  }).then((response) => handleFetchReturn(response));
  // .catch(error => failedToFetch(error));
}

function updateDiscountCode(discountCode: number, isActive: boolean) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/admin/discount_code/' +
      discountCode +
      '/status/' +
      isActive,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  ).then((response) => handleFetchReturn(response));
  // .catch(error => failedToFetch(error));
}

// get admin info about a session
function getSessionDataAdmin(uuid: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/admin/session/' + uuid + '/get',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

function adminUserSearch(
  limit: number,
  offset: number,
  hostLevel: EHostLevel | null,
  search: string
) {
  let request =
    process.env.REACT_APP_API_URL +
    '/admin/account/list?limit=' +
    limit +
    '&offset=' +
    offset +
    '&q=' +
    search;

  if (hostLevel) request += '&host_level=' + hostLevel;

  return fetch(request, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

function getUserData(uuid: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/admin/account/' + uuid + '/get',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  ).then((response) => handleFetchReturn(response));
  // .catch(error => failedToFetch(error)); // in case the uuid is invalid
}

function getUserActivity(uuid: string, limit: number, offset: number) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/admin/account/' +
      uuid +
      '/activity?limit=' +
      limit +
      '&offset=' +
      offset,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

function updateUserAccount(
  uuid: string,
  hostLevel: EHostLevel,
  status: string
) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/admin/account/' +
      uuid +
      '/profile/update',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ host_level: hostLevel, status}),
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

function loginAsAnotherUser(uuid: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/admin/account/' + uuid + '/login_as',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}



function adminSetSubscriptionInactive(uuid: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/admin/account/' + uuid + '/set_subscription_inactive',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}



