import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      width: '100%',
      backgroundSize: 'cover',
      [theme.breakpoints.up('md')]: {
        backgroundSize: 'contain',
      },
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      padding: '32px 0 0',
    },
    titleContainer: {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
    title: {
      marginBottom: '16px',
    },
    specialBreak: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    subtitle: {
      maxWidth: '555px',
    },
    buttonContainer: {
      marginTop: '32px',
      marginBottom: '16px',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    imageContainer: {
      position: 'relative',
      flexBasis: '50%',
      display: 'none',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        paddingTop: '21px',
      },
    },
    mastheadImage: {
      maxWidth: '420px',
    },
    btn:{
      padding: '10px 20px',
      fontSize: '20px',
    }
  })
);

export default useStyles;
