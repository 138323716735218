import { generalConstants } from '_constants';
import { AnyAction } from 'redux';

let defaultState = {
  imgix_domain: 'sharewell.imgix.net',
  imgix_token: undefined,
  polling_freq_sec: 3,
  server_time_offset: 0,
  client_featured_group_uuids: [],
  client_featured_sessions_uuids: [],
};

export function generalStore(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case generalConstants.SET_SERVER_CONFIG:
      let newConfig = action.config;
      let newState = { ...state };
      if (newConfig['client_polling_freq_sec'])
        newState['polling_freq_sec'] = newConfig['client_polling_freq_sec'];
      if (newConfig['client_imgix_domain'])
        newState['imgix_domain'] = newConfig['client_imgix_domain'];
      if (newConfig['client_imgix_token'])
        newState['imgix_token'] = newConfig['client_imgix_token'];

      if (newConfig['client_featured_group_uuids'])
        newState['client_featured_group_uuids'] =
          newConfig.client_featured_group_uuids.split(';');

      if (newConfig['client_featured_sessions_uuids'])
        newState['client_featured_sessions_uuids'] =
          newConfig.client_featured_sessions_uuids.split(';');

      if (newConfig['server_time'])
        newState['server_time_offset'] = Date.now() - newConfig['server_time'];

      return newState;

    case generalConstants.SET_SERVER_TIME_OFFSET:
      return { ...state, server_time_offset: Date.now() - action.epoch };

    default:
      return state;
  }
}
