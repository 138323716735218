import { groupService } from '_services';
import { IGroupCreateRequest } from '_types';

export const groupCreatorActions = {
  createGroup,
  updateGroup,
  updateGroupImage,
  deleteGroup,
  removeUser,
};

function createGroup(formData: IGroupCreateRequest) {
  return async () => {
    try {
      const response = await groupService.createGroup(formData);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(-1);
    }
  };
}

//Admin and group creator can call this
function updateGroup(uuid: string, formData: IGroupCreateRequest) {
  return async () => {
    try {
      const response = await groupService.updateGroup(uuid, formData);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(-1);
    }
  };
}

//Admin and group creator can call this
function updateGroupImage(uuid: string, picData: any) {
  return async () => {
    try {
      const response = await groupService.updateGroupImage(uuid, picData);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(-1);
    }
  };
}

function deleteGroup(uuid: string) {
  return async () => {
    try {
      const response = await groupService.deleteGroup(uuid);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function removeUser(group: string, user: string) {
  return async () => {
    try {
      await groupService.removeUser(group, user);
      return Promise.resolve(1);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
