import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { groupActions, groupForumActions } from '_actions';
import { IGroupWithHost, IActivity } from '_types';
import { useWellSelector, useWellDispatch } from '_hooks';

// http://web-dev.sharewellnow.com:8080/well/4180f934-eadc-44f5-9f01-b8dba58d4730/discussion

export const useGroupInfoState = () => {
  const dispatch = useWellDispatch();
  const { userStore } = useWellSelector((state) => state);
  const { uuid, post, title, tab } = useParams<{
    uuid: string;
    tab: string;
    post: string;
    title: string;
  }>();

  const [group, setGroup] = useState<IGroupWithHost>({} as IGroupWithHost);
  const [isLoading, setIsLoading] = useState(true);
  const [show404, setShow404] = useState(false);
  const [showingPost, setShowingPost] = useState<IActivity | boolean>(false);

  // gets the group info on page load
  useEffect(() => {
    const start = async () => {
      try {
        // if we are logged in then send the uuid
        const groupResponse = await dispatch(
          groupActions.getGroupInfo(uuid.length == 36 ? uuid : title)
        );
        setGroup(groupResponse);
      } catch (error) {
        setShow404(true);
      } finally {
        setIsLoading(false);
      }
    };

    start();
  }, [dispatch, uuid]);

  // checks if their is a single post we need to get
  useEffect(() => {
    if (!userStore.loggedIn || !group?.am_i_joined) return;
    if ((uuid.length == 36 && !post) || (uuid.length != 36 && !tab)) return;

    const getPost = async () => {
      let res: any = await dispatch(
        groupForumActions.postGet(uuid.length == 36 ? post : tab)
      );

      let asdf = uuid.length == 36 ? uuid : title;
      if (res && res?.group_uuid === asdf) {
        if (res.parent_uuid)
          res = await dispatch(groupForumActions.postGet(res.parent_uuid));
        setShowingPost(res);
      }
    };

    getPost();
  }, [dispatch, post, userStore.loggedIn, uuid, group]);

  return {
    group,
    isLoading,
    show404,
    showingPost,

    //state functions
    setGroup,
    setIsLoading,
    setShow404,
    setShowingPost,
  };
};
