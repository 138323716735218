import React from 'react';

import { GalleryProps, useMessageContext, StreamMessage } from 'stream-chat-react';
import { LocalAttachmentType } from '_types';
import { makeStyles, createStyles } from "@material-ui/styles";
import { useGetImgixVersion } from '_hooks';


const useStyles = makeStyles(() =>
  createStyles({
    galleryContainer: {
      position: 'relative',
    },
    galleryImage: {

    },
    isMyMessage: {
      textAlign: 'right',
    }
  })
);


export const CustomGallery: React.FC<GalleryProps<LocalAttachmentType>> = (props) => {
  const { images } = props;

  const classes = useStyles();

  const { isMyMessage, message } = useMessageContext();

  let myMessage = isMyMessage();

  images.map((image, i) => (
    <div
      className={classes.galleryImage + ' ' +
        (isMyMessage() ? classes.isMyMessage : '')}
      key={`gallery-image-${i}`}
    >
      <img alt='individual-gallery-element' src={image.image_url || image.thumb_url} />
    </div>
  ));

  return (
    <div
      className={classes.galleryContainer}
    >
      {images.map((image, i) => (
        <GalleryImage
          image={image.image_url || image.thumb_url}
          myMessage={myMessage}
          index={i}
          message={message}
        />

      ))}
    </div>
  );
};







type GalleryImageProps = {
  myMessage: boolean;
  image: any;
  index: number;
  message: StreamMessage;
}
const GalleryImage = (props: GalleryImageProps) => {

  const classes = useStyles();

  const { myMessage, image, index, message } = props;

  const pic = useGetImgixVersion(image, {
    h: 200,
    w: 200,
  })

  return (
    <div
      className={classes.galleryImage + ' ' +
        (myMessage ? classes.isMyMessage : '')}
      key={'gallery-image-' + message.id + '-' + index}
    >
      <img alt='individual-gallery-element' src={pic} />
    </div>
  )

}