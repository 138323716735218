import { useEffect } from 'react';
import { useWellDispatch, useWellSelector } from '_hooks';
import { getServerTime, history } from '_helpers';
import { roomActions } from '_actions';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import { roomConstants } from '_constants';

export const useSessionRoomPoll = () => {
  const dispatch = useWellDispatch();
  const { roomStore, userStore } = useWellSelector((state) => state);

  //Every 3 seconds poll sever
  useEffect(() => {
    const { userData } = userStore;
    const { sessionInfo, hasCompletedVideoCheck, sentHostSignal } = roomStore;

    let pollFrequency = 3000;
    let pollFunction = setInterval(async () => {
      if (!hasCompletedVideoCheck) return; // don't ping unless ready
      if (
        getServerTime().isBefore(
          moment.tz(sessionInfo.start_time, sessionInfo.timezone).local(),
        ) &&
        !sessionInfo.am_i_host &&
        !userStore.isAdmin
      )
        return; //if not host & before the time don't ping.

      const response = await dispatch(
        roomActions.inSessionRoomPoll(
          sessionInfo.uuid,
          userData,
          sentHostSignal,
        ),
      );

      // handle endings
      if (response === 'SESSION_CANCELED')
        dispatch({
          type: roomConstants.SET_LEAVE_STATE,
          redirectLocation: '/session-canceled',
        });
      else if (response === 'SESSION_ENDED')
        dispatch({
          type: roomConstants.SET_LEAVE_STATE,
          redirectLocation: '/session-survey',
        });
      else if (
        response === 'SESSION_TIME_SKEW' ||
        response === 'SESSION_USER_NOT_JOINED'
      )
        dispatch({
          type: roomConstants.SET_LEAVE_STATE,
          redirectLocation: '/',
        });
      else if (response === 'SESSION_TIME_ENTERING_TOO_LATE') {
        toastr.error('You are more than 5 minutes late.', '');
        dispatch({
          type: roomConstants.SET_LEAVE_STATE,
          redirectLocation: '/',
        });
      }
    }, pollFrequency);

    return () => clearInterval(pollFunction);
  }, [dispatch, roomStore, userStore.userData, userStore.isAdmin]);
};
