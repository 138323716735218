import styles from './MembershipItems.module.css';
import { NoCheck, YesCheck } from '_components';

export const BasicList = ({ title }: { title: string }) => {
  return (
    <div className={styles['list']}>
      <h5 className={styles['list-title']}>{title}</h5>
      <div className={styles['check-item']}>
        <NoCheck className={styles['no-check']} />
        <p>1 peer support session per month</p>
      </div>
    </div>
  );
};

export const UnlimitedList = ({
  title,
}: {
  title: string;
}) => {
  return (
    <div className={styles['list']}>
      <h5 className={styles['list-title']}>{title}</h5>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>
          Unlimited peer support for a fraction of the cost of therapy
        </p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>24/7 access to live video with peers</p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>Hourly peer support sessions with community hosts</p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>Free group hosting workshops</p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>Personalized mental health feed</p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>Group chat rooms</p>
      </div>
    </div>
  );
};

export const UnlockUnlimitedList = () => {
  return (
    <div className={styles['list'] + ' ' + styles['no-border']}>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>
          Life-changing support where you never feel alone in your struggles.{' '}
        </p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>
          100+ support sessions covering anxiety, depression, heartbreak, and
          more.
        </p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>
          Access motivational content and mental health resources added each
          day.
        </p>
      </div>
      <div className={styles['check-item']}>
        <YesCheck className={styles['yes-check']} />
        <p>
          Connect with peers via a 24/7 chat and forum for support when you need
          it.
        </p>
      </div>
    </div>
  );
};
