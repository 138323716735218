import React from 'react';
import { StatusUpdateForm, ActivityItem, WellLoader } from '_components';
import styles from './Forum.module.css';
import { useDetectAtBottom, useForum } from '_hooks';
import { generalConstants } from '_constants';
import { PollItem } from '../PollItem/PollItem';
import { IActivity } from '_types';


export const Forum = ({ showStatusUpdate }: { showStatusUpdate: boolean }) => {

  const {
    pinnedActivities,
    group,
    activities,
    isLoading,
    isOver,
    isMore,
    getActivities,
    getNewActivities,
  } = useForum();

  useDetectAtBottom(getActivities, true, 200, isLoading, isOver);

  if (isLoading)
    return (
      <div className={styles['loading-container']}>
        <WellLoader heightWidth={200} />
      </div>
    );

  return (
    <div className={styles['forum-container']}>
      {showStatusUpdate && <StatusUpdateForm />}
      {isMore && (
        <div className={styles['there-is-new']} onClick={getNewActivities}>
          <h4 className={styles['loading-more-inner']}>
            {group === generalConstants.SPECIAL_UUID ? (
              <>View new posts!</>
            ) : (
              <>This Well has new Posts! Click here to see!</>
            )}
          </h4>
        </div>
      )}
      <div>
        {activities &&
          activities.length > 0 &&
          activities.map((activity: IActivity) => {
            if (pinnedActivities && pinnedActivities?.itemIds?.includes(activity.uuid) && !activity?.is_pinned)
              return <React.Fragment key={activity.uuid + '-hidden-pin'}></React.Fragment>;
            return (
              <ActivityItem
                activity={activity}
                key={'activity-' + activity.uuid}
                isSinglePost={false}
              />
            );
          })}
      </div>
      <div>
        {activities.length === 0 && !isMore ? (
          <div className={styles['no-more']}>
            <h4 className={styles['loading-more-inner']}>
              There are no Posts yet for this Well.
              <br></br>
              Be the first to Post!
            </h4>
          </div>
        ) : activities.length === 0 && isMore ? (
          <></>
        ) : !isOver ? (
          <div className={styles['load-more']}>
            <WellLoader heightWidth={50} />
          </div>
        ) : (
          <div className={styles['no-more']}>
            <h4 className={styles['loading-more-inner']}>
              You have reached the end!
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};
