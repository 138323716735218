import { generalService } from '_services';
import { AppDispatch } from '_reducers';

export const userHomeActions = {
  getAnnouncementLink,
  getForumPoll,
};

// load pinned posts
function getAnnouncementLink() {
  return async () => {
    try {
      const res = await generalService.loadMarketingData(
        'announcement_username'
      );

      if (res.announcement_username || res.announcement_username.length === 1) {
        let parsed = JSON.parse(res.announcement_username[0]);
        return Promise.resolve(parsed);
      }

      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  };
}

function getForumPoll(isAdmin: boolean) {
  return async () => {
    try {
      const res = await generalService.loadMarketingData('forum_poll');

      // only can have one poll at a time
      if (!res.forum_poll)
        return Promise.reject();

      // for admin need to return anything we get,
      // if nothing is has not been set yet
      if (isAdmin) {
        try {
          let parsed = JSON.parse(res.forum_poll);
          return Promise.resolve(parsed);
        } catch (error) {
          // this is never been set
          return Promise.resolve({
            question: '',
            answerOne: '',
            answerTwo: '',
            answerThree: '',
            answerFour: '',
            answerFive: '',
            answerKey: 0,
          });
        }
      }


      if (res.forum_poll.length === 1) {
        let parsed = JSON.parse(res.forum_poll[0]);
        return Promise.resolve(parsed);
      }

      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  };
}
