import { useContext } from 'react';

import { GroupCreateContext } from '../../Groups';

export const useGroupCreate = () => {
  const context = useContext(GroupCreateContext);
  if (context === undefined) {
    throw new Error('useGroupCreate must be used within a GroupCreateContext');
  }
  return context;
};
