import React from 'react';
import useStyles from './HostQualificationsStyles';
import { useTheme } from '@material-ui/core/styles';
import { SectionWrapper } from '../Wrappers/SectionWrapper';
import { TrainingBtn } from '../Masthead/Masthead';

interface IListItem {
  title: string;
  text: string;
  image: string;
  imageClassName: string;
}

export const HostQualifications = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <SectionWrapper>
      <div>
        <div className={classes.container}>
          <h3>What's Included In The Training?</h3>
          <p>
            The Host Training is a combination of experiential learning and
            in-depth training and is the first step to getting your ShareWell
            peer support certification. It's 100% virtual and self-paced.
          </p>

          <div className={classes.listContainer}>
            <ListItem
              title={'9 Topics Led by Peer Support Experts'}
              text={
                'Learn from seasoned mental health professionals on 9 key topics that are core to peer support; covering everything from facilitation skills, to emotional safety, to trauma sensitivity and crisis response.'
              }
              image={
                'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/heart.svg'
              }
              imageClassName={classes.imageOne}
            />

            <ListItem
              title={"42 Instructional Video's & Discussion Boards"}
              text={
                'Move through the curriculum at your own pace, and learn through dynamic instructional video lessons and PDF resources. Connect with the ShareWell community through discussion groups to further your learning.'
              }
              image={
                'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/speaker.svg'
              }
              imageClassName={classes.imageTwo}
            />

            <ListItem
              title={'14 Exercises for Self-Discovery'}
              text={
                'Knowing yourself as a facilitator is key to leading impactful support groups. Dive into various thought exercises for self-discovery to achieve greater self-awareness and growth throughout the course.'
              }
              image={
                'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-site/index/books.svg'
              }
              imageClassName={classes.imageThree}
            />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <TrainingBtn className={classes.btn} text={'START NOW'} />
        </div>
      </div>
    </SectionWrapper>
  );
};

const ListItem: React.FC<IListItem> = ({
  title,
  text,
  image,
  imageClassName,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.listItem}>
      <div className={classes.imageContainer + ' ' + imageClassName}>
        <img src={image} className={classes.image} alt={title} />
      </div>
      <div>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};
