import { authConstants, componentsConstants } from '_constants';
import { useWellSelector, useWellDispatch } from '_hooks';
import { AnalyticsSendEvent, history } from '_helpers';
import { userActions } from '_actions';
import { DispatchBoolean, DispatchString } from '_types';

export const useOauthLogin = (
  setError: DispatchString,
  setIsLoading: DispatchBoolean,
) => {
  const dispatch = useWellDispatch();

  const { authStore, componentsStore } = useWellSelector((state) => state);
  const { redirect } = componentsStore.newModal.isRegisterModalOpen;

  const loginCallback = async (
    loginFunction: () => Promise<any>,
    type: string,
  ) => {
    setIsLoading(true);

    dispatch({ type: authConstants.SET_IS_LOGGING_IN, isLoggingIn: true });

    try {
      const res = await loginFunction();

      // on new repo this will be in catch
      if (res.notice && res.notice.msg) {
        setError(res.notice.msg);
        return;
      }

      // get the profile
      await dispatch(userActions.getProfileInfo());

      // was this a account creation?
      if (res.auth_user && res.auth_user.was_oath_creation) {
        // send event to mixpanel
        AnalyticsSendEvent('auth_register_success', {
          username: res.auth_user.username,
          email: res.auth_user.email,
        });
        // on new repo this should be cleaned up
        dispatch({
          type: authConstants.SET_CAN_GO_TO_ONBOARDING,
          canGoToOnboarding: true,
        });
        if (
          history.location.pathname !== '/gift' &&
          history.location.pathname !== '/redeem'
        )
          history.push('/onboarding');

        // always close the modal
        dispatch({
          type: componentsConstants.TOGGLE_NEW_MODAL,
          isRegisterModalOpen: {
            isOpen: false,
            skipOnboarding: false,
            redirect: '',
          },
        });
      } else if (res.auth_user && !res.auth_user.was_oath_creation) {
        AnalyticsSendEvent('auth_login_success', { type: type });
        // only on the gift page do we not recirect
        // TODO: move this to be same as useLogin
        if (
          history.location.pathname !== '/gift' &&
          history.location.pathname !== '/redeem'
        ) {
          if (authStore.loginRedirect && authStore.loginRedirect !== '/login')
            history.push(authStore.loginRedirect);
          else history.push('/');
        }
      }
    } catch (error) {
    } finally {
      dispatch({ type: authConstants.SET_IS_LOGGING_IN, isLoggingIn: false });
      setIsLoading(false);
    }
  };

  return {
    loginCallback,
  };
};
