import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useGroupInfoTabs = (am_i_joined: boolean) => {
  const { uuid, tab } = useParams<{ uuid: string; tab: string }>();
  const [curTab, setCurTab] = useState(0);

  useEffect(() => {
    if (am_i_joined === undefined) return;

    //set correct tab
    setCurTab(() => {
      let t = uuid.length == 36 ? tab : uuid;
      if (t === 'discussion' && am_i_joined) return 1;
      else if (t === 'members' && am_i_joined) return 2;
      return 0;
    });
  }, [uuid, tab, am_i_joined]);

  return {
    curTab,
    setCurTab,
  };
};
