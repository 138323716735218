import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions } from '_actions';

export const useGroupBadge = (curTab, group, isLoading) => {
  const dispatch = useDispatch();

  const { userStore } = useSelector((state) => state);

  /* This handles clearing the badge on click */
  useEffect(() => {
    if (!group || !userStore.loggedIn || !group.am_i_joined || isLoading)
      return false;

    const handleTabChange = async () => {

      if(curTab === 0 && group.badge_session !== 0)
        await dispatch(groupActions.badgeReset(group.uuid, 'badge_session'));

      //This badge is now handled from within the forum
      // if(curTab === 1 && group.badge_forum_post !== 0)
      //   await dispatch(groupActions.badgeReset(group.uuid, 'badge_forum_post'));

      if(curTab === 2 && group.badge_user_join !== 0)
        await dispatch(groupActions.badgeReset(group.uuid, 'badge_user_join'));
    };

    handleTabChange();
  }, [curTab, userStore.loggedIn, group, dispatch, isLoading]);
};
