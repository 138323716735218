import styles from './LegalDocuments.module.css';
import { WellHeader } from '_components';

export const PrivacyPolicy = () => {
  return (
    <div className={styles['legal-document']}>
      <WellHeader title={'Privacy Policy'} />

      <div className={styles['legal-document-container'] + ' container'}>
        <h4 className={styles['last-updated']}>
          Last Updated: September 14, 2021
        </h4>

        <p>
          At ShareWell Labs Co. (“ShareWell”, “we”, “our” and “us”), we respect
          your privacy and appreciate the trust you have placed in us. This
          Privacy Policy explains how ShareWell collects, uses and discloses
          information we obtain in connection with our provision of services
          through our website or mobile application or other online services
          that link to or refer to this Privacy Policy (collectively, the
          “Services”).
        </p>
        <p>
          It is important that you read this Privacy Policy carefully because
          any time you use our Services, you consent, on your own behalf, to the
          practices we describe in this Privacy Policy.
        </p>

        <h3>How we collect your information</h3>
        <h4>Information You Provide</h4>
        <p>
          We collect any information that you provide us when you use the
          Services, when you communicate with our team, or when requesting
          additional information from or about our Services.
        </p>
        <p className={styles['no-bottom']}>For example, we collect:</p>
        <ul>
          <li>
            Your contact information and other identifiers, such as your name,
            phone number and email address
          </li>
          <li>
            Other information, when you register or contact us directly, such as
            when we ask you to create a user name
          </li>
          <li>Information We Collect Automatically</li>
          <li>
            We may automatically collect certain technical information from your
            computer or mobile device when you use the Services.
          </li>
        </ul>
        <h4>Information We Collect Automatically</h4>
        <p>
          We may automatically collect certain technical information from your
          computer or mobile device when you use the Services.
        </p>
        <p className={styles['no-bottom']}>For example, we collect:</p>
        <ul>
          <li>
            Device and Usage Information. We may receive information from your
            device, including the IP address, mobile operating system version,
            and unique device identifiers. We may also collect and store your
            browser and app settings and preferences.
          </li>
          <li>
            Online Activity. We may receive information about your interactions
            with our Services and other electronic and network activity
            information, such as the time spent interacting with content and the
            dates and times of your visits, and other information through the
            use of cookies, web beacons, and other Internet technologies:
          </li>
          <ul className={styles['no-bottom']}>
            <li>
              Cookies. Cookies are pieces of information that are stored by your
              browser on the hard drive or memory of your computer or other
              Internet access device. Cookies may enable us to personalize your
              experience on the Services, maintain a persistent session,
              passively collect demographic information about your computer and
              monitor other activities. Please consult your browser and app
              settings for information about the choices you may have to disable
              or limit cookies.
            </li>
            <li>
              Web Beacons. Web beacons and similar technologies are small bits
              of code, which are embedded in web pages, ads and e-mail, that
              communicate with third parties. We may use web beacons, for
              example, to count the number of users who have visited a
              particular web page, to deliver or communicate with cookies and to
              understand usage patterns.
            </li>
            <li>
              Other Internet Technologies. Our Services may use local stored
              objects (also referred to as “Flash cookies”) and other types of
              local storage (such as browser-based or plugin-based local
              storage) that can be used to store certain information about your
              activities and preferences across different services and sessions.
              Please note that these technologies are distinct from
              browser-based cookies, and you may not be able to control them
              using standard browser tools and settings.
            </li>
          </ul>
          <li>
            Geolocation Information. We may obtain information about your
            physical location, such as when you use the geolocation features in
            your device.
          </li>
        </ul>

        <h4>Other Information</h4>
        <ul>
          <li>
            Information from Other Sources. We may obtain information from third
            parties and sources other than the Services, such as from our
            sponsors and advertisers.
          </li>
          <li>
            We may combine the information we collect about you from different
            sources, including to generate inferences, to help us enhance and
            improve our products and services, and tailor our communications to
            you.
          </li>
        </ul>

        <h4>How we use your information</h4>
        <p className={styles['no-bottom']}>
          We generally use the information we collect for the following business
          purposes:
        </p>
        <ul>
          <li>
            Provide, customize and improve our products and services. For
            example, we use the information we collect about you to provide the
            Services, to improve and develop new products, services, features
            and functionality and to understand and analyze the usage trends and
            preferences of our users. We also use your information to monitor
            and analyze usage and the effectiveness of the Services and
            marketing activities.
          </li>
          <li>
            Communicate with you about our products, services, and promotions.
            For example, we may send emails to the email address you provide to
            us for operational purposes, such as account management or customer
            service. We may also use your information to send you promotional
            communications by email. If you prefer not to receive such
            promotional communications from us, you may opt-out by following the
            instructions included in those communications.
          </li>
          <li>
            Comply with applicable law and enforce our legal rights. We may also
            process the information we collect about you or from you for the
            following purposes: (i) to enforce our terms and other legal rights,
            including intellectual property rights; (ii) to comply with or
            address applicable law, regulations, or legal processes, such as a
            court order or subpoena; (iii) in response to requests by government
            agencies, such as law enforcement authorities; and (iv) to comply
            with industry standards and our policies.
          </li>
          <li>
            Otherwise with your consent or as described to you at the time of
            collection.
          </li>
        </ul>

        <h4>Third parties with whom we share your information</h4>
        <ul>
          <li>
            Service Providers. We work with third-party service providers who
            provide development, hosting and other services. These third parties
            may have access to or process information you provide and
            information we collect automatically in connection with providing us
            these services.
          </li>

          <li>
            Business Transfers. We also reserve the right to transfer
            information you provide and information we collect automatically to
            advisors, potential transactional partners or other third parties in
            connection with the consideration, negotiation or completion of a
            corporate transaction in which we are acquired by or merged with
            another company or we sell, liquidate or transfer all or a portion
            of our assets or in the unlikely event of a bankruptcy.
          </li>

          <li>
            Other Parties As Required by Law or As Necessary. We may disclose
            information you provide and information we collect automatically
            about you: (i) if we are required to do so by law, regulation or
            legal processes, such as a court order or subpoena; (ii) in response
            to requests by government agencies, such as law enforcement
            authorities; (iii) when we believe disclosure is necessary or
            appropriate to protect against or respond to physical, financial or
            other harm, injury or loss to property; or (iv) in connection with
            an investigation of suspected or actual unlawful activity.
          </li>

          <li>
            With Your Consent. We may share information you provide and
            information we collect automatically for other purposes with your
            consent or at your direction.
          </li>
        </ul>
        <h4>Third party services</h4>
        <p>
          We also may use or make available to third parties information you
          provide and information we collect automatically in a manner that does
          not identify you or has been aggregated. For example, we may share
          aggregated information with third parties that sponsor usage of the
          Services.
        </p>

        <h4>Do-Not-Track Signals and Similar Mechanisms </h4>
        <p>
          Some web browsers may transmit “do-not-track” signals to the websites
          with which the user communicates. Because of differences in how web
          browsers incorporate and activate this feature, it is not always clear
          whether users intend for these signals to be transmitted or whether
          they even are aware of them. Because there currently is no industry
          standard concerning what, if anything, websites should do when they
          receive such signals, the Services currently do not take action in
          response to these signals. If and when a final standard is established
          and accepted, we will reassess how to respond to these signals.
        </p>

        <h4>How we hold and protect your information</h4>
        <p>
          We use certain physical, administrative and technical measures that
          are intended to maintain the integrity and security of information
          that we collect and maintain. Please be aware that no security
          measures are perfect or impenetrable. We cannot and do not guarantee
          that information about you will not be accessed, viewed, disclosed,
          altered or destroyed by breach of any of our physical, technical or
          managerial safeguards. Moreover, ShareWell cannot guarantee the
          security of networks that we do not control, including the Internet
          and any wireless networks over which information may be transmitted to
          ShareWell.
        </p>

        <h4>Children’s privacy</h4>
        <p>
          We prohibit anyone under the age of 18 from using or accessing our
          Services. We do not knowingly collect information online from children
          under the age of 18. If ShareWell becomes aware that any person
          submitting information to the Services is under the age of 18, we will
          promptly take steps to delete any personal information collected in
          accordance with applicable law.
        </p>

        <h4>Changes to our privacy and practices</h4>
        <p>
          This Privacy Policy may be updated periodically and without prior
          notice to you to reflect changes in our information practices or
          relevant laws. To inform you of any change, we will post the updated
          Privacy Policy on the Services and indicate at the top of the Privacy
          Policy when it was updated.
        </p>

        <h4>Your choices and rights; other legal disclosures</h4>
        <p>
          Depending on where you live, you may have certain additional rights as
          described in this section.
        </p>
        <p>
          With respect to personal information covered by this Privacy Policy,
          you may have the right to request the personal information we have
          collected by submitting a request at support@sharewellnow.com. Subject
          to certain exceptions, you also may have the right to have such
          information deleted. Additionally, you may have the right to request
          that your personal information not be sold to third parties. We will
          not discriminate against you for exercising your rights. Any rights
          you may have are not unlimited and we reserve the right to limit our
          response in accordance with applicable law. For example, we are unable
          to provide you certain information unless you verify your identity. To
          verify your identity, we may need you to authenticate your account or
          collect additional information from you, such as date of birth,
          insurance information, address, or answers to questions about publicly
          available personal information. You may have the right to authorize an
          agent to make certain requests on your behalf.
        </p>
        <p>
          We do not sell or share personal information with third parties for
          their own direct marketing purposes.
        </p>

        <h4>How to contact us</h4>
        <p>
          If you have any questions or comments about this Privacy Policy, our
          privacy practices or if you would like to exercise your rights and
          choices, please contact us at{' '}
          <a href='mailto:support@sharewellnow.com'>support@sharewellnow.com</a>.
        </p>
      </div>
    </div>
  );
};
