import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? ''
);

export const StripeContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <>
      <div>
        <Elements
          stripe={stripePromise}
          options={{ appearance: { theme: 'night', labels: 'floating' } }}
        >
          {children}
        </Elements>
      </div>
    </>
  );
};
