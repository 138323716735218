import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupUserActions } from '_actions';
import { RootState } from '_reducers';

//Assumes user is logged in
export const useMyGroups = () => {
  const dispatch = useDispatch();
  const { userStore } = useSelector((state: RootState) => state);

  const [myGroupList, setMyGroupList] = useState([]);
  const [isLoadingMyGroups, setIsLoadingMyGroups] = useState(true);

  useEffect(() => {
    const start = async () => {
      if (userStore.loggedIn) {
        const myGroupsResponse: any = await dispatch(
          groupUserActions.getMyGroups(100, 0)
        );
        if (myGroupsResponse) setMyGroupList(myGroupsResponse);
      }

      setIsLoadingMyGroups(false);
    };

    start();
  }, [dispatch, userStore.loggedIn]);

  return {
    myGroupList,
    isLoadingMyGroups,
  };
};
