import { makeStyles, createStyles } from "@material-ui/styles";
import { useWellChat } from '_hooks';


const useStyles = makeStyles(() =>
  createStyles({
    minimizeIcon: {
      cursor: 'pointer',
      borderRadius: '100%',
      padding: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: 'rgba(15, 20, 25, 0.1)',
      },
    },
    innerSvg: {
      width: '25px',
    }
  })
);


export const MinimizeIcon = () => {

  const classes = useStyles();
  const { setIsOpen } = useWellChat();

  return (
    <div className={classes.minimizeIcon} onClick={() => {
      setIsOpen(false);
    }}>
      <svg viewBox="0 0 24 24" className={classes.innerSvg} aria-hidden='true'><g><path d="M12 19.344l-8.72-8.72c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l9.25 9.25c.292.294.767.294 1.06 0l9.25-9.25c.146-.145.22-.337.22-.53s-.073-.383-.22-.53c-.293-.292-.768-.292-1.06 0L12 19.344z"></path><path d="M12 11.535l-8.72-8.72c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l9.25 9.25c.292.294.767.294 1.06 0l9.25-9.25c.146-.145.22-.337.22-.53s-.073-.383-.22-.53c-.293-.292-.768-.292-1.06 0L12 11.535z"></path></g></svg>
    </div>
  )

}




export const CollapseIcon = ({ size, fill }: { size: string, fill: string }) => {

  return (

    <svg width={size} height={size} viewBox="0 0 24 24" fill="white">
      <path d="M16.392 9H19.992C20.592 9 21 9.408 21 10.008C21 10.608 20.592 10.992 19.992 10.992H14.112C13.8 10.992 13.512 10.896 13.296 10.704C13.104 10.488 13.008 10.296 13.008 10.008V4.008C13.008 3.408 13.392 3 13.992 3C14.592 3 15 3.408 15 4.008V7.608L19.296 3.288C19.704 2.904 20.304 2.904 20.712 3.288C21.096 3.696 21.096 4.296 20.712 4.704L16.392 9V9ZM7.608 15H4.008C3.408 15 3 14.592 3 13.992C3 13.392 3.408 13.008 4.008 13.008H10.008C10.296 13.008 10.512 13.104 10.704 13.296C10.896 13.488 10.992 13.8 10.992 14.088V19.992C10.992 20.592 10.608 21 10.008 21C9.408 21 9 20.592 9 19.992V16.392L4.704 20.712C4.296 21.096 3.696 21.096 3.312 20.712C2.904 20.304 2.904 19.704 3.312 19.296L7.608 15V15Z" fill={fill} />
    </svg>

  )
}


export const OpenIcon = ({ size, fill }: { size: string, fill: string }) => {

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M6.408 19.008H10.008C10.608 19.008 10.992 19.392 10.992 19.992C10.992 20.592 10.608 21 10.008 21H4.104C3.792 21 3.504 20.904 3.312 20.712C3.096 20.592 3 20.4 3 20.088V19.992V13.992C3 13.392 3.408 13.008 4.008 13.008C4.608 13.008 4.992 13.392 4.992 13.992V17.592L9.312 13.296C9.696 12.888 10.296 12.888 10.704 13.296C11.112 13.704 11.112 14.304 10.704 14.688L6.408 19.008ZM17.592 4.992H13.992C13.392 4.992 13.008 4.608 13.008 4.008C13.008 3.408 13.392 3 13.992 3H19.896C20.208 3 20.496 3.096 20.712 3.288C20.904 3.504 21 3.696 21 3.888V4.008V10.008C21 10.608 20.592 10.992 19.992 10.992C19.392 10.992 19.008 10.608 19.008 10.008V6.408L14.712 10.704C14.304 11.088 13.704 11.088 13.296 10.704C12.912 10.296 12.912 9.696 13.296 9.288L17.592 4.992V4.992Z" fill={fill} />
    </svg>

  )
}




