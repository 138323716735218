import { creatorService } from '_services';
import { sessionsConstants } from '_constants';
import { toastr } from 'react-redux-toastr';

export const creatorActions = {
  // creating/ deleting
  createSession,
  deleteSession,
  deleteSeries,

  // editing sessions
  updateSession,
  uploadSessionImage,

  // editing series
  updateSeries,
  updateSeriesTime,
  uploadSeriesImage,

  setShowCongrats, // show congrats after session create
};

// creates a session
function createSession(formData) {
  return async () => {
    try {
      const response = await creatorService.createSession(formData);
      if (response.notice) {
        toastr.error(response.notice.msg);
        return Promise.resolve(0);
      } else if (response.session) {
        return Promise.resolve(response.session);
      }
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function deleteSession(sessionUUID) {
  return async () => {
    try {
      const response = await creatorService.deleteSession(sessionUUID);
      if (response.notice) {
        toastr.error('This session has already started');
        return Promise.resolve(0);
      }
      return Promise.resolve(1);
    } catch (error) {}
  };
}

function updateSession(sessionUUID, formData) {
  return async () => {
    try {
      const response = await creatorService.updateSession(
        sessionUUID,
        formData
      );
      if (response.notice) {
        toastr.error(response.notice.msg);
        return Promise.resolve(0);
      } else {
        return Promise.resolve(response.session);
      }
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function uploadSessionImage(sessionUUID, picData) {
  return async () => {
    try {
      await creatorService.uploadSessionImage(sessionUUID, picData);
    } catch (error) {}
  };
}

function updateSeries(series, formData) {
  return async () => {
    try {
      const response = await creatorService.updateSeries(series, formData);
      if (response.notice) {
        return Promise.reject(response.notice.msg);
      } else {
        return Promise.resolve(response.session);
      }
    } catch (error) {
      return Promise.reject();
    }
  };
}

function updateSeriesTime(seriesUUID, formData) {
  return async () => {
    try {
      const response = await creatorService.updateSeriesTime(
        seriesUUID,
        formData
      );
      if (response.notice) {
        toastr.error(response.notice.msg);
        return Promise.resolve(0);
      } else {
        return Promise.resolve(response.session);
      }
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function deleteSeries(seriesUUID) {
  return async () => {
    try {
      const response = await creatorService.deleteSeries(seriesUUID);
      if (response.notice) {
        if (response.notice.msg) toastr.error(response.notice.msg);
        return Promise.resolve(0);
      }
      return Promise.resolve(1);
    } catch (error) {}
  };
}

function uploadSeriesImage(uuid, picData) {
  return async () => {
    try {
      await creatorService.uploadSeriesImage(uuid, picData);
    } catch (error) {}
  };
}

function setShowCongrats(showCongrats) {
  return async (dispatch) => {
    await dispatch({
      type: sessionsConstants.SET_SHOW_CONGRATS,
      showCongrats,
    });
    return Promise.resolve(true);
  };
}
