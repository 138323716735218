import { useState, useEffect, useRef, useCallback } from 'react';
import { useWellApp } from '_hooks';
import { isScrollable } from '_helpers';
import { useLocation } from 'react-router-dom';

export function useGetImgixVersion(url, options) {
  const { imgIxClient } = useWellApp();

  const [image, setImage] = useState(url);

  const previous = usePrevious({ url, options });
  useEffect(() => {
    if (imgIxClient && url && options) {
      if (!previous || previous.url !== url)
        setImage(imgIxClient.buildURL(url, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, options]);

  if (image && image.includes('imgix')) return image;

  return '';
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Hook for finding if you have scrolled to bottom of page
 */
export function useDetectAtBottom(
  callBack,
  useIsFiring,
  offset = 0,
  isLoading = false,
  isOver
) {
  const [isFiring, setIsFiring] = useState(false);

  useEffect(() => {
    if (isOver) return;

    const scrollWatch = async (event) => {
      if (isFiring || isLoading) return false;
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight - offset) {
        if (useIsFiring) setIsFiring(true);
        await callBack();
        // setTimeout(()=>setIsFiring(false), 400);
        setIsFiring(false);
      }
    };

    document.addEventListener('scroll', scrollWatch);

    return () => {
      document.removeEventListener('scroll', scrollWatch);
    };
  }, [callBack, isFiring, isLoading, offset, useIsFiring, isOver]);

  return true;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickOutside(ref, callBack, cancel) {
  useEffect(() => {
    if (cancel) return;

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callBack, cancel]);
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickInside(ref, callBack) {
  useEffect(() => {
    function handleClickInside(event) {
      if (ref.current && ref.current.contains(event.target)) {
        callBack();
      }
    }

    document.addEventListener('mousedown', handleClickInside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickInside);
    };
  }, [ref, callBack]);
}

export function useScrollIntoView(ref, isPartial, offset, callBack) {
  useEffect(() => {
    if (!ref) return false;

    const scrollFunction = () => {
      if (!ref.current) return false;
      var rect = ref.current.getBoundingClientRect();
      var elemTop = rect.top - offset;
      var elemBottom = rect.bottom;

      let isVisible = isPartial
        ? elemTop < window.innerHeight && elemBottom >= 0
        : elemTop >= 0 && elemBottom <= window.innerHeight;

      if (isVisible) {
        callBack();
        document.removeEventListener('scroll', scrollFunction);
      }
    };

    document.addEventListener('scroll', scrollFunction);
    return () => document.removeEventListener('scroll', scrollFunction);
  }, [ref]);
}

// move to own file
export function useListAtBottomWithRef(ref, callBack, offset = 0, isDone) {
  const [isFiring, setIsFiring] = useState(false);

  useEffect(() => {
    if (!ref.current || isDone) return;

    const scrollWatch = async (event) => {
      if (isFiring) return false;
      if (
        ref.current &&
        ref.current.scrollTop + offset >=
          ref.current.scrollHeight - ref.current.offsetHeight
      ) {
        setIsFiring(true);
        await callBack();
        setIsFiring(false);
      }
    };

    const scrollWatchWindow = async (event) => {
      if (isFiring) return false;
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight - offset) {
        setIsFiring(true);
        await callBack();
        setIsFiring(false);
      }
    };

    let usedRef = false; //variable to see which is used in the returned function (resets each render)
    if (isScrollable(ref.current)) {
      ref.current.addEventListener('scroll', scrollWatch);
      usedRef = true;
    } else {
      document.addEventListener('scroll', scrollWatchWindow);
    }

    return () => {
      if (ref.current && usedRef) {
        ref.current.removeEventListener('scroll', scrollWatch);
      } else {
        document.removeEventListener('scroll', scrollWatchWindow);
      }
    };
  }, [callBack, ref, isFiring]);

  return true;
}

export function usePageLoadScrollToAnchor() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          // const y = element.getBoundingClientRect().top + window.pageYOffset + 0;
          // window.scrollTo({top: y, behavior: 'smooth'});
          element.scrollIntoView();
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, hash, key]);
}

export function useScrollToOnCreation(offset, timeout) {
  const [hasRun, setHasRun] = useState(false);

  const itemRef = useCallback((node) => {
    if (node !== null && !hasRun) {
      setTimeout(() => {
        const y =
          node.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
        setHasRun(true);
      }, timeout);
    }
  }, []);

  return itemRef;
}
