export const sessionsConstants = {
  LIST_PAGINATION_LIMIT: 20,

  //SESSION CREATION
  SET_SHOW_CONGRATS: 'SESSION_SET_SHOW_CONGRATS',

  //GET SESSION INFO
  GET_SESSION_INFO_SUCCESS: 'SESSION_GET_SESSION_INFO_SUCCESS',

  //CLEAR SESSION INFO
  CLEAR_SESSION_INFO: 'SESSION_CLEAR_SESSION_INFO',

  //GET MY SESSIONS
  SET_MY_UPCOMING_SESSION: 'SESSION_SESSIONS_SET_MY_UPCOMING_SESSION',

  SET_UNSET_SESSIONS_CALLBACK_LIST: 'SESSION_SET_UNSET_SESSIONS_CALLBACK_LIST',
};
