import { useState, useRef, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { useGetImgixVersion } from '_hooks';
import styles from './UserImage.module.css';

import { resizeImage } from '_helpers';
import { userActions } from '_actions';
import PencilIcon from '_images/white-pencil.svg';

import { IUser } from '_types';
import { WellLoader } from '_components/WellItems';
import { toastr } from 'react-redux-toastr';

interface IUserImage {
  userData: IUser;
  isSelf: boolean;
  className: string;
}

export const UserImage = ({ userData, isSelf, className }: IUserImage) => {
  const { username, pic } = userData;

  const [isLoading, setIsLoading] = useState(false);
  const hiddenUpload = useRef<any>(null);

  const userImage = useGetImgixVersion(pic ? pic : '', {
    h: 200,
    w: 200,
    fit: 'crop',
  });

  const openUploadImage = () => {
    if (!isSelf) return;
    hiddenUpload?.current?.click();
  };

  const dispatch = useDispatch();
  const uploadImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    // make sure we got a file
    if (!event.target.files) return;

    setIsLoading(true);
    resizeImage({ file: event.target.files[0], maxSize: 6000 })
      .then(async (data) => {
        try {
          // the linter lies...
          await dispatch(userActions.uploadProfilePic(data));
        } catch (error) {
          toastr.error(
            'There was an error with the image upload. Please try again later.',
            ''
          );
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={className}>
      <input
        type='file'
        onChange={uploadImageChange}
        className={styles['hidden-upload']}
        ref={hiddenUpload}
        accept='image/jpg'
      />

      {isSelf && !isLoading && (
        <div
          className={styles['new-photo-clickable']}
          onClick={openUploadImage}
        >
          <img src={PencilIcon} className={styles['pencil']} alt={'Pencil'} />
        </div>
      )}

      {isLoading ? (
        <div className={styles['user-image-loader']}>
          <WellLoader heightWidth={100} />
        </div>
      ) : (
        <img src={userImage} className={styles['user-image']} alt={username} />
      )}
    </div>
  );
};
