import { AnyAction } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk'

// stores
import { adminStore } from './admin.reducer';
import { authStore } from './auth.reducer';
import { componentsStore } from './components.reducer';
import { generalStore } from './general.reducer';
import { roomStore } from './room.reducer';
import { sessionsStore } from './sessions.reducer';
import { stripeStore } from './stripe.reducer';
import { userStore } from './user.reducer';

// others
import { reducer as toastrReducer } from 'react-redux-toastr';

// https://redux-toolkit.js.org/introduction/getting-started
// thunk is auto added
export const store = configureStore({
  reducer: {
    adminStore,
    authStore,
    componentsStore,
    generalStore,
    roomStore,
    sessionsStore,
    stripeStore,
    userStore,
    toastr: toastrReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

// https://redux.js.org/usage/usage-with-typescript#type-checking-redux-thunks
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
