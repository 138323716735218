import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { userActions } from '_actions';
import { useWellDispatch } from '_hooks';
import { TUserMapping } from '_types';
import { userListToUuidUsernameMapping } from '_helpers';

// should only take the item we are searching
export const useUserSearch = (
  value: string,
  setValue: Dispatch<SetStateAction<string>>,
  setTaggedUsers: Dispatch<SetStateAction<TUserMapping>>,
  group: string,
  inputRef: any //billy
) => {
  const dispatch = useWellDispatch();

  const [isOpen, setIsOpen] = useState(false); // is user search open
  const [results, setResults] = useState<TUserMapping>({}); // current results list
  const [selected, setSelected] = useState(0); // uuid of the currently selected person

  const [atSignMark, setAtSignMark] = useState(-1); // this is where the @ started

  // handle search when isOpen == true
  useEffect(() => {
    if (!isOpen || atSignMark === -1) return;

    const handleSearch = async () => {
      let result = value.substring(atSignMark);

      // get the match up to first delimiter
      const match = /[\n\r\s]+/.exec(result);
      if (match) result = result.substring(0, match.index);

      // if the result is empty or it has a space, do not search
      if (!result || !result.substring(1) || result.includes(' ')) return;

      // remove the @ from the substring
      const response = await dispatch(
        userActions.searchForUser(group, result.substring(1))
      );

      if (response.length > 0) {
        // augment the response to fit TUserMapping
        setResults(userListToUuidUsernameMapping(response));
        // if the new list has fewer items then before, need to reset
        if (selected > response.length) setSelected(0);
      }
    };
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, group, value, isOpen, atSignMark]);

  useEffect(() => {
    if (!value) return;

    // get cursor position, should be same
    const [start] = [inputRef.current.selectionStart];

    const enteredChar = value.charAt(start - 1);
    const secondLast = value.charAt(start - 2);
    if (enteredChar === '@' && (!secondLast || secondLast.match(/[\n\r\s]+/))) {
      setAtSignMark(start - 1);
      setIsOpen(true);
    }
  }, [inputRef, setIsOpen, value]);

  // close when the @ sign disappears
  useEffect(() => {
    const atSign = value.charAt(atSignMark);

    if (isOpen && atSign !== '@') {
      setAtSignMark(-1);
      setIsOpen(false);
    }
  }, [isOpen, value, atSignMark]);

  // add the user to the mapping
  const addUserToTaggedMapping = (userMapping: TUserMapping) => {
    // add the user to known tags
    setTaggedUsers((prev: TUserMapping) => {
      return {
        ...prev,
        ...userMapping,
      };
    });

    // get position
    const [start] = [
      inputRef.current.selectionStart,
      inputRef.current.selectionEnd,
    ];

    setValue((prev: string) => {
      // everything before cursor & everything after
      const [firstHalf, secondHalf] = [
        prev.substring(0, start),
        prev.substring(start),
      ];

      // can assume last occurrence of @ is where we need to be
      let lastPosition = firstHalf.lastIndexOf('@');
      let result = prev.substring(0, lastPosition + 1);
      return (
        result + userMapping[Object.keys(userMapping)[0]] + ' ' + secondHalf
      );
    });
    setIsOpen(false);
    setResults({});
    setSelected(0);

    // inputRef.current.setRangeText('', start, end, 'end'); //add the emoji
    // var event = new Event('input', { bubbles: true }); //create a new event to trigger on change
    // inputRef.current.dispatchEvent(event); //trigger event
  };

  return {
    results,
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    addUserToTaggedMapping,
  };
};
