import { forwardRef } from 'react';
import styles from './WellInput.module.css';

import magnifyingGlass from '_images/magnifying-glass.png';
import { WellToolTip } from '_components';

export const WellInput = forwardRef(
  (
    {
      label,
      onChange,
      placeHolder,
      autoFocus,
      maxLength,
      value,
      readOnly,
      name,
      className,
      showCharacterCount,
      showMaxReached,
      toolTipText,
      initialMsg,
    },
    ref
  ) => {
    return (
      <div
        className={styles['well-form-group'] + ' ' + (className && className)}
      >
        {label && <label className={styles['well-form-label']}>{label}</label>}
        <div className={styles['well-form-item-container']} ref={ref}>
          {showCharacterCount && (
            <div className={styles['character-count']}>
              Count: {value.length}
            </div>
          )}
          <input
            value={value}
            maxLength={maxLength}
            autoFocus={autoFocus}
            readOnly={readOnly}
            onChange={onChange}
            placeholder={placeHolder}
            name={name}
            className={readOnly ? styles['read-only'] : ''}
          />
        </div>
        <span className={styles['form-error-msg'] + ' form-error-msg'}></span>
        <span
          className={
            styles['form-initial-msg'] +
            ' form-initial-msg ' +
            (!initialMsg && 'form-initial-msg-hidden')
          }
        >
          {initialMsg}
        </span>
        {toolTipText && <WellToolTip text={toolTipText} />}
        {showMaxReached && value.length === maxLength ? (
          <div className={styles['maxed-reached']}>Max characters reached</div>
        ) : null}
      </div>
    );
  }
);

export const WellSearchInput = forwardRef(
  (
    { onChange, placeHolder, autoFocus, maxLength, value, readOnly, name },
    ref
  ) => {
    return (
      <div className={styles['well-form-group']}>
        <div
          className={
            styles['well-form-item-container'] +
            ' ' +
            styles['well-form-item-search-container']
          }
          ref={ref}
        >
          <input
            value={value}
            maxLength={maxLength}
            autoFocus={autoFocus}
            readOnly={readOnly}
            onChange={onChange}
            placeholder={placeHolder}
            name={name}
            className={readOnly ? styles['read-only'] : ''}
          />
        </div>
        <img
          className={styles['magnifying-glass']}
          src={magnifyingGlass}
          alt={'Magnifying Glass'}
        />
      </div>
    );
  }
);

export const WellPassword = forwardRef(
  (
    {
      onChange,
      autoFocus,
      maxLength,
      value,
      readOnly,
      name,
      label,
      placeHolder,
    },
    ref
  ) => {
    return (
      <div className={styles['well-form-group']}>
        <label className={styles['well-form-label']}>{label}</label>
        <div className={styles['well-form-item-container']} ref={ref}>
          <input
            value={value}
            maxLength={maxLength}
            autoFocus={autoFocus}
            readOnly={readOnly}
            onChange={onChange}
            name={name}
            type={'password'}
            placeholder={placeHolder}
            className={readOnly ? styles['read-only'] : ''}
          />
        </div>
        <span className={styles['form-error-msg'] + ' form-error-msg'}></span>
      </div>
    );
  }
);
export const WellTextarea = forwardRef(
  (
    {
      label,
      onChange,
      placeHolder,
      autoFocus,
      maxLength,
      value,
      readOnly,
      name,
      showCharacterCount,
      className,
      showMaxReached,
      disallowEnter,
    },
    ref
  ) => {
    const preventEnter = (e) => {
      if (e.key === 'Enter') e.preventDefault();
    };

    return (
      <div
        className={
          styles['well-form-group-textarea'] + ' ' + (className && className)
        }
      >
        {label && <label className={styles['well-form-label']}>{label}</label>}
        <div className={styles['well-form-textarea-container']} ref={ref}>
          {showCharacterCount && (
            <div className={styles['character-count']}>
              Count: {value.length}
            </div>
          )}
          <textarea
            value={value}
            maxLength={maxLength}
            autoFocus={autoFocus}
            readOnly={readOnly}
            onChange={onChange}
            placeholder={placeHolder}
            onKeyPress={disallowEnter ? preventEnter : () => {}}
            name={name}
          />
        </div>
        <span className={styles['form-error-msg'] + ' form-error-msg'}></span>
        {showMaxReached && value.length == maxLength ? (
          <div className={styles['maxed-reached']}>Max characters reached</div>
        ) : null}
      </div>
    );
  }
);

export const WellImageInput = forwardRef(
  ({ className, label, imageName, handleChange }, ref) => {
    return (
      <div className={styles['well-form-group'] + ' ' + className}>
        <label className={styles['well-form-label']}>{label}</label>
        <div className={styles['well-form-item-container']}>
          <label className={styles['custom-file-upload']}>
            <input
              type='file'
              accept='image/jpg'
              placeholder='Session Image'
              onChange={handleChange}
            />

            <div className={styles['file-input-text']}>Choose File</div>
          </label>
          <div className={styles['file-input-name']}>{imageName}</div>
        </div>
        <div className={styles['form-error-msg']}></div>
      </div>
    );
  }
);
