import styles from './SessionRepeat.module.css'
import rStyles from '../RadioInput.module.css';
import { SeriesEndTimeInput } from '_components';
import { timeConstants } from '_constants';
import moment from 'moment-timezone';

// first is this a single or multiple session
export const SessionRepeat = ({ data, nonValidationData, setNonValidationData, isSeriesEdit, disableEnd }: any) => {


  const { repeat } = nonValidationData;

  const setToSingleSession = () => {
    setNonValidationData({
      ...nonValidationData,
      repeat: 'SINGLE',
    })
  }

  const setToRepeatSession = () => {

    if (nonValidationData.repeat !== 'SINGLE') return;

    setNonValidationData({
      ...nonValidationData,
      repeat: 'WEEKS:1',
    });
  }

  const setRepeatType = (newType: 'WEEKS:1' | 'WEEKS:2' | 'WEEKS:4') => {
    setNonValidationData({
      ...nonValidationData,
      repeat: newType,
    });
  }



  return (
    <div className={styles['container']}>

      {!isSeriesEdit &&
        <div className={styles['container-row']}>
          <div
            className={rStyles['radio-item'] + ' ' + (repeat === 'SINGLE' ? rStyles['active'] : '')}
            onClick={setToSingleSession}
          >
            <span className={rStyles['label']}>
              Single Session
            </span>
          </div>

          <div
            className={rStyles['radio-item'] + ' ' + (repeat !== 'SINGLE' ? rStyles['active'] : '')}
            onClick={setToRepeatSession}
          >
            <span className={rStyles['label']}>
              Recurring Session
            </span>
          </div>

        </div>
      }


      {repeat !== 'SINGLE' &&

        <>
          <div className={styles['container-row']}>
            <div
              className={rStyles['radio-item'] + ' ' + (repeat === 'WEEKS:1' ? rStyles['active'] : '')}
              onClick={() => setRepeatType('WEEKS:1')}
            >
              <span className={rStyles['label']}>
                Weekly
              </span>
            </div>
            <div
              className={rStyles['radio-item'] + ' ' + (repeat === 'WEEKS:2' ? rStyles['active'] : '')}
              onClick={() => setRepeatType('WEEKS:2')}
            >
              <span className={rStyles['label']}>
                Bi-Weekly
              </span>
            </div>
            <div
              className={rStyles['radio-item'] + ' ' + (repeat === 'WEEKS:4' ? rStyles['active'] : '')}
              onClick={() => setRepeatType('WEEKS:4')}
            >
              <span className={rStyles['label']}>
                Every 4 Weeks
              </span>
            </div>
          </div>

        </>
      }



      {repeat !== 'SINGLE' &&
        <div className={disableEnd ? styles['deactive'] : ''}>
          {disableEnd ?
            <h4 className={styles['repeat-text']}>This series will automatically end on {moment(nonValidationData.seriesEndTime).format(timeConstants.dateFormat)}</h4>
            :
            <>
              <h4 className={styles['repeat-text']}>Do you want the recurring sessions to end on a given date?</h4>
              <SeriesEndTimeInput
                data={data}
                nonValidationData={nonValidationData}
                setNonValidationData={setNonValidationData}
              />

            </>

          }

        </div>
      }


    </div>
  )

}