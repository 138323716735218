import styles from './HostRight.module.css';
import {
  SessionItemList,
  Testimonials,
  WellLoader,
  SessionItemListEmpty,
} from '_components';

import { useProfile } from '_hooks';
import { ISession } from '_types';

export const HostRight = () => {
  const { selectedUserData, isSelf } = useProfile();

  return (
    <div className={styles['container']}>
      <FutureSessions />

      <Testimonials
        uuid={selectedUserData.uuid}
        isSelf={isSelf}
        isSessionInfo={false}
      />

      <PastSessions />
    </div>
  );
};

const FutureSessions = () => {
  const { futureSessions, isSelf } = useProfile();

  const { allSessions, sessionsPagination, loadMore, isLoadingSessions } =
    futureSessions;

  if (isLoadingSessions)
    return (
      <div className={styles['is-loading']}>
        <WellLoader heightWidth={75} />
      </div>
    );

  //if nothing to show
  if (allSessions.length === 0) return <></>;

  return (
    <div className={styles['section']}>
      <div>
        <h4 className={styles['section-title']}>Hosting Soon</h4>
      </div>
      <div className={styles['sessions-row']}>
        {isSelf && allSessions.length <= 6 ? (
          <>
            {allSessions.slice(0, 5).map((session: ISession) => (
              <SessionItemList
                session={session}
                key={'profile-soon-' + session.uuid}
              />
            ))}

            <SessionItemListEmpty
              allowCreate={true}
              selectedGroup={{ uuid: '', name: '' }}
            />
          </>
        ) : (
          <>
            {allSessions.map((session: ISession) => (
              <SessionItemList
                session={session}
                key={'profile-soon-' + session.uuid}
              />
            ))}
          </>
        )}
      </div>

      {!sessionsPagination.isDone && (
        <span className={styles['view-more']} onClick={loadMore}>
          VIEW MORE
        </span>
      )}
    </div>
  );
};

const PastSessions = () => {
  const { pastSessions } = useProfile();

  const { allSessions, sessionsPagination, loadMore } = pastSessions;

  //if nothing to show
  if (allSessions.length === 0) return <></>;

  return (
    <div className={styles['section']}>
      <h4 className={styles['section-title']}>Past Hosted</h4>
      <div className={styles['sessions-row']}>
        {allSessions.map((session: ISession) => (
          <SessionItemList
            session={session}
            key={'profile-past-' + session.uuid}
          />
        ))}
      </div>

      {!sessionsPagination.isDone && (
        <span className={styles['view-more']} onClick={loadMore}>
          VIEW MORE
        </span>
      )}
    </div>
  );
};
