import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { groupForumActions } from '_actions';
const GROUP_FEED_LIMIT = 8;
const GROUP_REPLY_LIMIT = 2;

export const useActivityPagination = (group) => {
  const dispatch = useDispatch();

  const [activityMarkers, setActivityMarkers] = useState({
    first: null,
    current: null,
  });
  const [isOver, setIsOver] = useState(false); // no more to load
  const [isMore, setIsMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Loads items behind (older) the current marker
   */
  const loadBehindOfMarker = useCallback(
    //future typescript developers I am sorry
    async (parent, replyFirstLoad) => {
      if (isLoading) return;
      setIsLoading(true);
      const limit =
        typeof parent === 'string' && replyFirstLoad === true
          ? GROUP_REPLY_LIMIT
          : typeof parent === 'string'
          ? 100
          : GROUP_FEED_LIMIT;
      const response = await dispatch(
        groupForumActions.postList(
          group,
          limit,
          activityMarkers.current ? 'lt' : 'none',
          activityMarkers.current ? activityMarkers.current.uuid : null,
          typeof parent === 'string' ? parent : null
        )
      );

      if (response && response.length > 0) {
        //set new marker on current position
        setActivityMarkers((prevState) => {
          const newState = {
            ...prevState,
            current: response[response.length - 1],
          };
          //if we do not have a first set yet, set it
          //otherwise it was set in loadAheadOfMarker
          if (!activityMarkers.first) newState.first = response[0];
          return newState;
        });
        if (response.length < GROUP_FEED_LIMIT) {
          setIsOver(true);
        }
      } else {
        setIsOver(true); //Less than asked for so its over
      }
      setIsLoading(false);

      return Promise.resolve(response);
    },
    [activityMarkers, dispatch, group, isLoading]
  );

  /**
   * Loads everything ahead (newer) of current marker
   * parent: uuid of the parent post
   * total: the total number of posts if known
   */
  const loadAheadOfMarker = useCallback(
    async (parent, isFirstPost) => {
      const limit =
        typeof parent === 'string' && isFirstPost ? GROUP_REPLY_LIMIT : 100;
      const response = await dispatch(
        groupForumActions.postList(
          group,
          limit,
          activityMarkers.first ? 'gt' : 'none',
          activityMarkers.first ? activityMarkers.first.uuid : null,
          typeof parent === 'string' ? parent : null
        )
      );

      if (response && response.length > 0) {
        //set new marker on current position
        setActivityMarkers((prevState) => {
          let newState = {
            ...prevState,
            first: response[0],
          };
          if (!prevState.current)
            newState.current = response[response.length - 1];
          return newState;
        });
      }

      return Promise.resolve(response);
    },
    [activityMarkers, dispatch, group]
  );

  /**
   * Check for new activity but do not get
   */
  const checkForNewActivity = useCallback(async () => {
    if (!activityMarkers.first) return;

    const response = await dispatch(
      groupForumActions.postList(
        group,
        1,
        'gt',
        activityMarkers.first.uuid,
        null
      )
    );

    if (response && response.length > 0) setIsMore(true);
    else setIsMore(false);
    return Promise.resolve(response);
  }, [activityMarkers.first, dispatch, group]);

  return {
    isOver,
    setIsOver,
    isMore,
    setIsMore,
    loadBehindOfMarker,
    loadAheadOfMarker,
    checkForNewActivity,
  };
};
