import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ISession } from '_types';
import {
  componentActions,
  roomReportingActions,
  creatorActions,
} from '_actions';
import { notificationConstants } from '_constants';
import { AnalyticsSendEvent, history } from '_helpers';

export const useSessionOptions = (session: ISession) => {
  const dispatch = useDispatch();

  // deletes a session
  const deleteSession = useCallback(
    async (callback) => {
      dispatch(
        componentActions.openModal({
          title: 'Delete this Session?',
          body: notificationConstants.SESSION_DELETE_NOTICE,
          modalBodyStyle: { textAlign: 'center' },
          noButtonStyle: { background: '#999999' },
          yesButtonStyle: {
            background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
          },
          yesText: 'DELETE',
          noText: 'CANCEL',
          afterText: notificationConstants.SESSION_DELETE_NOTICE_AFTER,
          yesClick: async () => {
            const { uuid } = session;
            const response: any = await dispatch(
              creatorActions.deleteSession(uuid)
            );
            if (callback) callback(response);
            history.push('/'); // can go home before
            return Promise.resolve(1);
          },
          noClick: () => {},
        })
      );
    },
    [dispatch, session]
  );

  // reports a session
  const reportSession = useCallback(
    async (callback) => {
      dispatch(
        componentActions.openModal({
          title: 'Report this session?',
          body: notificationConstants.SESSION_REPORT_NOTICE,
          modalBodyStyle: { textAlign: 'center' },
          noButtonStyle: { background: '#999999' },
          yesButtonStyle: {
            background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
          },
          isInput: true,
          yesText: 'REPORT',
          noText: 'CANCEL',
          placeholder: 'Reason...',
          afterText: notificationConstants.SESSION_REPORT_NOTICE_AFTER,
          yesClick: (reason: string) => {
            dispatch(roomReportingActions.reportSession(session.uuid, reason));
            AnalyticsSendEvent('session_report', {
              session: session.uuid,
            });

            if (callback) callback(callback);
            return Promise.resolve(1);
          },
          noClick: () => {},
        })
      );
    },
    [dispatch, session.uuid]
  );

  return {
    deleteSession,
    reportSession,
  };
};
