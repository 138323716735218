
import { SessionRepeat } from '_components';
import styles from './InputGrouping.module.css';


export const InputGroupingRepeat = ({
  data,
  nonValidationData,
  setNonValidationData,
}: any) => {

  return (
    <section className={styles['input-grouping']}>
      <h2>Is this a recurring session?</h2>
      <p>
        Recurring sessions are automatically created on a regular schedule.
      </p>

      <SessionRepeat
        data={data}
        nonValidationData={nonValidationData}
        setNonValidationData={setNonValidationData}
        disableEnd={false}
        isSeriesEdit={false}
      />

    </section>
  )

}