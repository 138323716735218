import { roomService, sessionsService, breakoutRoomService } from '_services';
import { roomConstants } from '_constants';
import { parseRoomsData, AnalyticsSendEvent, sendSentryError } from '_helpers';

import { toastr } from 'react-redux-toastr';
import { AppDispatch } from '_reducers';
import { ESessionRoomSafety, IAccount, IUser } from '_types';

export const roomActions = {
  enterRoom,
  setSessionInfo,
  inSessionRoomPoll,
  clearTokenInfo,
  assignToRoom,
  checkPostSessionCompletions,
};

function enterRoom(session: string, room: number = 0) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await roomService.enterRoom(session, room);
      if (response.notice && response.notice.msg) {
        toastr.error(response.notice.msg, '');
        AnalyticsSendEvent('session_enter_room_error', { response: response });
        return Promise.resolve(0);
      } else {
        dispatch({
          type: roomConstants.ENTER_SESSION_ROOM_SUCCESS,
          roomInfo: response,
          currentRoom: room,
        });

        //if external then we open in new tab
        if (response.session_video.provider === 'EXTERNAL')
          window.open(response.session_video.provider_identifier, '_blank');

        return Promise.resolve(response.session_video);
      }
    } catch (error) {
      AnalyticsSendEvent('session_enter_room_error', {
        response: 'API CALL FAIL',
        error: error,
      });
      sendSentryError(error);
      return Promise.resolve(0);
    }
  };
}

function clearTokenInfo() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: roomConstants.CLEAR_TOKEN_INFO,
    });
  };
}

function setSessionInfo(session: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await sessionsService.getSessionInfo(session);
      if (response.notice) {
        return Promise.resolve(0);
      } else {
        dispatch({
          type: roomConstants.SET_SESSION_INFO,
          session: response.session,
        });
        return Promise.resolve(response.session);
      }
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function inSessionRoomPoll(
  session: string,
  userData: IAccount,
  hostPayload: any,
) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await roomService.sessionRoomList(session, hostPayload);

      //first check if cancelled
      if (!response || (response && response.notice)) {
        return Promise.resolve(response.notice.name);
      }

      if (response && response.session_rooms) {
        let roomData = response.session_rooms;

        // get the correct room, and check if the host is active
        let { correctRoom, hostIsActive } = parseRoomsData(
          roomData,
          userData.uuid,
        );

        let correctRoomSafety;

        // if we are admin at this point correctRoom = -1
        // this should be impossible for all other users
        if (correctRoom === -1 && !userData.is_admin) throw Error;
        else if (correctRoom === -1 && userData.is_admin) {
          // for admins every room is safe all of the time
          correctRoomSafety = ESessionRoomSafety.SAFE;
          correctRoom = 0; // for now correct room is main room
        } else {
          // dont like this but better to use the enum then strings
          correctRoomSafety =
            ESessionRoomSafety[
              roomData[correctRoom].safety as keyof typeof ESessionRoomSafety
            ];
        }

        dispatch({
          type: roomConstants.UPDATE_ROOM_DATA,
          roomData: roomData,
          correctRoomSafety: correctRoomSafety,
          correctRoom: correctRoom,
          hostIsActive: hostIsActive,
        });
      }

      // set the received payload
      if (response.session_payload) {
        dispatch({
          type: roomConstants.SET_RECEIVED_HOST_SIGNAL,
          receivedHostSignal: JSON.parse(response.session_payload),
        });
      }

      //Log the response
      AnalyticsSendEvent('session_session_room_poll', {
        response: response,
      });

      return Promise.resolve(1);
    } catch (error) {
      // log the response
      AnalyticsSendEvent('session_session_room_poll_error', {
        error: error,
      });

      return Promise.resolve('SESSION_TIME_SKEW');
    }
  };
}

function assignToRoom(session: string, user: string, room: number) {
  return () => {
    breakoutRoomService.assignToRoom(session, user, room);
  };
}

function checkPostSessionCompletions(session: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await roomService.checkPostSessionCompletions(session);
      if (response.session) {
        dispatch({
          type: roomConstants.SET_POST_SESSION_CHECKS,
          postSessionChecks: response.session,
        });
      }

      return Promise.resolve();
    } catch (error) {}
  };
}
