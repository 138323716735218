import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useActivityPagination } from '_hooks';
import { groupActions, groupForumActions, componentActions, adminDumpActions } from '_actions';
import { getObjSize } from '_helpers';
import { generalConstants } from '_constants';

/**
 * State & functions used & shared by the GroupForumProvider
 * Handles getting posts for the forum
 */
export const useForumState = (group, pinnedActivities) => {
  const dispatch = useDispatch();

  const [activities, setActivities] = useState(
    pinnedActivities && pinnedActivities.items
      ? [...pinnedActivities.items]
      : []
  ); //list of activities
  const [isLoading, setIsLoading] = useState(true); //isLoading the first time

  const {
    loadBehindOfMarker,
    checkForNewActivity,
    loadAheadOfMarker,
    isOver,
    isMore,
    setIsMore,
    setIsOver,
  } = useActivityPagination(group);

  const reloadActivity = useCallback(
    async (uuid, isPinned) => {
      if (!uuid) return;

      const response = await dispatch(groupForumActions.postGet(uuid));

      if (!response || !response.uuid) return;

      return setActivities((prevState) => {
        if (Object.prototype.toString.call(prevState) !== '[object Array]')
          return response;
        
        if (isPinned) response.is_pinned = true;
        return prevState.map((activityItem) => {
          if (activityItem.uuid === response.uuid) return response;
          return activityItem;
        });
      });
    },
    [dispatch]
  );

  // feel like this needs a dependency
  const removeActivity = useCallback(async (uuid) => {
    if (!uuid) return;

    setActivities((prevState) => {
      return prevState.filter((activityItem) => activityItem.uuid !== uuid);
    });
  }, []);

  // get older activities
  const getActivities = useCallback(async () => {
    if (isOver) return;

    let response = await loadBehindOfMarker();
    if (response) {
      if (pinnedActivities && pinnedActivities.itemIds)
        response = response.filter(
          (item) => !pinnedActivities.itemIds.includes(item.uuid)
        );

      setActivities((prevState) => [...prevState, ...response]);
      return Promise.resolve(1);
    }
    return Promise.reject();
  }, [isOver, loadBehindOfMarker, pinnedActivities]);

  // gets new activities
  const getNewActivities = useCallback(async () => {
    let response = await loadAheadOfMarker();
    if (response && response.length > 0) {
      if (pinnedActivities && pinnedActivities.itemIds)
        response = response.filter(
          (item) => !pinnedActivities.itemIds.includes(item.uuid)
        );
      setActivities((prevState) => [...response, ...prevState]);
    }
    setIsMore(false); //we always get everything in front
  }, [loadAheadOfMarker, pinnedActivities, setIsMore]);

  // tag a user in a forum post
  const tagUsers = useCallback(
    async (activity, postContent, taggedUsers) => {
      //handle tagged users. Need to check if in tagged list & still in string
      if (getObjSize(taggedUsers) > 0) {
        let promises = [];
        Object.keys(taggedUsers).map((key) => {
          if (postContent.includes('@' + taggedUsers[key]))
            promises.push(
              dispatch(groupForumActions.tagUser(activity.uuid, key))
            );
        });
        await Promise.all(promises); // don't need to wait for these
      }

      return Promise.resolve(1);
    },
    [dispatch]
  );

  const pinPost = useCallback(
    async (post) => {
      dispatch(adminDumpActions.pinPost(post));
    },
    [dispatch]
  );

  const resetPinPosts = useCallback(
    async (post) => {
      dispatch(adminDumpActions.resetPinPosts(post));
    },
    [dispatch]
  );

  // report the activity
  const reportActivity = useCallback(
    async (uuid) => {
      dispatch(
        componentActions.openModal({
          title: 'Report this post?',
          body: 'Please enter a reason for reporting this post.',
          noButtonStyle: { background: '#999999' },
          yesButtonStyle: {
            background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
          },
          isInput: true,
          yesText: 'REPORT',
          noText: 'CANCEL',
          placeholder: 'Reason...',
          afterText: 'Post has been reported.',
          yesClick: (reason) => {
            dispatch(groupForumActions.postReport(uuid, reason));
            return Promise.resolve(1);
          },
          noClick: () => {},
        })
      );
    },
    [dispatch]
  );

  // on init first get activities
  useEffect(() => {
    const start = async () => {
      await getActivities(); //get activities to start
      setTimeout(() => setIsLoading(false), 500);
    };
    start();

    // clear badges on page change.
    return () => {
      if (group !== generalConstants.SPECIAL_UUID)
        dispatch(groupActions.badgeReset(group, 'badge_forum_post'));
    };
  }, [group]);


  // every minute check for new activity
  useEffect(() => {
    if (!checkForNewActivity) return;
    const timeout = setInterval(() => {
      checkForNewActivity();
    }, 100000);
    return () => clearInterval(timeout);
  }, [checkForNewActivity]);

  return {
    //state
    activities,
    isLoading,
    isOver,
    isMore,

    //state functions
    setIsMore,
    setIsOver,
    setActivities,

    //functions
    reloadActivity,
    removeActivity,
    getActivities,
    getNewActivities,
    tagUsers,
    reportActivity,
    pinPost,
    resetPinPosts,
  };
};
