import { authService } from '_services';
import {
  authConstants,
  userConstants,
  roomConstants,
  notificationConstants,
  sessionsConstants,
} from '_constants';
import { sendSentryError } from '_helpers';
import { userActions } from '_actions';
import { AppDispatch } from '_reducers';
import { ICreateAccount } from '_types';

export const authActions = {
  loginWithPassword,
  register,
  handleLogout,
  setOnLoginRedirect,
  resetPasswordRequest,
  resetPasswordConfirm,
  setAccountInactive,
  loginThinkific,
};

// logs user into the site using email (or username) & password
function loginWithPassword(username_or_email: string, password: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await authService.loginWithPassword(
        username_or_email,
        password
      );

      if (response.notice) {
        return Promise.reject(response.notice.msg);
      } else {
        const response = await dispatch(userActions.getProfileInfo());
        if (response) {
          return Promise.resolve(true);
        }
      }
    } catch (error) {
      sendSentryError(error);
      return Promise.reject(
        'The page failed to load properly. Please refresh.'
      );
    }
  };
}

// request for a new user
function register(formData: ICreateAccount) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await authService.registerUser(formData);
      if (response.notice) {
        return Promise.reject(response.notice.msg);
      } else {
        //get the user data
        const response = await dispatch(userActions.getProfileInfo());
        if (response) {
          dispatch({
            type: authConstants.SET_CAN_GO_TO_ONBOARDING,
            canGoToOnboarding: true,
          });
          return Promise.resolve();
        }
        return Promise.reject(notificationConstants.SERVER_ERROR);
      }
    } catch (error) {
      sendSentryError(error);
      return Promise.reject(notificationConstants.SERVER_ERROR);
    }
  };
}

function handleLogout() {
  return async (dispatch: AppDispatch) => {
    try {
      await authService.handleLogout();
      dispatch({ type: userConstants.HANDLE_LOGOUT });
      dispatch({ type: roomConstants.CLEAR_SESSION_ROOM_DATA });
      dispatch({
        type: sessionsConstants.SET_MY_UPCOMING_SESSION,
        nextSession: {},
      });
      return Promise.resolve(1);
    } catch (error) {
      sendSentryError(error);
    }
  };
}

function setOnLoginRedirect(loginRedirect: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: authConstants.SET_ON_LOGIN_REDIRECT,
      loginRedirect: loginRedirect,
    });
  };
}

function resetPasswordRequest(email: string) {
  return async () => {
    try {
      await authService.resetPasswordRequest(email);
      return Promise.resolve(1);
    } catch (error) {
      sendSentryError(error);
      return Promise.resolve(0);
    }
  };
}

function resetPasswordConfirm(
  email: string,
  secret: string,
  newPassword: string
) {
  return async () => {
    try {
      const response = await authService.resetPasswordConfirm(
        email,
        secret,
        newPassword
      );
      return Promise.resolve(response);
    } catch (error) {
      sendSentryError(error);
      return Promise.resolve(0);
    }
  };
}

function setAccountInactive() {
  return async (dispatch: AppDispatch) => {
    try {
      await authService.setAccountInactive();
      dispatch(handleLogout); // log the user out
    } catch (error) {
      sendSentryError(error);
      return Promise.resolve(0);
    }
  };
}

// logs user into the site using email (or username) & password
function loginThinkific() {
  return async () => {
    try {
      const response = await authService.loginThinkific();
      if (!response.url) {
        return Promise.reject(response.notice.msg);
      } else {
        return Promise.resolve(response.url);
      }
    } catch (error) {
      sendSentryError(error);
      return Promise.reject('Server Error');
    }
  };
}
