import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history, isEmpty } from '_helpers';
import { RootState } from '_reducers';
import { authActions } from '_actions';
import { EHostLevel } from '_types';
import { useWellSelector } from '_hooks';

// is the user logged in?
export const LoggedinRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const { userStore } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  if (userStore.loggedIn)
    return <Route {...rest} render={(props) => <Component {...props} />} />;

  dispatch(authActions.setOnLoginRedirect(history.location.pathname));

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )}
    />
  );
};

// is the user logged out?
export const LoggedOutRoute: React.FC<any> = ({
  component: Component = null,
  render: Render = null,
  ...rest
}) => {
  const { userStore, authStore } = useSelector((state: RootState) => state);

  // you are good to go
  if (!userStore.loggedIn || authStore.isLoggingIn) {
    return (
      <Route
        {...rest}
        render={(props) => (Render ? Render(props) : <Component {...props} />)}
      />
    );
  }

  // redirect
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  );
};

// can user enter a room?
export const CanEnterRoom: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const { userStore, roomStore } = useWellSelector((state) => state);

  if (userStore.loggedIn && !isEmpty(roomStore.sessionInfo))
    return <Route {...rest} render={(props) => <Component {...props} />} />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: '/wells', state: { from: props.location } }}
        />
      )}
    />
  );
};

// can user create a session?
export const CanCreateSession: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const { uuid } = rest.computedMatch.params;

  const userStore = useSelector((state: RootState) => state.userStore);

  // These users need to complete the host check list
  if (
    userStore.loggedIn &&
    userStore.userData.host_level < EHostLevel.HOST_LEVEL_1
  )
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: '/host', state: { from: props.location } }}
          />
        )}
      />
    );

  // these people just can not host at all
  // need to login, verify email
  // can't be to welcome well
  if (
    !userStore.loggedIn ||
    !userStore.userData.email ||
    (uuid === process.env.REACT_APP_WELCOME_GROUP_UUID &&
      userStore.userData.host_level !== EHostLevel.HOST_LEVEL_2)
  )
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
      />
    );

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

// is user admin?
export const IsAdmin: React.FC<any> = ({ component: Component, ...rest }) => {
  const userStore = useSelector((state: RootState) => state.userStore);

  if (userStore.isAdmin)
    return <Route {...rest} render={(props) => <Component {...props} />} />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: '/wells', state: { from: props.location } }}
        />
      )}
    />
  );
};

// did the user just register? (uses redux state)
export const OnboardingRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const { userStore, authStore } = useSelector((state: RootState) => state);

  if (authStore.canGoToOnboarding || userStore.isAdmin)
    return <Route {...rest} render={(props) => <Component {...props} />} />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  );
};
