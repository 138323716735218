import { WellButton, WellButtonLink } from '_components';
import styles from './StripBands.module.css';
import HomepageGraphic from '_images/homepage-graphic.svg';

// Mark: Deletion
export const Statistics = () => {
  return (
    <section className={styles['statistics']}>
      <div className={styles['statistics-container'] + ' container'}>
        <div className={styles['statistics-row'] + ' row'}>
          <h3 className={styles['line-one']}>90% report being happier</h3>
          <h2 className={styles['line-two']}>after a ShareWell session</h2>
          <div className={styles['stat-graphic-container']}>
            <img
              src={HomepageGraphic}
              className={styles['stat-graphic']}
              alt={'Happy People'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const SignupToday = () => {
  return (
    <section className={styles['signup-today']}>
      <div className={styles['signup-container'] + ' container'}>
        <div className={styles['signup-row'] + ' row'}>
          <h2 className={styles['signup-title']}>We Are Stronger Together!</h2>
          <p className={styles['signup-text']}>
            Better mental health starts with a small step forward.
          </p>

          <WellButtonLink
            className={styles['signup-btn']}
            to={'/how-it-works'}
            btnText={'LEARN MORE'}
            type={'well-btn-orange'}
          />
        </div>
      </div>
    </section>
  );
};

export const GiveTheGiftOfSupport = () => {
  const scroll = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  return (
    <section className={styles['signup-today']}>
      <div className={styles['signup-container'] + ' container'}>
        <div className={styles['signup-row'] + ' row'}>
          <h2 className={styles['signup-title']}>GIVE THE GIFT OF SUPPORT</h2>

          <WellButton
            className={styles['signup-btn']}
            btnOnClick={scroll}
            btnText={'SEND NOW'}
            type={'well-btn-orange'}
          />
        </div>
      </div>
    </section>
  );
};
