import styles from './SessionHost.module.css';
import { Link } from 'react-router-dom';
import { useGetImgixVersion, useWellSelector } from '_hooks';
import { TopStat, HostBadges } from '_components';
import { IUser } from '_types';

interface ISessionHost {
  host: IUser;
  showDesc: boolean;
  showBadges: boolean;
}

export const SessionHost = ({ host, showDesc, showBadges }: ISessionHost) => {
  const { loggedIn } = useWellSelector((state) => state.userStore);
  const { pic, username, user_marketing_json } = host;

  const userImage = useGetImgixVersion(pic, {
    w: 65,
    h: 65,
    fit: 'crop',
    crop: 'focalpoint',
  });

  return (
    <div
      className={
        styles['organizer-container'] +
        ' ' +
        (loggedIn ? styles['logged-in'] : '')
      }
    >
      <h3 className={styles['title']}>About the Host</h3>
      <Link
        to={'/profile/' + username}
        className={styles['organizer-row']}
        style={!loggedIn ? { pointerEvents: 'none' } : undefined}
      >
        <div className={styles['organizer-img-container']}>
          <img
            src={userImage}
            className={styles['organizer-img']}
            alt={username}
          />
        </div>
        <div>
          <h4 className={styles['organizer-name']}>{username}</h4>

          <div className={styles['badges-row']}>
            <HostBadges selectedUserData={host} />
            {user_marketing_json?.profile_info?.credentials && (
              <div className={styles['credentials']}>
                {user_marketing_json?.profile_info?.credentials
                  .slice(0, 1)
                  .map((text: string) => (
                    <div
                      className={styles['credential']}
                      key={'host-credential-' + text}
                    >
                      {text}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Link>
      {showDesc && <p className={styles['organizer-about']}>{host.about_me}</p>}
      {showBadges && (
        <div className={styles['top-stats-row']}>
          <TopStat
            colorFill={'#CCEBFF'}
            color={'#5FA2CD'}
            number={host.num_session_users_met}
            string={'Peers Supported'}
            isPercent={false}
            isSmall={true}
          />
          <TopStat
            colorFill={'#FFF1D5'}
            color={'#F9B731'}
            number={host.num_sessions_hosted}
            string={'Sessions Hosted'}
            isPercent={false}
            isSmall={true}
          />
          {host.host_level > 0 && (
            <TopStat
              colorFill={'#D5FFEF'}
              color={'#95DAC0'}
              number={
                host.testimonials_pct_thumbs_up
                  ? host.testimonials_pct_thumbs_up
                  : 100
              }
              string={'Positive Reviews'}
              isPercent={true}
              isSmall={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const SessionHostless = () => {
  const { loggedIn } = useWellSelector((state) => state.userStore);
  return (
    <div
      className={
        styles['organizer-container'] +
        ' ' +
        (loggedIn ? styles['logged-in'] : '')
      }
    >
      <h3 className={styles['title']}>About Group-Hosted Sessions</h3>
      <p className={styles['organizer-about']}>
        We're trying something new! Group-Hosted sessions are led by the
        attendees. ShareWell will provide an agenda and instructions for how to
        have a connective, peer-led discussion. As a new offering, Group-hosted
        Sessions do not count against your monthly session allocation.
      </p>
    </div>
  );
};
