import styles from './SessionsListSearch.module.css';
import { WellSearchInput } from '_components';
import { useSessionsList } from '_hooks';

export const SessionsListSearch = () => {

  const { searchPhrase, handleSearchChange } = useSessionsList();

  function keyDown(e) {
    if (e.key === '\\' || e.key === ']' || e.key === '[') {
      e.preventDefault();
      return false;
    }
  }

  return (
    <div className={styles['search-bar-container']} onKeyDown={keyDown}>
      <WellSearchInput
        autoFocus={false}
        value={searchPhrase ? searchPhrase : ''}
        readOnly={false}
        onChange={handleSearchChange}
        onBlur={() => {}}
        name={'search-bar'}
        placeHolder={'Search'}
      />
    </div>
  );
};
