import { useCallback } from 'react';
import styles from './SessionDurationRadio.module.css';
import { WellLabel } from '_components';




export const SessionDurationRadio = ({ data, setData }: any) => {

  const handleChange = useCallback((newTime: number) => {
    setData({ ...data, duration: newTime });

  }, [data, setData]);

  return (

    <>
      <WellLabel label={'Session Duration'} />
      <div className={styles['radio-container-row']}>
        <div
          className={styles['radio-item'] + ' ' + (data.duration === 30 ? styles['active'] : '')}
          onClick={() => handleChange(30)}
        >
          <span className={styles['label']}>
            30 Minutes
          </span>
        </div>

        <div
          className={styles['radio-item'] + ' ' + (data.duration === 60 ? styles['active'] : '')}
          onClick={() => handleChange(60)}
        >
          <span className={styles['label']}>
            60 Minutes
          </span>
        </div>


        <div
          className={styles['radio-item'] + ' ' + (data.duration === 90 ? styles['active'] : '')}
          onClick={() => handleChange(90)}
        >
          <span className={styles['label']}>
            90 Minutes
          </span>
        </div>


      </div>
    </>
  );

}
