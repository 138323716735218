import React from 'react';
import styles from './WellBulletItem.module.css';


interface IWellButtonItemP {
  text: string;
  type?: string;
  className?: string
}

export const WellBulletItem: React.FC<IWellButtonItemP> = ({ text, type, className }) => {

  return (
    <div className={styles['bullet-item'] + ' ' + (className ? className : '')}>
      {type === 'check' ? (
        <div className={styles['green-check-container']}>
          <img src={process.env.REACT_APP_ASSETS_URL + '/site/hosts/checkmark.svg'} className={styles['check']} alt={'Check Mark'} />
        </div>
      ) : type === 'x' ? (
        <div className={styles['red-x-container']}>
          <img src={process.env.REACT_APP_ASSETS_URL + '/site/hosts/x-mark.svg'} className={styles['x-mark']} alt={'X Mark'} />
        </div>
      ) : (
        <div className={styles['circle-container']}>
          <img src={process.env.REACT_APP_ASSETS_URL + '/site/hosts/circle.svg'} className={styles['circle']} alt={'Big Circle'} />
        </div>
      )}
      <h4 className={styles['bullet-item-text']}>
        {text}
      </h4>
    </div>
  );
};
