import {
  FindingOne,
  FindingThree,
  FindingTwo,
  SessionHost,
  WellButton,
} from '_components';
import styles from './SessionExperience.module.css';
import { EQuizAnswers, QuizAnswersToSessionData } from '_types';
import { useGetImgixVersion, useGetUsersInfo, useWellDispatch } from '_hooks';
import { componentsConstants } from '_constants';

interface ISessionExperience {
  dataKey: EQuizAnswers;
}

export const SessionExperience = ({ dataKey }: ISessionExperience) => {
  const dispatch = useWellDispatch();

  const dataToUse = QuizAnswersToSessionData[dataKey];

  const backgroundImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + dataToUse.backgroundImage,
    {
      w: 2000,
      h: 2000,
      fit: 'crop',
      crop: 'entropy',
    }
  );

  const { userList, userListLoading } = useGetUsersInfo([dataToUse.host], 1);

  const openRegisterModal = () => {
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isRegisterModalOpen: {
        isOpen: true,
        skipOnboarding: false,
        redirect: '',
      },
    });
  };

  return (
    <section className={styles['main-container']}>
      <div className={styles['top-section']}>
        <h3 className={styles['section-title']}>
          ShareWell Session Experience
        </h3>
        <p className={styles['section-text']}>
          ShareWell peer support sessions are virtual meetings with a group of
          3-12 people who meet to support each other in overcoming mental health
          issues or through big life transitions. Sessions are led by a peer
          host who shares your life experience.
        </p>
      </div>

      <div
        className={styles['middle-section']}
        style={{
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(' +
            backgroundImage +
            ')',
        }}
      >
        <div
          className={
            styles['session-container'] +
            ' ' +
            (userList && userList.length == 1 ? styles['has-host'] : '')
          }
        >
          <div className={styles['featured-badge']}>
            <img
              src={
                process.env.REACT_APP_ASSETS_URL +
                '/site/session/featured-session-banner.svg'
              }
              alt={'Featured Session'}
            />
          </div>

          <h3 className={styles['group-name']}>{dataToUse.group}</h3>
          <h3 className={styles['session-name']}>{dataToUse.title}</h3>
          <p className={styles['session-desc']}>{dataToUse.description}</p>

          {userList && userList.length == 1 && (
            <div className={styles['host-info']}>
              <SessionHost
                host={userList[0]}
                showBadges={true}
                showDesc={false}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles['bottom-section']}>
        <h3 className={styles['section-title']}>ShareWell Findings</h3>
        <p className={styles['section-text']}>
          When using ShareWell, members report a boost in overall well-being.
        </p>

        <div className={styles['faces-row']}>
          <FindingOne isWhite={true} />
          <FindingTwo isWhite={true} />
          <FindingThree isWhite={true} />
        </div>

        <div className={styles['button-container']}>
          <WellButton
            btnOnClick={openRegisterModal}
            type={'well-btn-orange'}
            btnText={'TRY IT FREE TODAY'}
            className={styles['try-btn']}
          />
        </div>
      </div>
    </section>
  );
};
