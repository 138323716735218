import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


export function sentryInit(){
    //enable sentry
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.01, // We recommend adjusting this value in production, or using tracesSampler for finer control
    });
}


export function sendSentryError(error){
  Sentry.captureException(error);
}
