import styles from './LegalDocuments.module.css';
import { WellHeader } from '_components';

export function TermsAndConditions() {
  return (
    <div className={styles['legal-document']}>
      <WellHeader title={'Terms & Conditions'} />

      <div className={styles['legal-document-container'] + ' container'}>
        <p className={styles['terms-p']}>
          The following are the Terms and Conditions (the "Agreement") which
          govern your access and use of our online and app platform through
          which peer support may be provided (collectively the "Platform"). This
          website is owned and operated by ShareWell Labs, Co. d.b.a. ShareWell.
          The Platform may be provided or be accessible via multiple websites or
          applications whether owned and/or operated by us or by third parties,
          including, without limitation, the website sharewellnow.com and its
          related Platforms.
        </p>
        <p className={styles['terms-p']}>
          By accessing or using the Platform, you are entering into this
          Agreement. You should read this Agreement carefully before starting to
          use the Platform. If you do not agree to be bound to any term of this
          Agreement, you must not access the Platform.
        </p>
        <p className={styles['terms-p']}>
          When the terms "we", "us", "our" or similar are used in this
          Agreement, they refer to any company that owns and operates the
          Platform (the "Company").
        </p>

        <h4>The Hosts and Host Services</h4>
        <p className={styles['terms-p']}>
          The Platform may be used to connect you with a Host who will provide
          services to you through the Platform (“Host Services”).
        </p>
        <p className={styles['terms-p']}>
          The Hosts are independent providers who are neither our employees nor
          agents nor representatives. The Platform’s role is limited to enabling
          the Host Services while the Host Services themselves are the
          responsibility of the Host who provides them. If you feel the Host
          Services provided by the Host do not fit your needs or expectations,
          you may change to a different Host who provides services through the
          Platform.
        </p>
        <p className={styles['terms-p']}>
          While we hope the Host Services are beneficial to you, you understand,
          agree and acknowledge that they may not be the appropriate solution
          for everyone’s needs and that they may not be appropriate for every
          particular situation and/or may not be a complete substitute for a
          face-to-face examination and/or care in every particular situation.
        </p>
        <p className={styles['terms-p']}>
          From time to time, the Platform may include content, text, data,
          graphics, images, photographs, audio, video, information, suggestions,
          guidance and other materials including, without limitation, outputs
          provided by third parties and outputs provided in direct response to
          your inputs (“Outputs”). Outputs do not constitute, and are not
          intended for use as or as a substitute for, medical advice, diagnosis
          or treatment. The outputs are not personalized to you and do not
          diagnose any condition you may or may not have. You should always talk
          to a qualified medical provider about any questions you may have about
          a medical condition, symptoms or your health. Always seek the advice
          of a physician or other qualified healthcare provider concerning
          questions you have regarding your symptoms, health or a medical
          condition, and before starting, stopping or modifying any treatment,
          medication or care plan or making any other medical decisions. Any
          decisions you make affecting your health should always be made in
          consultation with a medical provider.
        </p>
        <p className={styles['terms-p']}>
          We do not warrant the accuracy, completeness, or usefulness of any
          Outputs or other information presented on or through the Platform. Any
          reliance you place on such information is strictly at your own risk.
          We disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Platform,
          or by anyone who may be informed of any of its contents or other
          Outputs.
        </p>
        <p className={styles['terms-p']}>
          Outputs provided by third parties, third-party licensors, aggregators
          and/or reporting services are solely the opinions and the
          responsibility of the person or entity providing those materials. We
          are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <p className={styles['terms-p']}>
          IF YOU ARE THINKING ABOUT SUICIDE OR IF YOU ARE CONSIDERING HARMING
          YOURSELF OR OTHERS OR IF YOU FEEL THAT ANY OTHER PERSON MAY BE IN ANY
          DANGER OR IF YOU HAVE ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL
          THE EMERGENCY SERVICE NUMBER (911 IN THE US) AND NOTIFY THE RELEVANT
          AUTHORITIES. SEEK IMMEDIATE IN PERSON ASSISTANCE. THE PLATFORM IS NOT
          DESIGNED FOR USE IN ANY OF THE AFOREMENTIONED CASES AND THE HOSTS
          CANNOT PROVIDE THE ASSISTANCE REQUIRED IN ANY OF THE AFOREMENTIONED
          CASES.
        </p>
        <p className={styles['terms-p']}>
          THE PLATFORM IS NOT INTENDED FOR THE PROVISION OF CLINICAL DIAGNOSIS
          REQUIRING AN IN-PERSON EVALUATION AND YOU SHOULD NOT USE IT IF YOU
          NEED ANY OFFICIAL DOCUMENTATION OR APPROVALS FOR PURPOSES SUCH AS, BUT
          NOT LIMITED TO, COURT-ORDERED PEER SUPPORT OR EMOTIONAL SERVICE DOG
          CERTIFICATION. IT IS ALSO NOT INTENDED FOR ANY INFORMATION REGARDING
          WHICH DRUGS OR MEDICAL TREATMENT MAY BE APPROPRIATE FOR YOU, AND YOU
          SHOULD DISREGARD ANY SUCH ADVICE IF DELIVERED THROUGH THE PLATFORM.
        </p>
        <p className={styles['terms-p']}>
          DO NOT DISREGARD, AVOID, OR DELAY IN OBTAINING IN-PERSON CARE FROM
          YOUR DOCTOR OR OTHER QUALIFIED PROFESSIONAL BECAUSE OF INFORMATION OR
          ADVICE YOU RECEIVED THROUGH THE PLATFORM.
        </p>

        <h4>Who Can Use the Platform</h4>
        <p className={styles['terms-p']}>
          The Platform is offered and available to users who are 18 years of age
          or older, and reside in the United States or any of its territories or
          possessions.
        </p>
        <p className={styles['terms-p']}>
          By using this Platform, you represent and warrant that you are of
          legal age to form a binding contract with the Company and meet all of
          the foregoing eligibility requirements. If you do not meet all of
          these requirements, you must not access or use the Platform.
        </p>

        <h4>Intellectual Property Rights</h4>
        <p className={styles['terms-p']}>
          The Platform, which includes its entire contents and other Outputs,
          features, and functionality (including but not limited to all
          information, features, software, text, displays, images, video, and
          audio, and the design, selection and arrangement thereof), is owned by
          the Company, its licensors or other providers of such material and
          protected by United States and international copyright, trademark,
          patent, trade secret and other intellectual property or proprietary
          rights laws.
        </p>

        <p className={styles['terms-p']}>
          This Agreement permits you to use the Platform for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store or transmit any of the material on our
          Platform, except as follows:
        </p>
        <ul>
          <li>
            Your mobile device may temporarily store copies of such materials in
            RAM incidental to your accessing and viewing those materials.
          </li>
          <li>
            Our Platform is available for download to your mobile device, and if
            you do so, it may update automatically. So long as you comply with
            this Agreement, we give you a limited, nonexclusive,
            non-transferable, revocable license to use the Platform, solely for
            your own personal, non-commercial use.
          </li>
          Except and solely to the extent such a restriction is impermissible
          under applicable law, you must not:
          <li>
            Reproduce, distribute, publicly display or publicly perform the
            Platform.
          </li>
          <li>Make modifications to the Platform.</li>
          <li>
            Interfere with or circumvent any feature of the Platform, including
            any security or access control mechanism.
          </li>
          <li>
            Reverse engineer or decompile any part of the Platform or any
            services or materials available through the Platform, attempt to do
            so, or assist anyone in doing so.
          </li>
          <li>
            Access or use for any commercial purposes any part of the Platform
            or any services or materials available through the Platform.
          </li>
          <li>
            Use the Platform if you are prohibited under applicable law from
            doing so.
          </li>
          <li>
            Engage in any other use or activity that violates any applicable
            law, intellectual property, privacy or other rights, or that would
            otherwise give rise to liability.
          </li>
        </ul>

        <p className={styles['terms-p']}>
          No right, title or interest in or to the Platform, which includes any
          content on the Platform or other Output, is transferred to you, and
          all rights not expressly granted are reserved by the Company. Any use
          of the Platform not expressly permitted by these Agreement is a breach
          of these Agreement and may violate intellectual property, copyright,
          trademark, and other laws.
        </p>

        <p className={styles['terms-p']}>Notwithstanding any of the above:</p>
        <p className={styles['terms-p']}>
          To the extent any component of the Platform may be offered under an
          open-source license, we will make that license available to you and
          the provisions of that license may expressly override some of these
          Agreement.
        </p>
        <p className={styles['terms-p']}>
          You may communicate information provided through the Platform to an
          accredited, licensed counselor/therapist, provided you do not remove
          or obscure any notices regarding intellectual property rights.
        </p>
        <p className={styles['terms-p']}>
          You agree that your use of the Platform will not intentionally
          infringe or facilitate infringement on any copyright, patent,
          trademark, trade secret or other proprietary, publicity or privacy
          rights of any party, including such rights of third parties. The
          Company maintains the right to delete any information provided by you
          that it deems fraudulent, abusive, defamatory, and obscene or in
          violation of a copyright, trademark or other intellectual property or
          ownership right of any other person.
        </p>
        <p className={styles['terms-p']}>
          If you choose to provide input and suggestions regarding problems with
          or proposed modifications or improvements to the Platform
          (“Feedback”), then you hereby grant the Company an unrestricted,
          perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right
          to exploit the Feedback in any manner and for any purpose, including
          to improve the Platform and create other products and services.
        </p>

        <h4>User Content</h4>
        <p className={styles['terms-p']}>
          Certain features of the Platform may permit you to upload content to
          the Platform, including descriptions of symptoms, photos, and other
          types of works (“User Content”). You retain any copyright and other
          proprietary rights that you may hold in the User Content that you post
          to the Platform.{' '}
        </p>
        <p className={styles['terms-p']}>
          By providing User Content to or via the Platform, you grant us a
          worldwide, non-exclusive, irrevocable, royalty-free, fully paid right
          and license (with the right to sublicense) to host, store, transfer,
          display, perform, reproduce, modify, and distribute your User Content
          in connection with providing the Platform to you and improving the
          Platform and the Company’s other products and services.
        </p>
        <p className={styles['terms-p']}>
          The Company disclaims all liability in connection with User Content.
          You are solely responsible for your User Content and the consequences
          of providing User Content via the Platform.
        </p>
        <p className={styles['terms-p']}>
          The Company does not control and does not have any obligation to
          monitor: (a) User Content; (b) any content made available by third
          parties; or (c) the use of the Platform by its users. You acknowledge
          and agree that the Company reserves the right to, and may from time to
          time, monitor all information transmitted or received through the
          Platform for operational and other purposes. If at any time the
          Company chooses to monitor the content, the Company still assumes no
          responsibility or liability for content or any loss or damage incurred
          as a result of the use of content. During monitoring, information may
          be examined, recorded, copied and used.
        </p>

        <h4>Trademarks</h4>
        <p className={styles['terms-p']}>
          The Company name, the Company logo, and all related names, logos,
          product and service names, designs and slogans are trademarks of the
          Company, its affiliates or licensors. You must not use such marks
          without the prior written permission of the Company. All other names,
          logos, product and service names, designs and slogans on this Platform
          are the trademarks of their respective owners.
        </p>

        <h4>Arbitration Notice and Class Action Waiver</h4>
        <p className={styles['terms-p']}>
          Except for certain kinds of disputes described in the “Disputes”
          section below, you agree that disputes arising under this Agreement
          will be resolved by binding, individual arbitration, and BY ACCEPTING
          THESE TERMS, YOU WAIVE THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE
          IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP
          YOUR RIGHT TO GO TO COURT to assert or defend your rights under this
          contract (except for matters that may be taken to small claims court).
          Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge
          or jury.
        </p>

        <h4>Privacy and Security</h4>
        <p className={styles['terms-p']}>
          Protecting and safeguarding any information you provide through the
          Platform is extremely important to us. Information about our security
          and privacy practices can be found on our Privacy Policy available at{' '}
          <a href='/privacy-policy'>https://sharewellnow.com/privacy-policy</a>{' '}
          (The ‘Privacy Policy’).
        </p>
        <p className={styles['terms-p']}>
          BY AGREEING TO THIS AGREEMENT AND/OR BY USING THE PLATFORM, YOU ARE
          ALSO AGREEING TO THE TERMS OF THE PRIVACY POLICY. THE PRIVACY POLICY
          IS INCORPORATED INTO AND DEEMED A PART OF THIS AGREEMENT. THE SAME
          RULES THAT APPLY REGARDING CHANGES AND REVISIONS OF THIS AGREEMENT
          ALSO PLATFORM TO CHANGES AND REVISIONS OF THE PRIVACY POLICY.
        </p>

        <h4>Third Party Content</h4>
        <p className={styles['terms-p']}>
          The Platform may contain other content, products or services which are
          offered or provided by third parties ("Third Party Content"), links to
          Third Party Content (including but not limited to links to other
          websites) or advertisements which are related to Third Party Content.
          We have no responsibility for the creation of any such Third Party
          Content, including (but not limited to) any related products,
          practices, terms or policies, and we will not be liable for any damage
          or loss caused by any Third Party Content.
        </p>

        <h4>Disclaimer of Warranties</h4>
        <p className={styles['terms-p']}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
          YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE PLATFORM OR THROUGH ANY SITES OR RESOURCES LINKED
          TO IT.
        </p>
        <p className={styles['terms-p']}>
          YOUR USE OF THE PLATFORM, ITS CONTENT, OUTPUT, AND ANY SERVICES
          OBTAINED THROUGH THE PLATFORM IS AT YOUR OWN RISK. THE PLATFORM, ITS
          CONTENT, OUTPUT, AND ANY SERVICES OBTAINED THROUGH THE PLATFORM ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE PLATFORM, ITS
          CONTENT, OUTPUT OR ANY SERVICES OBTAINED THROUGH THE PLATFORM. WITHOUT
          LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
          THE COMPANY REPRESENTS OR WARRANTS THAT THE PLATFORM, ITS CONTENT,
          OUTPUT OR ANY SERVICES OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE,
          RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
          THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE PLATFORM OR ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL OTHERWISE MEET
          YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p className={styles['terms-p']}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p className={styles['terms-p']}>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <h4>Limitation of Liability</h4>
        <p className={styles['terms-p']}>
          TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, IN NO EVENT
          WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
          PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR
          DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE OF, OR INABILITY TO USE, THE PLATFORM, ANY
          APPS LINKED TO IT, ANY CONTENT ON OR OUTPUT OF THE PLATFORM, INCLUDING
          ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
          OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. FOR THE AVOIDANCE OF
          DOUBT, IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
          DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON THE PLATFORM, ANY
          APPS LINKED TO IT OR ANY CONTENT ON OR OUTPUT OF THE PLATFORM.
        </p>
        <p className={styles['terms-p']}>
          YOU UNDERSTAND, AGREE AND ACKNOWLEDGE THAT OUR AGGREGATE LIABILITY FOR
          DAMAGES ARISING WITH RESPECT TO THIS AGREEMENT AND ANY AND ALL USE OF
          THE PLATFORM WILL NOT EXCEED THE TOTAL AMOUNT OF MONEY PAID BY YOU
          THROUGH THE PLATFORM IN THE 3 MONTH PERIOD PRIOR TO THE DATE OF THE
          CLAIM.
        </p>
        <p className={styles['terms-p']}>
          The limitation of liability set out above does not apply to liability
          resulting from our gross negligence or willful misconduct.
        </p>
        <p className={styles['terms-p']}>
          THIS SECTION (“LIMITATION ON LIABILITY”) DOES NOT AFFECT ANY LIABILITY
          THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p className={styles['terms-p']}>
          If the applicable law does not allow the limitation of liability as
          set forth above, the limitation will be deemed modified solely to the
          extent necessary to comply with applicable law.
        </p>
        <p className={styles['terms-p']}>
          This section (limitation of liability) shall survive the termination
          or expiration of this Agreement.
        </p>

        <p className={styles['terms-p']}>
          Your account, representations, conduct and commitments
        </p>
        <p className={styles['terms-p']}>
          You hereby confirm that you are legally able to consent to receive
          Services, and that you are legally able to enter into a contract.
        </p>
        <p className={styles['terms-p']}>
          You hereby confirm and agree that all the information that you
          provided in or through the Platform, and the information that you will
          provide in or through the Platform in the future, is accurate, true,
          current and complete. Furthermore, you agree that during the term of
          this Agreement you will make sure to maintain and update this
          information so it will continue to be accurate, current and complete.
        </p>
        <p className={styles['terms-p']}>
          You agree, confirm and acknowledge that you are responsible for
          maintaining the confidentiality of your password and any other
          security information related to your account (collectively "Account
          Access"). We advise you to change your password frequently and to take
          extra care in safeguarding your password.
        </p>
        <p className={styles['terms-p']}>
          You agree to notify us immediately of any unauthorized use of your
          Account Access or any other concern for breach of your account
          security.
        </p>
        <p className={styles['terms-p']}>
          You agree, confirm and acknowledge that we will not be liable for any
          loss or damage that incurred as a result of someone else using your
          account, either with or without your consent and/or knowledge.
        </p>
        <p className={styles['terms-p']}>
          You agree, confirm and acknowledge that you are solely and fully
          liable and responsible for all activities performed using your Account
          Access. You further acknowledge and agree that we will hold you liable
          and responsible for any damage or loss incurred as a result of the use
          of your Account Access by any person whether authorized by you or not,
          and you agree to indemnify us for any such damage or loss.
        </p>
        <p className={styles['terms-p']}>
          You agree and commit not to use the account or Account Access of any
          other person for any reason.
        </p>
        <p className={styles['terms-p']}>
          You agree and confirm that your use of the Platform, including the
          Host Services, are for your own personal use only and that you are not
          using the Platform or the Host Services for or behalf of any other
          person or organization.
        </p>
        <p className={styles['terms-p']}>
          You agree and commit not to interfere with or disrupt, or attempt to
          interfere with or disrupt, any of our systems, services, servers,
          networks or infrastructure, or any of the Platform's systems,
          services, servers, networks or infrastructure, including without
          limitation obtaining unauthorized access to the aforementioned.
        </p>
        <p className={styles['terms-p']}>
          You agree and commit not to make any use of the Platform for the
          posting, sending or delivering of either of the following: (a)
          unsolicited email and/or advertisement or promotion of goods and
          services; (b) malicious software or code; (c) unlawful, harassing,
          privacy invading, abusive, threatening, vulgar, obscene, racist or
          potentially harmful content; (d) any content that infringes a third
          party right including intellectual property rights; (e) any content
          that may cause damage to a third party; (f) any content which may
          constitute, cause or encourage a criminal action or violate any
          applicable law.
        </p>
        <p className={styles['terms-p']}>
          You agree and commit not to violate any applicable local, state,
          national or international law, statute, ordinance, rule, regulation or
          ethical code in relation to your use of the Platform and your
          relationship with the Hosts and us.
        </p>
        <p className={styles['terms-p']}>
          If you receive any file from us or from a Host, whether through the
          Platform or not, you agree to check and scan this file for any virus
          or malicious software prior to opening or using this file.
        </p>

        <h4>Indemnification</h4>
        <p className={styles['terms-p']}>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses or fees (including reasonable attorneys’ fees) arising out of
          or relating to your violation of this Agreement or your use of the
          Platform, including, but not limited to, your User Content, any use of
          the Platform’s content or other Output, services and products other
          than as expressly authorized in this Agreement, or your use of any
          information obtained from the Platform. This clause shall survive
          expiration or termination of this Agreement.
        </p>

        <p>
          You confirm and agree to use only credit cards or other payment means
          (collectively “Payment Means”) which you are duly and fully authorized
          to use, and that all payment related information that you provided and
          will provide in the future, to or through the Platform, is accurate,
          current and correct and will continue to be accurate, current and
          correct.
        </p>
        <p>
          You agree to pay all fees and charges associated with your Account on
          a timely basis and according to the fees schedule, the terms and the
          rates as published in the Platform. By providing us with your Payment
          Means you authorize us to bill and charge you through that Payment
          Means and you agree to maintain valid Payment Means information in
          your Account information.
        </p>
        <p>
          If you have any concerns about a bill or a payment, please contact us
          immediately by sending an email to support@sharewellnow.com. We will
          evaluate your issue on a case-by-case basis and, at our discretion,
          take steps to resolve any issue, including but not limited to helping
          you find a new Host, extending your subscription at no cost to you,
          and issuing partial or full refunds when applicable.
        </p>

        <h4>Conduct Relating to your Session</h4>
        <p className={styles['terms-p']}>
          When you sign up for and attend our sessions, certain standards of
          conduct must be followed in order that all attendees get the full
          benefit of the sessions and their privacy and other rights be
          respected. Each of the Hosts have agreed, and by accessing the
          Platform, you also agree to the following rules of conduct in and
          following sessions.
        </p>
        <h4>1. Confidentiality</h4>
        <p className={styles['terms-p']}>
          Everything in the session must remain confidential. Specifically, you
          are not permitted to discuss or divulge, orally or in print, anything
          related to the identity of another attendee or anything that occurred
          in session with respect to another attendee.
        </p>

        <h4>2. Privacy (The Stop Rule)</h4>
        <p className={styles['terms-p']}>
          No attendee is required to answer any question, to participate in any
          activity, or divulge any information.
        </p>

        <h4>3. Dignity; Timeliness</h4>
        <p className={styles['terms-p']}>
          There is no tolerance for objectionable content or abusive users. No
          attendee is ever to be humiliated, hazed, or abused in any way.
          Notwithstanding the foregoing, if you are a threat to yourself or
          others (showing suicidal or homicidal intent), your Host(s) may need
          to report your statements and/or behaviors to family, your therapist,
          or other appropriate mental health or law enforcement professionals in
          order to keep you and others safe.
        </p>
        <p className={styles['terms-p']}>
          Attendees are expected to log in on time and remain for the entire
          session.
        </p>

        <h4>4. Violence or Intimidation; Alcohol and Drugs</h4>
        <p className={styles['terms-p']}>
          Violence or intimidation toward other attendees, as well as shouting
          and profanity, are never tolerated. Attendees should take turns
          speaking and not talk over one another. Attendees should respect the
          rights of others to express their opinions.
        </p>
        <p className={styles['terms-p']}>
          Attendees are prohibited from participating in sessions while under
          the influence of alcohol or drugs (excluding prescription drugs
          specifically prescribed to the attendee, and being used in strict
          accordance with the prescription).
        </p>

        <h4>6. Relationships outside Session</h4>
        <p className={styles['terms-p']}>
          ShareWell is not responsible for any relationships potentially arising
          from sessions, or conducted outside the Platform.
        </p>

        <h4>7. Lived Experience</h4>
        <p className={styles['terms-p']}>
          Attendees and Host are required to have lived experience of the
          session topic.
        </p>

        <h4>8. Peer Support</h4>
        <p className={styles['terms-p']}>
          Sessions are rooted in peer support defined as the sharing of common
          experience. Sessions are not group therapy and Hosts are not trained
          mental health clinicians. Neither Hosts nor Attendees are qualified to
          give mental health advice or diagnose conditions.
        </p>

        <h4>Communications</h4>
        <p className={styles['terms-p']}>
          When you use the Platform or contact us, you are communicating with us
          electronically. You consent to receive communications from us
          electronically. We may communicate with you by e-mail or by posting
          notices, alerts, prompts, information fields or other information
          through the Platform. We may also communicate with you through push
          notifications if you enable them on your mobile device. If you opt in
          to receive text messages, we may also communicate with you through
          text messages. You agree that (a) all agreements and consents can be
          signed electronically and (b) all notices, disclosures and other
          communications that we provide to you electronically satisfy any legal
          requirement that such notices and other communications be in writing.
        </p>

        <h4>Children</h4>
        <p className={styles['terms-p']}>
          The Platform is not intended for anyone under the age of 18. If you
          are under 18 years of age, Platform use or access is prohibited. By
          using the Platform, you affirm that you are 18 years of age or older.
        </p>

        <h4>Monitoring and Enforcement; Termination</h4>
        <p className={styles['terms-p']}>
          We have the right to take appropriate legal action, including, without
          limitation, referral to law enforcement, for any illegal or
          unauthorized use of the Platform. We may terminate or suspend your
          access to all or part of the Platform for any violation of these
          Agreement.
        </p>
        <p className={styles['terms-p']}>
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Platform. YOU WAIVE AND AGREE
          TO HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSORS AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
          THE FOREGOING PERSONS IN CONNECTION WITH INVESTIGATIONS BY EITHER THE
          COMPANY OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p className={styles['terms-p']}>
          We cannot review all User Content before it is submitted on the
          Platform, and cannot ensure prompt removal of objectionable User
          Content after it has been submitted. Accordingly, we assume no
          liability for any action or inaction regarding transmissions,
          communications, or content provided by you. We have no liability or
          responsibility to anyone for performance or nonperformance of the
          activities described in this section.
        </p>

        <h4>Changes to the Platform</h4>
        <p className={styles['terms-p']}>
          We may update the content on this Platform from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Platform may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>

        <h4>
          Information About you, your Location, and your Visits to the Platform
        </h4>
        <p className={styles['terms-p']}>
          You agree that we may collect, use and share your personal information
          in connection with providing you the Platform and related services. By
          using the Platform or any of the services, you consent to us
          collecting and using technical information about the devices and
          related software, hardware and peripherals for services that are
          internet-based or wireless to improve our products and to provide any
          services to you.
        </p>
        <p className={styles['terms-p']}>
          Certain services may make use of location data sent from your devices.
          For information about how to turn off this functionality, please
          consult the location services settings for the Platform on your
          device. If you do not turn off this functionality, you consent to our
          transmission, collection, maintenance, processing and use of your
          location data and queries to provide and improve location-based
          services.
        </p>

        <h4>Posting on Websites</h4>
        <p className={styles['terms-p']}>
          You must not cause the Platform or portions of it to be displayed on,
          or appear to be displayed by, any website, for example, framing, deep
          linking or in-line linking. You must not otherwise take any action
          with respect to the materials on the Platform that is inconsistent
          with any other provision of this Agreement.
        </p>
        <p className={styles['terms-p']}>
          You agree to cooperate with us in causing any unauthorized framing or
          linking immediately to stop. We reserve the right to withdraw linking
          permission without notice.
        </p>

        <h4>
          Modifications, Termination, Interruption and Disruptions to the
          Platform
        </h4>
        <p className={styles['terms-p']}>
          You understand, agree and acknowledge that we may modify, suspend,
          disrupt or discontinue the Platform, any part of the Platform or the
          use of the Platform, whether to all clients or to you specifically, at
          any time with or without notice to you. You agree and acknowledge that
          we will not be liable for any of the aforementioned actions or for any
          losses or damages that are caused by any of the aforementioned
          actions.
        </p>
        <p className={styles['terms-p']}>
          The Platform depends on various factors such as software, hardware and
          tools, either our own or those owned and/or operated by our
          contractors and suppliers. While we make commercially reasonable
          efforts to ensure the Platform’s reliability and accessibility, you
          understand and agree that no platform can be 100% reliable and
          accessible and so we cannot guarantee that access to the Platform will
          be uninterrupted or that it will be accessible, consistent, timely or
          error-free at all times.
        </p>
        <p className={styles['terms-p']}>
          You understand that we cannot and do not guarantee or warrant that the
          App will be free of viruses or other destructive code. You are
          responsible for implementing sufficient procedures and checkpoints to
          satisfy your particular requirements for anti-virus protection and
          accuracy of data input and output, and for maintaining a means
          external to our site for any reconstruction of any lost data.
        </p>

        <h4>Disputes</h4>
        <p className={styles['terms-p']}>
          In the interest of resolving disputes between you and the Company in
          the most expedient and cost effective manner, you and the Company
          agree that every dispute arising in connection with this Agreement
          will be resolved by binding arbitration, except that nothing in this
          Agreement will be deemed to waive, preclude or otherwise limit the
          right of either party to: (a) bring an individual action in small
          claims court; (b) pursue an enforcement action through the applicable
          federal, state or local agency if that action is available; (c) seek
          injunctive relief in a court of law in aid of arbitration; or (d) file
          suit in a court of law to address an intellectual property
          infringement claim. Arbitration is less formal than a lawsuit in
          court. Arbitration uses a neutral arbitrator instead of a judge or
          jury, may allow for more limited discovery than in court, and can be
          subject to very limited review by courts. Arbitrators can award the
          same damages and relief that a court can award. This agreement to
          arbitrate disputes includes all claims arising from or relating to any
          aspect of this Agreement, whether based in contract, tort, statute,
          fraud, misrepresentation or any other legal theory, and regardless of
          whether a claim arises during or after the termination of these
          Agreement. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE
          TERMS, YOU AND THE COMPANY IS EACH WAIVING THE RIGHT TO A TRIAL BY
          JURY OR TO PARTICIPATE IN A CLASS ACTION.
        </p>
        <p className={styles['terms-p']}>
          Any arbitration between you and the Company will be settled under the
          Federal Arbitration Act and administered by the American Arbitration
          Association (“AAA”) under its Consumer Arbitration Rules
          (collectively, “AAA Rules”) as modified by these Agreement. The AAA
          Rules and filing forms are available online at www.adr.org, by calling
          the AAA at 1-800-778-7879, or by contacting the Company. The
          arbitrator has exclusive authority to resolve any dispute relating to
          the interpretation, applicability, or enforceability of this binding
          arbitration agreement.
        </p>
        <p className={styles['terms-p']}>
          A party who intends to seek arbitration must first send a written
          notice of the dispute to the other party by certified U.S. Mail or by
          Federal Express (signature required) or, only if that other party has
          not provided a current physical address, then by electronic mail
          (“Notice of Arbitration”). The Company's address for Notice is:
          Sharewell Labs Co. 77 Van Ness Ave. Ste 101-1206 San Francisco, CA
          94102. The Notice of Arbitration must: (a) describe the nature and
          basis of the claim or dispute; and (b) set forth the specific relief
          sought (“Demand”). The parties will make good faith efforts to resolve
          the claim directly, but if the parties do not reach an agreement to do
          so within 30 days after the Notice of Arbitration is received, you or
          the Company may commence an arbitration proceeding. All arbitration
          proceedings between the parties will be confidential unless otherwise
          agreed by the parties in writing. During the arbitration, the amount
          of any settlement offer made by you or the Company must not be
          disclosed to the arbitrator until after the arbitrator makes a final
          decision and award, if any. If the arbitrator awards you an amount
          higher than the last written settlement amount offered by the Company
          in settlement of the dispute prior to the award, the Company will pay
          to you the higher of: (i) the amount awarded by the arbitrator; or
          (ii) $10,000.
        </p>
        <p className={styles['terms-p']}>
          If you commence arbitration in accordance with these Agreement, the
          Company will reimburse you for your payment of the filing fee, unless
          your claim is for more than $10,000, in which case the payment of any
          fees will be decided by the AAA Rules. Any arbitration hearing will
          take place at a location to be agreed upon in San Francisco, but if
          the claim is for $10,000 or less, you may choose whether the
          arbitration will be conducted: (a) solely on the basis of documents
          submitted to the arbitrator; (b) through a non- appearance-based
          telephone hearing; or (c) by an in-person hearing as established by
          the AAA Rules in the county (or parish) of your billing address. If
          the arbitrator finds that either the substance of your claim or the
          relief sought in the Demand is frivolous or brought for an improper
          purpose (as measured by the standards set forth in Federal Rule of
          Civil Procedure 11(b)), then the payment of all fees will be governed
          by the AAA Rules. In that case, you agree to reimburse the Company for
          all monies previously disbursed by it that are otherwise your
          obligation to pay under the AAA Rules. Regardless of the manner in
          which the arbitration is conducted, the arbitrator must issue a
          reasoned written decision sufficient to explain the essential findings
          and conclusions on which the decision and award, if any, are based.
          The arbitrator may make rulings and resolve disputes as to the payment
          and reimbursement of fees or expenses at any time during the
          proceeding and upon request from either party made within 14 days of
          the arbitrator’s ruling on the merits.
        </p>
        <p className={styles['terms-p']}>
          YOU AND THE COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
          ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
          Further, unless both you and the Company agree otherwise, the
          arbitrator may not consolidate more than one person’s claims, and may
          not otherwise preside over any form of a representative or class
          proceeding.
        </p>
        <p className={styles['terms-p']}>
          If any provision in this section (“Disputes”) is found to be valid or
          unenforceable, all other provisions in this section (“Disputes”) will
          remain valid and enforceable, except that if the preceding paragraph
          is found to be unenforceable or if the entirety of this section
          (“Disputes”) is found to be unenforceable, then the entirety of this
          section (“Disputes”) will be null and void and, in that case, the
          parties agree that the jurisdiction and venue described in the
          preceding section (“Governing Law and Jurisdiction”) will govern any
          action arising out of or related to this Agreement.
        </p>
        <p className={styles['terms-p']}>
          If the Company makes any future change to the arbitration provisions
          in this section, other than a change to the Company’s address for
          Notice of Arbitration, you may reject the change by sending us written
          notice within 30 days of the change to the Company’s address for
          Notice of Arbitration, in which case your account with the Company
          will be immediately terminated and this arbitration provision, as in
          effect immediately prior to the changes you rejected will survive.
        </p>

        <h4>Limitation on Time to File Claims</h4>
        <p className={styles['terms-p']}>
          TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW, ANY CAUSE OF
          ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS
          OF USE OR THE APP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
          CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED.
        </p>

        <h4>Notices</h4>
        <p className={styles['terms-p']}>
          We may provide notices or other communications to you regarding this
          agreement or any aspect of the Platform, by email to the email address
          that we have on record, by regular mail or by posting it online. The
          date of receipt shall be deemed the date on which such notice is
          given. Notices sent to us must be delivered by email to
          support@sharewellnow.com.
        </p>

        <h4>Important notes about our Agreement</h4>
        <p className={styles['terms-p']}>
          This Agreement and our relationship with you shall both be interpreted
          solely in accordance with the laws of the State of Delaware excluding
          any rules governing choice of laws.
        </p>
        <p className={styles['terms-p']}>
          You irrevocably agree that the exclusive venue for any action or
          proceeding arising out of relating to this Agreement or our
          relationship with you, regardless of theory, shall be the U.S.
          District Court for the Northern District of California, or the state
          courts located in San Francisco County in California. You irrevocably
          consent to the personal jurisdiction of the aforementioned courts and
          hereby waive any objection to the exercise of jurisdiction by the
          aforementioned courts.
        </p>
        <p className={styles['terms-p']}>
          THIS AGREEMENT CONSTITUTES THE ENTIRE AGREEMENT BETWEEN YOU AND US.
          YOU CONFIRM THAT YOU HAVE NOT RELIED UPON ANY PROMISES OR
          REPRESENTATIONS BY US EXCEPT AS SET FORTH IN THIS AGREEMENT.
        </p>
        <p className={styles['terms-p']}>
          We may change this Agreement by posting modifications on the Platform.
          Unless otherwise specified by us, all modifications shall be effective
          upon posting. Therefore, you are encouraged to check the terms of this
          Agreement frequently. The last update date of this Agreement is posted
          at the bottom of the Agreement. By using the Platform after the
          changes become effective, you agree to be bound by such changes to the
          Agreement. If you do not agree to the changes, you must terminate
          access to the Platform and participation in its services.
        </p>
        <p className={styles['terms-p']}>
          We may freely transfer or assign this Agreement or any of its
          obligations hereunder.
        </p>
        <p className={styles['terms-p']}>
          The paragraph headings in this Agreement are solely for the sake of
          convenience and will not be applied in the interpretation of this
          Agreement.
        </p>
        <p className={styles['terms-p']}>
          If any provision of this Agreement is held by a court of competent
          jurisdiction to be illegal, invalid, unenforceable, or otherwise
          contrary to law, the remaining provisions of this Agreement will
          remain in full force and effect.
        </p>
        <p className={styles['terms-p']}>
          To clear any doubt, all clauses regarding limitations of liabilities
          and indemnification shall survive the termination or expiration of
          this Agreement.
        </p>

        <p className={styles['terms-p']}>
          Last Updated: <b>June 3, 2021</b>
        </p>
      </div>
    </div>
  );
}
