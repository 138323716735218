import { useState, useEffect, useCallback } from 'react';
import { groupForumActions } from '_actions';
import { useSelector, useDispatch } from 'react-redux';
import { AnalyticsSendEvent } from '_helpers';

export const useLikeItem = (activity, setOpenEmojis, reloadActivity) => {
  const dispatch = useDispatch();

  const { userStore } = useSelector((state) => state);
  const { userData } = userStore;

  const [likedItems, setLikeItems] = useState(false);
  const [totalLikes, setTotalLikes] = useState(null);

  useEffect(() => {
    setLikeItems(activity.like_user_emojis[userData.uuid]);

    let count = 0;

    Object.keys(activity.like_user_emojis).forEach((key, index) => {
      count += activity.like_user_emojis[key].length;
    });

    setTotalLikes(count);
  }, [activity, activity.like_users, userData.uuid]);

  const sendEmoji = useCallback(
    async (emoji) => {
      await dispatch(groupForumActions.postLike(activity.uuid, emoji, 'true'));
      reloadActivity();
      setOpenEmojis(false);
      AnalyticsSendEvent('group_forum_activity_like', {
        user: userData.uuid,
        group: activity.group_uuid,
        activity: activity.uuid,
      });
    },
    [
      activity.group_uuid,
      activity.uuid,
      dispatch,
      reloadActivity,
      setOpenEmojis,
      userData.uuid,
    ]
  );

  const unsendEmoji = useCallback(
    async (emoji) => {
      await dispatch(groupForumActions.postLike(activity.uuid, emoji, 'false'));
      reloadActivity();
      setOpenEmojis(false);
      AnalyticsSendEvent('group_forum_activity_unlike', {
        user: userData.uuid,
        group: activity.group_uuid,
        activity: activity.uuid,
      });
    },
    [
      activity.group_uuid,
      activity.uuid,
      dispatch,
      reloadActivity,
      setOpenEmojis,
      userData.uuid,
    ]
  );

  return {
    sendEmoji, //send emoji
    unsendEmoji, //send emoji
    likedItems, // the items the user has liked
    totalLikes, //total number of likes
  };
};
