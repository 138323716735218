import { createContext, ReactNode, useCallback } from 'react';
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';
import { ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
import { BackgroundSettings } from './useBackgroundSettings/useBackgroundSettings';
import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom/useRoom';
import useScreenShareToggle from './useScreenShareToggle/useScreenShareToggle';
import useHandleRoomChange from './useHandleRoomChange';
import useActiveSinkId from './useActiveSinkId';
import { roomConstants } from '_constants';
import { useWellDispatch } from '_hooks';

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  isBackgroundSelectionOpen: boolean;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
  backgroundSettings: BackgroundSettings;
  setBackgroundSettings: (settings: BackgroundSettings) => void;
}

export const VideoContext = createContext<IVideoContext | any>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

export function VideoProvider({
  options,
  children,
  onError = () => {},
}: VideoProviderProps) {
  const dispatch = useWellDispatch();

  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      dispatch({
        type: roomConstants.SET_LEAVE_STATE,
        redirectLocation: '/',
      });
      onError(error);
    },
    [dispatch, onError]
  );

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect, dataTrack } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );
  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);
  useHandleRoomChange(room);

  const [activeSinkId, setActiveSinkId] = useActiveSinkId();

  // const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
  // const videoTrack = localTracks.find(track => !track.name.includes('screen') && track.kind === 'video') as
  //   | LocalVideoTrack
  //   | undefined;
  // const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, room);

  const sendDataTrackMessage = useCallback(
    (message: any, data: any) => {
      if (!dataTrack) return false;
      const sendObj = JSON.stringify({ message: message, data: data });
      dataTrack.send(sendObj);
    },
    [dataTrack]
  );

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
        // isBackgroundSelectionOpen,
        // setIsBackgroundSelectionOpen,
        // backgroundSettings,
        // setBackgroundSettings,

        //new
        activeSinkId,
        setActiveSinkId,

        //data track
        dataTrack,
        sendDataTrackMessage,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
