import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './WellModal.module.css';
import {
  WellLoader,
  WellButton,
  WellCountdown,
  SocialShareSession,
  SocialShareGroup,
} from '_components';
import { componentActions } from '_actions';
import { FormControl } from 'react-bootstrap';

import { useClickOutside, useGetImgixVersion } from '_hooks';

// DO NOT ADD TO THIS FILE
// ONLY DELETE WHEN ALL MODALS HAVE BEEN MOVED
export const WellModal = (props) => {
  const { modalData } = useSelector((state) => state.componentsStore);
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAfterText, setShowAfterText] = useState(false);

  useClickOutside(modalRef, closeModal);

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('keydown', keyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (!modalData.autoCloseFun) return false;

    let timer = setInterval(modalData.autoCloseFun, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [modalData.autoCloseFun]);

  function closeModal() {
    if (isLoading || modalData.preventLeave) return false;

    setInput('');
    setShowAfterText(false);
    setIsLoading(false);
    dispatch(componentActions.closeModal());
  }

  const updateInput = (e) => {
    const newValue = e.target.value;
    setInput(newValue);
  };

  const keyDown = (e) => {
    if (e.key === 'Escape') closeModal();
  };

  const btnYes = async () => {
    if (modalData.isInput && !input) return;

    //if after text we have to wait for the reply
    if (modalData.afterText) {
      setIsLoading(true); //set loading
      const response = await modalData.yesClick(input); //do click function wait till response
      if (response) {
        setIsLoading(false); //remove loader
        setShowAfterText(true); //show after text
        setTimeout(() => {
          closeModal();
        }, 2000);
      } else closeModal();
    } else {
      modalData.yesClick(input);
      closeModal();
    }
  };

  const btnNo = () => {
    modalData.noClick();
    closeModal();
  };

  if (modalData.isSocialShareSession)
    return <SocialShareSession closeModal={closeModal} />;
  else if (modalData.isSocialShareGroup)
    return <SocialShareGroup closeModal={closeModal} group={modalData.group} />;
  else if (modalData.subscribeModal)
    return (
      <SubscribeModal
        modalData={modalData}
        closeModal={closeModal}
        ref={modalRef}
      />
    );
  else if (modalData.sessionCreation)
    return (
      <SessionCreation
        modalData={modalData}
        closeModal={closeModal}
        ref={modalRef}
      />
    );
  else if (modalData.createSessionCtaModal)
    return (
      <CreateSessionCtaModal
        modalData={modalData}
        closeModal={closeModal}
        ref={modalRef}
      />
    );

  return (
    <div
      className={styles['well-modal']}
      style={modalData.modalStyle}
      ref={modalRef}
    >
      <div className={styles['well-modal-container'] + ' container'}>
        {!modalData.preventLeave && (
          <div className={styles['modal-close-btn']} onClick={closeModal}>
            &#10006;
          </div>
        )}
        <div className={'row'}>
          <h4 className={styles['modal-title']}>{modalData.title}</h4>

          <div
            className={styles['modal-content']}
            style={modalData.modalBodyStyle}
          >
            <div
              className={
                isLoading || showAfterText ? styles['no-visibility'] : ''
              }
            >
              {modalData.body ? (
                <div className={styles['modal-body-container']}>
                  <p>{modalData.body}</p>
                </div>
              ) : null}

              {modalData.isInput ? (
                <div className={styles['modal-input']}>
                  <FormControl
                    as={'textarea'}
                    name={'modal-input'}
                    className={styles['modal-input']}
                    onChange={updateInput}
                    placeholder={modalData.placeholder}
                    autoFocus={true}
                    value={input}
                    maxLength={modalData.maxLength}
                  />
                </div>
              ) : null}

              {modalData.centerBtnText && modalData.centerBtnClick && (
                <div className={styles['modal-btns-container']}>
                  <WellButton
                    style={modalData.noButtonStyle}
                    // className={styles['go-to-sessions']}
                    btnOnClick={modalData.centerBtnClick}
                    btnText={modalData.centerBtnText}
                    type={'well-btn-primary'}
                  />
                </div>
              )}

              {modalData.yesClick || modalData.noClick ? (
                <div className={styles['modal-btns-container']}>
                  <WellButton
                    className={styles['no-btn']}
                    style={modalData.noButtonStyle}
                    btnOnClick={btnNo}
                    btnText={modalData.noText}
                    type={'well-btn-primary'}
                  />
                  <WellButton
                    className={styles['yes-btn']}
                    style={modalData.yesButtonStyle}
                    btnOnClick={btnYes}
                    btnText={modalData.yesText}
                    type={'well-btn-primary'}
                  />
                </div>
              ) : null}
            </div>

            {isLoading ? <WellLoader heightWidth={150} /> : null}
            {showAfterText ? (
              <div className={styles['after-text']}>{modalData.afterText}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const SessionCreation = React.forwardRef(({ modalData, closeModal }, ref) => {
  const dispatch = useDispatch();

  let image = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/modal/session_join.svg',
    {
      w: 225,
    }
  );

  const openSocialShare = () => {
    dispatch(
      componentActions.openModal({
        isSocialShareSession: true,
      })
    );
  };

  return (
    <div
      className={styles['well-modal'] + ' ' + styles['confetti-modal']}
      style={modalData.modalStyle}
      ref={ref}
    >
      <div className={styles['well-modal-container'] + ' ' + 'container'}>
        <div className={styles['modal-close-btn']} onClick={closeModal}>
          &#10006;
        </div>
        <div className={'row'}>
          <img src={image} className={styles['register-image']} alt={''} />

          <h4 className={styles['modal-title'] + ' ' + styles['congrats']}>
            CONGRATULATIONS
          </h4>
          <div className={styles['modal-content']}>
            <div className={styles['modal-body-container']}>
              {modalData.numSessionsHosted >= 4 ? (
                <>
                  <p>
                    You've created a peer support session! Want to invite people
                    to join your session? Send&nbsp;them an invitation!
                  </p>
                  <WellButton
                    className={styles['group-join-btn']}
                    btnOnClick={openSocialShare}
                    btnText={'SHARE'}
                    isLoading={false}
                    type={'well-btn-orange'}
                  />
                </>
              ) : (
                <>
                  <p>
                    {' '}
                    You've created a peer support session! Please watch this
                    in-session experience video to familiarize yourself with our
                    hosting features.{' '}
                  </p>
                  <iframe
                    className={styles['iframe-session-congrats']}
                    title={'ShareWell Video'}
                    src={'https://www.youtube.com/embed/p_QrGwQm6lw'}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <p className={styles['download-link']}>
                    Download our{' '}
                    <a
                      target={'_blank'}
                      rel="noopener noreferrer"
                      href={
                        'https://sharewell-space-live.sfo3.digitaloceanspaces.com/marketing-material%2FShareWell-Host-Handbook.pdf'
                      }
                    >
                      sample session agenda.
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const SubscribeModal = React.forwardRef(({ modalData, closeModal }, ref) => {
  let image = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/modal/welcome-1.svg',
    {
      w: 225,
    }
  );

  return (
    <div className={styles['well-modal']} ref={ref}>
      <div className={styles['well-modal-container']}>
        <div className={styles['modal-close-btn']} onClick={closeModal}>
          &#10006;
        </div>
        <div className={'row'}>
          <div className={styles['welcome-image-container']}>
            <img src={image} className={styles['welcome-image']} alt={''} />
          </div>

          <div className={styles['modal-content']}>
            <div
              className={styles['modal-body-container']}
              style={{ textAlign: 'center' }}
            >
              <p className={styles['welcome-text']}>
                Welcome to your ShareWell Unlimited membership! You now have
                access to unlimited sessions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const CreateSessionCtaModal = React.forwardRef(
  ({ modalData, closeModal }, ref) => {
    let image = useGetImgixVersion(
      process.env.REACT_APP_ASSETS_URL + '/site/modal/host-cta.svg',
      {
        w: 225,
      }
    );

    return (
      <div className={styles['well-modal']} ref={ref}>
        <div className={styles['well-modal-container']}>
          <div className={styles['modal-close-btn']} onClick={closeModal}>
            &#10006;
          </div>
          <div className={'row'}>
            <div className={styles['host-cta-image-container']}>
              <img src={image} className={styles['host-cta-image']} alt={''} />
            </div>

            <div className={styles['modal-content']}>
              <div
                className={styles['modal-body-container']}
                style={{ textAlign: 'center' }}
              >
                <p className={styles['welcome-text']}>
                  Ready to take the next step? Host a peer support session to
                  further your healing journey by helping others. Your life
                  experience is valuable and we've made it easy for you create a
                  session today. Get started{' '}
                  <a href={'/host'} target={'_blank'} rel="noreferrer">
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
