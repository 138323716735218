import { handleFetchReturn, failedToFetch } from '.';

export const generalService = {
  setServerConfig,
  setOrAppendMarketingData,
  loadMarketingData,
};

function setServerConfig() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(process.env.REACT_APP_API_URL + '/config', requestOptions)
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// sets or appends the marketing data
// (set to a single value). "val" field must be valid json, max size 1024. "key" field can be any string, max size 256)
function setOrAppendMarketingData(
  type: 'set' | 'append',
  key: string,
  value: string
) {
  return fetch(process.env.REACT_APP_API_URL + '/dump/' + type, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      key: key,
      val: value,
    }),
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

// gets the marketing data
function loadMarketingData(key: string) {
  return fetch(process.env.REACT_APP_API_URL + '/dump/load/' + key, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
