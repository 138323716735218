import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './LikeItem.module.css';

export const LikeList = ({ activity, isReaction }) => {
  const { userStore } = useSelector((state) => state);

  //state
  const [totalLikes, setTotalLikes] = useState(0); //number of total likes
  // const [hasLiked, setHasLiked] = useState(false); //if the user has likedA

  useEffect(() => {
    let likeCount = 0;

    Object.keys(activity.like_user_emojis).forEach((key, index) => {
      // if (key === userStore.userData.uuid) setHasLiked(true);
      likeCount += activity.like_user_emojis[key].length;
    });

    setTotalLikes(likeCount);
  }, [activity, activity.like_users, userStore.userData.uuid]);

  let propertyValues = [];

  Object.values(activity.like_user_emojis).map((key) => {
    propertyValues = [...new Set(propertyValues.concat(key))].sort();
  });

  if (propertyValues.length === 3) {
    [propertyValues[0], propertyValues[1]] = [
      propertyValues[1],
      propertyValues[0],
    ]; //dont like this
  }

  if (totalLikes === 0) return <div></div>;

  return (
    <div
      className={
        styles['like-list-container'] +
        ' ' +
        (isReaction ? styles['is-reaction'] : styles['not-reaction'])
      }
    >
      <div className={styles['emoji-container']}>
        <div className={styles['emoji-container-inner']}>
          {propertyValues &&
            propertyValues.reverse().map((emoji) => (
              <span
                className={styles['emoji']}
                key={activity.uuid + '-reaction-' + emoji}
              >
                {emoji}
              </span>
            ))}
        </div>
        <span className={styles['total-likes']}>{totalLikes}</span>
        {/* <span className={styles['people-liked']}>
          You, and{' '}
          {hasLiked
            ? Object.keys(activity.like_user_emojis).length - 1
            : Object.keys(activity.like_user_emojis).length}{' '}
          others
        </span> */}
      </div>

      <div className={styles['people-list']}></div>
    </div>
  );
};
