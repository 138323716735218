import styles from './Recommendations.module.css';

import { useGetImgixVersion } from '_hooks';

export const Recommendations = () => {
  return (
    <section className={styles['recommendations-section']}>
      <h3 className={styles['recommendations-section-title']}>
        {' '}
        What our members are saying about&nbsp;us!
      </h3>
      <p className={styles['recommendations-section-p']}>
        Hear what some of our community members are saying about peer support
        and how it's changing their&nbsp;lives.
      </p>

      <div className={styles['recommendations-row']}>
        <RecommendationItem
          name={'Stella'}
          text={
            'Amazing! I was so anxious before the sessions, but the host made me feel comfortable, and everyone was so welcoming. I saw my anxiety decrease from a 10 to a 3 after the session.'
          }
          image={'stella.svg'}
          quoteImage={'quote-green.svg'}
          signature={'stella-signature.svg'}
        />

        <RecommendationItem
          name={'Alex'}
          text={
            'I’ve never felt my friends got what I’m going through, so I feel embarrassed venting to them. This session was so great. I finally got to say what’s on my mind!'
          }
          image={'alex.svg'}
          quoteImage={'quote-blue.svg'}
          signature={'alex-signature.svg'}
        />

        <RecommendationItem
          name={'Lena'}
          text={
            'Thank you ShareWell, for this safe space. I learned so much listening to everyone’s stories, and it felt nice to be heard and supported. I feel so much lighter and happier!'
          }
          image={'lena.svg'}
          quoteImage={'quote-yellow.svg'}
          signature={'lena-signature.svg'}
        />
      </div>
    </section>
  );
};

export const RecommendationsPartners = () => {
  return (
    <section className={styles['recommendations-section']}>
      <h3 className={styles['recommendations-section-title']}>
        {' '}
        Hear it from our members: peer support works!
      </h3>

      <div className={styles['recommendations-row']}>
        <RecommendationItem
          name={'LadyFatima'}
          text={
            'Peer support is really great and healing. I could have never imagined how much I needed to have this experience before having it. Thank you.'
          }
          image={'lady-fatima.jpeg'}
          quoteImage={'quote-green.svg'}
        />

        <RecommendationItem
          name={'lghi'}
          text={
            "Good group, good chat, good ending exercise. I sensed that everybody present got something intangible that they couldn't have gotten outside of peer support."
          }
          image={'ighi.jpeg'}
          quoteImage={'quote-blue.svg'}
        />

        <RecommendationItem
          name={'Mlhawkins'}
          text={
            'I went to many therapists and this peer support healed me in no way therapy was able too. I felt heard and not alone. There was never judgment from my peers, only true support.'
          }
          image={'mlhawkins.jpg'}
          quoteImage={'quote-yellow.svg'}
        />
      </div>
    </section>
  );
};

export const RecommendationsGift = () => {
  return (
    <section className={styles['recommendations-section']}>
      <h3 className={styles['recommendations-section-title']}>
        {' '}
        What Members are saying about&nbsp;us
      </h3>
      <p className={styles['recommendations-section-p']}>
        Hear what some of our community members are saying about peer support
        and how it's changing their&nbsp;lives.
      </p>

      <div className={styles['recommendations-row']}>
        <RecommendationItem
          name={'Monica'}
          text={
            'ShareWell is a place I found to be a healing space. I made friends I know truly understand me. I went to many therapists, and this peer support healed me in no way that therapy was able to. I felt heard and not alone."'
          }
          image={'stella.svg'}
          quoteImage={'quote-green.svg'}
          signature={'stella-signature.svg'}
        />

        <RecommendationItem
          name={'Jack'}
          text={
            'I\'ve never felt my friends got what I\'m going through, so I feel embarrassed venting to them. This session was so great. I finally got to say what\'s on my mind!'
            // 'When I entered treatment for depression, I would reach out to close friends to talk and what they had to say was never very helpful, even though they cared about me. I quickly learned that strangers who shared the same life experience were able to get what I was going through - provide guidance and emotional support that really helped me.'
          }
          image={'jack-fake.svg'}
          quoteImage={'quote-blue.svg'}
          signature={'alex-signature.svg'}
        />

        <RecommendationItem
          name={'Monica'}
          text={
            'Thank you ShareWell, for this safe space. I learned so much listening to everyone’s stories, and it felt nice to be heard and supported. I feel so much lighter and happier!'
          }
          image={'lena.svg'}
          quoteImage={'quote-yellow.svg'}
          signature={'lena-signature.svg'}
        />
      </div>
    </section>
  );
};

interface IRecommendationItem {
  name: string;
  text: string;
  image: string;
  quoteImage: string;
  signature?: string;
}

const RecommendationItem = ({
  name,
  text,
  image,
  quoteImage,
  signature,
}: IRecommendationItem) => {
  const quotes = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/' + quoteImage,
    {
      h: 100,
      w: 100,
    }
  );

  const userImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/user-images/' + image,
    {
      h: 500,
      w: 500,
    }
  );

  const signatureImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/' + signature,
    {
      h: 100,
      w: 100,
    }
  );

  return (
    <div className={styles['item']}>
      <h3 className={styles['recommendation-text']}>{text}</h3>
      <div className={styles['recommendation-author-information']}>
        <img src={quotes} className={styles['quotes-img']} alt={'Quotes'} />
        <img
          src={userImage}
          className={styles['author-image']}
          alt={'ShareWell User ' + { name }}
        />
      </div>
      {signature ? (
        <img
          src={signatureImage}
          className={styles['recommendation-author-name']}
          alt={'ShareWell User ' + { name }}
        />
      ) : (
        <h4 className={styles['name']}>{name}</h4>
      )}
    </div>
  );
};

export const LongRecommendationItem = ({
  name,
  text,
  image,
  quoteImage,
  signature,
}: IRecommendationItem) => {
  const quotes = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/' + quoteImage,
    {
      h: 100,
      w: 100,
    }
  );

  const userImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/user-images/' + image,
    {
      h: 500,
      w: 500,
    }
  );

  const signatureImage = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/' + signature,
    {
      h: 100,
      w: 100,
    }
  );

  return (
    <div className={styles['item-long']}>
      <div className={styles['text-container']}>
        <h3 className={styles['recommendation-text']}>{text}</h3>
        <img
          src={signatureImage}
          className={styles['recommendation-author-name-long']}
          alt={'ShareWell User ' + { name }}
        />
      </div>

      <div className={styles['recommendation-author-information-long']}>
        <img
          src={quotes}
          className={styles['quotes-img-long']}
          alt={'Quotes'}
        />
        <img
          src={userImage}
          className={styles['author-image-long']}
          alt={'ShareWell User ' + { name }}
        />
      </div>
    </div>
  );
};
