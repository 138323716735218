import { useContext } from 'react';
import { WellChatContext } from 'WellChatProvider';

// CONTEXT
export const useWellChat = () => {
  const context = useContext(WellChatContext);
  if (context === undefined) {
    throw new Error('useWellChat must be used within a WellChatProvider');
  }
  return context;
};
