import { useRef } from 'react';
import styles from './GroupInfoSessions.module.css';
import { useGroupInfo, useSessionsPagination } from '_hooks';
import {
  WellButton,
  SessionsDates,
  WellLoader,
  WellSearchInput,
} from '_components';
import { getNowStringFormat, getFutureStringFormat } from '_helpers';
import { useWellSelector, useListAtBottomWithRef } from '_hooks';
import { ESortType } from '_types';
import { sessionsConstants } from '_constants';

export const GroupInfoSessions = () => {
  const { userStore } = useWellSelector((state) => state);
  const { userData } = userStore;
  const { group } = useGroupInfo();

  const {
    //state
    allSessions,
    sessionsPagination,
    isLoadingSessions,
    searchPhrase,

    //functions
    scrollSearchFunction,
    createSession,
    handleSearchChange,
  } = useSessionsPagination(
    group.uuid,
    getNowStringFormat(),
    getFutureStringFormat(),
    ESortType.SORT_TYPE_SOON,
    false,
    sessionsConstants.LIST_PAGINATION_LIMIT,
    false
  );

  const sessionsSection = useRef();

  useListAtBottomWithRef(
    sessionsSection,
    scrollSearchFunction,
    1000,
    sessionsPagination.isDone
  );

  return (
    <div className={styles['sessions']}>
      <div className={styles['sessions-container'] + ' container'}>
        <div className={styles['top-items']}>
          {group.uuid === process.env.REACT_APP_WELCOME_GROUP_UUID &&
          userStore.userData.host_level !== 2 ? (
            <></>
          ) : (
            group.am_i_joined &&
            userData.email && (
              <div className={styles['create-session-btn-container']}>
                <WellButton
                  className={styles['create-session-btn']}
                  btnOnClick={createSession}
                  type={'well-btn-orange'}
                  btnText={'CREATE SESSION'}
                  isLoading={false}
                />
              </div>
            )
          )}
          <WellSearchInput
            autoFocus={false}
            value={searchPhrase ? searchPhrase : ''}
            readOnly={false}
            onChange={handleSearchChange}
            onBlur={() => {}}
            name={'search-bar'}
            placeHolder={'Search'}
          />
        </div>

        <div className={styles['list-container']} ref={sessionsSection}>
          {allSessions && allSessions.length > 0 ? (
            <SessionsDates
              sessionsToShow={allSessions}
              showDates={true}
              searchPhrase={searchPhrase}
              showEmpty={true}
              isGroupPage={true}
            />
          ) : isLoadingSessions ? (
            <WellLoader heightWidth={150} />
          ) : (
            <div className={styles['no-sessions-notice']}>
              This Well currently has no sessions scheduled.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
