import { useRef, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useClickOutside } from '_hooks';
import styles from './WellSlideOut.module.css';
import { XIcon } from '_components';

interface IWellSlideOut {
  children: JSX.Element;
  closeSlide: () => void;
  disableOverlay: boolean;
  isLoading: boolean;
  disallowClose: boolean;
}

export const WellSlideOut = ({
  children,
  closeSlide,
  disableOverlay,
  isLoading,
  disallowClose,
}: IWellSlideOut) => {
  const slideOutRef = useRef(null); // slideOutRef's ref
  const [slideOut, setSlideOut] = useState('');

  // open the slide
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      setSlideOut(styles['slide-out']);
    }, 200);
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  // can the modal be closed?
  const checkIfShouldClose = useCallback(() => {
    if (isLoading || disallowClose) return false;
    closeSlide();
  }, [closeSlide, disallowClose, isLoading]);

  // check for click outside
  useClickOutside(slideOutRef, checkIfShouldClose);

  return createPortal(
    <>
      {!disableOverlay && <div className={styles['modal-overlay']} />}
      <div
        className={styles['slide-container'] + ' ' + slideOut}
        ref={slideOutRef}
      >
        <div className={styles['close-slide']}>
          <XIcon onClick={checkIfShouldClose} />
        </div>

        {children}
      </div>
    </>,
    document.getElementById('portal')!
  );
};
