import { createContext } from 'react';
import { ISession, ESortType } from '_types';
import { useSessionsPagination } from '_hooks';
import { getNowStringFormat, getFutureStringFormat } from '_helpers';
import { sessionsConstants } from '_constants';

interface ISessionsListContext {
  //session
  allSessions: ISession[];
  sessionsPagination: {
    curOffset: number;
    isDone: boolean;
  };

  //loading
  isLoadingInitial: boolean;
  isLoadingSessions: boolean;
  isLoadingMoreSessions: boolean;

  //pagination
  sortType: ESortType;
  searchPhrase: string;

  //functions
  handleSearchChange: (e: any) => void;
  handleSortTypeChange: (newSortType: ESortType) => void;
  scrollSearchFunction: () => void;
}

export const SessionsListContext = createContext({} as ISessionsListContext);
export const SessionsListProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const {
    //state
    allSessions,
    sessionsPagination,
    searchPhrase,
    sortType,

    isLoadingInitial,
    isLoadingSessions,
    isLoadingMoreSessions,

    //functions
    scrollSearchFunction,
    handleSearchChange,
    handleSortTypeChange,
  } = useSessionsPagination(
    '',
    getNowStringFormat(),
    getFutureStringFormat(),
    ESortType.SORT_TYPE_SOON,
    false,
    sessionsConstants.LIST_PAGINATION_LIMIT,
    false
  );

  return (
    <SessionsListContext.Provider
      value={{
        //session
        allSessions,
        sessionsPagination,

        //loading
        isLoadingInitial,
        isLoadingSessions,
        isLoadingMoreSessions,

        //pagination
        sortType,
        searchPhrase,

        //functions
        handleSearchChange,
        handleSortTypeChange,
        scrollSearchFunction,
      }}
    >
      {children}
    </SessionsListContext.Provider>
  );
};
