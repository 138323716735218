export const notificationConstants = {
  //Errors
  SERVER_ERROR: 'An error has occurred. Please try again.',
  CONNECTION_ERROR: 'Internet connection unstable',

  //SUCCESS
  SUCCESS_DEFAULT: 'Success',

  /////////////////////////////////////////

  //Session room
  BREAKOUT_ROOM_INVALID: 'Rooms must have at least 3 people.',

  //BLOCK
  USER_BLOCK_NOTICE:
    "Are you sure you want to block? Blocking means neither of you will be able to join a session with each other or see the other's profile. We will not notify this person that they were blocked and you can unblock anytime.",
  USER_UNBLOCK_NOTICE:
    'Are you sure you want to unrestrict this user? They will be able to register for sessions that you have registered for and vice versa. You can rerestrict this user at anytime.',

  //REPORTING
  USER_REPORT_NOTICE: 'Please enter the reason for reporting this user.',
  USER_REPORT_NOTICE_AFTER: 'User has been reported',
  SESSION_REPORT_NOTICE: 'Please enter the reason for reporting this session.',
  SESSION_REPORT_NOTICE_AFTER: 'Session has been reported.',

  //KICKING
  USER_KICK_NOTICE:
    'Are you sure you want to remove this user? They will not be able to return to this session afterwards.',

  //LEAVING
  HOST_LEAVING:
    'Are you sure you want to leave the session? This will end the session for all other users.',
  USER_LEAVING: 'Are you sure you want to leave the session?',

  //SESSION INFO
  YOUR_HOSTING:
    'You are hosting this session! You will be able to enter the session from this page 5min before it starts.',
  YOUR_GOING:
    'You are going! You will be able to enter the session from this page 5min before it starts and up to 5min past start time.',
  SESSION_IS_OVER:
    'This session has already concluded. Find a related session to join below, or return to the session_list.',
  SESSION_LIVE: 'Session Live',
  SESSION_UNREGISTER_NOTICE:
    'Are you sure you want to unregister from this session? You can register again for this session at a later time.',
  SESSION_REGISTER_NOTICE:
    'You are successfully registered! Know someone else who would benefit from joining this session? Invite them to join you or share on social media.',

  SESSION_WHEN_CAN_ENTER_NOTICE:
    'You will be able to enter the waiting room 5 minutes before the session&nbsp;start&nbsp;time.',
  SESSION_WHEN_CAN_ENTER_NOTICE_EXTERNAL:
    'You will be able to enter this session 5 minutes before the start time.',

  SESSION_DELETE_NOTICE:
    'Are you sure you want to delete this session? This action can not be undone.',
  SESSION_DELETE_NOTICE_AFTER: 'This session has been deleted.',

  //SERIES
  SERIES_DELETE_NOTICE:
    'Are you sure you want to end this series? This will stop future sessions from being created',
  SERIES_DELETE_NOTICE_AFTER: 'This series has been ended.',

  BROWSER_UNSUPPORTED_NOTICE:
    'We noticed you are using an in-app browser. Please switch to a  standard internet browser to use this ShareWell feature.',
  CURATED_UNSUPPORTED_NOTICE:
    'At this time, outside sessions are not supported on mobile browsers. Please try entering from a desktop to join this curated session.',
};
