// we only care about the number on this side, the msg should come from the server
export class ReturnNotice {
  static readonly SITE_IS_DOWN_FOR_MAINTENANCE = new ReturnNotice(
    453817827,
    'SITE_IS_DOWN_FOR_MAINTENANCE'
  );
  static readonly USER_DOES_NOT_EXIST = new ReturnNotice(
    345251441,
    'USER_DOES_NOT_EXIST'
  );
  static readonly USER_EMAIL_ADDRESS_IS_TAKEN = new ReturnNotice(
    241138485,
    'USER_EMAIL_ADDRESS_IS_TAKEN'
  );
  static readonly USER_EMAIL_ADDRESS_CONFIRMATION_WRONG = new ReturnNotice(
    221785574,
    'USER_EMAIL_ADDRESS_CONFIRMATION_WRONG'
  );
  static readonly USER_USERNAME_IS_TAKEN = new ReturnNotice(
    881332461,
    'USER_USERNAME_IS_TAKEN'
  );
  static readonly USER_CREDENTIALS_INCORRECT = new ReturnNotice(
    146765675,
    'USER_CREDENTIALS_INCORRECT'
  );
  static readonly SIGNUP_PHONE_NUMBER_INVALID = new ReturnNotice(
    543987555,
    'SIGNUP_PHONE_NUMBER_INVALID'
  );
  static readonly SESSION_DELETED = new ReturnNotice(
    276319502,
    'SESSION_DELETED'
  );
  static readonly SESSION_DOES_NOT_EXIST = new ReturnNotice(
    664838702,
    'SESSION_DOES_NOT_EXIST'
  );
  static readonly SERIES_DOES_NOT_EXIST = new ReturnNotice(
    232071109,
    'SERIES_DOES_NOT_EXIST'
  );
  static readonly SESSION_NOT_EMPTY = new ReturnNotice(
    513563462,
    'SESSION_NOT_EMPTY'
  );
  static readonly SESSION_FULL = new ReturnNotice(463186321, 'SESSION_FULL');
  static readonly SESSION_MAX_ATTENDEES_TOO_FEW = new ReturnNotice(
    722607164,
    'SESSION_MAX_ATTENDEES_TOO_FEW'
  );
  static readonly URL_PROVIDED_INVALID = new ReturnNotice(
    321319549,
    'URL_PROVIDED_INVALID'
  );
  static readonly SESSION_OVERLAP = new ReturnNotice(
    853635194,
    'SESSION_OVERLAP'
  );
  static readonly SESSION_JOINED_TOO_MANY = new ReturnNotice(
    365867022,
    'SESSION_JOINED_TOO_MANY'
  );
  static readonly SESSION_JOINED_TOO_MANY_BASIC = new ReturnNotice(
    423402125,
    'SESSION_JOINED_TOO_MANY_BASIC'
  );
  static readonly SESSION_BASIC_PLAN_MAX_REACHED = new ReturnNotice(
    402847776,
    'SESSION_BASIC_PLAN_MAX_REACHED'
  );
  static readonly SESSION_JOINED_NOT_PART_OF_GROUP = new ReturnNotice(
    983789597,
    'SESSION_JOINED_NOT_PART_OF_GROUP'
  );
  static readonly SESSION_NOSHOW_TOO_MANY = new ReturnNotice(
    191035447,
    'SESSION_NOSHOW_TOO_MANY'
  );
  static readonly SESSION_BLOCKED_USER = new ReturnNotice(
    976494429,
    'SESSION_BLOCKED_USER'
  );
  static readonly SESSION_TIME_ENTERING_TOO_LATE = new ReturnNotice(
    868156430,
    'SESSION_TIME_ENTERING_TOO_LATE'
  );
  static readonly SESSION_CANCELED = new ReturnNotice(
    163256354,
    'SESSION_CANCELED'
  );
  static readonly SESSION_ENDED = new ReturnNotice(690389806, 'SESSION_ENDED');
  static readonly SESSION_TIME_SKEW = new ReturnNotice(
    207137708,
    'SESSION_TIME_SKEW'
  );
  static readonly SESSION_ENTERING_UNASSIGNED_ROOM = new ReturnNotice(
    921342623,
    'SESSION_ENTERING_UNASSIGNED_ROOM'
  );
  static readonly SERIES_MAX_ATTENDEES_TO_FEW = new ReturnNotice(
    529445617,
    'SERIES_MAX_ATTENDEES_TO_FEW'
  );
  static readonly GROUP_TITLE_IS_TAKEN = new ReturnNotice(
    904512989,
    'GROUP_TITLE_IS_TAKEN'
  );
  static readonly GROUP_DELETED = new ReturnNotice(749407600, 'GROUP_DELETED');
  static readonly GROUP_UNJOIN_HAS_JOINED_SESSIONS = new ReturnNotice(
    744783198,
    'GROUP_UNJOIN_HAS_JOINED_SESSIONS'
  );
  static readonly GROUP_HAS_ACTIVE_SESSIONS = new ReturnNotice(
    230829449,
    'GROUP_HAS_ACTIVE_SESSIONS'
  );
  static readonly STRIPE_ERROR = new ReturnNotice(104840223, 'STRIPE_ERROR');
  static readonly DISCOUNT_CODE_ALREADY_USED = new ReturnNotice(
    726212550,
    'STRIPE_ERROR'
  );

  // private to disallow creating other instances of this type
  private constructor(
    public readonly id: number,
    public readonly name: string
  ) {}

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }
}
