import { useState, useCallback } from 'react';
import styles from './SubscriptionPurchase.module.css';
import {
  StripeContainer,
  StripeCardsWithPrevious,
  DiscountCodeInput,
  BillingPricePayNow,
  BillingPricePayMonth,
  BillingPriceFuture,
  BillingNotice,
  BillingNoticeMonthsOff,
  BillingPriceFreeMonths,
} from '_components';
import { useWellDispatch, useWellSelector } from '_hooks';
import { ISubscriptionType, IDiscountCode } from '_types';
import { stripeActions } from '_actions';
import { AnalyticsSendEvent } from '_helpers';

interface ISubscriptionPurchase {
  selectedSubscription: ISubscriptionType;
  callback: (...args: any[]) => void; // after the update
  btnText: string;
  allowDiscountInput: boolean;
}

export const SubscriptionPurchase = ({
  selectedSubscription,
  callback,
  btnText,
  allowDiscountInput,
}: ISubscriptionPurchase) => {
  const dispatch = useWellDispatch();
  const { userData } = useWellSelector((state) => state.userStore);
  const { subscription_info } = userData;
  const userSubscription = subscription_info;

  // errors are for this side of the checkout, there are also errors for card uploading
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [claimedCode, setClaimedCode] = useState<IDiscountCode>(
    {} as IDiscountCode,
  );

  const discountCallback = useCallback((code) => {
    setClaimedCode(code);
  }, []);

  const updateSubscription = useCallback(async () => {
    setIsLoading(true);
    setErrors('');

    try {
      // if needed try to claim a discount code
      if (claimedCode.code)
        await dispatch(stripeActions.claimDiscountCode(claimedCode.code, true));

      // front-end only cares about updating the info, the backend handles subscription
      const res = await dispatch(
        stripeActions.updateSubscription({
          subscription_type: selectedSubscription.id,
          auto_subscribe: 'true',
        }),
      );

      // this is a failed payment
      if (!res || res.next_subscription_type === null) {
        throw '';
      }

      // send events
      AnalyticsSendEvent('subscription_subscribed', {
        username: userData.username,
        email: userData.email,
        subscription_type: selectedSubscription.id,
      });

      callback();
    } catch (error) {
      setErrors(error ? (error as string) : 'Your card could not be charged');
      setIsLoading(false);
    }
  }, [
    claimedCode.code,
    dispatch,
    selectedSubscription.id,
    userData.username,
    userData.email,
    callback,
  ]);

  return (
    <div className={styles['payment']}>
      <h3>Start Your Mental Health Journey</h3>
      {userData.subscription_info.is_unlimited_subscribed ? (
        <h4>Improve your mental health. Satisfaction guaranteed.</h4>
      ) : (
        <h4>
          Satisfaction guaranteed or your money back in 30 days<sup>*</sup>
        </h4>
      )}

      <StripeContainer>
        <StripeCardsWithPrevious
          isLoadingCallback={isLoading}
          callback={updateSubscription}
          btnText={btnText}
        >
          <>
            {allowDiscountInput && !claimedCode.months_off && (
              <DiscountCodeInput callback={discountCallback} />
            )}

            <div className={styles['divider']}></div>

            <div className={styles['billing-information']}>
              {claimedCode.months_off ? (
                <BillingPriceFreeMonths
                  subscriptionType={selectedSubscription}
                  discountCode={claimedCode}
                />
              ) : userData.subscription_info.is_unlimited_subscribed ? (
                <BillingPriceFuture
                  userSubscription={userSubscription}
                  subscriptionType={selectedSubscription}
                />
              ) : selectedSubscription.months_off === 1 ? (
                <BillingPricePayMonth subscriptionType={selectedSubscription} />
              ) : (
                <BillingPricePayNow subscriptionType={selectedSubscription} />
              )}
            </div>

            {claimedCode.months_off ? (
              <BillingNoticeMonthsOff
                subscriptionType={selectedSubscription}
                discountCode={claimedCode}
              />
            ) : (
              <BillingNotice
                userSubscription={userSubscription}
                subscriptionType={selectedSubscription}
              />
            )}

            {errors && <div className={styles['errors']}>{errors}</div>}
          </>
        </StripeCardsWithPrevious>
      </StripeContainer>
    </div>
  );
};
