import { handleFetchReturn, failedToFetch } from '..';

export const breakoutRoomService = {
  createBreakOutRoom,
  assignToRoom,
};

export function createBreakOutRoom(session, room) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/' +
      session +
      '/room/' +
      room +
      '/create',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function assignToRoom(sessionUUID, userUUID, room) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/' +
      sessionUUID +
      '/room/' +
      room +
      '/user/' +
      userUUID +
      '/assign',
    requestOptions
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
