import React 	   from 'react';
import styles 		from './StaticPages.module.css';
import WellLogo     from '_images/WellLogo.png';


export function ServerDown() {

    return (
        <>
			<div className={styles['coming-soon-container'] + ' container'}>
            	<img src={WellLogo} className={styles['well-logo']} alt={'ShareWell Logo'}/>
				<p className={styles['coming-soon-text']}>Sorry! The site is currently down for maintenance.</p>
            </div>
        </>
    );
}