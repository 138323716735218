import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    sectionOne: {
      paddingBottom: '32px',
      [theme.breakpoints.up('md')]: {
        paddingBottom: '64px',
      },


    },
    sectionTwo: {},
    contentContainer: {},
    topSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
      },
    },
    first: {
      maxWidth: '900px',
      '& h3': {
        textAlign: 'left',
        marginBottom: '25px',
        [theme.breakpoints.up('md')]: {},
      },
      '& p': {
        marginBottom: 0,
      },
    },
    topSectionTwo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
      },
    },
    second: {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
      },
      '& > h3': {
        textAlign: 'center',
        marginBottom: '25px',
      },
      '& p': {
        marginBottom: '16px',
        [theme.breakpoints.up('md')]: {
          paddingLeft: '32px',
        },
      },
    },
    noMarginBottom: {
      marginBottom: '0 !important',
    },
    imageContainerOne: {
      width: '430px',
      position: 'relative',
      marginBottom: '16px',
      paddingLeft: '32px',
      flexShrink: 0
    },
    imageContainerTwo: {
      width: '900px',
      position: 'relative',
      maxWidth: '200px',
      margin: '30px auto 0',
      [theme.breakpoints.up('md')]: {
        marginRight: '32px',
        maxWidth: 'none',
      },
    },

    image: {},
  })
);

export default useStyles;
