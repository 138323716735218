import { handleFetchReturn, failedToFetch } from '..';

export const roomService = {
  enterRoom,
  sessionRoomList,
  checkPostSessionCompletions,
};

export function enterRoom(session: string, room: number) {
  return fetch(
    process.env.REACT_APP_API_URL +
      '/session/' +
      session +
      '/room/' +
      room +
      '/enter',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function sessionRoomList(session: string, payload: any = {}) {

  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + session + '/room/list',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function checkPostSessionCompletions(session: string) {

  return fetch(
    process.env.REACT_APP_API_URL + '/session/' + session + '/post_session',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
