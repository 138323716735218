import { handleFetchReturn, failedToFetch } from '.';
import { ICreateAccount, IFacebookLogin } from '_types';

export const authService = {
  loginWithPassword,
  registerUser,
  handleLogout,
  resetPasswordRequest,
  resetPasswordConfirm,
  setAccountInactive,
  loginThinkific,
  loginGoogle,
  loginFacebook,
};

export function loginWithPassword(username_or_email: string, password: string) {
  return fetch(process.env.REACT_APP_API_URL + '/account/login/password', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username_or_email: username_or_email,
      password: password,
    }),
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function registerUser(formData: ICreateAccount) {
  return fetch(process.env.REACT_APP_API_URL + '/account/create', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function handleLogout() {
  return fetch(process.env.REACT_APP_API_URL + '/account/logout', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function resetPasswordRequest(email: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/account/passwordreset/' + email,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function resetPasswordConfirm(
  email: string,
  secret: string,
  newPassword: string
) {
  let url =
    process.env.REACT_APP_API_URL +
    '/account/passwordreset/' +
    email +
    '/' +
    secret +
    '/' +
    encodeURIComponent(newPassword);

  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function setAccountInactive() {
  return fetch(process.env.REACT_APP_API_URL + '/account/set_inactive', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

export function loginThinkific() {
  return fetch(process.env.REACT_APP_API_URL + '/account/login_thinkific', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

function loginGoogle(code: string) {
  return fetch(process.env.REACT_APP_API_URL + '/account/login/google', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ code }),
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}

function loginFacebook(data: IFacebookLogin) {
  return fetch(process.env.REACT_APP_API_URL + '/account/login/facebook', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      email: data.email,
      name: data.name,
      fb_user_id: data.userId,
    }),
  })
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
