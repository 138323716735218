import styles from './SessionItemList.module.css';
import { useCallback } from 'react';
import { history } from '_helpers';

interface ISessionItemListEmpty {
  allowCreate: boolean;
  selectedGroup: { uuid: string; name: string };
}

export const SessionItemListEmpty = ({
  allowCreate,
  selectedGroup,
}: ISessionItemListEmpty) => {
  const handleOnClick = useCallback(() => {
    if (selectedGroup.uuid !== '')
      history.push('/session-create/' + selectedGroup.uuid);
    else history.push('/session-create');
  }, [selectedGroup.uuid]);

  return (
    <div
      className={
        styles['session-item-list'] + ' ' + styles['session-item-empty']
      }
      onClick={handleOnClick}
    >
      {allowCreate && (
        <div>
          <div className={styles['plus-sign']}>+</div>
          <div>CREATE SESSION</div>
        </div>
      )}
    </div>
  );
};
