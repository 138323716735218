import styles from './SessionInfoOptions.module.css';
import { SessionOptionsDropdown, ArrowIcon } from '_components';
import {
  AnalyticsSendEvent,
  getSessionEndTime,
  getSessionStartTime,
} from '_helpers';
import AddToCalendar from '@culturehq/add-to-calendar';
import '@culturehq/add-to-calendar/dist/styles.css';
import { componentActions } from '_actions';
import { useWellDispatch, useSessionInfo, useWellSelector } from '_hooks';
import { ISession } from '_types';

export const SessionInfoOptions = () => {
  const dispatch = useWellDispatch();
  const { loggedIn } = useWellSelector((state) => state.userStore);

  const { sessionInfo, sessionPermissions } = useSessionInfo();

  const openShareModal = () => {
    dispatch(
      componentActions.openModal({
        isSocialShareSession: true,
      })
    );
  };

  return (
    <div className={styles['options']}>
      <div className={styles['share-btn-container']} onClick={openShareModal}>
        <ArrowIcon width={'18px'} height={'18px'} />{' '}
        <span className={styles['share-text']}>Share</span>
      </div>

      {sessionPermissions.canSeeCalenderBtn && (
        <AddToCalendarBtn
          session={sessionInfo}
          isOrange={false}
          onAddToCalendarClick={() => {}}
        />
      )}

      {loggedIn && <SessionOptionsDropdown className={styles['options-btn']} />}
    </div>
  );
};

export const AddToCalendarBtn = ({
  session,
  isOrange,
  onAddToCalendarClick,
}: {
  session: ISession;
  isOrange: boolean;
  onAddToCalendarClick: () => void;
}) => {
  const addToCalendarEvent = () => {
    AnalyticsSendEvent('session_add_to_calendar', {
      session: session.uuid,
    });
    onAddToCalendarClick();
  };

  return (
    <div
      className={
        styles['add-to-calendar-container'] +
        ' ' +
        (isOrange ? styles['is-orange'] : '')
      }
      onClick={addToCalendarEvent}
    >
      <AddToCalendar
        event={{
          name: session.title,
          details: session.description,
          location: window.location.href,
          startsAt: getSessionStartTime(session).format(),
          endsAt: getSessionEndTime(session).format(),
        }}
        children={
          <span className={styles['add-to-calendar-text']}>
            Add to Calendar
          </span>
        }
      />
    </div>
  );
};
