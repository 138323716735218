import { useState, useEffect, useCallback } from 'react';
import { sessionsActions, userSessionsActions } from '_actions';
import { history } from '_helpers';
import { generalConstants } from '_constants';
import { ISession, ESortType } from '_types';
import { useWellDispatch } from '_hooks';

/**
 * Handles sessions pagination
 * If passed the special group uuid, it get's users own sessions
 * @param group
 */
export const useSessionsPagination = (
  group: string,
  startTime: string,
  endTime: string,
  startingSortType: ESortType,
  paidOnly: boolean,
  paginationLimit: number,
  excludeFullSessions: boolean
) => {
  const dispatch = useWellDispatch();

  const [allSessions, setAllSessions] = useState<ISession[]>([]);
  const [sessionsPagination, setSessionsPagination] = useState({
    curOffset: 0,
    isDone: false,
  });
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sortType, setSortType] = useState<ESortType>(startingSortType);

  //loading
  const [isLoadingInitial, setIsLoadingInitial] = useState(true); // is doing first load
  const [isLoadingSessions, setIsLoadingSessions] = useState(true); // is changing sort or search
  const [isLoadingMoreSessions, setIsLoadingMoreSessions] = useState(false); // is loading more

  // handle searching & initial load
  // on search/ sort change pagination resets
  useEffect(() => {
    const search = async () => {
      if (searchPhrase === null || group === undefined) return;
      setIsLoadingSessions(true);

      let response;
      // if special gets own sessions
      if (group === generalConstants.SPECIAL_UUID) {
        response = await dispatch(
          userSessionsActions.getMyGroupsSessions(
            paginationLimit,
            0,
            excludeFullSessions
          )
        );
      } else {
        response = await dispatch(
          sessionsActions.getSessionsList(
            searchPhrase,
            paginationLimit,
            0,
            startTime,
            endTime,
            group,
            sortType,
            paidOnly ? 'true' : 'false',
            excludeFullSessions
          )
        );
      }

      if ((response && response.length > 0) || (response && group !== '')) {
        setAllSessions(response);
        setSessionsPagination({
          curOffset: sessionsPagination.curOffset + paginationLimit,
          isDone: response.length === 0 ? true : false,
        });
      }

      setIsLoadingInitial(false);
      setIsLoadingSessions(false);
    };

    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, sortType, group, dispatch, paidOnly]);

  // loads more sessions when the user scrolls to the bottom of the page
  const scrollSearchFunction = async () => {
    if (sessionsPagination.isDone || isLoadingMoreSessions) return false;
    setIsLoadingMoreSessions(true);
    const response: any = await dispatch(
      sessionsActions.getSessionsList(
        searchPhrase,
        paginationLimit,
        sessionsPagination.curOffset,
        startTime,
        endTime,
        group,
        sortType,
        paidOnly ? 'true' : 'false',
        excludeFullSessions
      )
    );

    if (response) {
      setAllSessions(allSessions.concat(response));
      setSessionsPagination({
        curOffset: sessionsPagination.curOffset + paginationLimit,
        isDone: response.length === 0,
      });
    }

    setIsLoadingMoreSessions(false);
  };

  // reload a single session in the list if it's state has changed
  const reloadSingleSession = useCallback(
    (session: string) => {
      const hasSession =
        allSessions.filter((item) => item.uuid === session).length > 0;
      if (hasSession) {
        dispatch(sessionsActions.getSession(session)).then(
          (session: ISession) => {
            setAllSessions((prev) => {
              return prev.map((item) => {
                if (item.uuid === session.uuid) return session;
                return item;
              });
            });
          }
        );
      }
    },
    [allSessions, dispatch]
  );

  // takes the user to the createSession page (could also be done with Link now)
  const createSession = useCallback(() => {
    if (group) history.push('/session-create/' + group);
    else history.push('/session-create');
  }, [group]);

  // handles changing the search phrase from a function
  const handleSearchChange = useCallback((e: any) => {
    setSearchPhrase(e.target.value);
  }, []);

  // handles changing the search phrase from a function
  const handleSortTypeChange = useCallback((newSortType: ESortType) => {
    setSortType(newSortType);
  }, []);

  return {
    //sessions
    allSessions,
    sessionsPagination,
    searchPhrase,
    sortType,

    //loading
    isLoadingInitial,
    isLoadingSessions,
    isLoadingMoreSessions,

    //functions
    scrollSearchFunction,
    createSession,
    handleSearchChange,
    handleSortTypeChange,
    reloadSingleSession,
  };
};
