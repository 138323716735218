import { createContext, Dispatch, SetStateAction, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { GroupInfo } from '.';
import { groupActions, groupCreatorActions, componentActions } from '_actions';
import { history, AnalyticsSendEvent } from '_helpers';
import {
  useGroupInfoState,
  useGroupBadge,
  useGroupInfoTabs,
  useWellDispatch,
  useWellSelector,
  useModalFlagWatcher,
} from '_hooks';
import { IGroupWithHost, IActivity, EModalFlags } from '_types';
import { componentsConstants } from '_constants';

interface IGroupInfoContext {
  //state
  group: IGroupWithHost;
  isLoading: boolean;
  show404: boolean;
  curTab: number;
  showingPost: boolean | IActivity;

  //state functions
  setGroup: Dispatch<SetStateAction<IGroupWithHost>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setShow404: Dispatch<SetStateAction<boolean>>;
  setCurTab: Dispatch<SetStateAction<number>>;
  setShowingPost: Dispatch<SetStateAction<boolean | IActivity>>;

  //functions
  subscribeToGroup: () => void;
  unsubscribeToGroup: () => void;
  deleteGroup: () => void;
  reportGroup: () => void;
  removeUser: (user: string) => void;
  openShareModal: () => void;

  //routing functions
  editGroup: () => void;
  createSession: () => void;
  goToPreferences: () => void;
}

export const GroupInfoContext = createContext<IGroupInfoContext>(
  {} as IGroupInfoContext
);

export const GroupInfoProvider = () => {
  const dispatch = useWellDispatch();
  const { uuid, title } = useParams<{ uuid: string; title: string }>();
  const { userStore } = useWellSelector((state) => state);

  const {
    group,
    isLoading,
    show404,
    showingPost,
    setGroup,
    setIsLoading,
    setShow404,
    setShowingPost,
  } = useGroupInfoState();

  const { curTab, setCurTab } = useGroupInfoTabs(group?.am_i_joined);

  useGroupBadge(curTab, group, isLoading);

  const reloadGroup = useCallback(async () => {
    const groupResponse: any = await dispatch(
      groupActions.getGroupInfo(uuid.length == 36 ? uuid : title)
    );
    setGroup(groupResponse);
  }, [dispatch, setGroup, uuid, title]);

  // modal can change state, so watch for changes
  useModalFlagWatcher(EModalFlags.HAS_UPDATED_GROUP, reloadGroup);

  // it is possible to subscribe to group from any screen, so needs to use global modal
  const subscribeToGroup = async () => {
    const { uuid } = group;
    dispatch({
      type: componentsConstants.TOGGLE_NEW_MODAL,
      isGroupJoinModalOpen: {
        group: uuid,
      },
    });
  };

  // SHOULD BE MOVED
  const unsubscribeToGroup = async () => {
    const { uuid } = group;
    dispatch(
      componentActions.openModal({
        title: 'Are you sure you want to leave this Well?',
        modalBodyStyle: { textAlign: 'center' },
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        yesText: 'LEAVE',
        noText: 'CANCEL',
        yesClick: async () => {
          const response: any = await dispatch(
            groupActions.subscribeToGroup(uuid, false)
          );

          if (response?.notice) {
            dispatch(
              componentActions.openModal({
                title:
                  'Oh no! You are unable to leave this Well because you have committed to a Session. Please make sure you have no upcoming Sessions before leaving this Well.',
              })
            );
            return;
          }

          reloadGroup();
          AnalyticsSendEvent('group_unsubscribe', {
            user: userStore.userData.uuid,
            group: uuid,
          });
          setCurTab(0); // in case we are on another tab
        },
        noClick: () => {},
      })
    );
  };

  const editGroup = () => {
    history.push(
      '/well/' +
        group.title.toLowerCase().replaceAll(' ', '-') +
        '/' +
        group.uuid +
        '/edit'
    );
  };
  const createSession = () => {
    history.push('/session-create/' + group.uuid);
  };

  const deleteGroup = async () => {
    dispatch(
      componentActions.openModal({
        title: 'Delete this Well?',
        body: 'Oh no! Are you sure you want to delete this Well? This means all sessions in this Well will be deleted and your community will be disbanded. If you are no longer able to manage this Well, you can email support@sharewellnow.com and a member of our team will be happy to take-over. Please consider carefully before you decide to delete your community.',
        modalBodyStyle: { textAlign: 'center' },
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        yesText: 'DELETE',
        noText: 'CANCEL',
        yesClick: async () => {
          const response: any = await dispatch(
            groupCreatorActions.deleteGroup(group.uuid)
          );
          if (response?.notice) {
            dispatch(
              componentActions.openModal({
                title:
                  'Oh no! You are unable to leave delete this Well because there are still upcoming sessions. Please make sure there are no upcoming Sessions before deleting this Well.',
              })
            );
          } else {
            AnalyticsSendEvent('group_delete', {
              user: userStore.userData.uuid,
              group: uuid,
            });
            history.push('/wells');
          }
          return Promise.resolve(response);
        },
        noClick: () => {},
      })
    );
  };

  const reportGroup = async () => {
    dispatch(
      componentActions.openModal({
        title: 'Report this Well?',
        body: 'Please enter the reason for reporting this Well.',
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        isInput: true,
        yesText: 'REPORT',
        noText: 'CANCEL',
        placeholder: 'Reason...',
        afterText: 'Report submitted successfully.',
        yesClick: (reason: string) => {
          dispatch(groupActions.reportGroup(uuid, reason));
          AnalyticsSendEvent('group_report', {
            group: group.uuid,
            user: userStore.userData.uuid,
          });
          return Promise.resolve(1);
        },
        noClick: () => {},
      })
    );
  };

  const openShareModal = () => {
    dispatch(
      componentActions.openModal({
        isSocialShareGroup: true,
        group: group,
      })
    );
  };

  const removeUser = async (user: string) => {
    dispatch(
      componentActions.openModal({
        title: 'Remove this user?',
        body: 'Are you sure you want to remove this user from the Well.',
        modalBodyStyle: { textAlign: 'center' },
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        yesText: 'REMOVE',
        noText: 'CANCEL',
        yesClick: async () => {
          await dispatch(groupCreatorActions.removeUser(uuid, user));

          //need to get the correct group user list now
          const groupResponse: any = await dispatch(
            groupActions.getGroupInfo(uuid)
          );
          setGroup(groupResponse); //set the new group data

          return Promise.resolve(1);
        },
        noClick: () => {},
      })
    );
  };

  const goToPreferences = () => {
    history.push(
      '/well/' +
        group.title.toLowerCase().replaceAll(' ', '-') +
        '/' +
        group.uuid +
        '/preferences'
    );
  };

  return (
    <GroupInfoContext.Provider
      value={{
        // state
        group,
        isLoading,
        show404,
        curTab,
        showingPost,

        // state functions
        setGroup,
        setIsLoading,
        setShow404,
        setCurTab,
        setShowingPost,

        // functions
        subscribeToGroup,
        unsubscribeToGroup,
        deleteGroup,
        reportGroup,
        removeUser,
        openShareModal,

        // routing functions
        editGroup,
        createSession,
        goToPreferences,
      }}
    >
      <GroupInfo />
    </GroupInfoContext.Provider>
  );
};
