import { useRef } from 'react';
import CSS from 'csstype';
import styles from './WellInputWrapper.module.css';
import { WellLabel } from '_components';

interface IWellInputWrapper {
  children: JSX.Element; // this is actual input
  label?: string;
  error?: string;
  containerClassName?: string; // container style
  inputContainerClassName?: string;
  labelStyle?: CSS.Properties; // label style
  errorRef?: React.Ref<any> | null; // generic here?
  charCount?: number;
}

// a style wrapper for inputs, do not put logic here
export const WellInputWrapper = ({
  children,
  label,
  error,
  containerClassName,
  inputContainerClassName,
  labelStyle,
  errorRef,
  charCount,
}: IWellInputWrapper) => {
  const containerRef = useRef(null);

  return (
    <div
      className={
        styles['container'] +
        ' ' +
        (containerClassName ? containerClassName : '')
      }
      ref={containerRef}
    >
      {label && <WellLabel label={label} labelStyle={labelStyle} />}
      {error && error != 'Invalid' && (
        <div
          className={
            styles['error'] + ' ' + (label ? styles['error-with-label'] : '')
          }
        >
          {error}
        </div>
      )}
      <div
        className={
          styles['input-container'] +
          ' ' +
          (inputContainerClassName ? inputContainerClassName : '')
        }
        ref={errorRef}
      >
        {children}
      </div>
      {charCount !== undefined && (
        <div className={styles['char-count']}>Character Count: {charCount}</div>
      )}
      {error && error == 'Invalid' && (
        <div className={styles['error']}>
          This username is already taken. Please select another.
        </div>
      )}
    </div>
  );
};
