import styles from './SessionsListHeader.module.css';
import { useGetImgixVersion, useWellSelector } from '_hooks';
import { history } from '_helpers';
import { WellButton, SessionsListSearch, ModalWrapper } from '_components';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { DispatchBoolean, EHostLevel } from '_types';

export const SessionsListHeader = () => {
  const { userStore } = useWellSelector((state) => state);
  const { loggedIn, userData } = userStore;

  const [modal, setModal] = useState(false);

  const handleCreateSession = useCallback(() => {
    if (userData.host_level === EHostLevel.HOST_LEVEL_0) {
      setModal(true);
    } else {
      history.push('/session-create');
    }
  }, [userData.host_level]);

  return (
    <>
      <div className={styles['sessions-menu-container']}>
        <h1 className={styles['title']}>
          Discover Your Next&nbsp;Session
        </h1>
        <p className={styles['subtitle']}>
          You are not alone. Join a peer support session and uplift your heart
          and spirit with the help of your peers. If you don't see a session for
          you,{' '}
          <Link className={styles['header-link']} to={'/host'}>
            host&nbsp;your&nbsp;own!
          </Link>
        </p>

        <div className={styles['sessions-search-container']}>
          <SessionsListSearch />
          {loggedIn && (
            <WellButton
              type={'well-btn-orange'}
              className={styles['my-sessions-btn']}
              btnText={'CREATE SESSION'}
              btnOnClick={handleCreateSession}
            />
          )}
        </div>

        <svg
          className={styles['wave-svg']}
          viewBox="0 0 500 150"
          width={'100%'}
          height={'100'}
          preserveAspectRatio="none"
        >
          <path d="M0.00,49.98 C250.00,133.70 240.97,14.30 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"></path>
        </svg>
      </div>

      {modal && <LessThanOneSessionModal setModal={setModal} />}
    </>
  );
};

export const LessThanOneSessionModal = ({
  setModal,
}: {
  setModal: DispatchBoolean;
}) => {
  const image = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/how-it-works/host-2.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const closeModal = useCallback(() => {
    setModal(false);
  }, [setModal]);

  return (
    <ModalWrapper
      isLoading={false}
      closeModal={closeModal}
      disallowClose={false}
    >
      <>
        <div className={styles['image-container']}>
          <img src={image} className={styles['image']} alt={'Become a Host'} />
        </div>
        <div className={styles['modal-text']}>
          <p>
            We require you first attend one session before hosting. Once you've
            attended a session, you will unlock the ability to create a session.
            See how to <Link to={'/host'}>Host page</Link> for more information.
          </p>
        </div>
      </>
    </ModalWrapper>
  );
};
