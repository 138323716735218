import { useState, useEffect, useCallback } from 'react';
import { useWellDispatch } from '_hooks';
import { IUser, EHostLevel } from '_types';
import { adminUserActions } from '_actions';

export const useAdminUsersSearch = (hostLevel: EHostLevel | null) => {
  const dispatch = useWellDispatch();

  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(
    async (search) => {
      setIsLoading(true);
      const response = await dispatch(
        adminUserActions.adminUserSearch(hostLevel, search)
      );
      if (response) setUsers(response);
      setIsLoading(false);
    },
    [dispatch, hostLevel]
  );

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    users,
    isLoading,
    getData,
  };
};
