import styles from './SessionItemListSmall.module.css';
import { useGetImgixVersion } from '_hooks';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { timeConstants } from '_constants';
import PlusSign from '_images/plus-sign.png';


interface SessionItemListSmallProps {
  session: any;
  isSeries: boolean;
}

export const SessionItemListSmall = ({ session, isSeries }: SessionItemListSmallProps) => {

  const image = useGetImgixVersion(session.pic, {
    h: 100,
    w: 100,
    fit: 'crop',
    crop: 'edge',
  });

  const localTime = moment();

  return (
    <Link
      to={isSeries ? '/series/' + session.series.uuid : '/session/' + session.uuid}
      className={styles['session-item-list']}
    >
      <>
        <img className={styles['image']} src={image} alt={session.title} />
        <div className={styles['right']}>
          <h4 className={styles['title']}>{session.title}</h4>
          <h5 className={styles['date']}>
            {localTime
              .startOf('day')
              .isBefore(moment.tz(session.start_time, session.timezone)) &&
              localTime
                .endOf('day')
                .isAfter(moment.tz(session.start_time, session.timezone)) ? (
              <span className={styles['today']}>
                Today{' '}
                {moment
                  .tz(session.start_time, session.timezone)
                  .local()
                  .format(timeConstants.timeFormat)}
              </span>
            ) : (
              moment
                .tz(session.start_time, session.timezone)
                .local()
                .format('dddd, MMMM D')
            )}
          </h5>
        </div>
      </>
    </Link>
  );
};



export const SessionItemListSmallEmpty = () => {


  return (
    <Link
      to={'/session-create'}
      className={styles['session-item-list']}
    >
      <>
        <img className={styles['image'] + ' ' + styles['image-small']} src={PlusSign} alt={'Plus Sign'} />
        <div className={styles['right']}>
          <h4 className={styles['title']}>{'Create New Session'}</h4>
        </div>
      </>
    </Link>
  );
};