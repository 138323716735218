import { handleFetchReturn, failedToFetch } from '.';

export const chatService = {
  tagInChat,
};

export function tagInChat(session: string, user: string) {
  return fetch(
    process.env.REACT_APP_API_URL + '/session_chat/' + session + '/tag/' + user,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }
  )
    .then((response) => handleFetchReturn(response))
    .catch((error) => failedToFetch(error));
}
