import styles from './LegalDocuments.module.css';

import { WellHeader } from '_components';

export const ContentPolicy = () => {
  return (
    <div className={styles['legal-document']}>
      <WellHeader title={'Community Safety & Protocols'} />

      <div className={styles['legal-document-container'] + ' container'}>
        <p>
          ShareWell is a place for peer support. We define peer support as
          sharing of lived experiences with others for mutual connection and
          support. Seeking peer support on ShareWell means adhering to our
          safety policies. Violation of the policies below may result in
          suspension from the platform.{' '}
        </p>

        <h4>Content Policies</h4>

        <p className={styles['no-bottom']}>
          At ShareWell we do not allow the following on our platform:
        </p>
        <ul>
          <li>Dangerous content</li>
          <li>Harassing content</li>
          <li>Hateful content</li>
          <li>Manipulated media</li>
          <li>Sexually explicit content</li>
          <li>Vulgar language</li>
          <li>Medical diagnosis</li>
          <li>Instructive advice</li>
        </ul>
        <p className={styles['no-bottom']}>
          Use of offensive language or hate speech is not allowed on ShareWell.
          We remove content promoting violence or hatred against individuals or
          groups based on any of the following attributes:
        </p>
        <ul>
          <li>Age</li>
          <li>Caste</li>
          <li>Disability</li>
          <li>Ethnicity</li>
          <li>Gender Identity and Expression</li>
          <li>Nationality</li>
          <li>Race</li>
          <li>Immigration Status</li>
          <li>Religion</li>
          <li>Sex/Gender</li>
          <li>Sexual Orientation</li>
          <li>Victims of a major violent event and their kin</li>
          <li>Veteran Status</li>
        </ul>

        <p>
          If you find content that violates this policy, please report it.
          Violators will be suspended from the site, and their profile/content
          will go under review. ShareWell reserves the right to suspend or
          permanently remove any user from the platform as we see fit.{' '}
        </p>

        <p className={styles['no-bottom']}>
          ShareWell content posting etiquette includes:
        </p>
        <ul>
          <li>NO self-promotion</li>
          <li>NO spam or irrelevant content</li>
          <li>
            NO sharing of personal contact information: For your safety and
            privacy, we strongly discourage the public sharing of personal
            contact information on ShareWell. It is advised not to disclose your
            full name or contact details. Connecting outside of the ShareWell
            platform can potentially lead to unsafe communication and dynamics.
          </li>
        </ul>

        <p className={styles['no-bottom']}>
          In addition, ShareWell is not the correct support tool if you are:
        </p>
        <ul>
          <li>Harming yourself or another person</li>
          <li>Are in physical danger</li>
          <li>Contemplating suicide or having suicidal thoughts</li>
          <li>Have participated in or witnessed a crime</li>
          <li>In need of medical or psychiatric help</li>
        </ul>

        <p className={styles['terms-p']}>
          Please see additional{' '}
          <a href={'https://sharewellnow.com/safety-resources'}>
            safety resources
          </a>{' '}
          if you are experiencing any of the above.
        </p>

        <h4>REPORTING A DISCUSSION OR CHAT POST</h4>
        <p className={styles['terms-p']}>
          ShareWell may, at any time and without notice, remove inappropriate or
          offensive material from ShareWell’s community forum. If you come
          across any discussion posts that are disruptive, violate a community
          ground rule, or speak of self-harm, you may report the post for
          immediate review by the ShareWell team.
        </p>

        <p className={styles['no-bottom']}>For a Discussion Post:</p>
        <ul>
          <li>
            Click on the three dots in the right-hand corner of any discussion
            post.
          </li>
          <li>
            Select "report post." A pop-up window will appear, allowing you to
            provide your reason for reporting.
          </li>
        </ul>

        <p className={styles['no-bottom']}>For a Chat Message:</p>
        <ul>
          <li>Click on the three dots until a flag icon appears.</li>
          <li>
            Select the flag icon, and a pop-up window will appear where you can
            fill out your reason for reporting.
          </li>
        </ul>

        <p className={styles['no-bottom']}>
          What happens when you report a post?
        </p>
        <ul>
          <li>The post goes under immediate review by ShareWell </li>
          <li>
            ShareWell will determine whether a violation has taken place and
            may, at any time and without notice, remove any content.
          </li>
          <li>
            Based on the severity, ShareWell will give notice to the member in
            violation or suspend their account pending further review.
          </li>
          <li>Reporting stays 100% anonymous for those who report a post.</li>
        </ul>

        <h4>Reporting a Member</h4>
        <p className={styles['terms-p']}>
          If you feel a ShareWell member is acting against ShareWell community
          guidelines, is at risk of harming themselves or is exhibiting behavior
          that is cause for concern or disruptive, you may report them. Once
          reported, the member will be under review by the ShareWell team. This
          is done anonymously, the person being reported will never know who has
          reported them.
        </p>

        <p className={styles['no-bottom']}>How to report</p>
        <ul>
          <li>
            During a session, click on the participant's photo and choose
            "report member." A pop-up window will appear where you can provide
            your reason for reporting.
          </li>
          <li>
            On a member's profile, click on the three dots next to their profile
            photo and select "report member." A pop-up window will appear where
            you can provide your reason for reporting.
          </li>
        </ul>

        <p className={styles['no-bottom']}>What happens when you report?</p>
        <ul>
          <li>ShareWell will start a review process of the person reported</li>
          <li>
            ShareWell may reach out to both parties, the reporter and the
            reported, to obtain more information about the situation.{' '}
          </li>
          <li>
            Based on the findings of the review, ShareWell will act accordingly
            depending on the severity of the offense. Whether it’s giving the
            member a warning, putting them on probation or removing them from
            the platform.{' '}
          </li>
          <li>Reporting stays 100% anonymous for those who report </li>
        </ul>

        <p className={styles['terms-p']}>
          We may, at any time and without notice, remove any Content and suspend
          or terminate your account or access to our Services if you engage in
          any of the activities that violate the policies herein, including
          activities outside of your use of the Services.
        </p>

        <h4>Session Rules </h4>
        <p>
          All members must follow these rules when joining a peer support
          session on ShareWell. Violation of any rule may result in removal from
          the session and repeated violations may result in suspension from the
          platform.
        </p>
        <ul>
          <li>
            Lived experience of the topic. Everyone in the session must have
            personal experience of the session topic. This ensures authentic
            peer support and makes everyone feel safe sharing.{' '}
          </li>
          <li>
            Video camera on and stay within camera view. This is a virtual video
            session and your camera must be on during the session. We ask that
            you stay within camera view the entire session.
          </li>
          <li>
            Share your experience, not your advice. We do not allow members to
            give direct advice as this is a session amongst peers with no one
            acting in a professional mental health capacity.
          </li>{' '}
        </ul>

        <h4>Removal from a Session </h4>

        <p className={styles['terms-p']}>
          You are responsible for your conduct. Hosts may remove anyone from a
          session if they feel their behavior is disrespectful or disruptive to
          the group. Once removed, a member is unable to rejoin that specific
          session, but they can join future sessions.
        </p>
        <p className={styles['no-bottom']}>How to remove</p>
        <ul>
          <li>
            Hosts can remove a member from a session by clicking their photo and
            selecting "remove member."
          </li>
        </ul>
        <p className={styles['terms-p']}>
          We encourage hosts to give a warning before removal if possible, but
          ultimately it is up to the hosts' discretion as the group facilitator.
        </p>

        <h4>RESTRICTING</h4>
        <p className={styles['terms-p']}>
          To ensure a positive and respectful environment, we have implemented
          an account restriction feature. This feature enables individuals to
          limit their interactions with specific members. We recognize that
          everyone has different triggers, and it's important to note that
          restricting is not an indication of wrongdoing but rather reflects
          diverse emotional responses to various personalities or experiences.
          Our goal is to ensure that ShareWell is as comfortable as possible for
          each member.
        </p>
        <p className={styles['no-bottom']}>How to Restrict:</p>
        <ul>
          <li>
            During a session, click on the participant's photo and select
            "restrict member." The restriction will take effect after the
            session concludes.{' '}
          </li>
          <li>
            On a member's profile, click on the three dots next to their profile
            photo and choose "restrict member."
          </li>
        </ul>
        <p className={styles['no-bottom']}>Effects of Restriction: </p>
        <ul>
          <li>
            You will be unable to participate in sessions with that member.
          </li>
          <li>
            You will not be able to view or respond to their discussion posts.
          </li>
          <li>
            If the restricted member is a host, their sessions will be invisible
            and inaccessible to you.
          </li>
        </ul>

        <p className={styles['no-bottom']}>How to Remove Restriction:</p>
        <ul>
          <li>
            To unrestrict a member, go to their profile, click on the three dots
            next to their profile photo, and select "unrestrict member."
          </li>
        </ul>
        <p className={styles['terms-p']}>
          We understand that everyone has challenging days. We request you
          utilize the account restriction feature sparingly and only when it is
          genuinely necessary.
        </p>

        <p className={styles['no-bottom']}>If You Have Been Restricted:</p>
        <p className={styles['no-bottom']}>
          If another community member has restricted you, we expect you to
          respect their need for space. Violating their boundaries, personal
          privacy, or ShareWell's confidentiality agreement, as outlined in our
          code of conduct, is strictly prohibited. The following actions are
          considered unacceptable:
        </p>
        <ul>
          <li>
            Harassment, bullying, or attempting to contact a restricted peer on
            or off the ShareWell platform.
          </li>
          <li>
            Discussing your restriction or the specific behavior of a member who
            restricted you in ShareWell sessions, chats, or forums.
          </li>
          <li>Trying to circumvent the restriction in any form or manner.</li>
        </ul>
        <p className={styles['terms-p']}>
          Continued engagement in such behaviors, both on and off the ShareWell
          platform, can result in suspension.
        </p>

        <p className={styles['no-bottom']}>Understanding Restriction:</p>
        <p className={styles['no-bottom']}>
          We acknowledge that being restricted can be an adjustment and may
          cause hurt feelings. Please remember that restriction does not
          necessarily indicate wrongdoing on your part, but rather reflects a
          member's decision to take a break from attending sessions together.
          Each person has different triggers, and the restriction feature is in
          place to prevent uncomfortable situations that may arise within the
          community. Reasons for member restrictions are never disclosed to the
          restricted. Any attempts to contact ShareWell staff, ShareWell
          community members, or the support team regarding the restriction
          reason will not receive a response.{' '}
        </p>

        <p className={styles['no-bottom']}>
          Confidentiality and Community Harmony:
        </p>
        <p className={styles['terms-p']}>
          We treat all restrictions as confidential out of respect for our
          members and expect that all parties involved maintain confidentiality.
          Any attempt to create animosity within the community or breach
          confidentiality will be considered a violation of ShareWell's
          community groundrules. Please note that restrictions are typically
          temporary, and the feature is standard practice on community platforms
          like ours. By implementing this feature, we aim to enhance the peer
          experience and prevent potential discomfort that may arise with
          certain members.
        </p>

        <h4>HOST ETIQUETTE</h4>
        <p className={styles['no-bottom']}>
          As hosts, it is essential to uphold the community ground rules and
          adhere to all community guidelines. If you encounter any issues,
          please follow this protocol:
        </p>
        <ol>
          <li>
            Report it: If you experience any triggers related to a person,
            forum, or chat post, immediately report it using the reporting
            guidelines mentioned above. This is the only acceptable channel for
            discussing specific members' behavior.
          </li>
          <li>
            Reach out:
            <ul>
              <li>
                Support: For technical issues or immediate hosting concerns,
                contact{' '}
                <a href='mailto:support@sharewellnow.com'>
                  support@sharewellnow.com
                </a>{' '}
              </li>
              <li>
                Host Chat: For personal hosting questions, seek assistance in
                the Host Chat, where you can receive support from the host
                community and ShareWell staff. Please note that this platform is
                for non-urgent matters and not suitable for discussing specific
                members' behavior on the site.
              </li>
              <li>
                Host Support Circle: For guidance, inquiries, or feedback for
                ShareWell staff, participate in a Host Support Circle. This
                forum is for non-urgent matters and should not be used to
                discuss specific members' behavior on the site.
              </li>
            </ul>
          </li>
        </ol>

        <p className={styles['no-bottom']}>Host Privacy:</p>
        <p className={styles['no-bottom']}>
          For your safety and privacy, we strongly discourage the public sharing
          of personal contact information on ShareWell. Although professional
          hosts may include a company website on their profile, it is advisable
          to refrain from disclosing non-business related contact details.
          Connecting outside of the ShareWell platform can potentially lead to
          unsafe communication and dynamics.
        </p>
      </div>
    </div>
  );
};
