import { useState, useEffect } from 'react';
import styles from './WellCountdown.module.css';
import Countdown, { zeroPad } from 'react-countdown';
import { getServerTime } from '_helpers';

//timeToEnd in ms
export const WellCountdown = ({
  startTime,
  timeToEnd,
  endNotice,
  endNoticeClass,
  onComplete,
}) => {

  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    if (getServerTime().format('HHmm') > startTime.format('HHmm'))
      setDaysLeft(0);
    else setDaysLeft(1);
  }, [startTime]);

  const handleComplete = () => {
    if (onComplete !== null) onComplete();
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    if(days == 0 && hours == 0 && minutes == 10 && seconds == 0 && onComplete !== null){
      onComplete();
    }

    return (
      <span>
        {completed ? (
          <span className={styles[endNoticeClass]}>{endNotice}</span>
        ) : (
          <div className={styles['countdown-row']}>
            <div className={styles['time-item']}>
              {zeroPad(hours)}
              <span className={styles['time-name']}>HOURS</span>
            </div>
            <div className={styles['divider']}>:</div>
            <div className={styles['time-item']}>
              {zeroPad(minutes)}
              <span className={styles['time-name']}>MINUTES</span>
            </div>
            <div className={styles['divider']}>:</div>
            <div className={styles['time-item']}>
              {zeroPad(seconds)}
              <span className={styles['time-name']}>SECONDS</span>
            </div>
          </div>
        )}
      </span>
    );
  };

  let numDays = Math.ceil(timeToEnd / (60 * 60 * 24 * 1000)) - daysLeft;

  return (
    <div className={styles['well-timer-container']}>
      {timeToEnd > 86400000 ? (
        <span className={styles['days-left']}>
          {numDays} {numDays === 1 ? ' Day' : ' Days'} Away
        </span>
      ) : (
        <Countdown
          date={Date.now() + timeToEnd}
          renderer={renderer}
          zeroPadTime={2}
          onComplete={handleComplete}
        />
      )}
    </div>
  );
};
