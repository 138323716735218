import { roomConstants } from '_constants';
import { AppDispatch } from '_reducers';

export const roomTimerActions = {
  showTimer,
  hideTimer,
};

function showTimer(settings: any) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: roomConstants.SHOW_ROOM_TIMER, settings: settings });
  };
}

function hideTimer() {
  return (dispatch: AppDispatch) => {
    dispatch({ type: roomConstants.HIDE_ROOM_TIMER });
  };
}
