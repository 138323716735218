import {
  useState,
  useEffect,
  useCallback,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { history } from '_helpers';
import { IUser } from '_types';
import {
  userActions,
  componentActions,
  roomReportingActions,
  adminDumpActions,
} from '_actions';
import { notificationConstants } from '_constants';
import { AnalyticsSendEvent } from '_helpers';
import {
  useWellDispatch,
  useWellSelector,
  useWellApp,
  useHostedSessionsPagination,
} from '_hooks';
import { Profile } from '.';

interface IProfileProvider {
  isLoading: boolean;
  show404: boolean;
  isSelf: boolean;
  selectedUserData: IUser;
  isHost: boolean;
  setSelectedUserData: Dispatch<SetStateAction<IUser>>;
  blockUserCallback: () => void;
  unblockUser: () => void;
  reportUser: () => void;
  setAsAnnouncementLink: (user: string) => void;
  futureSessions: any;
  pastSessions: any;
}

export const ProfileContext = createContext<IProfileProvider>(
  {} as IProfileProvider
);

interface MatchParams {
  identifier: string;
}

export const ProfileProvider = ({
  match,
}: RouteComponentProps<MatchParams>) => {
  const dispatch = useWellDispatch();
  const { userStore } = useWellSelector((state) => state);
  const { getUserInformation } = useWellApp();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [show404, setShow404] = useState<boolean>(false);
  const [isSelf, setIsSelf] = useState<boolean>(false);
  const [selectedUserData, setSelectedUserData] = useState<IUser>({} as IUser);
  const [isHost, setIsHost] = useState(false);

  const { identifier } = match?.params;

  const futureSessions = useHostedSessionsPagination(
    selectedUserData.uuid,
    false,
    6
  );
  const pastSessions = useHostedSessionsPagination(
    selectedUserData.uuid,
    true,
    6
  );

  // checks to see whose profile we are looking at
  // checks to see if this is the own user's profile
  useEffect(() => {
    const checkProfile = async () => {
      setIsLoading(true);

      if (
        history.location.pathname === '/profile' ||
        history.location.pathname === '/profile/' ||
        userStore.userData.uuid === identifier ||
        userStore.userData.username === identifier
      ) {
        setIsSelf(true);
        setSelectedUserData(userStore.userData);
      } else {
        try {
          const info = await getUserInformation(identifier, false);
          if (info && info.uuid) {
            setIsSelf(false);
            setSelectedUserData({ ...info });

            if (
              !(
                (!info.public_private_profile && userStore.loggedIn) ||
                info.public_private_profile
              )
            ) {
              history.push('/login');
            }
          }
        } catch (error) {
          setShow404(true);
        }
      }

      setIsLoading(false);
    };
    checkProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  //check if the user is a host
  useEffect(() => {
    if (
      selectedUserData?.num_sessions_hosted &&
      selectedUserData?.num_sessions_hosted > 0
    )
      setIsHost(true);
    else setIsHost(false);
  }, [selectedUserData?.num_sessions_hosted]);

  const blockUserCallback = useCallback(async () => {
    if (!selectedUserData) return;

    setIsLoading(true);
    const info = await dispatch(
      userActions.getUserInfo(selectedUserData.username)
    );

    if (info) {
      setSelectedUserData(info);
    }
    setIsLoading(false);
  }, [dispatch, selectedUserData]);

  const unblockUser = useCallback(() => {
    dispatch(
      componentActions.openModal({
        title: 'Unrestrict ' + selectedUserData.username + '?',
        body: notificationConstants.USER_UNBLOCK_NOTICE,
        modalStyle: {},
        modalBodyStyle: { textAlign: 'center' },
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        yesText: 'UNRESTRICT',
        noText: 'CANCEL',
        yesClick: async () => {
          setIsLoading(true);
          await dispatch(
            roomReportingActions.blockUser(
              selectedUserData.uuid,
              'false',
              'UNBLOCK'
            )
          );
          AnalyticsSendEvent('users_user_unblock', {
            blocked: selectedUserData.uuid,
          });
          const info: any = await dispatch(
            userActions.getUserInfo(selectedUserData.username)
          );
          if (info) {
            setSelectedUserData(info);
          }
          setIsLoading(false);
        },
        noClick: () => {},
      })
    );
  }, [dispatch, selectedUserData.username, selectedUserData.uuid]);

  const reportUser = useCallback(() => {
    dispatch(
      componentActions.openModal({
        title: 'Report ' + selectedUserData.username + '?',
        body: 'Please enter a reason for reporting this user.',
        noButtonStyle: { background: '#999999' },
        yesButtonStyle: {
          background: 'linear-gradient(to bottom, #87BDEE 0%, #3E94E3 100%)',
        },
        isInput: true,
        yesText: 'REPORT',
        noText: 'CANCEL',
        placeholder: 'Reason...',
        afterText: 'User has been reported.',
        yesClick: (reason: string) => {
          dispatch(
            roomReportingActions.reportUser(selectedUserData.uuid, reason, '')
          );
          return Promise.resolve(1);
        },
        noClick: () => {},
      })
    );
  }, [dispatch, selectedUserData.username, selectedUserData.uuid]);

  const setAsAnnouncementLink = useCallback(
    (user: string) => {
      dispatch(adminDumpActions.setUserAsAnnouncementLink(user));
    },
    [dispatch]
  );

  return (
    <ProfileContext.Provider
      value={{
        isLoading,
        show404,
        isSelf,
        selectedUserData,
        isHost,
        setSelectedUserData,
        blockUserCallback,
        unblockUser,
        reportUser,
        setAsAnnouncementLink,
        futureSessions,
        pastSessions,
      }}
    >
      <Profile />
    </ProfileContext.Provider>
  );
};
