import styles from './FeaturedGroups.module.css';
import { GroupItem, WellButtonLink, WellLoader } from '_components';
import { useFeaturedGroups } from '_hooks';

export const FeaturedGroups = () => {
  const { groupsToShow, isLoadingFeaturedGroups } = useFeaturedGroups();

  return (
    <div className={styles['large-container'] + ' container'}>
      {isLoadingFeaturedGroups ? (
        <div className={styles['loader-div']}>
          <WellLoader heightWidth={200} />
        </div>
      ) : (
        <div className={styles['groups-container']}>
          {groupsToShow.length > 0 && (
            <>
              <div className={styles['groups-row']}>
                {groupsToShow.map((group, groupNum) => (
                  <div className={''} key={'homepage-group-' + groupNum}>
                    <GroupItem group={group} isHomepage={true} searchTerm="" />
                  </div>
                ))}
              </div>
            </>
          )}

          <div className={styles['view-groups-btn-container']}>
            <WellButtonLink
              to={'/wells'}
              className={styles['view-groups-btn']}
              btnText={'VIEW ALL WELLS'}
              type={'well-btn-orange'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
