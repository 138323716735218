import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useChannelStateContext } from 'stream-chat-react';

import { BackArrow, MinimizeIcon } from '_components';
import styles from './ChannelHeader.module.css';

export const ChannelHeader = () => {
  const { channel } = useChannelStateContext();
  const { data, state } = channel;

  const [isMemberListOpen, setIsMemberListOpen] = useState(false);
  const [isTitleOpen, setIsTitleOpen] = useState(false);

  return (
    <div className={styles['container']}>
      <div className={styles['left']}>
        <BackArrow />

        <div className={styles['channel-info']}>
          <div
            className={styles['title-container']}
            onMouseOver={() => setIsTitleOpen(true)}
            onMouseLeave={() => setIsTitleOpen(false)}
          >
            {data?.name && data?.session ? (
              <Link
                to={'/session/' + data?.session}
                className={styles['session-title']}
                key={'session-' + data?.session}
              >
                {data?.name}
              </Link>
            ) : data?.name ? (
              <div className={styles['session-title']}>{data?.name}</div>
            ) : (
              <></>
            )}
          </div>

          {isTitleOpen && data?.name && data?.session && (
            <div className={styles['title-modal-container']}>
              <h4 className={styles['title-modal']}>{data?.name}</h4>
            </div>
          )}

          <div className={styles['member-count-container']}>
            <p
              className={styles['member-count']}
              onMouseOver={() => setIsMemberListOpen(true)}
              onMouseLeave={() => setIsMemberListOpen(false)}
            >
              {data?.member_count} members, {state.watcher_count} online
            </p>

            {isMemberListOpen && (
              <div className={styles['member-list']}>
                <h4 className={styles['member-list-title']}>Members</h4>
                {Object.keys(channel.state.members).map((key) => (
                  <Link
                    to={'/profile/' + channel.state.members[key]?.user?.name}
                    className={styles['member-item']}
                    key={'profile' + channel.state.members[key]?.user?.name}
                  >
                    {channel.state.members[key]?.user?.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles['minimize']}>
        <MinimizeIcon />
      </div>
    </div>
  );
};
