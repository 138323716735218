import { sessionsService } from '_services';
import { sessionsConstants } from '_constants';
import { userSessionsActions, groupActions } from '_actions';
import { addDateFilterKey } from '_helpers';
import { ISession, ESortType, SERVER_ERROR } from '_types';
import { AppDispatch } from '_reducers';

export const sessionsActions = {
  // sessions
  getSession,
  getSessionInfo,
  getSessionInfoBatch,
  getSessionsList,
  joinOrLeaveSession,

  // series
  getSeriesInfo,
  getSeriesInfoBatch,
  getSessionsInSeries,
};

function getSession(session: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await sessionsService.getSessionInfo(session);
      if (response.session) return Promise.resolve(response.session);
      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  };
}

// gets info for a single sessions
function getSessionInfo(session: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await sessionsService.getSessionInfo(session);
      if (response.notice) {
        return Promise.resolve(response.notice);
      } else {
        const groupResponse = await dispatch(
          groupActions.getGroupInfo(response.session.group_uuid)
        );
        if (groupResponse.uuid) response.session.group_info = groupResponse;

        // parse the host marketing data
        if (response?.session?.host_user?.user_marketing_json) {
          response.session.host_user.user_marketing_json = JSON.parse(
            response?.session?.host_user?.user_marketing_json
          );
        }

        dispatch({
          type: sessionsConstants.GET_SESSION_INFO_SUCCESS,
          session: response.session,
        });

        return Promise.resolve(response.session);
      }
    } catch (error) {
      dispatch({ type: sessionsConstants.CLEAR_SESSION_INFO });
      return Promise.reject();
    }
  };
}

// gets information about a list of sessions
function getSessionInfoBatch(sessions: string[]) {
  return async (dispatch: AppDispatch) => {
    try {
      let promises = [];

      var len = sessions.length;
      while (len--) {
        promises.push(sessionsService.getSessionInfo(sessions[len]));
      }

      const response = await Promise.all(promises);
      let returnArr: ISession[] = [];
      if (response && response.length > 0) {
        response.map((item) => returnArr.push(item.session));
      }

      return Promise.resolve(returnArr);
    } catch (error) {
      dispatch({ type: sessionsConstants.CLEAR_SESSION_INFO });
      return Promise.resolve(-1);
    }
  };
}

// gets information about a series
// currently returns the first session in the series
function getSeriesInfo(uuid: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await sessionsService.getSessionsInSeries(
        uuid,
        1,
        0,
        'ASC'
      );
      if (response.notice) {
        return Promise.resolve(0);
      } else if (response.sessions[0]) {
        //need to get users which is an extra call
        const sessionInfo = await dispatch(
          getSessionInfo(response.sessions[0].uuid) as any
        );
        return Promise.resolve(sessionInfo);
      } else return Promise.resolve(0);
    } catch (error) {
      dispatch({ type: sessionsConstants.CLEAR_SESSION_INFO });
      return Promise.resolve(0);
    }
  };
}

// gets first session about a list of series
function getSeriesInfoBatch(series: string[]) {
  return async (dispatch: AppDispatch) => {
    try {
      let promises = [];

      var len = series.length;
      while (len--) {
        promises.push(
          sessionsService.getSessionsInSeries(series[len], 1, 0, 'ASC')
        );
      }

      const response = await Promise.all(promises);
      let returnArr: ISession[] = [];
      if (response && response.length > 0) {
        response.map((item) => {
          if (item.sessions.length > 0) return returnArr.push(item.sessions[0]);
          return null;
        });
      }

      return Promise.resolve(returnArr);
    } catch (error) {
      return Promise.reject();
    }
  };
}

// gets list of sessions to display to the user
function getSessionsList(
  searchQuery: string = '',
  limit: number,
  offset: number,
  startTime: string,
  endTime: string,
  group: string,
  sortType: ESortType,
  paidOnly = 'false',
  excludeFullSessions: boolean
) {
  return async () => {
    try {
      const response = await sessionsService.getSessionsList(
        searchQuery,
        limit,
        offset,
        startTime,
        endTime,
        group,
        sortType,
        paidOnly,
        excludeFullSessions
      );

      if (response.sessions) {
        for (var i = 0; i < response.sessions.length; i++) {
          addDateFilterKey(response.sessions[i]);
        }

        return Promise.resolve(response.sessions);
      }

      return Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

// register or unregister from a session
function joinOrLeaveSession(session: string, isJoin: boolean) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await sessionsService.joinOrLeaveSession(
        session,
        isJoin
      );
      if (response.notice) {
        return Promise.reject(response.notice);
      } else {
        await dispatch(userSessionsActions.checkForUpcomingSession());
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(SERVER_ERROR);
    }
  };
}

// get's all sessions of series
function getSessionsInSeries(uuid: string) {
  return async () => {
    try {
      const response = await sessionsService.getSessionsInSeries(
        uuid,
        999,
        0,
        'ASC'
      );

      if (response.sessions) return Promise.resolve(response.sessions);

      return Promise.resolve([]);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
