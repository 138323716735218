import { userService } from '_services';
import { componentsConstants, userConstants } from '_constants';
import { Dispatch } from 'redux';
import { AppDispatch } from '_reducers';

export const userActions = {
  getProfileInfo, // get my profile information
  updateProfile, // update my own profile information
  uploadProfilePic, // update my profile pic

  getUserInfo, // get some users information with uuid or username
  searchForUser, // searches for a user

  getSubscription,
};

function getProfileInfo(isLoginTest: boolean = false): any {
  return async (dispatch: Dispatch) => {
    try {
      const response = await userService.getProfileInfo(isLoginTest);
      if (response && response.user) {
        // parse user data
        if (response.user.user_marketing_json)
          response.user.user_marketing_json = JSON.parse(
            response.user.user_marketing_json
          );

        dispatch({
          type: userConstants.SELF_PROFILE_SUCCESS,
          userData: response.user,
        });

        // handle special notices
        if (response.user.special_notice) {
          if (response.user.special_notice === 'SHOW_TRAINING_LINK_MODEL')
            dispatch({
              type: componentsConstants.TOGGLE_NEW_MODAL,
              isHostTrainingModalOpen: true,
            });

          if (response.user.special_notice === 'SHOW_POST_ONE_SESSION_MODAL')
            dispatch({
              type: componentsConstants.TOGGLE_NEW_MODAL,
              isSessionVideoNoticeModalOpen: {
                isOpen: true,
                src: 'https://www.youtube.com/embed/YLsx7sZNVz4?autoplay=1',
                btnText: 'JOIN AN UPCOMING SESSION',
                btnHref: '/sessions',
              },
            });

          if (response.user.special_notice === 'SHOW_POST_THREE_SESSIONS_MODAL')
            dispatch({
              type: componentsConstants.TOGGLE_NEW_MODAL,
              isSessionVideoNoticeModalOpen: {
                isOpen: true,
                src: 'https://www.youtube.com/embed/CPRwl91qpfQ?autoplay=1',
                btnText: 'VIEW SAFETY POLICY',
                btnHref: '/safety-policy',
              },
            });

          if (response.user.special_notice === 'SHOW_POST_FIVE_SESSIONS_MODAL')
            dispatch({
              type: componentsConstants.TOGGLE_NEW_MODAL,
              isSessionVideoNoticeModalOpen: {
                isOpen: true,
                src: 'https://www.youtube.com/embed/dz-I6PrKq6w?autoplay=1',
                btnText: 'LEARN ABOUT HOSTING',
                btnHref: '/host',
              },
            });

          if (response.user.special_notice === 'SHOW_RECEIVED_FREE_MONTH_MODAL')
            dispatch({
              type: componentsConstants.TOGGLE_NEW_MODAL,
              isReceivedFreeMonthModalOpen: true,
            });
        }

        return Promise.resolve(response.user);
      }
      dispatch({ type: userConstants.SELF_PROFILE_FAILURE });
      return Promise.resolve(0);
    } catch (error) {
      dispatch({ type: userConstants.SELF_PROFILE_FAILURE });
      return Promise.resolve(0);
    }
  };
}

function updateProfile(formData: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await userService.updateProfile(formData);
      if (response.notice) {
        return Promise.resolve(response.notice.msg);
      } else {
        await dispatch(getProfileInfo(false));
        return Promise.resolve(1);
      }
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function getSubscription() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await userService.getSubscription();
      if (response.subscription) {
        dispatch({
          type: userConstants.GET_USER_SUBSCRIPTION,
          userSubscription: response.subscription,
        });
        return Promise.resolve(response.subscription);
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  };
}

// updates a users profile pic
function uploadProfilePic(picData: any) {
  return async (dispatch: AppDispatch) => {
    try {
      if (!picData) throw new Error('');
      await userService.uploadProfilePic(picData);
      const userInfo = await dispatch(getProfileInfo(false));
      return Promise.resolve(userInfo);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}

function getUserInfo(uuid: string) {
  return async () => {
    try {
      const response = await userService.getUserInfo(uuid);
      if (response.user) {
        if (response.user.user_marketing_json) {
          response.user.user_marketing_json = JSON.parse(
            response.user.user_marketing_json
          );
        }
        return Promise.resolve(response.user);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };
}

function searchForUser(group: string, username: string) {
  return async () => {
    try {
      const response = await userService.searchForUser(group, username);
      if (response.users) return Promise.resolve(response.users);
      return Promise.resolve(0);
    } catch (error) {
      return Promise.resolve(0);
    }
  };
}
