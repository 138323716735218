import { useState, useCallback } from 'react';
import styles from './ProfileInputItem.module.css';
import tagStyles from './ProfileTags.module.css';
import { WithContext as ReactTags, Tag } from 'react-tag-input';
import PencilIcon from '_images/pencil.png';
import SaveIcon from '_images/save-icon.png';
import { useWellDispatch, useWellSelector } from '_hooks';
import { userActions } from '_actions';

const KeyCodes = {
  enter: 13,
};

const delimiters = [KeyCodes.enter];

export const ProfileTagsSelf = ({ starterTags }: any) => {
  const dispatch = useWellDispatch();

  const { userData } = useWellSelector((state) => state.userStore);

  const [error, setError] = useState('');

  const [isEditing, setIsEditing] = useState(
    !starterTags || starterTags.length === 0 ? true : false
  );

  const [tags, setTags] = useState<Tag[]>(
    !starterTags || starterTags.length === 0
      ? []
      : starterTags.map((item: string, index: string) => {
          return {
            id: item,
            text: item,
          };
        })
  );

  const handleDelete = (i: any) => {
    setTags(tags.filter((tag: Tag, index: number) => index !== i));
  };

  const handleAddition = async (tag: any) => {
    if (tags.length > 2) {
      setError('Max 3 credentials allowed');
      return;
    }

    setError('');
    setTags([...tags, tag]);

    // update the user marketing dump
    await dispatch(
      userActions.updateProfile({
        user_marketing: JSON.stringify({
          ...userData.user_marketing_json,
          profile_info: {
            ...userData.user_marketing_json.profile_info,
            credentials: [...tags, tag].map((item) => item.text),
          },
        }),
      })
    );
  };

  const switchEdit = () => setIsEditing(!isEditing);

  const handleCancel = useCallback(async () => {
    await dispatch(
      userActions.updateProfile({
        user_marketing: JSON.stringify({
          ...userData.user_marketing_json,
          profile_info: {
            ...userData.user_marketing_json.profile_info,
            credentials: tags.map((item) => item.text),
          },
        }),
      })
    );
    setIsEditing(false);
  }, [dispatch, tags, userData.user_marketing_json]);

  return (
    <div className={styles['about']}>
      <h3 className={styles['about-title']}>Credentials</h3>

      <div className={styles['about-row']}>
        {tags.length > 0 && (
          <div className={styles['options-container']}>
            {!isEditing ? (
              <img
                src={PencilIcon}
                className={styles['pencil-icon']}
                onClick={switchEdit}
                alt={'Pencil'}
              />
            ) : (
              <>
                <img
                  src={SaveIcon}
                  className={styles['cancel-icon']}
                  onClick={handleCancel}
                  alt={'Cancel'}
                />
              </>
            )}
          </div>
        )}

        {!isEditing && tags.length > 0 ? (
          <div className={tagStyles['tags-container']}>
            {tags.map((item: Tag) => (
              <TagItem text={item.text} />
            ))}
          </div>
        ) : (
          <>
            <div className={tagStyles['container']}>
              <ReactTags
                tags={tags}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="bottom"
                allowDragDrop={false}
                autocomplete
                placeholder={'Add up to 3 credentials'}
                maxLength={30}
              />
            </div>

            {error ? <span className={styles['error']}>{error}</span> : <></>}
          </>
        )}
      </div>
    </div>
  );
};

export const ProfileTags = ({ starterTags }: any) => {
  const [tags, setTags] = useState<Tag[]>(
    !starterTags || starterTags.length === 0
      ? []
      : starterTags.map((item: string, index: string) => {
          return {
            id: item,
            text: item,
          };
        })
  );

  return (
    <div className={styles['about']}>
      <h3 className={styles['about-title']}>Credentials</h3>
      <div className={tagStyles['tags-container']}>
        {tags.map((item: Tag) => (
          <TagItem text={item.text} />
        ))}
      </div>
    </div>
  );
};

export const TagItem = ({ text }: { text: string }) => {
  return <div className={tagStyles['tag-item']}>{text}</div>;
};
