
import styles from './PrevCardItem.module.css';
import CreditCard from '_images/credit-card.png';


interface IPrevCardItem{
  last4: string;
  expire: string;
}

export const PrevCardItem = ({ last4, expire }: IPrevCardItem) => {

  // const [isSelected, setIsSelected] = useState();

  return (
    <div className={styles['card-item']}>
      <input type="radio" checked={true} onChange={()=>{}}/>
      <img src={CreditCard} className={styles['credit-card-image']} alt={'Credit Card'} />
      <div className={styles['card-item-inner']}>
        <div className={styles['top']}>Ending in</div>
        <div className={styles['bottom']}>{last4}</div>
      </div>
      <div className={styles['card-item-inner']}>
        <div className={styles['top']}>Expires on</div>
        <div className={styles['bottom']}>{expire}</div>
      </div>
    </div>
  )

}