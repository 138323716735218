import { useSelector } from 'react-redux';
import styles from './GroupListHeader.module.css';
import { WellButtonLink } from '_components';

export const GroupListHeader = () => {
  const { userStore } = useSelector((state) => state);
  const { loggedIn, userData } = userStore;


  return (
    <div className={styles['group-list-header-parent']}>
      <div className={styles['group-list-header']}>
        <h1 className={styles['title']}>Join one of our Well communities</h1>
        <p className={styles['subtitle']}>
          Connect with peers who are working through and overcoming similar
          challenges. At ShareWell, we talk about it all.
        </p>

        <div className={styles['group-search-container']}>
          {loggedIn && userData.is_admin && (
          <WellButtonLink
            type={'well-btn-orange'}
            className={styles['create-group-btn']}
            btnText={'CREATE A WELL'}
            to={'/well/create'}
          />
        )}
        </div>

        <svg
          className={styles['wave-svg']}
          viewBox='0 0 500 150'
          width={'100%'}
          height={'100'}
          preserveAspectRatio='none'
        >
          <path d='M0.00,49.98 C250.00,133.70 240.97,14.30 500.00,49.98 L500.00,150.00 L0.00,150.00 Z'></path>
        </svg>
      </div>

    </div>
  );
};
