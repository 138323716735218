import { userConstants } from '_constants';
import { AnyAction } from 'redux';
import { IAccount, IUserSubscription } from '_types';

interface IDefaultState {
  userData: IAccount;
  loggedIn: boolean;
  isAdmin: boolean;
}

const defaultState: IDefaultState = {
  userData: {} as IAccount,
  loggedIn: false,
  isAdmin: false,
};

export function userStore(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case userConstants.SELF_PROFILE_SUCCESS:
      return {
        ...state,
        userData: action.userData as IAccount,
        loggedIn: true,
        isAdmin: action.userData.is_admin,
      };

    case userConstants.GET_USER_SUBSCRIPTION:
        return {
          ...state,
          userData: {
            ...state.userData,
            subscription_info: action.userSubscription,
          }
        };      

    case userConstants.HANDLE_LOGOUT:
    case userConstants.SELF_PROFILE_FAILURE:
      return {
        userData: {} as IAccount,
        loggedIn: false,
        isAdmin: false,
      };

    default:
      return state;
  }
}
