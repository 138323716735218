import { useProfile, useWellSelector } from '_hooks';
import {
  ProfileItem,
  ProfileInputItemHasNone,
  ProfileInputItemSelf,
  WellButton,
  WellMeatBalls,
  HostBadges,
  UserImage,
  ProfileTagsSelf,
  ProfileTags,
  BlockUserModal,
} from '_components';
import styles from './AboutUser.module.css';
import { EHostLevel } from '_types';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';

export const AboutUser = () => {
  const { userStore } = useWellSelector((state) => state);

  const [blockModalIsOpen, setBlockModalIsOpen] = useState(false);

  const handleCloseModal = useCallback(() => {
    setBlockModalIsOpen(false);
  }, []);

  const {
    selectedUserData,
    isSelf,
    isHost,
    blockUserCallback,
    unblockUser,
    reportUser,
    setAsAnnouncementLink,
  } = useProfile();

  const {
    username,
    about_me,
    user_marketing_json,
    num_session_users_met,
    num_sessions_hosted,
    testimonials_pct_thumbs_up,
    host_level,
    blocked,
  } = selectedUserData;

  const { profile_info } = user_marketing_json;

  return (
    <>
      <div className={styles['container']}>
        {!isSelf && (
          <WellMeatBalls
            isLeft={false}
            dropDownKey={999}
            dropDownItems={[
              {
                text: !selectedUserData.can_block
                  ? 'Unrestrict Member'
                  : 'Restrict Member',
                onClick: !selectedUserData.can_block
                  ? unblockUser
                  : () => setBlockModalIsOpen(true),
                disable: false,
              },
              { text: 'Report Member', onClick: reportUser, disable: false },
              userStore.isAdmin
                ? {
                    text: 'Set as Announ',
                    onClick: () => {
                      setAsAnnouncementLink(selectedUserData.username);
                    },
                    disable: false,
                  }
                : {},
            ]}
            style={{ top: '1px', right: '-15px', color: '#70B5F4' }}
          />
        )}

        <div className={styles['top-items']}>
          <UserImage
            userData={selectedUserData}
            isSelf={isSelf}
            className={styles['user-image-container']}
          />

          <h3 className={styles['username']}>{username}</h3>
          {!blocked && (
            <h4 className={styles['member-since']}>
              Member for {selectedUserData.age}
            </h4>
          )}

          {isSelf && (
            <div className={styles['earn-credit-container']}>
              <Link to={'/gift'} className={styles['earn-credit']}>
                Gift Peer Support
              </Link>
            </div>
          )}

          {!blocked ? (
            <div className={styles['my-badges']}>
              <HostBadges selectedUserData={selectedUserData} />
            </div>
          ) : (
            <></>
          )}
        </div>

        {isHost && (
          <div className={styles['top-stats-row']}>
            <TopStat
              colorFill={'#CCEBFF'}
              color={'#5FA2CD'}
              number={num_session_users_met}
              string={'Peers Supported'}
              isPercent={false}
            />
            <TopStat
              colorFill={'#FFF1D5'}
              color={'#F9B731'}
              number={num_sessions_hosted}
              string={'Sessions Hosted'}
              isPercent={false}
            />

            {host_level > EHostLevel.HOST_LEVEL_0 && (
              <TopStat
                colorFill={'#D5FFEF'}
                color={'#95DAC0'}
                number={
                  testimonials_pct_thumbs_up ? testimonials_pct_thumbs_up : 100
                }
                string={'Positive Reviews'}
                isPercent={true}
              />
            )}
          </div>
        )}

        <div className={styles['input-items']}>
          {blocked ? (
            <></>
          ) : isSelf && profile_info === undefined && about_me === '' ? (
            <HasNothing />
          ) : !isSelf && profile_info === undefined && about_me === '' ? (
            <ProfileInputItemHasNone />
          ) : isSelf ? (
            <>
              <ProfileInputItemSelf
                startValue={about_me}
                keyName={'about_me'}
                title={'About Me'}
                placeholder={'Tell the community a little about yourself...'}
              />
              <ProfileInputItemSelf
                startValue={
                  profile_info?.brought_me ? profile_info?.brought_me : ''
                }
                keyName={'brought_me'}
                title={'What Brought Me To ShareWell'}
                placeholder={
                  'Let others know what brought you to this community...'
                }
              />
              <ProfileInputItemSelf
                startValue={
                  profile_info?.hope_to_gain ? profile_info?.hope_to_gain : ''
                }
                keyName={'hope_to_gain'}
                title={'What I Hope to Contribute and Gain'}
                placeholder={
                  'Let others know what do you hope to gain from this community...'
                }
              />

              {host_level > EHostLevel.HOST_LEVEL_0 && (
                <>
                  <ProfileTagsSelf starterTags={profile_info?.credentials} />
                  <ProfileInputItemSelf
                    startValue={
                      profile_info?.website ? profile_info?.website : ''
                    }
                    keyName={'website'}
                    title={'Website'}
                    error={'Please enter a valid url'}
                    placeholder={'Personal website...'}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {about_me && <ProfileItem value={about_me} title={'About Me'} />}
              {profile_info?.brought_me && (
                <ProfileItem
                  value={
                    profile_info?.brought_me ? profile_info?.brought_me : ''
                  }
                  title={'What Brought Me To ShareWell'}
                />
              )}
              {profile_info?.hope_to_gain && (
                <ProfileItem
                  value={
                    profile_info?.hope_to_gain ? profile_info?.hope_to_gain : ''
                  }
                  title={'What I Hope to Contribute and Gain'}
                />
              )}

              {profile_info?.credentials &&
                host_level > EHostLevel.HOST_LEVEL_0 && (
                  <ProfileTags starterTags={profile_info?.credentials} />
                )}

              {profile_info?.website &&
                host_level > EHostLevel.HOST_LEVEL_0 && (
                  <ProfileItem
                    value={profile_info?.website ? profile_info?.website : ''}
                    title={'Website'}
                    isLink={true}
                  />
                )}
            </>
          )}
        </div>
      </div>

      {blockModalIsOpen && (
        <BlockUserModal
          blockiee={selectedUserData}
          callback={blockUserCallback}
          closeModal={() => setBlockModalIsOpen(false)}
        />
      )}
    </>
  );
};

export const TopStat = ({
  colorFill,
  color,
  number,
  string,
  isPercent,
  isSmall,
}: any) => {
  return (
    <div
      className={
        styles['stat-item'] + ' ' + (isSmall ? styles['stat-item-small'] : '')
      }
      style={{ backgroundColor: colorFill }}
    >
      <h4 className={styles['state-item-number']} style={{ color: color }}>
        {number}
        {isPercent && <>%</>}
      </h4>
      <div className={styles['stat-item-text']}>{string}</div>
    </div>
  );
};

const HasNothing = () => {
  const { selectedUserData, setSelectedUserData } = useProfile();

  const handleClick = () => {
    const { user_marketing_json } = selectedUserData;

    setSelectedUserData((prev: any) => {
      return {
        ...prev,
        user_marketing_json: {
          ...user_marketing_json,
          profile_info: {},
        },
      };
    });
  };

  return (
    <div className={styles['has-nothing']}>
      <h4 className={styles['nothing-top-text']}>
        Complete your profile to help the community get to know you!
      </h4>

      <h4 className={styles['nothing-bottom-text']}>
        Start by sharing a little more about yourself, what brought you to
        ShareWell and what you hope to gain by being here.
      </h4>

      <WellButton
        btnText={'BUILD YOUR PROFILE'}
        btnOnClick={handleClick}
        type={'well-btn-orange'}
        className={styles['has-nothing-button']}
      />
    </div>
  );
};
