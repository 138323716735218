import { SetStateAction, useContext } from 'react';
import { WellAppContext } from 'WellAppProvider';
import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
  history,
  AnalyticsIdentify,
  sentryInit,
  AnalyticsSendEvent,
} from '_helpers';
import {
  userActions,
  generalActions,
  componentActions,
  userSessionsActions,
} from '_actions';

import ImgixClient from '@imgix/js-core';
import { AppDispatch, RootState } from '_reducers';

// use global functions
export const useWellApp = () => {
  const context = useContext(WellAppContext);
  if (context === undefined) {
    throw new Error('useWellApp must be used within a WellAppContext');
  }
  return context;
};

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
//
// Initial logic that must be completed on initial load
// General useEffects that are not part of anything in particular
//
//
///////////////////////////////////////////////////////////////
export const useWellAppLogic = (
  setInitialLoad: {
    (value: SetStateAction<boolean>): void;
  },
  setImgIxClient: any
) => {
  const dispatch = useDispatch();
  const { generalStore, componentsStore, userStore } = useSelector(
    (state: RootState) => state
  );

  useEffect(() => {
    //if server down hit sessions instead
    if (history.location.pathname === '/server-down') history.push('/wells');

    //monitor changes in history
    const unlisten = history.listen((targetLocation) => {
      window.scrollTo(0, 0); //whenever change route scroll to top
      //always close modal on changes
      if (componentsStore.modalData.isOpen)
        dispatch(componentActions.closeModal());
    });

    return () => {
      unlisten();
    };
  }, [componentsStore.modalData.isOpen, userStore.loggedIn, dispatch]);

  useEffect(() => {
    // I have to do this because of the zoom sdk. More of a bandage than a fix
    document.body.style.height = 'auto'; //set the body css to height auto so scroll events work

    sentryInit(); // init sentry

    // mobile vh options to use. Takes into account browser options at the top and bottom
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const startInitCalls = async () => {
      // API Calls we need to run on first load
      const [configResponse, profileResponse] = await Promise.all([
        dispatch(generalActions.setServerConfig()),
        dispatch(userActions.getProfileInfo(true)),
      ]);

      if (profileResponse !== 0) {
        dispatch(userSessionsActions.checkForUpcomingSession());
        // mark the user has loaded the page
        AnalyticsSendEvent('user_page_load', {
          user: profileResponse.uuid,
        });
      }

      setInitialLoad(false);
    };
    startInitCalls();
  }, [dispatch, setInitialLoad]);

  // sets the imgix client to be used throughout the site
  useEffect(() => {
    if (!generalStore.imgix_domain || !generalStore.imgix_token) return;

    try {
      const client = new ImgixClient({
        domain: generalStore.imgix_domain,
        secureURLToken: generalStore.imgix_token,
      });
      setImgIxClient(client);
    } catch (err) {}
  }, [generalStore.imgix_domain, generalStore.imgix_token, setImgIxClient]);

  // whenever a user logs in mark them for mixpanel
  useEffect(() => {
    if (userStore.userData && userStore.userData.uuid) {
      AnalyticsIdentify(userStore.userData.uuid, {
        firstName: userStore.userData.name,
        email: userStore.userData.email,
      });
    }
  }, [userStore.userData]);

  return null;
};

// https://redux.js.org/usage/usage-with-typescript
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useWellDispatch = () => useDispatch<AppDispatch>();
export const useWellSelector: TypedUseSelectorHook<RootState> = useSelector;
