import { useState, useEffect } from 'react';
import { useWellSelector } from '_hooks';

export const useLoginRequiredModal = () => {
  const { userStore } = useWellSelector((state) => state);

  const [showLoginRequiredModal, setShowLoginRequiredModal] = useState(false);

  useEffect(() => {
    if (!userStore.loggedIn) setShowLoginRequiredModal(true);
    else setShowLoginRequiredModal(false);
  }, [userStore.loggedIn]);

  return {
    showLoginRequiredModal,
  };
};
