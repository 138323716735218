import {
  getServerTime,
  getSessionEndTime,
  getSessionStartTime,
} from '_helpers';
import { IUser } from '_types';

export function addDateFilterKey(session: any) {
  let startTime = getSessionStartTime(session);
  let endTime = getSessionEndTime(session);

  session.date_filters = { thisMonth: true }; //everything is always this month

  if (startTime < getServerTime()) session.date_filters.inSession = true;

  //if within the next 60 minutes then it is upcoming
  if (startTime.add(60, 'minutes') < getServerTime())
    session.date_filters.upcoming = true;

  if (getServerTime().format('YYYY-MM-DD') === startTime.format('YYYY-MM-DD'))
    session.date_filters.today = true;

  if (
    getServerTime().add(1, 'day').format('YYYY-MM-DD') ===
    startTime.format('YYYY-MM-DD')
  )
    session.date_filters.tomorrow = true;

  if (endTime.isBetween(getServerTime(), getServerTime().add(6, 'days')))
    session.date_filters.thisWeek = true;

  if (
    endTime.isBetween(
      getServerTime().add(7, 'days'),
      getServerTime().add(13, 'days')
    )
  )
    session.date_filters.nextWeek = true;

  return session;
}

// creating object of uuid -> username
export function userListToUuidUsernameMapping(userList: IUser[]) {
  return userList.reduce(
    (a: any, v: any) => ({ ...a, [v.uuid]: v.username }),
    {}
  );
}
