import React, { useState, useRef } from 'react';
import styles from './WellMeatBallsRedux.module.css';
import { useClickOutside } from '_hooks';
import { TDropDownItemOrEmpty, IDropDownItem } from '_types';


interface IWellMeatBallsRedux {
  className: string; // outside class name
  dropDownKey: string;
  dropDownItems: TDropDownItemOrEmpty[] | any;
  text?: string;
}

export const WellMeatBallsRedux = ({ className, dropDownItems, dropDownKey, text }: IWellMeatBallsRedux) => {
  const [isOpen, setIsOpen] = useState(false);


  const flipIsOpen = () => {
    setIsOpen(!isOpen);
  }

  const closeDropDown = () => {
    setIsOpen(false);
  }

  const dropdownRef = useRef<any>();
  useClickOutside(dropdownRef, closeDropDown);


  return (
    <div
      className={styles['well-meatballs-container'] + ' ' + className}
      onClick={flipIsOpen}
    >
      {text ?
        <div className={styles['text']}>{text}</div>
        :
        <div className={styles['meatballs']}>•••</div>
      }

      {isOpen &&
        <div
          className={styles['well-meatballs-dropdown']}
          ref={dropdownRef}
        >
          {dropDownItems.map((item: IDropDownItem, index: number) => {
            if (!item || !item.text)
              return (
                <React.Fragment
                  key={dropDownKey + '-' + index}
                ></React.Fragment>
              );
            return (
              <div
                key={dropDownKey + '-' + index}
                className={
                  styles['well-meatballs-option'] +
                  ' ' +
                  (item?.disable ? styles['disabled-option'] : '')
                }
                onClick={() => {
                  item?.onClick();
                  flipIsOpen();
                }}
              >
                {item?.text}
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};
