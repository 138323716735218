import { useState, useCallback } from 'react';
import { useForum, useWellDispatch } from '_hooks';
import { groupForumActions } from '_actions';
import { AnalyticsSendEvent } from '_helpers';
import { generalConstants } from '_constants';
import { TUserMapping } from '_types';

export const useStatusUpdateForm = () => {
  const dispatch = useWellDispatch();
  const { group } = useForum();

  const [postText, setPostText] = useState('');
  const [taggedUsers, setTaggedUsers] = useState<TUserMapping>({}); // list of possibly tagged users
  const [isLoading, setIsLoading] = useState(false);

  // submits the activity text, creating a uuid
  const submitActivityText = useCallback(
    async (postText) => {
      const response = await dispatch(
        groupForumActions.postCreate(
          group === generalConstants.SPECIAL_UUID
            ? process.env.REACT_APP_WELCOME_GROUP_UUID
            : group,
          postText,
          null
        )
      );

      if (!response || !response.uuid) return false;

      AnalyticsSendEvent('group_forum_activity_create', {
        group: group,
        post: response.uuid,
      });

      return response;
    },
    [dispatch, group]
  );

  return {
    postText,
    setPostText,
    taggedUsers,
    setTaggedUsers,
    isLoading,
    setIsLoading,
    submitActivityText,
  };
};
