import styles from './WellLoader.module.css';

export const WellLoader = (props) => {
  let heightWidth = 40;
  if (props.heightWidth) heightWidth = props.heightWidth;
  let positionType = styles['absolute'];
  if (props.position) positionType = styles[props.position];

  return (
    <div
      className={
        styles['loader-parent'] +
        ' ' +
        positionType +
        ' ' +
        (props.negativeMargin ? styles['negative-margin'] : '')
      }
      style={{ height: heightWidth, width: heightWidth }}
    >
      <span
        className={styles['loader-child-1']}
        style={{ height: heightWidth / 2, width: heightWidth / 2 }}
      ></span>
      <span
        className={styles['loader-child-2']}
        style={{ height: heightWidth / 3, width: heightWidth / 3 }}
      ></span>
      <span
        className={styles['loader-child-3']}
        style={{ height: heightWidth / 6, width: heightWidth / 6 }}
      ></span>
    </div>
  );
};
