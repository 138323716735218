///////////////////////////////////////////////////////////////
//
// General Functions that are used throughout the application
// A user is allowed to register, unregister, or enter a session from anywhere on the site
//
//
///////////////////////////////////////////////////////////////
import { useState, createContext, useCallback } from 'react';
import {
  useWellAppLogic,
  useWellSelector,
  useWellDispatch,
  useSessionRegister,
} from '_hooks';
import { IKnownUsers } from '_types';

import {
  userActions,
  componentActions,
  roomMessagingActions,
  roomActions,
} from '_actions';
import {
  history,
  isFacebookApp,
  isInstagramApp,
  checkSessionHasStarted,
  AnalyticsSendEvent,
} from '_helpers';
import { componentsConstants, notificationConstants } from '_constants';
import ImgixClient from '@imgix/js-core';
import { ISession } from '_types';

interface IWellAppContext {
  initialLoad: boolean; //whether we are still loading
  setInitialLoad: (initialLoad: boolean) => void; //set whether we are still loading
  getUserInformation: (uuid: string, skipErrors: boolean) => Promise<any>; //get user information & store so no need to get later
  imgIxClient: ImgixClient | null;

  // session entering & registration
  enterSession: (uuid: string) => Promise<any>; //enter the session from anywhere
  registerForSession: (
    session: ISession,
    setIsLoading: (loading: boolean) => void,
    updateCallback: () => void
  ) => void;
  unregisterFromSession: (
    session: ISession,
    setIsLoading: (loading: boolean) => void,
    updateCallback: () => void
  ) => Promise<void>;
}

export const WellAppContext = createContext<IWellAppContext>(
  {} as IWellAppContext
);

export const WellAppProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const dispatch = useWellDispatch();
  const { userData, isAdmin } = useWellSelector((state) => state.userStore);

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [knownUsers, setKnownUsers] = useState<IKnownUsers>({}); //list of known users
  const [imgIxClient, setImgIxClient] = useState<ImgixClient | null>(null);

  useWellAppLogic(setInitialLoad, setImgIxClient); //logic that needs to be handled on all loads

  // allow user to register, unregister from anywhere on the site
  const { registerForSession, unregisterFromSession } = useSessionRegister();

  /**
   * Handles getting user information other than the logged-in user
   * If we already have the data stored than use that otherwise pull form API
   * TypeScript note: https://stackoverflow.com/questions/57086672/element-implicitly-has-an-any-type-because-expression-of-type-string-cant-b
   * @param  uuid {string} - the uuid of the user we are looking at
   * @param  skipErrors {boolean} - should this always resolve
   * @return Promise {obj} - the user information
   */
  const getUserInformation = useCallback(
    async (uuid: string, skipErrors: boolean) => {
      if (!uuid) return false;
      if (knownUsers && knownUsers[uuid])
        return Promise.resolve(knownUsers[uuid]);
      //need to grab the user
      const response = await dispatch(userActions.getUserInfo(uuid));
      if (response) {
        setKnownUsers((prevState: IKnownUsers) => {
          let newItem: IKnownUsers = {};
          newItem[uuid] = response;
          return { ...prevState, ...newItem };
        });
        return Promise.resolve(response);
      }

      if (!skipErrors) return Promise.reject(response);
    },
    [dispatch, knownUsers]
  );

  // enters a session from anywhere on the site
  const enterSession = useCallback(
    async (uuid: string) => {
      if (isFacebookApp() || isInstagramApp()) {
        dispatch(
          componentActions.openModal({
            title: 'Oops? We noticed you are using an in-app browser.',
            body: notificationConstants.BROWSER_UNSUPPORTED_NOTICE,
          })
        );
        return Promise.resolve(0);
      }

      // test if user has email. If not call profile and try to re-enter in case they have confirmed on a different device
      if (!userData.email) {
        const res = await dispatch(userActions.getProfileInfo(false));
        if (!res.email) {
          dispatch(
            componentActions.openModal({
              title: 'Please verify your email to enter this session.',
            })
          );
          return;
        }
      }

      dispatch(roomMessagingActions.clearHostSignal()); // clear signals

      const session = await dispatch(roomActions.setSessionInfo(uuid)); // set the session info

      if (!checkSessionHasStarted(session, isAdmin)) return Promise.resolve(0); // if session has started user can not enter

      // we allow external sessions for private wells
      if (session.type === 'EXTERNAL') {
        const response = await dispatch(roomActions.enterRoom(session.uuid, 0));

        if (!response) return Promise.resolve(0);
        // load modal in case can't open in tab
        dispatch({
          type: componentsConstants.TOGGLE_NEW_MODAL,
          isExternalSessionModalOpen: response.provider_identifier,
        });

        return Promise.resolve(response);
      }

      AnalyticsSendEvent('session_enter', {
        session: session.uuid,
        user: userData.uuid,
        group: session.group_uuid,
      });

      history.push('/room'); // always go to the room which now handles preview

      return Promise.resolve(1);
    },
    [dispatch, userData.email, userData.uuid, isAdmin]
  );

  return (
    <WellAppContext.Provider
      value={{
        initialLoad,
        setInitialLoad,
        getUserInformation,
        enterSession,
        imgIxClient,

        // session registration
        registerForSession,
        unregisterFromSession,
      }}
    >
      <>{children}</>
    </WellAppContext.Provider>
  );
};
