import { WellInputWrapper, WellSwitch } from '_components';
import styles from './InputGrouping.module.css';
import { useSessionCreateOrEdit } from '_hooks';

export const InputGroupingExternalUrl = () => {
  const {
    data,
    errors,
    handleChange,
    inputRefs,
    isExternal,
    setIsExternal,
    editSession,
  } = useSessionCreateOrEdit();

  return (
    <section className={styles['input-grouping']}>
      <h2>Is this session on Zoom?</h2>
      <p>
        Sessions from this well can be held on zoom. Please provide a valid zoom
        link if you would like to use this feature.
      </p>

      {!editSession?.uuid &&
        <WellSwitch
          isOn={isExternal}
          handleToggle={() => {
            setIsExternal(!isExternal);
          }}
          label={'Hold Session on Zoom'}
        />
      }

      {isExternal && (
        <div className={styles['form-container']}>
          <WellInputWrapper
            error={errors.externalLink}
            inputContainerClassName={styles['input-container']}
            errorRef={inputRefs.externalLink}
            label={'External Link'}
          >
            <input
              value={data.externalLink.value}
              onChange={handleChange}
              name={'externalLink'}
              placeholder={'Zoom or Google Meets URL'}
              maxLength={500}
            />
          </WellInputWrapper>
        </div>
      )}
    </section>
  );
};
