import { useRef } from 'react';
import styles from './LandingPageParagraph.module.css';
import { WellButtonLink } from '_components';
import { useGetImgixVersion, useScrollIntoView } from '_hooks';


interface ILandingPageParagraph {
  title: string;
  text: string;
}
export const LandingPageParagraph = ({
  title,
  text,
}: ILandingPageParagraph) => {
  const imageOne = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/benefit-1.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageTwo = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/benefit-2.svg',
    {
      h: 300,
      w: 300,
    }
  );
  const imageThree = useGetImgixVersion(
    process.env.REACT_APP_ASSETS_URL + '/site/homepage/benefit-3.svg',
    {
      h: 300,
      w: 300,
    }
  );

  const imageOneRef = useRef<any>();
  const imageTwoRef = useRef<any>();
  const imageThreeRef = useRef<any>();

  useScrollIntoView(imageOneRef, true, 0, () => {
    imageOneRef?.current?.classList.add(styles['animate']);
  });

  useScrollIntoView(imageTwoRef, true, 0, () => {
    imageTwoRef?.current?.classList.add(styles['animate']);
  });
  useScrollIntoView(imageThreeRef, true, 0, () => {
    imageThreeRef?.current?.classList.add(styles['animate']);
  });

  return (
    <>
      <section className={styles['why-peer-support-one']}>
        <div className={'container'}>
          <h3 className={styles['section-title']}>{title}</h3>
          <p className={styles['section-text']}>{text}</p>

          <div className={styles['tri-graphics']}>
            <div className={styles['graphic-item']}>
              <img
                src={imageOne}
                ref={imageOneRef}
                className={styles['tri-graphic-image']}
                alt={'ShareWell for laptops'}
              />
              <p className={styles['tri-graphic-text']}>
                Join dozens of peer support groups that meet weekly online
              </p>
            </div>
            <div className={styles['graphic-item']}>
              <img
                src={imageTwo}
                ref={imageTwoRef}
                className={styles['tri-graphic-image']}
                alt={'ShareWell for laptops'}
              />
              <p className={styles['tri-graphic-text']}>
                Connect with peers who understand you in a safe virtual
                environment
              </p>
            </div>
            <div className={styles['graphic-item']}>
              <img
                src={imageThree}
                ref={imageThreeRef}
                className={styles['tri-graphic-image']}
                alt={'ShareWell for laptops'}
              />
              <p className={styles['tri-graphic-text']}>
                Access 24/7 forum & chat groups for support when you need it
              </p>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};
