
import { useState, createContext, Dispatch, SetStateAction } from 'react';
import { useMyGroups, usePinnedActivities } from '_hooks';

import { UserHome, } from '.';
import { IActivity, IGroup } from '_types';

interface IUserHomeContext {
  myGroupList: IGroup[];
  isLoadingMyGroups: boolean;

  selectedGroup: { uuid: string; name: string; }
  setSelectedGroup: Dispatch<SetStateAction<{ uuid: string; name: string; }>>

  pinnedActivities: IActivity[];
  isLoadingPinnedActivities: boolean;

}

export const UserHomeContext = createContext<IUserHomeContext>({} as IUserHomeContext);
export const UserHomeProvider = () => {

  const { myGroupList, isLoadingMyGroups, } = useMyGroups();
  const [selectedGroup, setSelectedGroup] = useState({
    uuid: '',
    name: '',
  });

  const { isLoadingPinnedActivities, pinnedActivities } = usePinnedActivities();

  return (
    <UserHomeContext.Provider value={{
      myGroupList,
      isLoadingMyGroups,

      selectedGroup,
      setSelectedGroup,

      pinnedActivities,
      isLoadingPinnedActivities,

    }}>
      <UserHome />
    </UserHomeContext.Provider>
  )



}
